<div class="footer">
  <div class="footer-div">© {{ yearCurrent }} Vertuoz</div>

  <div *ngIf="releaseNotesHtmlUri; then thenBlock; else elseBlock"></div>
  <ng-template #thenBlock
    ><a class="footer-div" (click)="openReleaseNotes()"
      >{{ product }} - Version {{ version }}</a
    ></ng-template
  >
  <ng-template #elseBlock
    ><div class="footer-div">{{ product }} - Version {{ version }}</div></ng-template
  >

  <a class="footer-div" (click)="openMentionsLegales()">Mentions légales</a>
</div>
