//#region Fields
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { AppRoutes } from './app-routes';
//#endregion

@NgModule({
    imports: [
        RouterModule.forRoot(AppRoutes, {
            relativeLinkResolution: 'legacy',
            preloadingStrategy: PreloadAllModules
        }),
        TranslateModule
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
