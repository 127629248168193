import { DOCUMENT } from '@angular/common';
import { Component, Inject, Injectable, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DataControlNotificationsService } from '@app/core/http/data-control/data-control-notifications.service';
import { DataControlNotificationFilter } from '@app/core/models/data-control/filters/data-control.filter';
import { GeoSelectionTimeStampService } from '@app/core/services/geo-service.service';
import { NotificationHeaderService } from '@app/core/services/notification-header.service';
import { PermissionService } from '@app/core/services/permission.service';
import { PagedContext } from '@app/features/shared/models/paged-context';
import { PerimeterScope } from '@app/shared/constants/filters.enum';
import { AppService } from '@core/http/app.service';
import { MenuService } from '@core/menu/menu.service';
import { ContractModel } from '@core/models/contract/contract.model';
import { EnumPermissions } from '@core/models/permissions/permissions.enum';
import { UserModel } from '@core/models/user/user.model';
import { ThemesService } from '@core/services/themes.service';
import { SettingsService } from '@core/settings/settings.service';
import { timer, Subject, Subscription } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@environments/environment';
@Injectable({
    providedIn: 'root' // <--provides this service in the root ModuleInjector
})
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
    headerMenuItems = [];
    @Input() headerTpl: string;
    EnumPermissions = EnumPermissions;
    public currentUser: UserModel;
    currentContract: ContractModel;
    hasPermission_CONTROL_RULES_ACCESS: boolean;
    private timerSubscription: Subscription;
    private firstLoad = true;
    private _onDestroy = new Subject<void>();
    router: Router;
    isNavSearchVisible: boolean;
    constructor(
        public injector: Injector,
        public themes: ThemesService,
        public menu: MenuService,
        public settings: SettingsService,
        @Inject(DOCUMENT) public document: Document,
        public dialog: MatDialog,
        private appService: AppService,
        private translate: TranslateService,
        private permissionsService: PermissionService,
        private dataControlNotificationsService: DataControlNotificationsService,
        private notificationHeaderService: NotificationHeaderService,
        private geoSelectionTimeStampService: GeoSelectionTimeStampService
    ) {}

    // eslint-disable-next-line @angular-eslint/contextual-lifecycle
    ngOnInit(): void {
        this.isNavSearchVisible = false;
        this.router = this.injector.get(Router);
        this.currentUser = this.appService.getUserContext().user;
        this.currentContract = this.appService.getUserContext().contract;
        this.hasPermission_CONTROL_RULES_ACCESS = this.permissionsService.hasPermission([
            EnumPermissions.CONTROL_RULES_ACCESS
        ]);
        this.settings.layout.isHeaderDataControl = this.hasPermission_CONTROL_RULES_ACCESS;
        this.geoSelectionTimeStampService.data.pipe(takeUntil(this._onDestroy)).subscribe(() => {
            if (!this.firstLoad) {
                this.notificationHeaderService.notification.next(
                    this.hasPermission_CONTROL_RULES_ACCESS
                );
            }
        });
        this.timerSubscription = timer(0, 1000 * 60 * 60)
            .pipe(
                map(() => {
                    this.firstLoad = false;
                    this.notificationHeaderService.notification.next(
                        this.hasPermission_CONTROL_RULES_ACCESS
                    );
                })
            )
            .subscribe();
        this.notificationHeaderService.notification.subscribe(bool => {
            if (bool) {
                this.dataControlNotificationsService
                    .getNotifications(
                        new DataControlNotificationFilter({ notificationStatus: [1, 2] }),
                        new PagedContext({ pageSize: 1 }),
                        PerimeterScope.activeSelectedPerimeter
                    )
                    .subscribe(res => {
                        if (res) {
                            this.settings.layout.numberDataControl = res.rowCount;
                        } else {
                            this.settings.layout.numberDataControl = 0;
                        }
                    });
            }
        });
        // Autoclose navbar on mobile when route change
        this.router.events.subscribe(val => {
            // scroll view to top
            window.scrollTo(0, 0);
        });
    }
    // don't forget to unsubscribe when the Observable is not necessary anymore
    ngOnDestroy(): void {
        this.timerSubscription.unsubscribe();
    }
    openNavSearch(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();
        this.setNavSearchVisible(true);
    }
    setNavSearchVisible(stat: boolean): void {
        this.isNavSearchVisible = stat;
    }
    getNavSearchVisible(): boolean {
        return this.isNavSearchVisible;
    }
    resetTheme(): void {
        this.themes.setTheme(this.themes.getDefaultTheme());
    }
    selectLinkByRouteUrl(url: string): string {
        if (this.router.url.indexOf(url) > -1) {
            return 'link-active';
        }
    }
    setBrandClass(): string {
        let brandClass = 'brand';
        switch (this.themes.getCurrentTheme()) {
            case 'bigStone':
                brandClass += ' t-white c-gradient';
                break;
            case 'yellowSpark':
                brandClass += ' t-white c-yellow';
                break;
            case 'primaryBlue':
            case 'blueAlt':
            case 'gradient':
                brandClass += ' t-white c-black';
                break;
        }
        return brandClass;
    }
    toggleCollapsedSidebar(): void {
        this.settings.toggleLayoutSetting('isCollapsed');
    }
    isCollapsedText(): string | boolean {
        return this.settings.getLayoutSetting('isCollapsedText');
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mailToSav(mail: any): void {
        // TODO: voir pour stocker ces valeurs en base ou pouvoir les changer à la volé
        const body = `
        Utilisateur : ${this.currentUser.firstName} ${this.currentUser.familyName}
        %0D%0A
        Email: ${this.currentUser.login}
        %0D%0A
        Téléphone: ${this.currentUser.phoneNumber}
        %0D%0A%0D%0A
        Brève description du problème rencontré:
        %0D%0A%0D%0A%0D%0A%0D%0A
        Action effectuée lors de l'apparition du problème:
        %0D%0A%0D%0A%0D%0A%0D%0A
        Page sur laquelle vous etiez lors de l'apparition du problème:
        %0D%0A%0D%0A%0D%0A
        Cordialement,
        `;
        mail = document.createElement('a');
        mail.href = `mailto:support-vertuoz@siradel.com?subject=Demande de support&body=${body}`;
        mail.click();
    }
    // Set runtime Language
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    useLanguage(language: string) {
        this.translate.use(language);
    }
    showLanguage(): boolean {
        return !environment.production;
    }
}
