import { KeyValue } from '@angular/common';

export enum SourceEnum {
    Invoice = 3,
    Reading = 2,
    RemoteReading = 1
}
export const SourceEnumRSX: Array<KeyValue<SourceEnum, string>> = [
    { key: SourceEnum.Invoice, value: 'common.source.invoice' },
    { key: SourceEnum.Reading, value: 'common.source.reading' },
    { key: SourceEnum.RemoteReading, value: 'common.source.remote-reading' }
];
