export enum EnumPropertyRoute {
    path = 'property',

    listEstablishment = 'establishments',
    createOrUpdateEstablishment = 'establishment',
    readEstablishment = 'establishment-details',

    listBuilding = 'buildings',
    createOrUpdateBuilding = 'building',
    readBuilding = 'building-details',

    listZone = 'zones',
    createOrUpdateZone = 'zone',
    readZone = 'zone-details'
}

export enum EnumPropertyMapParams {
    id = 'id',
    parentType = 'parentType'
}
