import { KeyValue } from '@angular/common';

export enum SensEnum {
    Consumption = 1,
    Production = 2
}

export const SensRSX: Array<KeyValue<SensEnum, string>> = [
    { key: SensEnum.Production, value: 'aggregate-production.production' },
    { key: SensEnum.Consumption, value: 'aggregate-production.consumption' }
];
