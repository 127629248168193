import { Component, Input } from '@angular/core';
import { FluidEnum } from '@app/shared/constants/fluid.enum';
import { RenewableEnergyEnum } from '@app/shared/constants/renewable-energy.enum';

@Component({
    selector: 'meteringpoint-display',
    templateUrl: './meteringpoint-display.component.html',
    styleUrls: ['./meteringpoint-display.component.scss']
})
export class MeteringPointDisplayComponent {
    @Input() id: number;
    @Input() classStyle: string;
    @Input() displayIcon = true;
    @Input() fluidOrRenewableEnergy: boolean;
    @Input() badgeSize: string;

    FluidEnum = FluidEnum;
    RenewableEnergyEnum = RenewableEnergyEnum;

    constructor() {}
}
