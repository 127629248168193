//#region Imports
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import * as fileDownload from 'file-saver';

import { FileExportType } from '@app/shared/constants/export.enum';
//#endregion

@Injectable({
    providedIn: 'root'
})
export class BaseService {
    //#region fields
    public get version(): string {
        return 'v2.3';
    }
    //#endregion

    //#region Methods
    public createHttpParams(...datas: Object[]): HttpParams {
        let httpParams = new HttpParams();
        for (const data of datas) {
            if (data) {
                Object.keys(data).forEach((key: string) => {
                    if (data[key] !== undefined && data[key] !== null) {
                        if (Array.isArray(data[key])) {
                            for (const d of data[key]) {
                                httpParams = httpParams.append(key, d);
                            }
                        } else {
                            httpParams = httpParams.append(key, data[key]);
                        }
                    }
                });
            }
        }

        return httpParams;
    }

    public downloadFile(
        // tslint:disable-next-line: typedef
        data,
        filename: string,
        fileExportType: FileExportType,
        withHorodatage: boolean = true
    ): void {
        let horodatage = '';
        if (withHorodatage) {
            const dateFormat = require('dateformat');
            horodatage = '_' + dateFormat(new Date(), 'yyyymmddhhMMss');
        }
        const blob = new Blob([data], { type: 'application/octet-stream' });
        const extension = FileExportType[fileExportType].toLowerCase();
        fileDownload.saveAs(blob, filename + horodatage + '.' + extension);
    }

    // tslint:disable-next-line:typedef
    public downloadFileFromData(data, headers): void {
        const blob = new Blob([data], { type: 'application/octet-stream' });
        const contentDisposition = headers.get('content-disposition');
        const filename = this.getFileName(contentDisposition);

        fileDownload.saveAs(blob, filename);
    }

    private getFileName(disposition: string): string {
        const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-\.]+)(?:; ?|$)/;
        const asciiFilenameRegex = /filename=(["']?)(.*?[^\\])\1(?:; ?|$)/;

        let fileName: string = null;
        if (utf8FilenameRegex.test(disposition)) {
            fileName = decodeURIComponent(utf8FilenameRegex.exec(disposition)[1]);
        } else {
            const matches = asciiFilenameRegex.exec(disposition);
            if (matches != null && matches[2]) {
                fileName = matches[2];
            }
        }
        return fileName;
    }
    //#endregion
}
