import { EnumPerimeterLevels } from '@app/features/perimeter/common/perimeter-levels.enum';
import { createAction, props } from '@ngrx/store';

import {
    BuildingPageState,
    EstablishmentPageState,
    ZonePageState
} from './property-module-state.interface';

export enum propertyActionsTypes {
    SET_NAVIGATIONPAN_STATE = '[NAVIGATION PAN State] Set',
    SET_ESTABLISHMENT_STATE = '[ESTABLISHMENT State] Set',
    SET_BUILDING_STATE = '[BUILDING State] Set',
    SET_ZONE_STATE = '[ZONE State] Set',
    RESET_STATE = '[PROPERTY State] Reset'
}

export const setNavigationPanState = createAction(
    propertyActionsTypes.SET_NAVIGATIONPAN_STATE,
    props<{ newNavigationPanState: EnumPerimeterLevels }>()
);
export const setEstablishmentPageState = createAction(
    propertyActionsTypes.SET_ESTABLISHMENT_STATE,
    props<{ newEstablishmentPageState: EstablishmentPageState }>()
);
export const setBuildingPageState = createAction(
    propertyActionsTypes.SET_BUILDING_STATE,
    props<{ newBuildingPageState: BuildingPageState }>()
);
export const setZonePageState = createAction(
    propertyActionsTypes.SET_ZONE_STATE,
    props<{ newZonePageState: ZonePageState }>()
);

export const resetPropertyModuleState = createAction(propertyActionsTypes.RESET_STATE);
