import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToolsService } from '@features/shared/services/tools.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DropdownComponent } from '../custom-dropdown/dropdown.component';

@Component({
    selector: 'vertuoz-range-selector',
    templateUrl: './vertuoz-range-selector.component.html',
    styleUrls: ['./vertuoz-range-selector.component.scss']
})
export class VertuozRangeSelectorComponent implements OnInit, OnChanges, OnDestroy {
    @Input()
    public label: string;

    @Input()
    public placeholder: string;

    @Input()
    public selected: string;

    @Input()
    public required = false;

    @Input()
    public disabled = false;

    @Input()
    public minAttributeName: string;

    @Input()
    public maxAttributeName: string;

    @Input()
    public defaultValues: { min?: number; max?: number };

    @ViewChild('input')
    public input: ElementRef;

    @ViewChild(DropdownComponent)
    public dropdown: DropdownComponent;

    min = '';
    max = '';

    form: FormGroup;
    @Output()
    closeWindow: EventEmitter<{
        closeWindow: boolean;
        isFilterSelected: boolean;
        min: number;
        max: number;
    }> = new EventEmitter<{
        closeWindow: boolean;
        isFilterSelected: boolean;
        min: number;
        max: number;
    }>();

    public displayText = null;

    private _onDestroy = new Subject<void>();

    constructor(private toolsService: ToolsService, private fb: FormBuilder) {}

    ngOnInit(): void {
        this.form = this.fb.group({ min: '', max: '' });
        this.form
            .get('min')
            .valueChanges.pipe(takeUntil(this._onDestroy))
            .subscribe(value => {
                if (
                    !this.toolsService.stringIsNullEmptyOrUndefined(this.form.get('max').value) &&
                    !this.toolsService.stringIsNullEmptyOrUndefined(value) &&
                    value > this.form.get('max').value
                ) {
                    this.form.get('max').setValue(value);
                }
                this.min = value;
            });
        this.form
            .get('max')
            .valueChanges.pipe(takeUntil(this._onDestroy))
            .subscribe(value => {
                this.max = value;
            });

        if (this.defaultValues) {
            if (this.defaultValues.min) {
                this.form.patchValue({ min: this.defaultValues.min });
            }

            if (this.defaultValues.max) {
                this.form.patchValue({ max: this.defaultValues.max });
            }

            this.generateDisplayText();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && this.form) {
            if (changes['defaultValues'] && changes['defaultValues'].currentValue) {
                if (changes['defaultValues'].currentValue.min) {
                    this.form.patchValue({ min: changes['defaultValues'].currentValue.min });
                }

                if (changes['defaultValues'].currentValue.max) {
                    this.form.patchValue({ max: changes['defaultValues'].currentValue.min });
                }

                this.generateDisplayText();
            }
        }
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    public showDropdown(): void {
        this.dropdown.show();
    }

    public hideDropdown(): void {
        this.dropdown.hide();
    }

    public onDropMenuIconClick(event: UIEvent): void {
        event.stopPropagation();
        setTimeout(() => {
            this.input.nativeElement.focus();
            this.input.nativeElement.click();
        }, 10);
    }

    focusMin(): void {
        if (
            !this.toolsService.stringIsNullEmptyOrUndefined(this.form.get('max').value) &&
            this.toolsService.stringIsNullEmptyOrUndefined(this.form.get('min').value)
        ) {
            this.form.get('min').setValue('0');
        }
    }

    focusMax(): void {
        if (
            !this.toolsService.stringIsNullEmptyOrUndefined(this.form.get('min').value) &&
            this.toolsService.stringIsNullEmptyOrUndefined(this.form.get('max').value)
        ) {
            this.form.get('max').setValue(this.form.get('min').value);
        }
    }

    onReset(): void {
        this.closeWindow.emit({
            closeWindow: true,
            isFilterSelected: false,
            min: undefined,
            max: undefined
        });
        this.hideDropdown();
        this.form.reset();
        this.displayText = null;
    }

    onClick(): void {
        const minValue = this.toolsService.stringIsNullEmptyOrUndefined(this.min)
            ? undefined
            : +this.min;
        const maxValue = this.toolsService.stringIsNullEmptyOrUndefined(this.max)
            ? undefined
            : +this.max;
        const isEmptyFilterMinMax = !minValue && !maxValue;
        this.closeWindow.emit({
            closeWindow: true,
            isFilterSelected: !isEmptyFilterMinMax,
            min: minValue,
            max: maxValue
        });
        this.hideDropdown();
        this.generateDisplayText();
    }

    generateDisplayText(): void {
        if (this.min || this.max) {
            if (!this.min) {
                this.displayText = 'Max: ' + this.max;
            }
            if (!this.max) {
                this.displayText = 'Min: ' + this.min;
            }
            if (this.min && this.max) {
                this.displayText = 'Min: ' + this.min + ' - Max: ' + this.max;
            }
        } else {
            this.displayText = null;
        }
    }

    disabledApply(): boolean {
        return (
            !this.toolsService.stringIsNullEmptyOrUndefined(this.form.get('min').value) &&
            !this.toolsService.stringIsNullEmptyOrUndefined(this.form.get('max').value) &&
            this.form.get('min').value > this.form.get('max').value
        );
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
