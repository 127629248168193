export enum EnumPerimeterLevels {
    // GEOGRAPHY
    NATION = -3,
    REGION = -2,
    COUNTY = -1,
    TOWN = 0,

    // PROPERTY
    ESTABLISHMENT = 1,
    BUILDING = 2,
    ZONE = 3,

    // PUBLIC SPACE
    DISTRICT = 11,
    STREET = 12,
    CABINET = 13
}
