//#region Imports
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, Subject, Subscription, timer } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { UserContext, UserTracking } from '../models/user/user.context';
import { DEFAULT_PAGE_SIZE } from '@app/features/shared/models/paged-context';
//#endregion

@Injectable({
    providedIn: 'root'
})
export class AppService {
    //#region Fields
    public estimateTrackingSubject: Subject<number>;
    public static CUSTOM_PAGE_SIZE = DEFAULT_PAGE_SIZE;

    private timerSubscription: Subscription;
    private timeToLaunch = 5000;
    private usercontext: UserContext;
    private userTracking: UserTracking;
    private API_ENDPOINT: string = environment.API_ENDPOINT;
    private userApiUrl = `${this.API_ENDPOINT}Users/`;
    private name_browser = '';
    private ver_browser = '';
    //#endregion

    //#region Constructor
    constructor(private http: HttpClient) {
        this.name_browser = this.findname_browser();
        this.ver_browser = this.findver_browser();
    }
    //#endregion

    launchtimer(time: number): Subscription {
        return timer(time, time)
            .pipe(
                map(() => {
                    this.timeToLaunch = time * 2;
                })
            )
            .subscribe(() => {
                if (this.trackingIsEnable && this.userTracking.currentPage !== 'not-visible') {
                    this.trackingUserExperience(this.userTracking.currentPage, new Date(), true);
                }
            });
    }

    //#region Getters / Setters
    getUserContext(): UserContext {
        return this.usercontext;
    }

    setUserContext(userContext: UserContext): void {
        this.usercontext = userContext;
        AppService.CUSTOM_PAGE_SIZE =
            userContext?.contract?.numberOfItemsPerPage ?? DEFAULT_PAGE_SIZE;
    }

    get trackingIsEnable(): boolean {
        if (this.userTracking) {
            return true;
        } else {
            return false;
        }
    }

    private enableTracking(startDatePage: Date): void {
        this.userTracking = new UserTracking({
            userId: this.usercontext.user.userId,
            contractId: this.usercontext.contract.id,
            clientId: this.usercontext.contract.client.id,
            organizationId: this.usercontext.contract.organization.id,
            roleId: this.usercontext.role.id,
            session: `${this.usercontext.user.userId}-${
                this.usercontext.contract.id
            }-${startDatePage.getTime()}`,
            startDateSession: startDatePage,
            browserName: this.name_browser,
            browserVersion: this.ver_browser
        });
    }

    trackingUserExperience(
        currentPage: string,
        startDatePage: Date,
        isEstimated: boolean = false
    ): void {
        // le contexte utilisateur doit être chargé
        if (this.usercontext) {
            // si le tracking n'est pas initialisé on l'initialise
            if (!this.trackingIsEnable) {
                this.enableTracking(startDatePage);
            }
            this.userTracking.isEstimated = isEstimated;
            if (this.timerSubscription) {
                this.timerSubscription.unsubscribe();
            }
            if (this.userTracking.isEstimated) {
                this.timerSubscription = this.launchtimer(this.timeToLaunch);
            } else {
                this.timerSubscription = this.launchtimer(5000);
            }
            // si la navigation est sur la même page on ne déclenche pas le tracking
            if (currentPage !== this.userTracking.currentPage || isEstimated) {
                // Date d'arrivé sur la page
                this.userTracking.startDatePage = startDatePage;
                // la page précedende
                this.userTracking.previousPage = this.userTracking.currentPage;
                // la page courante
                this.userTracking.currentPage = currentPage;
                // API de tracking
                var params = new URLSearchParams({
                    data: JSON.stringify(this.userTracking)
                });
                navigator.sendBeacon(`${environment.API_ENDPOINT}tracks`, params);
            }
        }
    }

    private findname_browser(): string {
        const agent = window.navigator.userAgent.toLowerCase();
        switch (true) {
            case agent.indexOf('edge') > -1:
                return 'edge';
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            case agent.indexOf('opr') > -1 && !!(<any>window).opr:
                return 'opera';
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
                return 'chrome';
            case agent.indexOf('trident') > -1:
                return 'ie';
            case agent.indexOf('firefox') > -1:
                return 'firefox';
            case agent.indexOf('safari') > -1:
                return 'safari';
            default:
                return 'other';
        }
    }

    private findver_browser(): string {
        var userAgent = navigator.userAgent,
            tem,
            matchTest =
                userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) ||
                [];

        if (/trident/i.test(matchTest[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
            return 'IE ' + (tem[1] || '');
        }

        if (matchTest[1] === 'Chrome') {
            tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
            if (tem != null)
                return tem
                    .slice(1)
                    .join(' ')
                    .replace('OPR', 'Opera');
        }

        matchTest = matchTest[2]
            ? [matchTest[1], matchTest[2]]
            : [navigator.appName, navigator.appVersion, '-?'];

        if ((tem = userAgent.match(/version\/(\d+)/i)) != null) matchTest.splice(1, 1, tem[1]);
        return matchTest.join(' ');
    }
    //#endregion

    //#region API Calls
    public InitApp(contractId: string): Observable<UserContext> {
        return this.http.get<UserContext>(this.userApiUrl + 'UserContext' + '/' + contractId).pipe(
            map(response => {
                return response;
            })
        );
    }
    //#endregion
}
