/* eslint-disable @typescript-eslint/no-explicit-any */
import { Directive, Inject, Injectable, Input } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import {
    DateRange,
    MatDateRangeSelectionStrategy,
    MAT_DATE_RANGE_SELECTION_STRATEGY
} from '@angular/material/datepicker';

@Injectable()
export class MaxRangeSelectionStrategy<D> implements MatDateRangeSelectionStrategy<D> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    start: any;
    public delta: number;

    constructor(private _dateAdapter: DateAdapter<D>) {}

    selectionFinished(date: D, currentRange: DateRange<D>): DateRange<D> {
        if (this.delta == null) {
            return currentRange;
        }

        let { start, end } = currentRange;
        if (start == null || (start && end)) {
            start = date;
            end = null;
        } else if (end == null) {
            const minDate = this._dateAdapter.addCalendarDays(start, this.delta - 1);
            end = date ? (date < minDate ? minDate : date) : null;
        }

        return new DateRange<D>(start, end);
    }

    createPreview(activeDate: D | null, currentRange: DateRange<D>): DateRange<D> {
        if (this.delta == null) {
            return currentRange;
        }

        if (currentRange.start && !currentRange.end) {
            const minDate = this._dateAdapter.addCalendarDays(currentRange.start, this.delta - 1);

            const rangeEnd = activeDate ? (activeDate < minDate ? minDate : activeDate) : null;
            return new DateRange(currentRange.start, rangeEnd);
        }

        return new DateRange<D>(null, null);
    }
}

@Directive({
    selector: '[maxRange]',
    providers: [
        {
            provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
            useClass: MaxRangeSelectionStrategy
        }
    ]
})
export class MaxRangeDirective {
    constructor(
        @Inject(MAT_DATE_RANGE_SELECTION_STRATEGY)
        private maxRangeStrategy: MaxRangeSelectionStrategy<any>
    ) {}
    @Input() set maxRange(value: number) {
        this.maxRangeStrategy.delta = +value || 0;
    }
}
