<!-- START Top Navbar-->
<header
  class="navbar topnavbar pe-3"
  [ngClass]="!settings.getLayoutSetting('isSidebarBtn') ? 'ps-4' : 'ps-0'"
  role="navigation"
>
  <div class="navbar-header">
    <p class="navbar-nav m-0">
      <ng-container *ngIf="settings.getLayoutSetting('isSidebarBtn')">
        <span class="nav-item">
          <!-- Button used to collapse the left sidebar. Only visible on tablet and desktops-->
          <button
            mat-button
            class="nav-link d-none d-md-inline-block px-3"
            trigger-resize=""
            (click)="settings.toggleLayoutSetting('isSidebarCollapsedText')"
          >
            <mat-icon>menu</mat-icon>
          </button>
          <!-- Button to show/hide the sidebar on mobile. Visible on mobile only.-->
          <button
            mat-button
            class="nav-link d-inline-block d-md-none"
            (click)="settings.toggleLayoutSetting('isSidebarToggled')"
          >
            <mat-icon>menu</mat-icon>
          </button>
        </span>
      </ng-container>
      <!-- Logo -->
      <a [routerLink]="'/home'" class="navbar-brand d-flex align-items-center">
        <span class="navbar-brand_logo-wrapper">
          <ng-container *ngIf="settings.layout.brand === 'ds'">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 217.5 24"
              [ngClass]="setBrandClass()"
              width="218"
              height="24"
            >
              <linearGradient
                id="gradient"
                gradientUnits="userSpaceOnUse"
                x1="163.46"
                y1="-7.73"
                x2="204.09"
                y2="32.9"
              >
                <stop offset="0" stop-color="#51b7e9" />
                <stop offset="1" stop-color="#5ba" />
              </linearGradient>
              <path
                class="typo"
                d="M160 20.7h-7.5V3.2h7.5c4 0 6 1.8 6 4.5 0 1.9-.9 3-2.3 3.7 1.9.6 2.9 2 2.9 4 0 3.3-2 5.3-6.6 5.3zm-.2-13.5h-2.6v3h2.7c1.1 0 1.5-.4 1.5-1.5 0-1.2-.5-1.5-1.6-1.5zm.2 6.4h-2.8v3.3h2.9c1.2 0 1.7-.3 1.7-1.7-.1-1.2-.5-1.6-1.8-1.6zm19.8 2.9l-.1 4.2h-11.4V3.2h4.9v13.3h6.6zm15.7-2.1c0 4.3-2.4 6.6-7.3 6.6-4.8 0-7.3-2.2-7.3-6.6V3.2h4.9v11.2c0 1.6.7 2.4 2.5 2.4 1.7 0 2.4-.7 2.4-2.4V3.2h4.8v11.2zm3.7 6.6c-1.7 0-2.5-.6-2.5-2.3 0-1.5.7-2.3 2.5-2.3s2.5.8 2.5 2.3c0 1.4-.7 2.3-2.5 2.3zm3-9.1c0-5.5 2.5-8.9 8-8.9 4.6 0 7.4 2.6 7.4 8.1 0 .8-.1 1.7-.1 2.4H208c.2 2.1 1.5 2.9 4 2.9 1.6 0 3.1-.2 4.6-.7l.6 4.2c-1.4.6-3.6 1.1-5.9 1.1-6.3 0-9.1-3.3-9.1-9.1zm10.3-1.8c-.1-2-.9-2.8-2.4-2.8-1.6 0-2.2.7-2.4 2.8h4.8z"
              />
              <path
                class="typo"
                d="M16.7 3.4L11 20.6H5.7L0 3.4h5.3L8 14l.4 1.8h.1l.4-1.8 2.8-10.6h5zm.7 8.4c0-5.5 2.5-8.8 7.9-8.8 4.6 0 7.3 2.6 7.3 8.1 0 .8-.1 1.7-.1 2.4H23c.2 2.1 1.5 2.9 4 2.9 1.6 0 3.1-.2 4.5-.7l.6 4.2c-1.4.6-3.6 1-5.8 1-6.1 0-8.9-3.2-8.9-9.1zm10.3-1.7c-.1-2-.8-2.7-2.3-2.7-1.6 0-2.2.6-2.4 2.7h4.7zm17.9 3.6l3.7 6.9h-5.6l-2.5-6h-1.7v6h-4.8V3.4h6.6c4.6 0 7 1.7 7 5.6-.1 2.2-1.1 4-2.7 4.7zm-4.4-6.5h-1.8v4h1.8c1.4 0 2-.3 2-2 0-1.6-.5-2-2-2zm17.1.3v13.1h-4.9V7.5h-4.3l.1-4.2h13.3l.1 4.2h-4.3zm21.1 6.8c0 4.4-2.5 6.6-7.6 6.6s-7.6-2.3-7.6-6.6V3.4h5.4v10.9c0 1.4.6 2.1 2.2 2.1 1.5 0 2.1-.6 2.1-2.1V3.4h5.5v10.9zM89.5 21c-5.7 0-8.2-2.6-8.2-9s2.5-9 8.2-9c5.7 0 8.1 2.7 8.1 9s-2.4 9-8.1 9zm0-4.2c1.9 0 2.5-.9 2.5-4.7 0-3.9-.6-4.7-2.5-4.7-1.9-.1-2.5.8-2.5 4.6 0 3.9.7 4.8 2.5 4.8zm17.1-2.3l-1.4 1.7v.1h7.2l-.1 4.3H99.1v-3.7l5.6-7.4 1.4-1.7v-.1h-6.7l.2-4.3h12.8V7l-5.8 7.5z"
              />
              <path
                class="cross"
                d="M144.3 22.7c.3.3.3.8 0 1.1-.1.1-.3.2-.5.2s-.4-.1-.5-.2l-10.7-10.7-10.7 10.7c-.1.1-.3.2-.5.2s-.4-.1-.5-.2c-.3-.3-.3-.8 0-1.1L131.4 12 120.7 1.3c-.3-.3-.3-.8 0-1.1.3-.3.8-.3 1.1 0l10.7 10.7L143.2.2c.3-.3.8-.3 1.1 0 .3.3.3.8 0 1.1L133.5 12l10.8 10.7z"
              />
            </svg>
          </ng-container>
          <ng-container *ngIf="settings.layout.brand === 'blue'">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 144 40"
              width="90"
              height="24"
              [ngStyle]="{
                fill:
                  settings.layout.defaultTheme === 'whiteSnow' ||
                  settings.layout.defaultTheme === 'bigStoneLight'
                    ? '#000'
                    : '#fff'
              }"
            >
              >
              <path
                d="M16.53 39.28H0V.52h16.58c8.91 0 13.34 4 13.34 10.08 0 4.19-2 6.77-5.05 8.16v.11c4.18 1.29 6.34 4.34 6.34 9 0 7.02-4.38 11.41-14.68 11.41zm-.36-30h-5.82v6.61h5.92c2.48 0 3.35-.93 3.35-3.41s-.93-3.22-3.45-3.22zm.36 14.21h-6.18v7.28h6.34c2.73 0 3.76-.77 3.76-3.67 0-2.6-.98-3.63-3.92-3.63zM60.46 30l-.21 9.3H35V.52h10.88V30zm34.74-4.57C95.2 35 89.9 40 79.13 40S63 35 63 25.43V.52h10.73v24.81c0 3.61 1.54 5.27 5.45 5.27s5.31-1.55 5.31-5.27V.52H95.2zm8.36 14.47c-3.86 0-5.51-1.4-5.51-5.12 0-3.31 1.65-5.17 5.51-5.17s5.51 1.86 5.51 5.17c0 3.15-1.65 5.12-5.51 5.12zM110 19.7C110 7.39 115.55 0 127.64 0 137.9 0 144 5.81 144 18a51.91 51.91 0 01-.31 5.43H122.6c.5 4.67 3.27 6.44 8.94 6.44a32.45 32.45 0 0010.13-1.58l1.33 9.34A34.24 34.24 0 01130 40c-13.69 0-20-7.23-20-20.3zm22.91-3.91c-.12-4.42-1.89-6.13-5.22-6.13-3.65 0-5 1.45-5.35 6.13z"
              />
            </svg>
          </ng-container>
          <ng-container *ngIf="settings.layout.brand === 'vertuoz'">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 250 40"
              width="160"
              height="24"
              [ngStyle]="{
                fill:
                  settings.layout.defaultTheme === 'whiteSnow' ||
                  settings.layout.defaultTheme === 'bigStoneLight'
                    ? '#000'
                    : '#fff'
              }"
            >
              <path
                d="M37.19.81L24.52 39.12h-11.9L0 .81h11.75l6.08 23.55 1 4H19l.92-4L26.1.81zm1.42 18.84C38.61 7.41 44.14.06 56.2.06 66.43.06 72.52 5.84 72.52 18a48.63 48.63 0 01-.31 5.4h-21c.51 4.65 3.27 6.4 8.92 6.4a32.32 32.32 0 0010.07-1.61l1.32 9.3a34.31 34.31 0 01-12.94 2.32c-13.69 0-19.97-7.16-19.97-20.16zm22.86-3.89c-.12-4.39-1.88-6.09-5.21-6.09-3.64 0-5 1.44-5.34 6.09zm39.79 7.93l8.33 15.43H97.13l-5.62-13.33h-3.89v13.33H77V.81h14.56C101.83.81 107 4.64 107 13.22c0 5.11-2.06 8.99-5.74 10.47zm-9.6-14.35h-4v8.79h3.94c3.16 0 4.34-.77 4.34-4.5-.04-3.37-1.11-4.29-4.28-4.29zm37.94.72v29.06h-10.78V10.06h-9.55l.2-9.25H139l.2 9.25zm46.79 15.06c0 9.67-5.53 14.69-17 14.69S142.5 34.6 142.5 25.12V.8h12.1v24.13c0 3.08 1.44 4.58 4.89 4.58s4.78-1.38 4.78-4.58V.8h12.12zM198.92 40c-12.56 0-18.15-5.84-18.15-19.91S186.43 0 199 0s18.08 6 18.08 20.09S211.61 40 198.92 40zm.07-9.42c4.14 0 5.58-2 5.58-10.49S203.13 9.61 199 9.61s-5.66 1.88-5.66 10.48 1.5 10.49 5.66 10.49zm38-5l-3.07 3.81.06.19h16l-.25 9.58h-29.59V31l12.33-16.52 3.07-3.89-.06-.12h-15l.38-9.64h28.48V9z"
              />
            </svg>
          </ng-container>
        </span>
        <span class="brand-logo-project pt-1 font-24 align-middle">{{
          settings.getAppSetting('name')
        }}</span>
      </a>
    </p>
  </div>

  <!-- Zone polyvalente -->
  <div class="free-zone w-25">
    <ng-container *ngIf="settings.getLayoutSetting('isHeaderFreeZone')">
      <header-free-zone></header-free-zone>
    </ng-container>
  </div>

  <!-- Navigation -->
  <menu class="m-0 ms-auto">
    <ul class="navbar-nav m-0 d-none d-md-flex">
      <ng-container *ngIf="settings.getLayoutSetting('isHeaderNavAdd')">
        <li class="nav-item" *ngFor="let item of menu.headerMenuItems">
          <a
            [routerLink]="item.link"
            [ngClass]="selectLinkByRouteUrl(item.link)"
            class="nav-link font-16"
            mat-button
          >
            <ng-container *ngIf="item.svgIcon">
              <mat-icon svgIcon="{{ item.svgIcon }}" class="me-1"></mat-icon>
            </ng-container>
            <ng-container *ngIf="!item.svgIcon">
              <mat-icon class="me-1">{{ item.icon }}</mat-icon>
            </ng-container>
            <span class="d-none d-xl-inline">{{ item.text | translate }}</span>
          </a>
        </li>
      </ng-container>
      <li class="nav-item" *ngIf="settings.getLayoutSetting('isHeaderSearch')">
        <button mat-button class="nav-link" (click)="openNavSearch($event)">
          <mat-icon>search</mat-icon>
        </button>
      </li>

      <li class="nav-item" *ngIf="settings.getLayoutSetting('isHeaderDataControl')">
        <a mat-button [routerLink]="['/data-control']" class="nav-link">
          <mat-icon
            [matBadge]="
              settings.getLayoutSetting('numberDataControl') &&
              settings.getLayoutSetting('numberDataControl') <= 99
                ? settings.getLayoutSetting('numberDataControl')
                : '99+'
            "
            [matBadgeHidden]="settings.getLayoutSetting('numberDataControl') === 0"
            matBadgePosition="before"
            matBadgeColor="warn"
            >notifications</mat-icon
          >
        </a>
      </li>

      <li
        class="nav-item"
        *ngIf="settings.getLayoutSetting('isHeaderMailLink')"
        (click)="mailToSav(this)"
      >
        <button mat-button class="nav-link" (click)="$event.preventDefault()">
          <mat-icon>mail</mat-icon>
        </button>
      </li>
      <li class="nav-item" #fsbutton *ngIf="settings.getLayoutSetting('isHeaderToggleFullscreen')">
        <button mat-button class="nav-link" toggleFullscreen>
          <mat-icon>fullscreen</mat-icon>
        </button>
      </li>
      <li class="nav-item" *ngIf="settings.getLayoutSetting('isProfileMenu')">
        <button
          mat-button
          class="nav-link"
          [matMenuTriggerFor]="profile"
          (click)="$event.preventDefault()"
        >
          <mat-icon>person_outline</mat-icon>
        </button>
        <mat-menu #profile="matMenu">
          <profile-menu></profile-menu>
        </mat-menu>
      </li>
      <li class="nav-item" *ngIf="settings.getLayoutSetting('isHeaderHub')">
        <button
          mat-button
          class="nav-link"
          [matMenuTriggerFor]="hub"
          (click)="$event.preventDefault()"
        >
          <mat-icon>apps</mat-icon>
        </button>
        <mat-menu #hub="matMenu">
          <hub-menu [hubMenu]="this.menu.hubMenuItems"></hub-menu>
        </mat-menu>
      </li>
      <li class="nav-item align-self-center" *ngIf="settings.getLayoutSetting('isLangSelector')">
        <lang-selector></lang-selector>
      </li>
    </ul>
  </menu>
  <app-navsearch
    [visible]="getNavSearchVisible()"
    (onclose)="setNavSearchVisible(false)"
  ></app-navsearch>
</header>
<!-- END Top Navbar-->
