import { Injectable } from '@angular/core';
import { IMenu } from '@core/menu/IMenu';

@Injectable({
    providedIn: 'root'
})
export class MenuService {
    sidebarMenuItems: Array<IMenu>;
    headerMenuItems: Array<IMenu>;
    hubMenuItems: Array<IMenu>;

    constructor() {
        this.sidebarMenuItems = [];
        this.headerMenuItems = [];
        this.hubMenuItems = [];
    }

    addMenu = (items: Array<IMenu>, type: string) => {
        switch (type) {
            case 'sidebar':
                items.forEach(item => {
                    this.sidebarMenuItems.push(item);
                });
                break;
            case 'header':
                items.forEach(item => {
                    this.headerMenuItems.push(item);
                });
                break;
            case 'hub':
                items.forEach(item => {
                    this.hubMenuItems.push(item);
                });
        }
    };

    removeMenuElt = (value: string) => {
        for (let i = 0; i < this.sidebarMenuItems.length; i++) {
            if (this.sidebarMenuItems[i].text === value) {
                this.sidebarMenuItems.splice(i, 1);
                break;
            }
        }
    };

    getMenu = (type: string) => {
        switch (type) {
            case 'sidebar':
                return this.sidebarMenuItems;
            case 'header':
                return this.headerMenuItems;
            case 'hub':
                return this.hubMenuItems;
        }
    };
}
