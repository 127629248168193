import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LinkedFileModel, FormFileModel, ILinkedFiles } from '@core/models/file/linked-file.model';
import { ToasterService } from '@vertuoz/vertuoz-library';
import { LinkedFileAllowedFileType } from '@shared/constants/file.enum';
import { FileService } from '@core/services/file/file.service';

@Component({
    selector: 'linked-file',
    templateUrl: './linked-file.component.html',
    styleUrls: ['./linked-file.component.scss']
})
export class LinkedFileComponent implements OnInit {
    _linkedFiles: LinkedFileModel[] = [];
    @Input()
    set linkedFiles(value: LinkedFileModel[]) {
        this._linkedFiles = value;
        this._linkedFiles.forEach(
            f => (f.fileSvgIcon = this.fileService.getSvgIconName(f.fileName.split('.')[1]))
        );
    }

    get linkedFiles(): LinkedFileModel[] {
        return this._linkedFiles;
    }

    @Input() totalMaxCountFile = 0;
    @Input() totalMaxSizeInKB = 0;
    @Input() isUpdateMode: false;

    @Output() linkedFilesChange = new EventEmitter<ILinkedFiles>();

    private formFileArray: FormFileModel[] = [];
    public errorSizeTotalMessage = `Le poids maximal de ${this.totalMaxSizeInKB} Ko est dépassé`;
    public errorAcceptedFileTypeMessage = 'Format de fichier non accepté';
    public errorFileSizeMessage = 'Fichier vide non accepté';
    public acceptedFileType = '';
    public isOverQuota = false;

    constructor(private toasterService: ToasterService, private fileService: FileService) {}

    ngOnInit(): void {
        this.setInputAcceptFileTypes();
        this.errorSizeTotalMessage = `Le poids maximal de ${this.totalMaxSizeInKB /
            1024} Mo est dépassé`;
    }

    setInputAcceptFileTypes(): void {
        for (const extension in LinkedFileAllowedFileType) {
            this.acceptedFileType += `.${extension},`;
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onFileSelected(event: any): void {
        if (event) {
            const file: File = event.target.files[0];
            if (file && file.size === 0) {
                this.toasterService.showError(this.errorFileSizeMessage);
            } else {
                this.checkTotalSizeInKB(file.size / 1024);
                if (this.fileService.isAcceptedFileFormat(file.name)) {
                    if (this.isOverQuota) {
                        this.toasterService.showError(this.errorSizeTotalMessage);
                    } else {
                        const formFile: FormFileModel = new FormFileModel(event.target.files[0]);
                        this.formFileArray.push(formFile);

                        this._linkedFiles.push({
                            fileId: FormFileModel.counter,
                            fileName: file.name,
                            kiloBytesSize:
                                file.size / 1024 >= 0.5 ? Math.round(file.size / 1024) : 1,
                            fileSvgIcon: this.fileService.getSvgIconName(file.name.split('.')[1])
                        });
                        this.sendDataToParent();
                    }
                } else {
                    this.toasterService.showError(this.errorAcceptedFileTypeMessage);
                }
            }
        }
    }

    sendDataToParent(): void {
        this._linkedFiles.filter(f => f.fileId > 0);
        this.linkedFilesChange.emit({
            filesData: this.formFileArray,
            linkedFiles: this._linkedFiles
        });
    }

    checkTotalSizeInKB(fileToAddSize: number): void {
        const initialValue = 0;
        const sumWithInitial = this._linkedFiles.reduce(
            (previousValue, currentValue) => previousValue + currentValue.kiloBytesSize,
            initialValue
        );
        this.isOverQuota = sumWithInitial + fileToAddSize > this.totalMaxSizeInKB ? true : false;
    }

    delete(index: number): void {
        const fileToDelete = this._linkedFiles.splice(index, 1)[0];
        // si id < 0 alors fichier ajouté pendant la session donc pas en bdd
        // on le supprime uniquement du tableau des ajouts
        if (fileToDelete.fileId < 0) {
            const i = this.formFileArray.findIndex(f => f.formId === fileToDelete.fileId);
            this.formFileArray.splice(i, 1);
        }
        this.sendDataToParent();
    }
}
