import { KeyValue } from '@angular/common';

export enum ProductionUseEnum {
    Autoconsumption = 1,
    Resale = 2
}

export const ProductionUseRSX: Array<KeyValue<ProductionUseEnum, string>> = [
    { key: ProductionUseEnum.Resale, value: 'aggregate-production.resale' },
    { key: ProductionUseEnum.Autoconsumption, value: 'aggregate-production.autoconsumption' }
];
