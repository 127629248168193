//#region Imports
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
//#endregion

@Injectable({
    providedIn: 'root'
})
export class ReleaseNotesService {
    //#region Fields
    private API_ENDPOINT: string = environment.API_ENDPOINT;
    private releaseNotesApiUrl = `${this.API_ENDPOINT}ReleaseNotes/`;
    //#endregion

    //#region  constructor
    constructor(private http: HttpClient) {}
    //#endregion

    //#region API Calls
    /**
     * Permet de récupérer la releaseNotes de l'Offre
     */
    public getReleaseNotes(): Observable<string> {
        return this.http.get<string>(this.releaseNotesApiUrl + `1`, {
            responseType: 'text' as 'json'
        });
    }
    //#endregion
}
