<custom-dialog
  [originX]="originX"
  [originY]="originY"
  [useOverlay]="true"
  [overlayX]="overlayX"
  [overlayY]="overlayY"
  [closeOnScroll]="false"
  #customDialogAdd
>
  <mat-icon-button-content
    mat-icon-button
    matTooltip="Ajouter les PDC au(x) contrat(s) fournisseur(s)"
    (click)="toggleAddToSupplierContracts()"
  >
    <mat-icon class="material-icons me-2">content_paste</mat-icon>
  </mat-icon-button-content>

  <dialog-content *ngIf="isAddPanelShowed">
    <form
      *ngIf="!isSupplierContractsLoading"
      [formGroup]="addToSupplierContractsFormGroup"
      (click)="$event.stopPropagation()"
      class="px-3"
    >
      <div>
        <div
          #scrollDiv
          class="list-container list-group scroll-me"
          formArrayName="selectedSupplierContracts"
          autosize
        >
          <div
            *ngFor="
              let selectedSupplierContract of addToSupplierContractsFormGroup.controls
                .selectedSupplierContracts['controls'];
              let i = index
            "
            class="list-group-item"
            [style.height.px]=""
          >
            <mat-checkbox class="example-margin" [formControlName]="i">
              <span class="word-break-all wh-sp-normal m-0">{{
                addSupplierContracts[i]?.label
              }}</span>
            </mat-checkbox>
          </div>
        </div>
      </div>
      <span *ngIf="addSupplierContracts.length === 0">
        Aucun contrat fournisseur possédant le(s) fluide(s) associé(s) au(x) point(s) de comptage
        sélectionné(s) n'a été trouvé.
      </span>
    </form>

    <div *ngIf="isSupplierContractsLoading" class="loading-container">
      <mat-spinner [diameter]="30" class="float-right"></mat-spinner>
      <p class="loading-text">{{ loadingText }}</p>
    </div>
  </dialog-content>

  <dialog-buttons *ngIf="isAddPanelShowed && !isSupplierContractsLoading">
    <div class="dialog__buttons text-end p-3">
      <button
        type="button"
        mat-stroked-button
        color="primary"
        class="btn-sm"
        (click)="this.isAddPanelShowed = false"
      >
        {{ 'common.action.cancel' | translate }}
      </button>
      <button
        type="button"
        mat-flat-button
        color="primary"
        class="btn-sm ms-3"
        (click)="onAddToSupplierContracts($event)"
        [disabled]="addSupplierContracts.length === 0"
      >
        Associer
      </button>
    </div>
  </dialog-buttons>
</custom-dialog>

<custom-dialog
  [padding]="10"
  [originX]="originX"
  [originY]="originY"
  [useOverlay]="true"
  [overlayX]="overlayX"
  [overlayY]="overlayY"
  [closeOnScroll]="false"
  #customDialogRemove
>
  <mat-icon-button-content
    mat-icon-button
    matTooltip="Dissocier les PDC des contrats fournisseurs"
    (click)="toggleRemoveFromSupplierContracts()"
  >
    <mat-icon class="material-icons me-2">content_paste_off</mat-icon>
  </mat-icon-button-content>

  <dialog-content *ngIf="isRemovePanelShowed">
    <form
      *ngIf="!isSupplierContractsLoading"
      [formGroup]="removeFromSupplierContractsFormGroup"
      class="px-3"
    >
      <div>
        <div
          class="list-container list-group scroll-me"
          formArrayName="selectedSupplierContracts"
          #scrollDiv
          autosize
        >
          <div
            *ngFor="
              let selectedGroup of removeFromSupplierContractsFormGroup.controls
                .selectedSupplierContracts['controls'];
              let i = index
            "
            class="list-group-item"
          >
            <mat-checkbox class="example-margin" [formControlName]="i">
              <span class="word-break-all wh-sp-normal m-0">{{
                removeSupplierContracts[i]?.label
              }}</span>
            </mat-checkbox>
          </div>
        </div>
        <p class="m-0" *ngIf="removeSupplierContracts.length === 0">
          Les PDC sélectionnés n'appartiennent à aucun contrat fournisseur.
        </p>
      </div>
    </form>

    <div *ngIf="isSupplierContractsLoading" class="loading-container">
      <mat-spinner [diameter]="30" class="float-right"></mat-spinner>
      <p class="loading-text">{{ loadingText }}</p>
    </div>
  </dialog-content>

  <dialog-buttons
    *ngIf="
      isRemovePanelShowed &&
      !isSupplierContractsLoading &&
      removeSupplierContracts &&
      removeSupplierContracts.length > 0
    "
  >
    <div class="dialog__buttons px-3 pb-3">
      <button
        mat-stroked-button
        color="primary"
        class="btn-sm"
        (click)="this.isRemovePanelShowed = false"
      >
        {{ 'common.action.cancel' | translate }}
      </button>
      <button
        mat-flat-button
        color="primary"
        class="btn-sm btn-error ms-2"
        (click)="onRemoveFromSupplierContracts($event)"
        [disabled]="removeSupplierContracts.length === 0"
      >
        Dissocier
      </button>
    </div>
  </dialog-buttons>
</custom-dialog>
