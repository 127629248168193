import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FluidEnum } from '@shared/constants/fluid.enum';
import { RenewableEnergyEnum } from '@shared/constants/renewable-energy.enum';
import { sensorMeasureType } from '@shared/constants/sensor-measure-types.enum';

@Component({
    selector: 'fluid-display',
    templateUrl: './fluid-display.component.html',
    styleUrls: ['./fluid-display.component.scss']
})
export class FluidDisplayComponent {
    @Input() fluidId: number;
    @Input() classStyle: string;
    @Input() displayIcon = true;
    @Input() customText: string;
    @Input() badgeClass: string;
    @Input() color: string;
    @Input() badgeType: string;
    @Input() badgeSize: string;

    @Input() isClickable = false;
    @Output() selectedFluid = new EventEmitter<number>();
    public hover = false;

    FluidEnum = FluidEnum;
    RenewableEnergyEnum = RenewableEnergyEnum;
    SensorMeasureTypeEnum = sensorMeasureType;
    sizeClass: string;

    constructor() {}

    public getSensorMeasureTypeBadge(
        SensorMeasureType: sensorMeasureType,
        badgeClass: string = null,
        badgeSize: string = null
    ): string {
        if (this.badgeType === 'sensor-measure') {
            this.sizeClass = `badge-${badgeSize}`;
        }

        switch (SensorMeasureType) {
            case sensorMeasureType.IndoorTemperature:
                return `badge-indoor-temp ${badgeClass} ${this.sizeClass}`;
            case sensorMeasureType.OutdoorTemperature:
                return `badge-outdoor-temp ${badgeClass} ${this.sizeClass}`;
            case sensorMeasureType.Temperature:
                return `badge-temp ${badgeClass} ${this.sizeClass}`;
            case sensorMeasureType.IndoorHygrometry:
                return `badge-indoor-hygro ${badgeClass} ${this.sizeClass}`;
            case sensorMeasureType.CO2Rate:
                return `badge-co2rate ${badgeClass} ${this.sizeClass}`;
            case sensorMeasureType.NO2Rate:
                return `badge-no2rate ${badgeClass} ${this.sizeClass}`;
            case sensorMeasureType.Presence:
                return `badge-presence ${badgeClass} ${this.sizeClass}`;
            case sensorMeasureType.AirQuality:
                return `badge-air-quality ${badgeClass} ${this.sizeClass}`;
            case sensorMeasureType.Pression:
                return `badge-pression ${badgeClass} ${this.sizeClass}`;
            case sensorMeasureType.Brightness:
                return `badge-brightness ${badgeClass} ${this.sizeClass}`;
            case sensorMeasureType.State:
                return `badge-state ${badgeClass} ${this.sizeClass}`;
            case sensorMeasureType.Radon:
                return `badge-radon ${badgeClass} ${this.sizeClass}`;
            case sensorMeasureType.COV:
                return `badge-cov ${badgeClass} ${this.sizeClass}`;
            default:
                return `badge-other ${badgeClass} ${this.sizeClass}`;
        }
    }

    public getRenewEnergyFluidBadge(
        RenewableEnergy: RenewableEnergyEnum,
        badgeSize: string = null
    ): string {
        if (this.badgeType === 'equipment') {
            this.sizeClass = `badge-${badgeSize}`;
        }

        switch (RenewableEnergy) {
            case RenewableEnergyEnum.Wind:
                return `badge-windturbine ${this.sizeClass}`;
            case RenewableEnergyEnum.Photovoltaic:
                return `badge-solar-photo ${this.sizeClass}`;
            case RenewableEnergyEnum.Hydraulic:
                return `badge-hydraulic ${this.sizeClass}`;
            case RenewableEnergyEnum.SolarThermal:
                return `badge-solar-therm ${this.sizeClass}`;
            case RenewableEnergyEnum.Geothermal:
                return `badge-geothermal ${this.sizeClass}`;
            case RenewableEnergyEnum.Biomass:
                return `badge-wood-industrial-chips ${this.sizeClass}`;
        }
    }

    public getFluidBadge(
        fluid: FluidEnum,
        badgeClass: string = null,
        badgeSize: string = null
    ): string {
        if (badgeClass === null) {
            badgeClass = this.displayIcon ? 'badge-ico' : 'badge-text';
        }

        if (this.badgeType === 'equipment') {
            this.sizeClass = `badge-${badgeSize}`;
        }

        switch (fluid) {
            case FluidEnum.Electricity:
                return `badge-electricity ${badgeClass} ${this.sizeClass}`;
            case FluidEnum.GreenElectricity:
                return `badge-green-electricity ${badgeClass} ${this.sizeClass}`;
            case FluidEnum.NaturalGas:
                return `badge-natural-gas ${badgeClass} ${this.sizeClass}`;
            case FluidEnum.PropaneGas:
                return `badge-propane ${badgeClass} ${this.sizeClass}`;
            case FluidEnum.ButaneGas:
                return `badge-butane ${badgeClass} ${this.sizeClass}`;
            case FluidEnum.Cold:
                return `badge-cold ${badgeClass} ${this.sizeClass}`;
            case FluidEnum.ColdNetwork:
                return `badge-cold-network ${badgeClass} ${this.sizeClass}`;
            case FluidEnum.Heat:
                return `badge-heat ${badgeClass} ${this.sizeClass}`;
            case FluidEnum.HeatingNetwork:
                return `badge-heat-network ${badgeClass} ${this.sizeClass}`;
            case FluidEnum.HeatingGas:
                return `badge-heat-gas ${badgeClass} ${this.sizeClass}`;
            case FluidEnum.HeatingElectricHeatPump:
                return `badge-heat-electric-heat-pump ${badgeClass} ${this.sizeClass}`;
            case FluidEnum.HeatRecovery:
                return `badge-heat-recovery ${badgeClass} ${this.sizeClass}`;
            case FluidEnum.HeatingGeothermal:
                return `badge-heat-geothermal ${badgeClass} ${this.sizeClass}`;
            case FluidEnum.SwimmingPoolWater:
                return `badge-swimming-pool-water ${badgeClass} ${this.sizeClass}`;
            case FluidEnum.IcedWater:
                return `badge-iced-water ${badgeClass} ${this.sizeClass}`;
            case FluidEnum.WaterFromConcessionaire:
                return `badge-water-from-concessionaire ${badgeClass} ${this.sizeClass}`;
            case FluidEnum.WaterHydrant:
                return `badge-water-hydrant ${badgeClass} ${this.sizeClass}`;
            case FluidEnum.GroundWater:
                return `badge-ground-water ${badgeClass} ${this.sizeClass}`;
            case FluidEnum.RecycledWater:
                return `badge-recycled-water ${badgeClass} ${this.sizeClass}`;
            case FluidEnum.MainWater:
                return `badge-main-water ${badgeClass} ${this.sizeClass}`;
            case FluidEnum.CollectedWater:
                return `badge-collected-water ${badgeClass} ${this.sizeClass}`;
            case FluidEnum.HotWater:
                return `badge-hot-water ${badgeClass} ${this.sizeClass}`;
            case FluidEnum.BrutWater:
                return `badge-brut-water ${badgeClass} ${this.sizeClass}`;
            case FluidEnum.SanitationWater:
                return `badge-sanitation-water ${badgeClass} ${this.sizeClass}`;
            case FluidEnum.HeavyFuelOil:
                return `badge-heavy-fuel-oil ${badgeClass} ${this.sizeClass}`;
            case FluidEnum.HeatingFuel:
                return `badge-heating-fuel ${badgeClass} ${this.sizeClass}`;
            case FluidEnum.WoodOther:
                return `badge-wood-other ${badgeClass} ${this.sizeClass}`;
            case FluidEnum.WoodForestryChipppings:
                return `badge-wood-forestry-chips ${badgeClass} ${this.sizeClass}`;
            case FluidEnum.WoodPellets:
                return `badge-wood-pellets ${badgeClass} ${this.sizeClass}`;
            case FluidEnum.WoodBriquettes:
                return `badge-wood-briquettes ${badgeClass} ${this.sizeClass}`;
            case FluidEnum.WoodIndustrialChippings:
                return `badge-wood-industrial-chips ${badgeClass} ${this.sizeClass}`;
            case FluidEnum.Coal:
                return `badge-coal ${badgeClass} ${this.sizeClass}`;
            case FluidEnum.CombinedGasHeatingFuel:
                return `badge-combined-gas-heating-fuel ${badgeClass} ${this.sizeClass}`;
            case FluidEnum.CombinedHeatingCooling:
                return `badge-combined-heating-cooling ${badgeClass} ${this.sizeClass}`;
            case FluidEnum.Electricity:
                return `badge-electricity ${badgeClass} ${this.sizeClass}`;
            default:
                return `badge-other ${badgeClass} ${this.sizeClass}`;
        }
    }

    public getCustomColorBadge(badgeClass: string = null): string {
        if (badgeClass === null) {
            badgeClass = this.displayIcon ? 'badge-ico' : 'badge-text';
        }

        let sizeClass = this.sizeClass;
        if (this.sizeClass == null) {
            sizeClass = '';
        }

        if (this.badgeType === 'usage') {
            return `badge-usage ${badgeClass} ${sizeClass}`;
        }

        return '';
    }

    public emitSelectedFluidValue(fluidId: number): void {
        this.selectedFluid.emit(fluidId);
    }
}
