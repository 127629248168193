import { Dictionary } from '@core/models/common/dictionary.model';
import { PerimeterItemModel } from '@core/models/perimeters/perimeter-item.model';

export class ActivePerimeterModel {
    groupId?: number;
    groupPerimeterLabel: string;
    contractId: number;
    perimeterItemsIds: { [index: number]: Array<number> };
    constructor() {}
}

export class ActivePerimeterInitModel {
    activePerimeterId: number;
    groupPerimeterId: number;
    groupPerimeterLabel: string;
    hasGeography: boolean;
    hasProperty: boolean;
    hasPublicSpace: boolean;
    perimeterItems: Array<PerimeterItemModel>;

    constructor() {
        this.perimeterItems = [];
    }
}

export function transformToActivePerimeterModel(
    activePerimeterInit: ActivePerimeterInitModel
): ActivePerimeterModel {
    const activePerimeter = new ActivePerimeterModel();
    activePerimeter.groupId = activePerimeterInit.groupPerimeterId;
    activePerimeter.groupPerimeterLabel = activePerimeterInit.groupPerimeterLabel;
    const dico = new Dictionary<number[]>();
    for (const item of activePerimeterInit.perimeterItems) {
        if (dico.ContainsKey(item.perimeterLevel)) {
            const value = dico.Value(item.perimeterLevel);
            value.push(item.itemId);
        } else {
            dico.Add(item.perimeterLevel, [item.itemId]);
        }
    }
    activePerimeter.perimeterItemsIds = dico.Items;
    return activePerimeter;
}
