import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'vertuoz-confirm-delete-dialog',
    templateUrl: './confirm-delete-dialog.component.html',
    styleUrls: ['./confirm-delete-dialog.component.scss']
})
export class ConfirmDeleteDialogComponent implements OnInit {
    /** Evenment émis une fois l'action validée et confirmée */
    @Output() public validate: EventEmitter<boolean> = new EventEmitter<boolean>();

    /** permet de définir le texte contenu de la popup */
    @Input() public title: string;

    constructor() {}

    ngOnInit() {}

    public OnValidate() {
        this.validate.emit(true);
    }
}
