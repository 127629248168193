<form [formGroup]="chartSettingsForm">
  <div class="row row-cols-4 p-4 align-items-center">
    <div *ngIf="!isDomainHidden" class="col">
      <mat-form-field class="w-100">
        <mat-select
          placeholder="{{ 'common.placeholder.domain' | translate }}"
          formControlName="domainsField"
          (selectionChange)="selectDomains($event.value, false)"
          [multiple]="enableMultiDomainSelection"
        >
          <ng-container>
            <mat-option *ngFor="let domain of displayedDomains; let i = index" [value]="domain">
              {{ getDomainLabel(domain) | translate }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="!hideAnalysisLevels" class="col">
      <mat-form-field class="w-100">
        <mat-select
          placeholder="{{ 'common.placeholder.analysis-level' | translate }}"
          formControlName="analysisLevelField"
          (selectionChange)="selectAnalysisLevel($event.value, false)"
        >
          <ng-container>
            <mat-option *ngFor="let al of displayedAnalysisLevel; let i = index" [value]="al">
              {{ getAnalysisLevelLabel(al) | translate: { count: 2 } }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="!hideSources" class="col">
      <mat-form-field class="w-100">
        <mat-select
          placeholder="{{ 'common.placeholder.source' | translate }}"
          formControlName="sourceField"
          (selectionChange)="selectSource($event.value, false)"
        >
          <ng-container>
            <mat-option *ngFor="let source of displayedSources" [value]="source">
              {{ getSourceLabel(source) | translate }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>

    <div *ngIf="!hidePeriods" class="col">
      <mat-form-field class="w-100">
        <mat-select
          placeholder="{{ 'common.placeholder.period' | translate }}"
          formControlName="periodField"
          (selectionChange)="selectPeriod($event.value, false)"
        >
          <ng-container>
            <mat-option *ngFor="let period of displayedPeriods" [value]="period">
              {{ getPeriodLabel(period) | translate }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col">
      <mat-form-field class="w-100">
        <input
          matInput
          [matDatepicker]="pickerStart"
          formControlName="startDateField"
          placeholder="{{ 'common.placeholder.startdate' | translate }}"
          (dateChange)="selectStartDate($event.value, false)"
          [max]="selectedMaxDate"
        />

        <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
        <mat-datepicker
          #pickerStart
          startView="{{ dateTimeService.getStartView(selectedTimestep) }}"
          (yearSelected)="onPickerYearSelected($event, pickerStart, 'start')"
          (monthSelected)="onPickerMonthSelected($event, pickerStart, 'start')"
        >
        </mat-datepicker>
      </mat-form-field>
    </div>

    <div class="col" *ngIf="!hideHours">
      <mat-form-field class="w-100">
        <mat-label>{{ 'common.placeholder.starttime' | translate }}</mat-label>
        <mat-select
          class="time-custom"
          formControlName="startTimeField"
          (selectionChange)="selectStartTime($event.value, false)"
        >
          <ng-container
            *ngIf="
              selectedTimestep === TimestepEnum.Minutes10 ||
              selectedTimestep === TimestepEnum.Minutes
            "
          >
            <mat-option *ngFor="let step of displayedMinutesInDay" [value]="step">
              {{ step | hoursMinutes }}
            </mat-option>
          </ng-container>
          <ng-container
            *ngIf="
              !(
                selectedTimestep === TimestepEnum.Minutes10 ||
                selectedTimestep === TimestepEnum.Minutes
              )
            "
          >
            <mat-option *ngFor="let step of displayedMinutesInDay" [value]="step">
              {{ step | hours }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col">
      <mat-form-field class="w-100">
        <input
          matInput
          [matDatepicker]="pickerEnd"
          placeholder="{{ 'common.placeholder.enddate' | translate }}"
          formControlName="endDateField"
          (dateChange)="selectEndDate($event.value, false)"
          [max]="selectedMaxDate"
        />
        <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
        <mat-datepicker
          #pickerEnd
          startView="{{ dateTimeService.getStartView(selectedTimestep) }}"
          (yearSelected)="onPickerYearSelected($event, pickerEnd, 'end')"
          (monthSelected)="onPickerMonthSelected($event, pickerEnd, 'end')"
        >
        </mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-3" *ngIf="!hideHours">
      <mat-form-field class="w-100">
        <mat-label>{{ 'common.placeholder.endtime' | translate }}</mat-label>
        <mat-select
          class="time-custom"
          formControlName="endTimeField"
          (selectionChange)="selectEndTime($event.value, false)"
        >
          <ng-container
            *ngIf="
              selectedTimestep === TimestepEnum.Minutes10 ||
              selectedTimestep === TimestepEnum.Minutes
            "
          >
            <mat-option *ngFor="let step of displayedMinutesInDay" [value]="step">
              {{ step | hoursMinutes }}
            </mat-option>
          </ng-container>
          <ng-container
            *ngIf="
              !(
                selectedTimestep === TimestepEnum.Minutes10 ||
                selectedTimestep === TimestepEnum.Minutes
              )
            "
          >
            <mat-option *ngFor="let step of displayedMinutesInDay" [value]="step">
              {{ step | hours }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>

    <div [ngClass]="!hideTimesteps ? 'col-6' : 'col-3'">
      <div class="row">
        <div class="col-6" *ngIf="!hideTimesteps">
          <mat-form-field class="w-100">
            <mat-select
              placeholder="{{ 'common.placeholder.timestep' | translate }}"
              formControlName="timestepField"
              (selectionChange)="selectTimestep($event.value, false)"
            >
              <ng-container>
                <mat-option *ngFor="let step of displayedTimesteps" [value]="step">
                  {{ getTimestepLabel(step) | translate: { gender: 'female' } }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="align-self-center" [ngClass]="!hideTimesteps ? 'col-6' : 'col-12'">
          <button
            [disabled]="
              !settingsIsChanging || selectedDomains.length < 1 || !chartSettingsForm.valid
            "
            (click)="onSubmitForm()"
            mat-flat-button
            color="primary"
            class="btn-sm"
          >
            {{ 'common.button.apply' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
