import { Component, Input, OnInit } from '@angular/core';
import { sensorMeasureType } from '@shared/constants/sensor-measure-types.enum';

@Component({
    selector: 'sensor-measure-icon',
    templateUrl: './sensor-measure-icon.component.html',
    styleUrls: ['./sensor-measure-icon.component.scss']
})
export class SensorMeasureIconComponent implements OnInit {
    @Input() sensorMeasureId: number;
    @Input() classStyle: string;

    SensorMeasureTypeEnum = sensorMeasureType;

    constructor() {}

    ngOnInit(): void {}
}
