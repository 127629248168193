import {
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';

import { ToasterService } from '@vertuoz/vertuoz-library';
import { Subject } from 'rxjs';
import { debounceTime, take, takeUntil } from 'rxjs/operators';

import { GroupsService } from '@app/core/http/perimeter/groups.service';
import { Dictionary } from '@app/core/models/common/dictionary.model';
import { Label } from '@app/core/models/common/label.model';
import { PerimeterItemModel } from '@app/core/models/perimeters/perimeter-item.model';
import { DomainService } from '@app/core/services/domain.service';
import { GeoSelectionTimeStampService } from '@app/core/services/geo-service.service';
import { EnumPerimeterLevels } from '@app/features/perimeter/common/perimeter-levels.enum';
import { PagedContext } from '@app/features/shared/models/paged-context';
import { DomainEnum } from '@app/shared/constants/domain.enum';
import { MODE } from '@app/shared/constants/mode.enum';
import { AppService } from '@core/http/app.service';
import { ActivePerimeterService } from '@core/http/perimeter/active-perimeter.service';
import { PerimetersService } from '@core/http/perimeter/perimeters.service';
import {
    ActivePerimeterInitModel,
    ActivePerimeterModel
} from '@core/models/perimeters/active-perimeter.model';
import { EnumPermissions } from '@core/models/permissions/permissions.enum';
import { GroupsTabComponent } from '@shared/components/geographic-menu/groups-tab/groups-tab.component';
import {
    DispatchHierachyEnum,
    PerimeterSelectorComponent
} from '@shared/components/perimeter-selector/perimeter-selector.component';
import { TertiaryDecreeFilter } from '@shared/components/perimeter-selector/model/tertiary-decree-filter.model';

import { Store } from '@ngrx/store';
import { getGeoMenuFilterState } from './store/geographic-menu.selector';
import { GeographicMenuFilterState } from '@app/core/store/state/geographic-menu/geographic-menu-module-state.interface';
import { setGeoMenuFilterState } from '@app/core/store/state/geographic-menu/geographic-menu.action';
import { GeoMenuService } from './geo-menu.service';

@Component({
    selector: 'geographic-menu',
    templateUrl: './geographic-menu.component.html',
    styleUrls: ['./geographic-menu.component.scss']
})
export class GeographicMenuComponent
    implements OnInit, OnDestroy, OnChanges, AfterViewChecked, AfterViewInit {
    /////////////////// Inputs section /////////////////////////
    @Input() updateMode = true;
    @Input() selectedLabel: string;
    @Input() lowestLevelMode = true;
    @Input() partialSelectedLabel: string;
    @Input() loading = false;
    @Input() geographicSelection: Array<PerimeterItemModel> = [];
    @Input() propertySelection: Array<PerimeterItemModel> = [];
    @Input() publicSpaceSelection: Array<PerimeterItemModel> = [];
    @Input() withPublicSpace = true;
    @Input() withProperty = true;
    @Input() withGeography = true;
    @Input() propertyLevelBegin = 1;
    @Input() propertyLevelFinish = 3;
    @Input() selectedGroups: Array<PerimeterItemModel>;
    @Input() mode: MODE;
    @Input() inlineMode = false;
    @Input() tertiaryDecreeFilter?: TertiaryDecreeFilter;

    @Output() closeMenuContainer = new EventEmitter<boolean>();
    @Output() selectionChange = new EventEmitter<void>();
    /**
     * possibility to set default activated tab
     * 1: géographie
     * 2: patimoine > immobilier
     * 3: patimoine > espace publique
     * et groupe
     */
    @Input() selectedTab = 0;

    /////////////////// Outputs section /////////////////////////

    /////////////////// Attributs section /////////////////////////
    dispatchHierachyEnum = DispatchHierachyEnum;
    loadingPerimeter = true;
    applyingPerimeter = false;
    indexTabGeography = 0;
    indexTabProperty = 1;
    indexTabGroup = 2;

    showSearchBox = false;
    searchSection = '';
    searchValue = '';

    // active collapse by default === immobilier
    estatePanelState = true;
    publicSpacePanelState = false;

    public EnumPermissions = EnumPermissions;
    public domainEnum = DomainEnum;

    // Subject for delay the call
    private searchChange$ = new Subject<void>();

    /** enregistrement pour desouscrire */
    private _onDestroy$ = new Subject<void>();

    geographySearchKey: string;
    propertySearchKey: string;
    publicSpaceSearchKey: string;
    groupsSearchKey: string;

    geographicDefaultSelection: Array<PerimeterItemModel> = [];
    propertyDefaultSelection: Array<PerimeterItemModel> = [];
    publicSpaceDefaultSelection: Array<PerimeterItemModel> = [];

    geographyFilterSelection: Array<PerimeterItemModel> = [];
    propertyFilterSelection: Array<PerimeterItemModel> = [];
    publicSpaceFilterSelection: Array<PerimeterItemModel> = [];

    public propertySearchPerimeterKey = 'propertySearchPerimeter';
    public geoSearchPerimeterKey = 'geoSearchPerimeter';

    // property filters vars
    pageSize = 10;

    towns: Array<Label> = [];
    tonwsIsLoading = false;
    totalTowns = undefined;
    selectedTownId: number = null;

    counties: Array<Label> = [];
    countiesIsLoading = false;
    totalCounties = undefined;
    selectedCountytId: number = null;

    regions: Array<Label> = [];
    regionsIsLoading = false;
    totalRegions = undefined;
    selectedRegionId: number = null;

    perimeterFilter: { [index: number]: Array<number> } = {};
    perimeterFilterRegions: { [index: number]: Array<number> } = {};
    perimeterFilterCounties: { [index: number]: Array<number> } = {};
    perimeterFilterTowns: { [index: number]: Array<number> } = {};

    activatedGroupId: number;

    /* State management */
    private geoMenuPageFilterState$ = this.store.select(getGeoMenuFilterState);
    private hasState: boolean = false;
    private isInitNavigation: boolean = true;
    private isDestroyed: boolean = false;

    // components references
    @ViewChild('search') searchField: ElementRef;

    @ViewChild('geographic')
    public geographicPerimeterSelector: PerimeterSelectorComponent;

    @ViewChild('property')
    public propertyPerimeterSelector: PerimeterSelectorComponent;

    @ViewChild('publicSpace')
    public publicSpacePerimeterSelector: PerimeterSelectorComponent;

    @ViewChild('groupsTab')
    public tabGroupsSelector: GroupsTabComponent;

    /**
     * Constructeur
     * @param appService
     * @param domainService
     * @param changeDetectionRef Pour le changement du dom
     * @param dataChange Import du service Geo-Service pour créer un évenement pour recharger la route
     * @param consumptionService
     * @param toaster
     * @param groupsService
     * @param activePerimeterService
     * @param perimetersService
     */
    constructor(
        private appService: AppService,
        private domainService: DomainService,
        public changeDetectionRef: ChangeDetectorRef,
        public dataChange: GeoSelectionTimeStampService,
        public toaster: ToasterService,
        public groupsService: GroupsService,
        public activePerimeterService: ActivePerimeterService,
        private perimetersService: PerimetersService,
        private geoMenuService: GeoMenuService, // Shared service for the child components
        private store: Store
    ) {}

    ngOnInit(): void {
        if (!this.inlineMode) {
            // get perimeter and init all
            this.initActivePerimeter();
        }

        this.geoMenuPageFilterState$.pipe(takeUntil(this._onDestroy$), take(1)).subscribe(res => {
            if (res) {
                this.hasState = true;

                this.selectedRegionId = res.regionId;
                this.selectedCountytId = res.departmentId;
                this.selectedTownId = res.townId;

                this.initFiltersWithData(res);
            }
        });

        // Delay for applying the filter
        this.searchChange$.pipe(debounceTime(400), takeUntil(this._onDestroy$)).subscribe(() => {
            this.updateSearch();
        });
    }

    ngAfterViewInit(): void {
        if (!this.hasState) {
            this.initFilters();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        // init only view navigation
        // params are passed as input
        if (
            this.inlineMode &&
            (changes.geographicSelection ||
                changes.propertySelection ||
                changes.publicSpaceSelection)
        ) {
            this.initViewNavigation();
        }
        if (changes.withGeography || changes.withProperty || changes.withPublicSpace) {
            if (!changes.withGeography.currentValue) {
                this.indexTabGeography = -1;
                this.indexTabProperty = 0;
                this.indexTabGroup = 1;
                this.initViewNavigation();
            }
        }
    }

    // default selection changed after view checked
    // here we force change detection after view checked to avoid compiler error
    ngAfterViewChecked(): void {
        this.changeDetectionRef.detectChanges();
    }

    ngOnDestroy(): void {
        // Clear the data in the shared service
        this.geoMenuService.removeSearchParameters(this.geoSearchPerimeterKey);
        this.geoMenuService.removeSearchParameters(this.propertySearchPerimeterKey);

        this.isDestroyed = true;

        this._onDestroy$.next();
        this._onDestroy$.complete();
    }

    /**
     * init active perimeter (format data/ navigation/ loading ...)
     */
    initActivePerimeter(): void {
        // get active perimeter
        this.activePerimeterService.loadActivePerimeter().subscribe(
            res => {
                // initialiser les filtres
                this.geographyFilterSelection = [];
                this.propertyFilterSelection = [];
                this.publicSpaceFilterSelection = [];
                if (!res) {
                    // init ActivePerimeter si nocontent
                    res = new ActivePerimeterInitModel();
                }
                if (res.groupPerimeterId) {
                    this.activatedGroupId = res.groupPerimeterId;
                }
                // regrouper par domaine
                this.geographicSelection = [
                    ...res.perimeterItems.filter(item => item.perimeterLevel < 1)
                ];
                this.propertySelection = [
                    ...res.perimeterItems.filter(
                        item =>
                            item.perimeterLevel >= +EnumPerimeterLevels.ESTABLISHMENT &&
                            item.perimeterLevel <= +EnumPerimeterLevels.ZONE
                    )
                ];
                this.publicSpaceSelection = [
                    ...res.perimeterItems.filter(
                        item =>
                            item.perimeterLevel >= +EnumPerimeterLevels.DISTRICT &&
                            item.perimeterLevel <= +EnumPerimeterLevels.CABINET
                    )
                ];
                // store init state
                this.geographicDefaultSelection = [...this.geographicSelection];
                this.propertyDefaultSelection = [...this.propertySelection];
                this.publicSpaceDefaultSelection = [...this.publicSpaceSelection];
                this.initViewNavigation();
                // handle hiding collapses (permissions)
                if (
                    !this.domainService.hasDomains([
                        this.domainEnum.Property,
                        this.domainEnum.PublicSpace
                    ])
                ) {
                    if (this.domainService.hasDomains([this.domainEnum.Property])) {
                        this.withProperty = true;
                        this.withPublicSpace = false;
                    } else {
                        this.withPublicSpace = true;
                        this.withProperty = false;
                    }
                }
                // masquer loader
                this.loadingPerimeter = false;
            },
            () => {
                this.loadingPerimeter = false;
                this.toaster.showError('Un problème est survenu au chargement de votre périmètre.');
            }
        );
    }

    // init navigation to current tab
    initViewNavigation(): void {
        // init showed tab/collapse
        if (this.activatedGroupId) {
            this.selectedTab = this.indexTabGroup;
            if (this.publicSpaceSelection.length) {
                this.publicSpacePanelState = true;
                this.estatePanelState = false;
            } else {
                this.estatePanelState = true;
                this.publicSpacePanelState = false;
            }
        } else {
            if (this.publicSpaceSelection.length || this.propertySelection.length) {
                this.selectedTab = this.indexTabProperty;
                if (this.publicSpaceSelection.length) {
                    this.publicSpacePanelState = true;
                    this.estatePanelState = false;
                } else {
                    this.estatePanelState = true;
                    this.publicSpacePanelState = false;
                }
            } else {
                if (this.withGeography) {
                    this.selectedTab = this.indexTabGeography;
                } else {
                    this.selectedTab = this.indexTabProperty;
                }
            }
        }

        this.isInitNavigation = false;
        this.updateSearch();
    }

    // init the first select
    initFilters(): void {
        this.appendLevelItems(EnumPerimeterLevels.REGION, 1);
        this.appendLevelItems(EnumPerimeterLevels.COUNTY, 1);
        this.appendLevelItems(EnumPerimeterLevels.TOWN, 1);
    }

    private initFiltersWithData(res: GeographicMenuFilterState): void {
        this.resetCountiesItems();
        this.resetTownsItems();

        const perimeterFilter = this.initPerimeterFilter(res);

        this.appendLevelItems(EnumPerimeterLevels.REGION, 1);
        this.appendLevelItems(EnumPerimeterLevels.COUNTY, 1);
        this.appendLevelItems(EnumPerimeterLevels.TOWN, 1);

        this.perimeterFilter = perimeterFilter;
        this.updateSearch();
    }

    private updateSearch(): void {
        if (this.isInitNavigation || this.isDestroyed) {
            return;
        }

        const searchPerimeter = {
            searchKey: this.searchValue,
            perimeterFilter: this.perimeterFilter
        };
        if (this.selectedTab === this.indexTabGeography) {
            this.geoMenuService.updateSearchParameters(this.geoSearchPerimeterKey, searchPerimeter);
        } else if (this.selectedTab === this.indexTabProperty) {
            this.geoMenuService.updateSearchParameters(
                this.propertySearchPerimeterKey,
                searchPerimeter
            );
        } else if (this.selectedTab === this.indexTabGroup) {
            this.groupsSearchKey = this.searchValue;
        }
    }

    private initPerimeterFilter(
        res: GeographicMenuFilterState
    ): { [index: number]: Array<number> } {
        this.perimeterFilterTowns = {};
        this.perimeterFilterCounties = {};

        const perimeterFilter = {};

        // 3 items selected
        if (res.regionId && res.departmentId && res.townId) {
            this.perimeterFilterCounties[+EnumPerimeterLevels.REGION] = [res.regionId];
            this.perimeterFilterTowns[+EnumPerimeterLevels.COUNTY] = [res.departmentId];

            perimeterFilter[+EnumPerimeterLevels.TOWN] = [res.townId];
        }

        // 2 items selected
        else if (res.regionId && res.departmentId && !res.townId) {
            this.perimeterFilterCounties[+EnumPerimeterLevels.REGION] = [res.regionId];
            this.perimeterFilterTowns[+EnumPerimeterLevels.COUNTY] = [res.departmentId];

            perimeterFilter[+EnumPerimeterLevels.COUNTY] = [res.departmentId];
        } else if (res.regionId && !res.departmentId && res.townId) {
            this.perimeterFilterCounties[+EnumPerimeterLevels.REGION] = [res.regionId];
            this.perimeterFilterTowns[+EnumPerimeterLevels.REGION] = [res.regionId];

            perimeterFilter[+EnumPerimeterLevels.TOWN] = [res.townId];
        } else if (!res.regionId && res.departmentId && res.townId) {
            this.perimeterFilterTowns[+EnumPerimeterLevels.COUNTY] = [res.departmentId];
            perimeterFilter[+EnumPerimeterLevels.TOWN] = [res.townId];
        }

        // 1 item selected
        else if (res.regionId && !res.departmentId && !res.townId) {
            this.perimeterFilterCounties[+EnumPerimeterLevels.REGION] = [res.regionId];
            this.perimeterFilterTowns[+EnumPerimeterLevels.REGION] = [res.regionId];

            perimeterFilter[+EnumPerimeterLevels.REGION] = [res.regionId];
        } else if (!res.regionId && res.departmentId && !res.townId) {
            this.perimeterFilterTowns[+EnumPerimeterLevels.COUNTY] = [res.departmentId];

            perimeterFilter[+EnumPerimeterLevels.COUNTY] = [res.departmentId];
        } else if (!res.regionId && !res.departmentId && res.townId) {
            perimeterFilter[+EnumPerimeterLevels.TOWN] = [res.townId];
        }

        return perimeterFilter;
    }

    appendTownsItems(pagedContext: PagedContext): void {
        this.appendLevelItems(EnumPerimeterLevels.TOWN, pagedContext.currentPage);
    }

    resetTownsItems(): void {
        this.towns = [];
    }

    appendCountiesItems(pagedContext: PagedContext): void {
        this.appendLevelItems(EnumPerimeterLevels.COUNTY, pagedContext.currentPage);
    }

    resetCountiesItems(): void {
        this.counties = [];
    }

    appendRegionsItems(pagedContext: PagedContext): void {
        this.appendLevelItems(EnumPerimeterLevels.REGION, pagedContext.currentPage);
    }

    appendLevelItems(levelId: number, page: number): void {
        let perimeter = {};
        switch (levelId) {
            case +EnumPerimeterLevels.REGION:
                this.regionsIsLoading = true;
                perimeter = this.perimeterFilterRegions;
                break;
            case +EnumPerimeterLevels.COUNTY:
                this.countiesIsLoading = true;
                perimeter = this.perimeterFilterCounties;
                break;
            case +EnumPerimeterLevels.TOWN:
                this.tonwsIsLoading = true;
                perimeter = this.perimeterFilterTowns;
                break;
        }

        this.perimetersService
            .getPerimetersByLevel(levelId, '', '', page, perimeter)
            .subscribe(res => {
                this.tonwsIsLoading = false;
                this.countiesIsLoading = false;
                this.regionsIsLoading = false;
                if (res && res.results) {
                    const formatedItems = [];
                    res.results.map(item => {
                        formatedItems.push(<Label>{
                            label: item.itemLabel,
                            id: item.itemId
                        });
                    });
                    switch (levelId) {
                        case +EnumPerimeterLevels.REGION:
                            this.totalRegions = res.rowCount;
                            this.regions = [...this.regions, ...formatedItems];
                            break;
                        case +EnumPerimeterLevels.COUNTY:
                            this.totalCounties = res.rowCount;
                            this.counties = [...this.counties, ...formatedItems];
                            break;
                        case +EnumPerimeterLevels.TOWN:
                            this.totalTowns = res.rowCount;
                            this.towns = [...this.towns, ...formatedItems];
                            break;
                    }
                }
            });
    }

    regionChanged($event: number): void {
        this.selectedRegionId = null;
        this.selectedRegionId = $event;

        this.selectedCountytId = this.selectedTownId = null;
        this.perimeterFilterTowns = this.perimeterFilterCounties = {};

        const perimeterFilter = {};
        if ($event !== null) {
            perimeterFilter[+EnumPerimeterLevels.REGION] = [$event];
            this.perimeterFilterTowns[+EnumPerimeterLevels.REGION] = [$event];
            this.perimeterFilterCounties[+EnumPerimeterLevels.REGION] = [$event];
        }

        this.perimeterFilter = perimeterFilter;
        this.searchChange$.next();

        this.resetCountiesItems();
        this.appendLevelItems(EnumPerimeterLevels.COUNTY, 1);
        this.resetTownsItems();
        this.appendLevelItems(EnumPerimeterLevels.TOWN, 1);
    }

    countyChanged($event: number): void {
        this.selectedCountytId = this.selectedTownId = null;
        this.selectedCountytId = $event;

        const perimeterFilter = (this.perimeterFilterTowns = {});

        if ($event !== null) {
            perimeterFilter[+EnumPerimeterLevels.COUNTY] = [$event];
            this.perimeterFilterTowns[+EnumPerimeterLevels.COUNTY] = [$event];
        } else if (this.selectedRegionId !== null) {
            perimeterFilter[+EnumPerimeterLevels.REGION] = [this.selectedRegionId];
            this.perimeterFilterTowns[+EnumPerimeterLevels.REGION] = [this.selectedRegionId];
        }

        this.perimeterFilter = perimeterFilter;
        this.searchChange$.next();

        this.resetTownsItems();
        this.appendLevelItems(EnumPerimeterLevels.TOWN, 1);
    }

    townChanged($event: number): void {
        this.selectedTownId = null;
        this.selectedTownId = $event;
        const perimeterFilter = {};

        if ($event !== null) {
            perimeterFilter[+EnumPerimeterLevels.TOWN] = [$event];
        } else if (this.selectedCountytId !== null) {
            perimeterFilter[+EnumPerimeterLevels.COUNTY] = [this.selectedCountytId];
        }

        this.perimeterFilter = perimeterFilter;
        this.searchChange$.next();
    }

    selectedTabChange($event: MatTabChangeEvent): void {
        this.selectedTab = $event.index;
    }

    resetAllSelection(): void {
        if (this.geographicPerimeterSelector) {
            this.geographicPerimeterSelector.removeAllSelection();
        }
        // search patrimoine
        if (this.propertyPerimeterSelector) {
            // search patrimoine > immobilier
            this.propertyPerimeterSelector.removeAllSelection();
        }
        if (this.publicSpacePerimeterSelector) {
            // search patrimoine > espace publique
            this.publicSpacePerimeterSelector.removeAllSelection();
        }
    }

    public closeAllPanel(): void {
        if (this.geographicPerimeterSelector) {
            this.geographicPerimeterSelector.closeAllPanel();
        }
        if (this.propertyPerimeterSelector) {
            // patrimoine > immobilier
            this.propertyPerimeterSelector.closeAllPanel();
        }
        if (this.publicSpacePerimeterSelector) {
            // patrimoine > espace publique
            this.publicSpacePerimeterSelector.closeAllPanel();
        }
    }

    // reset la selection de l'utilisateur a default
    public cancel($event: Event): void {
        $event.stopPropagation();
        if (this.selectedTab === this.indexTabGeography) {
            this.geographicPerimeterSelector.resetDefaultSelection();
        } else if (this.selectedTab === this.indexTabProperty) {
            // search patrimoine
            if (this.estatePanelState) {
                // search patrimoine > immobilier
                this.propertyPerimeterSelector.resetDefaultSelection();
            }
            if (this.publicSpacePanelState) {
                // search patrimoine > espace publique
                this.publicSpacePerimeterSelector.resetDefaultSelection();
            }
        }
    }

    // Check pour désactivé ou activé le bouton appliquer
    defaultSelectionExist(): boolean {
        function isSelectionChanged(array1, array2): boolean {
            const result = array1.filter(
                i =>
                    !array2.some(
                        o => i.itemId === o.itemId && i.perimeterLevel === o.perimeterLevel
                    )
            );
            return result != null && result.length != 0;
        }

        if (this.selectedTab === this.indexTabGeography) {
            return isSelectionChanged(this.geographicSelection, this.geographicDefaultSelection);
        } else if (this.selectedTab === this.indexTabProperty) {
            if (this.estatePanelState) {
                return isSelectionChanged(this.propertySelection, this.propertyDefaultSelection);
            } else if (this.publicSpacePanelState) {
                return isSelectionChanged(
                    this.publicSpaceSelection,
                    this.publicSpaceDefaultSelection
                );
            } else {
                return false;
            }
        }
        return false;
    }

    /////////// tabs bar actions callbacks //////////////////

    searchGeography($event: Event): void {
        $event.preventDefault();
        $event.stopPropagation();
        this.showSearchBox = true;
        this.searchSection = 'géographie';
        this.setAutoFocus();
    }

    searchEstates($event: Event): void {
        $event.preventDefault();
        this.showSearchBox = true;
        this.searchSection = 'patrimoine';
        this.setAutoFocus();
    }

    searchGroups($event: Event): void {
        $event.preventDefault();
        this.showSearchBox = true;
        this.searchSection = 'groupes';
        this.setAutoFocus();
    }

    setAutoFocus(): void {
        // we should have a little timeout, waiting to elment ref to be initialized.
        setTimeout(() => {
            this.searchField.nativeElement.focus();
        });
    }

    hideSearch($event: Event): void {
        if ($event) {
            $event.preventDefault();
            $event.stopPropagation();
        }
        // hide search box
        this.showSearchBox = false;
        // notify the child component
        if (this.selectedTab === this.indexTabGeography) {
            this.geographicPerimeterSelector.stopSearchMode();
        } else if (this.selectedTab === this.indexTabProperty) {
            if (this.propertyPerimeterSelector) {
                this.propertyPerimeterSelector.stopSearchMode();
            }
            if (this.publicSpacePerimeterSelector) {
                this.publicSpacePerimeterSelector.stopSearchMode();
            }
        }
        // reset all keys
        this.searchValue = null;
        this.searchSection = this.geographySearchKey = this.propertySearchKey = this.groupsSearchKey =
            '';
        // reset all search filters
        this.selectedRegionId = this.selectedCountytId = this.selectedTownId = null;
        this.perimeterFilter = this.perimeterFilterRegions = this.perimeterFilterCounties = this.perimeterFilterTowns = {};
    }

    onSearch(searchKey: string): void {
        this.searchValue = searchKey;
        this.searchChange$.next();
    }

    onAfterCollapse($event: Event): void {
        if ($event) {
            $event.preventDefault();
            $event.stopPropagation();
        }

        this.updateSearch();
    }

    applyPerimeter(groupId?: number): void {
        this.applyingPerimeter = true;

        this.updateStoreSelection();

        const activePerimeter = new ActivePerimeterModel();
        activePerimeter.contractId = this.appService.getUserContext().contract.id;

        const DictionaryActivePerimeter = new Dictionary<Array<number>>();

        if (groupId) {
            activePerimeter.groupId = groupId;
            this.groupsService
                .getGroupDetailsById(groupId)
                .pipe(takeUntil(this._onDestroy$))
                .subscribe(res => {
                    if (res) {
                        DictionaryActivePerimeter.Add(
                            res.levelId,
                            this.reducePerimeterToIds(res.perimeters)
                        );
                    } else {
                        DictionaryActivePerimeter.Add(-3, []);
                    }
                });
            activePerimeter.perimeterItemsIds = DictionaryActivePerimeter.Items;
        }

        if (this.selectedTab === this.indexTabGeography) {
            if (this.geographicSelection && this.geographicSelection[0]) {
                DictionaryActivePerimeter.Add(
                    this.geographicSelection[0].perimeterLevel,
                    this.reducePerimeterToIds(this.geographicSelection)
                );
            }
            // else {
            //     DictionaryActivePerimeter.Add(-3, []);
            // }
            activePerimeter.perimeterItemsIds = DictionaryActivePerimeter.Items;
        } else if (this.selectedTab === this.indexTabProperty) {
            if (this.estatePanelState) {
                if (this.propertySelection && this.propertySelection[0]) {
                    DictionaryActivePerimeter.Add(
                        this.propertySelection[0].perimeterLevel,
                        this.reducePerimeterToIds(this.propertySelection)
                    );
                }
                // else {
                //     DictionaryActivePerimeter.Add(1, []);
                // }
            } else if (this.publicSpacePanelState) {
                if (this.publicSpaceSelection && this.publicSpaceSelection[0]) {
                    DictionaryActivePerimeter.Add(
                        this.publicSpaceSelection[0].perimeterLevel,
                        this.reducePerimeterToIds(this.publicSpaceSelection)
                    );
                }
                // else {
                //     DictionaryActivePerimeter.Add(11, []);
                // }
            }
            activePerimeter.perimeterItemsIds = DictionaryActivePerimeter.Items;
        }

        if (!this.inlineMode) {
            this.activePerimeterService.applyActivePerimeter(activePerimeter).subscribe(
                () => {
                    this.dataChange.updatedData();
                    this.applyingPerimeter = false;
                    this.initActivePerimeter();
                    this.closeMenuContainer.emit(true);
                },
                () => {
                    this.toaster.showError(
                        "Un problème est survenu à l'application de votre nouveau périmètre."
                    );
                    this.applyingPerimeter = false;
                }
            );
        }
    }

    getCurrentSelection(): Array<PerimeterItemModel> {
        if (this.selectedTab === this.indexTabGeography) {
            return this.geographicSelection;
        } else if (this.selectedTab === this.indexTabProperty) {
            if (this.estatePanelState) {
                return this.propertySelection;
            }
            if (this.publicSpacePanelState) {
                return this.publicSpaceSelection;
            }
        }
    }

    reducePerimeterToIds(perimeter: Array<PerimeterItemModel>): number[] {
        const ids: Array<number> = [];
        perimeter.map(el => {
            ids.push(el.itemId);
        });
        return ids;
    }

    public _selectionChange(): void {
        this.selectionChange.emit();
    }

    private updateStoreSelection(): void {
        const geoMenuFilterMenu: GeographicMenuFilterState = new GeographicMenuFilterState();
        geoMenuFilterMenu.regionId = this.selectedRegionId;
        geoMenuFilterMenu.departmentId = this.selectedCountytId;
        geoMenuFilterMenu.townId = this.selectedTownId;

        this.store.dispatch(
            setGeoMenuFilterState({ newGeographicMenuFilterState: geoMenuFilterMenu })
        );
    }
}
