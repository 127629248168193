export enum DataControlCategory {
    Invoice = 1,
    // ConsumptionOverrun = 1,
    Sensor = 3,
    MissingData = 4,
    FileImport = 5,
    Consumption = 10
    // SuspectedWaterLeak = 3,
    // PowerOverrun = 5,
    // Objective = 6,
}

export enum DataControlType {
    OverlapInvoice = 1,
    /** Invoice gap */
    MissingInvoice = 2,
    MissingTotalAmount = 3,
    MissingTotalConsumption = 4,
    MissingStartAndEndDates = 5,
    NegativeValues = 6,
    InactiveMeteringPoint = 7,

    /** Sensor */
    Sensor = 8,

    /** Missing data */
    MissingDataInvoice = 9,
    MissingDataReading = 10,
    MissingDataRemoteReading = 11,
    MissingDataSensorMeasure = 12,

    FileImportMeteringPointNotFound = 20,

    InvoiceConsumption = 30,
    ReadingConsumption = 31,
    RemoteReadingConsumption = 32
}

export enum NotificationStatus {
    InProgress = 1,
    Terminated = 2,
    Acknowledged = 3
}

export enum DataControlCriticity {
    Critic = 1,
    Major = 2,
    Minor = 3
}

export enum DataControlPeriodicity {
    Permanent = 1,
    Weekly = 2,
    Calendar = 3,
    Monthly = 4,
    HeatingSeason = 5
}

export enum DataControlValueType {
    SingleValue = 1,
    AverageValue = 2,
    DailyAverageValue = 3,
    MonthlyAverageValue = 4,
    DailyValue = 5,
    MonthlyValue = 6
}

export enum DataControlObservationPeriod {
    _continious = 0,
    // 0xx = xx minutes / 1xx = xx hours / 2xx = xx days / 3xx = xx months
    // _30min=030,
    _1h = 101,
    _2h = 102,
    _4h = 104,
    _12h = 112,
    _24h = 124,
    _1day = 201,
    _2days = 202,
    _3days = 203,
    _4days = 204,
    _5days = 205,
    _6days = 206,
    _7days = 207,
    _30days = 230,
    _1month = 301,
    _2months = 302,
    _3months = 303,
    _4months = 304,
    _5months = 305,
    _6months = 306
}

export enum DataControlRetroactivityPeriod {
    // 0xx = xx minutes / 1xx = xx hours / 2xx = xx days / 3xx = xx months
    _48h = 148,
    _7days = 207
}

export enum Operators {
    Superior = 1,
    Lower = 2,
    Equal = 3,
    Between = 4
}

export enum DataControlConsumptionType {
    Total = 1,
    Use = 2,
    Fluid = 3
}
