import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from '@core/http/app.service';
import {
    transformToActivePerimeterModel,
    ActivePerimeterInitModel,
    ActivePerimeterModel
} from '@core/models/perimeters/active-perimeter.model';
import { PermissionService } from '@core/services/permission.service';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ActivePerimeterService {
    private API_ENDPOINT: string = environment.API_ENDPOINT;
    private ACTIVE_PERIMETER_ENDPOINT = `${this.API_ENDPOINT}v2.3/ActivePerimeters`;

    private curentActivePerimeter: ActivePerimeterModel;
    private emptyActivePerimeter: ActivePerimeterModel;
    private IsOn = true;

    constructor(
        private http: HttpClient,
        private permissionService: PermissionService,
        private appService: AppService
    ) {
        this.emptyActivePerimeter = new ActivePerimeterModel();
        this.emptyActivePerimeter[-3] = [];
        this.emptyActivePerimeter.contractId = this.appService.getUserContext().contract.id;
    }

    get CurentActivePerimeter(): ActivePerimeterModel {
        if (this.IsOn) {
            return this.curentActivePerimeter;
        } else {
            return this.emptyActivePerimeter;
        }
    }

    loadActivePerimeter(): Observable<ActivePerimeterInitModel> {
        const contractId = this.appService.getUserContext().contract.id;
        return this.http
            .get<ActivePerimeterInitModel>(
                `${this.ACTIVE_PERIMETER_ENDPOINT}` + `?contractId=${contractId}`
            )
            .pipe(
                map(res => {
                    if (res) {
                        this.curentActivePerimeter = transformToActivePerimeterModel(res);
                        this.curentActivePerimeter.contractId = this.appService.getUserContext().contract.id;
                    } else {
                        if (!this.curentActivePerimeter) {
                            this.curentActivePerimeter = this.emptyActivePerimeter;
                        }
                    }
                    return res;
                })
            );
    }

    applyActivePerimeter(activePerimeter: ActivePerimeterModel): Observable<number> {
        this.curentActivePerimeter = activePerimeter;
        return this.http.post<number>(`${this.ACTIVE_PERIMETER_ENDPOINT}`, activePerimeter, {
            headers: this.permissionService.getHeader()
        });
    }

    turnOnActivePerimeter(): Observable<number> {
        this.IsOn = true;
        if (!this.curentActivePerimeter) {
            this.curentActivePerimeter = this.emptyActivePerimeter;
        }
        return this.http.post<number>(
            `${this.ACTIVE_PERIMETER_ENDPOINT}`,
            this.curentActivePerimeter,
            {
                headers: this.permissionService.getHeader()
            }
        );
    }

    turnOffActivePerimeter(): Observable<number> {
        this.IsOn = false;
        this.emptyActivePerimeter.contractId = this.appService.getUserContext().contract.id;

        return this.http.post<number>(
            `${this.ACTIVE_PERIMETER_ENDPOINT}`,
            this.emptyActivePerimeter,
            {
                headers: this.permissionService.getHeader()
            }
        );
    }
}
