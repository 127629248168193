<!-- <div class="container">
  <h1>403</h1>
    Vertuoz Tableau de bord est actuellement indisponible pour la maintenance de 13h à 20h
</div> -->

<div id="unavailable-domain" class="row">
  <div class="col-12">
    <img src="assets/img/illust/not-found_dataviz.svg" alt="" class="align-middle me-2" />
  </div>
  <div class="col-12">
    <p class="font-28">
      Vertuoz Tableau de bord est actuellement indisponible.
    </p>
  </div>
</div>
