<section class="delete-modal px-2 pb-3">
  <ng-container *ngIf="!data.unauthorized">
    <h2 class="delete-modal__title font-18 font-weight-bold mb-2" mat-dialog-title>
      Avant de continuer ...
    </h2>
    <mat-dialog-content>
      <div class="delete-modal__text">
        Vous êtes sur le point de supprimer <strong>{{ data.element }}</strong> :
        <ng-container *ngFor="let info of data.elementInfos">
          <p>
            <strong>{{ info }}</strong>
          </p>
        </ng-container>
        {{ data.customMessage }}
      </div>

      <div *ngIf="data.confirmSection" class="bg-gray-100 px-4 pt-3 pb-2 mb-2">
        <div [formGroup]="form">
          <mat-form-field class="w-100">
            <input
              matInput
              placeholder="Saisissez le mot &quot;supprimer&quot; pour confirmer"
              formControlName="name"
            />
          </mat-form-field>
        </div>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions class="justify-content-end">
      <button
        type="button"
        mat-stroked-button
        color="primary"
        class="btn-md"
        (click)="closeModal()"
      >
        {{ 'common.action.cancel' | translate }}
      </button>

      <button
        mat-flat-button
        color="warn"
        class="btn-md ms-4"
        (click)="delete()"
        [disabled]="data.confirmSection && form.get('name').value !== 'supprimer'"
      >
        Supprimer
      </button>
    </mat-dialog-actions>
  </ng-container>

  <ng-container *ngIf="data.unauthorized">
    <h2 class="delete-modal__title font-18 font-weight-bold mb-2" mat-dialog-title>
      Suppression non autorisée.
    </h2>
    <mat-dialog-content>
      <p class="delete-modal__text">{{ data.unauthorizedMessage }}</p>
    </mat-dialog-content>

    <mat-dialog-actions class="justify-content-end">
      <button type="button" mat-flat-button color="primary" class="btn-md" (click)="closeModal()">
        Ok
      </button>
    </mat-dialog-actions>
  </ng-container>
</section>
