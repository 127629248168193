<section class="delete-modal px-2 pb-3">
  <h2 class="delete-modal__title font-18 font-weight-bold mb-2" mat-dialog-title>
    {{ data.title }}
  </h2>

  <mat-dialog-content>
    <p *ngIf="data.announcement" class="delete-modal__text">
      {{ data.announcement }} <strong>{{ data.element }}</strong> :
      <ng-container *ngFor="let info of data.elementInfos">
        <p class="pt-2">
          <strong>{{ info }}</strong>
        </p>
      </ng-container>
    </p>
    <p>
      <mat-icon class="me-2" ngClass="warningIcon">warning</mat-icon>
      {{ data.customMessage }}
    </p>
  </mat-dialog-content>

  <mat-dialog-actions class="justify-content-end">
    <button
      type="button"
      mat-flat-button
      color="primary"
      class="btn-update-modal btn-md bg-none"
      (click)="closeModal()"
    >
      {{ data.labelCancelModalButton }}
    </button>

    <button
      mat-flat-button
      class="btn-update-modal btn-md bg-error ms-4"
      type="submit"
      (click)="update()"
    >
      {{ data.labelApplyModalButton }}
    </button>
  </mat-dialog-actions>
</section>
