<form [formGroup]="myInfosForm" class="profile__formInfos px-2">
  <div class="row mb-3">
    <div class="col-12 align-self-center">
      <h2 class="profile__titlelv2 font-12 pt-2 m-0 mb-2">Informations personnelles</h2>
    </div>
  </div>
  <div class="row row-cols-2">
    <div class="col-8 mb-3">
      <p class="m-0">
        <span class="me-4">Civilité *</span>
        <mat-radio-group formControlName="civilityCtrl" required>
          <mat-radio-button *ngFor="let civility of civilityList" [value]="civility">
            {{ civility }}
          </mat-radio-button>
        </mat-radio-group>
      </p>
    </div>
    <div class="col">
      <mat-form-field class="w-100">
        <input formControlName="lastNameCtrl" matInput placeholder="Nom *" />
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field class="w-100">
        <input formControlName="firstNameCtrl" matInput placeholder="Prénom *" />
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field class="w-100">
        <input formControlName="phoneNumberCtrl" matInput placeholder="Téléphone" />
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field class="w-100">
        <input formControlName="functionCtrl" matInput placeholder="Fonction" />
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field class="w-100">
        <input formControlName="entityCtrl" matInput placeholder="Entité" />
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field class="w-100">
        <mat-select placeholder="Secteur" formControlName="sectorCtrl" required>
          <mat-option *ngFor="let sector of organizationSectors" [value]="sector.id">
            {{ sector.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="text-end pt-3">
    <button type="button" mat-stroked-button color="primary" class="btn-sm" (click)="cancelForm()">
      {{ 'common.action.cancel' | translate }}
    </button>
    <button
      mat-flat-button
      color="primary"
      class="btn-sm ms-3"
      [disabled]="myInfosForm.invalid"
      (click)="saveUserInfos()"
    >
      Enregistrer
    </button>
  </div>
</form>
