import { Directive, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

export interface IAutoCompleteScrollEvent {
    autoComplete: MatAutocomplete;
    scrollEvent: Event;
}

@Directive({
    selector: 'mat-autocomplete[optionsScroll]'
})
export class OptionsScrollDirective implements OnDestroy {
    @Input() thresholdPercent = 0.99;
    // eslint-disable-next-line @angular-eslint/no-output-rename, @angular-eslint/no-output-native
    @Output('optionsScroll') scroll = new EventEmitter<IAutoCompleteScrollEvent>();
    _onDestroy = new Subject<void>();

    constructor(public autoComplete: MatAutocomplete) {
        this.autoComplete.opened
            .pipe(
                tap(() => {
                    /** Note: When autocomplete raises opened, panel is not yet created (by Overlay)
            Note: The panel will be available on next tick
            Note: The panel wil NOT open if there are no options to display
        */
                    setTimeout(() => {
                        /** Note: remove listner just for safety, in case the close event is skipped. */
                        this.removeScrollEventListener();
                        this.autoComplete.panel.nativeElement.addEventListener(
                            'scroll',
                            this.onScroll.bind(this)
                        );
                    });
                }),
                takeUntil(this._onDestroy)
            )
            .subscribe();

        this.autoComplete.closed
            .pipe(
                tap(() => this.removeScrollEventListener()),
                takeUntil(this._onDestroy)
            )
            .subscribe();
    }

    private removeScrollEventListener(): void {
        if (this.autoComplete && this.autoComplete.panel && this.autoComplete.panel.nativeElement) {
            this.autoComplete.panel.nativeElement.removeEventListener('scroll', this.onScroll);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onScroll(event: any): void {
        if (this.thresholdPercent === undefined) {
            this.scroll.next({ autoComplete: this.autoComplete, scrollEvent: event });
        } else {
            const threshold = (this.thresholdPercent * 100 * event.target.scrollHeight) / 100;
            const current = Math.ceil(event.target.scrollTop + event.target.clientHeight);
            if (current >= threshold) {
                this.scroll.next({ autoComplete: this.autoComplete, scrollEvent: event });
            }
        }
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
        this.removeScrollEventListener();
    }
}
