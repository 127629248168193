//#region
import { Directive, EmbeddedViewRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { AppService } from '@app/core/http/app.service';
import { PermissionModel } from '@app/core/models/role/permission.model';
import { RoleModel } from '@app/core/models/role/role.model';
//#endregion

//#region Types
export class HasPermissionsContext {
    public operator = null;
    public condition = null;
    public $implicit = null;
    public hasPermissions = null;
}
//#endregion

@Directive({
    selector: '[hasPermissions]'
})
export class HasPermissionsDirective {
    //#region Fields
    private userRole: RoleModel;
    private userRolePermissions: PermissionModel[];
    // tslint:disable-next-line: no-use-before-declare
    private _context: HasPermissionsContext = new HasPermissionsContext();
    private _thenTemplateRef: TemplateRef<HasPermissionsContext> | null = null;
    private _elseTemplateRef: TemplateRef<HasPermissionsContext> | null = null;
    private _thenViewRef: EmbeddedViewRef<HasPermissionsContext> | null = null;
    private _elseViewRef: EmbeddedViewRef<HasPermissionsContext> | null = null;
    //#endregion

    //#region Constructor
    constructor(
        private appService: AppService,
        private _viewContainer: ViewContainerRef,
        templateRef: TemplateRef<HasPermissionsContext>
    ) {
        this._thenTemplateRef = templateRef;
    }
    //#endregion

    //#region Methods
    @Input()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    set hasPermissions(condition: any) {
        console.debug('HasPermissionsDirective : hasPermissions : condition : ' + condition);

        console.debug(
            'HasPermissionsDirective : hasPermissions : hasPermissionsOperator : ' +
                this._context.operator
        );

        this._context.condition = condition;

        if ((this._context.condition as string[]).length > 1) {
            this._context.operator = 'OR';
        }

        this._updateView();
    }

    @Input()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    set hasPermissionsOperator(op: any) {
        console.debug('HasPermissionsDirective : hasPermissionsOperation : ' + op);

        this._context.operator = op;
        this._updateView();
    }

    @Input()
    set hasPermissionsThen(templateRef: TemplateRef<HasPermissionsContext>) {
        this._thenTemplateRef = templateRef;
        this._thenViewRef = null; // clear previous view if any.
        this._updateView();
    }

    @Input()
    set hasPermissionsElse(templateRef: TemplateRef<HasPermissionsContext>) {
        this._elseTemplateRef = templateRef;
        this._elseViewRef = null; // clear previous view if any.
        this._updateView();
    }

    private checkPermissions(): boolean {
        this.userRole = this.appService.getUserContext().role;
        this.userRolePermissions = this.userRole.permissions;

        console.debug(
            'HasPermissionsDirective : hasPermissions : userRole : ' + JSON.stringify(this.userRole)
        );

        console.debug(
            'HasPermissionsDirective : hasPermissions : condition : ' +
                JSON.stringify(this.userRolePermissions)
        );

        if (this._context.condition && (this._context.condition as string[]).length > 0) {
            switch (this._context.operator) {
                case 'OR':
                    return (this._context.$implicit = this._context.hasPermissions = this._context.condition.some(
                        perm => this.userRolePermissions.some(urp => urp.permissionType === perm)
                    ));
                case 'AND':
                    return (this._context.$implicit = this._context.hasPermissions = this._context.condition.every(
                        perm => this.userRolePermissions.some(urp => urp.permissionType === perm)
                    ));
                default:
                    return (this._context.$implicit = this._context.hasPermissions = this._context.condition.every(
                        perm => this.userRolePermissions.some(urp => urp.permissionType === perm)
                    ));
            }
        }

        return true;
    }

    private _updateView(): void {
        if (this.checkPermissions()) {
            if (!this._thenViewRef) {
                this._viewContainer.clear();
                this._elseViewRef = null;
                if (this._thenTemplateRef) {
                    this._thenViewRef = this._viewContainer.createEmbeddedView(
                        this._thenTemplateRef,
                        this._context
                    );
                }
            }
        } else {
            if (!this._elseViewRef) {
                this._viewContainer.clear();
                this._thenViewRef = null;
                if (this._elseTemplateRef) {
                    this._elseViewRef = this._viewContainer.createEmbeddedView(
                        this._elseTemplateRef,
                        this._context
                    );
                }
            }
        }
    }
    //#endregion
}
