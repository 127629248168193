import { KeyValue } from '@angular/common';

export enum ProductionTypeEnum {
    ENR = 1,
    notENR = 2
}

export const ProductionTypeRSX: Array<KeyValue<ProductionTypeEnum, string>> = [
    { key: ProductionTypeEnum.ENR, value: 'aggregate-production.enr-production' },
    { key: ProductionTypeEnum.notENR, value: 'aggregate-production.not-enr-production' }
];
