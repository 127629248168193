import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'vertuoz-iconographic-message',
    templateUrl: './iconographic-message.component.html',
    styleUrls: ['./iconographic-message.component.scss']
})
export class IconographicMessageComponent implements OnInit {
    @Input() icon: string;

    constructor() {}

    ngOnInit(): void {}
}
