import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'email-settings',
    templateUrl: './email-settings.component.html',
    styleUrls: ['./email-settings.component.scss']
})
export class EmailSettingsComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
