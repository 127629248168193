import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EnumPermissions } from '@app/core/models/permissions/permissions.enum';
import { AccessMode } from '@app/features/perimeter/common/access-mode.enum';

export interface DialogMessage {
    showAlertImpactUsers: boolean;
    customMessage: boolean;
    confirmSection: boolean;
    element: string;
    elementInfos: string[];
    announcement: string;
    labelCancelModalButton: string;
    labelApplyModalButton: string;
    title: string;
}

@Component({
    selector: 'vertuoz-update-modal',
    templateUrl: './vertuoz-update-modal.component.html',
    styleUrls: ['./vertuoz-update-modal.component.scss']
})
export class VertuozUpdateModalComponent implements OnInit {
    form: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<VertuozUpdateModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogMessage,
        private fb: FormBuilder
    ) {}

    ngOnInit(): void {
        this.form = this.fb.group({
            name: [name, []]
        });
    }

    // When the user clicks the action button a.k.a. the update button in the\
    // modal, pass update event followed by the closing of the modal
    update(): void {
        this.dialogRef.close(true);
    }

    // If the user clicks the cancel button a.k.a. the go back button, then\
    // just close the modal
    closeModal(): void {
        this.dialogRef.close(false);
    }
}

@Component({
    selector: 'vertuoz-update',
    template: `
        <button
            mat-flat-button
            type="button"
            color="primary"
            class="btn-md ms-4"
            [disabled]="!isDescriptionValid"
            (click)="onClick(disableOpenModal)"
        >
            {{ labelUpdateButton }}
        </button>
    `,
    styleUrls: ['vertuoz-update.component.scss']
})
export class VertuozUpdateComponent {
    @Input() disableClose = false;
    @Input() confirmSection = false;
    @Input() id: string;
    @Input() width = '600px';
    @Input() height = 'auto';
    @Input() showAlertImpactUsers = true;
    @Input() element;
    @Input() elementInfos: string[];
    // @Input() toolTipMessage = 'common.action.update';
    @Input() toolTipMessage = 'Modifier';
    @Input() title: string;
    @Input() customMessage: string;
    @Input() isDescriptionValid: boolean;
    @Input() disableOpenModal: boolean;
    @Input() labelUpdateButton: string;
    @Input() labelCancelModalButton: string;
    @Input() labelApplyModalButton: string;
    @Input() announcement: string;

    @Output() update = new EventEmitter<boolean>();

    public EnumPermissions = EnumPermissions;
    public AccessMode = AccessMode;

    constructor(public dialog: MatDialog) {}

    onClick(disableOpenModal: boolean): void {
        if (!disableOpenModal) {
            this.openDialog();
        } // Si l'affichage de la modale n'est pas activé, on envoit directment l'évènement.
        else {
            this.update.emit(true);
        }
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(VertuozUpdateModalComponent, {
            width: this.width,
            height: this.height,
            disableClose: this.disableClose,
            id: this.id,
            data: {
                showAlertImpactUsers: this.showAlertImpactUsers,
                customMessage: this.customMessage,
                confirmSection: this.confirmSection,
                element: this.element,
                elementInfos: this.elementInfos,
                announcement: this.announcement,
                labelCancelModalButton: this.labelCancelModalButton,
                labelApplyModalButton: this.labelApplyModalButton,
                title: this.title
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.update.emit(true);
            }
        });
    }
}
