import { PerimeterScope } from '@app/shared/constants/filters.enum';
import { createAction, props } from '@ngrx/store';

export enum PerimeterActionsTypes {
    TOGGLE_PERIMETER = '[Perimeter State] toggle',
    SET_PERIMETER = '[Perimeter State] set'
}

export const togglePerimeter = createAction(PerimeterActionsTypes.TOGGLE_PERIMETER);
export const setPerimeterScope = createAction(
    PerimeterActionsTypes.SET_PERIMETER,
    props<{ newPerimeterScope: PerimeterScope }>()
);
