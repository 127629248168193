<custom-dialog
  [originX]="originX"
  [originY]="originY"
  [useOverlay]="true"
  [overlayX]="overlayX"
  [overlayY]="overlayY"
  [closeOnScroll]="false"
  (clickOut)="onCustomDialogClickOut()"
  (togglePanel)="onCustomDialogTogglePanel($event)"
>
  <mat-icon-button-content matTooltip="{{ tooltip || '' }}">
    <mat-icon mat-icon-button [svgIcon]="icon"></mat-icon>
  </mat-icon-button-content>

  <dialog-content *ngIf="isPanelShowed">
    <form [formGroup]="selectionGroupsFormGroup" (ngSubmit)="submit()">
      <h2 class="font-18">{{ tooltip || '' }}</h2>
      <div>
        <div #scrollDiv class="list-container list-group scroll-me" formArrayName="groups" autosize>
          <div
            *ngFor="
              let group of selectionGroupsFormGroup.controls.groups['controls'];
              let i = index
            "
            class="list-group-item px-0"
            [style.height.px]=""
          >
            <mat-checkbox class="example-margin" [formControlName]="i">
              {{ groups[i]?.name }}
            </mat-checkbox>
          </div>
        </div>
      </div>
    </form>
  </dialog-content>
  <dialog-buttons *ngIf="isPanelShowed">
    <div class="dialog__btn text-end pt-2">
      <button mat-stroked-button class="btn-sm" color="primary" (click)="closePanel()">
        {{ 'common.action.cancel' | translate }}
      </button>
      <button
        type="button"
        mat-flat-button
        class="btn-sm ms-3"
        color="primary"
        (click)="submit()"
        [disabled]="selectionGroupsFormGroup.invalid || groups.length === 0"
      >
        {{ actionLabel }}
      </button>
    </div>
  </dialog-buttons>
</custom-dialog>
