import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { takeLast } from 'rxjs/operators';

import { AppService } from '@app/core/http/app.service';
import { DomainModel } from '@app/core/models/domain/domain.model';
import { GroupFilter } from '@app/core/models/perimeters/group/group.filter';
import { PagedContext } from '@app/features/shared/models/paged-context';
import { DomainEnum } from '@app/shared/constants/domain.enum';
import { GroupsService } from '@core/http/perimeter/groups.service';
import { GroupPerimeterModel } from '@core/models/perimeters/group/group-perimeter.model';
import { PerimeterItemBaseModel } from '@core/models/perimeters/perimeter-item-base.model';
import { ToolsService } from '@features/shared/services/tools.service';

@Component({
    selector: 'groups-tab',
    templateUrl: './groups-tab.component.html',
    styleUrls: ['./groups-tab.component.scss']
})
export class GroupsTabComponent implements OnInit, OnChanges {
    //#region intput / outpu / viewChild
    @Input() activatedGroupId: number;
    @Input() searchKey = '';
    /**
     * apply group with id
     */
    @Output() applyGroupPerimeter = new EventEmitter<number>();

    @ViewChild('grid') groupsGrid: ElementRef;
    //#endregion

    //#region fields
    public gridView: GridDataResult;
    public pageSize = 5;
    public skip = 0;
    public isLoading = false;

    throttle = 100;
    scrollDistance = 1;
    scrollWindow = false;
    virtualScrollPage = 1;
    currentGroup: GroupPerimeterModel;
    currentGroupPerimeters: PerimeterItemBaseModel[] = [];
    groupDetailsHeight = 90;

    public groupFilter: GroupFilter;
    public pagedContext: PagedContext;

    private items = [];
    public userDomains: Array<DomainModel>;
    public selectedValue = -1;
    //#endregion

    //#region contructor / lifecycle hook
    constructor(
        private groupsService: GroupsService,
        private appService: AppService,
        private toolsService: ToolsService,
        private translate: TranslateService
    ) {
        this.setupView();
    }

    ngOnInit(): void {
        this.loadGroups();
        this.userDomains = this.appService.getUserContext().role.domains;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.searchKey && !changes.searchKey.firstChange) {
            if (changes.searchKey.currentValue) {
                this.collapseAll(this.groupsGrid);
                this.groupFilter.filterByGroupTerm = changes.searchKey.currentValue;
            } else {
                this.groupFilter.filterByGroupTerm = null;
            }
            // reset pagination
            this.skip = 0;
            this.loadGroups();
        }
    }

    //#endregion

    //region methods
    private setupView(): void {
        this.isLoading = false;

        this.groupFilter = <GroupFilter>{
            filterByGroupTerm: '',
            filterbyLevelId: null,
            filterByDomainIds: null
        };
    }

    private loadGroups(): void {
        this.isLoading = true;
        this.groupsService
            .getGroups('', this.groupFilter, this.pagedContext)
            .pipe(takeLast(1))
            .subscribe(result => {
                this.items = result
                    ? this.activatedGroupId
                        ? result.results.sort((a): number =>
                              a.groupPerimeterId === this.activatedGroupId ? -1 : 1
                          )
                        : result.results
                    : [];
                this.loadItems();
                this.isLoading = false;
            });
    }

    perimeterDomainFilterChanged(filterByDomainIds: DomainEnum | -1): void {
        this.groupFilter.filterByDomainIds = null;
        if (filterByDomainIds && filterByDomainIds !== -1) {
            this.groupFilter.filterByDomainIds = [filterByDomainIds];
        }
        // reset pagination
        this.skip = 0;
        this.loadGroups();
    }

    public pageChange(event: PageChangeEvent): void {
        this.skip = event.skip;
        this.loadItems();
    }

    private loadItems(): void {
        this.gridView = {
            data: this.items.slice(this.skip, this.skip + this.pageSize),
            total: this.items.length
        };
    }

    private setGroupDisplay(perimeters: PerimeterItemBaseModel[]): void {
        const tmp = [...perimeters];
        this.currentGroupPerimeters = tmp.splice(0, 9);
        if (this.currentGroupPerimeters.length <= 3) {
            this.groupDetailsHeight = 20;
        } else if (
            this.currentGroupPerimeters.length > 3 &&
            this.currentGroupPerimeters.length <= 6
        ) {
            this.groupDetailsHeight = 65;
        } else {
            this.groupDetailsHeight = 95;
        }
    }

    public onPanelOpened(groupId: number): void {
        this.getGroupDetailsById(groupId);
        this.virtualScrollPage = 1;
    }

    public getGroupDetailsById(id: number): void {
        this.groupsService.getGroupDetailsById(id).subscribe(result => {
            this.currentGroup = result;
            if (result.perimeters.length) {
                result.perimeters.sort((a, b) => a.itemLabel.localeCompare(b.itemLabel));
                this.setGroupDisplay(result.perimeters);
            }
        });
    }

    // collapse all details rows (to display only current one)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public collapseAll(topGrid: any): void {
        this.gridView.data.forEach((item, idx) => {
            topGrid.collapseRow(idx);
        });
    }

    public appendItems(): void {
        let currentGroupPerimetersPage = this.currentGroup.perimeters;
        currentGroupPerimetersPage = currentGroupPerimetersPage.slice(
            this.virtualScrollPage * 9,
            this.virtualScrollPage * 9 + 9
        );
        this.currentGroupPerimeters = this.currentGroupPerimeters.concat(
            currentGroupPerimetersPage
        );
        this.virtualScrollPage++;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    applyGroup($event: any): void {
        this.applyGroupPerimeter.emit($event.dataItem.groupPerimeterId);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getGroupItemsCount(dataItem: any): string {
        return (
            dataItem.count +
            ' ' +
            this.translate.instant(
                'geoMenu.groups.' +
                    this.toolsService.parseLevelToEnumGeographicLevel(dataItem.levelId),
                { count: dataItem.count }
            )
        );
    }
    //#endregion
}
