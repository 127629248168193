import { DomainEnum } from '@app/shared/constants/domain.enum';

export class GroupFilter {
    groupExactName?: string;
    filterByUserId?: number[];
    filterByGroupTerm?: string;
    filterbyLevelId?: number;
    filterByDomainIds?: DomainEnum[];
    FilterByPerimeterId?: number;
    groupPerimeterIds?: number[];
    hasObjective?: boolean;
    isSubjectToTertiaryDecree?: boolean;

    constructor(init: Partial<GroupFilter>) {
        Object.assign(this, init);
    }
}
