import { GeographicMenuModuleState } from '@app/core/store/state/geographic-menu/geographic-menu-module-state.interface';
import {
    resetGeoMenuModuleState,
    setGeoMenuFilterState
} from '@app/core/store/state/geographic-menu/geographic-menu.action';
import { createReducer, on } from '@ngrx/store';

export const initialState = new GeographicMenuModuleState(); // le State est READONLY

export const geographicMenuModuleReducer = createReducer(
    initialState,
    on(
        setGeoMenuFilterState,
        (state, { newGeographicMenuFilterState }) =>
            <GeographicMenuModuleState>{
                filter: newGeographicMenuFilterState
            }
    ),
    on(resetGeoMenuModuleState, () => null)
);
