import { AppService } from '@app/core/http/app.service';
import { PAGER_TYPE, PAGE_SIZES } from '@app/features/shared/models/paged-context';
import { PagerSettings, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, State } from '@progress/kendo-data-query';

export class GridConfigurationDefault<T extends PredicateDto> {
    public sort: SortDescriptor[];
    public state: State;
    public pagerSettings: PagerSettings;
    predicateDto: T;
    constructor(fieldName: string, beginWithFive: boolean = false) {
        this.initProperties(fieldName, beginWithFive);
    }
    private initProperties(fieldName: string, beginWithFive: boolean): void {
        this.state = {
            skip: 0,
            take: AppService.CUSTOM_PAGE_SIZE
        };
        this.sort = [
            {
                field: fieldName,
                dir: 'asc'
            }
        ];
        this.pagerSettings = {
            pageSizes: PAGE_SIZES.map(p => p),
            buttonCount: this.state.take,
            info: true,
            type: PAGER_TYPE,
            previousNext: true
        };
        this.predicateDto = <T>{
            page: this.state.skip,
            pageSize: this.state.take,
            activePerimeter: true,
            sort: false,
            sortField: this.sort[0].field
        };
    }
}
export abstract class GridConfiguration<T extends PredicateDto> extends GridConfigurationDefault<
    T
> {
    protected constructor(fieldName: string, beginWithFive: boolean = false) {
        super(fieldName, beginWithFive);
    }
    protected abstract loadData(): void;

    // isAlert a supprimer après la standardisation de mécanisme de traitement des tri coté back
    public sortChange(sort: SortDescriptor[], isAlert: boolean = false): void {
        this.predicateDto.sortField = sort[0].field;
        this.predicateDto.sort = isAlert
            ? this.predicateDto.sortField !== ''
            : sort[0].dir === 'desc';
        this.predicateDto.sortDesc = sort[0].dir === 'desc';
        this.sort = sort;
        this.loadData();
    }
    /**
     * Action exécuter à chaque changement de page
     * @param param0
     */
    public pageChange({ skip, take }: PageChangeEvent): void {
        this.state.skip = skip;
        this.state.take = take;
        this.predicateDto.page = this.state.skip;
        this.predicateDto.pageSize = this.state.take;
        this.loadData();
    }
}

export class PredicateDto {
    page: number;
    pageSize: number;
    activePerimeter?: boolean;
    sort: boolean;
    sortDesc: boolean;
    sortField: string;
    missingOccupant?: boolean;
    missingSurface?: boolean;
    missingGroupe?: boolean;
    missingLocalization?: boolean;
    missingConservationTemperature?: boolean;
    CategoryId?: number;
    deviceIds: number[];
}
