import { Injectable } from '@angular/core';

const whiteSnow = require('@shared/styles/ds-lib/themes/white-snow.scss');
const primaryBlue = require('@shared/styles/ds-lib/themes/primary-blue.scss');
const blueAlt = require('@shared/styles/ds-lib/themes/blue-alt.scss');
const bigStone = require('@shared/styles/ds-lib/themes/big-stone.scss');
const bigStoneLight = require('@shared/styles/ds-lib/themes/big-stone-light.scss');
const yellowSpark = require('@shared/styles/ds-lib/themes/yellow-spark.scss');
const gradient = require('@shared/styles/ds-lib/themes/gradient.scss');

@Injectable()
export class ThemesService {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    styleTag: any;
    defaultTheme: string;
    currentTheme: string;

    constructor() {
        this.createStyle();
    }

    private createStyle(): void {
        const head = document.head || document.getElementsByTagName('head')[0];
        this.styleTag = document.createElement('style');
        this.styleTag.type = 'text/css';
        this.styleTag.id = 'appthemes';
        this.styleTag.nonce = '6391DC36524F4A9FB2EC608AB77F8DB7';
        head.appendChild(this.styleTag);
    }

    setTheme(name: string): void {
        switch (name) {
            case 'blueAlt':
                this.injectStylesheet(blueAlt);
                break;
            case 'whiteSnow':
                this.injectStylesheet(whiteSnow);
                break;
            case 'primaryBlue':
                this.injectStylesheet(primaryBlue);
                break;
            case 'bigStone':
                this.injectStylesheet(bigStone);
                break;
            case 'bigStoneLight':
                this.injectStylesheet(bigStoneLight);
                break;
            case 'yellowSpark':
                this.injectStylesheet(yellowSpark);
                break;
            case 'gradient':
                this.injectStylesheet(gradient);
                break;
        }
        this.currentTheme = name;
    }

    // since v9, content is available in 'default'
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    injectStylesheet(css: any): void {
        if (css.default) {
            this.styleTag.innerHTML = css.default;
        } else {
            this.styleTag.innerHTML = css;
        }
    }

    getDefaultTheme(): string {
        return this.defaultTheme;
    }

    getCurrentTheme(): string {
        return this.currentTheme;
    }
}
