import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SafeResourceUrl } from '@angular/platform-browser';
import { DialogReleaseNotesData } from '../dialog-data.model';

@Component({
    selector: 'vertuoz-release-notes',
    templateUrl: './release-notes.component.html',
    styleUrls: ['./release-notes.component.scss']
})
export class ReleaseNotesComponent implements OnInit {
    @Input() htmlUri: SafeResourceUrl;

    constructor(
        public dialogRef: MatDialogRef<ReleaseNotesComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogReleaseNotesData
    ) {
        if (data && data.htmlUri) {
            this.htmlUri = data.htmlUri;
        }
    }

    ngOnInit(): void {}

    close(): void {
        this.dialogRef.close();
    }
}
