import { KeyValue } from '@angular/common';

export enum ComparisonYear {
    year1 = 1,
    year2 = 2,
    avg3 = 3,
    refYear = 4
}

export enum YearComparisonEnum {
    Minus1 = -1,
    Minus2 = -2,
    Avg3 = -3
}

export const ComparisonYearRSX: Array<KeyValue<ComparisonYear, string>> = [
    { key: ComparisonYear.year1, value: 'Année - 1' },
    { key: ComparisonYear.year2, value: 'Année - 2' },
    { key: ComparisonYear.avg3, value: 'Moyenne des 3 dernières années' },
    { key: ComparisonYear.refYear, value: 'Année de référence' }
];
