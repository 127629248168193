import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { _countGroupLabelsBeforeOption, MatOption } from '@angular/material/core';
import { MatSelect, MatSelectChange } from '@angular/material/select';

import * as _ from 'lodash';

import { CustomSectorModel } from '@app/core/models/customSector/custom-sector.model';
import { EquipmentWriteModel } from '@app/core/models/equipment/equipment-write.model';
import { MeteringPointLabelModel } from '@app/core/models/equipment/meteringpoint-label.model';
import { FluidUseEnum } from '@app/features/consumption-monitoring/pages/fluids-water-view/model/endpoint-models';
import { ValueLabelModel } from '@app/features/property-data/models/comparison.model';
import { ComparisonYear, ComparisonYearRSX } from '@app/shared/constants/comparison-year.enum';
import { ProductionTypeEnum, ProductionTypeRSX } from '@app/shared/constants/production-type.enum';
import { ProductionUseEnum, ProductionUseRSX } from '@app/shared/constants/production-use.enum';
import { SensEnum, SensRSX } from '@app/shared/constants/sens.enum';

export class ChartFilters {
    public comparisonYear: number;
    public fluidUseIds: Array<number>;
    public fluidUsageConsIds: Array<number>;
    public fluidProdIds: Array<number>;
    public customSectorIds: number[];
    public activitySectorId: number;
    public hasFluidUseSelection: boolean;
    public isYearComparisonChanged: boolean;
    public equipments: EquipmentWriteModel[];
    public sensIds: Array<SensEnum>;
    public productionTypeIds: Array<ProductionTypeEnum>;
    public productionUseIds: Array<ProductionUseEnum>;
}

@Component({
    selector: 'chart-filters',
    templateUrl: './vertuoz-chart-filters.component.html',
    styleUrls: ['./vertuoz-chart-filters.component.scss']
})
export class VertuozChartFiltersComponent implements OnInit, OnChanges {
    @Input() public hideComparisonYear = false;
    @Input() public hideCustomSectors = false;
    @Input() public hideActivitySectors = false;
    @Input() public hideFluidUse = false;
    @Input() public hideFluidProdLst = true;
    @Input() public hideFluidUseConsLst = true;
    @Input() public hideEquipments = true;
    @Input() public hideSens = true;
    @Input() public hideProductionType = true;
    @Input() public hideProductionUse = true;

    @Input() public overridenFluid: string = null;
    @Input() public overridenAllFluids: string = null;

    @Input() public fluidUse: FluidUseEnum;
    @Input() public fluidUseLst: Array<ValueLabelModel>;
    @Input() public fluidUsageConsLst: Array<ValueLabelModel>;
    @Input() public fluidProdLst: Array<ValueLabelModel>;
    @Input() public activitySectors: Array<CustomSectorModel>;
    @Input() public customSectors: Array<CustomSectorModel>;
    @Input() public equipmentList: Array<EquipmentWriteModel>;
    @Input() public sensSelection: Array<number>;

    @Input() public defaultFluidUsageIds: Array<number>;
    @Input() public defaultCustomSectorIds: Array<number>;
    @Input() public defaultActivitySectorId: number;

    @Input() public defaultComparisonYear: number;
    @Input() public comparisonYears: Array<ComparisonYear>;
    @Input() public referenceYears: Array<number> = [];

    @Output() public selectionChange: EventEmitter<ChartFilters> = new EventEmitter<ChartFilters>();

    public FluidUseEnum = FluidUseEnum;

    public chartFiltersForm: FormGroup;

    public selectedComparison: number;

    //#region local properties
    public sensList = [
        {
            value: SensEnum.Consumption,
            label: SensRSX.find(s => s.key === SensEnum.Consumption).value
        },
        {
            value: SensEnum.Production,
            label: SensRSX.find(s => s.key === SensEnum.Production).value
        }
    ];

    public productionTypeList = [
        {
            value: ProductionTypeEnum.ENR,
            label: ProductionTypeRSX.find(p => p.key === ProductionTypeEnum.ENR).value
        },
        {
            value: ProductionTypeEnum.notENR,
            label: ProductionTypeRSX.find(p => p.key === ProductionTypeEnum.notENR).value
        }
    ];

    public productionUseList = [
        {
            value: ProductionUseEnum.Autoconsumption,
            label: ProductionUseRSX.find(p => p.key === ProductionUseEnum.Autoconsumption).value
        },
        {
            value: ProductionUseEnum.Resale,
            label: ProductionUseRSX.find(p => p.key === ProductionUseEnum.Resale).value
        }
    ];
    public selectedEquipmentsLabel = '';
    public selectedCustomSectorsLabel = '';
    public selectedFluidsLabel = '';
    public selectedFluidsConsLabel = '';
    public selectedFluidsProdLabel = '';

    public allIndex = -1;
    public allCustomSectors = <CustomSectorModel>{
        id: this.allIndex,
        label: 'Tous les secteurs personnalisés'
    };
    //#endregion

    constructor(private fb: FormBuilder) {}

    ngOnInit(): void {
        this.initForm();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && this.chartFiltersForm) {
            if (changes.fluidUseLst) {
                this.updateMultipleSelection(
                    'fluids',
                    'fluidUseLst',
                    'defaultFluidUsageIds',
                    changes
                );
                this.selectedFluidsLabel = this.getSelectedFluidsLabel(
                    'fluids',
                    changes['fluidUseLst'].currentValue
                );
            }

            if (
                changes.defaultFluidUsageIds &&
                changes.defaultFluidUsageIds.currentValue &&
                changes.defaultFluidUsageIds.currentValue.length > 0
            ) {
                this.chartFiltersForm.controls['fluids'].patchValue(
                    changes['defaultFluidUsageIds'].currentValue
                );
            }

            if (changes.fluidUsageConsLst) {
                this.updateMultipleSelection('fluidUsageCons', 'fluidUsageConsLst', null, changes);
                this.selectedFluidsConsLabel = this.getSelectedFluidsLabel(
                    'fluidUsageCons',
                    changes['fluidUsageConsLst'].currentValue
                );
            }

            if (changes.fluidProdLst) {
                this.updateMultipleSelection('fluidProd', 'fluidProdLst', null, changes);
                this.selectedFluidsProdLabel = this.getSelectedFluidsLabel(
                    'fluidProd',
                    changes['fluidProdLst'].currentValue
                );
            }

            if (changes.customSectors) {
                this.updateMultipleSelection(
                    'customSectorIds',
                    'customSectors',
                    'defaultCustomSectorIds',
                    changes
                );
                this.selectedCustomSectorsLabel = this.getSelectedCustomSectorsLabel();
            }

            if (
                changes.defaultCustomSectorIds &&
                changes.defaultCustomSectorIds.currentValue &&
                changes.defaultCustomSectorIds.currentValue.length > 0
            ) {
                this.chartFiltersForm.controls['customSectorIds'].patchValue(
                    changes['defaultCustomSectorIds'].currentValue
                );
            }

            if (changes.activitySectors) {
                if (!this.activitySectors.find(s => s.id === this.defaultActivitySectorId)) {
                    this.chartFiltersForm.patchValue({ activitySector: null });
                }
            }

            if (changes.referenceYears) {
                const index = this.comparisonYears.findIndex(y => y === ComparisonYear.refYear);
                if (this.referenceYears && this.referenceYears.length >= 1 && index === -1) {
                    this.comparisonYears.push(ComparisonYear.refYear);
                } else if (
                    (!this.referenceYears || this.referenceYears.length === 0) &&
                    index !== -1
                ) {
                    this.comparisonYears.splice(index, 1);
                }
            }

            if (changes.equipmentList) {
                this.chartFiltersForm.patchValue({ equipments: this.equipmentList });
                this.selectedEquipmentsLabel = this.getSelectedEquipmentsLabel(this.equipmentList);
            }

            if (changes.hideSens && changes.hideSens.currentValue === false) {
                if (this.sensSelection != null) {
                    const selectedSens: Array<{ value: SensEnum; label: string }> = [];
                    this.sensSelection.forEach(s => {
                        const sens = this.sensList.find(sl => sl.value === s);
                        if (sens) {
                            selectedSens.push(sens);
                        }
                    });
                    this.chartFiltersForm.patchValue({ sens: selectedSens });
                } else {
                    this.chartFiltersForm.patchValue({ sens: this.sensList });
                }
            }

            if (changes.hideProductionType && changes.hideProductionType.currentValue === false) {
                this.chartFiltersForm.patchValue({ productionType: this.productionTypeList });
            }

            if (changes.hideProductionUse && changes.hideProductionUse.currentValue === false) {
                this.chartFiltersForm.patchValue({ productionUse: this.productionUseList });
            }
        }
    }

    onComparisonSelectionChange($event: MatSelectChange): void {
        this.selectedComparison = $event.value;

        if (this.selectedComparison === 4) {
            this.chartFiltersForm.patchValue({ referenceYear: this.referenceYears[0] });
        } else {
            this.chartFiltersForm.patchValue({ referenceYear: '' });
        }

        this.emitSelectionChange(true);
    }

    onEquipmentOpenChange($event: boolean): void {
        if ($event === false) {
            this.emitSelectionChange();
        }
    }

    onSelectionChange(matSelect?: MatSelect): void {
        if (matSelect != null) {
            matSelect.close();
        }

        this.emitSelectionChange();
    }

    onOpenChange($event: boolean): void {
        if ($event === false) {
            this.emitSelectionChange();
        }
    }

    onMultipleSelectionChange(matSelect: MatSelect): void {
        // Check all items that are selected
        const items = matSelect.options.filter((item: MatOption) => item.value !== this.allIndex);
        const selectedItems = items.filter((item: MatOption) => item.selected);
        if (selectedItems.length < items.length) {
            matSelect.options.find((item: MatOption) => item.value === this.allIndex).deselect();
        } else {
            matSelect.options.find((item: MatOption) => item.value === this.allIndex).select();
        }

        matSelect.close();

        this.emitSelectionChange();
    }

    onToggleAllSelection(matSelect: MatSelect): void {
        const isSelected = matSelect.options.find((item: MatOption) => item.value === this.allIndex)
            .selected;

        if (isSelected) {
            matSelect.options.forEach((item: MatOption) => item.select());
        } else {
            matSelect.options.forEach((item: MatOption) => item.deselect());
        }

        matSelect.close();

        this.emitSelectionChange();
    }

    getComparisonYearLabel(comparisonYear: ComparisonYear): string {
        const label = ComparisonYearRSX.find(p => p.key === comparisonYear);
        return label ? label.value : null;
    }

    getMeteringPointLabel(meteringPoints: MeteringPointLabelModel[]): string {
        return meteringPoints.map(mp => mp.meteringPointLabel).join(',');
    }

    private getSelectedEquipmentsLabel(equipments: EquipmentWriteModel[]): string {
        return equipments ? equipments.map(e => e.equipmentLabel).join(', ') : '';
    }

    private getSelectedFluidsLabel(controlName: string, list: ValueLabelModel[]): string {
        const values = this.chartFiltersForm.controls[controlName].value;

        if (values == null) {
            return null;
        }

        if (
            values.findIndex(v => v === this.allIndex) !== -1 ||
            _.union(values, list).length === list.length
        ) {
            return this.fluidUse === FluidUseEnum.Fluid
                ? this.overridenAllFluids
                    ? this.overridenAllFluids
                    : 'fluid.all'
                : 'usages.all';
        }

        const fluids: ValueLabelModel[] = [];
        for (const v of values) {
            fluids.push(list.find(s => s.value === v));
        }

        return fluids ? fluids.map(s => s.label).join(', ') : '';
    }

    private getSelectedCustomSectorsLabel(): string {
        const values = this.chartFiltersForm.controls['customSectorIds'].value;

        if (values && values.find(s => s === this.allIndex)) {
            return this.allCustomSectors.label;
        }

        if (values == null) {
            return null;
        }

        const selectedSectors = [];
        values.forEach(val => {
            const sector = this.customSectors.find(s => s.id === val);
            selectedSectors.push(sector);
        });

        return selectedSectors ? selectedSectors.map(s => s.label).join(', ') : '';
    }

    private initForm(): void {
        const { comp, year } = this.defaultComparisonYear
            ? this.defaultComparisonYear === ComparisonYear.year1 ||
              this.defaultComparisonYear === ComparisonYear.year2 ||
              this.defaultComparisonYear === ComparisonYear.avg3
                ? { comp: this.defaultComparisonYear, year: '' }
                : { comp: ComparisonYear.refYear, year: this.defaultComparisonYear }
            : { comp: undefined, year: '' };

        this.selectedComparison = comp;
        const customSectorIds =
            this.defaultCustomSectorIds ??
            (this.customSectors ? this.customSectors.map(s => s.id) : null);
        const fluidConsIds = this.fluidUsageConsLst
            ? this.fluidUsageConsLst.map(s => s.value)
            : null;

        // If there are a default selection for fluids/usages, it is used at first
        const fluidIds =
            this.defaultFluidUsageIds && this.defaultFluidUsageIds.length > 0
                ? this.defaultFluidUsageIds
                : this.fluidUseLst
                ? this.fluidUseLst.map(s => s.value)
                : null;

        this.chartFiltersForm = this.fb.group({
            equipments: [this.equipmentList],
            comparisonYear: [comp],
            referenceYear: [year],
            sens: [this.sensList],
            fluids: [fluidIds],
            fluidUsageCons: [fluidConsIds],
            fluidProd: [this.fluidProdLst],
            activitySector: [this.defaultActivitySectorId],
            customSectorIds: [customSectorIds],
            productionType: [],
            productionUse: []
        });

        this.chartFiltersForm.patchValue({ sens: this.sensList });
        this.chartFiltersForm.patchValue({ productionType: this.productionTypeList });
        this.chartFiltersForm.patchValue({ productionUse: this.productionUseList });

        if (
            fluidIds != null &&
            this.fluidUseLst != null &&
            fluidIds.length === this.fluidUseLst.length
        ) {
            this.chartFiltersForm.controls['fluids'].value.push(this.allIndex);
            this.selectedFluidsLabel = this.getSelectedFluidsLabel('fluids', this.fluidUseLst);
        }
    }

    private emitSelectionChange(isYearComparisonChanged: boolean = false): void {
        const values = this.chartFiltersForm.value;

        // Custom the text of selected items in the mat-select
        this.selectedEquipmentsLabel = this.getSelectedEquipmentsLabel(values.equipments);
        this.selectedCustomSectorsLabel = this.getSelectedCustomSectorsLabel();

        this.selectedFluidsLabel = this.getSelectedFluidsLabel('fluids', this.fluidUseLst);
        this.selectedFluidsConsLabel = this.getSelectedFluidsLabel(
            'fluidUsageCons',
            this.fluidUsageConsLst
        );
        this.selectedFluidsProdLabel = this.getSelectedFluidsLabel('fluidProd', this.fluidProdLst);

        const chartFilter = <ChartFilters>{
            comparisonYear: values.comparisonYear
                ? values.comparisonYear === 4
                    ? values.referenceYear
                    : values.comparisonYear
                : null,
            fluidUseIds: this.getMultipleSelectedItems(values.fluids),
            fluidUsageConsIds: this.getMultipleSelectedItems(values.fluidUsageCons),
            fluidProdIds: this.getMultipleSelectedItems(values.fluidProd),
            hasFluidUseSelection:
                values.fluids &&
                (values.fluids.indexOf(this.allIndex) > -1 || values.fluids.length > 0),
            activitySectorId: values.activitySector,
            customSectorIds: this.getMultipleSelectedItems(values.customSectorIds),
            isYearComparisonChanged: isYearComparisonChanged,
            equipments: values.equipments ? values.equipments : null,
            sensIds: values.sens && !this.hideSens ? values.sens.map(s => s.value) : null,
            productionTypeIds:
                values.productionType && !this.hideProductionType
                    ? values.productionType.map(t => t.value)
                    : null,
            productionUseIds:
                values.productionUse && !this.hideProductionUse
                    ? values.productionUse.map(t => t.value)
                    : null
        };

        this.selectionChange.emit(chartFilter);
    }

    private getMultipleSelectedItems(values: number[]): number[] {
        if (values == null) {
            return null;
        }

        if (values.find(s => s === this.allIndex)) {
            // All items is selected
            return null;
        }

        const selectedItems = values.filter(s => s !== this.allIndex);
        return selectedItems;
    }

    private updateMultipleSelection(
        controlName: string,
        changeName: string,
        defaultValueName: string,
        changes: SimpleChanges
    ): void {
        // When the list is previously empty
        if (
            changes[changeName].previousValue == null ||
            changes[changeName].previousValue.length === 0
        ) {
            // If there is a default list
            if (this[defaultValueName] != null) {
                this.chartFiltersForm.controls[controlName].patchValue(this[defaultValueName]);
            } else {
                this.setAllMultipleSelection(
                    controlName,
                    changes[changeName].currentValue.map(f => f.value)
                );
            }
        } else {
            let selectedItems = this.chartFiltersForm.controls[controlName].value;

            // Set all items selected
            if (selectedItems.indexOf(this.allIndex) > -1) {
                this.setAllMultipleSelection(
                    controlName,
                    changes[changeName].currentValue.map(f => f.value)
                );
            } else {
                selectedItems = _.intersection(
                    changes[changeName].currentValue.map(v => v.value),
                    selectedItems
                );
                this.chartFiltersForm.controls[controlName].patchValue(selectedItems);
            }
        }
    }

    private setAllMultipleSelection(controlName: string, list: number[]): void {
        this.chartFiltersForm.controls[controlName].patchValue(list);
        this.chartFiltersForm.controls[controlName].value.push(this.allIndex);
    }

    compareFn(c1: ValueLabelModel, c2: ValueLabelModel): boolean {
        return c1 && c2 ? c1.value === c2.value : c1 === c2;
    }

    compareFnValue(c1: number, c2: number): boolean {
        return c1 === c2;
    }
}
