//#region Imports
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import {
    MsalBroadcastService,
    MsalGuard,
    MsalGuardConfiguration,
    MsalInterceptor,
    MsalInterceptorConfiguration,
    MsalModule,
    MsalService,
    MSAL_GUARD_CONFIG,
    MSAL_INSTANCE,
    MSAL_INTERCEPTOR_CONFIG
} from '@azure/msal-angular';
import {
    BrowserCacheLocation,
    InteractionType,
    IPublicClientApplication,
    LogLevel,
    PublicClientApplication
} from '@azure/msal-browser';
import { MenuService } from '@core/menu/menu.service';
import { IconService } from '@core/services/svg-icons.service';
import { ThemesService } from '@core/services/themes.service';
import { SettingsService } from '@core/settings/settings.service';
import { environment } from '@environments/environment';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { CacheInterceptor } from './interceptors/cache.interceptor';
import { CastDateInterceptor } from './interceptors/cast-date.Interceptor';
import { HeaderInterceptor } from './interceptors/header.interceptor';
import { HttpErrorInterceptor } from './interceptors/http.error.interceptor';
import { LoadingIndicatorInterceptor } from './interceptors/loading-indicator.interceptor';
import { MonitorInterceptor } from './interceptors/monitor.interceptor';
import { metaReducers } from './store/metareducer';
import { appReducer } from './store/reducer';

//#endregion

export function loggerCallback(logLevel: LogLevel, message: string): void {
    console.debug(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
        auth: {
            clientId: environment.authConfig.clientId,
            authority: environment.authConfig.authority_signUpSignIn,
            redirectUri: environment.authConfig.redirectUri,
            postLogoutRedirectUri: environment.authConfig.postLogoutRedirectUri,
            navigateToLoginRequestUrl: environment.authConfig.navigateToLoginRequestUrl,
            knownAuthorities: [environment.authConfig.authorityDomain]
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: true // set to true for IE 11
        },
        system: {
            loggerOptions: {
                loggerCallback,
                logLevel: LogLevel.Verbose,
                piiLoggingEnabled: true
            }
        }
    });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set(environment.API_ENDPOINT, environment.authConfig.consentScopes);

    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap
    };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: [...environment.authConfig.consentScopes]
        },
        loginFailedRoute: 'login-failed'
    };
}

@NgModule({
    imports: [
        CommonModule,
        MsalModule,
        HttpClientModule,
        TranslateModule,
        StoreModule.forRoot({ appState: appReducer }, { metaReducers })
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoadingIndicatorInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CacheInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CastDateInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HeaderInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MonitorInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        SettingsService,
        MenuService,
        ThemesService,
        IconService
    ],
    declarations: [],
    exports: []
})
export class CoreModule {
    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule
        };
    }
}
