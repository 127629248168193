import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { StoreModule } from '@ngrx/store';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { GridModule } from '@progress/kendo-angular-grid';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { PopupModule } from '@progress/kendo-angular-popup';

import { ScrollingModule } from '@angular/cdk-experimental/scrolling';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { HubMenuComponent } from '@layout/header/hub-menu/hub-menu.component';

import { ClickOutsideModule } from 'ng-click-outside';

import { HeaderFreeZoneComponent } from '@layout/header/header-free-zone/header-free-zone.component';
import { HeaderComponent } from '@layout/header/header.component';
import { LangSelectorComponent } from '@layout/header/lang-selector/lang-selector.component';
import { NavsearchComponent } from '@layout/header/navsearch/navsearch.component';
import { ProfileMenuComponent } from '@layout/header/profile-menu/profile-menu.component';
import { SidebarFreeZoneComponent } from '@layout/sidebar/sidebar-free-zone/sidebar-free-zone.component';

import { HeaderPerimeterComponent } from '@layout/header/header-perimeter/header-perimeter.component';
import { SharedModule } from '@shared/shared.module';
import { LayoutComponent } from './layout.component';
import { SidebarCustomComponent } from './sidebar/sidebar-custom/sidebar-custom.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SiteMapComponent } from './sitemap/sitemap.component';
import { layoutModuleReducer } from './store/layout.reducer';

const angModules = [
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    GridModule,
    ButtonsModule,
    PopupModule,
    MatBadgeModule,
    CommonModule,
    MatCheckboxModule,
    ScrollingModule,
    LayoutModule,
    MatTabsModule,
    MatInputModule,
    MatToolbarModule,
    MatTableModule,
    DialogsModule,
    ClickOutsideModule
];
const angComponents = [
    LayoutComponent,
    SidebarComponent,
    HeaderComponent,
    HeaderFreeZoneComponent,
    NavsearchComponent,
    SiteMapComponent,
    SidebarCustomComponent,
    HeaderPerimeterComponent,
    HubMenuComponent,
    ProfileMenuComponent,
    LangSelectorComponent,
    SidebarFreeZoneComponent
];

@NgModule({
    imports: [
        ...angModules,
        StoreModule.forFeature('layoutModuleStore', {
            layoutModuleState: layoutModuleReducer
        })
    ],
    declarations: [...angComponents],
    providers: [],
    exports: [...angModules, ...angComponents]
})
export class VertuozLayoutModule {}
