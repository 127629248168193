import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'hoursMinutes'
})
export class HoursMinutesFormatPipe implements PipeTransform {
    constructor() {}

    transform(minutes: number): string {
        const hours: number = Math.floor(minutes / 60);
        return moment()
            .set({ hour: hours, minute: minutes - hours * 60 })
            .format('HH:mm');
    }
}

@Pipe({
    name: 'hours'
})
export class HoursFormatPipe implements PipeTransform {
    constructor() {}

    transform(minutes: number): string {
        const hours: number = Math.floor(minutes / 60);
        return moment(hours + ':' + (minutes - hours * 60), 'LT').format('HH');
    }
}
