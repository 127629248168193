import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AppState } from '@app/core/store/state/app-state.interface';

export const geoMenuModuleStoreKey = 'geographicMenuFilterModuleStore';

export const getGeoMenuStoreFeatureState = createFeatureSelector<AppState>(geoMenuModuleStoreKey);

export const getGeoMenuFilterState = createSelector(
    getGeoMenuStoreFeatureState,
    (appState: AppState) => appState?.geographicMenuModuleState?.filter
);
