import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'header-free-zone',
    templateUrl: './header-free-zone.component.html',
    styleUrls: ['./header-free-zone.component.scss']
})
export class HeaderFreeZoneComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
