//#region Imports
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { UserModel } from '@app/core/models/user/user.model';
//#endregion

@Component({
    selector: 'optin-settings',
    templateUrl: './optin-settings.component.html',
    styleUrls: ['./optin-settings.component.scss']
})
export class OptinSettingsComponent implements OnInit {
    //#region Input / Output
    @Input() currentUser: UserModel;
    @Output() cancel = new EventEmitter();
    @Output() saveInfos = new EventEmitter();
    //#endregion

    //#region Fields
    optinForm: FormGroup;
    //#endregion

    //#region Constructor
    constructor(private builder: FormBuilder) {}
    //#endregion

    //#region Methods
    ngOnInit(): void {
        this.initForm();
        if (this.currentUser) {
            this.patchFormvalue();
        }
    }

    initForm(): void {
        this.optinForm = this.builder.group({
            newslettersCtrl: ['', Validators.required],
            alertsmsCtrl: ['', Validators.required]
        });
    }

    patchFormvalue(): void {
        this.optinForm.patchValue({
            newslettersCtrl: this.currentUser.newslettersAccepted,
            alertsmsCtrl: this.currentUser.alertBySms
        });
    }

    cancelForm(): void {
        this.cancel.emit();
    }

    saveUserInfos(): void {
        if (this.optinForm.valid) {
            this.currentUser.newslettersAccepted = this.optinForm.get('newslettersCtrl').value;
            this.currentUser.alertBySms = this.optinForm.get('alertsmsCtrl').value;
            this.saveInfos.emit();
        }
    }
    //#endregion
}
