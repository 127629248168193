import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Dictionary } from '@app/core/models/common/dictionary.model';
import { resetGeoMenuModuleState } from '@app/core/store/state/geographic-menu/geographic-menu.action';
import { LayoutModuleState } from '@app/core/store/state/layout/layout-module-state.interface';
import { selectPerimeterToggle } from '@app/layout/store/layout.selector';
import { PerimeterScope } from '@app/shared/constants/filters.enum';
import { AppService } from '@core/http/app.service';
import { ActivePerimeterService } from '@core/http/perimeter/active-perimeter.service';
import {
    ActivePerimeterInitModel,
    ActivePerimeterModel
} from '@core/models/perimeters/active-perimeter.model';
import { PerimeterItemModel } from '@core/models/perimeters/perimeter-item.model';
import { GeoSelectionTimeStampService } from '@core/services/geo-service.service';
import { Store } from '@ngrx/store';
import { Label, ToasterService } from '@vertuoz/vertuoz-library';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'header-perimeter',
    templateUrl: './header-perimeter.component.html',
    styleUrls: ['./header-perimeter.component.scss']
})
export class HeaderPerimeterComponent implements OnInit, OnDestroy {
    /** enregistrement pour desouscrire */
    private _onDestroy = new Subject<void>();
    loading = false;
    switchLoading = false;
    public show: boolean;

    @ViewChild('anchor') public anchor: ElementRef;
    @ViewChild('popup', { read: ElementRef })
    public popup: ElementRef;

    public margin = { vertical: 5 };
    public offset = { left: 310, top: 50 };

    geographicSelection: Array<PerimeterItemModel> = [];
    propertySelection: Array<PerimeterItemModel> = [];
    publicSpaceSelection: Array<PerimeterItemModel> = [];
    groupActive: Label;

    public PerimeterScope = PerimeterScope;
    public currentPerimeterScope: PerimeterScope = PerimeterScope.activeSelectedPerimeter;
    perimeterToggleState$ = this.store.select(selectPerimeterToggle);

    constructor(
        private geoSelectionTimeStampService: GeoSelectionTimeStampService,
        private appService: AppService,
        public dataChange: GeoSelectionTimeStampService,
        private router: Router,
        public activePerimeterService: ActivePerimeterService,
        private toaster: ToasterService,
        private store: Store<LayoutModuleState>
    ) {}

    ngOnInit(): void {
        this.geoSelectionTimeStampService.data.pipe(takeUntil(this._onDestroy)).subscribe(p => {
            this.loadActivePerimeter();
        });

        this.perimeterToggleState$
            .pipe(takeUntil(this._onDestroy))
            .subscribe(state => (this.currentPerimeterScope = state));
    }

    /**
     * Le toggle local du menu geo est l'action qui réagit au click sur la barre du menu
     */
    public onToggle(): void {
        this.show = !this.show;
        if (this.router.url.includes('createAlert')) {
            this.toaster.showCustom(
                2,
                'La modification du périmètre actif entraîne la suppression des éléments sélectionnés.',
                'Attention'
            );
        }
    }

    /**
     * Active ou desactive le perimetre géo
     */
    public onTogglePerimeter(checked: boolean): void {
        this.switchLoading = true;
        this.currentPerimeterScope = checked
            ? PerimeterScope.activeSelectedPerimeter
            : PerimeterScope.activeGeographicRight;
        if (checked) {
            this.activePerimeterService
                .turnOnActivePerimeter()
                .pipe(takeUntil(this._onDestroy))
                .subscribe(
                    id => {
                        // loading finish
                        this.groupActive = null;
                        this.loading = false;
                        this.dataChange.updatedData();
                        this.switchLoading = false;
                    },
                    err => {
                        // todo: check if we need to close popup on error ?
                        // this.closeMenuContainer.emit(true);
                        this.toaster.showError(
                            "Un problème est survenu à l'application de votre nouveau périmètre."
                        );
                        this.switchLoading = false;
                        this.loading = false;
                    }
                );
        } else {
            this.activePerimeterService
                .turnOffActivePerimeter()
                .pipe(takeUntil(this._onDestroy))
                .subscribe(
                    id => {
                        // loading finish
                        this.groupActive = null;
                        this.loading = false;
                        this.dataChange.updatedData();
                        this.switchLoading = false;
                    },
                    err => {
                        // todo: check if we need to close popup on error ?
                        // this.closeMenuContainer.emit(true);
                        this.toaster.showError(
                            "Un problème est survenu à l'application de votre nouveau périmètre."
                        );
                        this.switchLoading = false;
                        this.loading = false;
                    }
                );
        }
    }

    loadActivePerimeter(): void {
        // get active perimeter
        this.activePerimeterService
            .loadActivePerimeter()
            .pipe(takeUntil(this._onDestroy))
            .subscribe(
                res => {
                    console.debug('**** loadActivePerimeter from header result !!!', res);
                    // initialiser les filtres
                    this.geographicSelection = [];
                    this.propertySelection = [];
                    this.publicSpaceSelection = [];
                    if (!res) {
                        // init ActivePerimeter si nocontent
                        res = new ActivePerimeterInitModel();
                    }
                    this.groupActive = null;
                    // regrouper par domaine
                    this.geographicSelection = [
                        ...res.perimeterItems.filter(item => item.perimeterLevel < 1)
                    ];
                    this.propertySelection = [
                        ...res.perimeterItems.filter(
                            item => item.perimeterLevel > 0 && item.perimeterLevel < 4
                        )
                    ];
                    this.publicSpaceSelection = [
                        ...res.perimeterItems.filter(
                            item => item.perimeterLevel > 10 && item.perimeterLevel < 14
                        )
                    ];
                    if (res.groupPerimeterLabel && res.groupPerimeterId) {
                        this.groupActive = new Label();
                        this.groupActive.id = res.groupPerimeterId;
                        this.groupActive.label = res.groupPerimeterLabel;
                    }
                    // set Peremeter to local storage + without next
                    this.dataChange.updatedData(false);
                },
                err => {
                    this.toaster.showError(
                        'Un problème est survenu au chargement de votre périmètre.'
                    );
                }
            );
    }

    closePopup(): void {
        if (this.show === true) {
            this.show = false;
        }
    }

    remove(): void {
        // loading start
        this.loading = true;
        const activePerimeter = new ActivePerimeterModel();
        activePerimeter.contractId = this.appService.getUserContext().contract.id;
        const dictionaryPerimeter = new Dictionary<number[]>();

        activePerimeter.perimeterItemsIds = dictionaryPerimeter.Items;

        // Remove the perimeter filter
        this.store.dispatch(resetGeoMenuModuleState());

        this.activePerimeterService
            .applyActivePerimeter(activePerimeter)
            .pipe(takeUntil(this._onDestroy))
            .subscribe(
                id => {
                    // loading finish
                    this.groupActive = null;
                    this.loading = false;
                    this.dataChange.updatedData();
                },
                err => {
                    // todo: check if we need to close popup on error ?
                    // this.closeMenuContainer.emit(true);
                    this.toaster.showError(
                        "Un problème est survenu à l'application de votre nouveau périmètre."
                    );
                }
            );
    }

    getLevelLabel(perimeterLevel: number): string {
        switch (perimeterLevel) {
            case -3:
                return 'Nations';
            case -2:
                return 'Régions';
            case -1:
                return 'Départements';
            case 0:
                return 'Villes';
            case 1:
                return 'Etablissements';
            case 2:
                return 'Bâtiments';
            case 3:
                return 'Zones';
            case 11:
                return 'Quartiers';
            case 12:
                return 'Rues';
            case 13:
                return 'Armoires';
            default:
                return 'Eléments';
        }
    }

    /*closePopup(): void {
        if (this.show === true) {
            this.show = false;
        }
    }*/

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    getLabelStringGroup(forTooltip: boolean): string {
        return !forTooltip && this.groupActive.label.length > 10
            ? this.groupActive.label.substring(0, 10) + '...'
            : this.groupActive.label;
    }

    getItemsStringByDomain(domainId: number): string {
        switch (domainId) {
            case 1:
                if (this.geographicSelection.length > 1) {
                    if (this.geographicSelection.length > 10) {
                        return (
                            this.geographicSelection
                                .slice(0, 10)
                                .map(item => item.itemLabel)
                                .join('\r\n') + '...'
                        );
                    } else {
                        return this.geographicSelection.map(item => item.itemLabel).join('\r\n');
                    }
                } else {
                    return '';
                }
            case 2:
                if (this.propertySelection.length > 1) {
                    if (this.propertySelection.length > 10) {
                        return (
                            this.propertySelection
                                .slice(0, 10)
                                .map(item => item.itemLabel)
                                .join('\r\n') + '...'
                        );
                    } else {
                        return this.propertySelection.map(item => item.itemLabel).join('\r\n');
                    }
                } else {
                    return '';
                }
            case 3:
                if (this.publicSpaceSelection.length > 1) {
                    if (this.publicSpaceSelection.length > 10) {
                        return (
                            this.publicSpaceSelection
                                .slice(0, 10)
                                .map(item => item.itemLabel)
                                .join('\r\n') + '...'
                        );
                    } else {
                        return this.publicSpaceSelection.map(item => item.itemLabel).join('\r\n');
                    }
                } else {
                    return '';
                }
            default:
                return '';
        }
    }
}
