export enum FluidEnum {
    Electricity = 1, // RSX_Fluide_Electricite
    VehicleElectricity = 125,
    VehicleElectricityGreen = 126,
    NaturalGas = 2, // RSX_Fluide_GazNaturel
    PropaneGas = 3, // RSX_Fluide_GazPropane
    ButaneGas = 4, // RSX_Fluide_GazButane
    HeavyFuelOil = 5, // RSX_Fluide_FioulLourd
    HeatingFuel = 6, // RSX_Fluide_FioulDomestique
    WoodIndustrialChippings = 7, // RSX_Fluide_BoisPlaquetteIndustrie
    WoodForestryChipppings = 8, // RSX_Fluide_BoisPlaquettesForestieres
    WoodPellets = 9, // RSX_Fluide_BoisGranules
    WoodOther = 10, // RSX_Fluide_BoisAutres
    Coal = 11, // RSX_Fluide_Charbon
    Heat = 12, // RSX_Fluide_Chaleur
    Cold = 13, // RSX_Fluide_Froid
    MainWater = 14, // RSX_Fluide_EauDeVille
    CollectedWater = 15, // RSX_Fluide_EauCollectée
    RecycledWater = 16, // RSX_Fluide_EauRecyclée
    NotRecycledWasteSentToLandFill = 18, // RSX_Fluide_DéchetsNonRecyclésEnvoyésEnDécharge
    NonReccyledWasteIncineratedWithEnergyRecovery = 19, // RSX_Fluide_DéchetsNonRecyclésIncinérésAvecRécup
    NonReccyledWasteIncineratedWithoutEnergyRecovery = 20, // RSX_Fluide_DéchetsNonRecyclésIncinérésSansRécup
    RecycledWaste = 21, // RSX_Fluide_DéchetsRecyclés
    CompostedWaste = 22, // RSX_Fluide_Déchetscompostés
    WoodBriquettes = 23, // RSX_Fluide_BoisBriquettes
    CombinedGasHeatingFuel = 24, // RSX_Fluide_CombinéGazFioulDomestique
    CombinedHeatingCooling = 25, // RSX_Fluide_CombinéChaleurFroid
    Other = 28, // RSX_Fluide_Autres
    MechanicalConnection = 48, // RSX_Fluide_LiaisonMécanique
    Lift = 49, // RSX_Fluide_Ascenseur
    BoilerRoom = 50, // RSX_Fluide_Chaufferie
    AirCooledAirConditionner = 51, // RSX_Fluide_Climatiseur
    HeatPump = 52, // RSX_Fluide_PAC
    VMC = 53, // RSX_Fluide_VMC
    Burner = 54, // RSX_Fluide_Brûleur
    HeatingGenerator = 55, // RSX_Fluide_GénérateurChaud
    HeatingCirculator = 56, // RSX_Fluide_CirculateurChaud
    HeatingControler = 57, // RSX_Fluide_RégulateurChaud
    HeatingDistribution = 58, // RSX_Fluide_DistributionChaud
    ColdProduction = 59, // RSX_Fluide_ProductionFroid
    OutdoorUnit = 60, // RSX_Fluide_UnitéExtérieure
    ColdCirculator = 61, // RSX_Fluide_CirculateurFroid
    ColdControler = 62, // RSX_Fluide_RégulateurFroid
    ColdDistribution = 63, // RSX_Fluide_DistributionFroid
    Lamp = 64, // RSX_Fluide_Lampe
    ECS = 65, // RSX_Fluide_ECS
    HeatingEmission = 66, // RSX_Fluide_EmissionChaud
    ColdEmission = 67, // RSX_Fluide_EmissionFroid
    IcedWater = 68, // RSX_Fluide_EauGlacée
    HotWater = 69, // RSX_Fluide_EauChaude
    GroundWater = 70, // RSX_Fluide_EauNappe
    HeatingGas = 72, // RSX_Fluide_ChaleurGaz
    HeatingElectricHeatPump = 73, // RSX_Fluide_ChaleurElecPAC
    HeatRecovery = 74, // RSX_Fluide_ChaleurRécupération
    HeatingGeothermal = 75, // RSX_Fluide_ChaleurGéothermie
    HeatingNetwork = 76, // RSX_Fluide_RéseauDeChaleur
    WaterFromConcessionaire = 78, // RSX_Fluide_EauConcessionnaire
    WaterHydrant = 79, // RSX_Fluide_EauIncendie
    ColdNetwork = 80, // RSX_Fluide_RéseauDeFroid
    SanitationWater = 82, // RSX_Eau_Assainissement
    SwimmingPoolWater = 83, // RSX_Eau_Piscine
    Biomass = 84, // RSX_Biomasse
    WindTurbine = 85, // RSX_Eolienne
    Geothermal = 86, // RSX_Géothemie
    Hydraulic = 87, // RSX_Hydraulique
    Solar = 88, // RSX_Solaire
    Heating = 89, // RSX_Chaleur
    NonRecycledWastes = 90, // RSX_Dechets_non_Recycles
    NaturalGas2 = 91, // RSX_Gaz_naturel
    RSX_Fioul_domestique = 92, // RSX_Fioul_domestique
    Autoconsommation = 93, // RSX_Autoconsommation
    Resale = 94, // RSX_Revente
    None = 95, // RSX_Aucun
    HeatingThermal = 96, // RSX_Thermique_Chaleur
    ColdThermal = 97, // RSX_Thermique_Froid
    Production = 98, // RSX_Production
    Consumption = 99, // RSX_Consommation
    PumpingStation = 112, // RSX_Type_Station_Pompage
    LiftingStation = 113, // RSX_Type_Poste_Relevage
    TreatmentPlant = 114, // RSX_Type_Station_TRT
    WastewaterTreatmentPlant = 115, // RSX_Type_Station_Epuration
    Reservoir = 116, // RSX_Type_Reservoir
    Equipment = 117, // RSX_Equipement
    GreenElectricity = 123, // RSX_Fluide_ElectriciteVerte
    BrutWater = 124, // RSX_Eau_Brute
    VehicleEssence = 127,
    VehicleDieselB7 = 128,
    VehicleGPL = 129,
    VehicleOtherCompressedGaseousHydrocarbon = 130,
    VehicleHydrogen = 131,
    VehicleSuperEthanolE85 = 132,
    VehicleOffRoadDiesel = 133,
    VehicleUnleaded95E5 = 134,
    VehicleUnleaded95E10 = 135,
    VehicleUnleaded98E5 = 136,
    VehicleTwoStrokeMix = 137,
    VehicleGazolePlus = 138,
    VehicleEthanolE100 = 139,
    VehicleKerosene = 140,
    VehicleAdblue = 141,
}
