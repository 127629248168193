<!-- START Sidebar (left)-->
<div class="aside-inner">
  <nav
    class="sidebar"
    sidebar-anyclick-close=""
    [class.show-scrollbar]="settings.getLayoutSetting('isSidebarScrollbar')"
  >
    <ng-container *ngIf="settings.getLayoutSetting('isSidebarFreeZone')">
      <app-sidebar-free-zone></app-sidebar-free-zone>
    </ng-container>

    <!-- START sidebar nav-->
    <ul class="sidebar-nav sidebar-menu pt-3">
      <li
        *ngFor="let item of menuItems"
        [ngClass]="{ 'nav-heading': item.heading }"
        [routerLinkActive]="['active']"
      >
        <!-- menu heading -->
        <span *ngIf="item.heading">{{ (item.translate | translate) || item.text }}</span>
        <!-- external links -->
        <a
          href
          mat-ripple
          *ngIf="!item.heading && !item.submenu && item.elink"
          [attr.target]="item.target"
          [attr.href]="item.elink"
        >
          <mat-icon *ngIf="item.icon">{{ item.icon }}</mat-icon>
          <mat-icon *ngIf="item.svgIcon" svgIcon="{{ item.svgIcon }}"></mat-icon>
          <span [ngClass]="!settings.getLayoutSetting('isSidebarCollapsedText') ? 'ms-4' : ''">{{
            (item.translate | translate) || item.text
          }}</span>
          <span *ngIf="item.alert" [ngClass]="item.label || 'badge badge-success'">{{
            item.alert
          }}</span>
        </a>
        <!-- single menu item -->
        <a
          href
          mat-ripple
          *ngIf="!item.heading && !item.submenu && !item.elink"
          [routerLink]="item.link"
          [attr.route]="item.link"
          (click)="toggleSubmenuClick($event)"
          (mouseenter)="toggleSubmenuHover($event)"
        >
          <mat-icon *ngIf="item.icon">{{ item.icon }}</mat-icon>
          <mat-icon *ngIf="item.svgIcon" svgIcon="{{ item.svgIcon }}"></mat-icon>
          <span [ngClass]="!settings.getLayoutSetting('isSidebarCollapsedText') ? 'ms-4' : ''">{{
            (item.translate | translate) || item.text
          }}</span>
          <span *ngIf="item.alert" [ngClass]="item.label || 'badge badge-success'">{{
            item.alert
          }}</span>
        </a>
        <!-- has submenu -->
        <a
          href
          mat-ripple
          *ngIf="!item.heading && item.submenu"
          (click)="toggleSubmenuClick($event)"
          (mouseenter)="toggleSubmenuHover($event)"
        >
          <mat-icon *ngIf="item.icon">{{ item.icon }}</mat-icon>
          <mat-icon *ngIf="item.svgIcon" svgIcon="{{ item.svgIcon }}"></mat-icon>
          <span [ngClass]="!settings.getLayoutSetting('isSidebarCollapsedText') ? 'ms-4' : ''">{{
            (item.translate | translate) || item.text
          }}</span>
          <span
            class="arrow-wrapper position-absolute end-0 pe-3"
            *ngIf="item.arrow && !settings.getLayoutSetting('isSidebarCollapsedText')"
          >
            <mat-icon class="arrow">arrow_right</mat-icon>
            <mat-icon class="arrow-down" class="d-none">arrow_drop_down</mat-icon>
          </span>
          <span *ngIf="item.alert" [ngClass]="item.label || 'badge badge-success'">{{
            item.alert
          }}</span>
        </a>
        <!-- SUBLEVEL -->
        <ul
          *ngIf="item.submenu"
          class="sidebar-nav sidebar-subnav"
          [routerLinkActive]="['opening']"
        >
          <li class="sidebar-subnav-header">{{ (item.translate | translate) || item.text }}</li>
          <li *ngFor="let subitem of item.submenu" [routerLinkActive]="['active']">
            <!-- sublevel: external links -->
            <a
              href
              mat-ripple
              *ngIf="!subitem.heading && !subitem.submenu && subitem.elink"
              [attr.target]="subitem.target"
              [attr.href]="subitem.elink"
            >
              <mat-icon *ngIf="subitem.icon">{{ subitem.icon }}</mat-icon>
              <mat-icon *ngIf="subitem.svgIcon" svgIcon="{{ subitem.svgIcon }}"></mat-icon>
              <span>{{ (subitem.translate | translate) || subitem.text }}</span>
            </a>
            <!-- sublevel: single menu item  -->
            <a
              href
              mat-ripple
              *ngIf="!subitem.submenu && !subitem.elink"
              [routerLink]="subitem.link"
              [attr.route]="subitem.link"
            >
              <mat-icon *ngIf="subitem.icon">{{ subitem.icon }}</mat-icon>
              <mat-icon *ngIf="subitem.svgIcon" svgIcon="{{ subitem.svgIcon }}"></mat-icon>
              <span>{{ (subitem.translate | translate) || subitem.text }}</span>
              <span *ngIf="subitem.alert" [ngClass]="subitem.label || 'badge badge-success'">{{
                subitem.alert
              }}</span>
            </a>
            <!-- sublevel: has submenu -->
            <a
              href
              mat-ripple
              *ngIf="subitem.submenu"
              (click)="toggleSubmenuClick($event)"
              (mouseenter)="toggleSubmenuHover($event)"
            >
              <mat-icon *ngIf="subitem.icon">{{ subitem.icon }}</mat-icon>
              <mat-icon *ngIf="subitem.svgIcon" svgIcon="{{ subitem.svgIcon }}"></mat-icon>
              <span>{{ (subitem.translate | translate) || subitem.text }}</span>
              <span *ngIf="subitem.alert" [ngClass]="subitem.label || 'badge badge-success'">{{
                subitem.alert
              }}</span>
            </a>
            <!-- SUBLEVEL 2 -->
            <ul
              *ngIf="subitem.submenu"
              class="sidebar-nav sidebar-subnav level2"
              [routerLinkActive]="['opening']"
            >
              <li *ngFor="let subitem2 of subitem.submenu" [routerLinkActive]="['active']">
                <!-- sublevel 2: single menu item  -->
                <a
                  href
                  mat-ripple
                  *ngIf="!subitem2.submenu"
                  [routerLink]="subitem2.link"
                  [attr.route]="subitem2.link"
                >
                  <mat-icon *ngIf="subitem2.icon">{{ subitem2.icon }}</mat-icon>
                  <mat-icon *ngIf="subitem2.svgIcon" svgIcon="{{ subitem2.svgIcon }}"></mat-icon>
                  <span>{{ (subitem2.translate | translate) || subitem2.text }}</span>
                  <span
                    *ngIf="subitem2.alert"
                    [ngClass]="subitem2.label || 'badge badge-success'"
                    >{{ subitem2.alert }}</span
                  >
                </a>
                <!-- sublevel2: has submenu -->
                <a
                  href
                  mat-ripple
                  *ngIf="subitem2.submenu"
                  (click)="toggleSubmenuClick($event)"
                  (mouseenter)="toggleSubmenuHover($event)"
                >
                  <span
                    class="float-right"
                    *ngIf="subitem2.alert"
                    [ngClass]="subitem2.label || 'badge badge-success'"
                    >{{ subitem2.alert }}</span
                  >
                  <mat-icon *ngIf="subitem2.icon">{{ subitem2.icon }}</mat-icon>
                  <mat-icon *ngIf="subitem2.svgIcon" svgIcon="{{ subitem2.svgIcon }}"></mat-icon>
                  <span>{{ (subitem2.translate | translate) || subitem2.text }}</span>
                </a>
                <!-- SUBLEVEL 3 -->
                <ul
                  *ngIf="subitem2.submenu"
                  class="sidebar-nav sidebar-subnav level3"
                  [routerLinkActive]="['opening']"
                >
                  <li *ngFor="let subitem3 of subitem2.submenu" [routerLinkActive]="['active']">
                    <!-- sublevel 2: single menu item  -->
                    <a
                      href
                      mat-ripple
                      *ngIf="!subitem3.submenu"
                      [routerLink]="subitem3.link"
                      [attr.route]="subitem3.link"
                    >
                      <span
                        class="float-right"
                        *ngIf="subitem3.alert"
                        [ngClass]="subitem3.label || 'badge badge-success'"
                        >{{ subitem3.alert }}</span
                      >
                      <mat-icon *ngIf="subitem3.icon">{{ subitem3.icon }}</mat-icon>
                      <mat-icon *ngIf="subitem3.svgIcon" svgIcon="{{ subitem3.svgIcon }}">
                      </mat-icon>
                      <span>{{ (subitem3.translate | translate) || subitem3.text }}</span>
                    </a>
                    <!-- sublevel3: has submenu -->
                    <a
                      href
                      mat-ripple
                      *ngIf="subitem3.submenu"
                      (click)="toggleSubmenuClick($event)"
                      (mouseenter)="toggleSubmenuHover($event)"
                    >
                      <span
                        class="float-right"
                        *ngIf="subitem3.alert"
                        [ngClass]="subitem3.label || 'badge badge-success'"
                        >{{ subitem3.alert }}</span
                      >
                      <mat-icon *ngIf="subitem3.icon">{{ subitem3.icon }}</mat-icon>
                      <mat-icon *ngIf="subitem3.svgIcon" svgIcon="{{ subitem3.svgIcon }}">
                      </mat-icon>
                      <span>{{ (subitem3.translate | translate) || subitem3.text }}</span>
                    </a>
                    <!-- SUBLEVEL 4 -->
                    <ul
                      *ngIf="subitem3.submenu"
                      class="sidebar-nav sidebar-subnav level3"
                      [routerLinkActive]="['opening']"
                    >
                      <li *ngFor="let subitem4 of subitem3.submenu" [routerLinkActive]="['active']">
                        <!-- sublevel 2: single menu item  -->
                        <a
                          href
                          mat-ripple
                          *ngIf="!subitem4.submenu"
                          [routerLink]="subitem4.link"
                          [attr.route]="subitem4.link"
                        >
                          <span
                            class="float-right"
                            *ngIf="subitem4.alert"
                            [ngClass]="subitem4.label || 'badge badge-success'"
                            >{{ subitem4.alert }}</span
                          >
                          <mat-icon *ngIf="subitem4.icon">{{ subitem4.icon }}</mat-icon>
                          <mat-icon *ngIf="subitem4.svgIcon" svgIcon="{{ subitem4.svgIcon }}">
                          </mat-icon>
                          <span>{{ (subitem4.translate | translate) || subitem4.text }}</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>

              <li></li>
            </ul>
          </li>

          <li></li>
        </ul>
      </li>
    </ul>
    <!-- END sidebar nav-->
  </nav>
</div>
<!-- END Sidebar (left)-->
