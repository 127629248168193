import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'logo-display',
    templateUrl: './logo-display.component.html',
    styleUrls: ['./logo-display.component.scss']
})
export class LogoDisplayComponent implements OnInit {
    /* Binary du logo. Doit être pécédé de 'data:image/png;base64,' */
    @Input() public logo: string;
    /* Initiales à afficher en cas de logo vide */
    @Input() public initials: string;
    /* Couleur du fond du logo des initiales */
    @Input() public backColor: string;

    constructor() {}

    ngOnInit() {
        if (!this.backColor) {
            this.backColor = 'black';
        }
    }
}
