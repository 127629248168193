import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { EnumPermissions } from '@app/core/models/permissions/permissions.enum';
import { PermissionService } from '@app/core/services/permission.service';
import { environment } from '@environments/environment';
import { EnumMeteringPointRoute } from '@features/metering-point/models/enum-routes';

import { enumSearchType } from '../models/navsearch.enum';
import { NavSearchResult, QuickSearchCriteriaDto } from '../models/navsearch.model';

import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class NavSearchEndPointService {
    /** Endpoint pour la méthode de quick search */
    public API_QUICKSEARCH_ENDPOINT: string =
        environment.API_ENDPOINT + 'searchBase/QuickSearchByCriteria';

    constructor(private http: HttpClient, private permissionService: PermissionService) {}

    /**
     * Récupère les résultats correspondant au terme de recherche
     * @param quickSearchCriteriaDto critères de recherche
     */
    getNavSearchResults(quickSearchCriteriaDto: QuickSearchCriteriaDto) {
        const headers = this.permissionService.getHeader();
        return this.http
            .post<Array<NavSearchResult>>(
                `${this.API_QUICKSEARCH_ENDPOINT}`,
                quickSearchCriteriaDto,
                { headers }
            )
            .pipe(
                map(data => {
                    switch (quickSearchCriteriaDto.searchType) {
                        case enumSearchType.Establishment:
                            return data.map(d => {
                                return {
                                    id: d.id,
                                    label: d.label,
                                    idSiTiers: d.idSiTiers,
                                    subLabel1: 'Ville : ' + d.subLabel1,
                                    subLabel2: 'Adresse : ' + d.subLabel2,
                                    subLabel3: 'Secteur : ' + d.subLabel3,
                                    subLabel4: d.subLabel4,
                                    subLabel5: d.subLabel5,
                                    subLabel6: d.subLabel6,
                                    viewPath: '/property/establishment-details/' + d.id,
                                    viewPermission: EnumPermissions.PROPERTY_ACCESS,
                                    editPath: '/property/establishment/' + d.id,
                                    editPermission: EnumPermissions.PROPERTY_ESTABLISHMENT_CREATE
                                } as NavSearchResult;
                            });
                        case enumSearchType.Building:
                            return data.map(d => {
                                return {
                                    id: d.id,
                                    label: d.label,
                                    idSiTiers: d.idSiTiers,
                                    subLabel1: 'Etablissement : ' + d.subLabel1,
                                    subLabel2: 'Ville : ' + d.subLabel2,
                                    subLabel3: 'Adresse : ' + d.subLabel3,
                                    subLabel4: 'Secteur : ' + d.subLabel4,
                                    subLabel5: d.subLabel5,
                                    subLabel6: d.subLabel6,
                                    viewPath: '/property/building-details/' + d.id,
                                    viewPermission: EnumPermissions.PROPERTY_ACCESS,
                                    editPath: '/property/building/' + d.id,
                                    editPermission: EnumPermissions.PROPERTY_BUILDING_CREATE
                                } as NavSearchResult;
                            });
                        case enumSearchType.Zone:
                            return data.map(d => {
                                return {
                                    id: d.id,
                                    label: d.label,
                                    idSiTiers: d.idSiTiers,
                                    subLabel1: 'Bâtiment : ' + d.subLabel1,
                                    subLabel2: 'Ville : ' + d.subLabel2,
                                    subLabel3: 'Adresse : ' + d.subLabel3,
                                    subLabel4: 'Secteur : ' + d.subLabel4,
                                    subLabel5: d.subLabel5,
                                    subLabel6: d.subLabel6,
                                    viewPath: '/property/zone-details/' + d.id,
                                    viewPermission: EnumPermissions.PROPERTY_ACCESS,
                                    editPath: '/property/zone/' + d.id,
                                    editPermission: EnumPermissions.PROPERTY_AREA_CREATE
                                } as NavSearchResult;
                            });
                        case enumSearchType.MeteringPoint:
                            return data.map(d => {
                                return {
                                    id: d.id,
                                    label: d.label,
                                    idSiTiers: d.idSiTiers,
                                    subLabel1: 'Fluide : ' + d.subLabel1,
                                    subLabel2: d.subLabel2,
                                    subLabel3: d.subLabel3,
                                    subLabel4: d.subLabel4,
                                    subLabel5: d.subLabel5,
                                    subLabel6: d.subLabel6,
                                    viewPath:
                                        `/${EnumMeteringPointRoute.path}/${
                                            EnumMeteringPointRoute.displayMeteringPoint
                                        }/` + d.id,
                                    viewPermission: EnumPermissions.METERINGPLAN_ACCESS,
                                    editPath:
                                        `/${EnumMeteringPointRoute.path}/${
                                            EnumMeteringPointRoute.updateMeteringPoint
                                        }/` + d.id,
                                    editPermission: EnumPermissions.METERINGPLAN_POINTS_CREATE
                                } as NavSearchResult;
                            });
                        case enumSearchType.Meter:
                            return data.map(d => {
                                return {
                                    id: d.id,
                                    label: d.label,
                                    idSiTiers: d.idSiTiers,
                                    subLabel1: 'PdC : ' + d.subLabel1,
                                    subLabel2: d.subLabel2,
                                    subLabel3: d.subLabel3,
                                    subLabel4: d.subLabel4,
                                    subLabel5: d.subLabel5,
                                    subLabel6: d.subLabel6,
                                    viewPath:
                                        `/${EnumMeteringPointRoute.path}/${
                                            EnumMeteringPointRoute.displayMeter
                                        }/` + d.id,
                                    viewPermission: EnumPermissions.METERINGPLAN_ACCESS,
                                    editPath:
                                        `/${EnumMeteringPointRoute.path}/${
                                            EnumMeteringPointRoute.updateMeter
                                        }/` +
                                        d.id +
                                        '/' +
                                        d.subId1 +
                                        '/' +
                                        d.subId2,
                                    editPermission: EnumPermissions.METERINGPLAN_POINTS_CREATE
                                } as NavSearchResult;
                            });
                        case enumSearchType.Dial:
                            return data.map(d => {
                                return {
                                    id: d.id,
                                    label: d.label,
                                    idSiTiers: d.idSiTiers,
                                    subLabel1: 'PdC : ' + d.subLabel1,
                                    subLabel2: 'Compteur : ' + d.subLabel2,
                                    subLabel3: d.subLabel3,
                                    subLabel4: d.subLabel4,
                                    subLabel5: d.subLabel5,
                                    subLabel6: d.subLabel6,
                                    viewPath:
                                        `/${EnumMeteringPointRoute.path}/${
                                            EnumMeteringPointRoute.displayDial
                                        }/` + d.id,
                                    viewPermission: EnumPermissions.METERINGPLAN_ACCESS,
                                    editPath:
                                        `/${EnumMeteringPointRoute.path}/${
                                            EnumMeteringPointRoute.updateDial
                                        }/` +
                                        d.id +
                                        '/' +
                                        d.subId1 +
                                        '/' +
                                        d.subId2,
                                    editPermission: EnumPermissions.METERINGPLAN_POINTS_CREATE
                                } as NavSearchResult;
                            });
                        // case enumSearchType.Invoice:
                        //     return data.map(d => {
                        //         return {
                        //             id: d.id,
                        //             label: d.label,
                        //             idSiTiers: d.idSiTiers,
                        //             subLabel1: 'PdC : ' + d.subLabel1,
                        //             subLabel2: 'ID Fournisseur du PDC : ' + d.subLabel2,
                        //             subLabel3: 'Fluide : ' + d.subLabel3,
                        //             subLabel4: 'Date de début : ' + d.subLabel4,
                        //             subLabel5: 'Date de fin : ' + d.subLabel5,
                        //             subLabel6: `Date d'émission : ` + d.subLabel6,
                        //             viewPath: '/invoice/' + d.id,
                        //             viewPermission: EnumPermissions.INVOICES_ACCESS,
                        //             editPath: '/invoice/' + d.id,
                        //             editPermission: EnumPermissions.INVOICES_CREATE
                        //         } as NavSearchResult;
                        //     });
                        // case enumSearchType.Contract:
                        //     return data.map(d => {
                        //         return {
                        //             id: d.id,
                        //             label: d.label,
                        //             idSiTiers: 'Signé le : ' + d.idSiTiers,
                        //             subLabel1: 'PdC : ' + d.subLabel1,
                        //             subLabel2: 'ID Fournisseur du PDC : ' + d.subLabel2,
                        //             subLabel3: 'Fluide : ' + d.subLabel3,
                        //             subLabel4: 'Date de début : ' + d.subLabel4,
                        //             subLabel5: 'Date de fin : ' + d.subLabel5,
                        //             subLabel6: `Date d'avenant : ` + d.subLabel6,
                        //             viewPath: '',
                        //             viewPermission: 'contracts_access',
                        //             editPath: '',
                        //             editPermission: 'contracts_create'
                        //         } as NavSearchResult;
                        //     });
                    }
                })
            );
    }
}
