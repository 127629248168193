<section class="map-module">
    <div class="container-fluid" [@subMap]="stateSubMap">
        <div id="map"></div>
        <div class="map-content">

            <div class="container">

                <ul class="poi-details-list">
                    <li class="poi-details">
                        <strong class="nb">345</strong>
                        <span>Bâtiments</span>
                        <a href="#">Voir les PDC</a>
                    </li>
                    <li class="poi-details">
                        <strong class="nb">157</strong>
                        <span>Equipements</span>
                        <a href="#">Voir les équipements</a>
                    </li>
                    <li class="poi-details">
                        <strong class="nb">11 000</strong>
                        <span>Factures</span>
                        <a href="#">Voir les factures</a>
                    </li>
                    <li class="poi-details">
                        <strong class="nb">129</strong>
                        <span>Contrats</span>
                        <a href="#">Voir les contrats</a>
                    </li>
                </ul>

                <div class="map-trigger-container" title="Ouvrir la carte" (click)="toggleCollapsedSubMap()">
                    <div class="map-trigger" [@subArrow]="stateSubMap"></div>
                </div>

            </div>
        </div>
    </div>
</section>