import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import { ToasterService } from '@vertuoz/vertuoz-library';
import { Subject } from 'rxjs';

import { UsersFactory } from '@app/core/factories/users.factory';
import { UsersService } from '@app/core/http/administration/users.service';
import { AppService } from '@app/core/http/app.service';
import { BaseUserModel } from '@app/core/models/user/base.user.model';
import { UserContext } from '@app/core/models/user/user.context';
import { UserModel } from '@app/core/models/user/user.model';
import { RGPDService } from '@app/core/services/rgpd.service';
import { environment } from '@environments/environment';
import { Civility, OrganizationSectorName } from '../constants/common.enum';

@Component({
    selector: 'account-settings',
    templateUrl: './account-settings.component.html',
    styleUrls: ['./account-settings.component.scss']
})
export class AccountSettingsComponent implements OnInit, OnDestroy {
    currentUser: UserModel;
    myAccountForm: FormGroup;
    enableUpdatingInfo = false;
    enableUpdatingEmail = false;
    enableUpdatingOptin = false;
    currentUserContext: UserContext;

    public isLoading = false;

    /** enregistrement pour desouscrire */
    private _onDestroy = new Subject<void>();

    constructor(
        private appService: AppService,
        private builder: FormBuilder,
        private dialogRef: MatDialogRef<AccountSettingsComponent>,
        private usersFactory: UsersFactory,
        private userService: UsersService,
        private toaster: ToasterService,
        private rgpdService: RGPDService
    ) {}

    ngOnInit(): void {
        this.initForm();
        this.currentUserContext = this.appService.getUserContext();
        this.currentUser = this.currentUserContext.user;
        this.patchFormvalue();
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    initForm(): void {
        this.myAccountForm = this.builder.group({
            civilityCtrl: ['', Validators.required],
            lastNameCtrl: ['', Validators.required],
            firstNameCtrl: ['', Validators.required],
            mailCtrl: [{ value: '', disabled: true }, Validators.required],
            organisationCtrl: ['', Validators.required],
            newslettersCtrl: ''
        });
    }

    patchFormvalue(): void {
        this.myAccountForm.patchValue({
            civilityCtrl: this.currentUser.civility,
            lastNameCtrl: this.currentUser.familyName,
            firstNameCtrl: this.currentUser.firstName,
            mailCtrl: this.currentUser.login,
            organisationCtrl: this.currentUserContext.contract.organization.label,
            newslettersCtrl: this.currentUser.newslettersAccepted
        });
    }

    updateProfile(param: string): void {
        this.isLoading = true;
        let baseUser: BaseUserModel = <BaseUserModel>{};
        const userContext = this.appService.getUserContext();

        baseUser = this.usersFactory.convert(
            this.currentUser,
            userContext.contract.offer
                ? this.appService.getUserContext().contract.offer.id
                : environment.DASHBOARD_OFFERID,
            userContext.contract.id,
            userContext.contract.client.id
        );

        this.userService.updateUser(this.currentUser.userId, baseUser).subscribe(response => {
            this.rgpdService.changeRgpd.next(true);
            this.toaster.showSuccess('Profil correctement modifié');
            this.disableUpdating(param);
            this.isLoading = false;
        });
    }

    close(): void {
        this.dialogRef.close();
    }

    getUserRoleName(): string {
        return this.appService.getUserContext().role.name;
    }

    disableUpdating(param: string): void {
        if (param === 'info') {
            this.enableUpdatingInfo = false;
        } else if (param === 'option') {
            this.enableUpdatingOptin = false;
        }
    }

    getCivilityLbl(): string {
        return Civility[this.currentUser.civility.toLowerCase()];
    }

    getUserInitials(): string {
        return String(this.currentUser.firstName.charAt(0) + this.currentUser.familyName.charAt(0));
    }

    getSectorName(): string {
        return OrganizationSectorName[this.currentUser.entitySectorId];
    }
}
