export enum PerimeterScope {
    NoScope = 0,
    activeGeographicRight = 1,
    activeSelectedPerimeter = 2
}

// Niveau de détail requis lors de la récupération de données et des mappings, afin de gérer les performances des requetes et le volume des données transférées.
export enum DetailLevel {
    Count = -1,
    Base = 0,
    High = 6,
    Full = 9
}
