import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { PageResult } from '@app/core/models/common/page-result.model';
import { BaseUserModel } from '@app/core/models/user/base.user.model';
import { UserFilter } from '@app/core/models/user/user-filter.model';
import { UserModel } from '@app/core/models/user/user.model';
import { PagedContext } from '@app/features/shared/models/paged-context';
import { ToolsService } from '@app/features/shared/services/tools.service';
import { environment } from '@environments/environment';
import { AppService } from '../app.service';
import { BaseService } from '../base.service';

@Injectable({
    providedIn: 'root'
})
export class UsersService {
    //#region Fields
    private API_ENDPOINT: string = environment.API_ENDPOINT;
    private userApiUrl = `${this.API_ENDPOINT}Users/`;
    private userWidgetApiUrl = `${this.API_ENDPOINT}UserWidgets/`;

    //#endregion

    //#region Constructor
    constructor(
        private http: HttpClient,
        private baseService: BaseService,
        private appService: AppService,
        private toolsService: ToolsService
    ) {}
    //#endregion

    //#region API Calls
    /**
     * Récupère la liste des utilisateurs
     *
     * @returns {Observable<UserModel[]>}
     * @memberof UserService
     */
    public getUsers(): Observable<UserModel[]> {
        return this.http.get<UserModel[]>(this.userApiUrl);
    }

    /**
     *
     *
     * @param {number} userId
     * @returns {Observable<UserModel>}
     * @memberof UserService
     */
    public getUserById(userId: number): Observable<UserModel> {
        return this.http.get<UserModel>(this.userApiUrl + `${userId}`);
    }

    /**
     *
     *
     * @param {Object} filter
     * @returns {Observable<UserModel>}
     * @memberof UsersService
     */
    public getUserByFilter(filter: Object): Observable<UserModel> {
        const httpParams = this.baseService.createHttpParams(filter);
        return this.http.get<UserModel>(this.userApiUrl + 'filter', { params: httpParams });
    }

    /**
     * Récupère la liste des utilisateurs d'un contrat
     * @param {number} contractId
     * @returns {Observable<UserModel[]>}
     * @memberof UsersService
     */
    public getUsersByContract(
        contractId: number,
        userFilter: UserFilter,
        pagedContext: PagedContext
    ): Observable<PageResult<UserModel>> {
        const httpParams = this.baseService.createHttpParams(userFilter);
        const qryPagedContext = pagedContext
            ? this.toolsService.transformParamsToQueryString(pagedContext)
            : '';
        return this.http.get<PageResult<UserModel>>(
            this.userApiUrl + 'contracts/' + contractId + `?${qryPagedContext}`,
            {
                params: httpParams
            }
        );
    }

    /**
     *
     *
     * @param {string} userLogin
     * @returns {Observable<UserModel>}
     * @memberof UsersService
     */
    public getUserInfos(userLogin: string): Observable<UserModel> {
        return this.http.get<UserModel>(this.userApiUrl + 'userInfos/' + userLogin);
    }

    /**
     * Ajoute un utilisateur
     *
     * @param {UserModel} userModel
     * @returns
     * @memberof UsersService
     */
    public addUser(data: BaseUserModel): Observable<HttpResponse<number>> {
        return this.http.post<number>(this.userApiUrl, data, { observe: 'response' });
    }

    /**
     * met à jours un utilisateurs
     *
     * @param {User} userModel
     * @returns
     * @memberof UsersService
     */
    public updateUser(userId: number, userModel: BaseUserModel): Observable<UserModel> {
        return this.http.put<UserModel>(this.userApiUrl + userId, userModel);
    }

    /**
     * Supprime (soft) un utilisateur
     *
     * @param {number} userId
     * @returns
     * @memberof UsersService
     */
    public deleteUser(userId: number): Observable<UserModel> {
        return this.http.delete<UserModel>(this.userApiUrl + userId);
    }

    /**
     * Supprime des utilisateur
     *
     * @param {number[]} userId
     * @returns
     * @memberof UsersService
     */
    public deleteUsers(ids: number[]): Observable<void> {
        const qryids = ids ? this.toolsService.transformParamsToQueryString({ ids }) : '';
        return this.http.delete<void>(this.userApiUrl + `?${qryids}`);
    }

    deleteUserWidgets(userId: number): Observable<void> {
        const queryContractId = `contractId=${this.appService.getUserContext().contract.id}`;
        return this.http.delete<void>(
            `${this.userWidgetApiUrl}${userId}/Widgets?${queryContractId}`
        );
    }

    deleteUsersWidgets(ids: number[]): Observable<void> {
        const queryContractId = `contractId=${this.appService.getUserContext().contract.id}`;
        const qryids = ids ? this.toolsService.transformParamsToQueryString({ ids }) : '';
        return this.http.delete<void>(`${this.userWidgetApiUrl}?${queryContractId}&${qryids}`);
    }
    //#endregion
}
