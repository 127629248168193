<vertuoz-confirm-dialog
  [width]="'300px'"
  [title]="title"
  [hasTimerToValidete]="true"
  [actionName]="'Supprimer'"
  (validate)="OnValidate()"
>
  <vertuoz-confirm-dialog-button-content>
    <mat-icon>delete</mat-icon>
  </vertuoz-confirm-dialog-button-content>
  <vertuoz-confirm-dialog-popup-content>
    <div class="text-center">
      {{ title }}
    </div>
  </vertuoz-confirm-dialog-popup-content>
</vertuoz-confirm-dialog>
