<div class="vz-autocomplete-select">
  <mat-form-field matAutocompleteOrigin #origin="matAutocompleteOrigin" class="w-100">
    <input
      matInput
      placeholder="{{ placeholder }}"
      [matAutocomplete]="auto"
      [formControl]="itemCtrl"
      [matAutocompleteConnectedTo]="origin"
      (focusout)="resetCurrentSelection()"
    />
    <mat-icon matSuffix disabled>keyboard_arrow_down</mat-icon>
  </mat-form-field>
  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="displayFn"
    (click)="$event.stopPropagation()"
    (optionsScroll)="onScroll()"
  >
    <mat-option *ngIf="isLoadingFetch"
      ><mat-progress-bar mode="indeterminate"></mat-progress-bar
    ></mat-option>

    <ng-container *ngIf="!isLoadingFetch && isMultiple">
      <mat-option
        [ngClass]="{ isMultiple: isMultiple }"
        *ngFor="let item of filteredItemsSelected"
        [value]="item"
      >
        <div (click)="optionClicked($event, item)">
          <mat-checkbox
            [checked]="item.isSelected"
            (change)="toggleSelection(item)"
            (click)="$event.stopPropagation()"
          >
            {{ getLabelFormatted(item) }} <br />
            <span
              *ngIf="item.itemMetadata && item.itemMetadata.length"
              class="multiple-autocomplete-metadata"
              ><em
                ><strong>{{ item.itemMetadata[0].labelMetaData }}</strong
                >{{ item.itemMetadata[0].textMetaData }}</em
              ></span
            >
          </mat-checkbox>
        </div>
      </mat-option>
      <mat-option
        [ngClass]="{ isMultiple: isMultiple }"
        *ngFor="let item of filteredItemsNotSelected"
        [value]="item"
      >
        <div (click)="optionClicked($event, item)">
          <mat-checkbox
            [checked]="item.isSelected"
            (change)="toggleSelection(item)"
            (click)="$event.stopPropagation()"
          >
            {{ getLabelFormatted(item) }} <br />
            <span
              *ngIf="item.itemMetadata && item.itemMetadata.length"
              class="multiple-autocomplete-metadata"
              ><em
                ><strong>{{ item.itemMetadata[0].labelMetaData }}</strong
                >{{ item.itemMetadata[0].textMetaData }}</em
              ></span
            >
          </mat-checkbox>
        </div>
      </mat-option>
      <mat-option *ngIf="isLoadingScroll"
        ><mat-progress-bar mode="indeterminate"></mat-progress-bar
      ></mat-option>
    </ng-container>

    <ng-container *ngIf="!isLoadingFetch && !isMultiple">
      <mat-option
        [ngClass]="{ isNotMultiple: !isMultiple }"
        #matOption
        *ngFor="let item of filteredItems"
        [value]="item"
        (click)="onSelectionChange(matOption)"
      >
        <span>{{ getLabelFormatted(item) }}</span>
        <ng-container *ngIf="item.itemMetadata && item.itemMetadata.length">
          <ng-container *ngFor="let metaData of item.itemMetadata">
            <span class="subitem" *ngIf="metaData">
              <br />
              <strong *ngIf="metaData.labelMetaData || metaData.textMetaData">
                {{ metaData.labelMetaData }}
                <span *ngIf="metaData.textMetaData && metaData.labelMetaData">: </span> </strong
              >{{ metaData.textMetaData }}
            </span>
          </ng-container>
        </ng-container>
      </mat-option>
      <mat-option *ngIf="isLoadingScroll"
        ><mat-progress-bar mode="indeterminate"></mat-progress-bar
      ></mat-option>
    </ng-container>
  </mat-autocomplete>

  <div class="chip-list-wrapper" *ngIf="isMultiple">
    <mat-chip-list #chipList>
      <ng-container *ngFor="let select of selectedItems">
        <mat-chip class="cardinal-colors">
          {{ select.itemLabel }}
          <mat-icon *ngIf="!disabledControl" class="mat-chip-remove" (click)="removeChip(select)"
            >cancel</mat-icon
          >
        </mat-chip>
      </ng-container>
    </mat-chip-list>
  </div>
</div>
