<div class="preloader h-100">
  <div class="align-middle loading__session w-100">
    <p class="loading__project d-flex flex-wrap pb-3 mx-auto w-28">
      <span class="col ps-0 ms-n3 text-end">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="20 -12 290 84" width="170" height="50">
          <linearGradient
            id="a"
            x1="30.69"
            y1="50.42"
            x2="287.65"
            y2="50.42"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stop-color="#00bcfd" />
            <stop offset="1" stop-color="#23d2b5" />
          </linearGradient>
          <path
            d="M68.92 30.69l-13 39.38H43.66l-13-39.38h12.11L49 54.9l1 4.1h.15l.95-4.09 6.4-24.21zm1.46 19.37c0-12.58 5.68-20.13 18.07-20.13 10.53 0 16.79 5.93 16.79 18.39a48.72 48.72 0 01-.33 5.55H83.29c.52 4.78 3.36 6.59 9.17 6.59a33.23 33.23 0 0010.39-1.62l1.35 9.55a35.22 35.22 0 01-13.29 2.39c-14.07 0-20.53-7.36-20.53-20.72zm23.5-4c-.13-4.52-1.94-6.26-5.36-6.26-3.74 0-5.1 1.49-5.49 6.26zm40.89 8.16l8.56 15.85h-12.81l-5.77-13.7h-4v13.7h-11V30.69h15c10.55 0 15.91 3.94 15.91 12.76.05 5.25-2.1 9.24-5.89 10.77zm-9.87-14.76h-4.14v9h4c3.25 0 4.46-.78 4.46-4.62.04-3.43-1.06-4.38-4.32-4.38zm39 .74v29.87h-11.08V40.2H143l.21-9.51h30.35l.21 9.51zM212 55.68c0 9.94-5.68 15.1-17.43 15.1s-17.36-5.35-17.36-15.1v-25h12.39v24.8c0 3.16 1.48 4.71 5 4.71s4.91-1.42 4.91-4.71V30.69H212zM235.15 71c-12.91 0-18.65-6-18.65-20.46s5.81-20.66 18.72-20.66 18.59 6.2 18.59 20.66S248.19 71 235.15 71zm.07-9.69c4.26 0 5.74-2.06 5.74-10.77s-1.48-10.78-5.74-10.78-5.81 1.93-5.81 10.78 1.59 10.75 5.81 10.75zm39-5.18L271.11 60l.07.2h16.47l-.26 9.84H257v-8.33l12.67-17 3.16-4-.07-.13h-15.42l.39-9.91H287v8.37z"
            fill="url(#a)"
          />
        </svg>
      </span>
      <span class="col ps-2 border-start border-gray-900 align-self-end">
        <strong class="font-20 d-block text-uppercase mb-n1">Tableau de bord</strong>
      </span>
    </p>
    <p class="text-center mb-1">
      <span class="loading__text font-14">Chargement en cours ...</span>
    </p>
    <div class="text-center">
      <div class="w-10 m-auto">
        <div class="preloader-progress-bar">
          <div class="preloader-progress">
            <!-- [style.width]="loadCounter + '%'" -->
            <div
              class="progress-bar rounded"
              role="progressbar"
              aria-valuenow="0"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
