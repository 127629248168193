import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { OrganizationsService } from '@app/core/http/administration/organizations.service';
import { OrganizationSectorModel } from '@app/core/models/organization/organization.sector.model';
import { UserModel } from '@app/core/models/user/user.model';
import { CIVILITY_LIST } from '@app/shared/constants/common.enum';

@Component({
    selector: 'personnal-informations',
    templateUrl: './personnal-informations.component.html',
    styleUrls: ['./personnal-informations.component.scss']
})
export class PersonnalInformationsComponent implements OnInit {
    @Output() cancel = new EventEmitter();
    @Output() saveInfos = new EventEmitter();

    @Input() currentUser: UserModel;

    myInfosForm: FormGroup;
    organizationSectors: OrganizationSectorModel[];
    civilityList = CIVILITY_LIST;

    constructor(private builder: FormBuilder, private organisationService: OrganizationsService) {}

    ngOnInit(): void {
        this.initForm();
        this.loadOrganisationSector();
        if (this.currentUser) {
            this.patchFormvalue();
        }
    }

    /**
     * Liste des secteurs d'activités
     *
     * @memberof UserDetailsComponent
     */
    loadOrganisationSector(): void {
        this.organisationService.GetOrganizationsSector().subscribe(organizationSectors => {
            this.organizationSectors = organizationSectors;
        });
    }

    /** Initialise le formulaire */
    initForm(): void {
        this.myInfosForm = this.builder.group({
            civilityCtrl: ['', Validators.required],
            lastNameCtrl: ['', Validators.required],
            firstNameCtrl: ['', Validators.required],
            phoneNumberCtrl: [''],
            functionCtrl: [''],
            entityCtrl: [''],
            sectorCtrl: ['', Validators.required]
        });
    }

    patchFormvalue(): void {
        this.myInfosForm.patchValue({
            civilityCtrl: this.currentUser.civility,
            lastNameCtrl: this.currentUser.familyName,
            firstNameCtrl: this.currentUser.firstName,
            phoneNumberCtrl: this.currentUser.phoneNumber,
            functionCtrl: this.currentUser.function,
            entityCtrl: this.currentUser.entity,
            sectorCtrl: this.currentUser.entitySectorId
        });
    }

    cancelForm(): void {
        this.cancel.emit();
    }

    saveUserInfos(): void {
        if (this.myInfosForm.valid) {
            this.currentUser.civility = this.myInfosForm.get('civilityCtrl').value;
            this.currentUser.firstName = this.myInfosForm.get('firstNameCtrl').value;
            this.currentUser.familyName = this.myInfosForm.get('lastNameCtrl').value;
            this.currentUser.phoneNumber = this.myInfosForm.get('phoneNumberCtrl').value;
            this.currentUser.function = this.myInfosForm.get('functionCtrl').value;
            this.currentUser.entitySectorId = this.myInfosForm.get('sectorCtrl').value;
            this.currentUser.entity = this.myInfosForm.get('entityCtrl').value;

            this.saveInfos.emit();
        }
    }
}
