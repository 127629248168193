import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { WidgetBaseComponent } from './widget-base.component';

@NgModule({
    declarations: [],
    exports: [],
    imports: [CommonModule, MatIconModule]
})
export class WidgetBaseModule {}
