import { enumSearchType } from './navsearch.enum';

import { EnumPermissions } from '@app/core/models/permissions/permissions.enum';

/** Objet pour les critères de recherche */
export class QuickSearchCriteriaDto {
    searchType: enumSearchType;
    term: string;
    startDate?: Date;
    endDate?: Date;
}

/** Objet pour les résultats de recherche */
export class NavSearchResult {
    id: number;
    subId1: number;
    subId2: number;
    label: string;
    idSiTiers: string;
    subLabel1: string;
    subLabel2: string;
    subLabel3: string;
    subLabel4: string;
    subLabel5: string;
    subLabel6: string;
    viewPath?: string;
    viewPermission: string;
    editPath?: string;
    editPermission: string;
}

/** Interface pour les types d'entité */
export interface NavSearchEntity {
    value: number;
    viewValue: string;
    permission: string;
}

/** Interface pour le groupement des types d'entité */
export interface NavSearchEntityGroup {
    name: string;
    entity: NavSearchEntity[];
    permissions: string[];
}

/** Déclaration des types d'entité */
export const NavSearchData: NavSearchEntityGroup[] = [
    {
        name: 'Patrimoine',
        entity: [
            {
                value: enumSearchType.Establishment,
                viewValue: 'Etablissement',
                permission: EnumPermissions.PROPERTY_ACCESS
            },
            {
                value: enumSearchType.Building,
                viewValue: 'Bâtiment',
                permission: EnumPermissions.PROPERTY_ACCESS
            },
            {
                value: enumSearchType.Zone,
                viewValue: 'Zone',
                permission: EnumPermissions.PROPERTY_ACCESS
            }
        ],
        permissions: [EnumPermissions.PROPERTY_ACCESS]
    },
    {
        name: 'Comptage',
        entity: [
            {
                value: enumSearchType.MeteringPoint,
                viewValue: 'Point de comptage',
                permission: EnumPermissions.METERINGPLAN_ACCESS
            },
            {
                value: enumSearchType.Meter,
                viewValue: 'Compteur',
                permission: EnumPermissions.METERINGPLAN_ACCESS
            },
            {
                value: enumSearchType.Dial,
                viewValue: 'Cadran',
                permission: EnumPermissions.METERINGPLAN_ACCESS
            }
        ],
        permissions: [EnumPermissions.METERINGPLAN_ACCESS]
    }
    // {
    //     name: 'Consommation',
    //     entity: [
    //         {
    //             value: enumSearchType.Invoice,
    //             viewValue: 'Facture',
    //             permission: EnumPermissions.INVOICES_ACCESS
    //         }
    //     ],
    //     permissions: [EnumPermissions.INVOICES_ACCESS]
    // }
];
