import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { PopupModule } from '@progress/kendo-angular-popup';

import { ConfirmDialogComponent } from '@features/common/confirm-dialog/confirm-dialog.component';
import { ConfirmDeleteDialogComponent } from './confirm-delete-dialog/confirm-delete-dialog.component';

const componentExport = [ConfirmDialogComponent, ConfirmDeleteDialogComponent];

@NgModule({
    imports: [CommonModule, MatProgressBarModule, PopupModule, MatIconModule],
    declarations: [...componentExport],
    exports: [...componentExport],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ConfirmDialogModule {}
