import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { GroupsService } from '@app/core/http/perimeter/groups.service';
import { GroupPerimeterInfosModel } from '@app/core/models/perimeters/group/group-perimeter-infos.model';
import { GroupFilter } from '@app/core/models/perimeters/group/group.filter';
import { PerimeterItemModel } from '@app/core/models/perimeters/perimeter-item.model';
import { PagedContext } from '@app/features/shared/models/paged-context';
import { DomainEnum } from '@app/shared/constants/domain.enum';

@Component({
    selector: 'vertuoz-group-selector',
    templateUrl: './groups-selector.component.html',
    styleUrls: ['./groups-selector.component.scss']
})
export class GroupsSelectorComponent implements OnInit, OnChanges {
    @Input() initialGroupsProperty: Array<GroupPerimeterInfosModel>;
    @Input() domains: Array<DomainEnum>;
    @Input() selectedGroups: Array<GroupPerimeterInfosModel>;
    @Input() updateMode: boolean;
    @Input() searchKey = '';
    initialValue: Array<GroupPerimeterInfosModel> = null;
    itemsCount = null;
    direction = '';
    // params pour infinit scroll
    throttle = 100;
    scrollDistance = 2;
    scrollWindow = false;
    private inputCount = 0;
    private inputDomainAlreadyApply = false;

    // todo: import and use the commun model when it is finished
    public groupsInfos: Array<GroupPerimeterInfosModel> = [];
    // liste des config pour l'endpoint groups
    private pagination: PagedContext = {
        currentPage: 1,
        pageSize: 10,
        sortDesc: false
    };

    public isLoading = false;
    public searchMode = false;

    constructor(private groupsService: GroupsService) {}

    ngOnInit(): void {
        // init to empty if no selected groups
        if (!this.selectedGroups) {
            this.selectedGroups = [];
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.initialGroupsProperty && changes.initialGroupsProperty.currentValue) {
            if (this.initialValue === null) {
                this.initialValue = [...this.initialGroupsProperty];
            }
        }
        if (changes.domains) {
            this.itemsCount = undefined;
            this.groupsInfos.length = 0;
            this.pagination.currentPage = 1;
            // si les domaines changes il faut réinitialiser la selection
            if (this.selectedGroups && this.selectedGroups.length > 0 && this.inputCount > 0) {
                // TODO remove selection domaines
                this.removeSelection();
            }
            this.inputDomainAlreadyApply = true;
            this.defineFilter();
        }
        if (changes.selectedGroups) {
            if (this.selectedGroups && this.selectedGroups.length > 0) {
                this.inputCount++;
                if (
                    this.groupsInfosNoSelected.length < 10 &&
                    this.domains &&
                    this.domains.length > 0 &&
                    !this.inputDomainAlreadyApply
                ) {
                    this.defineFilter();
                }
            }
        }

        if (changes.searchKey && !changes.searchKey.firstChange && this.searchMode) {
            if (changes.searchKey.currentValue !== changes.searchKey.previousValue) {
                this.pagination.currentPage = 1;
                this.groupsInfos = [];
                this.isLoading = true;
                this.defineFilter();
            }
        }
    }

    defineFilter(): void {
        let _searchKeyword = '';

        const groupFilter = <GroupFilter>{ filterByDomainIds: this.domains, filterByGroupTerm: '' };

        _searchKeyword = this.searchKey;
        groupFilter.filterByGroupTerm = _searchKeyword;

        if (!this.itemsCount || this.itemsCount > this.groupsInfos.length) {
            this.appendGroups(groupFilter, _searchKeyword);
        }
    }

    appendGroups(groupFilter: GroupFilter, _searchKeyword: string): void {
        this.groupsService.getGroups('', groupFilter, this.pagination).subscribe(result => {
            this.isLoading = false;
            if (result && result.results && result.results.length) {
                // a temp solution to get only unique groups on pagination
                for (const item of result.results) {
                    if (
                        !this.groupsInfos.some(
                            i =>
                                i.groupPerimeterId === item.groupPerimeterId &&
                                i.levelId === item.levelId
                        )
                    ) {
                        this.groupsInfos.push(item);
                    }
                }

                this.pagination.currentPage = this.pagination.currentPage + 1;
                this.itemsCount = result.rowCount;
            }
        });
    }

    get groupsInfosNoSelected(): Array<GroupPerimeterInfosModel> {
        return this.groupsInfos.filter(g => !this.isChecked(g.groupPerimeterId));
    }

    // return if a specific group is checked or not
    isChecked(groupId: number): boolean {
        return this.selectedGroups.findIndex(group => group.groupPerimeterId === groupId) > -1;
    }

    applyGroup($event: boolean, group: GroupPerimeterInfosModel): void {
        const groupToApply = group;

        if ($event) {
            if (this.selectedGroups.indexOf(groupToApply) === -1) {
                this.selectedGroups.push(groupToApply);
            }
        } else {
            const indexToDelete = this.selectedGroups.findIndex(
                grp => grp.groupPerimeterId === group.groupPerimeterId
            );
            if (indexToDelete > -1) {
                this.selectedGroups.splice(indexToDelete, 1);
            }
        }

        // si la liste des éléments non sélectionnés se vide rapidement on ajoute des éléments si c'est possible
        if (this.groupsInfosNoSelected.length < 3) {
            this.defineFilter();
        }
    }

    /**
     *
     * @param selectedGroups list to sort
     */
    sortGroupsList(selectedGroups: PerimeterItemModel[]): PerimeterItemModel[] {
        if (!selectedGroups) {
            return [];
        }

        return selectedGroups.sort((a, b) => a.itemLabel.localeCompare(b.itemLabel));
    }

    /**
     *
     * @param groupsInfos
     */
    sortGroupsInfosList(groupsInfos: GroupPerimeterInfosModel[]): GroupPerimeterInfosModel[] {
        if (!groupsInfos) {
            return [];
        }
        return groupsInfos.sort((a, b) => a.name.localeCompare(b.name));
    }

    public removeSelection(): void {
        this.selectedGroups.length = 0;
    }

    public resetDefaultSelection(): void {
        this.removeSelection();
        if (this.initialValue !== null) {
            this.selectedGroups.push(...this.initialValue);
        }
    }

    public searchGroup(): void {
        this.searchMode = true;
    }
    public stopSearchModeReload(): void {
        this.pagination.currentPage = 1;
        this.groupsInfos = [];
        this.isLoading = true;
        this.searchKey = '';
        this.defineFilter();
        this.stopSearchMode();
    }

    public stopSearchMode(): void {
        this.searchMode = false;
    }
}
