import { Injectable } from '@angular/core';
import { FileExportType } from '@app/shared/constants/export.enum';
import { LinkedFileAllowedFileType } from '../../../shared/constants/file.enum';

@Injectable({
    providedIn: 'root'
})
export class FileService {
    getSvgIconName(fileExtension: string): string {
        let svgIconName = '';
        switch ((fileExtension as unknown) as LinkedFileAllowedFileType) {
            case LinkedFileAllowedFileType.csv:
                svgIconName = 'csv_file';
                break;
            case LinkedFileAllowedFileType.doc:
            case LinkedFileAllowedFileType.docx:
                svgIconName = 'doc_file';
                break;
            case LinkedFileAllowedFileType.jpeg:
            case LinkedFileAllowedFileType.jpg:
                svgIconName = 'jpg_file';
                break;
            case LinkedFileAllowedFileType.pdf:
                svgIconName = 'pdf_file';
                break;
            case LinkedFileAllowedFileType.png:
                svgIconName = 'png_file';
                break;
            case LinkedFileAllowedFileType.txt:
                svgIconName = 'txt_file';
                break;
            case LinkedFileAllowedFileType.xls:
            case LinkedFileAllowedFileType.xlsx:
                svgIconName = 'xls_file';
                break;
            case LinkedFileAllowedFileType.odt:
                svgIconName = 'odt_file';
                break;
            case LinkedFileAllowedFileType.ods:
                svgIconName = 'ods_file';
                break;
            default:
                svgIconName = 'unknown_file';
                break;
        }
        return svgIconName;
    }

    isAcceptedFileFormat(fileName: string): boolean {
        const ext = fileName.split('.')[1];
        const allowedType = Object.values(LinkedFileAllowedFileType);
        return allowedType.includes((ext as unknown) as LinkedFileAllowedFileType) ? true : false;
    }
}
