import { GlobalPositionStrategy, Overlay, OverlayRef } from '@angular/cdk/overlay';
import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { forkJoin } from 'rxjs';

import { DataControlRulesService } from '@app/core/http/data-control/data-control-rules.service';
import { DeviceService } from '@app/core/http/devices/device.service';
import { GroupsService } from '@app/core/http/perimeter/groups.service';
import {
    DataControlCategory,
    DataControlType
} from '@app/core/models/data-control/enums/data-control.enum';
import { DataControlRuleFilter } from '@app/core/models/data-control/filters/data-control.filter';
import { DataControlLocationModel } from '@app/core/models/data-control/models/data-control-rule.model';
import { PredicateDeviceDto, SensorModel } from '@app/features/device/models/models';
import { PagedContext } from '@app/features/shared/models/paged-context';
import { MeteringPointsService } from '@core/http/meteringplan/meteringpoint/metering-point.service';
import { MeteringPointsSupplierContractsModel } from '@core/models/meteringplan/meteringpoint/metering-points-supplier-contracts.model';
import { PatchGroupsDto } from '@core/models/perimeters/group/group-perimeter.model';
import { EnumPermissions } from '@core/models/permissions/permissions.enum';
import { enumGeographicLevelId } from '@shared/constants/common.enum';
import { DomainEnum } from '@shared/constants/domain.enum';
import { Operation } from '@shared/constants/operation.enum';
import { VertuozAssignmentGroupsServiceService } from '@shared/directive/vertuoz-assignment/vertuoz-assignment-groups-service.service';
import {
    AssignmentItemsType,
    Mode
} from '@shared/directive/vertuoz-assignment/vertuoz-assignment.directive';
import { ToasterService } from '@vertuoz/vertuoz-library';
import { FileExportType } from '../constants/export.enum';
import { PerimeterScope } from '../constants/filters.enum';
import { sensorMeasureType } from '../constants/sensor-measure-types.enum';

/**
 * Generic component to display buttons on bottom of the screen in order to perform
 * actions in bulk.
 * As a consequence, this component is to be used in context of multiselection grid, but could
 * be tweaked to function otherwise.
 *
 * In inputs it takes the number of selected items in the grid.
 * Then, for each button to perform action it takes a couple of emailMode and toggleEmail output.
 * toggleEmail returns a boolean, where true means it toggles on the item and false if it toggles off.
 *
 * In order to add new buttons and actions, use either ToggleComponent or your own component.
 * For all uses cases that doesn't fit ToggleComponent, create your own leveraging ButtonDialogComponent.
 * See RemoveComponent as an example.
 */
@Component({
    selector: 'bulk-action-snackbar',
    templateUrl: './bulk-action-snackbar.component.html',
    styleUrls: ['./bulk-action-snackbar.component.scss']
})
export class BulkActionSnackbarComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() selectedItemsCount: number;
    @Input() selectedItems: number[];

    @Input() propertyGroupSelection = 'perimeterItemsIds';

    @Input() fluidsAssociatedToContracts: number[] = [];
    @Input() emailMode: boolean;
    @Input() smsMode: boolean;
    @Input() alertMode: boolean;

    // show warning message by default
    @Input() showWarningMessage = true;
    @Input() removeMode: boolean;
    @Input() isDeletingDisabled: boolean;
    @Input() toolTipDeleteMessage = 'common.action.delete';

    @Input() associateMode: boolean;
    @Input() dissociateMode: boolean;

    @Input() assignmentItemsTypes: AssignmentItemsType[] = [AssignmentItemsType.GROUP];

    @Input() newItemMode = true;
    @Input() includeMode: boolean;
    @Input() customContentMode: boolean;
    @Input() hasUserPhoneNumber: boolean;
    @Input() withoutBorder = false;
    @Input() itemType = 'élements';

    @Input() customMessage = '';
    @Input() customEmptyAddMessage: string;
    @Input() customEmptyRemoveMessage: string;
    @Input() elementInfos: string[] = [];

    @Output() toggleEmail = new EventEmitter<boolean>();
    @Output() toggleSms = new EventEmitter<boolean>();
    @Output() toggleAlert = new EventEmitter<boolean>();
    @Output() remove = new EventEmitter<boolean>();
    @Output() toggleIncludeToAnalyse = new EventEmitter<boolean>();

    @Input() levelId: enumGeographicLevelId;
    @Input() domainId: DomainEnum;

    @Input() isBulkActionSnackbarContent;
    public isBulkActionSnackbar = true;

    @Output() isOpen = new EventEmitter();

    /**
     * Notify page for activate/deactivate loader
     */
    @Output() doingBulkAction = new EventEmitter<boolean>();

    @ViewChild('portal') snackPortalComponent;
    private overlayRef: OverlayRef;

    loadingGroups = false;

    // Enums
    public typeFichier = FileExportType;
    public EnumPermissions = EnumPermissions;
    public AssignmentItemsType = AssignmentItemsType;
    public AssignmentItemsOperation = Operation;
    public Mode = Mode;

    public selectionProperty = '';

    constructor(
        private overlay: Overlay,
        public groupsService: GroupsService,
        public meteringPointsService: MeteringPointsService,
        public deviceService: DeviceService,
        private vertuozAssignmentGroupsServiceService: VertuozAssignmentGroupsServiceService,
        private zone: NgZone,
        private toasterService: ToasterService,
        private dataControlRulesService: DataControlRulesService
    ) {}

    ngOnInit(): void {
        if (this.propertyGroupSelection === 'usersIds') {
            this.selectionProperty = 'filterByUserId';
        }

        this.isOpen.emit();
    }

    ngAfterViewInit(): void {
        this.zone.runOutsideAngular(() => {
            const positionStrategy = new GlobalPositionStrategy();
            positionStrategy.bottom();
            this.overlayRef = this.overlay.create({
                positionStrategy,
                width: '100%',
                height: 100
            });
            this.overlayRef.attach(this.snackPortalComponent);
        });
    }

    ngOnDestroy(): void {
        this.overlayRef.detach();
    }

    associateTo($event: number[], assignmentType: AssignmentItemsType): void {
        if (assignmentType === AssignmentItemsType.GROUP) {
            this.patchSelectedGroups($event, true);
        }
        if (assignmentType === AssignmentItemsType.SUPPLIER_CONTRACT) {
            this.patchSelectedSupplierContracts(this.selectedItems, $event, Operation.ADD);
        }
        if (assignmentType === AssignmentItemsType.DATARULE && this.itemType == 'sensor') {
            this.patchSelectedSensorRules($event, this.selectedItems, Operation.ADD);
        }

        if (assignmentType === AssignmentItemsType.DATARULE && this.itemType == 'pdc') {
            this.patchSelectedRules($event, this.selectedItems, Operation.ADD);
        }
    }

    dissociateFrom($event: number[], assignmentType: AssignmentItemsType): void {
        if (assignmentType === AssignmentItemsType.GROUP) {
            this.patchSelectedGroups($event, false);
        }
        if (assignmentType === AssignmentItemsType.SUPPLIER_CONTRACT) {
            this.patchSelectedSupplierContracts(this.selectedItems, $event, Operation.REMOVE);
        }
        if (assignmentType === AssignmentItemsType.DATARULE && this.itemType == 'sensor') {
            this.patchSelectedSensorRules($event, this.selectedItems, Operation.REMOVE);
        }

        if (assignmentType === AssignmentItemsType.DATARULE && this.itemType == 'pdc') {
            this.patchSelectedRules($event, this.selectedItems, Operation.REMOVE);
        }
    }

    // patch groups
    private patchSelectedGroups(selectedGroupsIds: number[], hasToAddGroups: boolean): void {
        this.loadingGroups = true;
        const patchGroupsDto: PatchGroupsDto = <PatchGroupsDto>{
            groupsIds: selectedGroupsIds,
            hasToAddGroups: hasToAddGroups
        };
        patchGroupsDto[this.propertyGroupSelection] = this.selectedItems;

        this.groupsService.updateGroupsContents(patchGroupsDto).subscribe(
            result => {
                this.toasterService.showSuccess(
                    this.vertuozAssignmentGroupsServiceService.getToasterMessage(
                        hasToAddGroups,
                        this.levelId
                    )
                );
            },
            error => {
                this.toasterService.showError(`Erreur lors de la modification du groupe.`);
            }
        );
    }

    // patch supplier contracts
    private patchSelectedSupplierContracts(
        meteringPointIds: number[],
        supplierContractIds: number[],
        operation: Operation
    ): void {
        const selectedMeteringPoints = [];

        supplierContractIds.forEach(supplierContractId => {
            selectedMeteringPoints.push(<MeteringPointsSupplierContractsModel>{
                meteringPointIds: meteringPointIds,
                supplierContractId: supplierContractId
            });
        });

        if (operation === Operation.ADD) {
            this.meteringPointsService
                .associateMeteringPointToSupplierContract(selectedMeteringPoints)
                .subscribe(
                    () => {
                        this.toasterService.showSuccess(
                            'Les points de comptage sélectionnés sont bien ajoutés au(x) contrat(s) fournisseurs sélectionné(s).'
                        );
                    },
                    () => {
                        this.toasterService.showError(
                            `Erreur lors de la modification du contrat fournisseur.`
                        );
                    }
                );
        } else {
            this.meteringPointsService
                .dissociateMeteringPointsFromSupplierContract(selectedMeteringPoints)
                .subscribe(
                    () => {
                        this.toasterService.showSuccess(
                            'Les points de comptage sélectionnés sont bien retirés du/des contrat(s) fournisseur(s) sélectionné(s)'
                        );
                    },
                    () => {
                        this.toasterService.showError(
                            `Erreur lors du retrait des points de comptages du/des contrat(s) fournisseur(s) sélectionné(s).`
                        );
                    }
                );
        }
    }

    /**
     * associate or dissociate sensors with control rules
     *
     * @param rulesIds : Ids of the rules user selected
     * @param deviceIds : Ids of the device selected in the grid
     * @param operation : Association / Dissociation
     */
    private patchSelectedSensorRules(
        rulesIds: number[],
        deviceIds: number[],
        operation: Operation
    ): void {
        this.doingBulkAction.emit(true);
        const data = forkJoin({
            // Get sensors from selected devices
            devices: this.deviceService.getDeviceList(<PredicateDeviceDto>{
                deviceIds: deviceIds,
                page: 0,
                pageSize: -1
            }),
            // Get selected rules
            rules: this.dataControlRulesService.getRules(
                <PagedContext>{ pageSize: -1 },
                <DataControlRuleFilter>{ ruleIds: rulesIds },
                PerimeterScope.activeSelectedPerimeter
            )
        });

        switch (operation) {
            case Operation.ADD:
                data.subscribe(({ devices, rules }) => {
                    const sensorsOnWhichAddRules: SensorModel[] = [];
                    devices?.results.forEach(d => {
                        sensorsOnWhichAddRules.push(...d.sensors);
                    });

                    rules?.results.forEach(r => {
                        sensorsOnWhichAddRules.forEach(s => {
                            this.doingBulkAction.emit(true);
                            // Only sensors of the same type as the rule are eligible
                            // Are considered to be of the same type:
                            // - sensorMeasureType.Temperature
                            // - sensorMeasureType.IndoorTemperature
                            // - sensorMeasureType.OutdoorTemperature
                            if (
                                r.ruleType === DataControlType.MissingDataSensorMeasure ||
                                s.sensorMeasureType.measureTypeId === r.sensorMeasureTypeId ||
                                ((r.sensorMeasureTypeId === sensorMeasureType.Temperature ||
                                    r.sensorMeasureTypeId === sensorMeasureType.IndoorTemperature ||
                                    r.sensorMeasureTypeId ===
                                        sensorMeasureType.OutdoorTemperature) &&
                                    (s.sensorMeasureType.measureTypeId ===
                                        sensorMeasureType.Temperature ||
                                        s.sensorMeasureType.measureTypeId ===
                                            sensorMeasureType.IndoorTemperature ||
                                        s.sensorMeasureType.measureTypeId ===
                                            sensorMeasureType.OutdoorTemperature))
                            ) {
                                // check for do not reassign to a sensor, the rule already assigned to this sensor
                                if (
                                    r.locations.findIndex(sl => sl.sensorId === s.sensorId) === -1
                                ) {
                                    const dataControlSensorLocationModel = new DataControlLocationModel();
                                    dataControlSensorLocationModel.sensorId = s.sensorId;
                                    dataControlSensorLocationModel.sensorLabel = s.sensorLabel;
                                    r.locations.push(dataControlSensorLocationModel);
                                }
                            }
                        });
                        this.dataControlRulesService.updateRule(r.ruleId, r).subscribe(
                            () => {
                                this.toasterService.showSuccess(
                                    'Capteurs associés aux règles de contrôle'
                                );
                                this.doingBulkAction.emit(false);
                            },
                            () => {
                                this.toasterService.showError(
                                    "Echec de l'association des capteurs aux règles de contrôle"
                                );
                                this.doingBulkAction.emit(false);
                            }
                        );
                    });
                });
                break;

            case Operation.REMOVE:
                data.subscribe(({ devices, rules }) => {
                    this.doingBulkAction.emit(true);
                    const sensorsOnWhichRemoveRules: SensorModel[] = [];
                    devices?.results.forEach(d => {
                        sensorsOnWhichRemoveRules.push(...d.sensors);
                    });

                    rules?.results.forEach(r => {
                        const filtered = r.locations.filter(function(
                            value: DataControlLocationModel
                        ): boolean {
                            return !sensorsOnWhichRemoveRules
                                .map(e => e.sensorId)
                                .includes(value.sensorId);
                        });
                        r.locations = filtered;
                        this.dataControlRulesService.updateRule(r.ruleId, r).subscribe(
                            () => {
                                this.toasterService.showSuccess(
                                    'Capteurs dissociés des règles de contrôle'
                                );
                                this.doingBulkAction.emit(false);
                            },
                            () => {
                                this.toasterService.showError(
                                    'Echec de la dissociation des capteurs aux règles de contrôle'
                                );
                                this.doingBulkAction.emit(false);
                            }
                        );
                    });
                });
                break;

            default:
                this.doingBulkAction.emit(false);
                break;
        }
    }

    private patchSelectedRules(
        ruleIds: number[],
        locationIds: number[],
        operation: Operation
    ): void {
        let successMsg: string, errorMsg: string;
        let propertyName: string, itemName;

        if (this.itemType === 'pdc') {
            propertyName = 'meteringPointId';
            itemName = 'Points de comptage';
        }

        const data = forkJoin({
            meteringPoints: this.meteringPointsService.getMeteringPoints(
                { meteringPointIds: locationIds },
                null,
                null,
                null,
                null,
                PerimeterScope.activeGeographicRight,
                null
            ),
            rules: this.dataControlRulesService.getRules(
                new PagedContext(),
                <DataControlRuleFilter>{ ruleIds: ruleIds },
                PerimeterScope.activeGeographicRight
            )
        });

        data.subscribe(({ meteringPoints, rules }) => {
            if (operation === Operation.ADD) {
                successMsg = `${itemName} associés aux règles de contrôle`;
                errorMsg = `Echec de l\'assocation des ${itemName.toLowerCase()} aux règles de contrôle`;

                rules.results.forEach(rule => {
                    if (!rule.locations) {
                        rule.locations = [];
                    }

                    let fluidId;
                    if (
                        rule.ruleCategory === DataControlCategory.Consumption &&
                        rule.fluidIds?.length > 0
                    ) {
                        fluidId = rule.fluidIds[0];
                    }

                    // Associate all metering points to the rule that has the same fluid (only for consumption)
                    locationIds.forEach(l => {
                        if (
                            !rule.locations.find(r => r[propertyName] === l) &&
                            (rule.ruleCategory !== DataControlCategory.Consumption ||
                                (rule.ruleCategory === DataControlCategory.Consumption &&
                                    meteringPoints.results.find(
                                        m => m.id === l && m.fluidId === fluidId
                                    )))
                        ) {
                            const dataLocation: DataControlLocationModel = new DataControlLocationModel();
                            dataLocation[propertyName] = l;
                            rule.locations.push(dataLocation);
                        }
                    });
                });
            } else if (operation === Operation.REMOVE) {
                successMsg = `${itemName} dissociés des règles de contrôle`;
                errorMsg = `Echec de la dissociation des ${itemName} des règles de contrôle`;

                rules.results.forEach(rule => {
                    if (!rule.locations) {
                        return;
                    }

                    locationIds.forEach(l => {
                        const locationIndex = rule.locations.findIndex(r => r[propertyName] === l);

                        if (locationIndex !== -1) {
                            rule.locations.splice(locationIndex, 1);
                        }
                    });
                });
            }

            rules.results.forEach(r => {
                this.dataControlRulesService.updateRule(r.ruleId, r).subscribe(
                    () => {
                        this.toasterService.showSuccess(successMsg);
                        this.doingBulkAction.emit(false);
                    },
                    () => {
                        this.toasterService.showError(errorMsg);
                        this.doingBulkAction.emit(false);
                    }
                );
            });
        });
    }
}
