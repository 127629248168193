import { Component, Input, OnInit } from '@angular/core';
import { IMenu } from '@core/menu/IMenu';

@Component({
    selector: 'hub-menu',
    templateUrl: './hub-menu.component.html',
    styleUrls: ['./hub-menu.component.scss']
})
export class HubMenuComponent implements OnInit {
    @Input() hubMenu: Array<IMenu>;

    constructor() {}

    ngOnInit(): void {}
}
