<button
  mat-flat-button
  class="btn-md btn-export w-100"
  (click)="selectedType.emit(fileExportType)"
  [disabled]="activeRequestHttp"
>
  <mat-icon
    svgIcon="{{
      enumfileExportType[fileExportType] === enumfileExportType[enumfileExportType.PDF]
        ? 'file_pdf'
        : 'file_spreadsheet'
    }}"
    class="me-2"
    [ngClass]="{
      'color-success':
        enumfileExportType[fileExportType] === enumfileExportType[enumfileExportType.XLSX],
      'color-primary-700':
        enumfileExportType[fileExportType] === enumfileExportType[enumfileExportType.ODS] ||
        enumfileExportType[fileExportType] === enumfileExportType[enumfileExportType.CSV]
    }"
  ></mat-icon>
  <span>Exporter en {{ enumfileExportType[fileExportType] }}</span>
</button>
