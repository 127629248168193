import { Injectable } from '@angular/core';

import { BaseUserModel } from '../models/user/base.user.model';
import { UserModel } from '../models/user/user.model';

@Injectable({
    providedIn: 'root'
})
export class UsersFactory {
    constructor() {}

    public convert(
        user: UserModel,
        offerId: number,
        contractId: number,
        clientId: number
    ): BaseUserModel {
        const baseUser = <BaseUserModel>{};
        baseUser.id = user.userId;
        baseUser.login = user.login;
        baseUser.roleId = user.role.id;
        baseUser.civility = user.civility;
        baseUser.familyName = user.familyName;
        baseUser.firstName = user.firstName;
        baseUser.entity = user.entity;
        baseUser.function = user.function;
        baseUser.entitySectorId = user.entitySectorId;
        baseUser.loginCreator = user.loginCreator;
        baseUser.creatorUserId = user.creatorUserId;
        baseUser.updateLogin = user.updateLogin;
        baseUser.contractId = contractId;
        baseUser.offerId = offerId;
        baseUser.clientId = clientId;
        baseUser.newslettersAccepted = user.newslettersAccepted;
        baseUser.alertBySms = user.alertBySms;
        return baseUser;
    }
}
