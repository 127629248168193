export enum LinkedFileAllowedFileType {
    xls = 'xls',
    xlsx = 'xlsx',
    doc = 'doc',
    docx = 'docx',
    pdf = 'pdf',
    txt = 'txt',
    csv = 'csv',
    jpg = 'jpg',
    jpeg = 'jpeg',
    png = 'png',
    odt = 'odt',
    ods = 'ods'
}
