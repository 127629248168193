import { DOCUMENT } from '@angular/common';
import { Component, Inject, Injector, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { SectionsService } from '@core/http/section/sections.service';
import { MenuService } from '@core/menu/menu.service';
import { ISection } from '@core/models/section/section.model';
import { ThemesService } from '@core/services/themes.service';
import { SettingsService } from '@core/settings/settings.service';
import * as _ from 'lodash';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const $: any;

class IMenu {
    text: string;
    heading?: boolean;
    link?: string;
    elink?: string;
    target?: string;
    icon?: string;
    svgIcon?: string;
    alert?: string;
    position?: string;
    arrow?: boolean;
    submenu?: Array<IMenu>;
    sectionIndex?: number;

    constructor(init?: Partial<IMenu>) {
        Object.assign(this, init);
    }
}
@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
    sidebarMenu: Array<IMenu>;
    svgIcons: Array<string> = ['database'];
    menuItems: Array<IMenu>;
    router: Router;
    sbclickEvent = 'click.sidebar-toggle';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    $doc: any = null;

    constructor(
        public menu: MenuService,
        public settings: SettingsService,
        private sectionService: SectionsService,
        public injector: Injector,
        public themes: ThemesService,
        @Inject(DOCUMENT) public document: Document,
        private renderer: Renderer2
    ) {}

    ngOnInit(): void {
        if (this.menu.sidebarMenuItems.length === 0) {
            this.mapSidebarMenu();
        }

        this.router = this.injector.get(Router);
        this.router.events.subscribe(val => {
            // close any submenu opened when route changes
            this.removeFloatingNav();
            // scroll view to top
            window.scrollTo(0, 0);
            // close sidebar on route change
            this.settings.setLayoutSetting('isSidebarToggled', false);
        });

        this.renderer.addClass(this.document.body, 'embedded-body');

        // enable sidebar autoclose from extenal clicks
        this.anyClickClose();
        this.menuItems = this.menu.getMenu('sidebar');
    }

    public map(items: ISection[]): void {
        this.sidebarMenu.push(
            ..._.orderBy(
                items.map(
                    item =>
                        new IMenu({
                            text: item.text,
                            link: item.link.includes('http') ? null : item.link,
                            elink: item.link.includes('http') ? item.link : null,
                            icon: !this.svgIcons.includes(item.icon) ? item.icon : null,
                            svgIcon: this.svgIcons.includes(item.icon) ? item.icon : null,
                            arrow: item.children !== null,
                            heading: item.heading,
                            sectionIndex: item.sectionIndex,
                            submenu:
                                !item.heading && item.children
                                    ? _.orderBy(
                                          item.children.map(
                                              subItem =>
                                                  new IMenu({
                                                      text: subItem.text,
                                                      link: subItem.link,
                                                      icon: subItem.icon,
                                                      sectionIndex: subItem.sectionIndex
                                                  })
                                          ),
                                          'sectionIndex',
                                          'asc'
                                      )
                                    : null
                        })
                ),
                'sectionIndex',
                'asc'
            )
        );
    }
    public mapSidebarMenu(): void {
        this.sectionService.getSections().subscribe(items => {
            this.sidebarMenu = [];
            this.map(items);
            if (this.sidebarMenu.find(e => e.heading)) {
                this.map(items.filter(item => item.heading === true).map(item => item.children)[0]);
            }
            this.menu.addMenu(this.sidebarMenu, 'sidebar');
        });
    }

    anyClickClose(): void {
        this.$doc = $(document).on(this.sbclickEvent, e => {
            if (!$(e.target).parents('.aside-container').length) {
                this.settings.setLayoutSetting('isSidebarToggled', false);
            }
        });
    }

    ngOnDestroy(): void {
        if (this.$doc) {
            this.$doc.off(this.sbclickEvent);
        }

        this.renderer.removeAttribute(this.document.body, 'class');
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toggleSubmenuClick(event: any): void {
        event.preventDefault();
        const arrowDown = $(event.currentTarget).find('.arrow-down');
        const arrow = $(event.currentTarget).find('.arrow');
        $(event.currentTarget)
            .parent('li')
            .siblings()
            .find('.arrow-down')
            .hide();
        $(event.currentTarget)
            .parent('li')
            .siblings()
            .find('.arrow')
            .show();

        if (
            !this.settings.getLayoutSetting('isSidebarCollapsed') &&
            !this.settings.getLayoutSetting('isSidebarCollapsedText') &&
            !this.isEnabledHover()
        ) {
            const ul = $(event.currentTarget.nextElementSibling);

            // hide other submenus
            const parentNav = ul.parents('.sidebar-subnav');
            $('.sidebar-subnav').each((idx, el) => {
                const $el = $(el);
                // if element is not a parent or self ul
                if (el !== parentNav[0] && el !== ul[0]) {
                    this.closeMenu($el);
                }
            });

            // abort if not UL to process
            if (!ul.length) {
                return;
            }

            // any child menu should start closed
            ul.find('.sidebar-subnav').each((idx, el) => {
                this.closeMenu($(el));
            });

            // toggle UL height
            const ulHeight = ul.css('height');
            if (ulHeight === 'auto' || parseInt(ulHeight, 10)) {
                this.closeMenu(ul);
                arrowDown.hide();
                arrow.show();
            } else {
                // expand menu
                ul.on('transitionend', () => {
                    ul.css('height', 'auto').off('transitionend');
                }).css('height', ul[0].scrollHeight);
                // add class to manage animation
                ul.addClass('opening');
                arrowDown.show();
                arrow.hide();
            }
        }
    }

    // Close menu collapsing height
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    closeMenu(elem: any): void {
        elem.css('height', elem[0].scrollHeight); // set height
        elem.css('height', 0); // and move to zero to collapse
        elem.removeClass('opening');
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toggleSubmenuHover(event: any): void {
        const self = this;
        if (
            this.settings.getLayoutSetting('isSidebarCollapsed') ||
            this.settings.getLayoutSetting('isSidebarCollapsedText') ||
            this.isEnabledHover()
        ) {
            event.preventDefault();

            this.removeFloatingNav();

            const ul = $(event.currentTarget.nextElementSibling);
            const anchor = $(event.currentTarget);

            if (!ul.length) {
                return; // if not submenu return
            }

            const $aside = $('.aside-container');
            const $asideInner = $aside.children('.aside-inner'); // for top offset calculation
            const $sidebar = $asideInner.children('.sidebar');
            const mar =
                parseInt($asideInner.css('padding-top'), 0) +
                parseInt($aside.css('padding-top'), 0);

            const itemTop = anchor.parent().position().top + mar - $sidebar.scrollTop();

            const floatingNav = ul.clone().appendTo($aside);
            const vwHeight = document.body.clientHeight;

            // const itemTop = anchor.position().top || anchor.offset().top;

            floatingNav.addClass('nav-floating');

            // each item has ~40px height
            // multiply to force space for at least N items
            const safeOffsetValue = 40 * 5;
            const navHeight = floatingNav.outerHeight(true) + 2; // 2px border
            const safeOffset = navHeight < safeOffsetValue ? navHeight : safeOffsetValue;

            const displacement = 25; // displacement in px from bottom

            // if not enough space to show N items, use then calculated 'safeOffset'
            const menuTop =
                vwHeight - itemTop > safeOffset ? itemTop : vwHeight - safeOffset - displacement;

            floatingNav
                .removeClass('opening') // necesary for demo if switched between normal//collapsed mode
                .css({
                    position: this.settings.getLayoutSetting('isLayoutFixed')
                        ? 'fixed'
                        : 'absolute',
                    top: menuTop,
                    bottom:
                        floatingNav.outerHeight(true) + menuTop > vwHeight
                            ? displacement + 'px'
                            : 'auto'
                });

            floatingNav
                .on('mouseleave', () => {
                    floatingNav.remove();
                })
                .find('a')
                .on('click', function(e: MouseEvent): void {
                    e.preventDefault(); // prevents page reload on click
                    // get the exact route path to navigate
                    const routeTo = $(this).attr('route');
                    if (routeTo) {
                        self.router.navigate([routeTo]);
                    }
                });

            this.listenForExternalClicks();
        }
    }

    listenForExternalClicks(): void {
        const $doc = $(document).on('click.sidebar', e => {
            if (!$(e.target).parents('.aside-container').length) {
                this.removeFloatingNav();
                $doc.off('click.sidebar');
            }
        });
    }

    removeFloatingNav(): void {
        $('.nav-floating').remove();
    }

    isEnabledHover(): string | boolean {
        return this.settings.getLayoutSetting('isSidebarHover');
    }
}
