import { Label } from '../../common/label.model';

import { CharacteristicFeature } from '@app/features/perimeter/property/models/model';
import { LabelModel } from '@app/features/property-data/models/comparison.model';
import { DomainEnum } from '@app/shared/constants/domain.enum';
import { DistributionModel } from './distribution.model';
import { ItemLineDistributionModel } from './item-line-distribution.model';
import { MeteringPointBaseModel } from './metering-point-base.model';
import { RawDistributionModel } from './raw-distribution.model';
import { UsageLineDistributionModel } from './usage-line-distribution.model';

export class MeteringPointModel extends MeteringPointBaseModel {
    constructor() {
        super();
        this.isActive = true;
        this.updateDateActive = new Date();
        this.archiveDistributions = [];
    }
    public isActive: boolean;
    public updateDateActive: Date;
    public delivery: boolean;
    public fluidSubType: Label;
    public domain: DomainEnum;
    public destination: Label;
    public meteringPointSupplyId?: number;
    public meteringPointSupplyLabel?: string;
    public meteringPointSupply: Label;
    public meteringPointType: Label;
    public tariffZone: Label;
    public consummerProfil: Label;
    public useVersion: Label;
    public frequency: Label;
    public emissionFactors: Array<CharacteristicFeature>;
    public gpsLatitude: number;
    public gpsLongitude: number;
    public meteringPointFatherId: number;
    public meteringPointFatherName: string;
    public meteringPointFatherThirdPartyId: string;
    public location: string;
    public renewableEnergyPercents: CharacteristicFeature[];
    public inactiveDistributions: RawDistributionModel[];
    public comments: string;
    public meteringPointUseTypeId: number;
    public meteringPointUseType: string;
    public resaleTariffs: Array<CharacteristicFeature>;
    public enableIntgPdf?: boolean;
    /* Dial section */
    public unit: Label;
    public energyConversionFactors: Array<CharacteristicFeature>;
    public dialMultiplier: number;
    public dialReadingTypeId: number;
    public dialEnergyConversionFactorSupplierId: string;
    /* spécifique front */
    public archiveDistributions: DistributionModel[];
    public cpe: boolean;
    public pipeSizeId: number;
    public pipeSize: LabelModel;
    public power: number;

    public static fillDistribution(meteringPointModel: MeteringPointModel): void {
        meteringPointModel.archiveDistributions = MeteringPointModel.createDistribution(
            meteringPointModel.inactiveDistributions
        );
        const activeDist = MeteringPointModel.createDistribution(
            meteringPointModel.activeDistributions
        );
        meteringPointModel.activeDistribution = activeDist[0];
    }

    private static createDistribution(
        rawDistribution: RawDistributionModel[]
    ): DistributionModel[] {
        const results = new Array<DistributionModel>();
        rawDistribution.sort((a, b) => MeteringPointModel.startDateThenItemId(a, b));
        // Active distribution
        for (const raw of rawDistribution) {
            // s'il n'existe pas de répartition avec cette date de dbut on l'a créer
            if (!results.some(d => d.startDate.getTime() === raw.startDate.getTime())) {
                const distribution = <DistributionModel>{
                    startDate: raw.startDate,
                    endDate: raw.endDate,
                    itemLineDistributions: []
                };
                results.push(distribution);
            }
            const lastDistributionIndex = results.length - 1;
            const lastDistribution = results[lastDistributionIndex];
            // si cet item (zone ou armoire) n'existe pas encore il faut le créer
            if (
                !lastDistribution.itemLineDistributions.some(line => line.item.id === raw.item.id)
            ) {
                const item = <ItemLineDistributionModel>{
                    item: raw.item,
                    parentItem: raw.parentItem,
                    itemPercent: raw.itemPercent,
                    usageDistributions: []
                };
                lastDistribution.itemLineDistributions.push(item);
            }
            const lastItemIndex = lastDistribution.itemLineDistributions.length - 1;
            const lastItem = lastDistribution.itemLineDistributions[lastItemIndex];
            // création de l'usage
            const usage = <UsageLineDistributionModel>{
                usage: raw.usage,
                usagePercent: raw.usagePercent
            };
            lastItem.usageDistributions.push(usage);
        }
        return results;
    }

    private static startDateThenItemId(a: RawDistributionModel, b: RawDistributionModel): number {
        if (b.startDate.getTime() - a.startDate.getTime() !== 0) {
            return b.startDate.getTime() - a.startDate.getTime();
        } else {
            if (a.item.id > b.item.id) {
                return 1;
            } else {
                return -1;
            }
        }
    }

    private static createRawDistribution(
        distributions: DistributionModel[]
    ): RawDistributionModel[] {
        const results = new Array<RawDistributionModel>();
        if (!distributions) {
            return [];
        }

        for (const distribution of distributions) {
            if (distribution?.itemLineDistributions) {
                for (const itemLine of distribution.itemLineDistributions) {
                    for (const usageLine of itemLine.usageDistributions) {
                        const raw = new RawDistributionModel();
                        raw.startDate = distribution.startDate
                            ? distribution.startDate
                            : new Date(1900, 0, 1, 0, 0, 0);
                        raw.endDate = distribution.endDate;
                        raw.item = itemLine.item;
                        raw.itemPercent = itemLine.itemPercent;
                        raw.parentItem = itemLine.parentItem;
                        raw.usage = usageLine.usage;
                        raw.usagePercent = usageLine.usagePercent;
                        results.push(raw);
                    }
                }
            }
        }

        return results;
    }

    public static fillRawDistribution(meteringPointModel: MeteringPointModel): void {
        meteringPointModel.activeDistributions = MeteringPointModel.createRawDistribution([
            meteringPointModel.activeDistribution
        ]);
        meteringPointModel.inactiveDistributions = MeteringPointModel.createRawDistribution(
            meteringPointModel.archiveDistributions
        );
    }
}
