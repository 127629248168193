//#region Imports
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { appRoutes } from '@app/app-root/app-routes.enum';
import { environment } from '@environments/environment';
import { ToasterService } from '@vertuoz/vertuoz-library';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpEnum } from '@app/core/constants/http.enum';

//#endregion

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    //#region Constructor
    constructor(private router: Router, private toasterService: ToasterService) {}
    //#endregion

    //#region Methods
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (event: HttpEvent<any>) => {},
                (err: HttpErrorResponse) => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status === HttpEnum.NotFound) {
                            if (err.error && err.error.ExceptionStatusCode === 4040) {
                                window.location.href = environment.extLinks.portal;
                            }
                        }

                        if (err.status === HttpEnum.Forbidden) {
                            // If the error is the disabled site, it is redirected to the personnalized page
                            if (err.message?.includes('Site disabled')) {
                                this.router.navigateByUrl(appRoutes.siteDisabled);
                                return;
                            }

                            this.toasterService.showError(
                                "Vous n'avez pas les droits pour cette action !"
                            );
                            this.router.navigate([appRoutes.dashboard], {
                                queryParamsHandling: 'preserve'
                            });
                        }
                    }
                }
            )
        );
    }
    //#endregion
}
