<div class="v-stepper pt-3">
  <p class="w-100 d-flex flex-wrap align-items-center m-0 fw-bold pb-3">
    <span
      class="col-3 stepper-badge me-2"
      [ngClass]="stepValide == true ? 'bg-primary-700' : 'bg-gray-600'"
    >
      {{ stepNumber }}
    </span>
    <span
      class="col-9 stepper-title"
      [ngClass]="stepValide == true ? 'color-primary-700' : 'color-gray-500'"
      >{{ stepTitle }}</span
    >
  </p>
  <p class="stepDesc step-ms font-14 pe-5">
    <em>{{ stepDescription }}</em>
  </p>
  <div><ng-content></ng-content></div>
</div>
