//#region Imports
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { PageResult } from '@app/core/models/common/page-result.model';
import { PerimeterItemModel } from '@app/core/models/perimeters/perimeter-item.model';
import { EnumController } from '@app/core/services/end-point-extend';
import { ObjectiveCategory, ObjectiveType } from '@app/features/objectives/models/objective.enum';
import { PagedContext } from '@app/features/shared/models/paged-context';
import { sensorMeasureType } from '@app/shared/constants/sensor-measure-types.enum';
import { environment } from '@environments/environment';
import { BaseService } from '../base.service';

//#endregion

//#region
export class PerimeterFilter {
    /// <summary>
    /// Les entités sélectionnées
    /// on récupère les entités appartenant aux niveaux parents sélectionnés
    /// on exclut les entités du niveau courant déjà sélectionnées
    /// </summary>
    selectedEntityIds: {
        [index: number]: Array<number>;
    };

    /// <summary>
    /// Recherche par libellé, cette recherche annule les autres filtres
    /// </summary>
    perimeterLabel: string;

    /// <summary>
    /// Recherche par libellé, cette recherche annule les autres filtres
    /// </summary>
    perimeterSearch: string;

    /// <summary>
    /// Get the elements that are subject to tertiary decree
    /// </summary>
    IsSubjectToTertiaryDecree?: boolean;

    objectiveCategory?: ObjectiveCategory;

    objectiveType?: ObjectiveType;

    hasObjective?: boolean;

    isInsideTertiaryDecreeGroup?: boolean;

    hasSensors?: boolean;

    sensorMeasureTypeIds?: sensorMeasureType[];

    public constructor(init?: Partial<PerimeterFilter>) {
        Object.assign(this, init);
    }
}
//#endregion

@Injectable({
    providedIn: 'root'
})
export class PerimetersService extends BaseService {
    //#region Fields
    private perimetersApiUrl = `${environment.API_ENDPOINT}${EnumController.Perimeters}`;

    private apiUrl = `${environment.API_ENDPOINT}`;
    //#endregion

    //#region Constructor
    constructor(private http: HttpClient) {
        super();
    }

    //#endregion

    //#region Methods
    /**
     * get perimeter items by level
     * @param level
     * @param filterByName
     * @param currentPage (get all if -1)
     * @param perimeterFilters
     * @param activePerimeter
     */
    public getPerimetersByLevel(
        level: number,
        filterByName: string,
        filterByTextEntry: string,
        currentPage: number,
        perimeterFilters?: {
            [index: number]: Array<number>;
        },
        filters: {
            activePerimeter?: boolean;
            isSubjectToTertiaryDecree?: boolean;
            objectiveCategory?: ObjectiveCategory;
            objectiveType?: ObjectiveType;
            hasObjective?: boolean;
            isInsideTertiaryDecreeGroup?: boolean;
            hasSensors?: boolean;
            sensorMeasureTypeIds?: number[];
        } = {}
    ): Observable<PageResult<PerimeterItemModel>> {
        const pageSize = 10;

        const paging = new PagedContext();

        if (currentPage === -1) {
            // get all results
            paging.pageSize = 0;
            paging.currentPage = 0;
        } else {
            paging.pageSize = pageSize;
            paging.currentPage = currentPage;
        }

        paging.sortField = 'ItemLabel';
        let activePerimeterParams = {};
        if (filters.activePerimeter) {
            activePerimeterParams = { activePerimeter: filters.activePerimeter };
        }

        const perimeterFilter = new PerimeterFilter({
            selectedEntityIds: perimeterFilters,
            perimeterLabel: filterByName,
            perimeterSearch: filterByTextEntry,
            IsSubjectToTertiaryDecree: filters.isSubjectToTertiaryDecree,
            isInsideTertiaryDecreeGroup: filters.isInsideTertiaryDecreeGroup,
            objectiveCategory: filters.objectiveCategory,
            objectiveType: filters.objectiveType,
            hasObjective: filters.hasObjective,
            hasSensors: filters.hasSensors,
            sensorMeasureTypeIds: filters.sensorMeasureTypeIds
        });
        if (perimeterFilters) {
            return this.http.post<string>(this.apiUrl + 'filters', perimeterFilter).pipe(
                mergeMap(filterId => {
                    let queryFilterId = {};
                    if (filterId.length !== 0) {
                        queryFilterId = { filterId: filterId };
                    }
                    const params = this.createHttpParams(
                        paging,
                        { perimeterLabel: filterByName, perimeterSearch: filterByTextEntry },
                        activePerimeterParams,
                        queryFilterId
                    );
                    return this.http.get<PageResult<PerimeterItemModel>>(
                        `${this.perimetersApiUrl}/${level}`,
                        { params }
                    );
                })
            );
        } else {
            const params = this.createHttpParams(paging, perimeterFilter, activePerimeterParams);
            return this.http.get<PageResult<PerimeterItemModel>>(
                `${this.perimetersApiUrl}/${level}`,
                { params }
            );
        }
    }
}
