import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SelectEvent } from '@progress/kendo-angular-layout';

import { DialogMentionsLegalesData } from '../dialog-data.model';

@Component({
    selector: 'vertuoz-mentions-legales',
    templateUrl: './mentions-legales.component.html',
    styleUrls: ['./mentions-legales.component.scss']
})
export class MentionsLegalesComponent implements OnInit {
    /* Titre des sections */
    public MENTIONS_TITLE = `Mentions Légales`;
    public CGU_TITLE = `Conditions Générales d'Utilisation`;
    public CHARTE_TITLE = `Charte d'Utilisation et de Protection des Données Personnelles`;

    public indexSelected = 0;

    /** Orientation du tabstrip */
    @Input() public tabOrientation: string;

    constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: DialogMentionsLegalesData) {
        if (data && data.tabOrientation) {
            this.tabOrientation = data.tabOrientation;
        }
    }

    ngOnInit(): void {
        if (!this.tabOrientation) {
            this.tabOrientation = 'top';
        }
    }

    onTabSelect($event: SelectEvent): void {
        this.indexSelected = $event.index;
    }
}
