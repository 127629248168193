<mat-expansion-panel
  [ngClass]="{
    highlight: updateMode && !searchMode && listItemSelected?.length && highlightMode,
    'no-highlight-mode': !highlightMode
  }"
  class="grid-element-border border-bottom mb-0 py-2"
  (opened)="initPerimeterSelectorLevel()"
  (closed)="destroyFreezeFilter()"
>
  <mat-expansion-panel-header>
    <mat-panel-title class="row">
      <div class="d-flex align-items-center align-self-end col-5 align-self-end">
        <span class="me-2 font-14"> {{ levelLabel }} </span>
        <span
          class="badge badge-text badge-info bg-primary-700 font-12"
          *ngIf="updateMode && listItemSelected?.length"
        >
          <span class="badge-number me-2">{{ listItemSelected.length }}</span>
          <span class="badge-supp cursor-pointer">
            <button
              class="mat-button p-0"
              *ngIf="updateMode && listItemSelected?.length > 0"
              (click)="eventResetLevelSelected($event)"
            >
              <mat-icon class="font-16">clear</mat-icon>
            </button>
          </span>
        </span>
      </div>

      <span class="col-7 color-primary-700 search-info text-end" *ngIf="updateMode && searchMode">
        <span class="pt-1">
          ( <span>{{ countSearchItems }}</span> résultat<ng-container *ngIf="countSearchItems > 1"
            >s
          </ng-container>
          )
        </span>
      </span>
      <span
        *ngIf="
          updateMode &&
          !searchMode &&
          listItemSelected?.length &&
          (lowestLevelMode === true ? true : levelId !== 13 && levelId !== 3)
        "
        class="col-7 text-end font-12 color-primary-700"
      >
        {{ selectedInfoLevel }} {{ listItemSelected?.length }} {{ levelLabelInfos }}
      </span>
      <span
        *ngIf="
          updateMode &&
          !searchMode &&
          listItemSelected?.length &&
          (lowestLevelMode === false ? levelId === 13 || levelId === 3 : false)
        "
        class="col-8 text-end font-12 color-primary-700"
      >
        Accès à {{ listItemSelected?.length }} {{ levelLabelInfos }}
      </span>

      <mat-panel-description class="col-12">
        <div class="labelListContainer">
          <div class="labelList labelList-ellipsis">
            <span class="font-12" *ngIf="partialSelectedLabel && listItemFilter.length > 0"
              >{{ partialSelectedLabel }} :
            </span>
            <span
              *ngFor="let items of listItemFilter; let i = index"
              [ngStyle]="{ display: listItemFilter?.length > 0 ? 'inline' : 'none' }"
              >{{ items.itemLabel }}<span *ngIf="i !== listItemFilter?.length - 1">,</span>
            </span>
            <br *ngIf="listItemFilter.length > 0 && listItemSelected?.length > 0" />
            <strong *ngIf="selectedLabel && listItemSelected?.length > 0"
              >{{ selectedLabel }} :
            </strong>
            <strong
              *ngFor="let items of listItemSelected; let i = index"
              [ngStyle]="{
                display: listItemSelected?.length > 0 ? 'inline' : 'none'
              }"
              >{{ items.itemLabel }}<span *ngIf="i !== listItemSelected?.length - 1">,</span>
            </strong>
          </div>
        </div>
      </mat-panel-description>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <!-- todo: no need for this for now, leave it as a comment if no demande to show this bnt -->
  <!--  <button
    class="clear-btn"
    *ngIf="
      updateMode &&
      lowestLevelMode &&
      (!listItemSelected || listItemSelected?.length === 0) &&
      listItemFilter?.length > 0
    "
    (click)="resetFilter()"
  >
    <mat-icon class="align-middle">clear</mat-icon>
    Effacer mes filtres
  </button>-->

  <div *ngIf="listItemSelected?.length > 0 || listItemFilter?.length > 0" class="row mt-2">
    <mat-checkbox
      class="col-12"
      *ngFor="let item of listItemInfiniteScrollFilter"
      (change)="applyItem($event, item)"
      [indeterminate]="!lowestLevelMode"
      [checked]="lowestLevelMode"
      [disabled]="!updateMode"
    >
      {{ item.itemLabel }}
      <span
        class="badge badge-text badge-partial"
        *ngIf="
          ((!displayParentsItems && item?.parentItems?.length === 0) || levelId === -3) &&
          !lowestLevelMode
        "
      >
        Droits partiels
      </span>
      <ng-container *ngIf="displayParentsItems && item?.parentItems?.length > 0">
        (
        <span *ngFor="let parent of item.parentItems; let i = index">
          <ng-container *ngIf="i < 2">{{ parent.itemLabel }}</ng-container>
          <ng-container *ngIf="i < 1 && item.parentItems.length > 1">{{ ', ' }}</ng-container>
        </span>
        )
        <span class="badge badge-text badge-partial" *ngIf="!lowestLevelMode">
          Droits partiels
        </span>
      </ng-container>
    </mat-checkbox>
    <a
      class="text-center w-100 text-underline color-primary-700"
      *ngIf="
        listItemFilter.length >= 20 && listItemInfiniteScrollFilter.length !== listItemFilter.length
      "
      (click)="displayNextElementsFilter($event)"
      >Afficher les 20 suivants</a
    >
    <mat-checkbox
      class="col-12"
      *ngFor="let item of listItemInfiniteScrollSelected"
      (change)="applyItem($event, item)"
      [checked]="true"
      [disabled]="!updateMode"
    >
      {{ item.itemLabel }}

      <span
        class="badge-active badge-text badge"
        *ngIf="
          ((!displayParentsItems && item?.parentItems?.length === 0) || levelId === -3) &&
          !lowestLevelMode
        "
      >
        Droits complets
      </span>
      <ng-container *ngIf="displayParentsItems && item?.parentItems?.length > 0">
        (
        <span *ngFor="let parent of item.parentItems; let i = index">
          <ng-container *ngIf="i < 2">{{ parent.itemLabel }}</ng-container>
          <ng-container *ngIf="i < 1 && item.parentItems.length > 1">{{ ', ' }}</ng-container>
        </span>
        )
        <span class="badge-classic badge-active badge-text badge" *ngIf="!lowestLevelMode">
          Droits complets
        </span>
      </ng-container>
    </mat-checkbox>
    <a
      class="text-center w-100 text-underline color-primary-700"
      *ngIf="
        listItemSelected.length >= 20 &&
        listItemInfiniteScrollSelected.length !== listItemSelected.length
      "
      (click)="displayNextElementsSelected($event)"
      >Afficher les 20 suivants</a
    >
  </div>

  <hr
    *ngIf="
      (listItemSelected?.length > 0 || listItemFilter?.length > 0) && getNonSelectedItems.length > 0
    "
  />

  <div class="position-relative scrollArea">
    <div
      *ngIf="updateMode"
      class="groups-container overflow-auto"
      infinite-scroll
      [infiniteScrollDistance]="scrollDistance"
      [infiniteScrollThrottle]="throttle"
      [scrollWindow]="scrollWindow"
      (scrolled)="appendItems()"
    >
      <mat-checkbox
        class="d-block"
        [checked]="false"
        (change)="applyItem($event, item)"
        *ngFor="let item of getNonSelectedItems"
      >
        <span>{{ item.itemLabel }}</span>
        <span *ngIf="item.referenceClientTiers"
          >{{ ' ' }}(Id Client : {{ item.referenceClientTiers }})</span
        >
        <ng-container *ngIf="displayParentsItems && item?.parentItems?.length > 0">
          (
          <span *ngFor="let parent of item.parentItems.slice().reverse(); let i = index">
            <ng-container *ngIf="i < 2">{{ parent.itemLabel }}</ng-container>
            <ng-container *ngIf="i < 1 && item.parentItems.length > 1">{{ ', ' }}</ng-container>
          </span>
          )
        </ng-container>
      </mat-checkbox>
    </div>
    <div *ngIf="isLoading" class="position-absolute top-0 start-0 w-100 h-100">
      <div class="d-flex align-items-center h-100 justify-content-center">
        <mat-spinner [diameter]="50"></mat-spinner>
      </div>
    </div>

    <div
      class="empty-level"
      *ngIf="
        !listItemSelected?.length &&
        !listItemFilter.length &&
        !getNonSelectedItems?.length &&
        !isLoading
      "
    >
      La liste est vide
    </div>
  </div>
</mat-expansion-panel>
