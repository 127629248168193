import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[decimalInput]'
})
export class DecimalInputDirective {
    @Input() decimalInput: number;
    // Allow decimal numbers and negative values
    private regex: RegExp;
    // Allow key codes for special events. Reflect :
    // Backspace, tab, end, home
    private specialKeys: Array<string> = [
        'Backspace',
        'Tab',
        'End',
        'Home',
        '-',
        'ArrowLeft',
        'ArrowRight',
        'Del',
        'Delete'
    ];

    constructor(private el: ElementRef) {
        this.regex = new RegExp('.*');
    }

    // To use in the input with type="text" or type="textarea"
    // Otherwise selectionStart would be null
    @HostListener('keydown', ['$event'])
    onkeydown(event: KeyboardEvent): void {
        if (this.decimalInput) {
            this.regex = new RegExp(`^\\d*[.]?\\d{0,${this.decimalInput}}$`, 'g');
        } else {
            this.regex = new RegExp(`^\\d*[.]?\\d*$`, 'g');
        }
        // Allow Backspace, tab, end, and home keys
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }
        const current: string = this.el.nativeElement.value;
        const position = this.el.nativeElement.selectionStart;
        const next: string = [
            current.slice(0, position),
            event.key === 'Decimal' ? '.' : event.key,
            current.slice(position)
        ].join('');
        if (next && !String(next).match(this.regex)) {
            event.preventDefault();
        }
    }
}
