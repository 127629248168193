import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileExportType } from '@app/shared/constants/export.enum';

@Component({
    selector: 'export-type',
    templateUrl: './export-type.component.html',
    styleUrls: ['./export-type.component.scss']
})
export class ExportTypeComponent implements OnInit {
    @Input() fileExportType: FileExportType;

    @Output() selectedType = new EventEmitter<FileExportType>();

    @Input() activeRequestHttp = false;

    enumfileExportType = FileExportType;

    constructor() {}

    ngOnInit() {}
}
