export enum enumPublicSpaceRoute {
    path = 'public-space',

    // District
    listDistrict = 'districts',
    readDistrict = 'districts',
    createOrUpdateDistrict = 'district',

    // Street
    listStreet = 'streets',
    readStreet = 'streets',
    createOrUpdateStreet = 'street',

    // Cabinet
    listCabinet = 'cabinets',
    readCabinet = 'cabinets',
    createOrUpdateCabinet = 'cabinet'
}

export enum EnumPublicSpaceMapParams {
    id = 'id',
    parentType = 'parentType'
}
