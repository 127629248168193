import { KeyValue } from '@angular/common';

export enum PeriodEnum {
    Today = 1, // Aujourd'hui
    Yesterday = 2, // Hier
    DayBeforeYesterday = 3, // Avant-Hier
    Last3Days = 4, // 3 derniers jours
    Last7Days = 5, // 7 derniers jours
    CurrentWeek = 6, // Semaine en cours
    PreviousWeek = 7, // Semaine calendaire précédente
    Last30Days = 8, // 30 derniers jours
    CurrentMonth = 9, // Mois en cours
    PreviousMonth = 10, // Mois calendaire précédent
    Last3Months = 11, // 3 derniers mois calendaires
    CurrentYear = 12, // Mois en cours
    Last13Months = 13, // 13 derniers mois
    Last730Days = 14, // 2 dernières années (glissantes)
    Last1095Days = 15, // 3 dernières années (glissantes)
    PreviousYear = 16, // Année calendaire précédente
    Last2Years = 17, // 2 dernières années calendaires
    Last3Years = 18, // 3 dernières années calendaires
    Custom = 19 // Personnalisée
}
export const PeriodEnumRSX: Array<KeyValue<PeriodEnum, string>> = [
    { key: PeriodEnum.Today, value: 'common.period.today' },
    { key: PeriodEnum.Yesterday, value: 'common.period.yesterday' },
    { key: PeriodEnum.DayBeforeYesterday, value: 'common.period.daybeforeyesterday' },
    { key: PeriodEnum.Last3Days, value: 'common.period.last3days' },
    { key: PeriodEnum.Last7Days, value: 'common.period.last7days' },
    { key: PeriodEnum.CurrentWeek, value: 'common.period.currentweek' },
    { key: PeriodEnum.PreviousWeek, value: 'common.period.previousweek' },
    { key: PeriodEnum.Last30Days, value: 'common.period.last30days' },
    { key: PeriodEnum.CurrentMonth, value: 'common.period.currentmonth' },
    { key: PeriodEnum.PreviousMonth, value: 'common.period.previousmonth' },
    { key: PeriodEnum.Last3Months, value: 'common.period.last3months' },
    { key: PeriodEnum.CurrentYear, value: 'common.period.currentyear' },
    { key: PeriodEnum.Last13Months, value: 'common.period.last13months' },
    { key: PeriodEnum.Last730Days, value: 'common.period.last730days' },
    { key: PeriodEnum.Last1095Days, value: 'common.period.last1095days' },
    { key: PeriodEnum.PreviousYear, value: 'common.period.previousyear' },
    { key: PeriodEnum.Last2Years, value: 'common.period.last2years' },
    { key: PeriodEnum.Last3Years, value: 'common.period.last3years' },
    { key: PeriodEnum.Custom, value: 'common.period.custom' }
];

export enum TimestepEnum {
    Decennial = 0,
    Yearly = 1,
    Monthly = 2,
    Weekly = 3,
    Daily = 4,
    Hourly = 5,
    Minutes = 6,
    Minutes10 = 10
}
export const TimestepEnumRSX: Array<KeyValue<TimestepEnum, string>> = [
    { key: TimestepEnum.Decennial, value: 'common.timestep.decennial' },
    { key: TimestepEnum.Yearly, value: 'common.timestep.yearly' },
    { key: TimestepEnum.Monthly, value: 'common.timestep.monthly' },
    { key: TimestepEnum.Weekly, value: 'common.timestep.weekly' },
    { key: TimestepEnum.Daily, value: 'common.timestep.daily' },
    { key: TimestepEnum.Hourly, value: 'common.timestep.hourly' },
    { key: TimestepEnum.Minutes10, value: 'common.timestep.minutes10' },
    { key: TimestepEnum.Minutes, value: 'common.timestep.minutes' }
];
