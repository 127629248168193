import { ActionReducer, INIT, MetaReducer, UPDATE } from '@ngrx/store';
import { AppState } from './state/app-state.interface';

const SESSION_STORAGE_NAME = 'AppStateSession';
export const sessionStorageMetaReducer = (
    reducer: ActionReducer<AppState>
): ActionReducer<AppState> => {
    return (state, action) => {
        if (action.type === INIT || action.type === UPDATE) {
            const storageValue = sessionStorage.getItem(SESSION_STORAGE_NAME);
            if (storageValue) {
                try {
                    return JSON.parse(storageValue);
                } catch {
                    sessionStorage.removeItem(SESSION_STORAGE_NAME);
                }
            }
        }
        const nextState = reducer(state, action);
        sessionStorage.setItem(SESSION_STORAGE_NAME, JSON.stringify(nextState));
        return nextState;
    };
};

export const metaReducers: MetaReducer[] = [sessionStorageMetaReducer];
