import { Component, Input, Output, EventEmitter } from '@angular/core';
import { LinkedFileModel } from '@app/core/models/file/linked-file.model';
import { FileService } from '@app/core/http/file/file.service';
import { FileService as FileServiceHelper } from '@core/services/file/file.service';

@Component({
    selector: 'linked-file-display',
    templateUrl: './linked-file-display.component.html',
    styleUrls: ['./linked-file-display.component.scss']
})
export class LinkedFileDisplayComponent {
    private _linkedFiles: LinkedFileModel[] = [];
    @Input()
    set linkedFiles(value: LinkedFileModel[]) {
        this._linkedFiles = value;
        this._linkedFiles.forEach(
            f => (f.fileSvgIcon = this.fileServiceHelper.getSvgIconName(f.fileName.split('.')[1]))
        );
    }

    get linkedFiles(): LinkedFileModel[] {
        return this._linkedFiles;
    }

    public isLoading: boolean[] = [];

    constructor(private fileService: FileService, private fileServiceHelper: FileServiceHelper) {}

    public onDownloadFile($event: LinkedFileModel): void {
        this.isLoading[$event.fileId] = true;
        this.fileService.getFile($event.fileId).subscribe(res => {
            if (res) {
                const a = document.createElement('a');
                const objectUrl = URL.createObjectURL(res);
                a.href = objectUrl;
                a.download = $event.fileName;
                a.click();
                URL.revokeObjectURL(objectUrl);
            }
            this.isLoading[$event.fileId] = false;
        });
    }
}
