import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'custom-dialog-menu',
    templateUrl: './custom-dialog-menu.component.html',
    styleUrls: ['./custom-dialog-menu.component.scss']
})
export class CustomDialogMenuComponent implements OnInit {
    constructor() {}

    @Input() disabled = false;

    ngOnInit(): void {}
}
