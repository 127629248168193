import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { GroupPerimeterModel } from '@app/core/models/perimeters/group/group-perimeter.model';

@Component({
    selector: 'group-selector',
    templateUrl: './group-selector.component.html',
    styleUrls: ['./group-selector.component.scss']
})
export class GroupSelectorComponent implements OnInit, OnChanges {
    @Input() groups: Array<GroupPerimeterModel>;
    @Input() icon: string;
    @Input() tooltip: string;
    @Input() actionLabel: string;
    @Output() groupsSelected = new EventEmitter<boolean>();

    @Input() hide = false;
    @Output() openPanel = new EventEmitter();

    @Input() originY: 'center' | 'bottom' | 'top' = 'top';
    @Input() originX: 'center' | 'start' | 'end' = 'center';

    @Input() overlayX: 'center' | 'start' | 'end' = 'center';
    @Input() overlayY: 'center' | 'bottom' | 'top' = 'bottom';

    selectionGroupsFormGroup: FormGroup;
    isPanelShowed = false;
    constructor(private fb: FormBuilder) {}

    ngOnInit(): void {
        const controls = this.groups.map(c => new FormControl(false));
        this.selectionGroupsFormGroup = this.fb.group({
            groups: new FormArray(controls)
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.selectionGroupsFormGroup == null) {
            this.ngOnInit();
        }

        if (
            changes.hide != null &&
            changes.hide.firstChange === false &&
            changes.hide.currentValue === true
        ) {
            this.isPanelShowed = !changes.hide.currentValue;
        }
    }

    closePanel(): void {
        this.isPanelShowed = false;
    }

    onCustomDialogTogglePanel($event: boolean): void {
        this.isPanelShowed = $event;
        if (this.isPanelShowed) {
            this.openPanel.emit();
        }
    }

    onCustomDialogClickOut(): void {
        this.isPanelShowed = false;
    }

    submit(): void {
        this.closePanel();
        this.groupsSelected.emit(this.selectionGroupsFormGroup.value.groups);
    }
}
