//#region Imports
import {
    Component,
    DoCheck,
    EventEmitter,
    Input,
    IterableDiffer,
    IterableDiffers,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { PerimetersService } from '@app/core/http/perimeter/perimeters.service';
import { Dictionary } from '@app/core/models/common/dictionary.model';
import { PerimeterItemBaseModel } from '@app/core/models/perimeters/perimeter-item-base.model';
import { PerimeterItemModel } from '@app/core/models/perimeters/perimeter-item.model';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { SelectionItem } from '../model/selection-item';
import { TertiaryDecreeFilter } from '../model/tertiary-decree-filter.model';
import { GeoMenuService } from '../../geographic-menu/geo-menu.service';
import { EnumPerimeterLevels as PerimeterLevel } from '@app/features/perimeter/common/perimeter-levels.enum';
//#endregion

@Component({
    selector: 'perimeter-selector-level',
    templateUrl: './perimeter-selector-level.component.html',
    styleUrls: ['./perimeter-selector-level.component.scss']
})
// eslint-disable-next-line @angular-eslint/no-conflicting-lifecycle
export class PerimeterSelectorLevelComponent implements OnInit, OnDestroy, DoCheck {
    //#region Input / Output
    @Input() levelId: number;
    @Input() searchMode: boolean;
    @Input() updateMode: boolean;
    @Input() highlightMode: boolean;
    @Input() displayParentsItems: boolean;
    @Input() selectedLabel: string;
    @Input() selectedInfoLevel: string;
    @Input() partialSelectedLabel: string;
    @Input() allListItemFilter: Array<PerimeterItemModel>;
    @Input() allListItemSelected: Array<PerimeterItemModel>;
    @Input() lowestLevelMode: boolean;
    @Input() tertiaryDecreeFilter?: TertiaryDecreeFilter;
    @Input() key: string;

    @Output() levelReset = new EventEmitter<number>();
    @Output() selectionUpdate = new EventEmitter<SelectionItem>();
    @Output() closedOtherPanel = new EventEmitter<number>();
    @Output() selectionChange = new EventEmitter<void>();

    @Input() searchKey = '';
    @Input() searchFilter: { [index: number]: Array<number> } = {};

    isLoading = false;

    countSearchItems = 0;
    //#endregion
    //#region Properties
    direction = '';
    levelLabel = '';
    currentPage = 1;
    itemsCount = null;
    scrollDistance = 1;
    scrollWindow = false;
    throttle = 100;
    _onDestroy = new Subject<void>();
    listItems: Array<PerimeterItemModel> = [];
    perimeterFilter: { [index: number]: Array<number> } = {};
    searchFilterLowestLevel: number;

    // Les filtres sont gelé a l'ouverture d'un panel
    freezePerimeterFilter: { [index: number]: Array<number> } = {};

    get listItemFilter(): PerimeterItemModel[] {
        if (!this.allListItemFilter) {
            return [];
        }
        return this.allListItemFilter.filter(f => f.perimeterLevel === this.levelId);
    }

    get listItemSelected(): PerimeterItemModel[] {
        if (!this.allListItemSelected) {
            return [];
        }
        return this.allListItemSelected.filter(f => f.perimeterLevel === this.levelId);
    }

    listItemInfiniteScrollFilter: PerimeterItemModel[];
    listItemInfiniteScrollSelected: PerimeterItemModel[];
    iterableDiffer: IterableDiffer<unknown>;

    //#endregion
    //#region Constructor and lyfecycle hook
    constructor(
        private perimetersService: PerimetersService,
        iterableDiffers: IterableDiffers,
        private geoMenuService: GeoMenuService
    ) {
        this.iterableDiffer = iterableDiffers.find([]).create(null);
    }

    ngOnInit(): void {
        this.searchKey = '';

        // init submit & selectedList, submit by search if searchkey contains least one character
        this.initFilterAndSelectedList();

        // init the levelLabel
        this.setLevelLabel();

        // Subscribe the changes for the perimeter filter
        this.geoMenuService.getSearchParameters(this.key).subscribe(res => {
            if (!res) {
                return;
            }

            this.searchKey = res.searchKey;
            this.searchFilter = res.perimeterFilter;

            this.searchMode = !isEmpty(this.searchKey);

            if (this.searchFilter && Object.keys(this.searchFilter).length !== 0) {
                var key = +Object.keys(this.searchFilter)[0];
                if (!isNaN(key)) {
                    this.searchFilterLowestLevel = key;
                } else {
                    this.searchFilterLowestLevel = null;
                }
            } else {
                this.searchFilterLowestLevel = null;
            }

            if (this.searchMode) {
                this.loadPerimeterItems();
            }
        });
    }

    ngDoCheck(): void {
        // subscribe to array changes
        const changeSelected = this.iterableDiffer.diff(this.listItemSelected);
        const changeFilter = this.iterableDiffer.diff(this.listItemFilter);
        if (changeSelected) {
            // if no paginate and no searchMode, listItemInfiniteScrollSelected equal listItemSelected
            if (this.listItemSelected.length <= 20 && this.searchKey.length === 0) {
                this.listItemInfiniteScrollSelected = [...this.listItemSelected];
            }

            // if no paginate and searchMode, listItemInfiniteScrollSelected equal listItemSelected submit by searchKey
            if (this.listItemSelected.length <= 20 && this.searchKey.length > 0) {
                this.listItemInfiniteScrollSelected = [
                    ...this.listItemSelected.filter(o =>
                        o.itemLabel.toLowerCase().includes(this.searchKey.toLowerCase())
                    )
                ];
            }

            // Here we checked the difference on the arrays. If we find a difference, we remove the element not contains on listItemSelected
            if (
                this.listItemInfiniteScrollSelected &&
                this.listItemInfiniteScrollSelected.filter(
                    el => !this.listItemSelected.includes(el)
                ).length > 0
            ) {
                this.listItemInfiniteScrollSelected = this.listItemInfiniteScrollSelected.filter(
                    el => this.listItemSelected.includes(el)
                );
            }
        }
        if (changeFilter) {
            // if no paginate and no searchMode, listItemInfiniteScrollSelected equal listItemSelected
            if (this.listItemFilter.length <= 20 && this.searchKey.length === 0) {
                this.listItemInfiniteScrollFilter = [...this.listItemFilter];
            }

            // if no paginate and searchMode, listItemInfiniteScrollSelected equal listItemSelected submit by searchKey
            if (this.listItemSelected.length <= 20 && this.searchKey.length > 0) {
                this.listItemInfiniteScrollFilter = [
                    ...this.listItemFilter.filter(o =>
                        o.itemLabel.toLowerCase().includes(this.searchKey.toLowerCase())
                    )
                ];
            }

            // Here we checked the difference on the arrays. If we find a difference, we remove the element not contains on listItemSelected
            if (
                this.listItemInfiniteScrollFilter &&
                this.listItemInfiniteScrollFilter.filter(el => !this.listItemFilter.includes(el))
                    .length > 0
            ) {
                this.listItemInfiniteScrollFilter = this.listItemInfiniteScrollFilter.filter(el =>
                    this.listItemFilter.includes(el)
                );
            }
        }
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    private setLevelLabel(): void {
        switch (this.levelId) {
            case +PerimeterLevel.NATION:
                this.levelLabel = 'Pays';
                break;
            case +PerimeterLevel.REGION:
                this.levelLabel = 'Régions';
                break;
            case +PerimeterLevel.COUNTY:
                this.levelLabel = 'Départements';
                break;
            case +PerimeterLevel.TOWN:
                this.levelLabel = 'Villes';
                break;
            case +PerimeterLevel.ESTABLISHMENT:
                this.levelLabel = 'Etablissements';
                break;
            case +PerimeterLevel.BUILDING:
                this.levelLabel = 'Bâtiments';
                break;
            case +PerimeterLevel.ZONE:
                this.levelLabel = 'Zones';
                break;
            case +PerimeterLevel.DISTRICT:
                this.levelLabel = 'Quartiers';
                break;
            case +PerimeterLevel.STREET:
                this.levelLabel = 'Rues';
                break;
            case +PerimeterLevel.CABINET:
                this.levelLabel = 'Armoires';
                break;
        }
    }

    private loadPerimeterItems(): void {
        this.perimetersService
            .getPerimetersByLevel(
                this.levelId, // level
                this.searchKey, // filterByName
                this.searchKey, // filterByTextEntry
                0, // currentPage
                this.searchFilter, // perimeterFilters
                {
                    isInsideTertiaryDecreeGroup: this.tertiaryDecreeFilter
                        ?.isInsideTertiaryDecreeGroup,
                    isSubjectToTertiaryDecree: this.tertiaryDecreeFilter?.isSubjectToTertiaryDecree
                }
            )
            .subscribe(
                res => {
                    if (res) {
                        this.countSearchItems = res.rowCount || 0;
                    } else {
                        this.countSearchItems = 0;
                    }
                },
                () => {}
            );
    }

    //#endregion
    //#region Methods

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public displayNextElementsSelected($event: any): void {
        $event.stopPropagation();
        if (this.searchKey.length > 0) {
            // paginate submit by searchKey
            this.listItemInfiniteScrollSelected = this.listItemSelected
                .slice(0, this.listItemInfiniteScrollSelected.length + 20)
                .filter(o => o.itemLabel.toLowerCase().includes(this.searchKey.toLowerCase()));
        } else {
            // paginate not submit
            this.listItemInfiniteScrollSelected = this.listItemSelected.slice(
                0,
                this.listItemInfiniteScrollSelected.length + 20
            );
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public displayNextElementsFilter($event: any): void {
        $event.stopPropagation();
        if (this.searchKey.length > 0) {
            // paginate submit by searchKey
            this.listItemInfiniteScrollFilter = this.listItemFilter
                .slice(0, this.listItemInfiniteScrollFilter.length + 20)
                .filter(o => o.itemLabel.toLowerCase().includes(this.searchKey.toLowerCase()));
        } else {
            // paginate not submit
            this.listItemInfiniteScrollFilter = this.listItemFilter.slice(
                0,
                this.listItemInfiniteScrollFilter.length + 20
            );
        }
    }

    get levelLabelInfos(): string {
        let _levelLabelInfos = '';
        switch (this.levelId) {
            case +PerimeterLevel.NATION:
                _levelLabelInfos = 'Pays';
                break;
            case +PerimeterLevel.REGION:
                _levelLabelInfos =
                    this.listItemSelected && this.listItemSelected.length > 1
                        ? 'Régions'
                        : 'Région';
                break;
            case +PerimeterLevel.COUNTY:
                _levelLabelInfos =
                    this.listItemSelected && this.listItemSelected.length > 1
                        ? 'Départements'
                        : 'Département';
                break;
            case +PerimeterLevel.TOWN:
                _levelLabelInfos =
                    this.listItemSelected && this.listItemSelected.length > 1 ? 'Villes' : 'Ville';
                break;
            case +PerimeterLevel.ESTABLISHMENT:
                _levelLabelInfos =
                    this.listItemSelected && this.listItemSelected.length > 1
                        ? 'Établissements'
                        : 'Établissement';
                break;
            case +PerimeterLevel.BUILDING:
                _levelLabelInfos =
                    this.listItemSelected && this.listItemSelected.length > 1
                        ? 'Bâtiments'
                        : 'Bâtiment';
                break;
            case +PerimeterLevel.ZONE:
                _levelLabelInfos =
                    this.listItemSelected && this.listItemSelected.length > 1 ? 'Zones' : 'Zone';
                break;
            case +PerimeterLevel.DISTRICT:
                _levelLabelInfos =
                    this.listItemSelected && this.listItemSelected.length > 1
                        ? 'Quartiers'
                        : 'Quartier';
                break;
            case +PerimeterLevel.STREET:
                _levelLabelInfos =
                    this.listItemSelected && this.listItemSelected.length > 1 ? 'Rues' : 'Rue';
                break;
            case +PerimeterLevel.CABINET:
                _levelLabelInfos =
                    this.listItemSelected && this.listItemSelected.length > 1
                        ? 'Armoires'
                        : 'Armoire';
                break;
        }
        return _levelLabelInfos;
    }

    initPerimeterSelectorLevel(): void {
        this.closedOtherPanel.emit(this.levelId);
        // filterMode
        this.perimeterFilter = this.defineFilter();
        this.freezePerimeterFilter = this.perimeterFilter;

        if (this.updateMode) {
            this.itemsCount = null;
            this.listItems = [];
            this.currentPage = 1;
            this.appendItems();
        }
        this.initFilterAndSelectedList();
    }

    // Les filtres gelé sont détruit à la fermeture d'un panel
    destroyFreezeFilter(): void {
        this.freezePerimeterFilter = {};
    }

    initFilterAndSelectedList(): void {
        if (this.searchKey.length > 0) {
            this.listItemInfiniteScrollFilter = this.listItemFilter
                .slice(0, 20)
                .filter(o => o.itemLabel.toLowerCase().includes(this.searchKey.toLowerCase()));
            this.listItemInfiniteScrollSelected = this.listItemSelected
                .slice(0, 20)
                .filter(o => o.itemLabel.toLowerCase().includes(this.searchKey.toLowerCase()));
        } else {
            this.listItemInfiniteScrollFilter = this.listItemFilter.slice(0, 20);
            this.listItemInfiniteScrollSelected = this.listItemSelected.slice(0, 20);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    eventResetLevelSelected($event: any): void {
        $event.preventDefault();
        $event.stopPropagation();
        this.levelReset.emit(this.levelId);
        this.selectionChange.emit();
    }

    resetFilter(): void {
        for (const filterToDelete of this.listItemFilter) {
            const indexToDelete = this.allListItemFilter.indexOf(filterToDelete);
            this.allListItemFilter.splice(indexToDelete, 1);
        }
    }

    defineFilter(): { [index: number]: Array<number> } {
        const flatItem: PerimeterItemModel[] = [];
        // Mise a plat des listes userSelection et userFilter en PerimeterItemModel
        flatItem.push(...this.allListItemSelected);
        // Mise a plat des listes userFilter en PerimeterItemModel
        flatItem.push(...this.allListItemFilter);

        const value = this.searchFilterLowestLevel != null ? this.searchFilter[0] : [];

        const _perimeterFilter = new Dictionary<Array<number>>();
        const result = new Dictionary<Array<number>>();

        _perimeterFilter.Add(
            +PerimeterLevel.NATION,
            this.searchFilterLowestLevel === +PerimeterLevel.NATION ? value : []
        );
        _perimeterFilter.Add(
            +PerimeterLevel.REGION,
            this.searchFilterLowestLevel === +PerimeterLevel.REGION ? value : []
        );
        _perimeterFilter.Add(
            +PerimeterLevel.COUNTY,
            this.searchFilterLowestLevel === +PerimeterLevel.COUNTY ? value : []
        );
        _perimeterFilter.Add(
            +PerimeterLevel.TOWN,
            this.searchFilterLowestLevel === +PerimeterLevel.TOWN ? value : []
        );

        _perimeterFilter.Add(+PerimeterLevel.ESTABLISHMENT, []);
        _perimeterFilter.Add(+PerimeterLevel.BUILDING, []);
        _perimeterFilter.Add(+PerimeterLevel.ZONE, []);
        _perimeterFilter.Add(+PerimeterLevel.DISTRICT, []);
        _perimeterFilter.Add(+PerimeterLevel.STREET, []);
        _perimeterFilter.Add(+PerimeterLevel.CABINET, []);

        // mettre les Except dans le submit du level
        const exceptFilter = _perimeterFilter.Value(this.levelId);
        const exceptItem = flatItem.filter(x => x.perimeterLevel === this.levelId);
        if (exceptItem.length !== 0) {
            exceptFilter.push(...exceptItem.map(t => t.itemId));
        }

        this.defineFilterRecursive(flatItem, [], this.levelId, _perimeterFilter);

        if (
            _perimeterFilter.Value(+PerimeterLevel.NATION).length === 0 &&
            _perimeterFilter.Value(+PerimeterLevel.REGION).length === 0 &&
            _perimeterFilter.Value(+PerimeterLevel.COUNTY).length === 0 &&
            _perimeterFilter.Value(+PerimeterLevel.TOWN).length === 0 &&
            _perimeterFilter.Value(+PerimeterLevel.ESTABLISHMENT).length === 0 &&
            _perimeterFilter.Value(+PerimeterLevel.BUILDING).length === 0 &&
            _perimeterFilter.Value(+PerimeterLevel.ZONE).length === 0 &&
            _perimeterFilter.Value(+PerimeterLevel.DISTRICT).length === 0 &&
            _perimeterFilter.Value(+PerimeterLevel.STREET).length === 0 &&
            _perimeterFilter.Value(+PerimeterLevel.CABINET).length === 0
        ) {
            return null;
        }

        // Get the highest level with values that the current level
        var arr = Object.values(PerimeterLevel)
            .filter(v => !isNaN(+v) && this.levelId != +v)
            .sort((a, b) => (a > b ? -1 : 1));
        arr.every(v => {
            if (_perimeterFilter.Value(+v).length > 0) {
                result.Add(+v, _perimeterFilter.Value(+v));
                return false;
            }
            return true;
        });

        return result.Items;
    }

    private defineFilterRecursive(
        flatItem: PerimeterItemModel[],
        itemsHierarchy: PerimeterItemBaseModel[],
        levelId: number,
        resultPerimeterFilter: Dictionary<Array<number>>
    ): void {
        switch (levelId) {
            // niveau Region
            case +PerimeterLevel.REGION:
                // récupération des filtres de niveau Nations
                if (
                    flatItem.filter(x => x.perimeterLevel === +PerimeterLevel.NATION).length !== 0
                ) {
                    resultPerimeterFilter.Value(+PerimeterLevel.NATION).push(
                        ...flatItem
                            .filter(x => x.perimeterLevel === +PerimeterLevel.NATION) // ne récupére que des les élements de type nation
                            .filter(
                                x =>
                                    !itemsHierarchy.some(
                                        i =>
                                            i.perimeterLevel === +PerimeterLevel.NATION &&
                                            i.itemId === x.itemId
                                    )
                            ) // et si un élément de dessous n'a pas déjà été sélectioné.
                            .map(t => t.itemId)
                    );
                }
                break;
            // Niveau département
            case +PerimeterLevel.COUNTY:
                // récupération des filtre de niveau Régions
                this.createFilterLevel(
                    +PerimeterLevel.REGION,
                    flatItem,
                    itemsHierarchy,
                    resultPerimeterFilter,
                    resultPerimeterFilter.Value(+PerimeterLevel.REGION)
                );
                break;
            // Niveau Ville
            case +PerimeterLevel.TOWN:
                // récupération des filtres de niveau Département
                this.createFilterLevel(
                    +PerimeterLevel.COUNTY,
                    flatItem,
                    itemsHierarchy,
                    resultPerimeterFilter,
                    resultPerimeterFilter.Value(+PerimeterLevel.COUNTY)
                );
                break;
            // Niveau Etablissement
            case +PerimeterLevel.ESTABLISHMENT:
                this.createFilterLevel(
                    +PerimeterLevel.TOWN,
                    flatItem,
                    itemsHierarchy,
                    resultPerimeterFilter,
                    resultPerimeterFilter.Value(+PerimeterLevel.TOWN)
                );
                break;
            // niveau Batiment
            case +PerimeterLevel.BUILDING:
                this.createFilterLevel(
                    +PerimeterLevel.ESTABLISHMENT,
                    flatItem,
                    itemsHierarchy,
                    resultPerimeterFilter,
                    resultPerimeterFilter.Value(+PerimeterLevel.ESTABLISHMENT)
                );
                break;
            // niveau zone
            case +PerimeterLevel.ZONE:
                this.createFilterLevel(
                    +PerimeterLevel.BUILDING,
                    flatItem,
                    itemsHierarchy,
                    resultPerimeterFilter,
                    resultPerimeterFilter.Value(+PerimeterLevel.BUILDING)
                );
                break;
            // Niveau Quartier
            case +PerimeterLevel.DISTRICT:
                this.createFilterLevel(
                    +PerimeterLevel.TOWN,
                    flatItem,
                    itemsHierarchy,
                    resultPerimeterFilter,
                    resultPerimeterFilter.Value(+PerimeterLevel.TOWN)
                );
                break;
            // niveau Rues
            case +PerimeterLevel.STREET:
                this.createFilterLevel(
                    +PerimeterLevel.DISTRICT,
                    flatItem,
                    itemsHierarchy,
                    resultPerimeterFilter,
                    resultPerimeterFilter.Value(+PerimeterLevel.DISTRICT)
                );
                break;
            // niveau Cabinet
            case +PerimeterLevel.CABINET:
                this.createFilterLevel(
                    +PerimeterLevel.STREET,
                    flatItem,
                    itemsHierarchy,
                    resultPerimeterFilter,
                    resultPerimeterFilter.Value(+PerimeterLevel.STREET)
                );
                break;
        }
    }

    private createFilterLevel(
        levelIdParent: number,
        flatItem: Array<PerimeterItemModel>,
        itemsHierarchy: PerimeterItemBaseModel[],
        resultPerimeterFilter: Dictionary<number[]>,
        filterLevelIds: Array<number>
    ): void {
        const flatLevel = flatItem.filter(x => x.perimeterLevel === levelIdParent);
        const _itemsHierarchy: PerimeterItemBaseModel[] = [];
        if (flatLevel.length !== 0) {
            // et si un niveau plus bas n'a pas déjà été sélectioné. On ajoute
            const levelsToAdd = flatLevel.filter(
                x =>
                    !itemsHierarchy.some(
                        i => i.perimeterLevel === levelIdParent && i.itemId === x.itemId
                    )
            );
            filterLevelIds.push(...levelsToAdd.map(t => t.itemId));
            // récupération de toutes les hierarchies des nouveaux ajouts
            for (const level of levelsToAdd) {
                _itemsHierarchy.push(...level.parentItems);
            }
            // ajout au hiérarchie déjà en paramètre
            _itemsHierarchy.push(...itemsHierarchy);
        }
        // Récupération des filtres des niveaux supérieurs
        this.defineFilterRecursive(flatItem, _itemsHierarchy, levelIdParent, resultPerimeterFilter);
    }

    appendItems(): void {
        let _searchKeyword = '';
        if (this.searchMode) {
            // this.perimeterFilter = this.searchFilter;
            _searchKeyword = this.searchKey;
        } else {
            // filterMode
            this.perimeterFilter = this.defineFilter();
        }
        if (
            !this.itemsCount ||
            this.itemsCount >
                this.listItemFilter.length +
                    this.listItemSelected.length +
                    this.getNonSelectedItems.length
        ) {
            this.currentPage = Math.trunc(this.listItems.length / 10) + 1;

            // Si des filtres 'gelé' sont présent on les utilises, sinon on prend les filtres courant
            this.isLoading = true;
            this.perimetersService
                .getPerimetersByLevel(
                    this.levelId, // level
                    _searchKeyword, // filterByName
                    _searchKeyword, // filterByTextEntry
                    this.currentPage, // currentPage
                    this.searchMode // perimeterFilters
                        ? this.perimeterFilter
                        : this.freezePerimeterFilter,
                    {
                        isInsideTertiaryDecreeGroup: this.tertiaryDecreeFilter
                            ?.isInsideTertiaryDecreeGroup,
                        isSubjectToTertiaryDecree: this.tertiaryDecreeFilter
                            ?.isSubjectToTertiaryDecree
                    }
                )
                .pipe(
                    takeUntil(this._onDestroy),
                    finalize(() => {
                        this.isLoading = false;
                    })
                )
                .subscribe(
                    result => {
                        if (result && result.results && result.results.length) {
                            for (const item of result.results) {
                                if (
                                    !this.listItems.some(
                                        i =>
                                            i.itemId === item.itemId &&
                                            i.perimeterLevel === item.perimeterLevel
                                    )
                                ) {
                                    this.listItems.push(item);
                                }
                            }
                            this.itemsCount = result.rowCount;
                            this.currentPage = result.currentPage + 1;
                        }
                    },
                    () => {}
                );
        }
    }

    private sortAsc(a: PerimeterItemModel, b: PerimeterItemModel): number {
        if (a.itemLabel.toLowerCase() < b.itemLabel.toLowerCase()) {
            return -1;
        } else if (a.itemLabel.toLowerCase() === b.itemLabel.toLowerCase()) {
            return 0;
        } else {
            return 1;
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    applyItem($event: any, item: PerimeterItemModel): void {
        let selectionItem = <SelectionItem>{};

        // Si le status reçu est submit (lowestMode), pas de notion de indeterminate
        if (this.lowestLevelMode) {
            selectionItem = <SelectionItem>{
                item: item,
                checked: $event.checked,
                indeterminate: null
            };
        } else {
            selectionItem = <SelectionItem>{
                item: item,
                checked: $event.checked,
                indeterminate: $event.source.indeterminate
            };
        }

        // Si menu geo / admin group, pas de notion undeterminate => traitement différent
        if (this.lowestLevelMode && !selectionItem.indeterminate && !selectionItem.checked) {
            // suppression de l'item dans la liste des filtres s'il existe
            const index = this.allListItemFilter.findIndex(
                el => el.itemId === item.itemId && el.perimeterLevel === this.levelId
            );
            if (index > -1) {
                const uncheckedItem = this.allListItemFilter.splice(index, 1)[0];
                // s'il n'existe pas encore la list des élément récupérer on l'ajoute
                if (
                    !this.listItems.some(
                        i =>
                            i.itemId === uncheckedItem.itemId &&
                            i.perimeterLevel === uncheckedItem.perimeterLevel
                    )
                ) {
                    const sortArray = new Array<PerimeterItemModel>();
                    sortArray.push(...this.listItems);
                    sortArray.push(uncheckedItem[0]);
                    const s = sortArray.sort((a, b) => this.sortAsc(a, b));
                    const indexx = s.indexOf(uncheckedItem);
                    if (s.length - 1 > indexx) {
                        this.listItems.push(uncheckedItem);
                        this.listItems.sort((a, b) => this.sortAsc(a, b));
                    }
                }
            }
        }
        // en cas de selection
        if (selectionItem.checked) {
            if (
                !this.allListItemSelected.some(
                    el => el.itemId === item.itemId && el.perimeterLevel === this.levelId
                )
            ) {
                this.allListItemSelected.push(item);
                // suppression de l'item dans la liste des filtres s'il existe
                const index = this.allListItemFilter.findIndex(
                    el => el.itemId === item.itemId && el.perimeterLevel === this.levelId
                );
                if (index > -1) {
                    this.allListItemFilter.splice(index, 1);
                }
            }

            this.allListItemSelected.sort((a, b) => this.sortAsc(a, b));
            // en cas de deselection
        } else {
            const index = this.allListItemSelected.findIndex(
                el => el.itemId === item.itemId && el.perimeterLevel === this.levelId
            );
            if (index > -1) {
                const uncheckedItem = this.allListItemSelected.splice(index, 1)[0];
                // s'il n'existe pas encore la list des élément récupérer on l'ajoute
                if (
                    !this.listItems.some(
                        i =>
                            i.itemId === uncheckedItem.itemId &&
                            i.perimeterLevel === uncheckedItem.perimeterLevel
                    )
                ) {
                    // s'il n'existe pas encore la list des élément récupérer on l'ajoute
                    const sortArray = new Array<PerimeterItemModel>();
                    sortArray.push(...this.listItems);
                    sortArray.push(uncheckedItem);
                    const s = sortArray.sort((a, b) => this.sortAsc(a, b));
                    const indexx = s.indexOf(uncheckedItem);
                    if (s.length - 1 > indexx) {
                        this.listItems.push(uncheckedItem);
                        this.listItems.sort((a, b) => this.sortAsc(a, b));
                    }
                }
            }
        }
        // tri la liste des éléments selectionné par localisation des deux éléments de la hiérarchie supérieur
        this.allListItemSelected.sort((a, b) => this.sortAsc(a, b));
        // si la liste des éléments non sélectionnés se vide rapidement on ajoute des éléments si c'est possible
        if (this.getNonSelectedItems.length < 3) {
            this.appendItems();
        }
        this.selectionUpdate.emit(selectionItem);
    }

    get getNonSelectedItems(): Array<PerimeterItemModel> {
        // une copie de la liste des element qui n'existe pas dans la liste de selection utilisateur
        return [
            ...this.listItems.filter(
                el =>
                    !this.listItemFilter
                        .concat(this.listItemSelected)
                        .map(e => e.itemId)
                        .includes(el.itemId)
            )
        ];
    }
    //#endregion
}

function isEmpty(str): boolean {
    return !str || str.length === 0;
}
