import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { OrganizationSectorModel } from '@app/core/models/organization/organization.sector.model';
import { environment } from '@environments/environment';

@Injectable({
    providedIn: 'root'
})
export class OrganizationsService {
    //#region Fields
    private API_ENDPOINT: string = environment.API_ENDPOINT;
    private SECTOR_API_URL = `${this.API_ENDPOINT}OrganizationSectors/`;
    //#endregion

    //#region Constructor
    constructor(private http: HttpClient) {}
    //#endregion

    //#region API Calls

    public GetOrganizationsSector(): Observable<OrganizationSectorModel[]> {
        return this.http.get<OrganizationSectorModel[]>(this.SECTOR_API_URL);
    }

    //#endregion
}
