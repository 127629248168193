//#region Imports
import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivateChild,
    Router,
    RouterStateSnapshot
} from '@angular/router';

import { appRoutes } from '@app/app-root/app-routes.enum';
import { AppService } from '@app/core/http/app.service';
import { ToasterService } from '@vertuoz/vertuoz-library';
//#endregion

@Injectable({
    providedIn: 'root'
})
export class AccessGuard implements CanActivateChild {
    //#region Constructor
    constructor(
        private router: Router,
        private appService: AppService,
        private toasterService: ToasterService
    ) {}
    //#endregion

    //#region Methods
    canActivateChild = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
        console.debug('AccessGuard : canActivateChild');
        console.debug(
            'AccessGuard : canActivateChild : Navigation data : ' + JSON.stringify(route.data)
        );

        if (route.data && route.data.permissions) {
            console.debug(
                'AccessGuard : canActivateChild : Navigation data permissions : ' +
                    JSON.stringify(route.data.permissions)
            );
            return this.checkUserPermissions(route.data.permissions as Array<string>);
        }
        return true;
    };

    private checkUserPermissions(permissions: string[]): boolean {
        console.debug('AccessGuard : checkUserPermissions');

        const currentRole = this.appService.getUserContext().role;
        const userRolePermissions = currentRole.permissions;

        console.debug(
            "AccessGuard : Current user's role permissions : " + JSON.stringify(userRolePermissions)
        );
        console.debug('AccessGuard : Required permissions : ' + JSON.stringify(permissions));

        const hasPermissions = permissions.every(perm =>
            userRolePermissions.some(urp => urp.permissionType === perm)
        );
        console.debug('AccessGuard : HasPermissions : ' + hasPermissions);

        const routeUrl = this.router.url.split('?')[0];
        console.debug('AccessGuard : routeUrl : ' + routeUrl);

        if (!hasPermissions) {
            this.toasterService.showForbid(
                "Vous n'avez pas les droits pour accéder à cette page !"
            );
            if (!routeUrl.includes(appRoutes.dashboard)) {
                this.router.navigate([appRoutes.dashboard], { queryParamsHandling: 'preserve' });
            }
            return false;
        }
        return true;
    }
    //#endregion
}
