<button mat-icon-button [disabled]="disabled" (click)="_togglePanel()" #toggle>
  <ng-content select="mat-icon-button-content"> </ng-content>
</button>

<anchor-dialog
  *ngIf="isPanelShowed"
  [customClass]="customClass"
  [anchorElement]="toggle._elementRef"
  class="dialog"
  [overlayX]="overlayX"
  [overlayY]="overlayY"
  [originX]="originX"
  [originY]="originY"
  [closeOnScroll]="closeOnScroll"
  [useOverlay]="useOverlay"
  [padding]="padding"
>
  <ng-content select="dialog-content"> </ng-content>
  <div class="dialog__btn w-auto"><ng-content select="dialog-buttons"> </ng-content></div>
</anchor-dialog>
