<vertuoz-loader [showLoader]="isLoading">
  <div class="ps-4 pe-4 pt-2">
    <mat-form-field [floatLabel]="isLoading ? 'never' : 'auto'" class="w-45">
      <legend class="font-16 pb-3">Domaine des groupes</legend>
      <mat-select
        (selectionChange)="perimeterDomainFilterChanged($event.value)"
        [disabled]="
          groupFilter.filterByDomainIds === null &&
          (!gridView || !gridView.data || !gridView.data.length)
        "
        [(value)]="selectedValue"
      >
        <mat-option [value]="-1">Tous les domaines</mat-option>
        <mat-option *ngFor="let d of userDomains" [value]="d.perimeterItemDomainId">
          {{ d.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="grid-pages-footer">
    <kendo-grid
      #grid
      class="groups-grid"
      [data]="gridView"
      [pageSize]="pageSize"
      [skip]="skip"
      [pageable]="{ type: 'input' }"
      [selectable]="true"
      [hideHeader]="true"
      (pageChange)="pageChange($event)"
      (edit)="applyGroup($event)"
      (detailExpand)="collapseAll(grid); onPanelOpened($event.dataItem.groupPerimeterId)"
    >
      <kendo-grid-messages pagerItems="" pagerOf="sur"> </kendo-grid-messages>

      <kendo-grid-column field="name" title="Groupe">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span
            *ngIf="
              !activatedGroupId || dataItem.groupPerimeterId !== activatedGroupId;
              else currentGroup
            "
            >{{ dataItem.name }}
          </span>
          <ng-template #currentGroup>
            <span class="active-marker-after">{{ dataItem.name }}</span>
          </ng-template>
          <br />
          <span class="group-items-count">{{ getGroupItemsCount(dataItem) }}</span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="type" title="Type">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div
            class="tag"
            [ngClass]="{
              'geo-sticker': dataItem.domain === 1,
              'property-sticker': dataItem.domain === 2,
              'publicSpace-sticker': dataItem.domain === 3
            }"
          >
            {{
              dataItem.domain === 1
                ? 'Géographie'
                : dataItem.domain === 2
                ? 'Immobilier'
                : 'Espace public'
            }}
          </div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="Apply" title="Appliquer">
        <ng-template kendoGridCellTemplate let-dataItem>
          <button
            [disabled]="dataItem.groupPerimeterId === activatedGroupId"
            kendoGridEditCommand
            mat-flat-button
            color="primary"
            class="btn-sm"
          >
            Appliquer
          </button>
        </ng-template>
      </kendo-grid-column>

      <ng-template kendoGridDetailTemplate let-dataItem>
        <section>
          <div
            class="scrollArea"
            *ngIf="
              currentGroup && currentGroup.groupPerimeterId === dataItem.groupPerimeterId;
              else spinnerDiv
            "
          >
            <div
              *ngIf="currentGroup.perimeters && currentGroup.perimeters.length > 9"
              class="row overflow-auto"
              infinite-scroll
              [infiniteScrollDistance]="scrollDistance"
              [infiniteScrollThrottle]="throttle"
              [scrollWindow]="scrollWindow"
              (scrolled)="appendItems()"
            >
              <p class="col-4 pb-3 m-0" *ngFor="let item of currentGroupPerimeters">
                {{ item.itemLabel }}
              </p>
            </div>
            <div
              *ngIf="
                currentGroup.perimeters &&
                currentGroup.perimeters.length > 0 &&
                currentGroup.perimeters.length <= 9
              "
              class="row overflow-auto"
            >
              <p class="col-4 pb-3 m-0" *ngFor="let item of currentGroupPerimeters">
                {{ item.itemLabel }}
              </p>
            </div>
            <p class="m-0" *ngIf="!currentGroup.perimeters || currentGroup.perimeters.length === 0">
              <span>aucun élément</span>
            </p>
          </div>
          <ng-template #spinnerDiv>
            <mat-spinner [diameter]="30" class="m-auto"></mat-spinner>
          </ng-template>
        </section>
      </ng-template>
      <ng-template kendoGridNoRecordsTemplate>
        Il y a pas des groupes à afficher dans cette section.
      </ng-template>
    </kendo-grid>
  </div>
</vertuoz-loader>
