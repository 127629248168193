import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AppService } from '../http/app.service';

@Injectable({
    providedIn: 'root'
})
export class PermissionService {
    constructor(private appService: AppService) {}

    /**
     * Récupère les informations du header
     *
     * @returns
     * @memberof PermissionService
     */
    // Obsolete
    public getHeader(): HttpHeaders {
        const currentUser = this.appService.getUserContext().user;
        const currentContract = this.appService.getUserContext().contract;
        if (currentUser != null && currentContract != null) {
            return new HttpHeaders({
                userId: currentUser.userId.toString(),
                clientId: currentContract.client.id.toString(),
                contractId: currentContract.id.toString()
            });
        }
        return new HttpHeaders();
    }

    /**
     * Récupère les informations du header
     *
     * @returns
     * @memberof PermissionService
     */
    // Obsolete
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getHttpHeader(): any {
        const currentUser = this.appService.getUserContext().user;
        const currentContract = this.appService.getUserContext().contract;

        return {
            userId: currentUser.userId.toString(),
            clientId: currentContract.client.id.toString(),
            contractId: currentContract.id.toString()
        };
    }

    // Obsolete

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getHttpHeaderForUpload(): any {
        return {
            userId: this.appService.getUserContext().user.userId.toString(),
            clientId: this.appService.getUserContext().contract.client.id.toString(),
            contractId: this.appService.getUserContext().contract.id.toString()
        };
    }

    /**
     * Indique si les permissions renseigné sont présente pour l'utilisateur courant
     * @param permissionsElement permissions de l'élément a vérifier par rapport aux permissions de l'utilisateur courant
     * @param module module dans lequel la permission doit être cherchée
     */
    hasPermission(permissions: Array<string>, module?: string): boolean {
        if (!permissions || permissions.length === 0) {
            return false;
        }

        const userPermissions = this.currentUserPermissions(module);

        return userPermissions.some(userPermission => permissions.includes(userPermission));
    }

    /**
     * Indique si les permissions renseigné sont présente pour l'utilisateur courant
     * @param permissionsToCheck permissions a vérifier par rapport aux permissions de l'utilisateur courant
     * @param module module dans lequel la permission doit être cherchée
     */
    hasAllPermission(permissions: Array<string>, module?: string): boolean {
        if (!permissions || permissions.length === 0) {
            return false;
        }
        const userPermissions = this.currentUserPermissions(module);

        userPermissions.forEach(userPermission => {
            if (!permissions.includes(userPermission)) {
                return false;
            }
        });
        return true;
    }

    /**
     * Récupère la liste des permissions de l'utilisateur courant
     * @param module permet de filtrer les permissions récupérées par module
     */
    currentUserPermissions(module?: string): string[] {
        const userPermissions = [];
        let permissions = this.appService.getUserContext().role.permissions;

        if (!permissions) {
            return userPermissions;
        }

        if (module) {
            permissions = permissions.filter(
                item => item.module.id.toLowerCase() === module.toLowerCase()
            );
        }

        permissions.forEach(perm => {
            userPermissions.push(perm.permissionType);
        });

        return userPermissions;
    }
}
