<button
  type="button"
  mat-flat-button
  class="btn-sm bg-error ms-4"
  (mouseleave)="stopCounter()"
  (mousedown)="startCounter()"
  (mouseup)="stopCounter()"
>
  <ng-content></ng-content>
  <mat-progress-spinner
    class="d-inline-block align-middle ms-1"
    diameter="20"
    mode="determinate"
    [value]="progress"
  >
  </mat-progress-spinner>
</button>
