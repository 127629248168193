import { Highlightable } from '@angular/cdk/a11y';
import { Component, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';
import { CustomDropdownService } from './custom-dropdown.service';
import { CustomSelectComponent } from './custom-select.component';

@Component({
    selector: 'custom-select-option',
    template: `
        <ng-container *ngIf="!new; else createForm">
            <span class="px-3">{{ value }}</span>
        </ng-container>
        <ng-template #createForm>
            <div class="dropdown-create-form px-3 pt-3">
                <label class="me-2"> <input placeholder="Créer un nouvel Usage" #box /> </label>
                <button
                    [disabled]="!box.value"
                    mat-flat-button
                    color="primary"
                    class="btn-sm"
                    (click)="createNewUse(box.value); box.value = ''"
                >
                    Créer
                </button>
            </div>
        </ng-template>
    `,
    styleUrls: ['./_custom-select-option.scss']
})
export class CustomSelectOptionComponent implements Highlightable {
    @Input() public key: string;
    @Input() public new: boolean;
    @Input() public value: string;

    @Output() inputValueChange = new EventEmitter();
    @Output() useChanged = new EventEmitter();

    @HostBinding('class.selected')
    public get selected(): boolean {
        return this.select.selectedOption === this;
    }

    @HostBinding('class.active')
    public active = false;

    private select: CustomSelectComponent;

    constructor(private dropdownService: CustomDropdownService) {
        this.select = this.dropdownService.getSelect();
    }

    public getLabel(): string {
        return this.value;
    }

    public setActiveStyles(): void {
        this.active = true;
    }

    public setInactiveStyles(): void {
        this.active = false;
    }

    // tslint:disable-next-line:typedef
    public createNewUse(value): void {
        this.value = value;
        this.inputValueChange.emit(this);
    }

    @HostListener('click', ['$event'])
    public onClick(event: UIEvent): void {
        event.preventDefault();
        event.stopPropagation();

        if (!this.new && (this.key || this.value)) {
            this.select.selectOption(this);
            this.useChanged.emit(this);
        }
    }
}
