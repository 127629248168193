import { FileModel } from './file.model';

export class LinkedFileModel extends FileModel {
    establishmentId?: number;
    buildingId?: number;
    zoneId?: number;
    meteringPointId?: number;
    equipmentId?: number;
    vehicleId?: number;
    supplierContractId?: number;
    deviceId?: number;
    idArmoire?: number;
    idQuartier?: number;
    streetId?: number;
}

export interface ILinkedFiles {
    filesData: FormFileModel[];
    linkedFiles: LinkedFileModel[];
}

export class FormFileModel {
    static counter = 0;
    formData: File;
    formId: number;

    constructor(formData: File) {
        FormFileModel.counter--;
        this.formData = formData;
        this.formId = FormFileModel.counter;
    }
}
