import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output,
    ViewChild
} from '@angular/core';
import { AnchorDialogComponent } from '@shared/anchor-dialog/anchor-dialog.component';

@Component({
    selector: 'custom-dialog',
    templateUrl: './custom-dialog.component.html',
    styleUrls: ['./custom-dialog.component.scss']
})
export class CustomDialogComponent {
    @Input() isPanelShowed: boolean;
    @Output() togglePanel = new EventEmitter<boolean>();
    @ViewChild(AnchorDialogComponent, { static: false }) public anchorDialog: AnchorDialogComponent;

    @Input() overlayX: 'center' | 'start' | 'end' = 'center';
    @Input() overlayY: 'center' | 'bottom' | 'top' = 'bottom';
    @Input() originX: 'center' | 'start' | 'end' = 'center';
    @Input() originY: 'center' | 'bottom' | 'top' = 'top';

    @Output() clickOut = new EventEmitter();

    // padding input value
    @Input() padding = 0;

    @Input() customClass;
    @Input() closeOnScroll = true;
    @Input() useOverlay = false;

    @Input() disabled = false;

    /** pour gérer le "close on document click" */
    @HostListener('document:click', ['$event'])
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public documentClick(event: any): void {
        if (this.isPanelShowed === true && !this.contains(event.target)) {
            this.closePanel();
            this.clickOut.emit();
        }
    }
    /** pour gérer le "close on document click" */
    private contains(target: Element): boolean {
        if ((<ElementRef>this.anchorDialog.anchorElement).nativeElement.contains(target)) {
            return true;
        } else {
            // Empêcher la fermeture lors du clic sur la popup
            // no need for a loop
            return document.getElementsByClassName('anchor-dialog')[0].contains(target);
        }
    }

    _togglePanel(): void {
        this.isPanelShowed = !this.isPanelShowed;
        this.togglePanel.emit(this.isPanelShowed);
    }

    closePanel(): void {
        this.isPanelShowed = false;
    }
}
