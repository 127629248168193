import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { GroupPerimeterInfosModel } from '@app/core/models/perimeters/group/group-perimeter-infos.model';
import { PerimeterItemModel } from '@app/core/models/perimeters/perimeter-item.model';
import { DomainEnum } from '@app/shared/constants/domain.enum';
import { MODE } from '@app/shared/constants/mode.enum';
import { GroupsSelectorComponent } from '../groups-selector/groups-selector.component';
import { PerimeterSelectorComponent } from '../perimeter-selector/perimeter-selector.component';
import { GeoMenuService } from '../geographic-menu/geo-menu.service';

@Component({
    selector: 'mat-tab-perimeter-selector',
    templateUrl: './mat-tab-perimeter-selector.component.html',
    styleUrls: ['./mat-tab-perimeter-selector.component.scss']
})
export class MatTabPerimeterSelectorComponent implements OnInit, OnDestroy {
    //#region fields

    @Input() updateMode: boolean;
    @Input() selectedLabel: string;
    @Input() lowestLevelMode = true;
    @Input() selectedInfoGeographic: string;
    @Input() selectedInfoProperty: string;
    @Input() selectedInfoPublicSpace: string;

    @Input() partialSelectedLabel: string;
    @Input() loading = false;
    @Input() selection: Array<PerimeterItemModel>;
    @Input() domains: Array<DomainEnum>;
    @Input() withGroupeSelector = true;
    @Input() selectedGroups: Array<GroupPerimeterInfosModel>;
    @Input() mode: MODE;
    @Input() initialGroupsProperty: Array<GroupPerimeterInfosModel>;

    filterSelection: Array<PerimeterItemModel> = [];
    pageIndex = 0;
    searchActive = true;
    searchWidth = 0;

    searchKeyword = '';
    private _searchChanges$ = new Subject<string>();

    public geoMatTabPerimeterSelectorKey = 'geoMatTabPerimeterSelectorKey';
    public estateMatTabPerimeterSelectorKey = 'estateMatTabPerimeterSelectorKey';
    public publicSpaceMatTabPerimeterSelectorKey = 'publicSpaceMatTabPerimeterSelectorKey';

    @ViewChild('search') searchField: ElementRef;

    @ViewChild('geographic')
    public geographicPerimeterSelector: PerimeterSelectorComponent;

    @ViewChild('property')
    public propertyPerimeterSelector: PerimeterSelectorComponent;

    @ViewChild('publicSpace')
    public publicSpacePerimeterSelector: PerimeterSelectorComponent;

    @ViewChild('tabGroups')
    public tabGroupsSelector: GroupsSelectorComponent;

    //#endregion

    get domainGeographyExist(): boolean {
        return this.domains.includes(DomainEnum.Geography);
    }

    get domainPropertyExist(): boolean {
        return this.domains.includes(DomainEnum.Property);
    }

    get domainPublisSpaceExist(): boolean {
        return this.domains.includes(DomainEnum.PublicSpace);
    }

    //#region constructor and cycle hook
    constructor(private geoMenuService: GeoMenuService) {}

    ngOnInit(): void {
        this._searchChanges$.pipe(debounceTime(400), distinctUntilChanged()).subscribe(value => {
            this.searchKeyword = value;
            this.updateSearch();
        });
    }

    ngOnDestroy(): void {
        this.geoMenuService.removeSearchParameters(this.publicSpaceMatTabPerimeterSelectorKey);
        this.geoMenuService.removeSearchParameters(this.geoMatTabPerimeterSelectorKey);
        this.geoMenuService.removeSearchParameters(this.estateMatTabPerimeterSelectorKey);
    }

    //#endregion

    //#region methods

    private updateSearch(): void {
        if (this.pageIndex === 0) {
            this.geoMenuService.updateSearchParameters(this.geoMatTabPerimeterSelectorKey, {
                searchKey: this.searchKeyword,
                perimeterFilter: {}
            });

            if (this.domains.includes(DomainEnum.Property)) {
                this.geoMenuService.updateSearchParameters(this.estateMatTabPerimeterSelectorKey, {
                    searchKey: this.searchKeyword,
                    perimeterFilter: {}
                });
            }
            if (this.domains.includes(DomainEnum.PublicSpace)) {
                this.geoMenuService.updateSearchParameters(
                    this.publicSpaceMatTabPerimeterSelectorKey,
                    { searchKey: this.searchKeyword, perimeterFilter: {} }
                );
            }
        } else if (this.pageIndex === 1) {
            this.tabGroupsSelector.searchGroup();
        }
    }

    // lancer la recherche
    public showNavSearch($event: Event): void {
        $event.stopPropagation();
        if (this.searchWidth === 0) {
            this.openSearch();
        } else {
            this.closeSearch(true);
        }
    }

    /**
     * Ouvre le champ de recherche
     * Ferme les les panels et active le mode recherche
     */
    private openSearch(): void {
        this.searchWidth = 400;
        this.searchField.nativeElement.focus();
    }

    /**
     * Ferme de champ de recherche
     *
     */
    private closeSearch(reloadGroups: boolean): void {
        this.searchField.nativeElement.blur();
        this.searchField.nativeElement.value = '';
        this.searchWidth = 0;
        if (this.pageIndex === 0) {
            this.geographicPerimeterSelector.stopSearchMode();
            if (this.domains.includes(DomainEnum.Property)) {
                this.propertyPerimeterSelector.stopSearchMode();
            }
            if (this.domains.includes(DomainEnum.PublicSpace)) {
                this.publicSpacePerimeterSelector.stopSearchMode();
            }
        } else if (this.pageIndex === 1) {
            reloadGroups
                ? this.tabGroupsSelector.stopSearchModeReload()
                : this.tabGroupsSelector.stopSearchMode();
        }
        this.searchKeyword = '';
    }

    public searchInputChange(searchInput: string): void {
        this._searchChanges$.next(searchInput);
    }

    public changeSearchStatus($index: number): void {
        this.pageIndex = $index;
        if ($index === 1) {
            this.closeSearch(false);
            this.searchActive = true;
            this.searchKeyword = '';
            this.searchField.nativeElement.value = '';
            // TODO otherTabSearchEvent
        }
        if ($index === 0) {
            this.closeSearch(false);
            this.searchActive = true;
            this.searchKeyword = '';
            this.searchField.nativeElement.value = '';
        }
    }

    // vider la selection utilisateur
    public resetAllSelection($event?: Event): void {
        if ($event) {
            $event.stopPropagation();
        }
        if (this.pageIndex === 0) {
            this.geographicPerimeterSelector.removeAllSelection();
            if (this.domains.includes(DomainEnum.Property)) {
                this.propertyPerimeterSelector.removeAllSelection();
            }
            if (this.domains.includes(DomainEnum.PublicSpace)) {
                this.publicSpacePerimeterSelector.removeAllSelection();
            }
        } else if (this.pageIndex === 1) {
            this.tabGroupsSelector.removeSelection();
        }
    }

    // reset la selection de l'utilisateur a default
    public cancel($event: Event): void {
        $event.stopPropagation();

        if (this.pageIndex === 0) {
            this.geographicPerimeterSelector.resetDefaultSelection();
            if (this.domains.includes(DomainEnum.Property)) {
                this.propertyPerimeterSelector.resetDefaultSelection();
            }
            if (this.domains.includes(DomainEnum.PublicSpace)) {
                this.publicSpacePerimeterSelector.resetDefaultSelection();
            }
        } else if (this.pageIndex === 1) {
            if (this.compareGroupsInitialValues() === true) {
                this.tabGroupsSelector.resetDefaultSelection();
            }
        }
    }

    compareGroupsInitialValues(): boolean {
        if (
            this.pageIndex === 1 &&
            this.tabGroupsSelector &&
            JSON.stringify(this.tabGroupsSelector.initialValue) ===
                JSON.stringify(this.tabGroupsSelector.selectedGroups)
        ) {
            return false;
        }
        return true;
    }

    get defaultSelectionExist(): boolean {
        if (this.geographicPerimeterSelector && this.geographicPerimeterSelector.defaultSelection) {
            return true;
        }
        if (this.propertyPerimeterSelector && this.propertyPerimeterSelector.defaultSelection) {
            return true;
        }
        if (
            this.publicSpacePerimeterSelector &&
            this.publicSpacePerimeterSelector.defaultSelection
        ) {
            return true;
        }

        return false;
    }

    closedPanel($event: number): void {
        if ($event <= 0) {
            this.propertyPerimeterSelector.closeAllPanel();
            this.publicSpacePerimeterSelector.closeAllPanel();
        } else if ($event > 0 && $event <= 3) {
            this.geographicPerimeterSelector.closeAllPanel();
            this.publicSpacePerimeterSelector.closeAllPanel();
        } else if ($event > 10 && $event <= 13) {
            this.geographicPerimeterSelector.closeAllPanel();
            this.propertyPerimeterSelector.closeAllPanel();
        }
    }

    //#endregion
}
