import { Component, Input, OnInit } from '@angular/core';
import { FluidEnum } from '@shared/constants/fluid.enum';

@Component({
    selector: 'fluid-icon',
    templateUrl: './fluid-icon.component.html',
    styleUrls: ['./fluid-icon.component.scss']
})
export class FluidIconComponent implements OnInit {
    @Input() fluidId: number;
    @Input() classStyle: string;

    FluidEnum = FluidEnum;

    constructor() {}

    ngOnInit(): void {}
}
