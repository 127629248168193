import { CompactType, DisplayGrid, GridsterConfig, GridType } from 'angular-gridster2';

import { Label } from '../models/common/label.model';
import { NameValuePair } from '../types/name-value-pair.type';

export enum DashboardConfig {
    fixedColWidth = 145,
    fixedRowHeight = 120,
    mobileBreakpoint = 640,
    marginBetweenWidget = 24,
    minCols = 1,
    maxCols = 13,
    minRows = 1,
    maxRows = 20,
    maxItemCols = 100,
    minItemCols = 1,
    maxItemRows = 100,
    minItemRows = 1,
    maxItemArea = 2500,
    minItemArea = 1,
    defaultItemCols = 1,
    defaultItemRows = 1,
    scrollSensitivity = 10,
    scrollSpeed = 20,
    emptyCellDragMaxCols = 50,
    emptyCellDragMaxRows = 50,
    heightHeader = 110
}
export const MeasuresGridsterOptions = <GridsterConfig>{
    gridType: GridType.VerticalFixed,
    // fixedColWidth: number,
    fixedRowHeight: 430,
    keepFixedHeightInMobile: false,
    keepFixedWidthInMobile: false,
    // setGridSize: true,
    compactType: CompactType.CompactUp,
    mobileBreakpoint: 640,
    // minCols: MeasuresDashboardConfig.minCols,
    maxCols: 1,
    minRows: 1,
    maxRows: 20,
    // defaultItemCols: MeasuresDashboardConfig.defaultItemCols,
    defaultItemRows: 1,
    // maxItemCols?: number;
    maxItemRows: 100,
    // minItemCols?: number;
    minItemRows: 1,
    maxItemArea: 100,
    minItemArea: 0,
    margin: 5,
    outerMargin: true,
    outerMarginTop: 0,
    outerMarginRight: 0,
    outerMarginBottom: 0,
    outerMarginLeft: 0,
    scrollSensitivity: 10,
    scrollSpeed: 20,
    // initCallback?: (gridster: GridsterComponentInterface) => void;
    // destroyCallback?: (gridster: GridsterComponentInterface) => void;
    // gridSizeChangedCallback?: (gridster: GridsterComponentInterface) => void;
    // itemChangeCallback?: (item: GridsterItem, itemComponent: GridsterItemComponentInterface) => void;
    // itemResizeCallback?: (item: GridsterItem, itemComponent: GridsterItemComponentInterface) => void;
    // itemInitCallback?: (item: GridsterItem, itemComponent: GridsterItemComponentInterface) => void;
    // itemRemovedCallback?: (item: GridsterItem, itemComponent: GridsterItemComponentInterface) => void;
    // itemValidateCallback?: (item: GridsterItem) => boolean;
    draggable: {
        enabled: true,
        ignoreContent: false,
        ignoreContentClass: 'ignore-content-drag'
        // stop: this.onfinichChangeWidget.bind(this)
    },
    resizable: {
        enabled: false
    },
    swap: false,
    pushItems: true,
    disablePushOnDrag: false,
    disablePushOnResize: false,
    // disableAutoPositionOnConflict?: boolean;
    pushDirections: { north: true, east: true, south: true, west: true },
    pushResizeItems: false,
    dipslayGrid: DisplayGrid.OnDragAndResize,
    disableWindowResize: true,
    disableWarnings: false,
    scrollToNewItems: true,
    enableEmptyCellClick: false,
    enableEmptyCellContextMenu: false,
    enableEmptyCellDrop: false,
    enableEmptyCellDrag: false,
    // emptyCellClickCallback?: (event: MouseEvent, item: GridsterItem) => void;
    // emptyCellContextMenuCallback?: (event: MouseEvent, item: GridsterItem) => void;
    // emptyCellDropCallback?: (event: MouseEvent, item: GridsterItem) => void;
    // emptyCellDragCallback?: (event: MouseEvent, item: GridsterItem) => void;
    // emptyCellDragMaxCols: MeasuresDashboardConfig.emptyCellDragMaxCols,
    emptyCellDragMaxRows: 0,
    ignoreMarginInRow: false
    // api?: {
    //     resize?: () => void;
    //     optionsChanged?: () => void;
    //     getNextPossiblePosition?: (newItem: GridsterItem) => boolean;
    //     getFirstPossiblePosition?: (item: GridsterItem) => GridsterItem;
    //     getLastPossiblePosition?: (item: GridsterItem) => GridsterItem;
    // };
    // [propName: string]: any
};

/* Ne pas utiliser, ces constantes sont dans shared/constants */
export const defaultSources: Array<NameValuePair> = [
    { name: 'Facture', value: 3 },
    { name: 'Relève', value: 2 },
    { name: 'Télérelève', value: 1 }
];

/* Ne pas utiliser, ces constantes sont dans shared/constants */
export const timeSteps: Array<NameValuePair> = [
    { name: 'Annuelle', value: 1 },
    { name: 'Mensuelle', value: 2 },
    { name: 'Hebdomadaire', value: 3 },
    { name: 'Journalier', value: 4 },
    { name: 'Horaire', value: 5 },
    { name: 'Minutes', value: 6 }
];

export const perimterLevels: Array<Label> = [
    { id: -3, label: 'Pays' },
    { id: -2, label: 'Région' },
    { id: -1, label: 'Département' },
    { id: 0, label: 'Ville' },
    { id: 1, label: 'Établissement' },
    { id: 2, label: 'Bâtiment' },
    { id: 3, label: 'Zone' }
];
