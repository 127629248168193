import { NgModule } from '@angular/core';

import { LoginComponent } from './login/login.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { SiteDisabledComponent } from './site-disabled/site-disabled.component';

@NgModule({
    declarations: [LoginComponent, NotFoundComponent, UnauthorizedComponent, SiteDisabledComponent],
    exports: [LoginComponent, NotFoundComponent, UnauthorizedComponent, SiteDisabledComponent],
    imports: [],
    providers: []
})
export class PagesModule {}
