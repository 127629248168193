import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NotificationHeaderService {
    public notification: BehaviorSubject<boolean>;

    constructor() {
        this.notification = new BehaviorSubject<boolean>(false);
    }
}
