import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
    ModalDialogComponent,
    ModalMessage
} from '@app/shared/components/modal/modal-dialog/modal-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class ModalHandlerService {
    constructor(private dialog: MatDialog) {}
    /**
     * call this function to launch an error modal as alert
     * @param modalMessage
     */
    openModal(modalMessage: ModalMessage): void {
        this.dialog.open(ModalDialogComponent, {
            autoFocus: true,
            data: modalMessage,
            width: '400px'
        });
    }
}
