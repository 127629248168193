<vertuoz-loader *ngIf="!noLoader" [showLoader]="activeRequestHttp" [size]="'medium'">
  <menu type="toolbar" class="p-0 m-0">
    <ul class="d-flex align-items-center justify-content-center">
      <li *ngIf="showPrint">
        <button mat-icon-button (click)="capturGraph()">
          <mat-icon-button-content [activeRequestHttp]="activeRequestHttp">
            <mat-icon matTooltip=" {{ tooltipMessagePrint | translate }}">camera_alt</mat-icon>
          </mat-icon-button-content>
        </button>
      </li>
      <li *ngIf="showExport" [ngClass]="{ 'ps-2': showPrint }">
        <button
          mat-icon-button
          matTooltip="{{ tooltipMessageExport }}"
          [matTooltipDisabled]="isBulkActionSnackbarContent"
          [matMenuTriggerFor]="bulkExportMenu"
        >
          <mat-icon>get_app</mat-icon>
          <span *ngIf="isBulkActionSnackbarContent" class="v-tooltip ms-n5">{{
            tooltipMessageExport
          }}</span>
        </button>

        <mat-menu class="export-menu" #bulkExportMenu="matMenu">
          <ul class="dialog__btn">
            <li *ngFor="let item of fileExportList">
              <export-type
                [fileExportType]="item"
                (selectedType)="selectedTypeEmission(item)"
                [activeRequestHttp]="activeRequestHttp"
              >
              </export-type>
            </li>
          </ul>
        </mat-menu>
      </li>
      <li *ngIf="showZoom" class="ps-2">
        <button
          mat-icon-button
          class="f-screen-open"
          (click)="enableFullscreen()"
          matTooltip="{{ tooltipMessageFullscreen }}"
        >
          <mat-icon>fullscreen</mat-icon>
        </button>
        <button
          mat-icon-button
          matTooltip="Quitter le mode plein écran"
          class="f-screen-close"
          (click)="disableFullscreen()"
        >
          <mat-icon>fullscreen_exit</mat-icon>
        </button>
      </li>
      <li *ngIf="showDownload" class="ps-2">
        <button matTooltip="{{ tooltipDownload }}" mat-icon-button (click)="download()">
          <mat-icon>description</mat-icon>
        </button>
      </li>
      <li *ngIf="showFilter" class="ps-2">
        <button
          mat-icon-button
          class=""
          (click)="onOpenFilter()"
          matTooltip="{{ 'measurement-and-sensors.tooltip.show-filter' | translate }}"
        >
          <mat-icon>menu</mat-icon>
        </button>
      </li>
    </ul>
  </menu>
</vertuoz-loader>
