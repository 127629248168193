<ng-container *ngIf="!initLoading; else loader">
  <!-- Title section -->
  <h2 mat-dialog-title class="mat-dialog-title font-18 font-weight-bold mb-2">
    {{ data.title }}<br />
    <span class="sub-title">{{ data.customMessage }}</span>
  </h2>
  <!-- Content section -->
  <mat-dialog-content>
    <div *ngIf="searchMode">
      <div *ngIf="hideSearch && items && items.length">
        <button mat-icon-button matTooltip="Rechercher" (click)="openSearh()">
          <mat-icon>search</mat-icon>
        </button>
      </div>
      <div *ngIf="!hideSearch">
        <mat-form-field class="w-100">
          <input
            #search
            matInput
            type="text"
            (keyup)="onSearch(search.value)"
            placeholder="Rechercher"
          />
          <span matSuffix>
            <button type="button" mat-icon-button (click)="closeSearch()" class="mb-2">
              <mat-icon>clear</mat-icon>
            </button>
          </span>
        </mat-form-field>
      </div>
    </div>
    <div class="bg-gray-100 h-100" *ngIf="(items && items.length) || searchLoading; else noData">
      <form [formGroup]="formGroup" class="w-100" (click)="$event.stopPropagation()">
        <div
          class="items-list-infinite-scroll list-container bg-gray-100"
          infinite-scroll
          [infiniteScrollDistance]="1"
          [infiniteScrollThrottle]="100"
          [scrollWindow]="false"
          (scrolled)="appendItems()"
        >
          <ul
            #scrollDiv
            class="list-group scroll-me px-3 pt-3"
            formArrayName="selectedGroups"
            autosize
          >
            <!-- selected-items -->
            <li *ngFor="let index of selectedItemsIndex" class="list-group-item py-1 px-0">
              <mat-checkbox class="font-14" [value]="items[index]?.label" [formControlName]="index">
                <span class="word-break-all wh-sp-normal m-0">{{ items[index]?.label }}</span>
              </mat-checkbox>
            </li>
            <!-- not selected-items -->
            <li *ngFor="let index of notSelectedItemsIndex" class="list-group-item py-1 px-0">
              <mat-checkbox class="font-14" [value]="items[index]?.label" [formControlName]="index">
                <span class="word-break-all wh-sp-normal m-0">{{ items[index]?.label }}</span>
              </mat-checkbox>
            </li>
            <li *ngIf="loading">
              <mat-spinner [diameter]="30"></mat-spinner>
            </li>
          </ul>

          <div *ngIf="data.newItemForm" class="check-field px-3 mt-3 pb-2">
            <mat-checkbox formControlName="isNewGroup" class="font-14">
              <mat-form-field class="w-100">
                <input
                  matInput
                  placeholder="Nouveau groupe"
                  value=""
                  formControlName="newGroupName"
                />
              </mat-form-field>
              <ng-container
                *ngIf="
                  formGroup.controls.newGroupName.invalid &&
                  (formGroup.controls.newGroupName.dirty || formGroup.controls.newGroupName.touched)
                "
              >
                <mat-error
                  class="font-12 mt-n3 text-end"
                  *ngIf="
                    formGroup.controls.newGroupName.errors.required ||
                    formGroup.controls.newGroupName.errors.pattern
                  "
                  >Champ obligatoire.
                </mat-error>
                <mat-error
                  class="font-12 mt-n3 text-end"
                  *ngIf="formGroup.controls.newGroupName.hasError('nameExists')"
                >
                  Nom existant, veuillez en choisir un nouveau.
                </mat-error>
              </ng-container>
            </mat-checkbox>
          </div>
        </div>
      </form>
    </div>
  </mat-dialog-content>
  <!-- Action section -->
  <mat-dialog-actions class="justify-content-end pt-2">
    <button type="button" mat-stroked-button color="primary" class="btn-md" (click)="closeModal()">
      {{ 'common.action.cancel' | translate }}
    </button>
    <button
      *ngIf="items && items.length"
      mat-flat-button
      color="primary"
      class="btn-md ms-3"
      (click)="submit()"
      [disabled]="!isFormValid()"
    >
      {{ data?.actionLabel }}
    </button>
  </mat-dialog-actions>
</ng-container>

<ng-template #loader>
  <vertuoz-loader [showLoader]="true"></vertuoz-loader>
</ng-template>
<ng-template #noData>
  <ng-container>
    <p class="font-18 fw-bold" *ngIf="data.assignmentItemsOperation === Operation.ADD">
      {{ data.customEmptyAddMessage }}
    </p>
    <p class="font-18 fw-bold" *ngIf="data.assignmentItemsOperation === Operation.REMOVE">
      {{ data.customEmptyRemoveMessage }}
    </p>
  </ng-container>
</ng-template>
