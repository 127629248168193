import { LabelColor } from '@app/core/models/common/label.model';
import { LabelModel, ValueLabelModel } from '@app/features/property-data/models/comparison.model';

export enum EnergyTypeEnum {
    Primary = 1,
    Final = 2
}

export enum FluidUseEnum {
    Fluid = 1,
    Use = 2,
    None = 3
}

export class PropertyValuesModel {
    name: string;
    value?: number;
}

export class ConsumptionFluidsUsesModel {
    public type: string;
    public id: number;
    public name: string;
    public color: string;
    public colorAlt: string;
    public value?: number;
    public characValue?: number;
    public valueComparaison?: number;
    public characValueComparison?: number;
    public djuRatio?: number;
    public djuRatioComparison?: number;

    public secondValue?: number;
    public secondValueComparison?: number;
    public thirdValue?: number;
    public thirdValueComparison?: number;
}

export class ConsumptionModel {
    public dateValue: Date;
    public dateLabel: string;
    public consumptionValue: PropertyValuesModel;
    public consumptionComparisonValue: PropertyValuesModel;
    public ratioConsumptionCaracterisitic: number;
    public ratioConsumptionCaracterisiticComparaison: number;
    public djuRatio?: number;
    public djuRatioComparison?: number;
    public propertyCaracteristics?: PropertyValuesModel;
    public propertyCaracteristicsComparaison?: PropertyValuesModel;
    public fluidsOrUses: Array<ConsumptionFluidsUsesModel>;

    public secondConsumptionValue: PropertyValuesModel;
    public secondConsumptionComparisonValue: PropertyValuesModel;
    public thirdConsumptionValue: PropertyValuesModel;
    public thirdConsumptionComparisonValue: PropertyValuesModel;
}

export class ConsumptionByMeterModel {
    public fluid: LabelColor;
    public date: Date;
    public meter: LabelModel;
    public dial: LabelModel;
    public value: PropertyValuesModel;
}

export class ChartDataTime {
    time: string;

    // Final value divided by characteristic or not
    value: number;
    valueComparaison?: number;

    // Total value of a fluid or use
    totalValue: number;
    totalValueComparison?: number;

    // Characteristic value (surface, occupiers number, etc.)
    characValue?: number;
    characValueComparison?: number;

    /** Secondary values */
    secondValue?: number;
    thirdValue?: number;
    secondValueComparison: number;
    thirdValueComparison: number;

    // Used for the cumulation
    oldValue: number;

    unit: string;

    ratio?: number;
    ratioComparaison?: number;

    // Ratio pre-calculated (such as DJU ratio)
    ratioValue?: number;
    ratioComparisonValue?: number;

    fluidsOrUsages: Array<ValueLabelModel>;
    date?: Date;

    /** Sum of values for all fluids or uses */
    sumValues?: number;

    // Sum of all pre-calulated ratio value
    ratioValuesSum?: number;
}

export class ChartDataConsumption {
    dates: Array<ChartDataTime>;
    fluidOrUsage: string;
    fluidUseId: number;
    color: string;
    value: number; // To delete?
    totalValue: number; // To delete?
    characValue: number; // To delete?
    fluidOrUsageComparaison: string;
    valueComparaison: number;
    comparisonTotalValue: number;
    comparisonCharacValue: number;
    comparisonColor: string;

    // Ratio pre-calculated (such as DJU ratio)
    valueRatio?: number;
    valueRatioComparison?: number;
}

export enum CalendarWeekRuleEnum {
    FirstDay = 0,
    FirstFullWeek = 1,
    FirstFourDayWeek = 2
}

export enum DayOfWeekEnum {
    Sunday = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6
}
