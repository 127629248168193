<span [ngSwitch]="true">
  <mat-icon
    [ngClass]="classStyle"
    [svgIcon]="
      (fluidId === FluidEnum.Electricity || fluidId === FluidEnum.GreenElectricity || fluidId === FluidEnum.VehicleElectricity || fluidId === FluidEnum.VehicleElectricityGreen
        ? 'electricity'
        : '') ||
      (fluidId === FluidEnum.NaturalGas ||
      fluidId === FluidEnum.PropaneGas ||
      fluidId === FluidEnum.ButaneGas
        ? 'gas'
        : '') ||
      (fluidId === FluidEnum.HeavyFuelOil || fluidId === FluidEnum.HeatingFuel
      || fluidId === FluidEnum.VehicleEssence
      || fluidId === FluidEnum.VehicleDieselB7
      || fluidId === FluidEnum.VehicleGPL
      || fluidId === FluidEnum.VehicleOtherCompressedGaseousHydrocarbon
      || fluidId === FluidEnum.VehicleHydrogen
      || fluidId === FluidEnum.VehicleSuperEthanolE85
      || fluidId === FluidEnum.VehicleOffRoadDiesel
      || fluidId === FluidEnum.VehicleUnleaded95E5
      || fluidId === FluidEnum.VehicleUnleaded95E10
      || fluidId === FluidEnum.VehicleUnleaded98E5
      || fluidId === FluidEnum.VehicleTwoStrokeMix
      || fluidId === FluidEnum.VehicleGazolePlus
      || fluidId === FluidEnum.VehicleEthanolE100
      || fluidId === FluidEnum.VehicleKerosene
      || fluidId === FluidEnum.VehicleAdblue
      ? 'fuel' : '') ||
      (fluidId === FluidEnum.Heat ||
      fluidId === FluidEnum.HeatingNetwork ||
      fluidId === FluidEnum.HeatingGas ||
      fluidId === FluidEnum.HeatingElectricHeatPump ||
      fluidId === FluidEnum.HeatRecovery ||
      fluidId === FluidEnum.HeatingGeothermal
        ? 'heat'
        : '') ||
      (fluidId === FluidEnum.Cold || fluidId === FluidEnum.ColdNetwork ? 'snowflake' : '') ||
      (fluidId === FluidEnum.MainWater ||
      fluidId === FluidEnum.CollectedWater ||
      fluidId === FluidEnum.RecycledWater ||
      fluidId === FluidEnum.IcedWater ||
      fluidId === FluidEnum.HotWater ||
      fluidId === FluidEnum.WaterFromConcessionaire ||
      fluidId === FluidEnum.WaterHydrant ||
      fluidId === FluidEnum.SanitationWater ||
      fluidId === FluidEnum.SwimmingPoolWater ||
      fluidId === FluidEnum.BrutWater ||
      fluidId === FluidEnum.GroundWater
        ? 'water'
        : '') ||
      (fluidId === FluidEnum.WoodOther ||
      fluidId === FluidEnum.WoodForestryChipppings ||
      fluidId === FluidEnum.WoodPellets ||
      fluidId === FluidEnum.WoodBriquettes ||
      fluidId === FluidEnum.WoodIndustrialChippings
        ? 'nature'
        : '')
    "
  >
    <ng-container *ngSwitchDefault> more_horiz </ng-container>
  </mat-icon>
</span>
