<div class="input" [class.required]="required" #dropreference>
  <mat-form-field class="w-100">
    <input
      #input
      matInput
      placeholder="{{ placeholder }}"
      [disabled]="disabled"
      readonly
      [value]="displayText"
      (click)="showDropdown()"
      autocomplete="off"
      class="cursor-pointer"
    />
    <button mat-icon-button matSuffix (click)="onDropMenuIconClick($event)">
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
  </mat-form-field>

  <custom-dropdown [reference]="dropreference" #dropdownComp>
    <div class="mat-select-panel py-3 mt-n5 dropdown-options-container">
      <form [formGroup]="form" class="px-3">

        <mat-form-field class="w-100 mb-1">
          <input
            matInput
            placeholder="Min"
            type="number"
            formControlName="min"
            (focus)="focusMin()"
          />
        </mat-form-field>

        <mat-form-field class="w-100">
          <input
            matInput
            placeholder="Max"
            type="number"
            formControlName="max"
            (focus)="focusMax()"
          />
        </mat-form-field>

        <div class="pt-2 text-end">
          <button
            [disabled]="!form.get('min').value && !form.get('max').value"
            type="button"
            mat-stroked-button
            color="primary"
            class="btn-sm"
            (click)="onReset()"
          >
            {{ 'common.action.delete' | translate }}
          </button>

          <button
            mat-flat-button
            color="primary"
            class="btn-sm px-2 ms-2"
            (click)="onClick()"
            [disabled]="disabledApply()"
            matTooltip="{{
              (disabledApply() ? 'common.messages.minmustbelessthanorequalmax' : '') | translate
            }}"
          >
            {{ 'common.action.apply' | translate }}
          </button>
        </div>
      </form>
    </div>
  </custom-dropdown>
</div>
