<form class="navbar-form top-search ps-3" [class.open]="visible" [formGroup]="NavSearchFormGroup">
  <div class="form-group has-feedback bg-white">
    <div class="row row-cols-3 justify-content-start">
      <div class="col-2 pt-2">
        <mat-form-field class="w-100">
          <mat-select
            placeholder="Type d'entité"
            [(value)]="preselected"
            (selectionChange)="entitySelectionChange($event.value)"
          >
            <div *ngFor="let group of navSearchData">
              <mat-optgroup
                *hasPermissions="group.permissions"
                [label]="group.name"
                id="EntityGroup"
              >
                <ng-container *ngFor="let entity of group.entity">
                  <mat-option *hasPermissions="[entity.permission]" [value]="entity.value">
                    {{ entity.viewValue }}
                  </mat-option>
                </ng-container>
              </mat-optgroup>
            </div>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-7 top-search__field position-relative pt-2">
        <mat-form-field class="w-100">
          <!--<mat-icon matPrefix>search</mat-icon>-->
          <input
            #focusInput
            type="text"
            matInput
            [placeholder]="searchPlaceHolder"
            formControlName="searchNavFormControl"
            class="navbar-formcontrol-input"
            (focus)="focusFunction(true)"
            (focusout)="focusFunction(false)"
          />
          <mat-icon matSuffix>search</mat-icon>
          <mat-hint *ngIf="isLoading" align="start">Recherche des données en cours ...</mat-hint>
          <mat-hint *ngIf="isLoaded" align="end"
            ><strong>{{ navSearchResult.length }}</strong> résulat{{
              navSearchResult.length > 1 ? 's' : ''
            }}
          </mat-hint>
        </mat-form-field>

        <div
          class="result-grid position-absolute start-0 w-100 bg-white"
          *ngIf="navSearchResult.length !== 0"
        >
          <mat-option
            *ngFor="let entity of navSearchResult"
            [value]="entity.id"
            class="result-grid__option p-0 px-3 m-0 w-100"
            disabled
          >
            <div class="row py-3 border-bottom border-grey-100">
              <div class="col-10 align-self-center">
                <p class="m-0 mb-1">
                  <span class="result-grid__label color-black">{{ entity.label }}</span>
                  <span class="result-grid__label color-black" *ngIf="entity.idSiTiers"
                    >( {{ entity.idSiTiers }} )</span
                  >
                </p>

                <p class="result-grid__sublabels font-14 m-0">
                  {{ !!entity.subLabel1 ? entity.subLabel1 + '  |  ' : '' }}
                  {{ !!entity.subLabel2 ? entity.subLabel2 + '  |  ' : '' }}
                  {{ !!entity.subLabel3 ? entity.subLabel3 + '  |  ' : '' }}
                  {{ !!entity.subLabel4 ? entity.subLabel4 + '  |  ' : '' }}
                  {{ !!entity.subLabel5 ? entity.subLabel5 + '  |  ' : '' }}
                  {{ !!entity.subLabel6 ? entity.subLabel6 : '' }}
                </p>
              </div>
              <div class="col-2 result-grid__buttons text-end align-self-center">
                <ng-container *ngIf="entity.editPath">
                  <a
                    mat-icon-button
                    *hasPermissions="[entity.editPermission]"
                    (click)="navigateToEditPage(entity.editPath)"
                    matTooltip="Modifier"
                  >
                    <mat-icon>edit</mat-icon>
                  </a>
                </ng-container>
                <ng-container *ngIf="entity.viewPath">
                  <a
                    mat-icon-button
                    *hasPermissions="[entity.viewPermission]"
                    (click)="navigateToViewPage(entity.viewPath)"
                    matTooltip="Visualiser"
                  >
                    <mat-icon>visibility</mat-icon>
                  </a>
                </ng-container>
              </div>
            </div>
          </mat-option>
        </div>
      </div>
      <div class="col-3 align-self-center text-end pe-4">
        <button mat-icon-button (click)="closeNavSearch()" matTooltip="Fermer" class="me-4">
          <mat-icon>cancel</mat-icon>
        </button>
      </div>
    </div>
  </div>
</form>
