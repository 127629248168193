import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'sidebar-custom',
    templateUrl: './sidebar-custom.component.html',
    styleUrls: ['./sidebar-custom.component.scss']
})
export class SidebarCustomComponent implements OnInit {
    ngOnInit(): void {}
}
