<div class="widget mb-4">
  <header>
    <div class="row">
      <div class="col-12">
        <h2 class="widget__title mb-3">{{ title }}</h2>
      </div>
    </div>
  </header>
  <ng-content></ng-content>
</div>
