//#region Imports
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import * as Highcharts from 'highcharts';
import { Chart } from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
HC_exporting(Highcharts);

import { I18nService } from '@app/core/internationalization/i18n.service';
import { ChartSerieModel } from '@app/core/models/charts/charts.models';
import { SensorMeasureModel } from '@app/features/device/models/models';
import { LoadCurveZone } from '@app/features/load-curve/components/load-curve-view/model/model';
import {
    BuildingWithConservationTemperature,
    DjuValuesSerieModel,
    PdcSerieConsumptions
} from '@app/features/measure/models/models';
import { TimestepEnum } from '@app/shared/constants/date-time.enum';
import { TranslateService } from '@ngx-translate/core';
import { Operators } from '@app/core/models/data-control/enums/data-control.enum';

declare const require;

const Boost = require('highcharts/modules/boost');
const noData = require('highcharts/modules/no-data-to-display');
const More = require('highcharts/highcharts-more');

Boost(Highcharts);
More(Highcharts);
noData(Highcharts);

//#endregion

@Component({
    selector: 'vertuoz-chart',
    templateUrl: './vertuoz-chart.component.html',
    styleUrls: ['./vertuoz-chart.component.scss']
})
export class VertuozChartComponent implements OnChanges {
    //#region Inputs
    @Input() loading: boolean;
    @Input() noDial: boolean;
    @Input() xAxis: Array<string>;
    @Input() series: SensorMeasureModel[];
    @Input() pdcConsumptionSerie: PdcSerieConsumptions[];
    @Input() djuSerie: DjuValuesSerieModel;
    @Input() buildingWithConservationTemperature: BuildingWithConservationTemperature;
    @Input() areaRangeSeries: number[][][];
    @Input() areaRangeName: string;
    @Input() thresholdMin: number;
    @Input() thresholdMax: number;
    @Input() serie: number[];
    @Input() zones: Array<LoadCurveZone>;
    @Input() subscribedPower: Array<number>;
    @Input() noDataMsg: string;
    @Input() chartTitle: string;
    @Input() isLegendEnabled: boolean;
    @Input() isMarkerEnabled: boolean;
    @Input() yAxisTitle: string;
    @Input() unit: string;
    @Input() isLoadCurve: boolean;
    @Input() timeStep: TimestepEnum;
    @Output() mouseMove: EventEmitter<object> = new EventEmitter();
    @Output() mouseLeave: EventEmitter<object> = new EventEmitter();
    @Output() chartOption = new EventEmitter<Object>();

    @Output() public hiddenSeriesChanged = new EventEmitter<string[]>();
    private hiddenSeries: string[] = [];
    //#endregion

    //#region Properties
    public options: Object = {};

    private chart: Chart;
    private serieValue = [];
    private defaultNoDataMsg = 'Ce graphique ne contient pas de données';

    cid: string;
    isFullScreenChart = false;
    fullscreenIcon =
        '<svg version="1.1" id="IconsRepoEditor" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 384.97 384.97" enable-background="new 0 0 384.97 384.97" xml:space="preserve" width="16px" height="16px" fill="#000000" stroke="#000000" stroke-width="0"><g id="IconsRepo_bgCarrier"></g> <g id="IconsRepoEditor"> <path d="M384.97,12.03c0-6.713-5.317-12.03-12.03-12.03H264.847c-6.833,0-11.922,5.39-11.934,12.223 c0,6.821,5.101,11.838,11.934,11.838h96.062l-0.193,96.519c0,6.833,5.197,12.03,12.03,12.03c6.833-0.012,12.03-5.197,12.03-12.03 l0.193-108.369c0-0.036-0.012-0.06-0.012-0.084C384.958,12.09,384.97,12.066,384.97,12.03z"></path> <path d="M120.496,0H12.403c-0.036,0-0.06,0.012-0.096,0.012C12.283,0.012,12.247,0,12.223,0C5.51,0,0.192,5.317,0.192,12.03 L0,120.399c0,6.833,5.39,11.934,12.223,11.934c6.821,0,11.838-5.101,11.838-11.934l0.192-96.339h96.242 c6.833,0,12.03-5.197,12.03-12.03C132.514,5.197,127.317,0,120.496,0z"></path> <path d="M120.123,360.909H24.061v-96.242c0-6.833-5.197-12.03-12.03-12.03S0,257.833,0,264.667v108.092 c0,0.036,0.012,0.06,0.012,0.084c0,0.036-0.012,0.06-0.012,0.096c0,6.713,5.317,12.03,12.03,12.03h108.092 c6.833,0,11.922-5.39,11.934-12.223C132.057,365.926,126.956,360.909,120.123,360.909z"></path> <path d="M372.747,252.913c-6.833,0-11.85,5.101-11.838,11.934v96.062h-96.242c-6.833,0-12.03,5.197-12.03,12.03 s5.197,12.03,12.03,12.03h108.092c0.036,0,0.06-0.012,0.084-0.012c0.036-0.012,0.06,0.012,0.096,0.012 c6.713,0,12.03-5.317,12.03-12.03V264.847C384.97,258.014,379.58,252.913,372.747,252.913z"></path> </g></svg>';
    //#endregion

    //#region constructor
    constructor(private i18nService: I18nService, private translateService: TranslateService) {
        this.cid = new Date().getTime() + '_' + this.unit;
    }
    //#endregion

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.chart) {
            setTimeout(() => this.updateChart(), 50);
        } else {
            if (changes.loading && changes.loading.currentValue) {
                this.showLoadingMessage();
            } else {
                this.hideLoadingMessage();
                setTimeout(() => this.updateChart(), 50);
            }
        }
    }

    public onMouseMove(event: Event): void {
        this.mouseMove.emit({ event: event, hoverPoint: this.chart.hoverPoint });
    }

    // tslint:disable-next-line:typedef
    public onMouseLeave(event): void {
        this.mouseMove.emit({ event: event, hoverPoint: this.chart.hoverPoint });
    }

    private updateChart(): void {
        if (this.serie) {
            this.serieValue = [
                {
                    name: 'Puissance',
                    type: 'line',
                    turboThreshold: this.serie.length,
                    data: this.serie,
                    zoneAxis: 'x',
                    zones: this.zones
                },
                {
                    name: 'Puissance souscrite',
                    dashStyle: 'Dot',
                    color: '#666666',
                    stickyTracking: false,
                    turboThreshold: this.subscribedPower.length,
                    data: this.subscribedPower,
                    step: true
                }
            ];
        }

        const yaxis = [
            {
                title: {
                    text: this.yAxisTitle,
                    rotation: -90,
                    useHTML: true
                },
                opposite: false,
                min: this.areaRangeSeries ? this.thresholdMin : null,
                max: this.areaRangeSeries ? this.thresholdMax : null,
                startOnTick: this.areaRangeSeries ? false : true,
                endOnTick: this.areaRangeSeries ? false : true
            }
        ];
        if (this.djuSerie && this.djuSerie.values && this.djuSerie.values.length > 0) {
            yaxis.push({
                title: {
                    text: 'DJU',
                    rotation: -90,
                    useHTML: false
                },
                opposite: true,
                min: null,
                max: null,
                startOnTick: true,
                endOnTick: true
            });
        }

        if (this.pdcConsumptionSerie && this.pdcConsumptionSerie.length > 0) {
            yaxis.push({
                title: {
                    text: 'Consommation (kWh)',
                    rotation: -90,
                    useHTML: false
                },
                opposite: true,
                min: null,
                max: null,
                startOnTick: true,
                endOnTick: true
            });
        }
        if (this.isLoadCurve) {
            this.options = {
                isSynced: true,
                chart: {
                    numberFormatter: function(): void {
                        return Highcharts.numberFormat.apply(0, arguments);
                    },
                    style: {
                        fontFamily: 'Roboto',
                        marginLeft: 10
                    },
                    events: {
                        redraw: function(): void {
                            this.onRedraw();
                        }.bind(this)
                    }
                },
                plotOptions: {
                    series: {
                        marker: { enabled: this.isMarkerEnabled, symbol: 'circle' },
                        connectNulls: true
                    }
                },
                title: { text: this.chartTitle = '' },
                legend: {
                    enabled: this.isLegendEnabled
                },
                lang: {
                    noData: this.loading
                        ? null
                        : this.noDataMsg
                        ? this.noDataMsg
                        : this.defaultNoDataMsg,
                    contextButtonTitle: '',
                    downloadPNG: 'Télécharger en PNG',
                    downloadPDF: 'Télécharger en PDF',
                    downloadJPEG: 'Télécharger en JPEG',
                    downloadCSV: 'Télécharger en CSV',
                    downloadSVG: 'Télécharger en SVG',
                    viewFullscreen: 'Voir en mode plein écran',
                    printChart: 'Imprimer la charte'
                },
                noData: {
                    style: {
                        fontWeight: 'bold',
                        fontFamily: 'Roboto',
                        fontSize: '10px',
                        color: '#303030'
                    }
                },
                credits: { enabled: false },

                xAxis: {
                    type: 'string',
                    categories: this.xAxis ? this.xAxis : null,

                    crosshair: this.serie
                        ? false
                        : {
                              width: 1,
                              color: 'black'
                          }
                },
                yAxis: yaxis,
                tooltip: {
                    borderWidth: 0.5,
                    style: {
                        fontFamily: 'Roboto',
                        fontSize: '12px'
                    },
                    shared: true
                },
                series:
                    this.serie && this.serie.length > 0
                        ? this.serieValue
                        : this.getMeasures(this.series),

                navigation: {
                    buttonOptions: {
                        x: -60,
                        y: -12
                    }
                },

                exporting: {
                    enabled: false,
                    buttons: {
                        contextButton: {}
                    }
                }
            };
        } else {
            this.options = {
                isSynced: true,
                chart: {
                    type: 'spline',
                    zoomType: 'x',
                    numberFormatter: function(): void {
                        return Highcharts.numberFormat.apply(0, arguments);
                    },
                    style: {
                        fontFamily: 'Roboto',
                        marginLeft: 10
                    },
                    events: {
                        redraw: function(): void {
                            this.onRedraw();
                        }.bind(this)
                    }
                },
                plotOptions: {
                    series: {
                        marker: { enabled: true, radius: 3, symbol: 'circle' },
                        connectNulls: true
                    }
                },
                title: { text: this.chartTitle = '' },
                legend: {
                    enabled: this.isLegendEnabled
                },
                lang: {
                    noData: this.loading
                        ? null
                        : this.noDataMsg
                        ? this.noDataMsg
                        : this.defaultNoDataMsg,
                    contextButtonTitle: '',
                    downloadPNG: 'Télécharger en PNG',
                    downloadPDF: 'Télécharger en PDF',
                    downloadJPEG: 'Télécharger en JPEG',
                    downloadCSV: 'Télécharger en CSV',
                    downloadSVG: 'Télécharger en SVG',
                    viewFullscreen: 'Voir en mode plein écran',
                    printChart: 'Imprimer la charte'
                },
                noData: {
                    style: {
                        fontWeight: 'bold',
                        fontFamily: 'Roboto',
                        fontSize: '10px',
                        color: '#303030'
                    }
                },
                credits: { enabled: false },
                xAxis: {
                    type: 'datetime',
                    labels: {
                        format: this.areaRangeSeries
                            ? '{value:%d/%m/%Y %H:%M}'
                            : this.timeStep
                            ? this.timeStep === TimestepEnum.Yearly
                                ? '{value:%Y}'
                                : this.timeStep === TimestepEnum.Monthly
                                ? '{value:%m/%Y}'
                                : this.timeStep === TimestepEnum.Daily
                                ? '{value:%d/%m/%Y}'
                                : this.timeStep === TimestepEnum.Hourly
                                ? '{value:%d/%m/%Y %H:%M}'
                                : this.timeStep === TimestepEnum.Minutes10
                                ? '{value:%d/%m/%Y %H:%M}'
                                : '{value:%d/%m/%Y}'
                            : '{value:%d/%m/%Y}',
                        rotation: -45
                    },
                    crosshair: this.serie
                        ? false
                        : {
                              width: 1,
                              color: 'black'
                          }
                },
                yAxis: yaxis,
                tooltip: {
                    borderWidth: 0.5,
                    style: {
                        fontFamily: 'Roboto',
                        fontSize: '12px'
                    },
                    shared: true,
                    xDateFormat: this.areaRangeSeries
                        ? '%d/%m/%Y %H:%M'
                        : this.timeStep
                        ? this.timeStep === TimestepEnum.Yearly
                            ? '%Y'
                            : this.timeStep === TimestepEnum.Monthly
                            ? '%m/%Y'
                            : this.timeStep === TimestepEnum.Daily
                            ? '%d/%m/%Y'
                            : this.timeStep === TimestepEnum.Hourly
                            ? '%d/%m/%Y %H:%M'
                            : this.timeStep === TimestepEnum.Minutes10
                            ? '%d/%m/%Y %H:%M'
                            : '%d/%m/%Y'
                        : '%d/%m/%Y'
                },
                series: this.getMeasures(this.series),

                navigation: {
                    buttonOptions: {
                        x: -60,
                        y: -12
                    }
                },

                exporting: {
                    enabled: false,
                    buttons: {
                        contextButton: {}
                    }
                }
            };
        }

        this.updateChartOptions();

        // this.chart.update(this.options, true, true, true);
        // the update not updating the zones coloration, so we create a new chart on data change
        // visual results are the same
        // todo: check for a solution to update colors with chart
        this.chart = null;
        const test = this.options;
        this.chart = Highcharts.chart(this.cid, this.options);
        Highcharts.setOptions({
            lang: {
                decimalPoint: this.i18nService.locale === 'fr-FR' ? ',' : '.',
                thousandsSep: this.i18nService.locale === 'fr-FR' ? ' ' : ',',
                resetZoom: 'Réinitialiser le zoom'
            },
            time: {
                useUTC: this.isLoadCurve ? true : false
            }
        });
        // dispatch a resize event to update chart width after init
        window.dispatchEvent(new Event('resize'));
    }

    updateChartOptions(): void {
        this.chartOption.emit(this.options);
    }

    onRedraw(): void {
        if (!this.hiddenSeries) {
            this.hiddenSeries = this.getHiddenSeries();
        }
        const ids = this.getHiddenSeries();
        if (JSON.stringify(ids.sort()) !== JSON.stringify(this.hiddenSeries)) {
            this.hiddenSeries = ids;
            // si la liste des séries modifiées est différentes on envoie l'évènement hiddenSeriesChanged
            this.hiddenSeriesChanged.emit(this.getHiddenSeries());
        }
    }

    getHiddenSeries(): string[] {
        if (this.chart && this.chart.series) {
            return this.chart.series.filter(s => !s.visible).map(o => o.options.id);
        } else {
            return [];
        }
    }

    getMeasures(series: SensorMeasureModel[]): Array<ChartSerieModel> {
        const mesuresAndConso: Array<ChartSerieModel> = [];
        if (series && series.length > 0) {
            this.series
                .sort((a, b) => a.sensor.sensorLabel.localeCompare(b.sensor.sensorLabel))
                .forEach(s => {
                    const dataValues = new Array<Array<number>>();
                    s.measures.forEach(m => {
                        if (m.measure != null) {
                            dataValues.push([m.dateTime.getTime(), m.measure]);
                        }
                    });

                    mesuresAndConso.push({
                        id: s.sensor.sensorId,
                        name: s.sensor.sensorLabel,
                        type: 'spline',
                        turboThreshold: 10000,
                        data: dataValues,
                        zoneAxis: 'x',
                        yAxis: 0,
                        zIndex: 1,
                        tooltip: {
                            valueSuffix: ' ' + this.unit,
                            valueDecimals: 2
                        },
                        mesureTypeId: s.sensor.sensorMeasureType.measureTypeId
                    });
                });
        }

        if (this.pdcConsumptionSerie && this.pdcConsumptionSerie.length > 0) {
            this.pdcConsumptionSerie.forEach(pdc => {
                const dataValues = new Array<Array<number>>();
                pdc.consumptions.forEach(m => {
                    if (m.kwh != null) {
                        dataValues.push([m.date.getTime(), m.kwh]);
                    }
                });
                mesuresAndConso.push({
                    name: pdc.PdcName,
                    type: 'column',
                    turboThreshold: 10000,
                    data: dataValues,
                    zoneAxis: 'x',
                    yAxis: 1,
                    zIndex: 0,
                    tooltip: {
                        valueSuffix: ' kWh',
                        valueDecimals: 0
                    },
                    color: 'rgba(63,127,191,0.80)',
                    mesureTypeId: null,
                    time: {
                        useUTC: this.isLoadCurve ? true : false
                    }
                });
            });
        }
        if (this.djuSerie && this.djuSerie.values && this.djuSerie.values.length > 0) {
            const dataValues = new Array<Array<number>>();
            this.djuSerie.values.forEach(m => {
                if (m.value != null) {
                    dataValues.push([m.date.getTime(), m.value]);
                }
            });
            mesuresAndConso.push({
                name: this.djuSerie.WeatherStationName,
                type: 'line',
                turboThreshold: 10000,
                data: dataValues,
                zoneAxis: 'x',
                yAxis: 1,
                zIndex: 1,
                tooltip: {
                    valueSuffix: ' DJU',
                    valueDecimals: 2
                },
                color: 'black',
                mesureTypeId: null
            });
        }

        if (
            this.buildingWithConservationTemperature &&
            this.buildingWithConservationTemperature.buildingId !== 0
        ) {
            const conservationTemperatureSerie = new Array<Array<number>>();
            mesuresAndConso.forEach(mac => {
                mac.data.forEach(o => {
                    conservationTemperatureSerie.push([
                        o[0],
                        this.buildingWithConservationTemperature.conservationTemperature
                    ]);
                });
            });

            mesuresAndConso.push({
                name: this.translateService.instant(
                    'property.caracteristic.conservation-temperature'
                ),
                dashStyle: 'Dot',
                turboThreshold: conservationTemperatureSerie.length,
                data: conservationTemperatureSerie,
                zoneAxis: 'x',
                yAxis: 0,
                zIndex: 0,
                color: 'black',
                tooltip: {
                    valueSuffix: ' °C',
                    valueDecimals: 1
                },
                mesureTypeId: null
            });
        }
        if (this.areaRangeSeries) {
            mesuresAndConso.push({
                type: 'arearange',
                turboThreshold: 0,
                boostThreshold: 0,
                data: this.areaRangeSeries[0],
                mesureTypeId: null,
                name: this.areaRangeName,
                fillOpacity: 0.3,
                zIndex: 0,
                unit: this.unit,
                color: '#7cb5ec',
                keys: ['x', 'low', 'high', 'custom'],
                connectNulls: false,
                marker: {
                    enabled: false,
                    fillColor: null,
                    lineColor: null,
                    lineWidth: null
                },
                tooltip: {
                    shared: true,
                    pointFormatter: function() {
                        const operator = this.options.custom.operator;
                        const operatorIs = this.options.custom.operatorIs;

                        const operatorName =
                            operator === 1
                                ? 'supérieure à'
                                : operator === 2
                                ? 'inférieure à'
                                : operator === 3
                                ? 'égale à'
                                : 'comprise entre';
                        const operatorIsName = operatorIs ? 'est' : "n'est pas";

                        let sentence = '';

                        if (
                            (operator === Operators.Between || operator === Operators.Equal) &&
                            !operatorIs
                        ) {
                            if (operator === Operators.Equal) {
                                sentence = `La valeur ${operatorIsName} ${operatorName} ${this.options.low} ${this.series.options.unit}`;
                            } else {
                                sentence = `La valeur ${operatorIsName} ${operatorName} ${this.options.low} ${this.series.options.unit} et`;
                            }
                        } else {
                            if (operator === Operators.Between) {
                                sentence = `La valeur ${operatorIsName} ${operatorName} ${this.options.low} ${this.series.options.unit} et ${this.options.high} ${this.series.options.unit}`;
                            } else {
                                sentence = `La valeur ${operatorIsName} ${operatorName} ${this.options.low} ${this.series.options.unit}`;
                            }
                        }

                        return `
                    <br>
                    ${sentence}`;
                    }
                }
            });
            mesuresAndConso.push({
                type: 'arearange',
                turboThreshold: 0,
                boostThreshold: 0,
                data: this.areaRangeSeries[1],
                fillOpacity: 0.3,
                mesureTypeId: null,
                name: null,
                zIndex: 0,
                color: '#7cb5ec',
                connectNulls: false,
                unit: this.unit,
                keys: ['x', 'low', 'high', 'custom'],
                linkedTo: ':previous',
                marker: {
                    enabled: false,
                    fillColor: null,
                    lineColor: null,
                    lineWidth: null
                },
                tooltip: {
                    shared: true,
                    pointFormatter: function() {
                        const operator = this.options.custom.operator;
                        const operatorIs = this.options.custom.operatorIs;

                        let sentence = '';

                        if (
                            (operator === Operators.Between || operator === Operators.Equal) &&
                            !operatorIs
                        ) {
                            if (operator === Operators.Between) {
                                sentence = ` ${this.options.low} ${this.series.options.unit}`;
                            }
                        } else {
                            if (operator === Operators.Between) {
                                sentence = ` ${this.options.low} ${this.series.options.unit}`;
                            }
                        }

                        return `${sentence}`;
                    }
                }
            });
        }
        return mesuresAndConso;
    }

    showLoadingMessage(customMessage: string = 'Chargement des données ...'): void {
        this.chart.showLoading(customMessage);
    }

    hideLoadingMessage(): void {
        this.chart.hideLoading();
    }

    onFullScreenToggle(): void {
        this.isFullScreenChart = !this.isFullScreenChart;
        if (this.chart) {
            this.chart.reflow();
        }
    }

    //#endregion
}
