const clientId = '_#AppId#_';
const tenantName = '_#TenantName#_';
const tenantUrl = `${tenantName}.onmicrosoft.com`;
const DashboardOfferID = 1;

const portalUrl = '_#PortalUrl#_';
const apiEndPoint = '_#ApiEndpoint#_';
const dashboardUrl = '_#DashboardUrl#_';

const loginRedirectUri = dashboardUrl;
const logoutRedirectUri =
    dashboardUrl + '/assets/pages/logout-redirect.html?portalUrl=' + portalUrl;

const version = '_#BUILD_BUILDNUMBER#_';
const googleMapKey = '_#GoogleMapKey#_';
const appInsightsInstrumentationKey = '_#AppInsightsInstrumentationKey#_';
const azureMapsKey = '_#AzureMapsKey#_';

//#region Constants
const signUpSignInPolicyName = 'B2C_1_SignIn';
const forgotPasswordPolicyName = 'B2C_1_PasswordReset';

const authorityDomain = `${tenantName}.b2clogin.com`;
const authoritySignUpSignIn = `https://${authorityDomain}/${tenantUrl}/${signUpSignInPolicyName}/`;
const authorityForgotPassword = `https://${authorityDomain}/${tenantUrl}/${forgotPasswordPolicyName}/`;

//#endregion
//#region Config
export const environment = {
    isLocal: false,
    production: '_#IsProduction#_',
    API_ENDPOINT: apiEndPoint,
    levelNation: -3,
    levelRegion: -2,
    levelCounty: -1,
    levelTown: 0,
    levelEstablishment: 1,
    levelBuilding: 2,
    levelZone: 3,
    levelGroup: 100,
    page_global: 0,
    pageSize_global: 50,
    appInsightsConfig: {
        instrumentationKey: appInsightsInstrumentationKey
    },
    authConfig: {
        clientId: clientId,
        authority_signUpSignIn: authoritySignUpSignIn,
        authority_forgotPassword: authorityForgotPassword,
        authorityDomain: authorityDomain,
        redirectUri: loginRedirectUri,
        postLogoutRedirectUri: logoutRedirectUri,
        navigateToLoginRequestUrl: true,
        consentScopes: [`https://${tenantUrl}/api/vertuoz-v2-api`]
    },
    extLinks: {
        portal: portalUrl,
        portalAdministration: portalUrl + '/administration'
    },
    version: version,
    GOOGLEMAP_KEY: googleMapKey,
    DASHBOARD_OFFERID: DashboardOfferID,
    AZURE_MAPS_KEY: azureMapsKey
};
//#endregion
