import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'lang-selector',
    templateUrl: './lang-selector.component.html',
    styleUrls: ['./lang-selector.component.scss']
})
export class LangSelectorComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
