<mat-icon
  [matTooltip]="matTooltip"
  [ngClass]="
    (equipmentType === EquipmentType.Energy ? 'electricity-icon' : '') ||
    (equipmentType === EquipmentType.AdductionSanitation ? 'water-icon' : '')
  "
  [svgIcon]="
    (equipmentType === EquipmentType.Energy ? 'electricity' : '') ||
    (equipmentType === EquipmentType.AdductionSanitation ? 'water' : '')
  "
></mat-icon>
