<widget-base [title]="(getPerimeterLevelRSX(perimeterLevel) | translate) + ' ' + perimeterName">
  <vertuoz-loader [showLoader]="loading" [size]="'tall'">
    <div class="details__content font-14 pt-2">
      <!-- ID CLIENT -->
      <p class="row" *ngIf="subTitle">
        <strong class="col color-gray-500">{{ subTitle }}</strong>
      </p>

      <!-- SECTORS -->
      <p class="row">
        <span class="col">
          <span *ngIf="tagsActivitySectors && tagsActivitySectors.length > 0">
            <strong
              *ngFor="let secteurA of tagsActivitySectors"
              class="badge badge-text badge-classic badge-activity-sector me-2 mb-1"
            >
              {{ secteurA }}
            </strong>
          </span>

          <span *ngIf="tagCustomizedSectors && tagCustomizedSectors.length > 0">
            <strong
              *ngFor="let secteurA of tagCustomizedSectors"
              class="badge badge-text badge-classic badge-customized-sector me-2 mb-1"
            >
              {{ secteurA }}
            </strong>
          </span>

          <span *ngIf="tagActivityCategories && tagActivityCategories.length > 0">
            <strong
              *ngFor="let secteurA of tagActivityCategories"
              class="badge badge-text badge-classic badge-activity-category me-2 mb-1"
            >
              {{ secteurA }}
            </strong>
          </span>
        </span>
      </p>

      <!-- MAP -->
      <div *ngIf="!hideMap" class="row mb-4 position-relative widget__map">
        <div
          class="position-absolute w-100 h-100"
          *ngIf="
            gps_coo && gps_coo.length === 2 && this.gps_coo[0] != null && this.gps_coo[1] != null
          "
        >
          <agm-map [latitude]="gps_coo[0]" [longitude]="gps_coo[1]" [zoom]="15">
            <agm-marker
              [latitude]="gps_coo[0]"
              [longitude]="gps_coo[1]"
              [iconUrl]="'assets/img/carto/building.svg'"
              (mouseOver)="infoWindow.open()"
              (mouseOut)="infoWindow.close()"
            >
              <agm-info-window [disableAutoPan]="false" #infoWindow>
                <div>Latitude : {{ gps_coo[0] }}</div>
                <div>Longitude : {{ gps_coo[1] }}</div>
              </agm-info-window>
            </agm-marker>
          </agm-map>
        </div>
        <div
          class="widget__lostcity bg-gray-100 position-absolute w-100 h-100"
          *ngIf="!(gps_coo && gps_coo.length === 2 && gps_coo[0] && gps_coo[1])"
        ></div>
      </div>

      <!-- GEO -->
      <p
        class="row"
        *ngIf="
          address || postCode || (parents && (parents[0] || parents[1] || parents[2] || parents[3]))
        "
      >
        <span class="col-1"> <mat-icon class="mat-icon-16">gps_fixed</mat-icon> </span>

        <span class="col-11">
          <ng-container *ngIf="address">{{ address }}</ng-container>

          <span class="d-block">
            <ng-container *ngIf="postCode"> {{ postCode }} </ng-container>

            <ng-container *ngIf="parents">
              <ng-container *ngIf="parents[3]">
                <a [routerLink]="[townPath, parents[3].id]">{{ parents[3].label }}</a>
                <ng-container *ngIf="parents[2] || parents[1] || parents[0]"> ,</ng-container>
              </ng-container>
              <ng-container *ngIf="parents[2]">
                <a [routerLink]="[countyPath, parents[2].id]">{{ parents[2].label }}</a>
                <ng-container *ngIf="parents[1] || parents[0]"> ,</ng-container>
              </ng-container>

              <ng-container *ngIf="parents[1]">
                <a [routerLink]="[regionPath, parents[1].id]">{{ parents[1].label }}</a>
                <ng-container *ngIf="parents[0]"> ,</ng-container>
              </ng-container>

              <ng-container *ngIf="parents[0]">
                <a [routerLink]="[nationPath, parents[0].id]">{{ parents[0].label }}</a>
              </ng-container>
            </ng-container>
          </span>
        </span>
      </p>

      <!--Links Property (Building & Zone only) -->
      <p
        class="row"
        *ngIf="
          (perimeterLevel === EnumPerimeterLevels.ZONE ||
            perimeterLevel === EnumPerimeterLevels.BUILDING) &&
          parents &&
          (parents[4] || parents[5])
        "
      >
        <span class="col-1"> <mat-icon class="mat-icon-16">location_city</mat-icon> </span>
        <span class="col-11">
          <span *ngIf="parents && parents[4]">
            <a [routerLink]="[establishmentPath, parents[4].id]">{{ parents[4].label }}</a>
          </span>
          <span *ngIf="parents && parents[5]">
            <a [routerLink]="[buildingPath, parents[5].id]">, {{ parents[5].label }}</a>
          </span>
        </span>
      </p>

      <!-- Links EP (Street & Cabinet only)-->
      <p
        class="row"
        *ngIf="
          (perimeterLevel === EnumPerimeterLevels.CABINET ||
            perimeterLevel === EnumPerimeterLevels.STREET) &&
          parents &&
          (parents[4] || parents[5])
        "
      >
        <span class="col-1"> <mat-icon class="mat-icon-16">location_city</mat-icon> </span>
        <span class="col-11">
          <span *ngIf="parents && parents[4]">
            <a [routerLink]="[districtPath, parents[4].id]">{{ parents[4].label }}</a>
          </span>
          <span *ngIf="parents && parents[5]">
            <a [routerLink]="[streetPath, parents[5].id]">, {{ parents[5].label }}</a>
          </span>
        </span>
      </p>

      <!-- WEATHER (Establishment only) -->
      <p
        class="row"
        *ngIf="
          perimeterLevel === EnumPerimeterLevels.ESTABLISHMENT && (altitude || wheatherStation)
        "
      >
        <span class="col-1"> <mat-icon class="mat-icon-16">wb_sunny</mat-icon> </span>
        <span class="col-11">
          <ng-container *ngIf="altitude">{{ altitude }} (m)</ng-container>
          <ng-container *ngIf="altitude && wheatherStation"> - </ng-container>
          <ng-container *ngIf="wheatherStation"> {{ wheatherStation }}</ng-container>
        </span>
      </p>

      <!-- USAGE (Zone only) -->
      <p class="row" *ngIf="perimeterLevel === EnumPerimeterLevels.ZONE && use">
        <span class="col-2">Usage de la zone</span>
        <span class="col-10">
          <strong class="badge badge-text badge-classic badge-use">{{ use }}</strong>
        </span>
      </p>

      <!-- LINK (Establishment and Building only)-->
      <p
        class="row"
        *ngIf="
          (perimeterLevel === EnumPerimeterLevels.ESTABLISHMENT ||
            perimeterLevel === EnumPerimeterLevels.BUILDING) &&
          webLink
        "
      >
        <span class="col-1"></span>
        <span class="col-11"> <a href="{{ webLink }}">lien externe</a> </span>
      </p>

      <!-- COMMENT (District only)-->
      <p class="row" *ngIf="perimeterLevel === EnumPerimeterLevels.DISTRICT">
        <span class="col-2">Commentaires</span> <span class="col-10"> {{ comments }} </span>
      </p>
      <p></p>
    </div>
  </vertuoz-loader>
</widget-base>
