import { Component, Input, OnInit } from '@angular/core';
import { EquipmentType } from '@app/core/models/equipment/equipments.enum';

@Component({
    selector: 'equipment-type-icon',
    templateUrl: './equipment-type-icon.component.html',
    styleUrls: ['./equipment-type-icon.component.scss']
})
export class EquipmentTypeIconComponent implements OnInit {
    @Input() equipmentType: number;
    @Input() matTooltip: string;

    // Enums
    EquipmentType = EquipmentType;

    constructor() {}

    ngOnInit(): void {}
}
