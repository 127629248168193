<custom-dialog-menu (togglePanel)="onTogglePanel($event)">
  <mat-icon-button-content> <mat-icon>toggle_on</mat-icon> </mat-icon-button-content>

  <dialog-content> <ng-content> </ng-content> </dialog-content>

  <dialog-buttons>
    <div *ngIf="displayButton">
      <ng-container *ngIf="withoutBorder">
        <button
          color="primary"
          mat-flat-button
          class="btn-sm d-block w-100"
          (click)="onToggleAction(true)"
        >
          Activer
        </button>
        <button
          color="primary"
          mat-flat-button
          class="btn-sm d-block w-100"
          (click)="onToggleAction(false)"
        >
          Désactiver
        </button>
      </ng-container>
      <ng-container *ngIf="!withoutBorder">
        <button
          color="primary"
          mat-flat-button
          class="btn-sm d-block w-100"
          (click)="onToggleAction(true)"
        >
          Activer
        </button>
        <button
          color="primary"
          mat-flat-button
          class="btn-sm d-block w-100"
          (click)="onToggleAction(false)"
        >
          Désactiver
        </button>
      </ng-container>
    </div>
  </dialog-buttons>
</custom-dialog-menu>
