import { AppState } from '@app/core/store/state/app-state.interface';
import { PerimeterScope } from '@app/shared/constants/filters.enum';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const getLayoutStoreFeatureState = createFeatureSelector<AppState>('layoutModuleStore');

export const selectPerimeterToggle = createSelector(
    getLayoutStoreFeatureState,
    (appState: AppState) => {
        if (!appState?.layoutModuleState.perimeterScopeState) {
            return PerimeterScope.activeSelectedPerimeter;
        }
        return appState?.layoutModuleState.perimeterScopeState;
    }
);
