<!-- svg icons -->
<ng-container
  *ngIf="
    sensorMeasureId !== SensorMeasureTypeEnum.IndoorHygrometry &&
    sensorMeasureId !== SensorMeasureTypeEnum.Brightness &&
    sensorMeasureId !== SensorMeasureTypeEnum.AirQuality &&
    sensorMeasureId !== SensorMeasureTypeEnum.Pression
  "
>
  <mat-icon
    [ngClass]="classStyle"
    [svgIcon]="
      (sensorMeasureId === SensorMeasureTypeEnum.IndoorTemperature ||
      sensorMeasureId === SensorMeasureTypeEnum.OutdoorTemperature ||
      sensorMeasureId === SensorMeasureTypeEnum.Temperature
        ? 'thermometer'
        : '') ||
      (sensorMeasureId === SensorMeasureTypeEnum.CO2Rate ? 'co2' : '') ||
      (sensorMeasureId === SensorMeasureTypeEnum.Radon ? 'radon' : '') ||
      (sensorMeasureId === SensorMeasureTypeEnum.NO2Rate ? 'no2' : '') ||
      (sensorMeasureId === SensorMeasureTypeEnum.COV ? 'cov' : '')
    "
  ></mat-icon>
</ng-container>

<!-- fa icons -->
<ng-container
  *ngIf="
    sensorMeasureId === SensorMeasureTypeEnum.IndoorHygrometry ||
    sensorMeasureId === SensorMeasureTypeEnum.Brightness ||
    sensorMeasureId === SensorMeasureTypeEnum.AirQuality ||
    sensorMeasureId === SensorMeasureTypeEnum.Pression
  "
>
  <mat-icon
    [ngClass]="{
      'far fa-droplet-percent': sensorMeasureId === SensorMeasureTypeEnum.IndoorHygrometry,
      'fal fa-lightbulb-o': sensorMeasureId === SensorMeasureTypeEnum.Brightness,
      'fal fa-sensor-cloud': sensorMeasureId === SensorMeasureTypeEnum.AirQuality,
      'fal fa-gauge-max': sensorMeasureId === SensorMeasureTypeEnum.Pression
    }"
  ></mat-icon>
</ng-container>
