//#region Imports
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Params, Router } from '@angular/router';

import { MsalService } from '@azure/msal-angular';
import { UserContext } from '@core/models/user/user.context';
import { Subject } from 'rxjs';

import { appRoutes } from '@app/app-root/app-routes.enum';
import { AppService } from '@app/core/http/app.service';
import { environment } from '@environments/environment';
import { takeUntil } from 'rxjs/operators';

//#endregion

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
    /** enregistrement pour desouscrire */
    private _onDestroy = new Subject<void>();
    //#region Constructor
    constructor(
        private router: Router,
        private appService: AppService,
        private authService: MsalService
    ) {}
    //#endregion

    //#region Methods
    ngOnInit(): void {
        if (this.authService.instance.getActiveAccount()) {
            const contractId = localStorage.getItem('idc');

            console.debug('LoginComponent : ngOnInit : contractId = ' + contractId);
            console.debug(
                'LoginComponent : ngOnInit : isLoggedIn = ' +
                    this.authService.instance.getActiveAccount()
            );

            if (!contractId) {
                this.router.navigate([
                    appRoutes.externalRedirect,
                    { externalUrl: environment.extLinks.portal }
                ]);
            } else {
                this.appService
                    .InitApp(contractId)
                    .pipe(takeUntil(this._onDestroy))
                    .subscribe({
                        next: result => {
                            console.debug(
                                'LoginComponent : ngOnInit : UserContext = ' +
                                    JSON.stringify(result)
                            );
                            this.appService.setUserContext(result);
                            this.NavigatTo();
                        },
                        error: () => {
                            this.router.navigate([
                                appRoutes.externalRedirect,
                                { externalUrl: environment.extLinks.portal }
                            ]);
                        }
                    });
            }
        }
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    private NavigatTo(): void {
        const previousUrl = localStorage.getItem('previousUrl');
        const previousUrlParams = localStorage.getItem('previousUrlParams');

        localStorage.removeItem('previousUrl');
        localStorage.removeItem('previousUrlParams');

        this.router.navigate([previousUrl ? previousUrl : appRoutes.default], {
            queryParams: JSON.parse(previousUrlParams) as Params,
            replaceUrl: true
        });
    }
    //#endregion
}
