<form [formGroup]="optinForm" class="profile__formOpt pt-2 pb-3">
  <div class="row mb-3">
    <div class="col-12">
      <h2 class="profile__titlelv2 font-12 m-0">Utilisation des données personnelles</h2>
    </div>
  </div>

  <p class="d-flex">
    <mat-checkbox formControlName="alertsmsCtrl" class="customMatCheckBox">
      J’accepte que mes données (nom, prénom, adresse mail, téléphone) soient utilisées pour
      recevoir des notifications, alertes liées aux fonctionnalités de mes options Vertuoz.
    </mat-checkbox>
  </p>
  <p class="d-flex">
    <mat-checkbox formControlName="newslettersCtrl" class="customMatCheckBox">
      J’accepte de recevoir les newsletters par mail.
    </mat-checkbox>
  </p>

  <div class="text-end">
    <button type="button" mat-stroked-button color="primary" class="btn-sm" (click)="cancelForm()">
      {{ 'common.action.cancel' | translate }}
    </button>
    <button
      mat-flat-button
      color="primary"
      class="btn-sm ms-3"
      [disabled]="optinForm.invalid"
      (click)="saveUserInfos()"
    >
      Enregistrer
    </button>
  </div>
</form>
