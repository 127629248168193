import { Injectable } from '@angular/core';
import { ActivePerimeterService } from '@core/http/perimeter/active-perimeter.service';
import { ToolsService } from '@features/shared/services/tools.service';
import { BehaviorSubject, Observable } from 'rxjs';

import { enumGeographicLevel, LocalStorageService } from '@vertuoz/vertuoz-library';
import { Dictionary } from '../models/common/dictionary.model';

export class PerimeterLevelState {
    id: number;
    count: number;
    enum: enumGeographicLevel;
}

@Injectable({ providedIn: 'root' })
export class GeoSelectionTimeStampService {
    private dataSource: BehaviorSubject<PerimeterLevelState>;
    public data: Observable<PerimeterLevelState>;

    private perimeterLevel: PerimeterLevelState;

    public get perimeterLevelState(): PerimeterLevelState {
        return this.perimeterLevel;
    }

    constructor(
        private localStorageService: LocalStorageService,
        private toolsService: ToolsService,
        private activePerimeterService: ActivePerimeterService
    ) {
        this.setPerimeterLevel();
        this.dataSource = new BehaviorSubject<PerimeterLevelState>(this.perimeterLevel);
        this.data = this.dataSource.asObservable();
    }

    public updatedData(withNext: boolean = true): void {
        const active = this.activePerimeterService.CurentActivePerimeter;
        const keys = new Dictionary<number[]>(active.perimeterItemsIds).Keys;
        // regrouper par domaine
        if (keys && keys.length > 0) {
            const minKey = Math.min(...keys);
            this.toolsService.setPerimeterToLocalStorage(
                minKey,
                active.perimeterItemsIds[minKey].length
            );
        } else {
            this.toolsService.removePerimeterFromLocalStorage();
        }
        this.setPerimeterLevel();
        if (withNext) {
            this.dataSource.next(this.perimeterLevel);
        }
    }

    private setPerimeterLevel(): void {
        this.perimeterLevel = this.localStorageService.getPerimeterLevelSelectedCard();
        if (!this.perimeterLevel || this.perimeterLevel.id == null) {
            this.perimeterLevel = <PerimeterLevelState>{
                enum: enumGeographicLevel.nation,
                id: -3,
                count: 0
            };
        }
    }
}
