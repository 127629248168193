import { PerimeterLevel } from '@app/features/objectives/models/objective.enum';
import { LabelModel } from '@app/features/property-data/models/comparison.model';
import { FluidTypeEnum } from '@app/shared/constants/fluid-type.enum';
import {
    DataControlCategory,
    DataControlConsumptionType,
    DataControlCriticity,
    DataControlObservationPeriod,
    DataControlPeriodicity,
    DataControlRetroactivityPeriod,
    DataControlType,
    DataControlValueType,
    Operators
} from '../enums/data-control.enum';

export class DataControlRuleModel {
    public ruleId?: number;
    public label: string;
    public ruleType: DataControlType;
    public ruleTypeLabel?: string;
    public preConfigured: boolean;
    public activeNotification?: boolean;
    public criticity: DataControlCriticity;
    public criticityLabel?: string;
    public subscribed?: boolean;
    public ruleCategory?: DataControlCategory;
    public ruleCategoryLabel?: string;

    // Sensors
    public sensorMeasureTypeId?: number;
    public sensorMeasureTypeLabel?: string;

    public locations?: DataControlLocationModel[];
    public isAggregated?: boolean;

    public valueType?: DataControlValueType;
    public observationPeriod?: DataControlObservationPeriod;
    public retroactivityPeriod?: DataControlRetroactivityPeriod;
    public conditionPeriodicities?: DataControlConditionPeriodicityModel[];

    public isAggregate?: boolean;
    public consumptionType?: DataControlConsumptionType;
    public consumptionTypeLabel?: string;
    public idListeTypeFluide?: number;
    public idTypeFluide?: number;
    public typeFluideLabel?: string;
    public typeFluid?: FluidTypeEnum;
    public unitId?: number;
    public unitLabel?: string;
    public useId?: number;
    public useLabel?: string;
    public fluidIds?: Array<number>;
    public Fluids?: Array<LabelModel>;
}

export class DataControlRuleTypeModel {
    public category: DataControlCategory;
    public categoryLabel: string;
    public ruleType: DataControlType;
    public ruleTypeKey: string;
    public ruleTypeLabel: string;
    public preConfigured: boolean;
}

export class DataControlConditionPeriodicityModel {
    public label?: string;
    public priority = 0;
    public periodicity: DataControlPeriodicity;
    public isDisabled?: boolean;

    public operatorIs: boolean;
    public operator: Operators;

    public thresholdValue: number;
    public secondThresholdValue?: number;
    public monday?: boolean;
    public tuesday?: boolean;
    public wednesday?: boolean;
    public thursday?: boolean;
    public friday?: boolean;
    public saturday?: boolean;
    public sunday?: boolean;
    public january?: boolean;
    public february?: boolean;
    public march?: boolean;
    public april?: boolean;
    public may?: boolean;
    public june?: boolean;
    public july?: boolean;
    public august?: boolean;
    public september?: boolean;
    public october?: boolean;
    public november?: boolean;
    public december?: boolean;
    public timeSlotStart?: number;
    public timeSlotEnd?: number;
    public startDate?: Date;
    public endDate?: Date;

    public periodicityLabel?: string;
    public thresholdValuesLabel?: string;
}

/** Models for the location of sensors ids */
export class DataControlLocationModel {
    public sensorId?: number;
    public sensorLabel?: string;
    public sensorUnitId?: number;
    public sensorUnit?: string;
    public sensorMeasureTypeId?: number;
    public sensorMeasureTypeLabel?: string;

    public deviceId?: number;
    public deviceLabel?: string;

    public establishmentId?: number;
    public establishmentLabel?: string;

    public buildingId?: number;
    public buildingLabel?: string;

    public zoneId?: number;
    public zoneLabel?: string;

    public groupPerimeterId?: number;
    public groupPerimeterLabel?: string;

    public meteringPointId?: number;
    public meteringPointLabel?: string;
}

/**
 * Model for the perimeter step
 */
export class DataControlPerimeterStep {
    public locations: DataControlLocationModel[];
    public perimeterLevel?: PerimeterLevel; // 1, 2, 3 (etablissement, batiment, zone)
    public fluidType?: FluidTypeEnum; // type de fluide (energie ou eau)
    public fluidId?: number; // id du fluide sélectionné
    public isAggregate?: boolean; // agrégation des consommations des éléments
    public perimeterType?: PerimeterType;
}

export enum PerimeterType {
    perimeter = 1,
    meteringPoint = 2
}

/**
 * Model for consumption characteristic step
 */
export class DataControlCharacteristicStep {
    public consumptionType: DataControlConsumptionType;
    public fluidType?: FluidTypeEnum;
    public useIds?: number;
    public fluidIds?: Array<number>;
    public unitId: number;
}

/**
 * Model for the grid of sensors
 */
export class DataControlSensorLocationsGrid {
    public perimeterLevel: PerimeterLevel;
    public perimeterLevelLabel?: string;
    public perimeterItemId: number;
    public perimeterItemLabel?: string;
    public sensors: LabelModel[];
}

/**
 * Model for the periodicity step
 */
export class DataControlConditionPeriodicityStep {
    public valueType: DataControlValueType;
    public observationPeriod: DataControlObservationPeriod;
    public conditionPeriodicities: DataControlConditionPeriodicityModel[];
    public retroactivityPeriod: DataControlRetroactivityPeriod;
}
