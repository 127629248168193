export enum EnumGeographyRoute {
    path = 'geography',

    // Nations
    listNation = 'nations',
    readNation = 'nation-details',
    createOrUpdateNation = 'nation',

    // Regions
    listRegion = 'regions',
    readRegion = 'region-details',
    createOrUpdateRegion = 'region',

    // Counties
    listCounty = 'counties',
    readCounty = 'county-details',
    createOrUpdateCounty = 'county',

    // Towns
    listTown = 'towns',
    readTown = 'town-details',
    createOrUpdateTown = 'town'
}

export enum EnumGeographyMapParams {
    id = 'id',
    parentType = 'parentType'
}
