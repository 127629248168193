import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BatchUpdateParams } from '@app/core/models/common/batch-update-params.model';
import { PageResult } from '@app/core/models/common/page-result.model';
import { DataControlRuleFilter } from '@app/core/models/data-control/filters/data-control.filter';
import { DataControlRuleModel } from '@app/core/models/data-control/models/data-control-rule.model';
import { EnumController } from '@app/core/services/end-point-extend';
import { PagedContext } from '@app/features/shared/models/paged-context';
import { ToolsService } from '@app/features/shared/services/tools.service';
import { FileExportType } from '@app/shared/constants/export.enum';
import { PerimeterScope } from '@app/shared/constants/filters.enum';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { AppService } from '../app.service';
import { BaseService } from '../base.service';

@Injectable({
    providedIn: 'root'
})
export class DataControlRulesService extends BaseService {
    //#region fields
    private API_DATACONTROL_RULES = `${environment.API_ENDPOINT}${this.version}/${EnumController.datacontrolrules}`;
    //#endregion

    //#region Constructor
    constructor(
        private http: HttpClient,
        private appService: AppService,
        private toolsService: ToolsService
    ) {
        super();
    }
    //#endregion

    //#region Methods

    /// <summary>
    /// Create data control rule
    /// </summary>
    public createRule(model: DataControlRuleModel): Observable<number> {
        const contractId = this.appService.getUserContext().contract.id.toString();
        return this.http.post<number>(
            `${this.API_DATACONTROL_RULES}?contractId=${contractId}`,
            model
        );
    }

    /// <summary>
    /// Batch update single property on rules list
    /// </summary>
    public BatchUpdateRules(batchUpdateParams: BatchUpdateParams): Observable<void> {
        const contractId = this.appService.getUserContext().contract.id.toString();
        return this.http.post<void>(
            `${this.API_DATACONTROL_RULES}/BatchUpdate?contractId=${contractId}`,
            batchUpdateParams
        );
    }

    /// <summary>
    /// Get data control rules
    /// </summary>
    public getRules(
        pagedContext: PagedContext,
        rulesFilter: DataControlRuleFilter,
        perimeterScope: PerimeterScope
    ): Observable<PageResult<DataControlRuleModel>> {
        const contractId = this.appService.getUserContext().contract.id.toString();

        const queryStr = this.toolsService.transformParamsArrayToQueryString(
            pagedContext,
            rulesFilter,
            { perimeterScope: perimeterScope },
            { contractId: contractId }
        );

        return this.http.get<PageResult<DataControlRuleModel>>(
            `${this.API_DATACONTROL_RULES}?${queryStr}`
        );
    }

    /// <summary>
    /// Get data control rule
    /// </summary>
    public getRuleById(id: number): Observable<DataControlRuleModel> {
        const contractId = this.appService.getUserContext().contract.id.toString();

        return this.http.get<DataControlRuleModel>(
            `${this.API_DATACONTROL_RULES}/${id}?contractId=${contractId}`
        );
    }

    /// <summary>
    /// Update data control rule
    /// </summary>
    public updateRule(id: number, model: DataControlRuleModel): Observable<void> {
        const contractId = this.appService.getUserContext().contract.id.toString();
        return this.http.put<void>(
            `${this.API_DATACONTROL_RULES}/${id}?contractId=${contractId}`,
            model
        );
    }

    /// <summary>
    /// Delete data control rules
    /// </summary>
    public deleteRules(ids: Array<number>): Observable<void> {
        const contractId = this.appService.getUserContext().contract.id.toString();
        const queryStrIds = this.toolsService.transformParamsToQueryString({ ids });

        return this.http.delete<void>(
            `${this.API_DATACONTROL_RULES}?${queryStrIds}&contractId=${contractId}`
        );
    }

    /// <summary>
    /// Delete data control rule
    /// </summary>
    public deleteRule(id: number): Observable<void> {
        const contractId = this.appService.getUserContext().contract.id.toString();

        return this.http.delete<void>(
            `${this.API_DATACONTROL_RULES}/${id}?contractId=${contractId}`
        );
    }

    public GetFileDatas(
        fileType: FileExportType,
        rulesFilter: DataControlRuleFilter,
        perimeterScope: PerimeterScope
    ): Observable<HttpResponse<Object>> {
        const contractId = this.appService.getUserContext().contract.id.toString();
        const queryStr = this.toolsService.transformParamsArrayToQueryString(
            { contractId: contractId },
            rulesFilter,
            { perimeterScope: perimeterScope },
            { fileType: fileType }
        );
        return this.http.get(`${this.API_DATACONTROL_RULES}/FileDatas?${queryStr}`, {
            responseType: 'blob' as 'json',
            observe: 'response'
        });
    }

    public labelExists(label: string, id?: number): Observable<boolean> {
        const contractId = this.appService.getUserContext().contract.id.toString();

        const queryStr = this.toolsService.transformParamsArrayToQueryString(
            { contractId: contractId },
            { label: label },
            { dataControlRuleIdToExclude: id }
        );

        return this.http.get<boolean>(`${this.API_DATACONTROL_RULES}/LabelExists?${queryStr}`);
    }

    //#endregion
}
