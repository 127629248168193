<div [formGroup]="chartFiltersForm">
  <ng-container *ngIf="!hideEquipments && equipmentList">
    <mat-form-field class="w-100 mb-3">
      <mat-select
        placeholder="Equipements"
        formControlName="equipments"
        (openedChange)="onEquipmentOpenChange($event)"
        multiple
      >
        <mat-select-trigger> {{ selectedEquipmentsLabel }} </mat-select-trigger>
        <mat-option
          class="mat-option-equipment"
          *ngFor="let equipment of equipmentList"
          [value]="equipment"
        >
          <strong>{{ equipment.equipmentLabel }}</strong> -
          <span class="content-label-equipment">
            <ng-container
              *ngIf="
                equipment.equipmentInputMeteringPoints &&
                equipment.equipmentInputMeteringPoints.length > 0
              "
            >
              Source : {{ getMeteringPointLabel(equipment.equipmentInputMeteringPoints) }}
            </ng-container>
            <ng-container
              *ngIf="equipment.equipmentOutputs && equipment.equipmentOutputs.length > 0"
            >
              Sortie : {{ getMeteringPointLabel(equipment.equipmentOutputs) }}
            </ng-container>
          </span>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="!hideComparisonYear">
    <mat-form-field class="w-100 mb-3">
      <mat-select
        placeholder="Comparaison"
        formControlName="comparisonYear"
        (selectionChange)="onComparisonSelectionChange($event)"
      >
        <mat-option>Aucun</mat-option>
        <mat-option *ngFor="let year of comparisonYears" [value]="year">
          {{ getComparisonYearLabel(year) | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>

  <ng-container
    *ngIf="
      !hideComparisonYear &&
      comparisonYears &&
      comparisonYears.length > 0 &&
      selectedComparison === 4
    "
  >
    <mat-form-field class="w-100 mb-3">
      <mat-select
        placeholder="Année de référence"
        formControlName="referenceYear"
        (selectionChange)="onSelectionChange()"
      >
        <mat-option *ngFor="let refYear of referenceYears" [value]="refYear">
          {{ refYear }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="!hideSens && sensList">
    <mat-form-field class="w-100 mb-3">
      <mat-select
        placeholder="Sens"
        formControlName="sens"
        (openedChange)="onOpenChange($event)"
        multiple
        #sensMatSelect
      >
        <mat-option *ngFor="let s of sensList" [value]="s"> {{ s.label | translate }} </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>

  <ng-container
    *ngIf="!hideFluidUse && (fluidUse === FluidUseEnum.Fluid || fluidUse === FluidUseEnum.Use)"
  >
    <mat-form-field class="w-100 mb-3">
      <mat-select
        placeholder="{{
          (fluidUse === FluidUseEnum.Fluid
            ? overridenFluid
              ? overridenFluid
              : 'Fluides'
            : 'Usages'
          ) | translate
        }}"
        formControlName="fluids"
        [compareWith]="compareFnValue"
        [disabled]="fluidUseLst == null || fluidUseLst.length === 0"
        multiple
        #fluidsMatSelect
      >
        <mat-select-trigger> {{ selectedFluidsLabel | translate }} </mat-select-trigger>
        <mat-option [value]="allIndex" (click)="onToggleAllSelection(fluidsMatSelect)">{{
          (fluidUse === FluidUseEnum.Fluid
            ? overridenAllFluids
              ? overridenAllFluids
              : 'fluid.all'
            : 'usages.all'
          ) | translate
        }}</mat-option>
        <mat-option
          *ngFor="let fu of fluidUseLst"
          [value]="fu.value"
          (click)="onMultipleSelectionChange(fluidsMatSelect)"
        >
          {{ fu.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="!hideFluidProdLst && fluidUse === FluidUseEnum.Fluid">
    <mat-form-field class="w-100 mb-3">
      <mat-select
        placeholder="{{ fluidUse === FluidUseEnum.Fluid ? 'Fluides en production' : 'Usages' }}"
        formControlName="fluidProd"
        [compareWith]="compareFnValue"
        [disabled]="fluidProdLst == null || fluidProdLst.length === 0"
        multiple
        #fluidsProdMatSelect
      >
        <mat-select-trigger> {{ selectedFluidsProdLabel | translate }} </mat-select-trigger>
        <mat-option [value]="allIndex" (click)="onToggleAllSelection(fluidsProdMatSelect)">{{
          (fluidUse === FluidUseEnum.Fluid ? 'fluid.all' : 'usages.all') | translate
        }}</mat-option>
        <mat-option
          *ngFor="let fu of fluidProdLst"
          [value]="fu.value"
          (click)="onMultipleSelectionChange(fluidsProdMatSelect)"
        >
          {{ fu.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>

  <ng-container
    *ngIf="
      !hideFluidUseConsLst && (fluidUse === FluidUseEnum.Fluid || fluidUse === FluidUseEnum.Use)
    "
  >
    <mat-form-field class="w-100 mb-3">
      <mat-select
        placeholder="{{ fluidUse === FluidUseEnum.Fluid ? 'Fluides en consommation' : 'Usages' }}"
        formControlName="fluidUsageCons"
        [compareWith]="compareFnValue"
        [disabled]="fluidUsageConsLst == null || fluidUsageConsLst.length === 0"
        multiple
        #fluidsConsMatSelect
      >
        <mat-select-trigger> {{ selectedFluidsConsLabel | translate }} </mat-select-trigger>
        <mat-option [value]="allIndex" (click)="onToggleAllSelection(fluidsConsMatSelect)">{{
          (fluidUse === FluidUseEnum.Fluid ? 'fluid.all' : 'usages.all') | translate
        }}</mat-option>
        <mat-option
          *ngFor="let fu of fluidUsageConsLst"
          [value]="fu.value"
          (click)="onMultipleSelectionChange(fluidsConsMatSelect)"
        >
          {{ fu.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="!hideActivitySectors && activitySectors && activitySectors.length > 0">
    <mat-form-field class="w-100 mb-3">
      <mat-select
        placeholder="Secteur d'activité"
        formControlName="activitySector"
        (selectionChange)="onSelectionChange()"
      >
        <mat-option>{{ 'sector.all' | translate }}</mat-option>
        <mat-option *ngFor="let sector of activitySectors" [value]="sector.id">
          {{ sector.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="!hideCustomSectors && customSectors && customSectors.length > 0">
    <mat-form-field class="w-100 mb-3">
      <mat-select
        placeholder="Secteur personnalisé"
        formControlName="customSectorIds"
        multiple
        #customSectorSelect
      >
        <mat-select-trigger> {{ selectedCustomSectorsLabel }} </mat-select-trigger>
        <mat-option
          [value]="allCustomSectors.id"
          (click)="onToggleAllSelection(customSectorSelect)"
          >{{ allCustomSectors.label }}</mat-option
        >
        <mat-option
          *ngFor="let sector of customSectors"
          [value]="sector.id"
          (click)="onMultipleSelectionChange(customSectorSelect)"
        >
          {{ sector.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="!hideProductionType && productionTypeList">
    <mat-form-field class="w-100 mb-3">
      <mat-select
        placeholder="{{ 'aggregate-production.production-type' | translate }}"
        formControlName="productionType"
        (openedChange)="onOpenChange($event)"
        multiple
        #productionTypeMatSelect
      >
        <mat-option *ngFor="let p of productionTypeList" [value]="p">
          {{ p.label | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="!hideProductionUse && productionUseList">
    <mat-form-field class="w-100 mb-3">
      <mat-select
        placeholder="{{ 'aggregate-production.production-use' | translate }}"
        formControlName="productionUse"
        (openedChange)="onOpenChange($event)"
        multiple
        #productionUseMatSelect
      >
        <mat-option *ngFor="let p of productionUseList" [value]="p">
          {{ p.label | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>
</div>
