import { environment } from '@environments/environment';

export enum EnumController {
    Nations = 'Nations',
    Regions = 'Regions',
    Counties = 'Counties',
    town = 'town',
    Towns = 'Towns',
    establishment = 'establishment',
    Establishments = 'Establishments',
    building = 'building',
    Buildings = 'Buildings',
    zone = 'zone',
    zones = 'zones',
    fluid = 'fluid',
    unit = 'unit',
    lists = 'lists',
    meteringPoint = 'meteringPoint',
    meteringPoints = 'meteringPoints',
    meteringPointsOnSupplierContractsOverlapped = 'meteringPointsOnSupplierContractsOverlapped',
    meter = 'meter',
    dial = 'dial',
    usages = 'Usages',
    weather = 'weather',
    activitySector = 'activitySector',
    activitySectors = 'activitySectors',
    customizedSector = 'CustomSector',
    activityCategories = 'ActivityCategories',
    validator = 'propertyValidator',
    init = 'init',
    tarif = 'tarif',
    segment = 'segment',
    initImportScenarios = 'InitImportScenarios',
    Alert = 'Alert',
    reading = 'reading',

    invoice = 'invoice',
    invoices = 'invoices',
    filetoimports = 'filetoimports',

    datacontrols = 'datacontrols',
    datacontrolrules = 'datacontrolrules',
    datacontrolsubscriptions = 'datacontrolsubscriptions',
    datacontrolnotifications = 'datacontrolnotifications',

    EnergyMix = 'EnergyMix',
    EnergyLabel = 'EnergyLabel',
    sources = 'sources',
    EnergyFluids = 'EnergyFluids',
    Device = 'Device',
    Devices = 'Devices',
    Sensor = 'Sensor',
    Sensors = 'Sensors',
    Properties = 'Properties',
    Perimeters = 'Perimeters',
    CustomSectors = 'CustomSectors',

    districts = 'districts',
    streets = 'streets',
    cabinets = 'cabinets',

    objectives = 'objectives',
    groups = 'groups',

    ActivePerimeters = 'ActivePerimeters',

    vehicleUse = 'VehicleUse',
    vehicles = 'Vehicles',
    vehicleInvoices = 'VehicleInvoices',
    vehicle = 'Vehicle',
    file = 'files',
    linkedFile = 'linkedFiles',

    operatExportHistory = 'OperatExportHistory'
}

/**
 * Peut être utilisée comme classe mère des services faisant un appel au API
 */
export class EndPointExtend {
    protected API_ENDPOINT: string = environment.API_ENDPOINT;

    protected path_fluid_API = `${this.API_ENDPOINT}${EnumController.fluid}`;
    protected path_town_API = `${this.API_ENDPOINT}${EnumController.town}`;
    protected path_establishment_API = `${this.API_ENDPOINT}${EnumController.establishment}`;
    protected path_zone_API = `${this.API_ENDPOINT}${EnumController.zone}`;
    protected path_unit_API = `${this.API_ENDPOINT}${EnumController.unit}`;
    protected path_building_API = `${this.API_ENDPOINT}${EnumController.building}`;
    protected path_lists_API = `${this.API_ENDPOINT}${EnumController.lists}`;
    protected path_meteringPoint_API = `${this.API_ENDPOINT}${EnumController.meteringPoint}`;
    protected path_meter_API = `${this.API_ENDPOINT}${EnumController.meter}`;
    protected path_dial_API = `${this.API_ENDPOINT}${EnumController.dial}`;
    protected path_color_API = `${this.API_ENDPOINT}${EnumController.usages}`;
    protected path_weather_API = `${this.API_ENDPOINT}${EnumController.weather}`;
    protected path_activitySector_API = `${this.API_ENDPOINT}${EnumController.activitySector}`;
    protected path_customizedSector_API = `${this.API_ENDPOINT}${EnumController.customizedSector}`;
    protected path_activityCategories_API = `${this.API_ENDPOINT}v2.3/${EnumController.activityCategories}`;
    protected path_validatorLabel_API = `${this.API_ENDPOINT}${EnumController.validator}`;
    protected path_init_API = `${this.API_ENDPOINT}${EnumController.init}`;
    protected path_tarif_API = `${this.API_ENDPOINT}${EnumController.tarif}`;
    protected path_segment_API = `${this.API_ENDPOINT}${EnumController.segment}`;

    protected path_districts_API = `${this.API_ENDPOINT}${EnumController.districts}`;
    protected path_streets_API = `${this.API_ENDPOINT}${EnumController.streets}`;
    protected path_cabinets_API = `${this.API_ENDPOINT}${EnumController.cabinets}`;

    protected path_objective_API = `${this.API_ENDPOINT}${EnumController.objectives}`;

    constructor() {}
}
