<button class="btnComponentFilter" (click)="toggleDialog()">
  <ng-container *ngIf="!hasAllMembersChecked()">
    {{ filterNumber() }} FILTRE<span *ngIf="filterNumber() >= 2">S</span>
    <button mat-icon-button (click)="reinitCheck()" matTooltip="Effacer les filtres">
      <mat-icon>close</mat-icon>
    </button>
  </ng-container>
  <ng-container *ngIf="hasAllMembersChecked()">FILTRER</ng-container>
</button>
<div
  class="delete-dialog-container mat-elevation-z6"
  *ngIf="showDialog"
  (clickOutside)="closePanel()"
  [exclude]="'.btnComponentFilter'"
>
  <div *ngIf="filterSettings" class="row px-4">
    <div *ngFor="let filterType of filterSettings; let i = index" class="col-6">
      <h2 class="font-12 color-primary-700 pt-3">{{ filterType.filterName }}</h2>
      <div *ngIf="filterSettings" class="row px-4">
        <div class="scrollbar scrollbar-primary">
          <div class="example-viewport">
            <mat-checkbox
              [checked]="hasAllMembersChecked(filterType.filterId)"
              value="filterType.filterName"
              (change)="onAllFiltersSelected($event.checked, filterType.filterId)"
            >
              Tous les {{ filterType.filterName }}s
            </mat-checkbox>
            <div *ngFor="let value of filterType.filterValues">
              <mat-checkbox
                [checked]="value.selected"
                value="value.id"
                (change)="onFilterSelected(value)"
              >
                {{ value.label }}
              </mat-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
