/**
 * Enum représentant les routes des points de comptage
 */
export enum EnumMeteringPointRoute {
    path = 'metering-point',
    updateMeteringPoint = 'update-metering-point',
    createMeteringPoint = 'create-metering-point',
    updateMeter = 'update-meter',
    createMeter = 'create-meter',
    updateDial = 'update-dial',
    createDial = 'create-dial',
    listMainView = 'main-list',
    listCharacteristicView = 'characteristics-list',
    listFatherSonView = 'father-son-list',
    listMeterDialView = 'meters-dials-list',
    listSensorView = 'sensors-list',
    displayDial = 'display-dial',
    displayMeter = 'display-meter',
    displayMeteringPoint = 'display-metering-point'
}

export enum InvoiceRoute {
    path = 'invoices',
    display = 'display'
}
