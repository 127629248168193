// #region Imports
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
    Component,
    HostBinding,
    HostListener,
    Inject,
    LOCALE_ID,
    OnDestroy,
    OnInit
} from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';

import {
    MsalBroadcastService,
    MsalGuardConfiguration,
    MsalService,
    MSAL_GUARD_CONFIG
} from '@azure/msal-angular';

import {
    AuthenticationResult,
    AuthError,
    EventMessage,
    EventType,
    InteractionStatus,
    InteractionType,
    PopupRequest,
    RedirectRequest
} from '@azure/msal-browser';

import { I18nService } from '@core/internationalization/i18n.service';
import { MenuService } from '@core/menu/menu.service';
import { IconService } from '@core/services/svg-icons.service';
import { ThemesService } from '@core/services/themes.service';
import { SettingsService } from '@core/settings/settings.service';
import { environment } from '@environments/environment';
import { headerMenu, hubMenu } from '@features/menu';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AppService } from '@app/core/http/app.service';

//#endregion
declare let ga: Function;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare var $: any;
declare var dT_; // Declaration only needed in TypeScript. Remove this line if you are using JavaScript

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    //#region Properties
    @HostBinding('class.layout-fixed') get isLayoutFixed(): string | boolean {
        return this.settings.getLayoutSetting('isLayoutFixed');
    }
    @HostBinding('class.aside-collapsed') get isSidebarCollapsed(): string | boolean {
        return this.settings.getLayoutSetting('isSidebarCollapsed');
    }
    @HostBinding('class.layout-boxed') get isLayoutBoxed(): string | boolean {
        return this.settings.getLayoutSetting('isLayoutBoxed');
    }
    @HostBinding('class.hidden-footer') get isFooterHidden(): string | boolean {
        return this.settings.getLayoutSetting('isFooterHidden');
    }
    @HostBinding('class.aside-float') get isSidebarFloat(): string | boolean {
        return this.settings.getLayoutSetting('isSidebarFloat');
    }
    @HostBinding('class.aside-toggled') get isAsideToggled(): string | boolean {
        return this.settings.getLayoutSetting('isAsideToggled');
    }
    @HostBinding('class.aside-collapsed-text') get isSidebarCollapsedText(): string | boolean {
        return this.settings.getLayoutSetting('isSidebarCollapsedText');
    }
    @HostBinding('class.hidden-sidebar') get isSidebarHidden(): string | boolean {
        return this.settings.getLayoutSetting('isSidebarHidden');
    }
    @HostBinding('class.full-content') get isContentFull(): string | boolean {
        return this.settings.getLayoutSetting('isContentFull');
    }
    @HostListener('window:visibilitychange', ['$event']) beforeUnloadHandler(event): void {
        if (this.appService.trackingIsEnable) {
            this.isVisible = !this.isVisible;
            const currentPage = this.isVisible ? this.router.url : 'not-visible';
            this.appService.trackingUserExperience(currentPage, new Date());
        }
    }

    isVisible = true;
    loginDisplay = false;
    private readonly _destroying$ = new Subject<void>();
    //#endregion

    //#region Constructor
    constructor(
        private meta: Meta,
        private httpHttp: HttpClient,
        private authService: MsalService,
        private appService: AppService,
        private readonly msalBroadcastService: MsalBroadcastService,
        private settings: SettingsService,
        private menu: MenuService,
        public themes: ThemesService,
        private iconService: IconService,
        @Inject(LOCALE_ID) public localID: string,
        public i18nService: I18nService,
        private router: Router,
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration
    ) {
        this.router.events.pipe(takeUntil(this._destroying$)).subscribe(event => {
            if (event instanceof NavigationStart) {
                // en arrivant sur la page courante, on track l'info
                this.appService.trackingUserExperience(event.url, new Date());
                // this.appService.estimateTrackingSubject.next();
            }
        });
        if (typeof dT_ !== 'undefined' && dT_.initAngularNg) {
            dT_.initAngularNg(this.httpHttp, HttpHeaders);
        }

        this.menu.addMenu(headerMenu, 'header');
        this.menu.addMenu(hubMenu, 'hub');

        // à vérifier avec les PPOs, langue par défaut
        this.i18nService.init('fr-FR');
    }
    //#endregion

    //#region Methods
    ngOnInit(): void {
        if (!environment.isLocal) {
            const keywords = this.meta.getTag('name=csp');
            this.meta.removeTagElement(keywords);
        }

        this.setLoginDisplay();

        this.msalBroadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None),
                takeUntil(this._destroying$)
            )
            .subscribe(() => {
                this.setLoginDisplay();
                this.checkAndSetActiveAccount();
            });

        this.msalBroadcastService.msalSubject$
            .pipe(
                filter(
                    (msg: EventMessage) =>
                        msg.eventType === EventType.LOGIN_SUCCESS ||
                        msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
                ),
                takeUntil(this._destroying$)
            )
            .subscribe((result: EventMessage) => {
                console.debug('SUCCESS MSAL : ', result);
                /**
                 * For the purpose of setting an active account for UI update, we want to consider only the auth response resulting
                 * from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy policies may use "acr" instead of "tfp").
                 * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
                 */

                return result;
            });

        this.msalBroadcastService.msalSubject$
            .pipe(
                filter(
                    (msg: EventMessage) =>
                        msg.eventType === EventType.LOGIN_FAILURE ||
                        msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE
                ),
                takeUntil(this._destroying$)
            )
            .subscribe((result: EventMessage) => {
                console.debug('FAIL MSAL : ', result);
                if (result.error instanceof AuthError) {
                    // Check for forgot password error
                    // Learn more about AAD error codes at https://docs.microsoft.com/azure/active-directory/develop/reference-aadsts-error-codes
                    if (result.error.message.includes('AADB2C90118')) {
                        // login request with reset authority
                        const resetPasswordFlowRequest = {
                            scopes: ['openid'],
                            authority: environment.authConfig.authority_forgotPassword
                        };

                        this.login(resetPasswordFlowRequest);
                    } else if (result.error.errorCode === 'no_tokens_found') {
                        this.logout();
                    }
                }
            });

        // this.appInsights.loadAppInsights();
        // this.initMonitoringPageLoad();
        const params = new URLSearchParams(window.location.search);
        $(document).on('click', '[href="#"]', e => e.preventDefault());

        $(document).on('keypress', ':input[type="number"]', (e: KeyboardEvent) => {
            // add other invalid chars if needed here
            const invalidChars = ['e', 'E'];
            if (invalidChars.includes(e.key)) {
                e.preventDefault();
            }
        });

        this.setOrigin(params);
        this.setContractId(params);

        this.themes.setTheme(this.settings.layout.defaultTheme);

        this.iconService.registerIcons();
    }

    private setContractId(params: URLSearchParams): void {
        const idc = params.get('idc');
        if (idc) {
            localStorage.setItem('idc', params.get('idc'));
        }
    }

    private setOrigin(params: URLSearchParams): void {
        const origin = params.get('origin');
        if (origin) {
            localStorage.setItem('origin', origin);
        }
    }

    ngOnDestroy(): void {
        this._destroying$.next(null);
        this._destroying$.complete();
        localStorage.clear();
    }

    setLoginDisplay(): void {
        this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    }

    checkAndSetActiveAccount(): void {
        /**
         * If no active account set but there are accounts signed in, sets first account to active account
         * To use active account set here, subscribe to inProgress$ first in your component
         * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
         */
        const activeAccount = this.authService.instance.getActiveAccount();

        if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
            const accounts = this.authService.instance.getAllAccounts();
            this.authService.instance.setActiveAccount(accounts[0]);
        }
    }

    login(userFlowRequest?: RedirectRequest | PopupRequest): void {
        if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
            if (this.msalGuardConfig.authRequest) {
                this.authService
                    .loginPopup({
                        ...this.msalGuardConfig.authRequest,
                        ...userFlowRequest
                    } as PopupRequest)
                    .subscribe((response: AuthenticationResult) => {
                        this.authService.instance.setActiveAccount(response.account);
                    });
            } else {
                this.authService
                    .loginPopup(userFlowRequest)
                    .subscribe((response: AuthenticationResult) => {
                        this.authService.instance.setActiveAccount(response.account);
                    });
            }
        } else {
            if (this.msalGuardConfig.authRequest) {
                this.authService.loginRedirect({
                    ...this.msalGuardConfig.authRequest,
                    ...userFlowRequest
                } as RedirectRequest);
            } else {
                this.authService.loginRedirect(userFlowRequest);
            }
        }
    }

    logout(): void {
        if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
            this.authService.logoutPopup({
                mainWindowRedirectUri: '/'
            });
        } else {
            this.authService.logoutRedirect();
        }
    }
    //#endregion
}
