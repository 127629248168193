import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'widget-base',
    templateUrl: './widget-base.component.html',
    styleUrls: ['./widget-base.component.scss']
})
export class WidgetBaseComponent implements OnInit {
    @Input() title = '';
    constructor() {}

    ngOnInit() {}
}
