export enum EnumPermissions {
    MAP_ACCESS = 'map_access',
    WIDGETS_MANAGEMENT = 'widgets_settings',

    CONSUMPTION_MONITORING_ACCESS = 'consumption_access',
    CONSUMPTION_MONITORING_BY_SENSOR_ACCESS = 'consumption_sensor_access',
    LOAD_CURVE_ACCESS = 'load_curve_access',

    SITES_COMPARISON_ACCESS = 'sitecomparison_access',
    CLOUD_POINT_ACCESS = 'cloudpoint_access',

    PROPERTY_ACCESS = 'property_access',
    PROPERTY_COUNTRY_CREATE = 'property_country_create',
    PROPERTY_COUNTRY_DELETE = 'property_country_delete',
    PROPERTY_REGION_CREATE = 'property_region_create',
    PROPERTY_REGION_DELETE = 'property_region_delete',
    PROPERTY_COUNTY_CREATE = 'property_county_create',
    PROPERTY_COUNTY_DELETE = 'property_county_delete',
    PROPERTY_CITY_CREATE = 'property_city_create',
    PROPERTY_CITY_DELETE = 'property_city_delete',
    GEOGRAPHY_PARENT_ATTACHMENT = 'geography_attachment',

    PROPERTY_ESTABLISHMENT_CREATE = 'property_settlement_create',
    PROPERTY_ESTABLISHMENT_DELETE = 'property_settlement_delete',
    PROPERTY_BUILDING_CREATE = 'property_building_create',
    PROPERTY_BUILDING_DELETE = 'property_building_delete',
    PROPERTY_BUILDING_IMPORT_FILE = 'property_building_import_file', //
    PROPERTY_AREA_CREATE = 'property_area_create',
    PROPERTY_AREA_DELETE = 'property_area_delete',
    PROPERTY_PARENT_ATTACHMENT = 'property_attachment',

    PUBLICSPACE_ACCESS = 'public_space_access',
    PUBLICSPACE_DISTRICT_CREATE = 'district_create',
    PUBLICSPACE_DISTRICT_DELETE = 'district_delete',
    PUBLICSPACE_STREET_CREATE = 'street_create',
    PUBLICSPACE_STREET_DELETE = 'street_delete',
    PUBLICSPACE_CABINET_CREATE = 'cabinet_create',
    PUBLICSPACE_CABINET_DELETE = 'cabinet_delete',
    PUBLICSPACE_PARENT_ATTACHMENT = 'public_space_attachment',

    METERINGPLAN_ACCESS = 'counting_points_access',
    METERINGPLAN_POINTS_CREATE = 'counting_points_create',
    METERINGPLAN_POINTS_DELETE = 'counting_points_delete',
    METERINGPLAN_IMPORT_FILE = 'counting_points_import_file',
    METERINGPLAN_POINTS_IMPORT_FILE = 'metering_points_import_file',

    INVOICES_ACCESS = 'bills_access',
    INVOICES_CREATE = 'bills_create',
    INVOICES_DELETE = 'bills_delete',
    INVOICES_IMPORT_FILE = 'bills_import_file',
    INVOICES_IMPORT_AUTO = 'bills_import_auto',
    INVOICES_IMPORT_PDF = 'bills_import_ocr',
    INVOICES_IMPORT_HISTORIC_ACCESS = 'bills_histo',
    INVOICE_IMPORT_PDF_FLAG = 'bills_import_pdf_flag',

    GROUP_CREATE = 'group_create',
    GROUP_ACCESS = 'group_access',
    GROUP_DELETE = 'group_delete',

    READINGS_ACCESS = 'reports_access',
    READINGS_CREATE = 'reports_create',
    READINGS_DELETE = 'reports_delete',
    READINGS_IMPORT_FILE = 'reports_by_files',
    READINGS_HISTORIC_ACCESS = 'reports_histo',
    DELETE_HISTORIC_ACCESS = 'reports_histo_delete',

    OVERVIEW_ACCESS = 'state_access',

    IMPORT_SCENARIO_ACCESS = 'import_scenarios_access',
    DATA_IMPORT_FILE = 'import_data_access',

    REPORTS_PBI_ACCESS = 'reports_pbi_access',

    STARTUP_HELPER_ACCESS = 'startup_helper_access',
    STARTUP_HELPER_DELETEALL = 'startup_helper_deleteall',

    USERS_ACCESS = 'users_access',
    USERS_CREATE = 'users_create',
    USERS_DELETE = 'users_delete',

    ROLES_ACCESS = 'roles_access',
    ROLES_CREATE = 'roles_create',
    ROLES_DELETE = 'roles_delete',

    USES_ACCESS = 'uses_access',
    USES_CREATE = 'uses_create',
    USES_EDIT_ADD_REMOVE = 'uses_edit_add_remove',

    OBJECTIVES_ACCESS = 'objectives_access',
    OBJECTIVES_CREATE = 'objectives_create',
    OBJECTIVES_DELETE = 'objectives_delete',

    RESTITUTION_OBJECTIVES_ACCESS = 'restitution_objectives_access',

    PERSONALIZED_SECTOR_ACCESS = 'personalized_sector_access',
    PERSONALIZED_SECTOR_CREATE = 'personalized_sector_create',
    PERSONALIZED_SECTOR_DELETE = 'personalized_sector_delete',

    ACTIVITY_SECTOR_ACCESS = 'activity_sector_access',
    ACTIVITY_SECTOR_CREATE = 'activity_sector_create',
    ACTIVITY_SECTOR_DELETE = 'activity_sector_delete',

    LABELS_ACCESS = 'labels_access',

    DEVICE_ACCESS = 'device_access',
    DEVICE_CREATE = 'device_create',
    DEVICE_DELETE = 'device_delete',

    EQUIPMENTS_ACCESS = 'equipment_access',
    EQUIPMENTS_CREATE = 'equipment_write',
    EQUIPMENTS_DELETE = 'equipment_delete',

    ENERGY_SUPPLIER_CONTRACTS_DELETE = 'energy_supplier_contracts_delete',
    ENERGY_SUPPLIER_CONTRACTS_CREATE_UPDATE = 'energy_supplier_contracts_write',
    ENERGY_SUPPLIER_CONTRACTS_ACCESS = 'energy_supplier_contracts_read',

    CONTROL_RULES_ACCESS = 'control_rules_access',
    CONTROL_RULES_CREATE = 'control_rules_create',
    CONTROL_RULES_DELETE = 'control_rules_delete',

    VEHICLE_ACCESS = 'vehicle_read',
    VEHICLE_CREATE = 'vehicle_write',
    VEHICLE_DELETE = 'vehicle_delete',

    USAGE_ZONE_DELETE = 'zone_usage_delete',
    USAGE_ZONE_CREATE = 'zone_usage_create',
    USAGE_ZONE_ACCESS = 'zone_usage_access'
}
