<div class="profile font-14">
  <header class="profile__pageHeader py-3"><h1 class="profile__title px-2">Profil</h1></header>

  <mat-divider *ngIf="!enableUpdatingInfo"></mat-divider>
  <vertuoz-loader [showLoader]="isLoading" [size]="'tall'">
    <form [formGroup]="myAccountForm">
      <section class="profile__persoInfos pb-3 px-2" *ngIf="!enableUpdatingInfo">
        <div class="row mb-2 pt-3">
          <div class="col-8">
            <h2 class="profile__titlelv2 font-12 m-0">Informations personnelles</h2>
          </div>
          <div class="col-4 text-end">
            <button
              (click)="enableUpdatingInfo = !enableUpdatingInfo"
              mat-flat-button
              color="primary"
              class="btn-sm"
            >
              modifier
            </button>
          </div>
        </div>

        <ng-container *ngIf="!enableUpdatingInfo">
          <div class="d-flex">
            <div class="me-3">
              <strong
                class="profile__initials d-inline-block bg-primary-700 text-center rounded-circle"
                >{{ getUserInitials() }}</strong
              >
            </div>
            <ul class="profile__infosList">
              <li class="d-flex mb-2">
                <div class="profile__infos">
                  <p class="m-0">
                    <strong>
                      >{{ getCivilityLbl() }} {{ currentUser.firstName }}
                      {{ currentUser.familyName }}</strong
                    >
                  </p>
                  <p class="profile__admin m-0">{{ getUserRoleName() }}</p>
                </div>
              </li>
              <li class="mb-2">
                <mat-icon class="me-2">call</mat-icon>
                {{ currentUser.phoneNumber ? currentUser.phoneNumber : 'Aucun numéro renseigné' }}
              </li>
              <li class="mb-2">
                <mat-icon class="me-2">work </mat-icon> {{ currentUser.function }}
              </li>
              <li class="mb-2">
                <mat-icon class="me-2">account_balance</mat-icon>
                <span class="m-0">{{ currentUser.organization }}</span>
                <span class="m-0">{{ getSectorName() }}</span>
              </li>
            </ul>
          </div>
        </ng-container>
      </section>

      <mat-divider></mat-divider>

      <personnal-informations
        *ngIf="enableUpdatingInfo"
        [(currentUser)]="currentUser"
        (cancel)="disableUpdating('info')"
        (saveInfos)="updateProfile('info')"
      >
      </personnal-informations>

      <mat-divider *ngIf="!enableUpdatingInfo"></mat-divider>

      <section class="profile__email pt-3 px-2">
        <!-- Modification du mail plus d'actualité pour le moment - à reactiver ou pas une fois la décision prise -->
        <!-- <label (click)="enableUpdatingEmail = !enableUpdatingEmail">modifier</label> -->
        <h2 class="profile__titlelv2 font-12">Email / Login</h2>
        <p>{{ currentUser.login }}</p>
      </section>

      <mat-divider></mat-divider>

      <section class="profile__persoDatas py-3 px-2">
        <div class="row mb-2" *ngIf="!enableUpdatingOptin">
          <div class="col-8 align-self-center">
            <h2 class="profile__titlelv2 font-12 m-0">Utilisation des données personnelles</h2>
          </div>
          <div class="col-4 text-end">
            <button
              (click)="enableUpdatingOptin = !enableUpdatingOptin"
              mat-flat-button
              color="primary"
              class="btn-sm"
            >
              modifier
            </button>
          </div>
        </div>

        <optin-settings
          *ngIf="enableUpdatingOptin"
          [(currentUser)]="currentUser"
          (cancel)="disableUpdating('option')"
          (saveInfos)="updateProfile('option')"
        >
        </optin-settings>

        <ng-container *ngIf="!enableUpdatingOptin">
          <p class="d-flex">
            <mat-icon class="icon-valid me-2">done</mat-icon>
            <span
              >J’accepte que mes données (nom, prénom, adresse mail, téléphone) servent à gérer mon
              compte Vertuoz Tableau de Bord (identification, authentification, accès à la
              plate-forme)</span
            >
          </p>
          <p class="d-flex">
            <mat-icon
              class="material-icons me-2"
              [ngClass]="{
                'color-success': currentUser.alertBySms,
                'color-error': !currentUser.alertBySms
              }"
            >
              {{ currentUser.alertBySms ? 'done' : 'clear' }}
            </mat-icon>
            <span
              >J’accepte que mes données (nom, prénom, adresse mail, téléphone) soient utilisées
              pour recevoir des notifications, alertes liées aux fonctionnalités de mes options
              Vertuoz</span
            >
          </p>
          <p class="d-flex">
            <mat-icon
              class="material-icons me-2"
              [ngClass]="{
                'color-success': currentUser.newslettersAccepted,
                'color-error': !currentUser.newslettersAccepted
              }"
            >
              {{ currentUser.newslettersAccepted ? 'done' : 'clear' }}
            </mat-icon>
            <span
              >J’accepte de recevoir les newsletters et autres communications Vertuoz envoyées par
              Mail</span
            >
          </p>
        </ng-container>
      </section>
    </form>
  </vertuoz-loader>

  <!--<div mat-dialog-actions class="btn-container">
<button mat-flat-button (click)="close()">Fermer</button>
</div>-->
</div>
