import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Add RxJS observable
import { Observable } from 'rxjs';

import { finalize } from 'rxjs/operators';
import { share } from 'rxjs/operators';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private cachedData = new Map<string, any>();

    constructor() {}

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public intercept(httpRequest: HttpRequest<any>, handler: HttpHandler): Observable<any> {
        // Don't cache if it's not a GET request
        if (httpRequest.method !== 'GET') {
            return handler.handle(httpRequest);
        }

        // Also leave scope of resetting already cached data for a URI
        if (httpRequest.headers.get('reset-cache')) {
            this.cachedData.delete(httpRequest.urlWithParams);
        }

        // Checked if there is cached data for this URI
        const lastResponse = this.cachedData.get(httpRequest.urlWithParams);
        if (lastResponse && lastResponse instanceof Observable) {
            // In case of parallel requests to same URI,
            // return the request already in progress
            return lastResponse;
        }

        // If the request of going through for first time
        // then let the request proceed and cache the response
        const requestHandle = handler
            .handle(httpRequest)
            .pipe(
                finalize(() => {
                    this.cachedData.delete(httpRequest.urlWithParams);
                })
            )
            .pipe(share());

        // Meanwhile cache the request Observable to handle parallel request
        this.cachedData.set(httpRequest.urlWithParams, requestHandle);

        return requestHandle;
    }
}
