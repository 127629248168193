//#region
import { Directive, EmbeddedViewRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { DomainService } from '@app/core/services/domain.service';
import { DomainEnum } from '../constants/domain.enum';
//#endregion

//#region Types
export class HasDomainsContext {
    public operator: string = null;
    public $implicit: boolean = null;
    public hasDomains: boolean = null;
    public condition: DomainEnum[] = null;
}
//#endregion

@Directive({
    selector: '[hasDomains]'
})
export class HasDomainsDirective {
    //#region Fields
    private _context: HasDomainsContext = new HasDomainsContext();
    private _thenTemplateRef: TemplateRef<HasDomainsContext> | null = null;
    private _elseTemplateRef: TemplateRef<HasDomainsContext> | null = null;
    private _thenViewRef: EmbeddedViewRef<HasDomainsContext> | null = null;
    private _elseViewRef: EmbeddedViewRef<HasDomainsContext> | null = null;
    //#endregion

    //#region Constructor
    constructor(
        private domainService: DomainService,
        private _viewContainer: ViewContainerRef,
        templateRef: TemplateRef<HasDomainsContext>
    ) {
        this._thenTemplateRef = templateRef;
    }
    //#endregion

    //#region Methods
    @Input()
    set hasDomains(condition: DomainEnum[]) {
        console.debug('HasDomainsDirective : hasDomains : condition : ' + condition);

        console.debug(
            'HasDomainsDirective : hasDomains : hasDomainsOperator : ' + this._context.operator
        );

        this._context.condition = condition;
        this._updateView();
    }

    @Input()
    set hasDomainsOperator(op) {
        console.debug('HasDomainsDirective : hasDomainsOperation : ' + op);

        this._context.operator = op;
        this._updateView();
    }

    @Input()
    set hasDomainsThen(templateRef: TemplateRef<HasDomainsContext>) {
        this._thenTemplateRef = templateRef;
        this._thenViewRef = null; // clear previous view if any.
        this._updateView();
    }

    @Input()
    set hasDomainsElse(templateRef: TemplateRef<HasDomainsContext>) {
        this._elseTemplateRef = templateRef;
        this._elseViewRef = null; // clear previous view if any.
        this._updateView();
    }

    private checkDomains(): boolean {
        if (this._context.condition && this._context.condition.length > 0) {
            return (this._context.$implicit = this._context.hasDomains = this.domainService.hasDomains(
                this._context.condition,
                this._context.operator
            ));
        }
        return true;
    }

    private _updateView() {
        if (this.checkDomains()) {
            if (!this._thenViewRef) {
                this._viewContainer.clear();
                this._elseViewRef = null;
                if (this._thenTemplateRef) {
                    this._thenViewRef = this._viewContainer.createEmbeddedView(
                        this._thenTemplateRef,
                        this._context
                    );
                }
            }
        } else {
            if (!this._elseViewRef) {
                this._viewContainer.clear();
                this._thenViewRef = null;
                if (this._elseTemplateRef) {
                    this._elseViewRef = this._viewContainer.createEmbeddedView(
                        this._elseTemplateRef,
                        this._context
                    );
                }
            }
        }
    }
    //#endregion
}
