import {
    Component,
    DoCheck,
    EventEmitter,
    Input,
    IterableDiffer,
    IterableDiffers,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { MatSelectChange } from '@angular/material/select';

import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Label } from '@app/core/models/common/label.model';
import { PagedContext } from '@app/features/shared/models/paged-context';

@Component({
    selector: 'vertuoz-infinite-scroll-mat-select',
    templateUrl: './vertuoz-infinite-scroll-mat-select.component.html',
    styleUrls: ['./vertuoz-infinite-scroll-mat-select.component.scss']
})
export class VertuozInfiniteScrollMatSelectComponent implements OnInit, OnDestroy, DoCheck {
    // optional, for test
    @Input() selectedValue: number = null;
    @Input() disabled: boolean;
    @Input() total: number;
    @Input() defaultOption: Label = null;
    @Input() data: Array<Label>;
    @Input() limit = 10;
    @Input() title = 'Vertuoz default title';
    offset = 0;
    @Input() isLoading;
    pendingAppend = 0;
    options = new BehaviorSubject<Array<Label>>([]);
    options$: Array<Label>;

    @Output() valueChanged = new EventEmitter<number>();
    @Output() appendItems = new EventEmitter<PagedContext>();

    iterableDiffer: IterableDiffer<Label>;

    /** enregistrement pour desouscrire */
    private _onDestroy = new Subject<void>();

    constructor(private iterable: IterableDiffers) {
        this.iterableDiffer = this.iterable.find([]).create(null);
    }

    ngOnInit(): void {
        this.options
            .asObservable()
            .pipe(takeUntil(this._onDestroy))
            .subscribe(result => {
                this.offset = Math.floor(result.length / this.limit) * this.limit;
                this.pendingAppend = 0;
                this.options$ = result;
            });
        if (!this.data || this.data.length < this.limit) {
            this.remoteAppendItems();
        }
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    private getPagedContex(take: number, offset: number): PagedContext {
        return <PagedContext>{
            currentPage: Math.floor(offset / take) + 1,
            pageSize: take
        };
    }

    // Iterative loading
    private loadSelectedItem(selectedId: number): void {
        if (
            !selectedId ||
            this.data.find(d => d.id === selectedId) ||
            this.data.length >= this.total
        ) {
            return;
        }

        this.remoteAppendItems();
    }

    ngDoCheck(): void {
        const changes = this.iterableDiffer.diff(this.data);
        if (changes) {
            this.options.next(this.data);
            if (this.selectedValue) {
                this.loadSelectedItem(this.selectedValue);
            }
        }
    }

    onInfiniteScroll(): void {
        if (this.data.length < this.total) {
            const t =
                Math.floor(this.data.length / this.limit) * this.limit +
                this.pendingAppend * this.limit;
            this.offset = t;
            this.remoteAppendItems();
        }
    }

    remoteAppendItems(): void {
        if (this.total !== undefined && (this.data.length < this.total || this.total === 0)) {
            this.appendItems.emit(this.getPagedContex(this.limit, this.offset));
            this.pendingAppend++;
        }
    }

    selectionChanged($event: MatSelectChange): void {
        this.valueChanged.emit($event.value);
    }
}
