<mat-icon
  [ngClass]="classStyle"
  [svgIcon]="
    renewableEnergyId === RenewableEnergyEnum.Wind
      ? 'wind_turbine2'
      : '' || renewableEnergyId === RenewableEnergyEnum.Photovoltaic
      ? 'solar_elec'
      : '' || renewableEnergyId === RenewableEnergyEnum.Hydraulic
      ? 'hydraulic'
      : '' || renewableEnergyId === RenewableEnergyEnum.SolarThermal
      ? 'solar_therm'
      : '' || renewableEnergyId === RenewableEnergyEnum.Geothermal
      ? 'geothermal'
      : '' || renewableEnergyId === RenewableEnergyEnum.Biomass
      ? 'nature'
      : ''
  "
>
</mat-icon>
