import { sensorMeasureType } from '@app/shared/constants/sensor-measure-types.enum';
import {
    DataControlCategory,
    DataControlCriticity,
    DataControlType,
    NotificationStatus
} from '../enums/data-control.enum';

export class DataControlFilter {
    public dataControlIds: number[];
    public createDateMin: Date;
    public createDateMax: Date;
    public ruleTypes: DataControlType[];
    public invoiceIds: number[];
    public sensorIds: number[];
    public ruleIds: number[];
    public isTrigger: boolean;
    public acknowledged: boolean;

    constructor(init: Partial<DataControlFilter>) {
        Object.assign(this, init);
    }
}

export class DataControlNotificationFilter {
    public notificationIds: number[];
    public notificationCategories: DataControlCategory[];
    public notificationTypes: DataControlType[];
    public sensorMeasureTypes: sensorMeasureType[];
    public notificationStatus: Array<NotificationStatus>;
    public notificationFromDate: Date;
    public notificationToDate: Date;
    public acknowledged: boolean;
    public notificationCriticity: DataControlCriticity;
    public invoiceIds: number[];
    public meteringPointIds: number[];
    public ruleLabel: string;

    constructor(init?: Partial<DataControlNotificationFilter>) {
        if (init) {
            Object.assign(this, init);
        }
    }
}

export class DataControlRuleFilter {
    public ruleIds: number[];
    public ruleLabel: string;
    public ruleCategories: DataControlCategory[];
    public ruleTypes: DataControlType[];
    public activeNotification: boolean;
    public ruleCriticity: DataControlCriticity;
    public sensorMeasureTypes: number[];
    public hasLocation: boolean;
    public isPreconfigured: boolean;
    public hasMeteringPoint: boolean;
    public hasSensors: boolean;
}
