<ng-container [ngSwitch]="badgeType">
  <ng-container *ngSwitchCase="'equipment'">
    <div *ngIf="fluidId >= 0; then equip_basic; else equip_renew_energy"></div>
    <ng-template #equip_basic>
      <span
        class="badge-equip d-flex"
        [ngClass]="!color ? getFluidBadge(fluidId, badgeClass, badgeSize) : ''"
      >
        <span class="badge-equip__fluid d-flex">
          <fluid-icon [fluidId]="fluidId" [classStyle]="classStyle"></fluid-icon>
        </span>
      </span>
    </ng-template>
    <ng-template #equip_renew_energy>
      <span
        class="badge badge-classic badge-renew-energy me-2"
        [ngClass]="!color ? getRenewEnergyFluidBadge(fluidId, badgeSize) : ''"
      >
        <renewable-energy-icon
          [renewableEnergyId]="fluidId"
          [classStyle]="classStyle"
        ></renewable-energy-icon>
        <strong class="me-1" *ngIf="customText" [innerHTML]="customText"></strong>
      </span>
    </ng-template>
  </ng-container>

  <ng-container *ngSwitchCase="'sensor-measure'">
    <span
      class="badge badge-classic badge-sensor-measure me-2"
      [ngClass]="!color ? getSensorMeasureTypeBadge(fluidId, badgeSize, badgeClass) : ''"
    >
      <sensor-measure-icon
        [sensorMeasureId]="fluidId"
        [classStyle]="classStyle"
      ></sensor-measure-icon>
      <strong class="me-1" *ngIf="customText" [innerHTML]="customText"></strong>
    </span>
  </ng-container>

  <span
    *ngSwitchDefault
    class="badge badge-classic me-2"
    (mouseover)="isClickable && (hover = true)"
    (mouseout)="isClickable && (hover = false)"
    [ngStyle]="
      hover
        ? { backgroundColor: color, height: '26px', cursor: 'pointer', paddingTop: '5px' }
        : { backgroundColor: color }
    "
    [ngClass]="!color ? getFluidBadge(fluidId, badgeClass) : getCustomColorBadge(badgeClass)"
    (click)="isClickable && emitSelectedFluidValue(fluidId)"
  >
    <fluid-icon *ngIf="displayIcon" [classStyle]="classStyle" [fluidId]="fluidId"></fluid-icon>
    <strong
      class="me-1"
      [ngStyle]="hover ? { fontSize: '16px', verticalAlign: 'bottom' } : {}"
      *ngIf="customText"
      [innerHTML]="customText"
    ></strong>
  </span>
</ng-container>
