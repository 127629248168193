<div>
  <mat-tab-group
    (selectedTabChange)="changeSearchStatus($event.index)"
    [color]="'red'"
    [backgroundColor]="'accent'"
  >
    <mat-tab [label]="'Périmètre'">
      <div [ngStyle]="{ display: domainGeographyExist ? 'block' : 'none' }">
        <strong class="d-block pt-3 pb-2 font-18">Géographie</strong>
        <vertuoz-perimeter-selector
          #geographic
          [loading]="loading"
          [selectedInfoLevel]="selectedInfoGeographic"
          [partialSelectedLabel]="partialSelectedLabel"
          [selectedLabel]="selectedLabel"
          [selection]="selection"
          [filterSelection]="filterSelection"
          [updateMode]="updateMode"
          [lowestLevelMode]="lowestLevelMode"
          [displayBeginLevel]="-3"
          [displayUntilLevel]="0"
          [key]="geoMatTabPerimeterSelectorKey"
          (closedPanel)="closedPanel($event)"
        >
        </vertuoz-perimeter-selector>
      </div>
      <div [ngStyle]="{ display: domainPropertyExist ? 'block' : 'none' }">
        <strong class="d-block pt-3 pb-2 font-18">Immobilier</strong>
        <vertuoz-perimeter-selector
          #property
          [loading]="loading"
          [selectedInfoLevel]="selectedInfoProperty"
          [partialSelectedLabel]="partialSelectedLabel"
          [selectedLabel]="selectedLabel"
          [selection]="selection"
          [filterSelection]="filterSelection"
          [updateMode]="updateMode"
          [lowestLevelMode]="lowestLevelMode"
          [displayBeginLevel]="1"
          [displayUntilLevel]="3"
          [key]="estateMatTabPerimeterSelectorKey"
          (closedPanel)="closedPanel($event)"
        >
        </vertuoz-perimeter-selector>
      </div>
      <div [ngStyle]="{ display: domainPublisSpaceExist ? 'block' : 'none' }">
        <strong class="d-block pt-3 pb-2 font-18">Espace public</strong>
        <vertuoz-perimeter-selector
          #publicSpace
          [loading]="loading"
          [selectedInfoLevel]="selectedInfoPublicSpace"
          [partialSelectedLabel]="partialSelectedLabel"
          [selectedLabel]="selectedLabel"
          [selection]="selection"
          [filterSelection]="filterSelection"
          [updateMode]="updateMode"
          [lowestLevelMode]="lowestLevelMode"
          [displayBeginLevel]="11"
          [displayUntilLevel]="13"
          [key]="publicSpaceMatTabPerimeterSelectorKey"
          (closedPanel)="closedPanel($event)"
        >
        </vertuoz-perimeter-selector>
      </div>
    </mat-tab>
    <mat-tab class="rightTab" [label]="'Groupes'" *ngIf="withGroupeSelector">
      <vertuoz-group-selector
        #tabGroups
        [selectedGroups]="selectedGroups"
        [updateMode]="mode !== 3"
        [initialGroupsProperty]="initialGroupsProperty"
        [searchKey]="searchKeyword"
        [domains]="domains"
      ></vertuoz-group-selector>
    </mat-tab>
    <mat-tab *ngIf="updateMode" disabled class="actions-tab">
      <ng-template mat-tab-label>
        <form class="search-form container-toolbar-button" [style.width.px]="searchWidth">
          <mat-form-field class="w-100">
            <input
              #search
              type="text"
              matInput
              placeholder="Rechercher"
              [value]="searchKeyword"
              (input)="searchInputChange($event.target.value)"
            />
          </mat-form-field>
        </form>

        <button
          mat-icon-button
          matTooltip="Recherche"
          [matTooltipClass]="'tooltipGeo'"
          (click)="showNavSearch($event)"
          *ngIf="searchActive"
        >
          <mat-icon class="color-white" *ngIf="searchWidth === 0">search</mat-icon>
          <mat-icon class="color-white" *ngIf="searchWidth === 400">highlight_off</mat-icon>
        </button>

        <ng-container>
          <span class="btnHeader-separator"></span>

          <div class="tab-header-actions">
            <button
              mat-icon-button
              matTooltip="{{ 'common.action.cancel' | translate }}"
              [matTooltipClass]="'tooltipGeo'"
              (click)="cancel($event)"
              [disabled]="!compareGroupsInitialValues()"
              [ngClass]="{ 'disabled-header-action': !compareGroupsInitialValues() }"
            >
              <mat-icon class="color-white">undo</mat-icon>
            </button>
          </div>
        </ng-container>

        <span class="btnHeader-separator"></span>
        <button
          mat-icon-button
          matTooltip="Supprimer toutes les sélections"
          [matTooltipClass]="'tooltipGeo'"
          (click)="resetAllSelection($event)"
        >
          <mat-icon class="color-white">delete</mat-icon>
        </button>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
