<div class="field-dropdown">
  <div [class.required]="required" #dropreference>
    <mat-form-field class="w-100">
      <input
        #input
        matInput
        placeholder="{{placeholder}}"
        [disabled]="disabled"
        readonly
        [value]="displayText"
        (click)="showDropdown()"
        (keydown)="onKeyDown($event)"
        (blur)="onTouched()"
        autocomplete="off"
        class="cursor-pointer"
      />
      <button mat-icon-button matSuffix (click)="onDropMenuIconClick($event)">
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>
    </mat-form-field>

    <custom-dropdown [reference]="dropreference" #dropdownComp>
      <div class="dropdown-options-container">
        <ng-content select="custom-select-option"></ng-content>
      </div>
    </custom-dropdown>
  </div>
</div>
