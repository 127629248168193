import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '([formControlName], [formControl])[disabledControl]'
})
export class DisabledControlDirective implements OnChanges {
    @Input() disabledControl;
    constructor(private ngControl: NgControl) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['disabledControl']) {
            const action = this.disabledControl ? 'disable' : 'enable';

            this.ngControl.control[action]();
        }
    }
}
