//#region Imports
import { Injectable } from '@angular/core';

import { enumGeographicLevelId } from '@app/shared/constants/common.enum';
import { DomainEnum, DomainEnumRSX, PerimeterDomains } from '@app/shared/constants/domain.enum';
import { AppService } from '../http/app.service';
import { GeoSelectionTimeStampService } from './geo-service.service';

//#endregion

@Injectable({
    providedIn: 'root'
})
export class DomainService {
    //#region Constructor
    constructor(
        private appService: AppService,
        private geoSelectionTimeStampService: GeoSelectionTimeStampService
    ) {}
    //#endregion

    //#region Methods
    /**
     * Indique si les domaines renseignés sont présent pour l'utilisateur courant
     * @param domains Domaines à vérifier par rapport aux domaines de l'utilisateur courant
     *  @param operator AND : Tous les domaines doivent présent or OR : au moin un domaine doit présent
     */
    public hasDomains(domains: Array<DomainEnum>, operator: string = 'AND'): boolean {
        if (!domains || domains.length === 0) {
            return false;
        }

        const userDomains = this.appService.getUserContext().role.domains;

        switch (operator) {
            case 'OR':
                return domains.some(dom =>
                    userDomains.some(ud => ud.perimeterItemDomainId === dom)
                );
            case 'AND':
                return domains.every(dom =>
                    userDomains.some(ud => ud.perimeterItemDomainId === dom)
                );
            default:
                return domains.every(dom =>
                    userDomains.some(ud => ud.perimeterItemDomainId === dom)
                );
        }
    }

    /**
     * Renvoie la liste des domaines de l'utilisateur courant
     */
    public getUserDomains(): DomainEnum[] {
        return <DomainEnum[]>(
            this.appService
                .getUserContext()
                .role.domains.map(d => DomainEnum[DomainEnum[d.perimeterItemDomainId]])
        );
    }

    /**
     * Renvoie la liste des domaines non autorisés pour l'utilisateur
     */
    public getUsersUnavailableDomains(): DomainEnum[] {
        const userDomains = this.getUserDomains();
        return this.getAllDomains().filter(d => userDomains.findIndex(ud => ud === d) <= 0);
    }

    /**
     * Renvoie tous les domaines de vertuoz
     * Converti l'enum de domaine en tableau de valeur.
     */
    public getAllDomains(): DomainEnum[] {
        return <DomainEnum[]>Object.keys(DomainEnum)
            .map(k => DomainEnum[k])
            .filter(f => !isNaN(f));
    }

    /**
     * Renvoie le domaine du niveau passé en paramètre
     * @param perimeterLevelId
     */
    public getPerimeterDomain(perimeterLevelId: enumGeographicLevelId): DomainEnum {
        return PerimeterDomains.find(pd => pd.value.findIndex(d => d === perimeterLevelId) >= 0)
            .key;
    }

    /**
     * Renvoie le domaine lié au périmètre actif
     */
    public getActivePerimeterDomain(): DomainEnum {
        const perimeterLevel: enumGeographicLevelId = this.geoSelectionTimeStampService
            .perimeterLevelState.id;
        return this.getPerimeterDomain(perimeterLevel);
    }

    public getDomainsPerimeters(domains: Array<DomainEnum>): Array<enumGeographicLevelId> {
        const levels: enumGeographicLevelId[] = [];
        domains.forEach(d => {
            const level = PerimeterDomains.find(pd => pd.key === d);
            if (level) {
                levels.push(...level.value);
            }
        });
        return levels;
    }

    /**
     * Returns the domain label
     * @param domain
     */
    public getDomainLabel(domain: DomainEnum): string {
        const DomainRSX = DomainEnumRSX.find(ts => ts.key === domain);
        return DomainRSX ? DomainRSX.value : null;
    }

    //#endregion
}
