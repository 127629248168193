<div class="container">
  <!--MD version-->
  <mat-form-field [floatLabel]="isLoading ? 'never' : 'auto'" class="w-100">
    <mat-label *ngIf="!isLoading">{{ title }}</mat-label>
    <mat-select
      msInfiniteScroll
      (infiniteScroll)="onInfiniteScroll()"
      [complete]="offset === total"
      (selectionChange)="selectionChanged($event)"
      [(ngModel)]="selectedValue"
      [disabled]="disabled"
    >
      <mat-option *ngIf="defaultOption" [value]="defaultOption.id || null">
        {{ defaultOption.label || 'Tout' }}
      </mat-option>
      <mat-option *ngFor="let option of options$" [value]="option.id">{{
        option.label
      }}</mat-option>
      <mat-option *ngIf="isLoading"
        ><mat-progress-bar mode="indeterminate"></mat-progress-bar
      ></mat-option>
    </mat-select>
    <mat-label *ngIf="isLoading">
      <div class="select-placeholder-container">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
    </mat-label>
  </mat-form-field>
</div>
