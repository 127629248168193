//#region Imports
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { PageResult } from '@app/core/models/common/page-result.model';
import { GroupPerimeterInfosModel } from '@app/core/models/perimeters/group/group-perimeter-infos.model';
import {
    GroupPerimeterModel,
    PatchGroupsDto
} from '@app/core/models/perimeters/group/group-perimeter.model';
import { GroupFilter } from '@app/core/models/perimeters/group/group.filter';
import { PagedContext } from '@app/features/shared/models/paged-context';
import { ToolsService } from '@app/features/shared/services/tools.service';
import { environment } from '@environments/environment';
import { BaseService } from '../base.service';
//#endregion

@Injectable({
    providedIn: 'root'
})
export class GroupsService extends BaseService {
    //#region Fields
    private API_ENDPOINT: string = environment.API_ENDPOINT;
    private GROUP_API_URL = `${this.API_ENDPOINT}GroupPerimeters`;

    private _groupsChange = new Subject<boolean>();
    public groupsChange = this._groupsChange.asObservable();
    //#endregion

    //#region Constructor
    constructor(private http: HttpClient, private toolsService: ToolsService) {
        super();
    }
    //#endregion

    //#region methodes public
    public notifyGroupsChange(): void {
        this._groupsChange.next(true);
    }
    //#endregion

    //#region API Calls

    /**
     * Get Groups
     * @param exactName Unicité
     * @param groupFilter Filtre par levelId et par nom pour la recherche
     * @param pagedContext Pagination
     */
    public getGroups(
        exactName: string,
        groupFilter: GroupFilter,
        pagedContext: PagedContext
    ): Observable<PageResult<GroupPerimeterInfosModel>> {
        const params = this.createHttpParams(groupFilter, pagedContext, { exactName: exactName });
        return this.http.get<PageResult<GroupPerimeterInfosModel>>(
            this.GROUP_API_URL + `?${params}`
        );
    }

    public getGroupsDetails(
        exactName: string,
        groupFilter: GroupFilter,
        pagedContext: PagedContext
    ): Observable<PageResult<GroupPerimeterModel>> {
        const queryStr = this.toolsService.transformParamsArrayToQueryString(
            groupFilter,
            pagedContext,
            { exactName: exactName }
        );

        return this.http.get<PageResult<GroupPerimeterModel>>(
            `${this.GROUP_API_URL}/Details?${queryStr}`
        );
    }

    public createNewGroup(newGroup: GroupPerimeterModel): Observable<GroupPerimeterModel> {
        return this.http.post<GroupPerimeterModel>(this.GROUP_API_URL, newGroup).pipe(
            map(r => {
                this.notifyGroupsChange();
                return r;
            })
        );
    }

    /**
     * Get group details
     *
     * @param {number} groupId
     * @returns
     * @memberof GroupsService
     */
    public getGroupDetailsById(groupId: number): Observable<GroupPerimeterModel> {
        return this.http.get<GroupPerimeterModel>(this.GROUP_API_URL + '/' + groupId);
    }

    /**
     * Add a group
     *
     * @param {GroupModel} group
     * @returns
     * @memberof GroupsService
     */
    public addGroup(group: GroupPerimeterModel): Observable<GroupPerimeterInfosModel> {
        return this.http.post<GroupPerimeterInfosModel>(this.GROUP_API_URL, group);
    }

    /**
     * update a group
     *
     * @param {number} groupId
     * @param {GroupModel} group
     * @returns
     * @memberof GroupsService
     */
    public updateGroup(
        groupId: number,
        group: GroupPerimeterModel
    ): Observable<GroupPerimeterInfosModel> {
        return this.http.put<GroupPerimeterInfosModel>(this.GROUP_API_URL + '/' + groupId, group);
    }

    /**
     * update groups (ajout des user en masse)
     *
     * @returns
     * @memberof GroupsService
     * @param patchGroupsDto
     */
    public updateGroupsUsers(patchGroupsDto: PatchGroupsDto): Observable<GroupPerimeterInfosModel> {
        return this.http.patch<GroupPerimeterInfosModel>(this.GROUP_API_URL, patchGroupsDto);
    }

    /**
     * update groups (ajout des éléments en masse)
     *
     * @returns
     * @memberof GroupsService
     * @param patchGroupsDto
     */
    public updateGroupsContents(
        patchGroupsDto: PatchGroupsDto
    ): Observable<GroupPerimeterInfosModel> {
        return this.http.patch<GroupPerimeterInfosModel>(this.GROUP_API_URL, patchGroupsDto);
    }

    /**
     * Delete a group
     *
     * @param {number} groupId
     * @returns
     * @memberof GroupsService
     */
    public deleteGroup(groupId: number): Observable<GroupPerimeterInfosModel> {
        return this.http.delete<GroupPerimeterInfosModel>(this.GROUP_API_URL + '/' + groupId);
    }

    /**
     * Delete multiple groups
     *
     * @param {number []} groupsIds[]
     * @returns
     * @memberof GroupsService
     */
    public deleteGroups(groupsIds: number[]): Observable<GroupPerimeterInfosModel> {
        let multipleDeleteQuery = '';
        groupsIds.forEach(groupId => {
            multipleDeleteQuery += 'groupsIds=' + groupId + '&';
        });
        return this.http.delete<GroupPerimeterInfosModel>(
            this.GROUP_API_URL + '/?' + multipleDeleteQuery
        );
    }
    //#endregion
}
