import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { GridDataResult } from '@progress/kendo-angular-grid';
import { Observable, Subject } from 'rxjs';

import { PageResult } from '@app/core/models/common/page-result.model';
import { EnergySupplierContractFilter } from '@app/core/models/energy-supplier-contracts/energy-supplier-contract.filter';
import {
    CustomerProfileModel,
    EnergySupplierContractModel,
    GasClassConsumptionModel,
    UnitModel,
    UseVersionModel
} from '@app/core/models/energy-supplier-contracts/energy-supplier-contracts.model';
import { PagedContext } from '@app/features/shared/models/paged-context';
import { ToolsService } from '@app/features/shared/services/tools.service';
import { FileExportType } from '@app/shared/constants/export.enum';
import { PerimeterScope } from '@app/shared/constants/filters.enum';
import { environment } from '@environments/environment';
import { BaseService } from '../base.service';

@Injectable({
    providedIn: 'root'
})
export class EnergySupplierContractsService extends BaseService {
    //#region Field
    private API_ENERGY_SUPPLIER_CONTRACTS = `${environment.API_ENDPOINT}energysuppliercontracts`;
    private API_CUSTOMER_PROFILS = `${environment.API_ENDPOINT}customerprofils`;
    private API_GAS_CLASS_CONSUMPTION = `${environment.API_ENDPOINT}gasclassconsumptions`;
    private API_USE_VERSIONS = `${environment.API_ENDPOINT}useversions`;
    private API_UNITS = `${environment.API_ENDPOINT}units`;
    public energySupplierContractsDataSource = new Subject<GridDataResult>();
    //#endregion

    //#region constructor
    constructor(private http: HttpClient, private toolsService: ToolsService) {
        super();
    }
    //#endregion

    //#region methods
    createEnergySupplierContract(
        energySupplierContractModel: EnergySupplierContractModel
    ): Observable<number> {
        return this.http.post<number>(
            this.API_ENERGY_SUPPLIER_CONTRACTS,
            energySupplierContractModel
        );
    }

    updateEnergySuppliercontract(
        energySupplierContractId: number,
        energySupplierContractModel: EnergySupplierContractModel
    ): Observable<number> {
        return this.http.put<number>(
            `${this.API_ENERGY_SUPPLIER_CONTRACTS}/${energySupplierContractId}`,
            energySupplierContractModel
        );
    }

    getEnergySupplierContracts(
        energySupplierContractFilter: EnergySupplierContractFilter,
        perimeterScope: PerimeterScope,
        pagedContext: PagedContext
    ): Observable<PageResult<EnergySupplierContractModel>> {
        const queryStr = this.toolsService.transformParamsArrayToQueryString(
            energySupplierContractFilter,
            pagedContext,
            { perimeterScope: perimeterScope }
        );

        return this.http.get<PageResult<EnergySupplierContractModel>>(
            `${this.API_ENERGY_SUPPLIER_CONTRACTS}?${queryStr}`
        );
    }

    getEnergySupplierContract(
        energySupplierContractId: number
    ): Observable<EnergySupplierContractModel> {
        return this.http.get<EnergySupplierContractModel>(
            `${this.API_ENERGY_SUPPLIER_CONTRACTS}/${energySupplierContractId}`
        );
    }

    getEnergySupplierContractNumberExists(
        contractNumber: string
    ): Observable<PageResult<EnergySupplierContractModel>> {
        const filter = new EnergySupplierContractFilter({});
        filter.exactNumber = contractNumber;
        return this.getEnergySupplierContracts(filter, PerimeterScope.NoScope, new PagedContext());
    }

    getEnergySupplierContractLabelExists(
        contractLabel: string
    ): Observable<PageResult<EnergySupplierContractModel>> {
        const filter = new EnergySupplierContractFilter({});
        filter.exactLabel = contractLabel;
        return this.getEnergySupplierContracts(filter, PerimeterScope.NoScope, new PagedContext());
    }

    deleteEnergySupplierContracts(energySupplierContractIds: number[]): Observable<void> {
        const queryStr = this.toolsService.transformParamsToQueryString({
            ids: energySupplierContractIds
        });
        return this.http.delete<void>(`${this.API_ENERGY_SUPPLIER_CONTRACTS}?${queryStr}`);
    }

    downloadEnergySupplierContracts(
        fileType: FileExportType,
        energySupplierContractFilter: EnergySupplierContractFilter,
        perimeterScope: PerimeterScope,
        pagedContext: PagedContext
    ): Observable<HttpResponse<Object>> {
        const queryStr = this.toolsService.transformParamsArrayToQueryString(
            energySupplierContractFilter,
            pagedContext,
            { perimeterScope: perimeterScope },
            { fileType: fileType }
        );

        return this.http.get<PageResult<EnergySupplierContractModel>>(
            `${this.API_ENERGY_SUPPLIER_CONTRACTS}/Download?${queryStr}`,
            {
                responseType: 'blob' as 'json',
                observe: 'response'
            }
        );
    }

    getCustomerProfile(): Observable<CustomerProfileModel[]> {
        return this.http.get<CustomerProfileModel[]>(this.API_CUSTOMER_PROFILS);
    }

    getGasClassConsumption(): Observable<GasClassConsumptionModel[]> {
        return this.http.get<GasClassConsumptionModel[]>(this.API_GAS_CLASS_CONSUMPTION);
    }

    getUseVersion(): Observable<UseVersionModel[]> {
        return this.http.get<UseVersionModel[]>(this.API_USE_VERSIONS);
    }

    getUnit(): Observable<UnitModel[]> {
        return this.http.get<UnitModel[]>(this.API_UNITS);
    }
    //#endregion
}
