<custom-dialog
  [originX]="originX"
  [originY]="originY"
  [useOverlay]="true"
  [overlayX]="overlayX"
  [overlayY]="overlayY"
  [closeOnScroll]="false"
  #customDialogAdd
>
  <mat-icon-button-content
    mat-icon-button
    matTooltip="Ajouter à un groupe"
    (click)="toggleAddGroup()"
  >
    <mat-icon> folder_special</mat-icon>
  </mat-icon-button-content>

  <dialog-content *ngIf="isAddPanelShowed">
    <form
      *ngIf="!isGroupsLoading"
      [formGroup]="addToGroupsFormGroup"
      (click)="$event.stopPropagation()"
      class="px-3 pt-3"
    >
      <div>
        <div
          #scrollDiv
          class="list-container list-group scroll-me"
          formArrayName="selectedGroups"
          autosize
        >
          <div
            *ngFor="
              let selectedGroup of addToGroupsFormGroup.controls.selectedGroups['controls'];
              let i = index
            "
            class="list-group-item"
            [style.height.px]=""
          >
            <mat-checkbox class="example-margin" [formControlName]="i">
              <span class="word-break-all wh-sp-normal m-0">{{ addGroups[i]?.name }}</span>
            </mat-checkbox>
          </div>
        </div>
      </div>
      <div class="cb_field">
        <mat-checkbox formControlName="isNewGroup">
          <mat-form-field class="w-100">
            <input matInput placeholder="Nouveau groupe" value="" formControlName="newGroupName" />
          </mat-form-field>
          <div
            *ngIf="
              addToGroupsFormGroup.controls.newGroupName.invalid &&
              (addToGroupsFormGroup.controls.newGroupName.dirty ||
                addToGroupsFormGroup.controls.newGroupName.touched)
            "
          >
            <mat-error
              class="font-12 mt-n3 text-end"
              *ngIf="
                addToGroupsFormGroup.controls.newGroupName.errors.required ||
                addToGroupsFormGroup.controls.newGroupName.errors.pattern
              "
            >
              Champ obligatoire.
            </mat-error>
            <mat-error
              class="font-12 mt-n3 text-end"
              *ngIf="addToGroupsFormGroup.controls.newGroupName.hasError('nameExists')"
            >
              Nom existant, veuillez en choisir un nouveau.
            </mat-error>
          </div>
        </mat-checkbox>
      </div>
    </form>

    <div *ngIf="isGroupsLoading" class="loading-container">
      <mat-spinner [diameter]="30" class="float-right"></mat-spinner>
      <p class="loading-text">{{ loadingText }}</p>
    </div>
  </dialog-content>

  <dialog-buttons *ngIf="isAddPanelShowed && !isGroupsLoading">
    <div class="dialog__buttons text-end p-3">
      <button
        type="button"
        mat-stroked-button
        color="primary"
        class="btn-sm"
        (click)="this.isAddPanelShowed = false"
      >
        {{ 'common.action.cancel' | translate }}
      </button>
      <button
        type="button"
        mat-flat-button
        color="primary"
        class="btn-sm ms-3"
        (click)="onAddGroup($event)"
        [disabled]="addToGroupsFormGroup.invalid"
      >
        Associer
      </button>
    </div>
  </dialog-buttons>
</custom-dialog>

<custom-dialog
  [padding]="10"
  [originX]="originX"
  [originY]="originY"
  [useOverlay]="true"
  [overlayX]="overlayX"
  [overlayY]="overlayY"
  [closeOnScroll]="false"
  #customDialogRemove
>
  <mat-icon-button-content
    mat-icon-button
    matTooltip="Dissocier d'un groupe"
    (click)="toggleRemoveGroup()"
  >
    <mat-icon svgIcon="folder_special_off"></mat-icon>
  </mat-icon-button-content>

  <dialog-content *ngIf="isRemovePanelShowed">
    <form *ngIf="!isGroupsLoading" [formGroup]="removeFromGroupsFormGroup" class="px-3 pt-3">
      <div>
        <div
          class="list-container list-group scroll-me"
          formArrayName="selectedGroups"
          #scrollDiv
          autosize
        >
          <div
            *ngFor="
              let selectedGroup of removeFromGroupsFormGroup.controls.selectedGroups['controls'];
              let i = index
            "
            class="list-group-item"
          >
            <mat-checkbox class="example-margin" [formControlName]="i">
              <span class="word-break-all wh-sp-normal m-0">{{ removeGroups[i]?.name }}</span>
            </mat-checkbox>
          </div>
        </div>
        <p *ngIf="removeGroups.length === 0">
          Les éléments sélectionnés n'appartiennent à aucun groupe.
        </p>
      </div>
    </form>

    <div *ngIf="isGroupsLoading" class="loading-container">
      <mat-spinner [diameter]="30" class="float-right"></mat-spinner>
      <p class="loading-text">{{ loadingText }}</p>
    </div>
  </dialog-content>

  <dialog-buttons
    *ngIf="isRemovePanelShowed && !isGroupsLoading && removeGroups && removeGroups.length > 0"
  >
    <div class="dialog__buttons px-3 pb-3">
      <button
        mat-stroked-button
        color="primary"
        class="btn-sm"
        (click)="this.isRemovePanelShowed = false"
      >
        {{ 'common.action.cancel' | translate }}
      </button>
      <button
        mat-flat-button
        color="primary"
        class="btn-sm btn-error ms-2"
        (click)="onRemoveGroup($event)"
      >
        Dissocier
      </button>
    </div>
  </dialog-buttons>
</custom-dialog>
