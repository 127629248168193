import {
    ChangeDetectorRef,
    Component,
    Directive,
    EventEmitter,
    HostListener,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from '@core/http/app.service';
import { EnergySupplierContractsService } from '@core/http/energy-supplier-contracts/energy-supplier-contracts.service';
import { GroupsService } from '@core/http/perimeter/groups.service';
import { Label } from '@core/models/common/label.model';
import { EnergySupplierContractFilter } from '@core/models/energy-supplier-contracts/energy-supplier-contract.filter';
import { GroupPerimeterModel } from '@core/models/perimeters/group/group-perimeter.model';
import { GroupFilter } from '@core/models/perimeters/group/group.filter';
import { UserContext } from '@core/models/user/user.context';
import { PagedContext } from '@features/shared/models/paged-context';
import { enumGeographicLevelId } from '@shared/constants/common.enum';
import { DomainEnum } from '@shared/constants/domain.enum';
import { PerimeterScope } from '@shared/constants/filters.enum';
import { Operation } from '@shared/constants/operation.enum';
import { VertuozAssignmentGroupsServiceService } from '@shared/directive/vertuoz-assignment/vertuoz-assignment-groups-service.service';
import { ToasterService } from '@vertuoz/vertuoz-library';
import { forkJoin, from, Observable, Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';

import { DataControlRulesService } from '@app/core/http/data-control/data-control-rules.service';
import { DeviceService } from '@app/core/http/devices/device.service';
import {
    DataControlCategory,
    DataControlType
} from '@app/core/models/data-control/enums/data-control.enum';
import { DataControlRuleFilter } from '@app/core/models/data-control/filters/data-control.filter';
import { PredicateDeviceDto } from '@app/features/device/models/models';
import { sensorMeasureType } from '@app/shared/constants/sensor-measure-types.enum';
import * as _ from 'lodash';

export interface AssignmentDialogMessage {
    mode: Mode;
    selectedItems: number[];
    selectionProperty: string;

    fluidsAssociatedToContracts: number[];
    levelId: enumGeographicLevelId;
    domainId: DomainEnum;
    assignmentItemsType: AssignmentItemsType;
    assignmentItemsOperation: Operation;
    itemType: string;
    title?: string;
    customMessage?: string;
    customEmptyAddMessage?: string;
    customEmptyRemoveMessage?: string;
    actionLabel?: string;
    newItemForm?: boolean;
    displaySearchText?: boolean;
}

export enum AssignmentItemsType {
    GROUP = 0,
    SUPPLIER_CONTRACT = 1,
    DATARULE = 2
}

export enum Mode {
    ASSOCIATE = 0,
    DISSOCIATE = 1
}

@Component({
    selector: 'vertuoz-assignment-modal',
    templateUrl: './vertuoz-assignment-modal.component.html',
    styleUrls: ['./vertuoz-assignment-modal.component.scss']
})
export class VertuozAssignmentModalComponent implements OnInit, OnDestroy {
    formGroup: FormGroup;
    items: Label[] = [];
    loading: boolean;
    initLoading: boolean;
    searchLoading: boolean;
    maxItems = 0;
    searchText = '';
    searchMode: boolean;
    hideSearch: boolean;

    searchChanges = new Subject<string>();

    // n'est pas forcément a jour doit être mis a jour avec getSelectedItemsLabel()
    private _selectedItems: Label[] = [];

    private dataControlRulesItems: Label[] = [];

    pagedContext: PagedContext;
    pageSize = 15;
    private authenticatedUserContext: UserContext;
    private _onDestroy = new Subject<void>();

    public AssignmentItemsType = AssignmentItemsType;
    public Operation = Operation;

    constructor(
        public dialogRef: MatDialogRef<VertuozAssignmentModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: AssignmentDialogMessage,
        private fb: FormBuilder,
        private appService: AppService,
        private groupsService: GroupsService,
        private energySupplierContractsService: EnergySupplierContractsService,
        private toasterService: ToasterService,
        private vertuozAssignmentGroupsServiceService: VertuozAssignmentGroupsServiceService,
        private dataControlRulesService: DataControlRulesService,
        private deviceService: DeviceService,
        private cdRef: ChangeDetectorRef
    ) {
        this.authenticatedUserContext = this.appService.getUserContext();
    }

    ngOnInit(): void {
        this.loading = true;
        this.initLoading = true;
        this.hideSearch = true;
        this.searchMode = this.data.displaySearchText;
        this.initForm();
        const init = true;
        // handle search
        this.searchChanges
            .pipe(debounceTime(400), takeUntil(this._onDestroy))
            .subscribe(searchValue => {
                // si 2 evenements se chevauche ne pas changer la selection sinon on la change
                if (!this.searchLoading) {
                    this._selectedItems = this.getSelectedItemsLabel();
                }
                this.searchText = searchValue;
                this.searchLoading = true;
                this.appendItems(init);
            });

        if (this.data.assignmentItemsType === AssignmentItemsType.DATARULE) {
            if (this.data.itemType === 'sensor') {
                this.loadSensorDataControlRules(init);
            } else {
                this.loadDataControlRules(init);
            }
        } else {
            this.appendItems(init);
        }
    }

    public checkGroupNameExists(input: FormControl): Observable<{}> {
        if (
            !input ||
            !input.value ||
            input.value.trim().length < 1 ||
            !this.formGroup ||
            !this.formGroup.controls.newGroupName.value
        ) {
            return from<string[]>(['']).pipe(takeUntil(this._onDestroy));
        }
        return this.groupsService.getGroups(input.value.trim(), new GroupFilter({}), null).pipe(
            takeUntil(this._onDestroy),
            map(result => {
                if (!result) {
                    return null;
                }
                return result.results.length > 0 ? { nameExists: true } : null;
            })
        );
    }

    onSearch(searchKey: string): void {
        this.searchChanges.next(searchKey);
    }

    appendItems(reinit: boolean = false): void {
        if (reinit || this.pagedContext.currentPage * this.pagedContext.pageSize < this.maxItems) {
            this.loading = true;
            if (reinit) {
                this.pagedContext = new PagedContext({ pageSize: this.pageSize, currentPage: 1 });
                this.items = [];
                (<FormArray>this.formGroup.get('selectedGroups')).clear();
                this.cdRef.detectChanges();
            } else {
                this.pagedContext.currentPage++;
            }
            // load data by items type
            switch (this.data.assignmentItemsType) {
                case AssignmentItemsType.GROUP:
                    this.loadGroups(this.pagedContext);
                    break;
                case AssignmentItemsType.SUPPLIER_CONTRACT:
                    this.loadEnergySupplierContracts(this.pagedContext);
                    break;
                case AssignmentItemsType.DATARULE:
                    this.appendDataControlRules(this.pagedContext);
                    break;
            }
        }
    }

    private loadSensorDataControlRules(init: boolean): void {
        forkJoin({
            /**
             * Get the types of measurement of the sensors of the selected devices
             * to propose control rules of the same type
             */
            devices: this.deviceService.getDeviceList(<PredicateDeviceDto>{
                deviceIds: this.data.selectedItems,
                page: 0,
                pageSize: -1
            }),
            dataControlRules: this.dataControlRulesService.getRules(
                <PagedContext>{ pageSize: -1 },
                <DataControlRuleFilter>{
                    ruleTypes: [DataControlType.Sensor, DataControlType.MissingDataSensorMeasure]
                },
                PerimeterScope.activeSelectedPerimeter
            )
        }).subscribe(({ devices, dataControlRules }) => {
            const sensorsMeasuresTypeOfSelectedDevices: Array<number> = [];
            const selectedSensorsIds: Array<number> = [];

            devices?.results.forEach(d => {
                d.sensors.forEach(s => {
                    sensorsMeasuresTypeOfSelectedDevices.push(s.sensorMeasureType.measureTypeId);
                    selectedSensorsIds.push(s.sensorId);
                });
            });

            // Sensors measures types 'Temperature', 'IndoorTemperature', 'OutdoorTemperature' are considered to be of the same type.
            // Example: Sensor measure is IndoorTemperature ? OutdoorTemperature rule can be associated with it
            if (
                sensorsMeasuresTypeOfSelectedDevices.indexOf(sensorMeasureType.Temperature) > -1 ||
                sensorsMeasuresTypeOfSelectedDevices.indexOf(sensorMeasureType.IndoorTemperature) >
                    -1 ||
                sensorsMeasuresTypeOfSelectedDevices.indexOf(sensorMeasureType.OutdoorTemperature) >
                    -1
            ) {
                sensorsMeasuresTypeOfSelectedDevices.push(
                    ...[
                        sensorMeasureType.Temperature,
                        sensorMeasureType.IndoorTemperature,
                        sensorMeasureType.OutdoorTemperature
                    ]
                );
            }
            const uniqueSensorsMeasuresTypeOfSelectedDevices = [
                ...new Set(sensorsMeasuresTypeOfSelectedDevices)
            ];

            /**
             * Filter control rules:
             * - in association mode, rules of the same type as the selected sensors
             * - in dissociation mode, the rules associated with the selected sensors
             */
            // Association mode
            if (this.data.mode === Mode.ASSOCIATE) {
                this.dataControlRulesItems = dataControlRules.results
                    .filter(
                        r =>
                            uniqueSensorsMeasuresTypeOfSelectedDevices.includes(
                                r.sensorMeasureTypeId
                            ) || r.ruleType === DataControlType.MissingDataSensorMeasure
                    )
                    .map(
                        dcr =>
                            <Label>{
                                label:
                                    dcr.ruleType === DataControlType.MissingDataSensorMeasure
                                        ? dcr.ruleCategoryLabel + ' - ' + dcr.label
                                        : dcr.sensorMeasureTypeId ===
                                              sensorMeasureType.IndoorTemperature ||
                                          dcr.sensorMeasureTypeId ===
                                              sensorMeasureType.OutdoorTemperature
                                        ? 'Température'
                                        : dcr.sensorMeasureTypeLabel + '  -  ' + dcr.label,
                                id: dcr.ruleId
                            }
                    )
                    .sort(function(a: Label, b: Label): number {
                        return a.label.localeCompare(b.label);
                    });
            } else {
                // Dissociation mode
                dataControlRules.results.forEach(r => {
                    if (r.locations.some(sl => selectedSensorsIds.includes(sl.sensorId))) {
                        this.dataControlRulesItems.push({
                            id: r.ruleId,
                            label:
                                r.ruleType === DataControlType.MissingDataSensorMeasure
                                    ? r.ruleCategoryLabel + ' - ' + r.label
                                    : r.sensorMeasureTypeId ===
                                          sensorMeasureType.IndoorTemperature ||
                                      r.sensorMeasureTypeId === sensorMeasureType.OutdoorTemperature
                                    ? 'Température'
                                    : r.sensorMeasureTypeLabel + '  -  ' + r.label
                        });
                    }
                });
                this.dataControlRulesItems.sort(function(a: Label, b: Label): number {
                    return a.label.localeCompare(b.label);
                });
            }
            this.appendItems(init);
        });
    }

    private loadDataControlRules(init: boolean): void {
        const filter: DataControlRuleFilter = new DataControlRuleFilter();

        if (this.data.itemType === 'pdc') {
            filter.ruleTypes = [
                DataControlType.MissingDataInvoice,
                DataControlType.MissingDataReading,
                DataControlType.MissingDataRemoteReading,
                DataControlType.InvoiceConsumption,
                DataControlType.ReadingConsumption,
                DataControlType.RemoteReadingConsumption
            ];

            filter.hasMeteringPoint = true;
        }

        this.dataControlRulesService
            .getRules(new PagedContext(), filter, PerimeterScope.activeGeographicRight)
            .subscribe(
                res => {
                    if (res != null) {
                        if (this.data.mode === Mode.ASSOCIATE) {
                            // Filter all metering points for the corresponding fluids
                            let filteredResult = [];
                            res.results.forEach(r => {
                                if (
                                    r.ruleCategory === DataControlCategory.MissingData ||
                                    (r.ruleCategory === DataControlCategory.Consumption &&
                                        r.fluidIds.filter(f =>
                                            this.data.fluidsAssociatedToContracts.includes(f)
                                        ).length > 0)
                                ) {
                                    filteredResult.push(r);
                                }
                            });
                            this.dataControlRulesItems = filteredResult
                                .map(r => <Label>{ id: r.ruleId, label: r.label })
                                .sort((a, b) => {
                                    return a.label.localeCompare(b.label);
                                });
                        } else {
                            res.results.forEach(r => {
                                if (
                                    _.intersection(
                                        r.locations.map(l => l.meteringPointId),
                                        this.data.selectedItems
                                    ).length > 0
                                ) {
                                    this.dataControlRulesItems.push({
                                        id: r.ruleId,
                                        label: r.label
                                    });
                                }
                            });
                            this.dataControlRulesItems = this.dataControlRulesItems.sort((a, b) => {
                                return a.label.localeCompare(b.label);
                            });
                        }
                    } else {
                        this.dataControlRulesItems = [];
                    }
                    this.appendItems(init);
                },
                () => {
                    this.dataControlRulesItems = [];
                }
            );
    }

    private loadGroups(pagedContext: PagedContext): void {
        const groupFilter = <GroupFilter>{ filterbyLevelId: this.data.levelId };

        // Filter all groups used by the selected items
        if (
            this.data.selectionProperty &&
            this.data.assignmentItemsOperation === Operation.REMOVE
        ) {
            groupFilter[this.data.selectionProperty] = this.data.selectedItems;
        }

        this.groupsService.getGroupsDetails(null, groupFilter, pagedContext).subscribe(
            result => {
                let numberToAddControlIntoArray = 0;
                if (result) {
                    this.maxItems = result.rowCount;
                    if (result.results) {
                        if (this.data.assignmentItemsOperation === Operation.ADD) {
                            const newGroups = this.vertuozAssignmentGroupsServiceService.getFormattedAddGroups(
                                result.results,
                                this.data.selectedItems
                            );
                            for (const group of newGroups) {
                                if (!this._selectedItems.some(i => i.id === group.id)) {
                                    numberToAddControlIntoArray++;
                                    this.items.push(group);
                                }
                            }
                        } else {
                            if (this.data.selectionProperty === 'filterByUserId') {
                                // Get all groups used by the user
                                for (const group of result.results.map(
                                    r => <Label>{ id: r.groupPerimeterId, label: r.name }
                                )) {
                                    if (!this._selectedItems.some(i => i.id === group.id)) {
                                        numberToAddControlIntoArray++;
                                        this.items.push(group);
                                    }
                                }
                            } else {
                                const removeGroups = this.vertuozAssignmentGroupsServiceService.getFormattedRemoveGroups(
                                    result.results,
                                    this.data.selectedItems
                                );
                                for (const group of removeGroups) {
                                    if (!this._selectedItems.some(i => i.id === group.id)) {
                                        numberToAddControlIntoArray++;
                                        this.items.push(group);
                                    }
                                }
                            }
                        }
                    }
                }
                this.addControlToFormArray(numberToAddControlIntoArray);
                this.loading = false;
                this.initLoading = false;
                this.searchLoading = false;
            },
            () => {
                this.loading = false;
                this.initLoading = false;
                this.searchLoading = false;
            }
        );
    }

    private loadEnergySupplierContracts(pagedContext: PagedContext): void {
        const filter =
            this.data.assignmentItemsOperation === Operation.ADD
                ? <EnergySupplierContractFilter>{
                      fluidsAssociatedToContract: this.data.fluidsAssociatedToContracts
                  } // To add to the supplier contracts depending on the selected fluids
                : <EnergySupplierContractFilter>{ meteringPointIds: this.data.selectedItems }; // To get the associated supplier contracts for removing it

        this.energySupplierContractsService
            .getEnergySupplierContracts(filter, PerimeterScope.NoScope, pagedContext)
            .subscribe(
                result => {
                    let numberToAddControlIntoArray = 0;
                    if (result) {
                        this.maxItems = result.rowCount;
                        if (result.results) {
                            if (this.data.assignmentItemsOperation === Operation.ADD) {
                                const addEnergySupplierContract = this.vertuozAssignmentGroupsServiceService.getFormattedAddEnergySupplierContract(
                                    result.results,
                                    this.data.selectedItems
                                );
                                for (const energySupplierContract of addEnergySupplierContract) {
                                    if (
                                        !this._selectedItems.some(
                                            i => i.id === energySupplierContract.id
                                        )
                                    ) {
                                        numberToAddControlIntoArray++;
                                        this.items.push(energySupplierContract);
                                    }
                                }
                            } else {
                                const removeEnergySupplierContract = this.vertuozAssignmentGroupsServiceService.getFormattedRemoveEnergySupplierContract(
                                    result.results,
                                    this.data.selectedItems
                                );
                                for (const energySupplierContract of removeEnergySupplierContract) {
                                    if (
                                        !this._selectedItems.some(
                                            i => i.id === energySupplierContract.id
                                        )
                                    ) {
                                        numberToAddControlIntoArray++;
                                        this.items.push(energySupplierContract);
                                    }
                                }
                            }
                        }
                    }
                    this.addControlToFormArray(numberToAddControlIntoArray);
                    this.loading = false;
                    this.initLoading = false;
                    this.searchLoading = false;
                },
                () => {
                    this.loading = false;
                    this.initLoading = false;
                    this.searchLoading = false;
                }
            );
    }

    private appendDataControlRules(pagedContext: PagedContext): void {
        if (this.dataControlRulesItems.length > 0) {
            let numberToAddControlIntoArray = 0;

            if (this.searchText?.length > 0) {
                const clone = [...this.dataControlRulesItems];

                this.maxItems = clone.filter(l => l.label.includes(this.searchText)).length;

                const rules = clone
                    .filter(l => l.label.includes(this.searchText))
                    .splice(
                        (pagedContext.currentPage - 1) * pagedContext.pageSize,
                        pagedContext.pageSize
                    );

                for (const rule of rules) {
                    if (!this._selectedItems.some(i => i.id === rule.id)) {
                        numberToAddControlIntoArray++;
                        this.items.push(rule);
                    }
                }
            } else {
                const clone = [...this.dataControlRulesItems];

                this.maxItems = clone.length;

                const rules = clone.splice(
                    (pagedContext.currentPage - 1) * pagedContext.pageSize,
                    pagedContext.pageSize
                );

                for (const rule of rules) {
                    if (!this._selectedItems.some(i => i.id === rule.id)) {
                        numberToAddControlIntoArray++;
                        this.items.push(rule);
                    }
                }
            }

            this.addControlToFormArray(numberToAddControlIntoArray);
            this.loading = false;
            this.initLoading = false;
            this.searchLoading = false;
        } else {
            this.loading = false;
            this.initLoading = false;
            this.searchLoading = false;
        }
    }

    private initForm(): void {
        const controls = this.items.map(c => new FormControl(false));

        if (this.data.newItemForm) {
            this.formGroup = this.fb.group({
                selectedGroups: new FormArray(controls),
                newGroupName: new FormControl('', null, this.checkGroupNameExists.bind(this)),
                isNewGroup: new FormControl(false, Validators.required)
            });

            this.formGroup.controls.isNewGroup.valueChanges.subscribe(newValue => {
                if (newValue) {
                    this.formGroup.controls.newGroupName.setValidators(Validators.required);
                    this.formGroup.controls.newGroupName.updateValueAndValidity();
                } else {
                    this.formGroup.controls.newGroupName.setValidators([]);
                    this.formGroup.controls.newGroupName.updateValueAndValidity();
                }
            });
            this.formGroup.controls.newGroupName.valueChanges.subscribe(newValue => {
                if (newValue && newValue.length > 0 && !this.formGroup.controls.isNewGroup.value) {
                    this.formGroup.controls.isNewGroup.setValue(true);
                }
            });
        } else {
            this.formGroup = this.fb.group({
                selectedGroups: new FormArray(controls)
            });
        }
    }

    addControlToFormArray(numberToAdd: number): void {
        const controlArray = <FormArray>this.formGroup.get('selectedGroups');
        for (let i = 0; i < numberToAdd; i++) {
            controlArray.push(new FormControl(false));
        }
        // Vérifie que les éléments selectionnés se trouve dans les items sinon on les ajoute avec le formArray de meme index
        for (const index in this._selectedItems) {
            if (this._selectedItems.hasOwnProperty(index)) {
                const indexArray = this.items.findIndex(
                    i => i.id === this._selectedItems[index].id
                );
                if (indexArray === -1) {
                    this.items.push(this._selectedItems[index]);
                    controlArray.push(new FormControl(true));
                } else {
                    controlArray.controls[indexArray].setValue(true);
                }
            }
        }
    }

    get selectedItemsIndex(): number[] {
        const controls = (<FormArray>this.formGroup.get('selectedGroups')).controls;
        const indexItems = [];
        if (controls) {
            for (const control of controls.filter(c => c.value)) {
                indexItems.push(controls.indexOf(control));
            }
        }
        return indexItems;
    }

    get notSelectedItemsIndex(): number[] {
        const controls = (<FormArray>this.formGroup.get('selectedGroups')).controls;
        const indexItems = [];
        if (controls) {
            for (const control of controls.filter(c => !c.value)) {
                indexItems.push(controls.indexOf(control));
            }
        }
        return indexItems;
    }

    // When the user clicks the action button a.k.a. the delete button in the\
    // modal, pass delete event followed by the closing of the modal
    submit(): void {
        // Cas spécifique de création d'un group dans la modal directement avec les éléments du goupe
        if (this.formGroup.value.isNewGroup) {
            const perimeters = [];
            this.data.selectedItems.forEach(item => {
                perimeters.push({
                    itemId: item,
                    perimeterLevel: this.data.levelId
                });
            });
            this.groupsService
                .createNewGroup(<GroupPerimeterModel>{
                    name: this.formGroup.value.newGroupName,
                    perimeters: perimeters,
                    domain: this.data.domainId,
                    levelId: this.data.levelId,
                    userId: this.authenticatedUserContext.user.userId,
                    labelUsers: [
                        <Label>{
                            id: this.authenticatedUserContext.user.userId
                        }
                    ]
                })
                .subscribe(res => {
                    this.toasterService.showSuccess('Groupe créé avec succés.');
                    this.toasterService.showSuccess(
                        this.vertuozAssignmentGroupsServiceService.getToasterMessage(
                            this.data.assignmentItemsOperation === Operation.ADD,
                            this.data.levelId
                        )
                    );
                });
        }

        // autres cases cochées -> remonté la liste des ID coché
        const emitIds = this.getSelectedItemsLabel().map(i => i.id);
        if (emitIds.length > 0) {
            this.dialogRef.close(emitIds);
        } else {
            this.dialogRef.close(null);
        }
    }

    getSelectedItemsLabel(): Label[] {
        const itemsLabel = [];
        this.items.forEach((item, i) => {
            if ((<FormArray>this.formGroup.get('selectedGroups')).controls[i].value) {
                itemsLabel.push(item);
            }
        });
        return itemsLabel;
    }

    /**
     * check if form is valid to activate submit btn
     */
    isFormValid(): boolean {
        return (
            this.formGroup.valid &&
            ((this.formGroup.value.isNewGroup && this.formGroup.value.newGroupName) ||
                (<FormArray>this.formGroup.get('selectedGroups')).controls.some(
                    e => e.value === true
                ))
        );
    }

    // If the user clicks the cancel button a.k.a. the go back button, then\
    // just close the modal
    closeModal(): void {
        this.dialogRef.close(null);
    }

    public ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    openSearh(): void {
        this.hideSearch = false;
    }

    closeSearch(): void {
        this.hideSearch = true;
        this.searchChanges.next(null);
    }
}

@Directive({
    selector: '[vertuozAssignment]'
})
export class VertuozAssignmentDirective {
    @Input() title;
    @Input() actionLabel = 'Valider';
    @Input() newItemForm = false;
    @Input() width = '30vw';
    @Input() height = '46vh';
    @Input() levelId: enumGeographicLevelId;
    @Input() domainId: DomainEnum;
    @Input() disableClose = false;

    @Input() customMessage = '';
    @Input() customEmptyAddMessage: string;
    @Input() customEmptyRemoveMessage: string;

    @Input() assignmentItemsType: AssignmentItemsType;
    @Input() assignmentItemsOperation: Operation;

    @Input() itemType: string;
    @Input() selectedItems = [];
    @Input() selectionProperty = '';
    @Input() fluidsAssociatedToContracts: number[] = [];
    @Input() displaySearchText = false;
    @Input() mode: Mode;

    @Output() assign = new EventEmitter<number[]>();

    constructor(private dialog: MatDialog) {}

    @HostListener('click') onClick(): void {
        this.openDialog();
    }

    openDialog(): void {
        let customTitle = 'Vertuoz Assign Modal',
            customMsg = '',
            customAddMsg = '',
            customRemoveMsg = '';
        const actionMsg = this.mode === Mode.ASSOCIATE ? 'Associer' : 'Dissocier';

        if (this.assignmentItemsType == AssignmentItemsType.DATARULE) {
            if (this.itemType === 'sensor') {
                customTitle = `${actionMsg} les capteurs de ces appareils à des règles de contrôle`;
                customMsg = `Veuillez sélectionner les règles de contrôle desquelles vous souhaitez ${actionMsg.toLowerCase()} les capteurs des ${
                    this.selectedItems.length
                } appareils de mesure sélectionnés`;
                customAddMsg = 'Aucune règle de contrôle existante pour ce type de mesure';
                customRemoveMsg =
                    'Les règles de contrôle sélectionnées ne sont associées à aucun capteur des appareils de mesure sélectionnés';
            } else if (this.itemType === 'pdc') {
                customTitle = `${actionMsg} les points de comptage à des règles de contrôle`;
                customMsg = `Veuillez sélectionner les règles de contrôle desquelles vous souhaitez ${actionMsg.toLowerCase()} les ${
                    this.selectedItems.length
                } points de comptage sélectionnés`;
                customAddMsg =
                    "Aucune règle de contrôle sur l'absence des données existante ou la consommation";
                customRemoveMsg =
                    'Les règles de contrôle sélectionnées ne sont associées à aucun points de comptage sélectionnés';
            }
        } else if (this.assignmentItemsType === AssignmentItemsType.GROUP) {
            customAddMsg = "Il n'y a pas de groupe auquel associer le patrimoine sélectionné";
            customRemoveMsg = "Le patrimoine sélectionné n'est associé à aucun groupe";
        } else if (this.assignmentItemsType == AssignmentItemsType.SUPPLIER_CONTRACT) {
            customAddMsg =
                "Il n'y a pas de contrat auquel associer les points de comptage sélectionnés";
            customRemoveMsg =
                'Les points de comptage sélectionnés ne sont associés à aucun contrat';
        }

        const dialogRef = this.dialog.open(VertuozAssignmentModalComponent, {
            width: this.width,
            height: 'auto',
            data: {
                mode: this.mode,
                selectedItems: this.selectedItems,
                selectionProperty: this.selectionProperty,
                fluidsAssociatedToContracts: this.fluidsAssociatedToContracts,
                customMessage:
                    this.customMessage.replace('__', this.selectedItems.length.toString()) ||
                    customMsg,
                customEmptyAddMessage: this.customEmptyAddMessage || customAddMsg,
                customEmptyRemoveMessage: this.customEmptyRemoveMessage || customRemoveMsg,
                title: this.title || customTitle,
                actionLabel: this.actionLabel,
                assignmentItemsType: this.assignmentItemsType,
                assignmentItemsOperation: this.assignmentItemsOperation,
                newItemForm: this.newItemForm,
                levelId: this.levelId,
                domain: this.domainId,
                displaySearchText: this.displaySearchText,
                itemType: this.itemType
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.assign.emit(result);
            } else {
                return;
            }
        });
    }
}
