import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogMessage {
    showAlertImpactUsers: boolean;
    customMessage: boolean;
    unauthorized: boolean;
    unauthorizedMessage: string;
    confirmSection: boolean;
    element: string;
    elementInfos: string[];
}

@Component({
    selector: 'vertuoz-delete-modal',
    templateUrl: './vertuoz-delete-modal.component.html',
    styleUrls: ['./vertuoz-delete-modal.component.scss']
})
export class VertuozDeleteModalComponent implements OnInit {
    form: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<VertuozDeleteModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogMessage,
        private fb: FormBuilder
    ) {}

    ngOnInit(): void {
        this.form = this.fb.group({
            name: [name, []]
        });
    }

    // When the user clicks the action button a.k.a. the delete button in the\
    // modal, pass delete event followed by the closing of the modal
    delete(): void {
        this.dialogRef.close(true);
    }

    // If the user clicks the cancel button a.k.a. the go back button, then\
    // just close the modal
    closeModal(): void {
        this.dialogRef.close(false);
    }
}

@Component({
    selector: 'vertuoz-delete',
    template: `
        <button
            mat-icon-button
            (click)="openDialog()"
            [matTooltip]="toolTipMessage | translate"
            [matTooltipDisabled]="isBulkActionSnackbar"
            [disabled]="isDeletingDisabled"
            *ngIf="!deletionLoading"
        >
            <mat-icon *ngIf="!isDeletingDisabled">{{ iconName }}</mat-icon>
            <mat-icon *ngIf="isDeletingDisabled" class="disabled-mat-icon">{{ iconName }}</mat-icon>
            <span *ngIf="isBulkActionSnackbar" class="v-tooltip">{{
                toolTipMessage | translate
            }}</span>
        </button>

        <div class="spinner-wrap d-inline-block ms-3 text-center" *ngIf="deletionLoading">
            <mat-spinner class="d-inline-block" [diameter]="20"></mat-spinner>
        </div>
    `,
    styleUrls: ['vertuoz-delete.component.scss']
})
export class VertuozDeleteComponent {
    @Input() disableClose = false;
    @Input() unauthorized = false;
    @Input() unauthorizedMessage = '';
    @Input() confirmSection = true;
    @Input() id: string;
    @Input() width = '600px';
    @Input() height = 'auto';
    @Input() displayButton = true;
    @Input() showAlertImpactUsers = true;
    @Input() element = 'cet élément';
    @Input() elementInfos: string[] = [];
    @Input() toolTipMessage = 'common.action.delete';
    @Input() iconName = 'delete';
    @Input() customMessage: String = '';
    @Input() deletionLoading = false;
    @Output() remove = new EventEmitter<boolean>();
    @Input() isBulkActionSnackbar: boolean;
    @Input() isDeletingDisabled = false;

    constructor(public dialog: MatDialog) {}

    openDialog(): void {
        const dialogRef = this.dialog.open(VertuozDeleteModalComponent, {
            width: this.width,
            height: this.height,
            disableClose: this.disableClose,
            id: this.id,
            data: {
                showAlertImpactUsers: this.showAlertImpactUsers,
                customMessage: this.customMessage,
                confirmSection: this.confirmSection,
                element: this.element,
                elementInfos: this.elementInfos,
                unauthorized: this.unauthorized,
                unauthorizedMessage: this.unauthorizedMessage
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.remove.emit(true);
            }
        });
    }
}
