import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, debounceTime, isEmpty } from 'rxjs';

export interface SearchPerimeterItem {
    searchKey: string;
    perimeterFilter: { [index: number]: Array<number> };
}

@Injectable()
export class GeoMenuService {
    getSearchParameters(key: string): Observable<SearchPerimeterItem> {
        if (!key) {
            return;
        }

        // Dynamically create the behavior subject
        if (!this[key]) {
            this[key] = new BehaviorSubject<SearchPerimeterItem>({
                searchKey: '',
                perimeterFilter: {}
            });
        }

        return this[key].asObservable();
    }

    updateSearchParameters(key: string, searchPerimeterItem: SearchPerimeterItem): void {
        if (!key) {
            return;
        }

        // Dynamically create the behavior subject
        if (!this[key]) {
            this[key] = new BehaviorSubject<SearchPerimeterItem>(searchPerimeterItem);
        }

        this[key].next(searchPerimeterItem);
    }

    removeSearchParameters(key: string): void {
        if (!key || !this[key]) {
            return;
        }

        this[key].next({ searchKey: '', perimeterFilter: {} });
    }
}
