import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { EnumPermissions } from '@app/core/models/permissions/permissions.enum';
import { PermissionService } from '@app/core/services/permission.service';
import { enumToaster, ToasterService } from '@vertuoz/vertuoz-library';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { enumSearchType } from './models/navsearch.enum';
import {
    NavSearchData,
    NavSearchEntityGroup,
    NavSearchResult,
    QuickSearchCriteriaDto
} from './models/navsearch.model';
import { NavSearchEndPointService } from './services/navsearch.service';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare var $: any;

@Component({
    selector: 'app-navsearch',
    templateUrl: './navsearch.component.html',
    styleUrls: ['./navsearch.component.scss']
})
export class NavsearchComponent implements OnChanges, OnInit {
    @Input() visible: boolean;
    @Output() onclose = new EventEmitter<boolean>();

    /** Liste qui contient les différents types d'entités pouvant être recherchées */
    public navSearchData: NavSearchEntityGroup[];
    /** Variable pour forcer la première sélection du type d'entité */
    public preselected: number;
    /** Enum du type d'entité courant */
    public currentSearchType: number;
    /** Liste des résultats exploitable */
    public navSearchResult: NavSearchResult[];
    /** Variable utilisée dans le but d'annuler la 'Subscription' précédente et donc d'annuler la requête */
    private navSearchResultSubscription: Subscription;
    /** Indique si le chargement des résultats de recherche est terminé */
    public isLoaded: boolean;
    /** Indique si le chargement des résultats de recherche est en cours */
    public isLoading: boolean;
    /** Indique la visibilité du panel de résultats */
    public showResults = false;
    /** Texte du placeholder de l'input "searchNavFormControl" (change en fonction du type d'entité sélectionné) */
    public searchPlaceHolder: string;
    /** Référence à l'objet "FormGroup" du même nom dnas le html */
    public NavSearchFormGroup: FormGroup;
    /** Déclaration permetant d'intéragir l'input "searchNavFormControl" */
    @ViewChild('focusInput') focusInput: ElementRef;

    constructor(
        public elem: ElementRef,
        private navSearchEndPointService: NavSearchEndPointService,
        private toasterService: ToasterService,
        private permissionsService: PermissionService,
        private router: Router
    ) {
        this.navSearchData = NavSearchData;
        this.NavSearchFormGroup = new FormGroup({
            searchNavFormControl: new FormControl('')
        });
        this.isLoading = false;
        this.isLoaded = false;
    }

    /** On définit ici les raccourcis clavier
     * ainsi que la souscription au "valueChanges" de l'input "searchNavFormControl"
     * ainsi que la préselection de la première entité en fonction des droits utilisateur
     */
    ngOnInit(): void {
        if (this.permissionsService.hasPermission([EnumPermissions.PROPERTY_ACCESS])) {
            this.preselected = enumSearchType.Establishment;
        } else if (this.permissionsService.hasPermission([EnumPermissions.METERINGPLAN_ACCESS])) {
            this.preselected = enumSearchType.MeteringPoint;
        }
        // else if (this.permissionsService.hasPermission([EnumPermissions.INVOICES_ACCESS])) {
        //     this.preselected = enumSearchType.Invoice;
        // }

        if (this.preselected !== undefined) {
            this.entitySelectionChange(this.preselected);

            this.NavSearchFormGroup.get('searchNavFormControl')
                .valueChanges.pipe(debounceTime(300))
                .subscribe(() => this.launchSearch());

            $(document)
                .on('keyup', event => {
                    if (event.keyCode === 27) {
                        this.closeNavSearch();
                    }
                })
                .on('click', event => {
                    if (
                        // Empêcher la fermeture de la recherche lors du clic sur un des éléments du form
                        !$.contains(this.elem.nativeElement, event.target) &&
                        // Empêcher la fermeture lors de la sélection d'un type d'entité
                        // d'un clic sur un group-option
                        // ou d'un clic pour fermer la mat-select box
                        event.target.parentElement &&
                        event.target.parentElement.id !== 'EntityGroup' &&
                        event.target.parentElement.parentElement &&
                        event.target.parentElement.parentElement.id !== 'EntityGroup' &&
                        event.target.className !== 'cdk-overlay-backdrop'
                    ) {
                        this.closeNavSearch();
                    }
                });
        }
    }

    /**
     * Gestion des résulats en fonction du terme de recherche
     */
    launchSearch(): void {
        this.isLoaded = false;
        this.isLoading = true;
        this.navSearchResult = new Array<NavSearchResult>();
        const searchCriterias: QuickSearchCriteriaDto = {
            searchType: this.currentSearchType,
            term: this.NavSearchFormGroup.get('searchNavFormControl').value
        };

        if (this.navSearchResultSubscription) {
            this.navSearchResultSubscription.unsubscribe();
        }

        if (searchCriterias.term) {
            this.navSearchResultSubscription = this.navSearchEndPointService
                .getNavSearchResults(searchCriterias)
                .subscribe(
                    data => {
                        this.navSearchResult = data;

                        if (data.length < 1) {
                            this.toasterService.showCustom(
                                enumToaster.warning,
                                'Modifiez votre recherche',
                                `Aucune correspondance n'a été trouvée`
                            );
                        }

                        if (this.visible) {
                            this.focusInput.nativeElement.focus();
                        } else {
                            this.toasterService.showCustom(
                                enumToaster.info,
                                'Ouvrez la recherche pour découvrir les résultats',
                                'Votre recherche est prête'
                            );
                        }

                        this.isLoaded = true;
                        this.isLoading = false;
                    },
                    error => {
                        if (error.status === 403) {
                            this.toasterService.showForbid();
                        } else {
                            this.toasterService.showError(
                                'Une erreur est survenue durant la récupération des résultats de recherche'
                            );
                        }
                        this.isLoading = false;
                    }
                );
        } else {
            this.isLoading = false;
        }
    }

    /**
     * Actions à effectuer lors du changement d'état des Inputs
     * @param changes SimpleChanges
     */
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.visible.currentValue) {
            this.focusInput.nativeElement.focus();
        }
    }

    /**
     * Methode appelée lors du focus/focusOut de l'input "searchNavFormControl"
     * @param status
     */
    focusFunction(status: boolean): void {
        // Sans ça, le routerLink n'est pas effectué (les résulats disparaissent avant de prendre en compte le clic)
        setTimeout(() => {
            this.showResults = status;
        }, 200);
    }

    /**
     * redirection a la page de modification
     * @param pathToEdit
     */
    navigateToEditPage(pathToEdit: string): void {
        this.router.navigate([pathToEdit]);
        this.closeNavSearch();
    }

    /**
     * redirection a la page de consultation
     * @param pathToView
     */
    navigateToViewPage(pathToView: string): void {
        this.router.navigate([pathToView]);
        this.closeNavSearch();
    }

    /** Actions effectuées lors de la fermeture de la pop-up */
    closeNavSearch(): void {
        this.visible = false;
        this.showResults = false;

        // vider le champs de recherche
        this.NavSearchFormGroup.get('searchNavFormControl').reset();
        this.onclose.emit();
    }

    /**
     * Actions lors du changement du type d'entité :
     * 1 - Modification de la variable "currentSearchType"
     * 2 - Changement du texte du "searchPlaceHolder"
     * 3 - Réactivation de l'input de recherche
     * 4 - Lancement de la recherche
     * @param entity nouveau type d'entité sélectionné
     */
    entitySelectionChange(entity: number): void {
        this.currentSearchType = entity;
        let searchPlaceHolderText = '';

        switch (this.currentSearchType) {
            case enumSearchType.Establishment:
                searchPlaceHolderText = `Nom, ID SI Tiers, Ville, adresse ou secteur personnalisé d'un établissement`;
                break;
            case enumSearchType.Building:
                searchPlaceHolderText = `Nom, ID SI Tiers, Ville, adresse ou secteur personnalisé d'un bâtiment`;
                break;
            case enumSearchType.Zone:
                searchPlaceHolderText = `Nom, ID SI Tiers, Ville, adresse ou secteur personnalisé d'une zone`;
                break;
            case enumSearchType.MeteringPoint:
                searchPlaceHolderText = `Libellé, ID Fournisseur ou fluide d'un point de comptage`;
                break;
            case enumSearchType.Meter:
                searchPlaceHolderText = `Libelle ou ID Fournisseur d'un compteur`;
                break;
            case enumSearchType.Dial:
                searchPlaceHolderText = `Libelle ou ID Fournisseur d'un cadran`;
                break;
            // case enumSearchType.Invoice:
            //     searchPlaceHolderText = `Numéro d'une facture ou le libellé, ID Fournisseur ou fluide d'un point de comptage associé`;
            //     break;
            // case enumSearchType.Contract:
            //     searchPlaceHolderText = `Numéro d'un contrat ou le libellé, ID Fournisseur ou fluide d'un point de comptage associé`;
            //     break;
            default:
                searchPlaceHolderText = `Ce type d'entité n'est pas géré`;
                this.toasterService.showCustom(enumToaster.error, searchPlaceHolderText, 'Oups');
                break;
        }

        this.searchPlaceHolder = searchPlaceHolderText;
        this.launchSearch();
    }
}
