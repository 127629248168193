import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MsalService } from '@azure/msal-angular';
import { AppService } from '@core/http/app.service';
import { I18nService } from '@core/internationalization/i18n.service';
import { ContractModel } from '@core/models/contract/contract.model';
import { EnumPermissions } from '@core/models/permissions/permissions.enum';
import { UserModel } from '@core/models/user/user.model';
import { environment } from '@environments/environment';
import { AccountSettingsComponent } from '@shared/account-settings/account-settings.component';
import { Subject } from 'rxjs';

@Component({
    selector: 'profile-menu',
    templateUrl: './profile-menu.component.html',
    styleUrls: ['./profile-menu.component.scss']
})
export class ProfileMenuComponent implements OnInit, OnDestroy {
    private _onDestroy = new Subject<void>();

    EnumPermissions = EnumPermissions;
    currentUser: UserModel;
    currentContract: ContractModel;

    logout(): void {
        this.authService.logout();
    }

    constructor(
        private appService: AppService,
        private authService: MsalService,
        public i18nService: I18nService,
        public dialog: MatDialog
    ) {}

    ngOnInit(): void {
        this.currentUser = this.appService.getUserContext().user;
        this.currentContract = this.appService.getUserContext().contract;
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    getDashboardRoleName(): string {
        return this.appService.getUserContext().role.name;
    }

    showLanguage(): boolean {
        return !environment.production;
    }

    openProfile(): void {
        this.dialog.open(AccountSettingsComponent, {
            width: '600px',
            height: '700px'
        });
    }
}
