//#region Imports
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ISection } from '@app/core/models/section/section.model';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { AppService } from '../app.service';
import { BaseService } from '../base.service';
//#endregion

@Injectable({
    providedIn: 'root'
})
export class SectionsService extends BaseService {
    //#region Fields
    private API_ENDPOINT = environment.API_ENDPOINT;
    private sectionsApiUrl = `${this.API_ENDPOINT}sections/`;
    //#endregion

    //#region Constructor
    constructor(private http: HttpClient, private appService: AppService) {
        super();
    }
    //#endregion

    //#region Methods
    public getSections(): Observable<Array<ISection>> {
        const contractId = this.appService.getUserContext().contract.id.toString();

        return this.http.get<Array<ISection>>(`${this.sectionsApiUrl}` + contractId);
    }
    //#endregion
}
