<mat-dialog-content>
  <div class="container">
    <kendo-tabstrip (tabSelect)="onTabSelect($event)" [tabPosition]="tabOrientation">
      <kendo-tabstrip-tab
        [title]="MENTIONS_TITLE"
        [selected]="indexSelected === 0"
      ></kendo-tabstrip-tab>
      <kendo-tabstrip-tab [title]="CGU_TITLE" [selected]="indexSelected === 1"></kendo-tabstrip-tab>
      <kendo-tabstrip-tab
        [title]="CHARTE_TITLE"
        [selected]="indexSelected === 2"
      ></kendo-tabstrip-tab>
    </kendo-tabstrip>
    <div *ngIf="indexSelected === 0" class="container-content">
      <p>VERTUOZ est éditée par la société <strong>SIRADEL</strong> (groupe ENGIE) :</p>
      <ul ps-4>
        <li><strong>Adresse</strong> : 2 Parc de Brocéliande, 35760 Saint-Grégoire, France</li>
        <li><strong>Immatriculation</strong> : RCS de Rennes, n° 444 062 624</li>
        <li><strong>Numéro de TVA intracommunautaire</strong> : FR 69 444 062 624</li>
        <li><strong>Directeur de la publication</strong> : Aurélien FATTORE</li>
        <li>
          <strong>Mail de contact</strong> :
          <a href="mailto:contact@siradel.com"><em>contact@siradel.com</em></a>
        </li>
      </ul>
      <p>
        La plateforme VERTUOZ est hébergée sur le cloud Microsoft Azure, région : Westeurope, data
        center situé aux Pays-Bas, pays de l'Union Européenne.
      </p>
    </div>
    <div *ngIf="indexSelected === 1" class="container-content">
      <h5 class="paragraph-title">ARTICLE 1 : PRÉAMBULE</h5>
      <p>
        Le service VERTUOZ, souscrit par le Client, offre aux Utilisateurs la possibilité d’accéder
        à diverses informations via la Plateforme VERTUOZ. Tout Utilisateur de cette plateforme
        VERTUOZ (ci-après la « Plateforme » ou le « Site ») doit impérativement prendre connaissance
        des présentes conditions générales d’utilisation (ci-après «CGU») qui définissent les règles
        de son utilisation, et les accepter sans réserve. La version française de ce document
        prévaut sur toute autre version.
      </p>
      <p>
        Dans les présentes, les mots ou expressions commençant avec une majuscule ont la
        signification suivante :
      </p>
      <ul>
        <li>
          « Compte » : compte d’accès et d’utilisation permettant à l’Utilisateur de se connecter à
          la Plateforme et d’accéder aux Services
        </li>
        <li>
          « Contenu(s) / Donnée(s) » : ensemble des données, et plus généralement des informations
          diffusées par SIRADEL à l’Utilisateur dans le cadre de la Plateforme, et notamment le
          contenu rédactionnel
        </li>
        <li>
          « Client » : entité ayant souscrit le Service VERTUOZ auprès de SIRADEL, ou ses ayants
          droits
        </li>
        <li>« Parties » : Le Client et SIRADEL</li>
        <li>
          « Service(s) » : désigne le ou les services accessibles sur la Plateforme. Pour pouvoir
          bénéficier des Services, l’Utilisateur doit disposer d’un compte d’accès à la Plateforme
          et valider les Conditions Générales d’Utilisation. L’Utilisateur autorise SIRADEL à
          récupérer les données collectées conformément au contrat qui les lie
        </li>
        <li>
          « Utilisateur » : toute personne physique habilitée par le Client pour bénéficier du
          Service, et qui a accepté les présentes CGU et qui dispose d’un Compte sur la Plateforme
          afin de pouvoir disposer des Service(s). L’utilisation des termes définis ci-avant au
          singulier ou au pluriel n’a pas d’influence sur leur signification
        </li>
      </ul>
      <h5 class="paragraph-title">ARTICLE 2 : OBJET</h5>
      <p>
        Les CGU ont pour objet de définir les conditions d’utilisation de la Plateforme. La
        Plateforme est ouverte à tout Utilisateur.
      </p>
      <h5 class="paragraph-title">ARTICLE 3 : ENTRÉE EN VIGUEUR</h5>
      <p>
        Les CGU entrent en vigueur à compter de leur acceptation par l’Utilisateur à la Plateforme.
      </p>
      <p>
        SIRADEL se réserve le droit de modifier à tout moment, de manière unilatérale, sans préavis
        et sans condition aucune les CGU, par la publication d’une nouvelle version. L’entrée en
        vigueur de toute modification est effective dès la mise en ligne sur le site des nouvelles
        CGU une fois acceptées par l’Utilisateur via la Plateforme. Néanmoins, il appartient à
        l’Utilisateur de les consulter régulièrement afin de prendre connaissance de la dernière
        version en vigueur. SIRADEL recommande à tout Utilisateur du Site de sauvegarder ou
        d’imprimer les CGU.
      </p>
      <h5 class="paragraph-title">ARTICLE 4 : DESCRIPTION DE LA PLATEFORME</h5>
      <p>
        La Plateforme permet de bénéficier des Services souscrit par le Client en vertu du contrat
        qui le lie à SIRADEL. Pour ce faire, SIRADEL recueille et traite les données collectées
        auprès du Client. Le descriptif des informations collectées et leur usage est décrit dans le
        contrat qui lie les Parties.
      </p>
      <p>
        Le Client autorise SIRADEL à traiter ces données collectées, et à les utiliser aux fins de
        fourniture du ou des Services.
      </p>
      <p>
        Le descriptif de la Plateforme et des Services proposés n’est pas limitatif et est
        susceptible d’être modifié notamment pour tenir compte de l’évolution de la technologie ou
        dans un objectif d’amélioration du Service rendu aux Utilisateurs.
      </p>
      <h5 class="paragraph-title">ARTICLE 5 : MOYENS D'ACCÈS À LA PLATEFORME</h5>
      <p>
        Afin de pouvoir accéder à la Plateforme, l’Utilisateur doit disposer de moyens de
        télécommunications lui permettant de se connecter à Internet. L’Utilisateur doit également
        disposer d’une adresse électronique. L’ensemble des coûts, frais, abonnements et taxes liés
        aux abonnements internet et/ou mobiles est à la charge exclusive de l’Utilisateur.
      </p>
      <h5 class="paragraph-title">ARTICLE 6 : OBLIGATION D'INSCRIPTION - OUVERTURE DE COMPTE</h5>
      <p>
        Pour pouvoir utiliser les Services, l’Utilisateur doit obligatoirement accepter expressément
        les CGU et la Charte « Protection des Données Personnelles de la plateforme »
      </p>
      <p>
        Un lien vers la Charte Protection des Données Personnelles de l’application permet à
        l’Utilisateur de prendre connaissance de ces documents et d’avoir accès à leur version en
        vigueur.
      </p>
      <h5 class="paragraph-title">
        ARTICLE 7 : DONNÉES D'INSCRIPTION, IDENTIFIANTS, MOT DE PASSE ET SÉCURITÉ
      </h5>
      <p>
        L’Utilisateur s’engage à prendre toutes mesures utiles pour assurer la parfaite
        confidentialité de ses Données Personnelles. La communication par l’Utilisateur à des tiers
        de ses Données Personnelles et, d’une manière générale, de toute information jugée par
        l’Utilisateur comme confidentielle, se fait à ses risques et périls.
      </p>
      <p>
        Il appartient à l’Utilisateur de prendre toutes les mesures appropriées de façon à protéger
        ses Données Personnelles et/ou ses propres logiciels de la contamination par d’éventuels
        virus sur le réseau Internet.
      </p>
      <p>
        L’Utilisateur s’engage à informer immédiatement SIRADEL de toute utilisation non autorisée
        de ses Données Personnelles, et plus généralement de toute atteinte à la sécurité dont il
        aurait connaissance. De même, l’Utilisateur veillera à se déconnecter de la Plateforme à
        l’issue de chaque session en utilisant le bouton ‘déconnexion’ prévu à cet effet.
      </p>
      <h5 class="paragraph-title">ARTICLE 8 : DROITS D'UTILISATION</h5>
      <p>
        L’Utilisateur s’engage à ne pas utiliser son Compte et/ou les informations fournies pour :
      </p>
      <ul>
        <li>
          - tenter d’induire en erreur d’autres utilisateurs et ce, en usurpant son nom, son mot de
          passe et/ou son numéro d’inscription à la Plateforme
        </li>
        <li>- contrefaire les entêtes des documents officiels de SIRADEL</li>
        <li>
          - manipuler de quelque manière que ce soit, les contenus et plus généralement les
          informations fournies sur la Plateforme de manière à dissimuler la source et l’origine des
          informations transmises sur la Plateforme
        </li>
        <li>
          - télécharger, afficher, transmettre par e-mail ou de quelque autre manière, tout contenu
          comportant un virus informatique ou tout code, dossier ou programme conçus pour
          interrompre, détruire ou limiter les fonctionnalités de tout logiciel, ordinateur ou outil
          de télécommunication sans que cette énumération ne soit limitative
        </li>
        <li>
          - commettre toute action ayant un effet perturbateur et/ou entravant les capacités de
          communication sur la Plateforme
        </li>
        <li>
          - entraver ou perturber la Plateforme, les serveurs, les réseaux connectés à la Plateforme
          ou refuser de se conformer aux conditions requises, aux procédures, aux règles générales
          et/ou aux dispositions réglementaires applicables au réseau connecté à la Plateforme
        </li>
      </ul>
      <h5 class="paragraph-title">
        ARTICLE 9 : DONNÉES UTILISATEURS ET DONNÉES A CARACTÈRE PERSONNEL
      </h5>
      <p>
        L’Utilisateur est propriétaire des Données relatives et propres à son/ses site(s) et
        accessibles sur la Plateforme. SIRADEL reste propriétaire de l’ensemble des autres Données
        figurant sur la Plateforme.
      </p>
      <p>
        La Charte d’utilisation et de protection des Données Personnelles détaille les modalités de
        traitement et de ces Données.
      </p>
      <h5 class="paragraph-title">ARTICLE 10 : CONTENU DE LA PLATEFORME</h5>
      <p>
        SIRADEL ou toute personne ou entité désignée par elle, sera en droit de supprimer tout
        Contenu personnalisé et/ou rédactionnel qui violerait les CGU et plus généralement les lois
        et règlements en vigueur.
      </p>
      <p>
        En cas de différence/incohérence entre les Données disponibles sur la Plateforme et celles
        figurant sur les documents reçus par l’Utilisateur (facture, bon de commande, diagnostic
        énergétique, etc.), seuls ces derniers documents feront foi et/ou preuve des opérations ou
        prestations réalisées.
      </p>
      <h5 class="paragraph-title">
        ARTICLE 11 : RÈGLES D'USAGE DE L'INTERNET – SÉCURITÉ DE LA PLATEFORME
      </h5>
      <p>
        L’Utilisateur déclare accepter les caractéristiques et les limites d’Internet et, en
        particulier, reconnaît :
      </p>
      <ul>
        <li>
          - avoir connaissance de la nature du réseau Internet et, en particulier, de ses
          performances techniques et des temps de réponse pour consulter, interroger ou transférer
          les données et informations
        </li>
        <li>
          - que les données circulant sur l'Internet ne sont pas nécessairement protégées, notamment
          contre les détournements éventuels
        </li>
        <li>
          - que les données et/ou informations circulant sur Internet peuvent être réglementées en
          termes d’usage ou être protégées par un droit de propriété
        </li>
      </ul>
      <p>De son côté, SIRADEL s’engage à :</p>
      <ul>
        <li>
          - sécuriser l'accès et l'utilisation de la Plateforme, en tenant compte des protocoles en
          vigueur, conformément aux usages de l'Internet
        </li>
        <li>
          - mettre en œuvre des mesures de sécurité et de confidentialité utiles des Données, et
          notamment, empêcher qu’elles soient altérées, endommagées, ou que des tiers non autorisés
          y aient accès
        </li>
        <li>
          - veiller à mettre en place les mesures préventives connues pour éviter les dangers
          potentiels (intrusions, failles de sécurité, virus, etc.) pouvant altérer le
          fonctionnement de la Plateforme
        </li>
        <li>
          - prendre immédiatement toutes mesures qui s’imposent pour protéger ou restaurer la
          sûreté, la sécurité ou l’intégrité du système et à corriger toutes erreurs
        </li>
      </ul>
      <p>
        Etant précisé qu’en l’état actuel, aucune technologie ne peut garantie l’inviolabilité des
        Données et de la Plateforme. En conséquence, SIRADEL n’a qu’une obligation de moyens en la
        matière.
      </p>

      <p>
        En cas d’acte malveillant, SIRADEL s’engage en informer les Utilisateurs, sous deux jours
        ouvrés à compter de sa prise de connaissance, et à mettre tout en oeuvre pour arrêter cette
        intrusion et minimiser les conséquences dommageables de cet évènement.
      </p>
      <h5 class="paragraph-title">ARTICLE 12 : HYPERLIENS</h5>
      <p>
        SIRADEL se réserve la possibilité de mettre en place sur la Plateforme des hyperliens
        donnant accès à des pages web autres que celles de SIRADEL. SIRADEL décline toute
        responsabilité quant au contenu des informations fournies sur ces sites au titre de
        l’activation de ces hyperliens.
      </p>
      <h5 class="paragraph-title">ARTICLE 13 : RÉSILIATION / SUSPENSION</h5>
      <p>
        SIRADEL se réserve le droit de suspendre, à tout moment, l’accès et l’utilisation de la
        Plateforme pour une durée limitée pour des raisons internes et/ou techniques, notamment pour
        permettre la maintenance de ses matériels et serveurs.
      </p>
      <p>
        En cas d’opération de maintenance de la Plateforme, ou en cas d’incident d’exploitation, un
        message avertit l’Utilisateur de la non-disponibilité de la Plateforme ou du Service.
        L’Utilisateur devra alors renouveler ultérieurement sa consultation.
      </p>
      <p>
        SIRADEL se réserve le droit de mettre fin à tout ou partie du droit d’accès au Compte de
        l’Utilisateur en raison d’impayés du Client, selon les modalités décrites au contrat qui lie
        les Parties.
      </p>
      <p>
        SIRADEL peut résilier de plein droit, sans formalité, ni mise en demeure le Compte, en cas
        de : violation des CGU ou violation des dispositions légales et réglementaires en vigueur.
      </p>
      <p>
        En cas de violation des CGU ou des dispositions légales et réglementaires, la résiliation
        sera immédiate.
      </p>
      <p>
        Toute résiliation au titre du présent article ne pourra donner lieu à aucune indemnité de
        quelque nature que ce soit.
      </p>
      <p>
        En outre, cette résiliation n’exclut pas les autres formes d’action - notamment judiciaires
        - que pourrait exercer SIRADEL à l’encontre de l’Utilisateur contrevenant pour faire valoir
        ses droits et/ou réparer son préjudice, notamment en cas de piratage.
      </p>
      <h5 class="paragraph-title">ARTICLE 14 : DROIT DE PROPRIÉTÉ INTELLECTUELLE DE SIRADEL</h5>
      <p>
        La Plateforme tant dans sa structure, son graphisme que son contenu, hormis les Données des
        Utilisateurs, appartient exclusivement à SIRADEL.
      </p>
      <p>
        Cette Plateforme et son contenu constituent une œuvre protégée par la législation française
        et Internationale sur le droit d'auteur et la propriété intellectuelle.
      </p>
      <p>
        Par conséquent, sauf autorisation écrite et préalable de SIRADEL, toute copie, reproduction,
        diffusion intégrale ou partielle du contenu de ce Site, quel que soit le procédé, est
        interdite, à l’exception d’une copie ou reproduction réservée à l’usage exclusivement privé
        du copiste.
      </p>
      <p>
        Il en est de même pour les différents logos, marques ou signes distinctifs qui apparaissent
        sur le Site, lesquels ne peuvent être utilisés sans l’autorisation écrite et préalable de
        SIRADEL.
      </p>
      <p>
        L’Utilisateur est informé que toute atteinte aux droits de propriété intellectuelle de
        SIRADEL constitue une contrefaçon susceptible de sanctions civiles et pénales.
      </p>
      <h5 class="paragraph-title">ARTICLE 15 : LIMITATION DE RESPONSABILITÉ</h5>
      <p>
        Compte tenu des contraintes techniques inhérentes aux sites web et au réseau Internet, la
        responsabilité de SIRADEL ne pourra être engagée du fait d’actes de tiers et notamment
        d’utilisateurs du réseau Internet qui contreviendraient aux dispositions des CGU.
      </p>
      <p>
        Il appartient à l’Utilisateur de prendre toutes les mesures appropriées de façon à protéger
        ses matériels, données et logiciels de la contamination par d’éventuels virus circulant sur
        le réseau Internet. Ainsi, l’Utilisateur reconnaît que SIRADEL ne saurait voir sa
        responsabilité engagée, notamment au titre de la contamination par virus des données et/ou
        logiciels de l’Utilisateur, dont la protection incombe à ce dernier, ou des intrusions
        malveillantes de tiers.
      </p>
      <p>
        De manière générale, SIRADEL est soumis, dans le cadre de l’exécution du contrat qui le lie
        au Client et pour le fonctionnement de la Plateforme, à une obligation de moyens.
      </p>
      <p>
        En tout état de cause, dans l’hypothèse où la responsabilité de SIRADEL serait engagée, les
        Parties se réfèreront aux dispositions du Contrat qui les lie.
      </p>
      <h5 class="paragraph-title">ARTICLE 16 : DIVERS</h5>
      <p>
        Les CGU expriment l’intégralité des obligations des Parties relatives à l’utilisation de la
        Plateforme.
      </p>
      <p>
        Les Parties reconnaissent et acceptent que le fait pour l’une d’elle de tolérer une
        situation, n’a pas pour effet d’accorder à l’autre Partie des droits acquis. Une telle
        tolérance ne peut être interprétée comme une renonciation pour faire valoir les droits en
        cause.
      </p>
      <p>
        Si une ou plusieurs stipulations des CGU sont tenues pour non-valides ou déclarées comme
        telle(s) en application d’une loi, d’un règlement ou à la suite d’une décision définitive
        d’une juridiction compétente, les autres stipulations garderont toute leur force et leur
        portée.
      </p>
      <p>Les CGU sont régies par la loi française.</p>
      <p>
        En cas de litige lié au fonctionnement de la Plateforme, les Parties feront application des
        modalités de règlement des différends prévues dans le contrat qui les lie.
      </p>
    </div>
    <div *ngIf="indexSelected === 2" class="container-content">
      <h5 class="paragraph-title">ARTICLE 1 : OBJET</h5>
      <p>
        La présente charte fait partie intégrante des Mentions légales et Conditions Générales
        d'Utilisation (CGU) de la plateforme Vertuoz, et a pour objet de définir les conditions dans
        lesquelles vos données personnelles sont collectées et traitées en respect des Loi(s) de
        Protection des Données Personnelles applicables et notamment la loi n°78-17 « Informatique
        et Libertés » du 6 janvier 1978 modifiée, ainsi que le règlement européen relatif à la
        protection des données à caractère personnel applicable à partir du 25 mai 2018.
      </p>
      <h5 class="paragraph-title">ARTICLE 2 : FINALITÉS DU TRAITEMENT</h5>
      <p>
        Les Services proposés sur la Plateforme utilisent les données récupérées à partir de
        fichiers (factures, relèves, ou télérelèves), ou à partir d’Objets Connectés de
        l’Utilisateur. L’Utilisateur reste propriétaire des données relatives et propres à son/ses
        site(s) et accessibles sur la Plateforme. SIRADEL reste propriétaire de l’ensemble des
        autres données figurant sur la Plateforme.
      </p>
      <p>
        Les données concernant les Utilisateurs, et collectées pour les besoins du Service, feront
        l'objet d'un traitement automatisé par SIRADEL pour assurer le bon fonctionnement du
        Service. Ces données sont réservées à l’usage du Service. La finalité de ce traitement est
        d'optimiser la consommation d'énergie et de proposer de nouveaux Services personnalisés à
        l'Utilisateur.
      </p>
      <p>
        Les Données Personnelles collectées lors de l’inscription sont nécessaires à SIRADEL,
        Responsable du Traitement, pour contacter l’Utilisateur et l’informer sur les Services
        accessibles sur la Plateforme.
      </p>
      <p>
        Par convention expresse, SIRADEL garde la faculté de conserver vers elle, de reproduire et
        d’exploiter une partie de ces données « anonymisées », ceci à des fins statistiques ou de
        modélisation, de formation de son personnel ou de ses prestataires.
      </p>
      <h5 class="paragraph-title">ARTICLE 3 : DÉFINITIONS</h5>
      <ul>
        <li>
          « Compte » : compte d’accès et d’utilisation permettant à l’Utilisateur de se connecter à
          la Plateforme et d’accéder aux Services
        </li>
        <li>
          « Cookie » : Fichier texte, stocké sur le Terminal de l'Utilisateur, permettant au Site de
          se souvenir de celui-ci au cours d'une même session ou d'une session à l'autre
        </li>
        <li>
          « Destinataire des données » : la personne physique ou morale, l'autorité publique, le
          service ou tout autre organisme qui reçoit communication de données à caractère personnel
        </li>
        <li>« Données Brutes » : données captées par l’Objet Connecté</li>
        <li>
          « Données Enrichies » : données ayant fait l’objet d’un traitement au niveau de la
          Plateforme à partir des Données Brutes
        </li>
        <li>
          « Donnée(s) Personnelle(s) » : toute information communiquée par et relative à
          l’Utilisateur de la Plateforme, le rendant identifiable directement ou indirectement
        </li>
        <li>« Client » : entité ayant souscrit le Service auprès de SIRADEL</li>
        <li>
          « Loi(s) de Protection des Données Personnelles » : désigne jusqu’au 24 mai 2018, la
          Directive 95/46/CE du Parlement européen et du Conseil du 24 Octobre 1995, et à partir du
          24 mai 2018, Règlement (UE) 2016/679 du Parlement et du Conseil du 27 avril 2016 relatif à
          la protection des personnes physiques à l’égard du traitement des Données Personnelles et
          à la libre circulation de ces données (le « Règlement européen » ou « Règlement 2016/976
          ») ; ainsi que toute législation ou règlementation relative à la protection des Données
          Personnelles applicable aux Traitements effectués
        </li>
        <li>
          « Objet(s) Connecté(s) » : La liste des Objets Connectés qui peuvent être reliés à un
          Compte n’est pas exhaustive et peut être modifiée par SIRADEL sans préavis et sans
          condition aucune
        </li>
        <li>
          « Plateforme » : plateforme Vertuoz où l’ensemble des données venant de l’Objet Connecté
          sont centralisés et traitées par nos algorithmes
        </li>
        <li>
          « Responsable du traitement »: la personne physique ou morale, l'autorité publique, le
          service ou un autre organisme qui, seul ou conjointement avec d'autres, détermine les
          finalités et les moyens du traitement
        </li>
        <li>
          « Service(s) »: désigne le ou les Services accessibles sur la Plateforme. Pour pouvoir
          bénéficier du Service, l’Utilisateur doit disposer d’un Compte d’accès à la Plateforme et
          valider les Conditions Générales d’utilisation
        </li>
        <li>
          « Site web/application web » : site web /application mobile de la plateforme Vertuoz
          Habitat+
        </li>
        <li>
          « Sous-traitant » : la personne physique ou morale, l'autorité publique, le service ou un
          autre organisme qui traite des données à caractère personnel pour le compte du Responsable
          du traitement
        </li>
        <li>
          « Terminal » : désigne l’équipement (ordinateur, smartphone, tablette) utilisé par
          l'Utilisateur pour accéder au Service
        </li>
        <li>
          « Utilisateur » : toute personne physique habilité par le Client et qui a accepté les CGU
          afin de pouvoir disposer de Service(s)
        </li>
      </ul>
      <h5 class="paragraph-title">ARTICLE 4 : DONNÉES COLLECTÉES</h5>
      <h6 class="paragraph-title">ARTICLE 4.1 : NATURE DES DONNÉES COLLECTÉES</h6>
      <p>
        Lors de votre inscription au service, SIRADEL collecte votre adresse e-mail et votre mot de
        passe. SIRADEL sera également amené à collecter les données rattachées à votre Terminal et
        recueillies au fur et à mesure de votre accès et utilisation du Service (notamment l’adresse
        IP, le type de navigateur...).
      </p>
      <p>
        La plate-forme collectera l'ensemble des données captées par l'Objet Connecté ou via des
        fichiers de données nécessaires au bon fonctionnement du Service.
      </p>
      <p>
        S’agissant des données des Utilisateurs, SIRADEL s’engage, sauf autorisation écrite, à :
      </p>
      <ul>
        <li>- ne pas divulguer à des tiers lesdites données</li>
        <li>
          - n’utiliser et à ne traiter les données collectées, que dans un but en rapport avec le
          Service effectué au profit des Utilisateurs ou proposé à ces derniers
        </li>
      </ul>
      <h6 class="paragraph-title">ARTICLE 4.2 : TRAITEMENT DES DONNÉES PERSONNELLES</h6>
      <p>
        SIRADEL et l’Utilisateur s’engagent à respecter, chacune pour ce qui la concerne, les
        obligations auxquelles elles sont chacune tenues en matière de protection de ces Données
        Personnelles, en particulier :
      </p>
      <ul>
        <li>
          - des exigences du Règlement européen n°2016-679 du 27/04/2016 relatif à la protection des
          personnes physiques à l’égard du traitement des données à caractère personnel et à la
          libre circulation de ces données, (« RGPD »)
        </li>
        <li>- de la loi n°78-17 du 6 janvier 1978 modifiée et de ses textes d’application</li>
      </ul>
      <p>
        S’agissant du traitement de ces Données personnelles, le Client agit en qualité de
        Responsable de Traitement desdites Données Personnelles et SIRADEL agit pour le compte du
        Client en seule qualité de Sous-traitant, conformément au contrat qui les lie.
      </p>
      <h6 class="paragraph-title">ARTICLE 4.3 : MODALITÉS DE LA COLLECTE DE DONNÉES</h6>
      <p>
        Le caractère obligatoire ou facultatif des Données Personnelles recueillies est indiqué dans
        les formulaires de collecte. Les Données Personnelles sont collectées directement auprès de
        l'Utilisateur lors de l'inscription ou via les informations fournies par le Client ou par le
        gestionnaire de son logement. L'Utilisateur s’engage à fournir des informations exactes,
        complètes et à les modifier, les corriger et les mettre à jour si nécessaire.
      </p>
      <h5 class="paragraph-title">ARTICLE 5 : STOCKAGE ET DURÉE DE CONSERVATION DES DONNÉES</h5>
      <p>
        Les Données Personnelles sont stockées par Microsoft AZURE dont les serveurs se situent en
        Europe.
      </p>
      <p>
        Les Données Personnelles collectées sont conservées pendant la durée du contrat souscrit par
        le Client, puis pendant une durée de 5 ans à l’expiration de ce contrat.
      </p>
      <p>
        Au-delà, SIRADEL s’engage à détruire ces Données, étant rappelé que SIRADEL garde la faculté
        de conserver vers elle et d’exploiter une partie de ces données « anonymisées », comme
        énoncé plus haut en article 2.
      </p>
      <h5 class="paragraph-title">ARTICLE 6 : SÉCURITÉ ET CONFIDENTIALITÉ</h5>
      <p>
        Il est recommandé à l’Utilisateur de choisir un mot de passe présentant une certaine
        complexité.
      </p>
      <p>
        Il est préconisé de ne pas utiliser un mot de passe identique pour plusieurs comptes en
        ligne et de le changer régulièrement.
      </p>
      <h5 class="paragraph-title">
        ARTICLE 7 : EXERCICE DES DROITS DE LA PERSONNE (ACCÈS, RECTIFICATION ET SUPPRESSION)
      </h5>
      <p>
        Conformément aux Loi(s) de Protection des Données Personnelles applicables, vous disposez
        d'un droit d'accès, de rectification et de suppression ainsi que de portabilité des Données
        Personnelles vous concernant.
      </p>
      <p>
        Vous pouvez également à tout moment retirer votre consentement concernant l’utilisation de
        vos Données et désapparier le Compte de l’Objet Connecté.
      </p>
      <p>
        Vous pouvez exercer l’ensemble de ces droits en vous adressant à :
        <a href="mailto:privacy-vertuoz@engie.com"><em>privacy-vertuoz@engie.com</em></a
        >.
      </p>
      <h5 class="paragraph-title">ARTICLE 8 : POLITIQUE D'UTILISATION DES COOKIES</h5>
      <p>
        Lors de votre accès sur ce Site, un Cookie de session peut être installé automatiquement et
        être conservé temporairement en mémoire sur le disque dur de l’Utilisateur afin de faciliter
        la navigation. Une fois votre session fermée, le cookie sera détruit.
      </p>
      <p>
        Vous avez la possibilité, si vous le souhaitez, de vous opposer à l’enregistrement de ces
        cookies de session en configurant en conséquence votre navigateur. Cette opération est de
        nature à modifier éventuellement, voire à rendre difficile votre navigation sur la
        Plateforme.
      </p>
      <p>
        Par ailleurs, pour analyser vos préférences et sous réserve de votre consentement, des
        pixels invisibles ou balises internet sont susceptibles d’être placés par un prestataire
        tiers sur certains de nos articles et messages pour nous aider à identifier les pages
        consultées, comptabiliser les fréquences d’affichage ainsi que le nombre de visiteurs,
        analyser les structures du trafic et établir des statistiques pour les contenus les plus
        appréciés.
      </p>
      <p>
        La démarche à suivre pour refuser l’insertion de ces cookies « tiers » est la suivante,
        selon les modalités proposées vous convenant le mieux :
      </p>
      <p>Pour Mozilla firefox :</p>
      <ul>
        <li>1. Choisissez le menu « outil » puis « options »</li>
        <li>2. Cliquez sur l’icône « vie privée »</li>
        <li>
          3. Repérez le menu « cookie » et sélectionnez les options qui vous conviennent (<a
            href="http://support.mozilla.org/fr/kb/activer-desactiver-cookies?redirectlocale=fr&redirectslug=Activer+et+d%C3%A9sactiver+les+cookies"
            target="_blank"
            >Guide</a
          >)
        </li>
      </ul>
      <p>Pour Microsoft Internet Explorer :</p>
      <ul>
        <li>1. Choisissez le menu « outil » puis « options Internet »</li>
        <li>2. Cliquez sur l’icône « Confidentialité »</li>
        <li>
          3. Sélectionnez le niveau souhaité à l’aide du curseur (<a
            href="http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies"
            target="_blank"
            >Guide</a
          >)
        </li>
      </ul>
      <p>Pour Chrome :</p>
      <ul>
        <li>1. Allez dans le menu Chrome / Préférences</li>
        <li>2. Cliquez sur « Afficher les paramètres avancés »</li>
        <li>3. Cliquez sur « Paramètres de contenu... » dans la partie confidentialité</li>
        <li>4. Cliquez sur « Cookies et données de site... » dans la nouvelle fenêtre</li>
        <li>
          5. Cliquez sur « Tout supprimer » et validez en cliquant sur « OK » (<a
            href="https://support.google.com/chrome/answer/95647?hl=fr&hlrm=en"
            target="_blank"
            >Guide</a
          >)
        </li>
      </ul>
      <p>Pour Safari :</p>
      <ul>
        <li>1. Allez dans le menu Safari / Préférences</li>
        <li>2. Cliquez sur « confidentialité »</li>
        <li>
          3. Dans « Bloquer les cookies », cochez « Toujours » (<a
            href="http://www.apple.com/support/?path=Safari/3.0/fr/9277.html"
            target="_blank"
            >Guide</a
          >)
        </li>
      </ul>
      <p>
        Pour connaître les options offertes par tout autre logiciel de navigation et les modalités
        de suppression de fichiers cookies stockés dans votre Terminal, la Cnil détaille sur ce lien
        les démarches à suivre pour limiter vos traces sur le web
        <a href="https://www.cnil.fr/fr/comment-ca-marche" target="_blank"
          >https://www.cnil.fr/fr/comment-ca-marche</a
        >
        et, plus généralement, vous informe sur les mécanismes de paramétrage des cookies
        <a href="http://www.cnil.fr/vos-droits/vos-traces/les-cookies/#c5554" target="_blank"
          >http://www.cnil.fr/vos-droits/vos-traces/les-cookies/#c5554</a
        >.
      </p>
      <p>
        Si vous poursuivez votre navigation, vous consentez à l’utilisation de ces cookies « tiers »
        sur notre site. Vous pourrez à tout moment modifier votre choix le cas échéant.
      </p>
      <h5 class="paragraph-title">ARTICLE 9 : ACCEPTATION ET MODIFICATION</h5>
      <p>
        Vous acceptez sans réserve la présente charte en cochant la case
        <em
          >« J'ai lu et j’accepte les termes et conditions de l'utilisation du service et la charte
          des données personnelles. »</em
        >
        située en bas du formulaire d’inscription au Service.
      </p>
      <p>
        Cette charte peut être modifiée par SIRADEL préavis et sans condition aucune. L'Utilisateur
        est invité à en prendre régulièrement connaissance en suivant le lien "Mentions Légales".
      </p>
    </div>
  </div>
</mat-dialog-content>
