import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'padNumberWithLeadingZeros'
})
export class PadNumberPipe implements PipeTransform {
    constructor() {}
    /**
     *
     * @param value la valeur à formater
     * @param size taille définie pour un nombre
     */
    transform(value: number, size: number) {
        let s = value + '';
        while (s.length < size) {
            s = '0' + s;
        }
        return s;
    }
}
