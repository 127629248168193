import { KeyValue } from '@angular/common';

export enum PerimeterLevel {
    // No level = Root level = All included
    WithoutLevel = -4,
    // Geography
    Nation = -3,
    Region = -2,
    County = -1,
    Town = 0,
    // Property
    Establishment = 1,
    Building = 2,
    Zone = 3,
    // Public space
    District = 11,
    Street = 12,
    Cabinet = 13
}

export enum ObjectiveCategory {
    Total = 1,
    Fluids = 2,
    Usages = 3,
    Third_Party = 4
}

export enum ObjectiveType {
    Manual = 1,
    TertiaryDecree = 2,
    Third_Party = 3
}

export enum ObjectiveThreshold {
    // 1 value for each temporel step
    Fixed = 1,
    // 1 value for each temporel step over period
    Variable = 2
}

export enum ObjectiveAnalysedMeasureEnum {
    RawConsumption = 1,
    Percent = 2
}

export enum ObjectiveDataSource {
    Input = 1,
    ReferenceYear = 2
}

export enum ObjectiveCalculationMethod {
    None = 1,
    Percent = 2
}

export enum ObjectiveInputType {
    DataSource = 1,
    Percent = 2
}

// Export the wording

export const PerimeterLevelRSX: Array<KeyValue<PerimeterLevel, string>> = [
    { key: PerimeterLevel.Town, value: 'perimeter-level.town' },
    { key: PerimeterLevel.Establishment, value: 'perimeter-level.establishment' },
    { key: PerimeterLevel.Building, value: 'perimeter-level.buidling' },
    { key: PerimeterLevel.Zone, value: 'perimeter-level.zone' },
    { key: PerimeterLevel.District, value: 'perimeter-level.district' },
    { key: PerimeterLevel.Street, value: 'perimeter-level.street' },
    { key: PerimeterLevel.Cabinet, value: 'perimeter-level.cabinet' }
];

export const CategoryRSX: Array<KeyValue<ObjectiveCategory, string>> = [
    { key: ObjectiveCategory.Total, value: 'objective.category.total' },
    { key: ObjectiveCategory.Fluids, value: 'objective.category.fluids' },
    { key: ObjectiveCategory.Usages, value: 'objective.category.usages' },
    { key: ObjectiveCategory.Third_Party, value: 'objective.category.third-party' }
];

export const ThresholdRSX: Array<KeyValue<ObjectiveThreshold, string>> = [
    { key: ObjectiveThreshold.Fixed, value: 'objective.treshold.fixed' },
    { key: ObjectiveThreshold.Variable, value: 'objective.treshold.variable' }
];

export const AnalysedMeasureRSX: Array<KeyValue<ObjectiveAnalysedMeasureEnum, string>> = [
    { key: ObjectiveAnalysedMeasureEnum.Percent, value: 'objective.analysed-measure.percent' },
    {
        key: ObjectiveAnalysedMeasureEnum.RawConsumption,
        value: 'objective.analysed-measure.raw-consumption'
    }
];

export const DataSourceRSX: Array<KeyValue<ObjectiveDataSource, string>> = [
    { key: ObjectiveDataSource.Input, value: 'objective.data-source.input' },
    { key: ObjectiveDataSource.ReferenceYear, value: 'objective.data-source.reference-year' }
];

export const CalculatedMethodRSX: Array<KeyValue<ObjectiveCalculationMethod, string>> = [
    { key: ObjectiveCalculationMethod.None, value: 'objective.calculated-method.none' },
    { key: ObjectiveCalculationMethod.Percent, value: 'objective.calculated-method.percent' }
];

export const InputTypeRSX: Array<KeyValue<ObjectiveInputType, string>> = [
    { key: ObjectiveInputType.DataSource, value: 'objective.input-type.data-source' },
    { key: ObjectiveInputType.Percent, value: 'objective.input-type.percent' }
];
