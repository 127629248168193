import { Label, LabelColor } from '../../common/label.model';
export class RawDistributionModel {
    meteringPointId: number;
    startDate: Date;
    endDate: Date;
    item: Label; // Zone ou Armoire
    parentItem: Label;
    itemPercent: number; // Pourcentage répartition Zone ou 100% si Armoire
    usage: LabelColor;
    usagePercent: Number;
    referenceClientTiers: string;
}
