import { Directive, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

/** Directive en attendant l'update de la directive RouterLink d'Angular
 * ISSUE : https://github.com/angular/angular/pull/24697
 */
@Directive({
    selector: '[routerExternalLink]'
})
export class RouterExternalLinkDirective implements OnInit {
    /** url */
    @Input() routerExternalLink: string;

    constructor(private router: Router) {}

    ngOnInit() {}

    @HostListener('click', ['$event'])
    clicked() {
        if (!this.routerExternalLink) {
            return;
        }

        if (this.routerExternalLink.indexOf('http') === 0) {
            this.router.navigate(['/externalRedirect', { externalUrl: this.routerExternalLink }], {
                skipLocationChange: true
            });
        } else {
            this.router.navigate([this.routerExternalLink]);
        }
    }
}
