<div>
  <div #anchor class="w-fit">
    <ng-content select="vertuoz-confirm-dialog-button-content"> </ng-content>
  </div>
</div>
<kendo-popup
  [anchor]="anchor"
  [collision]="collision"
  [anchorAlign]="anchorAlign"
  [popupAlign]="popupAlign"
  *ngIf="editPanelIsOpen"
  (anchorViewportLeave)="editPanelIsOpen = false"
>
  <div class="p-3" [ngStyle]="{ width: width }">
    <ng-content select="vertuoz-confirm-dialog-popup-content"> </ng-content>
    <div class="d-flex pt-2">
      <button type="button" mat-button class="btn-sm">Annuler</button>
      <div>
        <button
          type="button"
          color="primary"
          mat-flat-button
          [ngClass]="{ red: isWarningMode, blue: !isWarningMode }"
          class="btn-sm ms-4"
          (click)="OnValidate()"
          (mouseleave)="stopCountLeave()"
          (mouseup)="stopCountDown()"
          (mousedown)="startCountDown()"
        >
          {{ actionName }}
          <div class="progress-wrap">
            <mat-progress-bar
              [color]="isWarningMode ? 'warn' : ''"
              [value]="counter"
              [ngStyle]="{ visibility: counter > 0 ? 'visible' : 'hidden' }"
            >
            </mat-progress-bar>
          </div>
        </button>
      </div>
    </div>
  </div>
</kendo-popup>
