import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AzureStorageService {
    API_ENDPOINT: string = environment.API_ENDPOINT;
    controller = 'AzureStorage/';
    public URL_EndPoint = `${this.API_ENDPOINT}${this.controller}`;

    constructor(private http: HttpClient) {}

    GetFile(file): Observable<HttpResponse<Blob>> {
        const temp = this.http.get(`${this.URL_EndPoint}GetAzureStorageFile/${file}`, {
            responseType: 'blob',
            observe: 'response'
        });
        return temp;
    }
}

export enum AzureStorageFile {
    templateInitFirst = 1,
    templateInitPdc = 2,
    templateInitFacture = 3,
    templateInitReleve = 4,
    noticeInit = 5,
    templateInitZoneBat = 6,
    templateInitSpacePublic = 7,
    templateInitEFA = 8,
    noticeInitEFA = 9
}
