//#region Imports
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { ToasterService } from '@vertuoz/vertuoz-library';
import { from, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { AppService } from '@app/core/http/app.service';
import { GroupsService } from '@app/core/http/perimeter/groups.service';
import { Label } from '@app/core/models/common/label.model';
import {
    GroupPerimeterModel,
    PatchGroupsDto
} from '@app/core/models/perimeters/group/group-perimeter.model';
import { GroupFilter } from '@app/core/models/perimeters/group/group.filter';
import { UserContext } from '@app/core/models/user/user.context';
import { EnumPerimeterLevels } from '@app/features/perimeter/common/perimeter-levels.enum';
import { PagedContext } from '@app/features/shared/models/paged-context';
import { CustomDialogComponent } from '@app/shared/bulk-action-snackbar/custom-dialog/custom-dialog.component';
import { DomainEnum } from '@app/shared/constants/domain.enum';
import { Operation } from '@app/shared/constants/operation.enum';
//#endregion

//#region Types

//#endregion

@Component({
    selector: 'group-management',
    templateUrl: './group-management.component.html',
    styleUrls: ['./group-management.component.scss']
})
export class GroupManagementComponent implements OnInit, OnDestroy {
    //#region Inputs/Output
    @Input() perimeterLevel: EnumPerimeterLevels;
    @Input() originY: 'center' | 'bottom' | 'top' = 'top';
    @Input() originX: 'center' | 'start' | 'end' = 'center';
    @Input() overlayX: 'center' | 'start' | 'end' = 'center';
    @Input() overlayY: 'center' | 'bottom' | 'top' = 'bottom';

    @ViewChild('customDialogAdd') public customDialogAdd: CustomDialogComponent;
    @ViewChild('customDialogRemove') public customDialogRemove: CustomDialogComponent;

    @Input()
    set selectedIds(value: number[]) {
        this._selectedIds = value;
        this.setAddGroups();
        this.setRemoveGroups();
    }
    //#endregion

    //#region Properties
    loadingText = '';
    isGroupsLoading = false;
    isAddPanelShowed = false;
    isRemovePanelShowed = false;

    operation = Operation;
    addToGroupsFormGroup: FormGroup;
    removeFromGroupsFormGroup: FormGroup;

    allGroups: GroupPerimeterModel[] = [];
    addGroups: GroupPerimeterModel[] = [];
    removeGroups: GroupPerimeterModel[] = [];

    private _onDestroy = new Subject<void>();
    private _selectedIds: number[] = [];
    private authenticatedUserContext: UserContext;

    @ViewChild('scrollDiv') scrollElement: ElementRef;
    //#endregion

    //#region Constructor
    constructor(
        private appService: AppService,
        private formBuilder: FormBuilder,
        private groupsService: GroupsService,
        private toasterService: ToasterService
    ) {}
    //#endregion

    //#region Methods
    ngOnInit(): void {
        this.authenticatedUserContext = this.appService.getUserContext();
    }

    //#region FormGroups
    public toggleAddGroup(): void {
        this.isAddPanelShowed = true;
        this.isRemovePanelShowed = false;
        this.getGroupsByLevel(Operation.ADD);
    }

    public toggleRemoveGroup(): void {
        this.isRemovePanelShowed = true;
        this.isAddPanelShowed = false;
        this.getGroupsByLevel(Operation.REMOVE);
    }

    public getGroupsByLevel(operation: Operation): void {
        this.loadingText = 'Chargement des groupes...';
        this.isGroupsLoading = true;
        this.groupsService
            .getGroupsDetails(
                '',
                <GroupFilter>{ filterbyLevelId: +this.perimeterLevel },
                new PagedContext()
            )
            .subscribe(
                result => {
                    if (result) {
                        this.allGroups = [...result.results];
                    }

                    if (operation === Operation.ADD) {
                        this.setAddGroups();
                    } else {
                        this.setRemoveGroups();
                    }
                    this.initAddToGroupsForm();

                    this.isGroupsLoading = false;
                },
                () => {
                    this.isGroupsLoading = false;
                }
            );
    }

    private setAddGroups(): void {
        this.addGroups = [];
        this.allGroups.forEach(grp => {
            if (grp.perimeters) {
                const perimetrIds = grp.perimeters.map(p => p.itemId);
                this._selectedIds.forEach(selectedId => {
                    if (!perimetrIds.includes(selectedId) && this.addGroups.indexOf(grp) === -1) {
                        this.addGroups.push(grp);
                    }
                });
            } else if (this.addGroups.indexOf(grp) === -1) {
                this.addGroups.push(grp);
            }
        });
    }

    private setRemoveGroups(): void {
        this.removeGroups = [];
        this.allGroups.forEach(grp => {
            if (grp.perimeters) {
                const perimetrIds = grp.perimeters.map(p => p.itemId);
                perimetrIds.forEach(perimetrId => {
                    if (
                        this._selectedIds.includes(perimetrId) &&
                        this.removeGroups.indexOf(grp) === -1
                    ) {
                        this.removeGroups.push(grp);
                    }
                });
            }
        });

        this.initRemoveFromGroupsForm();
    }

    private initAddToGroupsForm(): void {
        const controls = this.addGroups.map(c => new FormControl(false));

        this.addToGroupsFormGroup = this.formBuilder.group({
            selectedGroups: new FormArray(controls),
            newGroupName: new FormControl('', null, this.checkGroupNameExists.bind(this)),
            isNewGroup: new FormControl(false, Validators.required)
        });

        this.addToGroupsFormGroup.controls.isNewGroup.valueChanges.subscribe(newValue => {
            if (newValue) {
                this.addToGroupsFormGroup.controls.newGroupName.setValidators(Validators.required);
                this.addToGroupsFormGroup.controls.newGroupName.updateValueAndValidity();
            } else {
                this.addToGroupsFormGroup.controls.newGroupName.setValidators([]);
                this.addToGroupsFormGroup.controls.newGroupName.updateValueAndValidity();
            }
        });

        this.addToGroupsFormGroup.controls.newGroupName.valueChanges.subscribe(newValue => {
            if (
                newValue &&
                newValue.length > 0 &&
                !this.addToGroupsFormGroup.controls.isNewGroup.value
            ) {
                this.addToGroupsFormGroup.controls.isNewGroup.setValue(true);
            }
        });
    }

    private initRemoveFromGroupsForm(): void {
        const controls = this.removeGroups.map(c => new FormControl(false));

        this.removeFromGroupsFormGroup = this.formBuilder.group({
            selectedGroups: new FormArray(controls)
        });
    }

    public checkGroupNameExists(input: FormControl): Observable<{}> {
        if (
            !input ||
            !input.value ||
            input.value.trim().length < 1 ||
            !this.addToGroupsFormGroup ||
            !this.addToGroupsFormGroup.controls.newGroupName.value
        ) {
            return from<string[]>(['']).pipe(takeUntil(this._onDestroy));
        }
        return this.groupsService.getGroups(input.value.trim(), new GroupFilter({}), null).pipe(
            takeUntil(this._onDestroy),
            map(result => {
                if (!result) {
                    return null;
                }
                return result.results.length > 0 ? { nameExists: true } : null;
            })
        );
    }
    //#endregion

    //#region Toggles Buttons
    public onAddToGroupsToggleClick(): void {
        this.isRemovePanelShowed = false;
        this.isAddPanelShowed = !this.isAddPanelShowed;
    }

    public onRemoveFromGroupsToggleClick(): void {
        this.isAddPanelShowed = false;
        this.isRemovePanelShowed = !this.isRemovePanelShowed;
    }
    //#endregion

    //#region Popup's actions
    public onAddGroup(event: MouseEvent): void {
        event.stopPropagation();
        this.loadingText = 'Mise à jour des groupes sélectionnés en cours...';
        if (this.addToGroupsFormGroup.controls.isNewGroup.value) {
            this.createGroup();
        }
        this.updateSelectedGroups();
    }

    private createGroup(): void {
        this.isGroupsLoading = true;
        const groupName = this.addToGroupsFormGroup.controls.newGroupName.value;

        const perimeters = [];
        this._selectedIds.forEach(p => {
            perimeters.push({
                itemId: p,
                perimeterLevel: this.perimeterLevel,
                itemLabel: null
            });
        });

        const newGroupModel: GroupPerimeterModel = <GroupPerimeterModel>{
            name: groupName,
            perimeters: perimeters,
            domain: DomainEnum.Property,
            levelId: this.perimeterLevel,
            userId: this.authenticatedUserContext.user.userId,
            labelUsers: [
                <Label>{
                    id: this.authenticatedUserContext.user.userId
                }
            ]
        };

        this.groupsService
            .addGroup(newGroupModel)
            .pipe(takeUntil(this._onDestroy))
            .subscribe(
                result => {
                    this.toasterService.showSuccess(
                        'Le groupe ' +
                            this.getPerimeterLevelName() +
                            ' ' +
                            groupName +
                            ' est bien ajouté.'
                    );
                    this.isGroupsLoading = false;
                    this.isAddPanelShowed = false;
                    this.customDialogAdd.closePanel();
                    this.customDialogRemove.closePanel();
                },
                error => {
                    this.toasterService.showError(`Erreur lors de la création du groupe.`);
                    this.isGroupsLoading = false;
                }
            );
    }

    private getPerimeterLevelName(): string {
        switch (this.perimeterLevel) {
            case EnumPerimeterLevels.BUILDING:
                return 'de bâtiments';
            case EnumPerimeterLevels.ZONE:
                return 'de zones';
            case EnumPerimeterLevels.STREET:
                return 'de rues';
            case EnumPerimeterLevels.CABINET:
                return "d'armoires";
            default:
                return "d'éléments";
        }
    }

    private updateSelectedGroups(): void {
        const selectedGroupsIds = this.addToGroupsFormGroup.value.selectedGroups
            .map((v, i) => (v ? this.addGroups[i].groupPerimeterId : null))
            .filter(v => v !== null);

        this.patchSelectedGroups(selectedGroupsIds, true);
    }

    public onRemoveGroup(event: MouseEvent): void {
        this.loadingText = 'Mise à jour des groupes sélectionnés en cours...';
        event.stopPropagation();
        const selectedGroupsIds: number[] = this.removeFromGroupsFormGroup.value.selectedGroups
            .map((v, i) => (v ? this.removeGroups[i].groupPerimeterId : null))
            .filter(v => v !== null);

        this.patchSelectedGroups(selectedGroupsIds, false);
    }

    private patchSelectedGroups(selectedGroupsIds: number[], hasToAddGroups: boolean): void {
        this.isGroupsLoading = true;

        const patchGroupsDto: PatchGroupsDto = <PatchGroupsDto>{
            groupsIds: selectedGroupsIds,
            hasToAddGroups: hasToAddGroups,
            perimeterItemsIds: this._selectedIds
        };

        this.groupsService
            .updateGroupsContents(patchGroupsDto)
            .pipe(takeUntil(this._onDestroy))
            .subscribe(
                result => {
                    this.toasterService.showSuccess(this.getAddMessage(hasToAddGroups));
                    this.isGroupsLoading = false;
                    this.isAddPanelShowed = false;
                    this.isRemovePanelShowed = false;
                    this.customDialogAdd.closePanel();
                    this.customDialogRemove.closePanel();
                },
                error => {
                    this.toasterService.showError(`Erreur lors de la modification du groupe.`);
                    this.isGroupsLoading = false;
                }
            );
    }

    private getAddMessage(isAddMode: boolean): string {
        switch (this.perimeterLevel) {
            case EnumPerimeterLevels.BUILDING:
                return isAddMode
                    ? 'Les bâtiments sélectionnés sont bien ajoutés au(x) groupe(s) sélectionné(s).'
                    : 'Les bâtiments sont retirés de(s) groupe(s) sélectionné(s).';
            case EnumPerimeterLevels.ZONE:
                return isAddMode
                    ? 'Les zones sélectionnées sont bien ajoutées au(x) groupe(s) sélectionné(s).'
                    : 'Les zones sont retirées de(s) groupe(s) sélectionné(s).';
            case EnumPerimeterLevels.STREET:
                return isAddMode
                    ? 'Les rues sélectionnées sont bien ajoutées au(x) groupe(s) sélectionné(s)'
                    : 'Les rues sont retirées de(s) groupe(s) sélectionné(s).';
            case EnumPerimeterLevels.CABINET:
                return isAddMode
                    ? 'Les armoires sélectionnées sont bien ajoutées au(x) groupe(s) sélectionné(s).'
                    : 'Les armoires sont retirées de(s) groupe(s) sélectionné(s).';
            default:
                return isAddMode
                    ? 'Les éléments sélectionnés sont bien ajoutés au(x) groupe(s) sélectionné(s).'
                    : 'Les éléments sont retirés de(s) groupe(s) sélectionné(s).';
        }
    }
    //#endregion

    public ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }
    //#endregion
}
