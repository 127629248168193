<div
  *ngIf="loading !== undefined && !loading && cid !== undefined"
  [hidden]="noDial"
  id="{{ cid }}"
  class="m-2 mx-auto mh-500px"
  (mousemove)="onMouseMove($event)"
  (mouseleave)="onMouseLeave($event)"
></div>

<vertuoz-loader *ngIf="loading" [size]="'tall'"> </vertuoz-loader>

<ng-container *ngIf="!loading && noDial">
  <div class="empty-data d-flex align-items-center justify-content-center p-4">
    <p class="text-center m-0">
      Le périmètre géographique actif en cours ne contient aucun cadran de puissance, <br />
      nous vous conseillons de changer de niveau sur votre périmètre géographique actif.
    </p>
  </div>
</ng-container>
