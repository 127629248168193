import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { enumToaster, ToasterService } from '@vertuoz/vertuoz-library';
import { of, throwError, Observable } from 'rxjs';
import { concatMap, delay, retryWhen } from 'rxjs/operators';

import { HttpEnum } from '../constants/http.enum';

export const retryCount = 3;
export const retryWaitMilliSeconds = 1000;

@Injectable()
export class MonitorInterceptor implements HttpInterceptor {
    private retryAfterSecond: number;

    constructor(private toasterService: ToasterService) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            retryWhen(error =>
                error.pipe(
                    concatMap((err, count) => {
                        if (err.status === HttpEnum.TooManyRequest) {
                            if (err.error) {
                                const msg = err.error;
                                this.retryAfterSecond = msg.retryAfter;
                            }

                            if (count <= retryCount && err.status === HttpEnum.TooManyRequest) {
                                return of(err);
                            }
                            this.toasterService.showCustom(
                                enumToaster.warning,
                                'Une saturation du système entraîne un ralentissement de Vertuoz. <br>Veuillez renouveler votre requête dans quelques instants.',
                                'Erreur',
                                { enableHtml: true }
                            );
                            return throwError(() => err);
                        } else {
                            // For other errors, it throws directly the error
                            return throwError(() => err);
                        }
                    }),
                    delay(retryWaitMilliSeconds * this.retryAfterSecond)
                )
            )
        );
    }
}
