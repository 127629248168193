<input
  type="file"
  style="display: none"
  accept="{{ acceptedFileType }}"
  (change)="onFileSelected($event)"
  #fileUpload
/>
<div class="row">
  <div class="col-12">
    <div class="row align-items-center" *ngFor="let linkedFile of _linkedFiles; let i = index">
      <div class="col">
        <mat-icon [svgIcon]="linkedFile.fileSvgIcon"></mat-icon>
      </div>
      <div class="col-6">
        <span class="overflow-wrap">
          {{ linkedFile.fileName }}
        </span>
      </div>
      <div class="col">{{ linkedFile.kiloBytesSize }} Ko</div>
      <div class="col">
        <button mat-icon-button type="button">
          <mat-icon matTooltip="Supprimer" (click)="delete(i)">delete</mat-icon>
        </button>
      </div>
    </div>
    <div class="row align-items-center mt-2">
      <div class="col-3">
        <button
          type="button"
          [disabled]="_linkedFiles.length >= totalMaxCountFile"
          mat-flat-button
          color="primary"
          (click)="fileUpload.click()"
        >
          Déposer un fichier
        </button>
      </div>
      <div *ngIf="">
        {{ errorSizeTotalMessage }}
      </div>
    </div>
  </div>
</div>
