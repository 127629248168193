<nav
  aria-label="Type de graphique"
  class="graphToolbar d-flex flex-wrap justify-content-center align-items-center"
>
  <ul class="d-flex align-items-center">
    <li class="me-3" *ngIf="showSort">
      <button
        mat-icon-button
        class="btn-conso color-primary-700"
        (click)="onSort()"
        matTooltip="Tri croissant/décroissant"
      >
        <mat-icon svgIcon="{{ sort === 'desc' ? 'sort_descending' : 'sort_ascending' }}">
        </mat-icon>
      </button>
    </li>
    <li class="me-3" *ngIf="showTypeChart">
      <button
        mat-icon-button
        class="btn-conso color-primary-700"
        (click)="switchChartType()"
        matTooltip="Type de graphe"
      >
        <mat-icon *ngIf="areaType === 'column' && stacked === false" svgIcon="chart_bar"></mat-icon>
        <mat-icon
          *ngIf="areaType === 'column' && stacked === true"
          svgIcon="chart_stacked"
        ></mat-icon>
        <mat-icon *ngIf="areaType === 'line'">timeline</mat-icon>
        <mat-icon *ngIf="areaType === 'scatter'">scatter_plot</mat-icon>
        <mat-icon *ngIf="areaType === 'bar'" svgIcon="chart_bar_h"></mat-icon>
      </button>
    </li>
    <li class="me-3" *ngIf="showCumul">
      <button
        id="btnCumul"
        class="mat-icon-button btn-conso"
        (click)="onCumulChanged()"
        matTooltip="{{ getCustomCumulativeTooltip() }}"
        [ngClass]="{ 'color-primary-700': isCumul }"
      >
        <mat-icon svgIcon="chart_accrued"></mat-icon>
      </button>
    </li>
  </ul>
  <div class="me-3" *ngIf="showFluidUse">
    <mat-button-toggle-group>
      <mat-button-toggle
        mat-flat-button
        class="btn-sm btn-flat border-0"
        [hidden]="hideFluid"
        [checked]="selectedFluidUse === fluidUseEnum.Fluid"
        (click)="onFluidUsageChanged(fluidUseEnum.Fluid)"
        >{{
          (overridenFluid ? overridenFluid : 'chart-graphic-option.fluid') | translate
        }}</mat-button-toggle
      >
      <mat-button-toggle
        mat-flat-button
        class="btn-sm btn-flat"
        [hidden]="hideUse"
        [checked]="selectedFluidUse === fluidUseEnum.Use"
        (click)="onFluidUsageChanged(fluidUseEnum.Use)"
        >{{ 'chart-graphic-option.use' | translate }}</mat-button-toggle
      >
      <mat-button-toggle
        mat-flat-button
        class="btn-sm btn-flat"
        [checked]="selectedFluidUse === fluidUseEnum.None"
        (click)="onFluidUsageChanged(fluidUseEnum.None)"
        >{{ 'chart-graphic-option.total' | translate }}</mat-button-toggle
      >
    </mat-button-toggle-group>
  </div>
  <div class="me-3" *ngIf="showProduction">
    <mat-button-toggle-group multiple (change)="toggleProduction($event)">
      <mat-button-toggle mat-flat-button class="btn-sm btn-flat border-0" value="0">{{
        'chart-graphic-option.production-type' | translate
      }}</mat-button-toggle>
      <mat-button-toggle mat-flat-button class="btn-sm btn-flat border-0" value="1">{{
        'chart-graphic-option.production-use' | translate
      }}</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <ul class="d-flex align-items-center">
    <li class="me-3" [style.visibility]="'visible'" *ngIf="showConservationTemperature">
      <button
        mat-icon-button
        [matMenuTriggerFor]="conservTempMenu"
        [ngClass]="{
          'color-primary-700': selectedBuilding && selectedBuilding.buildingId !== 0
        }"
        matTooltip="{{ 'property.caracteristic.conservation-temperature' | translate }}"
      >
        <mat-icon svgIcon="thermostat"></mat-icon>
      </button>
      <mat-menu #conservTempMenu="matMenu">
        <div class="px-4 py-2">
          <p>
            <label>{{ 'chart-graphic-option.select-building' | translate }} :</label>
          </p>
          <mat-radio-group (change)="buildingsChange($event.value)">
            <ul>
              <li *ngFor="let building of buildings">
                <mat-radio-button
                  [value]="building"
                  [checked]="building.buildingId === selectedBuilding?.buildingId"
                >
                  <div [innerHTML]="building.buildingLabel"></div>
                </mat-radio-button>
              </li>
            </ul>
          </mat-radio-group>
        </div>
      </mat-menu>

      <custom-dialog
        [isPanelShowed]="showMissingConservationTemperatureMessage"
        *ngIf="numberOfBuildingsWithoutConservationTemperature"
        (togglePanel)="
          showMissingConservationTemperatureMessage = !showMissingConservationTemperatureMessage
        "
        [closeOnScroll]="true"
        [useOverlay]="true"
      >
        <mat-icon-button-content>
          <mat-icon class="color-warning" matTooltip="{{ 'common.missing-data' | translate }}">
            warning
          </mat-icon>
        </mat-icon-button-content>
        <dialog-content>
          <div class="dialog__content m-2">
            {{
              'measurement-and-sensors.text.number-of-buildings-without-conservation-temperature'
                | translate
                  : {
                      count: numberOfBuildingsWithoutConservationTemperature,
                      element:
                        'geoMenu.levels.buildings'
                        | translate: { count: numberOfBuildingsWithoutConservationTemperature }
                        | lowercase
                    }
            }}
          </div>
        </dialog-content>
        <dialog-buttons>
          <div class="dialog__button text-center m-2">
            <button
              mat-stroked-button
              color="primary"
              class="btn-sm"
              (click)="showMissingConservationTemperatureMessage = false"
            >
              {{ 'common.action.close' | translate }}
            </button>
            <button
              mat-flat-button
              color="primary"
              class="btn-sm ms-2"
              (click)="
                showMissingConservationTemperatureMessage = false; goToBuildingGridPage(true)
              "
            >
              {{
                'common.action.acces-to-list'
                  | translate: { element: 'geoMenu.levels.buildings' | translate: { count: 2 } }
              }}
            </button>
          </div>
        </dialog-buttons>
      </custom-dialog>
    </li>
    <li class="me-5" *ngIf="showUnit">
      <mat-form-field id="unit-field" class="p-0">
        <mat-select
          (selectionChange)="unitSelectionChange($event.value)"
          [(value)]="selectedUnit"
          class="position-absolute"
        >
          <ng-container>
            <mat-option *ngFor="let unit of units" [value]="unit"
              ><span [innerHTML]="unit.display"></span>
            </mat-option>
          </ng-container>
        </mat-select>
        <div class="mt-n3" matTooltip="Unités de l'axe des ordonnées (y)">
          <button class="mat-icon-button btn-conso color-primary-700">
            <mat-icon svgIcon="square_ruler_y"></mat-icon>
          </button>
        </div>
      </mat-form-field>
    </li>
    <li class="me-2" [style.visibility]="'visible'" *ngIf="showDju">
      <button
        class="mat-icon-button btn-conso"
        [ngClass]="{ 'color-primary-700': isDjuApplied }"
        (click)="openPopupDju()"
        matTooltip="Courbe des DJU"
        #btnDju
      >
        <mat-icon svgIcon="dju"></mat-icon>
      </button>
      <kendo-popup
        [anchor]="btnDju"
        (anchorViewportLeave)="showPopupDju = false"
        [style.visibility]="showPopupDju ? 'visible' : 'hidden'"
        #popupDju
        class="w-250px"
      >
        <div class="px-2 pt-2 pb-1">
          <div class="row m-0">
            <div class="col-12 pb-3"><label>Selectionner une station météo :</label></div>
            <div class="col-12 pb-3">
              <mat-radio-group (change)="weatherStationsChange($event.value)">
                <mat-radio-button
                  class="d-block"
                  *ngFor="let weatherStation of weatherStations"
                  [value]="weatherStation"
                  [checked]="
                    weatherStation.idStationMeteo === selectedWeatherStations?.idStationMeteo
                  "
                >
                  <div [innerHTML]="weatherStation.libCourtStationMeteo"></div>
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </div>
      </kendo-popup>
    </li>
    <li class="me-5" [style.visibility]="'visible'" *ngIf="showRatio">
      <mat-form-field id="ratio-field">
        <mat-select
          (selectionChange)="ratioSelectionChange($event.value)"
          [(value)]="selectedRatio"
        >
          <ng-container>
            <mat-option *ngFor="let ratio of ratios" [value]="ratio"
              ><span [innerHTML]="ratio.display"></span>
            </mat-option>
          </ng-container>
        </mat-select>

        <button
          mat-icon-button
          class="btn-conso position-absolute mt-n3"
          matTooltip="Ratios"
          [ngClass]="{ 'color-primary-700': isRatioApplied }"
        >
          <mat-icon svgIcon="ratio"></mat-icon>
        </button>
      </mat-form-field>
    </li>
    <li class="me-5" *ngIf="showSecondUnits">
      <mat-form-field id="sunit-field">
        <mat-select
          (selectionChange)="sUnitSelectionChange($event.value)"
          [(value)]="selectedSecondUnit"
        >
          <ng-container>
            <mat-option *ngFor="let unit of secondUnits" [value]="unit"
              ><span [innerHTML]="unit.name"></span>
            </mat-option>
          </ng-container>
        </mat-select>
        <button
          mat-icon-button
          class="position-absolute color-primary-700 top-07rem"
          matTooltip="Eau"
        >
          <mat-icon svgIcon="water"></mat-icon>
        </button>
      </mat-form-field>
    </li>
    <li *ngFor="let odometer of odometersModel">
      <vertuoz-odometer [odometerModel]="odometer"></vertuoz-odometer>
    </li>
    <li class="me-3" *ngIf="showSerieItemLabel">
      <button
        type="button"
        mat-icon-button
        (click)="onShowSerieItemLabelChanged()"
        matTooltip="Afficher les valeurs / Masquer les valeurs"
        [ngClass]="{ 'color-primary-700': isSerieItemLabelShowed }"
      >
        <mat-icon>query_stats</mat-icon>
      </button>
    </li>
  </ul>
</nav>
