import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpParams,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import * as moment from 'moment';
import 'reflect-metadata';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class CastDateInterceptor implements HttpInterceptor {
    // Migrated from AngularJS https://raw.githubusercontent.com/Ins87/angular-date-interceptor/master/src/angular-date-interceptor.js
    iso8601 = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/;

    iso8601IsUtc = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?Z$/;
    constructor() {}

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Ajuste les dates renseigner dans le body de la requete
        this.ajustToLocalDate(req.body);

        // Ajuste les date renseigner dans les paramatres url de la requete
        const cloneReq = req.clone({ params: this.ajustToLocalDateParams(req.params) });

        return next.handle(cloneReq).pipe(
            tap(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                        const body = event.body;
                        // Converti en date les propriétés du body JSON qui correspondent à la norme ISO8601
                        this.convertToDate(body);
                    }
                },
                err => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status === 401) {
                        }
                    }
                }
            )
        );
    }

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    convertToDate(body) {
        if (body === null || body === undefined) {
            return body;
        }

        if (typeof body !== 'object') {
            return body;
        }

        for (const key of Object.keys(body)) {
            const value = body[key];
            if (this.isIso8601(value)) {
                body[key] = new Date(value);
                if (this.isUtc(value)) {
                    Reflect.defineMetadata('dateUtc', 'DateEvent', body[key]);
                }
            } else if (typeof value === 'object') {
                this.convertToDate(value);
            }
        }
    }

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    ajustToLocalDate(data) {
        if (data === null || data === undefined) {
            return data;
        }

        if (typeof data !== 'object') {
            return data;
        }

        for (const key of Object.keys(data)) {
            const value = data[key];
            if (value instanceof Date) {
                const t = Reflect.getMetadata('dateUtc', value);
                if (t) {
                } else {
                    data[key] = moment(value).format('YYYY-MM-DD[T]HH:mm:ss.SSS');
                }
            } else if (value instanceof moment) {
                const momentValue = moment(value);
                data[key] = momentValue.format('YYYY-MM-DD[T]HH:mm:ss.SSS');
            } else if (this.isIso8601(value)) {
                const currentDate = new Date(value);
                data[key] = moment(currentDate).format('YYYY-MM-DD[T]HH:mm:ss.SSS');
            } else if (typeof value === 'object') {
                this.ajustToLocalDate(value);
            }
        }
    }

    ajustToLocalDateParams(params: HttpParams): HttpParams {
        if (params === null || params === undefined) {
            return params;
        }

        if (typeof params !== 'object') {
            return params;
        }

        for (const key of params.keys()) {
            const queryStringDate = params.get(key);
            if (this.isIso8601(queryStringDate)) {
                const currentDate = new Date(queryStringDate);
                params = params.delete(key);
                params = params.set(key, moment(currentDate).format('YYYY-MM-DD[T]HH:mm:ss.SSS'));
            }
        }
        return params;
    }

    isIso8601(value): boolean {
        if (value === null || value === undefined) {
            return false;
        }

        return this.iso8601.test(value);
    }

    isUtc(value): boolean {
        if (value === null || value === undefined) {
            return false;
        }

        return this.iso8601IsUtc.test(value);
    }
}
