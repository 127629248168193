import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ReleaseNotesService } from '@core/http/releaseNotes/releaseNotes.service';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DialogMentionsLegalesData, DialogReleaseNotesData } from './dialog-data.model';
import { MentionsLegalesComponent } from './mentions-legales/mentions-legales.component';
import { ReleaseNotesComponent } from './release-notes/release-notes.component';

@Component({
    selector: 'vz-footer',
    templateUrl: './vertuoz-footer.component.html',
    styleUrls: ['./vertuoz-footer.component.scss']
})
export class VertuozFooterComponent implements OnInit {
    /** Nom du produit */
    @Input() public product: string;
    /** Version du produit */
    @Input() public version: string;
    /** Orientation du tabstrip MentionsLegalesComponent */
    @Input() tabOrientation: string;
    /** Contenu HTML pour la Release Notes */
    public releaseNotesHtmlUri: SafeResourceUrl;

    public yearCurrent = new Date().getFullYear();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public releaseNotesDialogRef: MatDialogRef<ReleaseNotesComponent, any>;

    constructor(
        private releaseNotesService: ReleaseNotesService,
        private domSanitizer: DomSanitizer,
        public dialog: MatDialog
    ) {}

    ngOnInit(): void {
        // get current version from localStorage
        const currentVersion = localStorage.getItem('version');
        // load releaseNotes
        this.releaseNotesService.getReleaseNotes().subscribe(uri => {
            this.releaseNotesHtmlUri = this.domSanitizer.bypassSecurityTrustResourceUrl(uri);
            // check if update
            if (!currentVersion || currentVersion !== this.version) {
                // open releaseNote modal & update current version in localStorage
                this.openReleaseNotes();
                localStorage.setItem('version', this.version);
            }
        });
    }

    openMentionsLegales(): void {
        const dialogData: DialogMentionsLegalesData = { tabOrientation: this.tabOrientation };

        this.dialog.open(MentionsLegalesComponent, {
            width: '70%',
            height: '700px',
            data: dialogData
        });
    }

    openReleaseNotes(): void {
        const dialogData: DialogReleaseNotesData = { htmlUri: this.releaseNotesHtmlUri };

        this.releaseNotesDialogRef = this.dialog.open(ReleaseNotesComponent, {
            width: '70%',
            height: '700px',
            data: dialogData
        });
    }
}
