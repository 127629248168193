import { FocusMonitor } from '@angular/cdk/a11y';
import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { Router } from '@angular/router';

import { BuildingPageState } from '@app/core/store/state/property/property-module-state.interface';
import { setBuildingPageState } from '@app/core/store/state/property/property.action';
import {
    Ratios,
    UnitsWithEnum,
    WeatherStation
} from '@app/features/consumption-monitoring/pages/fluids-water-view/model/chart-model';
import { FluidUseEnum } from '@app/features/consumption-monitoring/pages/fluids-water-view/model/endpoint-models';
import { BuildingWithConservationTemperature } from '@app/features/measure/models/models';
import { EnumPropertyRoute } from '@app/features/perimeter/property/models/enum-property-route';
import { PerimeterScope } from '@app/shared/constants/filters.enum';
import { ProductionEnum } from '@app/shared/constants/production.enum';
import { RatioEnum, UnitsEnum } from '@app/shared/constants/property.enum';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { OdometerModel } from './odometer/odometer/odometer.model';

@Component({
    selector: 'chart-graphic-options',
    templateUrl: './chart-graphic-options.component.html',
    styleUrls: ['./chart-graphic-options.component.scss']
})
export class ChartGraphicOptionsComponent implements OnInit, OnChanges {
    //#region les inputs

    // type de graphe
    @Input() areaType: 'line' | 'column' | 'column-stacked' | 'scatter' | 'bar';

    @Input() availableTypes: string[];

    // à vrai empilé
    @Input() stacked = false;

    // à vrai, afficher le bouton des DJU
    @Input() showDju = true;

    // à vrai, afficher le bouton affichage en cumulé
    @Input() showCumul = true;

    // à vrai, afficher le bouton de changement de types des charts
    @Input() showTypeChart = true;

    // A vrai, afficher le bouton de tri des élements du graphe
    @Input() showSort = false;
    @Input() defaultSort: 'asc' | 'desc';

    // à vrai, afficher les boutons Fluide/Usage
    @Input() showFluidUse = true;

    @Input() hideFluid = false;
    @Input() hideUse = false;

    @Input() overridenFluid: string = null;

    // à vrai, afficher le switch entre Type de production et utilisation
    @Input() showProduction = false;

    // à vrai, afficher le bouton des températures de consigne
    @Input() showConservationTemperature = false;

    // nombre de batiment sans température de consigne
    @Input() numberOfBuildingsWithoutConservationTemperature: number;

    // à vrai, afficher le bouton de units
    @Input() showUnit = true;

    // à vrai, afficher le bouton de ratios
    @Input() showRatio = true;

    // La liste des stations météos
    @Input() weatherStations: Array<WeatherStation> = [];

    // La station de météo sélectionnée par défaut
    @Input() defaultWeatherStation: number;

    @Input() buildings: Array<BuildingWithConservationTemperature> = [];

    // La liste des unités
    @Input() units: Array<UnitsWithEnum> = [];

    // Unité sélectionné par défaut
    @Input() defaultUnit: UnitsEnum = null;

    // à vrai, afficher le bouton de deuxième axe d'units
    @Input() showSecondUnits = false;

    // La liste des unités
    @Input() secondUnits: Array<UnitsWithEnum> = [];

    // Unité sélectionné par défaut
    @Input() defaultSecondUnit: UnitsEnum = null;

    // La liste des ratios
    @Input() ratios: Array<Ratios> = [];

    // Ratio sélectionné par défaut
    // @Input() defaultRatio: Ratios; // obsolete => to delete
    @Input() defaultRatio: RatioEnum;

    // affichage par default
    @Input() selectedFluidUse: FluidUseEnum = FluidUseEnum.Fluid;

    // Liste des KPIs
    @Input() odometersModel: Array<OdometerModel> = [];

    @Input() customCumulativeTooltip: string;

    @Input() isCumulated = false;

    @Input() showSerieItemLabel = false;
    //#endregion

    //#region les outputs

    // Evenement déclenché lors de switch entre fluide et usage
    @Output() useOrFluidChanged = new EventEmitter<FluidUseEnum>();

    // Evenement déclenché lors de switch entre type de production et utilisation
    @Output() productionChanged = new EventEmitter<ProductionEnum>();

    // Evenement déclenché lors de changement de selection de station météo
    @Output() weatherStationChanged = new EventEmitter<WeatherStation>();

    // Evenement déclenché lors de changement de selection d'unité
    @Output() unitChanged = new EventEmitter<UnitsWithEnum>();

    // Evenement déclenché lors de changement de selection d'unité pour le deuxième axe
    @Output() sUnitChanged = new EventEmitter<UnitsWithEnum>();

    // Evenement déclenché lors de changement de selection ratio
    @Output() ratioChanged = new EventEmitter<Ratios>();

    // Evenement déclenché lors de changement de selection ratio
    @Output() cumulChanged = new EventEmitter<boolean>();

    // Evenement déclenché lors de changement de selection ratio
    @Output() chartTypeChanged = new EventEmitter();

    // Evenement déclenché lors de changement de selection température de consigne
    @Output() buildingChanged = new EventEmitter<BuildingWithConservationTemperature>();

    @Output() sortChanged = new EventEmitter<string>();

    @Output() serieItemLabelShowChanged = new EventEmitter<boolean>();
    //#endregion

    //#region Enum
    fluidUseEnum = FluidUseEnum;
    productionEnum = ProductionEnum;
    //#endregion

    //#region Properties
    showPopupDju = false;
    showPopupConservationTemperature = false;
    showPopupUnit = false;
    showPopupRatio = false;
    showMissingConservationTemperatureMessage = false;
    selectedWeatherStations: WeatherStation = null;
    @Input() selectedBuilding: BuildingWithConservationTemperature;
    isCumul = false;
    isRatioApplied = false;
    isDjuApplied = false;
    isSerieItemLabelShowed = false;
    pathListBuildings = `/${EnumPropertyRoute.path}/${EnumPropertyRoute.listBuilding}`;
    sort: 'asc' | 'desc' = 'desc';
    selectedProduction: ProductionEnum;
    //#endregion

    //#region kendo popups

    //#region popups properties
    @ViewChild('btnDju') public btnDju: ElementRef;
    @ViewChild('popupDju', { read: ElementRef }) public popupDju: ElementRef;

    @ViewChild('btnTemperature') public btnTemperature: ElementRef;
    @ViewChild('popupConservationTemperature', { read: ElementRef })
    public popupConservationTemperature: ElementRef;
    selectedUnit: UnitsWithEnum;
    selectedSecondUnit: UnitsWithEnum;
    @Input() selectedRatio: Ratios;
    //#endregion

    //#region event popups
    @HostListener('document:click', ['$event'])
    public documentClick(event: MouseEvent): void {
        if (!this.containsDju(event.target)) {
            this.showPopupDju = false;
        }
        if (!this.containsTemperature(event.target)) {
            this.showPopupConservationTemperature = false;
        }
    }

    containsDju(target: EventTarget): boolean {
        return (
            (this.btnDju && this.btnDju.nativeElement.contains(target)) ||
            (this.popupDju ? this.popupDju.nativeElement.contains(target) : false)
        );
    }

    containsTemperature(target: EventTarget): boolean {
        return (
            (this.btnTemperature && this.btnTemperature.nativeElement.contains(target)) ||
            (this.popupConservationTemperature
                ? this.popupConservationTemperature.nativeElement.contains(target)
                : false)
        );
    }

    //#endregion

    //#endregion

    constructor(
        private translateService: TranslateService,
        private _focusMonitor: FocusMonitor,
        private store: Store,
        private router: Router
    ) {}

    ngOnInit(): void {
        if (!this.selectedBuilding) {
            this.selectedBuilding = <BuildingWithConservationTemperature>{ buildingId: 0 };
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['defaultSort'] && changes['defaultSort'].currentValue) {
            this.sort = changes['defaultSort'].currentValue;
        }

        if (changes['ratios'] && changes['ratios'].currentValue) {
            const ratios = changes['ratios'].currentValue as Ratios[];
            if (ratios.findIndex(r => r === this.selectedRatio) === -1) {
                this.selectedRatio =
                    changes['ratios'].currentValue.length === 1
                        ? changes['ratios'].currentValue[0]
                        : undefined;
            }
            this.isRatioApplied =
                this.selectedRatio !== undefined && this.selectedRatio.enum != null;
        }

        if (changes['units'] && changes['units'].currentValue) {
            if (this.defaultUnit) {
                this.selectedUnit = changes['units'].currentValue.find(
                    u => u.enum === this.defaultUnit
                );
            } else {
                this.selectedUnit = changes['units'].currentValue[0];
            }
        }

        if (changes['defaultUnit'] && changes['defaultUnit'].currentValue && this.units) {
            this.selectedUnit = this.units.find(
                u => u.enum === changes['defaultUnit'].currentValue
            );
        }

        if (changes['secondUnits'] && changes['secondUnits'].currentValue) {
            if (this.defaultSecondUnit) {
                this.selectedSecondUnit = changes['secondUnits'].currentValue.find(
                    u => u.enum === changes['defaultUnit'].currentValue
                );
            }
        }

        if (
            changes['defaultSecondUnit'] &&
            changes['defaultSecondUnit'].currentValue &&
            this.secondUnits
        ) {
            this.selectedSecondUnit = this.secondUnits.find(u => u.enum === this.defaultSecondUnit);
        }

        if (changes['defaultRatio'] && changes['defaultRatio'].currentValue) {
            if (changes['defaultRatio'].currentValue.ratioEnum != null) {
                this.selectedRatio = this.ratios.find(
                    r => r.ratioEnum === changes['defaultRatio'].currentValue.ratioEnum
                );
            } else {
                this.selectedRatio = this.ratios.find(
                    r => r.enum === changes['defaultRatio'].currentValue.enum
                );
            }

            this.isRatioApplied =
                this.selectedRatio !== null &&
                (this.selectedRatio.enum != null || this.selectedRatio.ratioEnum != null);
        }

        if (changes['weatherStations'] && changes['weatherStations'].currentValue) {
            const stations = changes['weatherStations'].currentValue as WeatherStation[];
            if (
                this.selectedWeatherStations &&
                stations.findIndex(
                    s => this.selectedWeatherStations.idStationMeteo === s.idStationMeteo
                ) === -1
            ) {
                this.selectedWeatherStations = stations.length > 0 ? stations[0] : null;
                this.isDjuApplied = false;
            } else if (this.defaultWeatherStation != null) {
                const station = changes['weatherStations'].currentValue.find(
                    s => this.defaultWeatherStation === s.idStationMeteo
                );
                this.selectedWeatherStations = station;
                this.isDjuApplied = station != null && station.idStationMeteo != null;
            }
        }

        if (
            changes['defaultWeatherStation'] &&
            changes['defaultWeatherStation'].currentValue &&
            this.weatherStations
        ) {
            const station = this.weatherStations.find(
                s => changes['defaultWeatherStation'].currentValue === s.idStationMeteo
            );
            this.selectedWeatherStations = station;
            this.isDjuApplied = station != null && station.idStationMeteo !== 0;
        }

        if (changes['hideUse']) {
            if (changes['hideUse'].currentValue === true) {
                this.selectedFluidUse = !this.hideFluid ? FluidUseEnum.Fluid : FluidUseEnum.None;
            } else {
                // Fluids are the first then uses
                this.selectedFluidUse = !this.hideFluid ? FluidUseEnum.Fluid : FluidUseEnum.Use;
            }
        }

        if (changes['hideFluid']) {
            if (changes['hideFluid'].currentValue) {
                this.selectedFluidUse = !this.hideUse ? FluidUseEnum.Use : FluidUseEnum.None;
            } else {
                this.selectedFluidUse = FluidUseEnum.Fluid;
            }
        }
        // adapt to selected graph view type changes
        if (changes['selectedFluidUse']) {
            if (changes['selectedFluidUse'].currentValue) {
                this.selectedFluidUse = changes['selectedFluidUse'].currentValue;
            }
        }

        if (changes['isCumulated']) {
            this.isCumul = changes['isCumulated'].currentValue;
        }
    }

    switchChartType(): void {
        this.chartTypeChanged.emit();
    }

    onCumulChanged(): void {
        this.isCumul = !this.isCumul;
        this.cumulChanged.emit(this.isCumul);
        this._focusMonitor.stopMonitoring(document.getElementById('btnCumul')); // prevent the class .cdk-focused
    }

    openPopupDju(): void {
        this.showPopupDju = !this.showPopupDju;
    }

    openPopupUnit(): void {
        this.showPopupUnit = !this.showPopupUnit;
    }

    openPopupRatio(): void {
        this.showPopupRatio = !this.showPopupRatio;
    }

    ratioSelectionChange(ratio: Ratios): void {
        this.isRatioApplied = +ratio.enum !== 0;
        this.ratioChanged.emit(ratio);
    }

    unitSelectionChange(unit: UnitsWithEnum): void {
        this.unitChanged.emit(unit);
    }

    sUnitSelectionChange(unit: UnitsWithEnum): void {
        this.sUnitChanged.emit(unit);
    }

    buildingsChange(b: BuildingWithConservationTemperature): void {
        this.buildingChanged.emit(b);
        this.selectedBuilding = b;
        this.showPopupConservationTemperature = false;
    }

    weatherStationsChange(wS: WeatherStation): void {
        this.selectedWeatherStations = wS;
        this.weatherStationChanged.emit(wS);
        this.showPopupDju = false;
        this.isDjuApplied =
            this.selectedWeatherStations !== null &&
            this.selectedWeatherStations.idStationMeteo !== 0;
    }

    onFluidUsageChanged(fOrU: FluidUseEnum): void {
        if (this.selectedFluidUse !== fOrU) {
            this.selectedFluidUse = fOrU;
            this.useOrFluidChanged.emit(fOrU);
        }
    }

    toggleProduction(event: MatButtonToggleChange): void {
        const toggle = event.source;
        if (toggle) {
            const group = toggle.buttonToggleGroup;
            if (event.value.some(item => item === toggle.value)) {
                group.value = [toggle.value];
                this.selectedProduction = +toggle.value;
            }
        } else {
            this.selectedProduction = null;
        }

        this.productionChanged.emit(this.selectedProduction);
    }

    onShowSerieItemLabelChanged(): void {
        this.isSerieItemLabelShowed = !this.isSerieItemLabelShowed;
        this.serieItemLabelShowChanged.emit(this.isSerieItemLabelShowed);
    }

    onSort(): void {
        this.sort = this.sort === 'asc' ? 'desc' : 'asc';
        this.sortChanged.emit(this.sort);
    }

    getCustomCumulativeTooltip(): string {
        return this.customCumulativeTooltip
            ? this.customCumulativeTooltip
            : this.translateService.instant('common.cumulative-consumption', { count: 2 });
    }

    public reset(): void {
        this.selectedFluidUse = undefined;
        this.selectedUnit = this.units[0];
        this.resetRatio();
        this.isCumul = false;
    }

    public resetRatio(): void {
        this.selectedRatio = this.ratios && this.ratios.length > 0 ? this.ratios[0] : undefined;
        this.isRatioApplied = this.selectedRatio !== undefined && this.selectedRatio.enum != null;
    }

    public goToBuildingGridPage(missingConservationTemperature: boolean): void {
        this.store.dispatch(
            setBuildingPageState({
                newBuildingPageState: <BuildingPageState>{
                    missingConservationTemperature: missingConservationTemperature
                }
            })
        );

        this.router.navigate([this.pathListBuildings]);
    }
}
