import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'toggle-action',
    templateUrl: './toggle-action.component.html',
    styleUrls: ['./toggle-action.component.scss']
})
export class ToggleActionComponent implements OnInit {
    @Input() selectedItemsCount: number;
    @Input() displayButton = true;
    @Input() icon: string;
    @Input() withoutBorder = false;
    @Output() toggleAction = new EventEmitter<boolean>();
    @Output() togglePanel = new EventEmitter<boolean>();

    constructor() {}

    ngOnInit() {}

    onToggleAction(newValue: boolean) {
        this.toggleAction.emit(newValue);
    }

    onTogglePanel(newValue: boolean) {
        this.togglePanel.emit(newValue);
    }
}
