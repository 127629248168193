/** Enum pour les types de recherche
 * ATTENTION : doit être identique au fichier "VertuozCommon.Enums.SearchType" de l'API
 */
export enum enumSearchType {
    Establishment = 0,
    Building = 1,
    Zone = 2,
    MeteringPoint = 3,
    Meter = 4,
    Dial = 5
    // Invoice = 6,
    // PartiallyInvoice = 7,
    // Contract = 8
}
