import { Injectable } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatDateFormats } from '@angular/material/core';

import * as moment from 'moment';

import { I18nService } from '@app/core/internationalization/i18n.service';
import { DateTimeService } from './date-time.service';

export const MOMENT_FORMATS: MatDateFormats = {
    parse: {
        dateInput: 'L'
    },
    display: {
        dateInput: 'L',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM-YYYY'
    }
};
@Injectable()
export class I18nDateAdapter extends MomentDateAdapter {
    constructor(private _i18nService: I18nService, private _dateTimeService: DateTimeService) {
        super(_i18nService.locale);

        this._i18nService.cultureChanged.subscribe(local => {
            this.setLocale(local);
        });
    }

    format(date: moment.Moment, displayFormat: string): string {
        return date.locale(this._i18nService.locale).format(this._dateTimeService.format);
    }

    // tslint:disable-next-line: typedef
    parse(value, parseFormat): moment.Moment {
        return moment(value, this._dateTimeService.format, this._i18nService.locale, true);
    }
}
