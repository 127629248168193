import { Component, Input, OnInit } from '@angular/core';

enum EnumSize {
    giant = 'giant',
    tall = 'tall',
    medium = 'medium',
    small = 'small',
    none = 'none'
}

@Component({
    selector: 'vertuoz-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
    @Input() showLoader: boolean;

    @Input() size: String;

    public diameter: number;

    public noLoader = false;

    constructor() {}

    ngOnInit(): void {
        switch (this.size) {
            case EnumSize.giant:
                this.diameter = 128;
                break;
            case EnumSize.tall:
                this.diameter = 64;
                break;
            case EnumSize.medium:
                this.diameter = 32;
                break;
            case EnumSize.small:
                this.diameter = 16;
                break;
            case EnumSize.none:
                this.diameter = 0;
                this.noLoader = true;
                break;
            default:
                this.diameter = 32;
        }
    }
}
