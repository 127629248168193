import { enableProdMode, LOCALE_ID } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
    if (window) {
        window.console.log = function() {};
        // ignorer aussi le debug en mode production
        window.console.debug = function() {};
    }
}

platformBrowserDynamic()
    .bootstrapModule(AppModule, {
        providers: [{ provide: LOCALE_ID, useValue: 'fr_FR' }]
    })
    .then(ref => {
        // Ensure Angular destroys itself on hot reloads.
        if (window['ngRef']) {
            window['ngRef'].destroy();
        }
        window['ngRef'] = ref;
    })
    .catch(err => console.log(err));
