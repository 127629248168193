import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Label } from '@app/core/models/common/label.model';
import { PerimeterLevelRSX } from '@app/features/objectives/models/objective.enum';
import { EnumPerimeterLevels } from '@app/features/perimeter/common/perimeter-levels.enum';
import { EnumGeographyRoute } from '@app/features/perimeter/geography/enum-geography-route';
import { EnumPropertyRoute } from '@app/features/perimeter/property/models/enum-property-route';
import { enumPublicSpaceRoute } from '@app/features/perimeter/public-space/enum-public-space-route';

@Component({
    selector: 'perimeter-widget-details',
    templateUrl: './perimeter-widget-details.component.html',
    styleUrls: ['./perimeter-widget-details.component.scss']
})
export class PerimeterWidgetDetailsComponent implements OnInit {
    /** Style la carte */
    public EnumPerimeterLevels = EnumPerimeterLevels;

    @Input() loading = true;
    @Input() perimeterLevel: EnumPerimeterLevels;
    public perimeterLevelText: string;
    public nationPath = `/${EnumGeographyRoute.path}/${EnumGeographyRoute.readNation}`;
    public regionPath = `/${EnumGeographyRoute.path}/${EnumGeographyRoute.readRegion}`;
    public countyPath = `/${EnumGeographyRoute.path}/${EnumGeographyRoute.readCounty}`;
    public townPath = `/${EnumGeographyRoute.path}/${EnumGeographyRoute.readTown}`;
    public establishmentPath = `/${EnumPropertyRoute.path}/${EnumPropertyRoute.readEstablishment}`;
    public buildingPath = `/${EnumPropertyRoute.path}/${EnumPropertyRoute.readBuilding}`;
    public districtPath = `/${enumPublicSpaceRoute.path}/${enumPublicSpaceRoute.readDistrict}`;
    public streetPath = `/${enumPublicSpaceRoute.path}/${enumPublicSpaceRoute.readStreet}`;

    @Input() perimeterName: string;
    @Input() subTitle: string;

    @Input() tagsActivitySectors: Array<string>;
    @Input() tagCustomizedSectors: Array<string>;
    @Input() tagActivityCategories: Array<string>;

    @Input() hideMap: boolean;
    @Input() gps_coo: Array<number>;
    @Input() address: string;
    @Input() postCode: string;

    @Input() parents: Array<Label>;

    @Input() use: string;
    @Input() altitude: string;
    @Input() wheatherStation: string;
    @Input() webLink: string;
    @Input() comments: string;

    constructor() {}

    ngOnInit(): void {}

    public getPerimeterLevelRSX(id: EnumPerimeterLevels): string {
        return PerimeterLevelRSX.find(ts => +ts.key === id).value;
    }
}
