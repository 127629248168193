import { Directive, HostListener, Input } from '@angular/core';

import { AzureStorageFile, AzureStorageService } from '@app/core/services/azure-storage.service';
import { FileService } from '@app/core/services/file.service';
import {
    AzureStorageVTZFolder,
    enumInitImportFileParam
} from '@app/features/shared/routing/init-import-route.enums';
import { ToasterService } from '@vertuoz/vertuoz-library';

import { map } from 'rxjs/operators';

@Directive({
    selector: '[fileDownload]'
})
export class FileDownloadDirective {
    /** enum du fichier à télécharger */
    @Input() fileDownload: AzureStorageFile;

    constructor(
        private azureStorageService: AzureStorageService,
        private toasterService: ToasterService
    ) {}

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    @HostListener('click') onClick() {
        return this.azureStorageService
            .GetFile(this.fileDownload)
            .pipe(
                map(res => {
                    return {
                        filename: res.headers
                            .get('Content-Disposition')
                            .split(';')[1]
                            .replace('filename=', ''),
                        data: res.body
                    };
                })
            )
            .subscribe(
                res => {
                    const url = window.URL.createObjectURL(res.data);
                    const a = document.createElement('a');
                    document.body.appendChild(a);
                    a.setAttribute('style', 'display: none');
                    a.href = url;
                    a.download = res.filename;
                    a.click();
                    window.URL.revokeObjectURL(url);
                    a.remove();
                },
                error => {
                    this.toasterService.showError('Erreur lors du téléchargement du fichier');
                }
            );
    }
}

@Directive({
    selector: '[logFileDownload]'
})
export class LogFileDownloadDirective {
    @Input() logFileDownload: AzureStorageVTZFolder;

    constructor(private fileService: FileService, private toasterService: ToasterService) {}

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    @HostListener('click') onClick() {
        return this.fileService
            .getLogs(this.logFileDownload)
            .pipe(
                map(res => {
                    return {
                        filename: res.headers
                            .get('Content-Disposition')
                            .split(';')[1]
                            .replace('filename=', ''),
                        data: res.body
                    };
                })
            )
            .subscribe(
                res => {
                    const url = window.URL.createObjectURL(res.data);
                    const a = document.createElement('a');
                    document.body.appendChild(a);
                    a.setAttribute('style', 'display: none');
                    a.href = url;
                    a.download = res.filename;
                    a.click();
                    window.URL.revokeObjectURL(url);
                    a.remove();
                },
                error => {
                    this.toasterService.showError('Erreur lors du téléchargement du fichier');
                }
            );
    }
}
