import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-sidebar-free-zone',
    templateUrl: './sidebar-free-zone.component.html',
    styleUrls: ['./sidebar-free-zone.component.scss']
})
export class SidebarFreeZoneComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
