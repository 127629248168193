<!--liste fixe des element sélectionnés-->
<div class="paddingTopLists">
  <div class="row me-0 ms-0" *ngFor="let group of selectedGroups">
    <mat-checkbox
      class="col-12"
      [checked]="true"
      [disabled]="!updateMode"
      (change)="applyGroup($event.checked, group)"
    >
      <div *ngIf="group.levelId <= 0" class="float-left">
        {{ group.name || 'Groupe sans nom' }} (Géographie
      </div>
      <div *ngIf="group.levelId > 0 && group.levelId <= 3" class="float-left">
        {{ group.name || 'Groupe sans nom' }} (Immobilier
      </div>
      <div *ngIf="group.levelId > 10 && group.levelId <= 13" class="float-left">
        {{ group.name || 'Groupe sans nom' }} (Espace public
      </div>

      <div *ngIf="group.levelId === -3" class="float-left">- {{ group.count }} pay(s))</div>
      <div *ngIf="group.levelId === -2" class="float-left">- {{ group.count }} région(s))</div>
      <div *ngIf="group.levelId === -1" class="float-left">- {{ group.count }} département(s))</div>
      <div *ngIf="group.levelId === 0" class="float-left">- {{ group.count }} ville(s))</div>

      <div *ngIf="group.levelId === 1 && group.count > 0" class="float-left">
        - {{ group.count }} établissement(s))
      </div>
      <div *ngIf="group.levelId === 1 && group.count === 0" class="float-left">
        - Tous l'immobilier)
      </div>

      <div *ngIf="group.levelId === 2" class="float-left">- {{ group.count }} bâtiment(s))</div>
      <div *ngIf="group.levelId === 3" class="float-left">- {{ group.count }} zone(s))</div>

      <div *ngIf="group.levelId === 11" class="float-left">- {{ group.count }} quartier(s))</div>
      <div *ngIf="group.levelId === 12" class="float-left">- {{ group.count }} rue(s))</div>
      <div *ngIf="group.levelId === 13" class="float-left">- {{ group.count }} armoire(s))</div>
    </mat-checkbox>
  </div>
</div>

<ng-container *ngIf="updateMode && !isLoading">
  <hr
    *ngIf="selectedGroups && selectedGroups.length > 0 && groupsInfos && groupsInfos.length > 0"
    class="separator me-2 ms-2"
  />

  <!--liste scollable des element non sélectionnés-->
  <div
    class="groups-container row me-0 ms-0"
    infinite-scroll
    [infiniteScrollDistance]="scrollDistance"
    [infiniteScrollThrottle]="throttle"
    [scrollWindow]="scrollWindow"
    (scrolled)="defineFilter()"
  >
    <ng-container *ngFor="let group of groupsInfosNoSelected">
      <mat-checkbox class="col-12" [checked]="false" (change)="applyGroup($event, group)">
        <div *ngIf="group.levelId <= 0" class="float-left">
          {{ group.name || 'Groupe sans nom' }} (Géographie
        </div>
        <div *ngIf="group.levelId > 0 && group.levelId <= 3" class="float-left">
          {{ group.name || 'Groupe sans nom' }} (Immobilier
        </div>
        <div *ngIf="group.levelId > 10 && group.levelId <= 13" class="float-left">
          {{ group.name || 'Groupe sans nom' }} (Espace public
        </div>

        <div *ngIf="group.levelId === -3" class="float-left">- {{ group.count }} pay(s))</div>
        <div *ngIf="group.levelId === -2" class="float-left">- {{ group.count }} région(s))</div>
        <div *ngIf="group.levelId === -1" class="float-left">
          - {{ group.count }} département(s))
        </div>
        <div *ngIf="group.levelId === 0" class="float-left">- {{ group.count }} ville(s))</div>

        <div *ngIf="group.levelId === 1 && group.count > 0" class="float-left">
          - {{ group.count }} établissement(s))
        </div>
        <div *ngIf="group.levelId === 1 && group.count === 0" class="float-left">
          - Tous l'immobilier)
        </div>

        <div *ngIf="group.levelId === 2" class="float-left">- {{ group.count }} bâtiment(s))</div>
        <div *ngIf="group.levelId === 3" class="float-left">- {{ group.count }} zone(s))</div>

        <div *ngIf="group.levelId === 11" class="float-left">- {{ group.count }} quartier(s))</div>
        <div *ngIf="group.levelId === 12" class="float-left">- {{ group.count }} rue(s))</div>
        <div *ngIf="group.levelId === 13" class="float-left">- {{ group.count }} armoire(s))</div>
      </mat-checkbox>
    </ng-container>
  </div>
</ng-container>
<div *ngIf="isLoading" class="d-flex justify-content-center align-self-center align-middle m-52px">
  <mat-spinner [diameter]="50"></mat-spinner>
</div>
