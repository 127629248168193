import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import {
    forwardRef,
    AfterViewInit,
    Component,
    ContentChildren,
    ElementRef,
    Input,
    QueryList,
    ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CustomDropdownService } from './custom-dropdown.service';
import { CustomSelectOptionComponent } from './custom-select-option.component';
import { DropdownComponent } from './dropdown.component';

@Component({
    selector: 'custom-select',
    templateUrl: './custom-select.html',
    styleUrls: ['./_custom-select.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CustomSelectComponent),
            multi: true
        },
        CustomDropdownService
    ]
})
export class CustomSelectComponent implements AfterViewInit, ControlValueAccessor {
    private keyManager: ActiveDescendantKeyManager<CustomSelectOptionComponent>;

    @Input()
    public label: string;

    @Input()
    public placeholder: string;

    @Input()
    public selected: string;

    @Input()
    public required = false;

    @Input()
    public disabled = false;

    @ViewChild('input')
    public input: ElementRef;

    @ViewChild(DropdownComponent)
    public dropdown: DropdownComponent;

    @ContentChildren(CustomSelectOptionComponent)
    public options: QueryList<CustomSelectOptionComponent>;

    public selectedOption: CustomSelectOptionComponent;

    public displayText: string;

    public onChangeFn = _ => {};

    public onTouchedFn = () => {};

    constructor(private dropdownService: CustomDropdownService) {
        this.dropdownService.register(this);
    }

    public ngAfterViewInit(): void {
        setTimeout(() => {
            this.selectedOption = this.options
                .toArray()
                .find(option => option.key === this.selected);
            this.displayText =
                this.selectedOption && this.selectedOption.value && !this.selectedOption.new
                    ? this.selectedOption.value
                    : '';
            this.keyManager = new ActiveDescendantKeyManager(this.options)
                .withHorizontalOrientation('ltr')
                .withVerticalOrientation()
                .withWrap();
        });
    }

    public showDropdown(): void {
        this.dropdown.show();

        if (!this.options.length) {
            return;
        }

        this.selected
            ? this.keyManager.setActiveItem(this.selectedOption)
            : this.keyManager.setFirstItemActive();
    }

    public hideDropdown(): void {
        this.dropdown.hide();
    }

    public onDropMenuIconClick(event: UIEvent): void {
        event.stopPropagation();
        setTimeout(() => {
            this.input.nativeElement.focus();
            this.input.nativeElement.click();
        }, 10);
    }

    public onKeyDown(event: KeyboardEvent): void {
        if (['Enter', ' ', 'ArrowDown', 'Down', 'ArrowUp', 'Up'].indexOf(event.key) > -1) {
            if (!this.dropdown.showing) {
                this.showDropdown();
                return;
            }

            if (!this.options.length) {
                event.preventDefault();
                return;
            }
        }

        if (event.key === 'Enter' || event.key === ' ') {
            this.selectedOption = this.keyManager.activeItem;
            this.selected = this.selectedOption.key;
            this.displayText = this.selectedOption ? this.selectedOption.value : '';
            this.hideDropdown();
            this.onChange();
        } else if (
            [
                'ArrowUp',
                'Up',
                'ArrowDown',
                'Down',
                'ArrowRight',
                'Right',
                'ArrowLeft',
                'Left'
            ].indexOf(event.key) > -1
        ) {
            this.keyManager.onKeydown(event);
        }
    }

    public selectOption(option: CustomSelectOptionComponent): void {
        this.keyManager.setActiveItem(option);
        this.selected = option.key;
        this.selectedOption = option;
        this.displayText = this.selectedOption ? this.selectedOption.value : '';
        this.hideDropdown();
        this.input.nativeElement.focus();
        this.onChange();
    }

    // tslint:disable-next-line:typedef
    public registerOnChange(fn): void {
        this.onChangeFn = fn;
    }

    // tslint:disable-next-line:typedef
    public registerOnTouched(fn): void {
        this.onTouchedFn = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    // tslint:disable-next-line:typedef
    public writeValue(obj): void {
        this.selected = obj;
    }

    public onTouched(): void {
        this.onTouchedFn();
    }

    public onChange(): void {
        this.onChangeFn(this.selected);
    }
}
