//#region Imports
import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    Router,
    RouterStateSnapshot
} from '@angular/router';

import { appRoutes } from '@app/app-root/app-routes.enum';
import { AppService } from '@app/core/http/app.service';
import { MsalService } from '@azure/msal-angular';
import { environment } from '@environments/environment';
//#endregion

@Injectable({
    providedIn: 'root'
})
export class IdentificationGuard implements CanActivate, CanActivateChild {
    //#region Constructor
    constructor(
        private router: Router,
        private appService: AppService,
        private authService: MsalService
    ) {}
    //#endregion

    //#region Methods
    canActivate = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
        console.debug('IdentificationGuard : canActivate');
        return this.checkUserIdentity(state);
    };

    canActivateChild = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
        console.debug('IdentificationGuard : canActivateChild');
        return this.checkUserIdentity(state);
    };

    private checkUserIdentity(state: RouterStateSnapshot): boolean {
        console.debug('IdentificationGuard : checkUserIdentity');

        const isUserLoggedIn = this.authService.instance.getActiveAccount();
        console.debug('IdentificationGuard : checkUserIdentity : IsLoggedIn : ' + isUserLoggedIn);

        const userContext = this.appService.getUserContext();
        console.debug(
            'IdentificationGuard : checkUserIdentity : UserContext : ' + JSON.stringify(userContext)
        );

        if (isUserLoggedIn && userContext && userContext.user) {
            if (
                userContext.user.cguValidate &&
                userContext.contract.offer.id === environment.DASHBOARD_OFFERID
            ) {
                return true;
            } else {
                this.router.navigate([
                    appRoutes.externalRedirect,
                    { externalUrl: environment.extLinks.portal }
                ]);
                return false;
            }
        } else {
            const routeUrl = state.url;
            const routeName = state.url.split('?')[0];

            localStorage.setItem('previousUrl', routeName);
            localStorage.setItem('previousUrlParams', JSON.stringify(state.root.queryParams));

            console.debug('IdentificationGuard : checkUserIdentity : RouteUrl = ' + routeUrl);
            console.debug('IdentificationGuard : checkUserIdentity : RouteName = ' + routeName);
            console.debug(
                'IdentificationGuard : checkUserIdentity : params = ' +
                    JSON.stringify(state.root.queryParams)
            );

            this.router.navigate([appRoutes.login], { queryParamsHandling: 'preserve' });
            return false;
        }
    }
    //#endregion
}
