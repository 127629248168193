import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { FilterSettingsModel, FilterValue } from './filter.model';

@Component({
    selector: 'filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
    @Input() filterSettings: FilterSettingsModel[];
    @Output() closeAllPopups = new EventEmitter<boolean>();
    @Output() selectedFilters = new EventEmitter<FilterSettingsModel[]>();

    showDialog = false;
    allFilters: FilterValue[] = [];
    selectedFilterSettings: FilterSettingsModel[] = [];
    uid: number;

    constructor() {
        this.uid = new Date().getTime();
    }

    ngOnInit() {
        this.filterSettings.forEach(f => {
            this.allFilters.push(<FilterValue>{
                id: f.filterId,
                selected: true,
                label: f.filterName
            });
        });
    }

    toggleDialog() {
        this.showDialog = !this.showDialog;
    }

    onFilterSelected(element: FilterValue) {
        element.selected = !element.selected;
        this.selectedFilters.emit(this.filterSettings);
    }

    onAllFiltersSelected(checked: boolean, filterId: number) {
        this.filterSettings
            .filter(f => f.filterId === filterId)
            .forEach(f => f.filterValues.forEach(v => (v.selected = checked)));
        this.selectedFilters.emit(this.filterSettings);
    }

    closePanel() {
        if (this.showDialog) {
            this.showDialog = false;
        }
    }

    hasAllMembersChecked(filterId?: number) {
        let state = true;
        if (filterId) {
            this.filterSettings
                .filter(f => f.filterId === filterId)
                .forEach(f =>
                    f.filterValues.forEach(v => {
                        state = state && v.selected;
                    })
                );
        } else {
            this.filterSettings.forEach(f =>
                f.filterValues.forEach(v => {
                    state = state && v.selected;
                })
            );
        }
        return state;
    }

    reinitCheck() {
        this.filterSettings.forEach(f => f.filterValues.forEach(v => (v.selected = true)));
        this.selectedFilters.emit(this.filterSettings);
        this.showDialog = false;
    }

    filterNumber(): number {
        return (
            this.filterSettings[0].filterValues.filter(f => f.selected).length +
            this.filterSettings[1].filterValues.filter(f => f.selected).length
        );
    }
}
