<div class="sub-menu">
  <p class="profile__infos pt-3 px-3">
    <strong class="profile__name font-14 d-block"
      >{{ currentUser?.firstName }} {{ currentUser?.familyName }}</strong
    >
    <span class="font-11 d-block">{{ getDashboardRoleName() }}</span>
  </p>
  <p class="px-3 pb-3 mb-3 border-bottom">
    <button mat-flat-button color="primary" class="btn-sm" (click)="openProfile()">
      {{ 'userprofil.actions.show-profil' | translate }}
    </button>
  </p>

  <p class="profile__contract px-3 pb-3 mb-3 font-14 border-bottom">
    <strong class="profile__name d-block">{{ 'userprofil.text.contract' | translate }}</strong>
    <small class="profile__status font-11 d-block">{{ currentContract.name }}</small>
  </p>

  <p class="profile__language px-3 pb-3 mb-3 border-bottom" *ngIf="showLanguage()">
    <mat-icon class="me-2">language</mat-icon>
    <button class="btn-language py-0 font-12" (click)="i18nService.updateLanguage('fr-FR')">
      FR
    </button>
    <button class="btn-language py-0 font-12" (click)="i18nService.updateLanguage('en-US')">
      EN
    </button>
  </p>

  <p>
    <button mat-flat-button class="btn-logout btn-sm" (click)="logout()">
      <mat-icon class="color-error me-2">power_settings_new</mat-icon>
      <span class="btn-logout__text color-black d-inline-block">{{
        'app.actions.logout' | translate
      }}</span>
    </button>
  </p>
</div>
