export class PagedContext {
    currentPage: number;
    pageSize: number;
    sortDesc?: boolean;
    sortField?: string;

    constructor(init?: Partial<PagedContext>) {
        if (init) {
            Object.assign(this, init);
        }
    }
}

export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PAGE = 0;
export const DEFAULT_SORT_DESC = false;

export const PAGE_SIZES: Readonly<Array<number>> = [5, 10, 25, 50, 100];
export const PAGER_TYPE: 'numeric' | 'input' = 'input';
