import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Align, Collision } from '@progress/kendo-angular-popup';
import { timer, Subscription } from 'rxjs';

@Component({
    selector: 'vertuoz-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
    /** indique que le panel est ouvert ou fermé */
    public editPanelIsOpen: boolean;

    /** Déclaration de la "subscription" pour pouvoir l'annuler */
    private subscribe: Subscription = new Subscription();

    /** Number pour la gestion de la progression */
    public counter = 0;

    /** Timer qui avance de 1 toutes les 20 millisecondes  */
    private source = timer(1, 30);

    /** align de l'ancre */
    public anchorAlign: Align = { horizontal: 'center', vertical: 'top' };

    /** align de la popup */
    public popupAlign: Align = { horizontal: 'center', vertical: 'bottom' };

    /** permet de définir la largeur de la popup */
    @Input() public width = '100%';

    /** indique si la validation impose un delai*/
    @Input() public hasTimerToValidete = false;

    /** indique si le composant prend le style warning (rouge au lieu de bleu)*/
    @Input() public isWarningMode = true;

    /** indique le nom de l'action à mener */
    @Input() public actionName = '';

    /** vitesse maximum  */
    private readonly maxSpeed = 20;

    /** vitesse minimum */
    private readonly minSpeed = 0.5;

    /** coefficient multiplicateur permettant d'ajuster le compteur en fonction de la vitesse*/
    private readonly coefMultCounter = 4;

    /** valeur maximum du compteur de 100 + un buffer lié à la vitesse pour que ca corresponde avec la progresse bar*/
    private maxCounter = 100;

    public collision: Collision = { horizontal: 'flip', vertical: 'fit' };

    /** permet de définir la vitesse du délai de validation*/
    @Input()
    public set timerSpeed(value: number) {
        if (value < this.minSpeed) {
            this._timerSpeed = this.minSpeed;
        } else if (value > this.maxSpeed) {
            this._timerSpeed = this.maxSpeed;
        } else {
            this._timerSpeed = value;
        }
    }

    /** vitesse timer initialiser à 3 vitesses standard*/
    private _timerSpeed = 3;

    /** Evenment émis une fois l'action validée et confirmée */
    @Output() public validate: EventEmitter<boolean> = new EventEmitter<boolean>();

    @HostListener('click', ['$event'])
    onClick(event: MouseEvent) {
        if ((<Element>event.target).classList.contains('closePanel')) {
            this.editPanelIsOpen = false;
        } else if ((<Element>event.target).classList.contains('closePanelTimer')) {
            // dans l'event
        } else {
            this.editPanelIsOpen = true;
        }
    }

    constructor() {}

    ngOnInit() {
        this.maxCounter += this._timerSpeed * this.coefMultCounter;
    }

    /** Lance le timer qui va de 0 -> 100 */
    public startCountDown() {
        if (this.hasTimerToValidete) {
            this.subscribe = this.source.subscribe(val => {
                if (this.counter < this.maxCounter) {
                    this.counter = val * this._timerSpeed;
                }
            });
        }
    }

    /** action lorque la souris releve le click */
    public stopCountDown() {
        this.stopCount();
    }

    /** action lorsque la souris sort de l'espace du bouton valider*/
    public stopCountLeave() {
        this.stopCount();
        this.resetCount();
    }

    /** permet d'arreter le compteur de tourner*/
    private stopCount() {
        if (this.subscribe !== null) {
            this.subscribe.unsubscribe();
            this.subscribe = null;
        }
    }
    /** permet de rinitialiser le compteur */
    private resetCount() {
        this.counter = 0;
    }

    /** permet de valider la confiirmation*/
    private validation() {
        this.validate.emit(true);
        this.editPanelIsOpen = false;
    }

    /** action lors du clique sur la valider*/
    public OnValidate() {
        if (this.hasTimerToValidete) {
            if (this.counter < this.maxCounter) {
                console.log('OnValidate : ', this.counter);
            } else {
                this.validation();
            }
            this.resetCount();
        } else {
            this.validation();
        }
    }
}
