import { Component, Input, OnInit } from '@angular/core';
import { FileService } from '@app/core/services/file/file.service';
import { LinkedFileModel } from '../../../../core/models/file/linked-file.model';
import { FileService as fileHttpService } from '@app/core/http/file/file.service';
import { ToasterService } from '@vertuoz/vertuoz-library';

@Component({
    selector: 'linked-file-download',
    templateUrl: './linked-file-download.component.html',
    styleUrls: ['./linked-file-download.component.scss']
})
export class LinkedFileDownloadComponent {
    _linkedFiles: LinkedFileModel[] = [];

    @Input()
    set linkedFiles(value: LinkedFileModel[]) {
        this._linkedFiles = value;
        this._linkedFiles?.forEach(
            f => (f.fileSvgIcon = this.fileService.getSvgIconName(f.fileName.split('.')[1]))
        );
    }

    get linkedFiles(): LinkedFileModel[] {
        return this._linkedFiles;
    }

    constructor(
        private fileService: FileService,
        private fileHttpService: fileHttpService,
        private toasterService: ToasterService
    ) {}

    downloadFile(fileId: number, fileName: string): void {
        this.fileHttpService.getFile(fileId).subscribe(
            res => {
                if (res) {
                    const a = document.createElement('a');
                    const objectUrl = URL.createObjectURL(res);
                    a.href = objectUrl;
                    a.download = fileName;
                    a.click();
                    URL.revokeObjectURL(objectUrl);
                }
            },
            () => {
                this.toasterService.showError('Erreur de téléchargement');
            }
        );
    }
}
