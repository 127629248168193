<div
  #anchor
  class="header-elt header-search geo-select bg-primary-700 px-2 ms-4 position-relative d-flex align-items-center w-100"
  [ngClass]="
    currentPerimeterScope === PerimeterScope.activeSelectedPerimeter ? 'cursor-pointer' : ''
  "
>
  <div class="w-75 overflow-hidden">
    <div (click)="onToggle()">
      <p
        class="initial m-0 pt-2"
        *ngIf="
          !geographicSelection.length &&
          !propertySelection.length &&
          !publicSpaceSelection.length &&
          currentPerimeterScope === PerimeterScope.activeSelectedPerimeter
        "
      >
        <span>Tout le périmètre</span>
      </p>

      <ng-container *ngIf="currentPerimeterScope === PerimeterScope.activeSelectedPerimeter">
        <mat-chip-list #chipList aria-label="Périmètre actif">
          <div
            class="d-flex"
            *ngIf="!groupActive && geographicSelection && geographicSelection.length"
          >
            <mat-chip
              class="badge badge-geo badge-classic text-truncate"
              matTooltip="{{ getItemsStringByDomain(1) }}"
              [removable]="true"
              (removed)="remove()"
            >
              <ng-template #loader> <mat-spinner [diameter]="18"></mat-spinner> </ng-template>
              <span class="align-middle">
                <mat-icon *ngIf="!loading; else loader" matChipRemove>close</mat-icon>
              </span>
              <span class="align-middle pt-1">{{
                geographicSelection.length === 1
                  ? geographicSelection[0].itemLabel
                  : geographicSelection.length +
                    ' ' +
                    getLevelLabel(geographicSelection[0].perimeterLevel)
              }}</span>
            </mat-chip>
          </div>

          <div class="d-flex" *ngIf="groupActive">
            <mat-chip
              [removable]="false"
              [matTooltipDisabled]="groupActive.label.length < 10"
              matTooltip="{{ getLabelStringGroup(true) }}"
              class="badge badge-text badge-classic"
              [ngClass]="{
                'badge-property': propertySelection && propertySelection.length,
                'badge-public-space': publicSpaceSelection && publicSpaceSelection.length,
                'badge-geo': geographicSelection && geographicSelection.length
              }"
            >
              Groupe {{ getLabelStringGroup(false) }}
            </mat-chip>

            <mat-chip
              *ngIf="propertySelection && propertySelection.length"
              matTooltip="{{ getItemsStringByDomain(2) }}"
              class="badge badge-classic badge-property text-truncate badge-text"
              [removable]="true"
              (removed)="remove()"
            >
              <ng-template #loader> <mat-spinner [diameter]="18"></mat-spinner> </ng-template>
              <span class="align-middle">
                <mat-icon *ngIf="!loading; else loader" matChipRemove>close</mat-icon>
              </span>
              <span class="align-middle pt-1">{{
                propertySelection.length === 1
                  ? propertySelection[0].itemLabel
                  : propertySelection.length +
                    ' ' +
                    getLevelLabel(propertySelection[0].perimeterLevel)
              }}</span>
            </mat-chip>

            <mat-chip
              *ngIf="publicSpaceSelection && publicSpaceSelection.length"
              matTooltip="{{ getItemsStringByDomain(3) }}"
              class="badge badge-classic badge-public-space badge-text"
              [removable]="true"
              (removed)="remove()"
            >
              <ng-template #loader> <mat-spinner [diameter]="18"></mat-spinner> </ng-template>
              <span class="align-middle">
                <mat-icon *ngIf="!loading; else loader" matChipRemove>close</mat-icon>
              </span>
              <span class="align-middle pt-1">{{
                publicSpaceSelection.length === 1
                  ? publicSpaceSelection[0].itemLabel
                  : publicSpaceSelection.length +
                    ' ' +
                    getLevelLabel(publicSpaceSelection[0].perimeterLevel)
              }}</span>
            </mat-chip>

            <mat-chip
              *ngIf="geographicSelection && geographicSelection.length"
              class="badge badge-geo badge-classic text-truncate"
              matTooltip="{{ getItemsStringByDomain(1) }}"
              [removable]="true"
              (removed)="remove()"
            >
              <ng-template #loader> <mat-spinner [diameter]="18"></mat-spinner> </ng-template>
              <span class="align-middle">
                <mat-icon *ngIf="!loading; else loader" matChipRemove>close</mat-icon>
              </span>
              <span class="align-middle pt-1">{{
                geographicSelection.length === 1
                  ? geographicSelection[0].itemLabel
                  : geographicSelection.length +
                    ' ' +
                    getLevelLabel(geographicSelection[0].perimeterLevel)
              }}</span>
            </mat-chip>
          </div>

          <div class="d-flex" *ngIf="!groupActive && propertySelection && propertySelection.length">
            <mat-chip [removable]="false" class="badge badge-classic badge-property badge-text">
              Immobilier
            </mat-chip>

            <mat-chip
              matTooltip="{{ getItemsStringByDomain(2) }}"
              class="badge badge-classic badge-property text-truncate badge-text"
              [removable]="true"
              (removed)="remove()"
            >
              <ng-template #loader> <mat-spinner [diameter]="18"></mat-spinner> </ng-template>
              <span class="align-middle">
                <mat-icon *ngIf="!loading; else loader" matChipRemove>close</mat-icon>
              </span>
              <span class="align-middle pt-1">{{
                propertySelection.length === 1
                  ? propertySelection[0].itemLabel
                  : propertySelection.length +
                    ' ' +
                    getLevelLabel(propertySelection[0].perimeterLevel)
              }}</span>
            </mat-chip>
          </div>

          <div
            class="d-flex"
            *ngIf="!groupActive && publicSpaceSelection && publicSpaceSelection.length"
          >
            <mat-chip [removable]="false" class="badge badge-classic badge-public-space badge-text">
              Espace public
            </mat-chip>

            <mat-chip
              matTooltip="{{ getItemsStringByDomain(3) }}"
              class="badge badge-classic badge-public-space badge-text"
              [removable]="true"
              (removed)="remove()"
            >
              <ng-template #loader> <mat-spinner [diameter]="18"></mat-spinner> </ng-template>
              <span class="align-middle">
                <mat-icon *ngIf="!loading; else loader" matChipRemove>close</mat-icon>
              </span>
              <span class="align-middle pt-1">{{
                publicSpaceSelection.length === 1
                  ? publicSpaceSelection[0].itemLabel
                  : publicSpaceSelection.length +
                    ' ' +
                    getLevelLabel(publicSpaceSelection[0].perimeterLevel)
              }}</span>
            </mat-chip>
          </div>
        </mat-chip-list>
      </ng-container>
    </div>

    <p
      class="no-perim-filter m-0 pt-2"
      *ngIf="currentPerimeterScope !== PerimeterScope.activeSelectedPerimeter"
    >
      <span>Le filtre sur le périmètre est désactivé</span>
    </p>

    <div class="position-absolute h-100 d-flex align-items-center top-0 end-0 pe-5">
      <div
        *ngIf="currentPerimeterScope === PerimeterScope.activeSelectedPerimeter"
        class="pe-4"
        (click)="onToggle()"
      >
        <mat-icon class="cursor-pointer">keyboard_arrow_down</mat-icon>
      </div>

      <!-- Toggle perimeter button -->
      <div
        class="border-start border-primary-900 position-absolute top-0 end-0 h-100 ps-3 pe-2 d-flex align-items-center"
      >
        <mat-slide-toggle
          [disabled]="this.switchLoading"
          [matTooltip]="
            (currentPerimeterScope !== PerimeterScope.activeSelectedPerimeter
              ? 'common.action.switch-on'
              : 'common.action.switch-off'
            ) | translate
          "
          [checked]="currentPerimeterScope === PerimeterScope.activeSelectedPerimeter"
          (change)="onTogglePerimeter($event.checked)"
        ></mat-slide-toggle>
      </div>
    </div>
  </div>
</div>

<kendo-popup
  #popup
  [offset]="offset"
  [anchor]="anchor"
  (anchorViewportLeave)="closePopup()"
  (clickOutside)="closePopup()"
  [exclude]="'.cdk-overlay-container *, .mat-option, .header-elt.header-search'"
  [excludeBeforeClick]="true"
  [clickOutsideEvents]="'click,touchstart'"
  *ngIf="show"
  class="k-popup-wrapper bg-white p-0"
>
  <geographic-menu
    (closeMenuContainer)="show = false"
    [ngStyle]="{ display: show ? 'block' : 'none' }"
    class="h-100"
  >
  </geographic-menu>
</kendo-popup>
