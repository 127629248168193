//#region Imports
import { Location } from '@angular/common';
import { Component } from '@angular/core';
//#endregion

@Component({
    selector: 'base-component',
    template: ``
})
export class BaseComponent {
    //#region Constructor
    constructor(public location: Location) {}
    //#endregion

    //#region Methods
    public goBack(): void {
        this.location.back();
    }
    //#endregion
}
