<div #wrapperContainer class="wrapper">
  <!-- top navbar-->
  <app-header class="topnavbar-wrapper"></app-header>
  <!-- sidebar-->
  <app-sidebar class="aside-container"></app-sidebar>

  <!-- Main section-->
  <main class="section-container">
    <!-- subheader map -->
    <!-- <app-map></app-map> -->
    <div [hidden]="!loading">
      <mat-progress-bar [mode]="mode" [value]="value" class="h-10px"> </mat-progress-bar>
    </div>

    <!-- Page content-->
    <vertuoz-loader [showLoader]="loadingPerimeter" [size]="'tall'">
      <div
        [hidden]="loading"
        class="content-wrapper p-5"
        [ngStyle]="{ height: ifRouterUrlApplyHeight('/dashboard') }"
      >
        <router-outlet></router-outlet>
      </div>
    </vertuoz-loader>
  </main>

  <!-- footer -->
  <vz-footer
    class="footer-container"
    [product]="'VERTUOZ Dashboard'"
    [version]="version"
  ></vz-footer>
</div>
