import { KeyValue } from '@angular/common';

export enum FileExportType {
    XLSX = 1,
    CSV = 2,
    ODS = 3,
    PDF = 4,
    XLS = 5
}

export enum ImportType {
    Facture = 0,
    Releve = 1,
    Zone = 2,
    DialPower = 3,
    DialConversionCoefficient = 4,
    TechnicalInformations = 5,
    VehicleInvoice = 6,
    VehicleKilometerIndex = 7
}

export const ImportTypeRSX: Array<KeyValue<ImportType, string>> = [
    { key: ImportType.Facture, value: 'invoice.text.invoice' },
    { key: ImportType.Releve, value: 'readings.text.reading' },
    { key: ImportType.DialPower, value: 'counting-plan.text.dial' },
    { key: ImportType.DialConversionCoefficient, value: 'counting-plan.text.dial' },
    { key: ImportType.Zone, value: 'geoMenu.levels.zones' },
    { key: ImportType.TechnicalInformations, value: 'counting-plan.text.metering-point' },
    { key: ImportType.VehicleInvoice, value: 'invoice.text.invoice' },
    { key: ImportType.VehicleKilometerIndex, value: 'vehicle.kilometer-index.text' }
];

export const ImportBreadcrumTrailsRSX: Array<KeyValue<ImportType, string>> = [
    { key: ImportType.Facture, value: 'invoice.breadcrumb-trail' },
    { key: ImportType.Releve, value: 'readings.text.reading' },
    { key: ImportType.DialPower, value: 'counting-plan.text.dial' },
    { key: ImportType.DialConversionCoefficient, value: 'counting-plan.text.dial' },
    { key: ImportType.Zone, value: 'geoMenu.levels.zones' },
    { key: ImportType.TechnicalInformations, value: 'counting-plan.text.metering-point' },
    { key: ImportType.VehicleInvoice, value: 'vehicle-invoice.breadcrumb-trail' },
    {
        key: ImportType.VehicleKilometerIndex,
        value: 'vehicle.kilometer-index.breadcrumb-trail-import'
    }
];
