<mat-accordion *ngIf="!loading" class="w-100">
  <!--Level-->
  <perimeter-selector-level
    *ngFor="let levelId of diplayLevel"
    [selectedInfoLevel]="selectedInfoLevel"
    [partialSelectedLabel]="partialSelectedLabel"
    [selectedLabel]="selectedLabel"
    [levelId]="levelId"
    [searchMode]="searchMode"
    [updateMode]="updateMode"
    [highlightMode]="highlightMode"
    [displayParentsItems]="!displayParentsItemsFor || displayParents(levelId)"
    [allListItemSelected]="selection"
    [allListItemFilter]="filterSelection"
    (selectionUpdate)="selectionUpdate($event)"
    [lowestLevelMode]="lowestLevelMode"
    (levelReset)="levelReset($event)"
    [tertiaryDecreeFilter]="tertiaryDecreeFilter"
    (closedOtherPanel)="closedOtherPanel($event)"
    (selectionChange)="_selectionChange()"
    [key]="key"
  ></perimeter-selector-level>
</mat-accordion>

<div *ngIf="loading" class="d-flex justify-content-center align-items-center m-52px">
  <mat-spinner [diameter]="50"></mat-spinner>
</div>
