<ng-template cdkPortal #portal="cdkPortal">
  <div class="bulk-action ps-3 d-flex w-100 align-items-center bg-black-87">
    <p
      class="m-0 bulk-action__selected-items color-white-70 font-11 position-absolute d-flex align-items-center"
    >
      <span
        class="bulk-action__selected-items-number font-12 d-flex align-items-center justify-content-center bg-primary-700 color-white"
      >
        {{ selectedItemsCount }}
      </span>
      <strong *ngIf="selectedItemsCount < 2" class="bulk-action__selected-items-text">
        élément séléctionné
      </strong>
      <strong *ngIf="selectedItemsCount > 1" class="bulk-action__selected-items-text">
        éléments séléctionnés
      </strong>
    </p>

    <div class="bulk-action__actions d-flex m-auto">
      <toggle-action
        matTooltip="Activer / Désactiver Mail"
        *ngIf="emailMode"
        icon="email"
        (toggleAction)="toggleEmail.emit($event)"
      ></toggle-action>

      <toggle-action
        matTooltip="Activer / Désactiver SMS"
        *ngIf="smsMode"
        icon="sms"
        (toggleAction)="toggleSms.emit($event)"
        [displayButton]="hasUserPhoneNumber"
      >
        <ng-container *ngIf="!hasUserPhoneNumber">
          <p class="m-0 text-center">
            <span>
              Vous devez renseigner votre <br />
              numéro de téléphone mobile <br />
              pour recevoir les alertes par SMS.
            </span>
          </p>
        </ng-container>
      </toggle-action>

      <toggle-action
        matTooltip="Activer / Désactiver"
        *ngIf="alertMode"
        [withoutBorder]="withoutBorder"
        icon="toggle-switch-on"
        (toggleAction)="toggleAlert.emit($event)"
      ></toggle-action>

      <custom-dialog *ngIf="includeMode && false">
        <mat-icon-button-content mat-icon-button matTooltip="Inclure/Exclure des analyses">
          <mat-icon>domain</mat-icon>
        </mat-icon-button-content>
        <dialog-content></dialog-content>
        <dialog-buttons>
          <div class="dialog__btn d-flex flex-column">
            <button mat-button (click)="toggleIncludeToAnalyse.emit(true)">
              <mat-icon mat-icon-button>domain</mat-icon>
              Inclure dans l'analyse
            </button>
            <button mat-button (click)="toggleIncludeToAnalyse.emit(false)">
              <mat-icon mat-icon-button>domain_disabled</mat-icon>
              Exclure de l'analyse
            </button>
          </div>
        </dialog-buttons>
      </custom-dialog>

      <vertuoz-delete
        *ngIf="removeMode"
        [showAlertImpactUsers]="showWarningMessage"
        (remove)="remove.emit($event)"
        [customMessage]="customMessage"
        element="{{ selectedItemsCount }} {{ itemType }}"
        [elementInfos]="elementInfos"
        [toolTipMessage]="toolTipDeleteMessage"
        [isDeletingDisabled]="isDeletingDisabled"
        isBulkActionSnackbar="isBulkActionSnackbar"
      ></vertuoz-delete>

      <!--custom content bloc-->
      <ng-container *ngIf="customContentMode">
        <!--Here a custom content for snackbar !!!! -->
        <ng-content></ng-content>
      </ng-container>

      <!-- Associate to/ dissociate from Groups -->
      <ng-container *ngIf="assignmentItemsTypes.indexOf(AssignmentItemsType.GROUP) !== -1">
        <!--association-->
        <ng-container *ngIf="associateMode">
          <button
            mat-icon-button
            *hasPermissions="[EnumPermissions.GROUP_CREATE]"
            vertuozAssignment
            [mode]="Mode.ASSOCIATE"
            [displaySearchText]="false"
            [fluidsAssociatedToContracts]="fluidsAssociatedToContracts"
            [levelId]="this.levelId"
            [domainId]="this.domainId"
            [newItemForm]="newItemMode"
            [actionLabel]="'Associer'"
            [title]="'Associer la sélection à un ou plusieurs groupes'"
            [assignmentItemsType]="AssignmentItemsType.GROUP"
            [customEmptyAddMessage]="customEmptyAddMessage"
            [customEmptyRemoveMessage]="customEmptyRemoveMessage"
            [customMessage]="''"
            [assignmentItemsOperation]="AssignmentItemsOperation.ADD"
            [selectedItems]="selectedItems"
            [selectionProperty]="selectionProperty"
            (assign)="associateTo($event, AssignmentItemsType.GROUP)"
            matTooltip="{{ 'Associer à un groupe' }}"
            class="ms-2"
            [matTooltipDisabled]="true"
          >
            <mat-icon>folder_special</mat-icon>

            <!-- Debug multiple CDK tooltip -->
            <span class="v-tooltip ms-n4">
              {{ 'Associer à un groupe' }}
            </span>
          </button>
        </ng-container>
        <!-- dissociation -->
        <ng-container *ngIf="dissociateMode">
          <button
            mat-icon-button
            *hasPermissions="[EnumPermissions.GROUP_CREATE]"
            vertuozAssignment
            [mode]="Mode.DISSOCIATE"
            [fluidsAssociatedToContracts]="fluidsAssociatedToContracts"
            [levelId]="this.levelId"
            [domainId]="this.domainId"
            [newItemForm]="false"
            [actionLabel]="'Dissocier'"
            [customEmptyAddMessage]="customEmptyAddMessage"
            [customEmptyRemoveMessage]="customEmptyRemoveMessage"
            [customMessage]="''"
            [title]="'Dissocier la sélection d\'un ou plusieurs groupes'"
            [assignmentItemsType]="AssignmentItemsType.GROUP"
            [assignmentItemsOperation]="AssignmentItemsOperation.REMOVE"
            [selectedItems]="selectedItems"
            [selectionProperty]="selectionProperty"
            (assign)="dissociateFrom($event, AssignmentItemsType.GROUP)"
            matTooltip="{{ 'Dissocier d\'un groupe' }}"
            class="mx-2"
            [matTooltipDisabled]="true"
          >
            <mat-icon svgIcon="folder_special_off"></mat-icon>

            <!-- Debug multiple CDK tooltip -->
            <span class="v-tooltip ms-n4">
              {{ "Dissocier d'un groupe" }}
            </span>
          </button>
        </ng-container>
      </ng-container>

      <!-- Associate to/ dissociate from supplier contracts -->
      <ng-container
        *ngIf="assignmentItemsTypes.indexOf(AssignmentItemsType.SUPPLIER_CONTRACT) !== -1"
      >
        <!-- association -->
        <ng-container *ngIf="associateMode">
          <button
            mat-icon-button
            *hasPermissions="[EnumPermissions.ENERGY_SUPPLIER_CONTRACTS_CREATE_UPDATE]"
            vertuozAssignment
            [mode]="Mode.ASSOCIATE"
            [displaySearchText]="false"
            [fluidsAssociatedToContracts]="fluidsAssociatedToContracts"
            [levelId]="this.levelId"
            [domainId]="this.domainId"
            [newItemForm]="newItemMode"
            [actionLabel]="'Associer'"
            [title]="'Associer la sélection à un ou plusieurs contrats'"
            [assignmentItemsType]="AssignmentItemsType.SUPPLIER_CONTRACT"
            [customEmptyAddMessage]="customEmptyAddMessage"
            [customEmptyRemoveMessage]="customEmptyRemoveMessage"
            [assignmentItemsOperation]="AssignmentItemsOperation.ADD"
            [selectedItems]="selectedItems"
            [selectionProperty]="selectionProperty"
            (assign)="associateTo($event, AssignmentItemsType.SUPPLIER_CONTRACT)"
            matTooltip="{{ 'Associer à un contrat' }}"
            class="ms-2"
            [matTooltipDisabled]="true"
          >
            <mat-icon>content_paste</mat-icon>

            <!-- Debug multiple CDK tooltip -->
            <span class="v-tooltip ms-n4">
              {{ 'Associer à un contrat' }}
            </span>
          </button>
        </ng-container>
        <!-- dissociation -->
        <ng-container *ngIf="dissociateMode">
          <button
            mat-icon-button
            *hasPermissions="[EnumPermissions.ENERGY_SUPPLIER_CONTRACTS_CREATE_UPDATE]"
            vertuozAssignment
            [mode]="Mode.DISSOCIATE"
            [fluidsAssociatedToContracts]="fluidsAssociatedToContracts"
            [levelId]="this.levelId"
            [domainId]="this.domainId"
            [newItemForm]="false"
            [actionLabel]="'Dissocier'"
            [customEmptyAddMessage]="customEmptyAddMessage"
            [customEmptyRemoveMessage]="customEmptyRemoveMessage"
            [title]="'Dissocier la sélection d\'un ou plusieurs contrats'"
            [assignmentItemsType]="AssignmentItemsType.SUPPLIER_CONTRACT"
            [assignmentItemsOperation]="AssignmentItemsOperation.REMOVE"
            [selectedItems]="selectedItems"
            [selectionProperty]="selectionProperty"
            (assign)="dissociateFrom($event, AssignmentItemsType.SUPPLIER_CONTRACT)"
            matTooltip="{{ 'Dissocier d\'un contrat' }}"
            class="mx-2"
            [matTooltipDisabled]="true"
          >
            <mat-icon>content_paste_off</mat-icon>

            <!-- Debug multiple CDK tooltip -->
            <span class="v-tooltip ms-n4">
              {{ "Dissocier d'un contrat" }}
            </span>
          </button>
        </ng-container>
      </ng-container>

      <!-- Associate to/ dissociate from data rules-->
      <ng-container *ngIf="assignmentItemsTypes.indexOf(AssignmentItemsType.DATARULE) !== -1">
        <!--association -->
        <ng-container *ngIf="associateMode">
          <button
            mat-icon-button
            *hasPermissions="[EnumPermissions.CONTROL_RULES_CREATE]"
            vertuozAssignment
            [mode]="Mode.ASSOCIATE"
            [displaySearchText]="true"
            [newItemForm]="newItemMode"
            [actionLabel]="'Associer'"
            [fluidsAssociatedToContracts]="fluidsAssociatedToContracts"
            [assignmentItemsType]="AssignmentItemsType.DATARULE"
            [assignmentItemsOperation]="AssignmentItemsOperation.ADD"
            [itemType]="itemType"
            [selectedItems]="selectedItems"
            (assign)="associateTo($event, AssignmentItemsType.DATARULE)"
            matTooltip="{{ 'Associer à des règles de contrôle' }}"
            class="ms-2"
            [matTooltipDisabled]="true"
          >
            <mat-icon>notifications</mat-icon>

            <!-- Debug multiple CDK tooltip -->
            <span class="v-tooltip ms-n4">
              {{ 'Associer à des règles de contrôle' }}
            </span>
          </button>
        </ng-container>
        <!-- dissociation -->
        <ng-container *ngIf="dissociateMode">
          <button
            mat-icon-button
            *hasPermissions="[EnumPermissions.CONTROL_RULES_CREATE]"
            vertuozAssignment
            [mode]="Mode.DISSOCIATE"
            [newItemForm]="false"
            [actionLabel]="'Dissocier'"
            [assignmentItemsType]="AssignmentItemsType.DATARULE"
            [assignmentItemsOperation]="AssignmentItemsOperation.REMOVE"
            [itemType]="itemType"
            [selectedItems]="selectedItems"
            (assign)="dissociateFrom($event, AssignmentItemsType.DATARULE)"
            matTooltip="{{ 'Dissocier de règles de contrôle' }}"
            class="mx-2"
            [matTooltipDisabled]="true"
          >
            <mat-icon>notifications_off</mat-icon>

            <!-- Debug multiple CDK tooltip -->
            <span class="v-tooltip ms-n4">
              {{ 'Dissocier de règles de contrôle' }}
            </span>
          </button>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>
