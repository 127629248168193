<div class="position-relative h-100">
  <div id="geo-menu" class="geo-menu overflow-auto h-100" [ngClass]="{ 'inline-mode': inlineMode }">
    <div
      class="wrap search-container overflow-hidden bg-white border-bottom border-primary-700 position-absolute top-0 start-0 w-100"
      *ngIf="showSearchBox"
    >
      <div class="search px-3">
        <mat-form-field class="w-100 px-2 pt-2 mb-n4">
          <input
            #search
            matInput
            type="text"
            (keyup)="onSearch(search.value)"
            placeholder="Rechercher dans {{ searchSection }} ..."
          />
          <span matSuffix>
            <button type="button" mat-icon-button (click)="hideSearch($event)" class="mb-2">
              <mat-icon>clear</mat-icon>
            </button>
          </span>
        </mat-form-field>
      </div>
    </div>

    <mat-tab-group
      [(selectedIndex)]="selectedTab"
      (selectedTabChange)="selectedTabChange($event)"
      dynamicHeight="true"
    >
      <mat-tab label="Géographie" *ngIf="withGeography">
        <ng-template matTabLabel>
          <div class="me-2">Géographie</div>
          <div class="tab-header-actions" *ngIf="this.updateMode">
            <span>
              <button mat-icon-button matTooltip="Rechercher" (click)="searchGeography($event)">
                <mat-icon>search</mat-icon>
              </button>
            </span>
            <span
              *ngIf="!inlineMode"
              [ngClass]="{
                'disabled-header-action': !defaultSelectionExist()
              }"
            >
              <button mat-icon-button matTooltip="Annuler la sélection" (click)="cancel($event)">
                <mat-icon>undo</mat-icon>
              </button>
            </span>
            <span
              [ngClass]="{
                'disabled-header-action': geographicSelection.length === 0
              }"
            >
              <button
                mat-icon-button
                matTooltip="Supprimer la sélection"
                (click)="resetAllSelection()"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </span>
          </div>
        </ng-template>
        <ng-template matTabContent>
          <!--la section géographie de pays à villes-->
          <div class="p-4 pt-0">
            <vertuoz-perimeter-selector
              #geographic
              selectedInfoLevel="Perimètre du groupe de"
              partialSelectedLabel="Filtres"
              selectedLabel="Sélection"
              [key]="geoSearchPerimeterKey"
              [loading]="loading"
              [displayParentsItemsFor]="[]"
              [selectedInfoLevel]="'Votre sélection contient'"
              [partialSelectedLabel]="partialSelectedLabel"
              [selectedLabel]="selectedLabel"
              [selection]="geographicSelection"
              [filterSelection]="geographyFilterSelection"
              [updateMode]="updateMode"
              [lowestLevelMode]="lowestLevelMode"
              [displayBeginLevel]="-3"
              [displayUntilLevel]="0"
              [dispatchHierachy]="dispatchHierachyEnum.onlyDisplayLevel"
            >
            </vertuoz-perimeter-selector>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab label="Patrimoine">
        <ng-template matTabLabel>
          <div class="me-2">Patrimoine</div>
          <div class="tab-header-actions" *ngIf="this.updateMode">
            <span>
              <button mat-icon-button matTooltip="Rechercher" (click)="searchEstates($event)">
                <mat-icon>search</mat-icon>
              </button>
            </span>
            <span
              *ngIf="!inlineMode"
              [ngClass]="{
                'disabled-header-action': !defaultSelectionExist()
              }"
            >
              <button mat-icon-button matTooltip="Annuler la sélection" (click)="cancel($event)">
                <mat-icon>undo</mat-icon>
              </button>
            </span>
            <span
              [ngClass]="{
                'disabled-header-action':
                  (estatePanelState && propertySelection.length === 0) ||
                  (publicSpacePanelState && publicSpaceSelection.length === 0)
              }"
            >
              <button
                mat-icon-button
                matTooltip="Supprimer la sélection"
                (click)="resetAllSelection()"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </span>
          </div>
        </ng-template>
        <ng-template matTabContent>
          <!--todo: complete filters section-->
          <div class="d-flex px-3 pt-3 filters-container">
            <vertuoz-infinite-scroll-mat-select
              class="col-4"
              (valueChanged)="regionChanged($event)"
              [selectedValue]="selectedRegionId"
              [data]="regions"
              [defaultOption]="{ label: 'Toutes les régions', id: null }"
              [total]="totalRegions"
              [title]="'Régions'"
              (appendItems)="appendRegionsItems($event)"
              [isLoading]="regionsIsLoading"
            ></vertuoz-infinite-scroll-mat-select>
            <vertuoz-infinite-scroll-mat-select
              class="col-4"
              (valueChanged)="countyChanged($event)"
              [selectedValue]="selectedCountytId"
              [data]="counties"
              [defaultOption]="{ label: 'Tous les départements', id: null }"
              [total]="totalCounties"
              [title]="'Départements'"
              (appendItems)="appendCountiesItems($event)"
              [isLoading]="countiesIsLoading"
            ></vertuoz-infinite-scroll-mat-select>
            <vertuoz-infinite-scroll-mat-select
              class="col-4"
              (valueChanged)="townChanged($event)"
              [selectedValue]="selectedTownId"
              [data]="towns"
              [defaultOption]="{ label: 'Toutes les villes', id: null }"
              [total]="totalTowns"
              [title]="'Villes'"
              [limit]="pageSize"
              (appendItems)="appendTownsItems($event)"
              [isLoading]="tonwsIsLoading"
            ></vertuoz-infinite-scroll-mat-select>
          </div>
          <mat-accordion class="patrimony-collapse" [multi]="false" class="w-100">
            <ng-container *ngIf="withProperty">
              <div class="p-4 pt-0">
                <mat-expansion-panel
                  *hasDomains="[domainEnum.Property]"
                  [expanded]="estatePanelState"
                  (opened)="estatePanelState = true; publicSpacePanelState = false"
                  (closed)="estatePanelState = false; publicSpacePanelState = true"
                  (afterCollapse)="onAfterCollapse($event)"
                >
                  <mat-expansion-panel-header *ngIf="withPublicSpace">
                    <mat-panel-title class="color-primary-700 font-18"> Immobilier</mat-panel-title>
                    <mat-slide-toggle
                      (click)="$event.preventDefault()"
                      matTooltip="{{
                        estatePanelState
                          ? 'Désélectionner le patrimoine immobilier'
                          : 'Sélectionner le patrimoine immobilier'
                      }}"
                      [checked]="estatePanelState"
                      color="primary"
                      class="mt-1 me-3"
                    ></mat-slide-toggle>
                  </mat-expansion-panel-header>

                  <!--la section patrimoine/immobilier d'établissements à zones-->
                  <vertuoz-perimeter-selector
                    #property
                    selectedInfoLevel="Perimétre du groupe de"
                    partialSelectedLabel="Filtres"
                    selectedLabel="Sélection"
                    [updateMode]="updateMode"
                    [key]="propertySearchPerimeterKey"
                    [displayParentsItemsFor]="[1]"
                    [loading]="loading"
                    [selectedInfoLevel]="'Votre sélection contient'"
                    [partialSelectedLabel]="partialSelectedLabel"
                    [selectedLabel]="selectedLabel"
                    [selection]="propertySelection"
                    [filterSelection]="propertyFilterSelection"
                    [lowestLevelMode]="lowestLevelMode"
                    [displayBeginLevel]="propertyLevelBegin"
                    [displayUntilLevel]="propertyLevelFinish"
                    [dispatchHierachy]="dispatchHierachyEnum.onlyDisplayLevel"
                    [tertiaryDecreeFilter]="tertiaryDecreeFilter"
                    (selectionChange)="_selectionChange()"
                  >
                  </vertuoz-perimeter-selector>
                </mat-expansion-panel>
              </div>
            </ng-container>
            <ng-container *ngIf="withPublicSpace">
              <div class="p-4 pt-0">
                <mat-expansion-panel
                  *hasDomains="[domainEnum.PublicSpace]"
                  (opened)="publicSpacePanelState = true; estatePanelState = false"
                  (closed)="publicSpacePanelState = false; estatePanelState = true"
                  [expanded]="publicSpacePanelState"
                  (afterCollapse)="onAfterCollapse($event)"
                >
                  <mat-expansion-panel-header *ngIf="withProperty">
                    <mat-panel-title class="font-18"> Espace Public</mat-panel-title>
                    <mat-slide-toggle
                      (click)="$event.preventDefault()"
                      matTooltip="{{
                        publicSpacePanelState
                          ? 'Désélectionner le patrimoine espace public'
                          : 'Sélectionner le patrimoine espace public'
                      }}"
                      [checked]="publicSpacePanelState"
                      color="primary"
                      class="mt-1 me-3"
                    ></mat-slide-toggle>
                  </mat-expansion-panel-header>
                  <!--la section patrimoine/EP de rue à armoire-->
                  <vertuoz-perimeter-selector
                    #publicSpace
                    selectedInfoLevel="Perimétre du groupe de"
                    partialSelectedLabel="Filtres"
                    selectedLabel="Sélection"
                    [key]="propertySearchPerimeterKey"
                    [updateMode]="updateMode"
                    [displayParentsItemsFor]="[11]"
                    [loading]="loading"
                    [selectedInfoLevel]="'Votre sélection contient'"
                    [partialSelectedLabel]="partialSelectedLabel"
                    [selectedLabel]="selectedLabel"
                    [selection]="publicSpaceSelection"
                    [filterSelection]="publicSpaceFilterSelection"
                    [lowestLevelMode]="lowestLevelMode"
                    [displayBeginLevel]="11"
                    [displayUntilLevel]="13"
                    [dispatchHierachy]="dispatchHierachyEnum.onlyDisplayLevel"
                  >
                  </vertuoz-perimeter-selector>
                </mat-expansion-panel>
              </div>
            </ng-container>
          </mat-accordion>
        </ng-template>
      </mat-tab>
      <mat-tab label="Mes groupes" *ngIf="!inlineMode">
        <ng-template matTabLabel>
          <div [ngClass]="activatedGroupId ? 'active-marker-before' : ''" class="me-2">
            Mes groupes
          </div>
          <div class="tab-header-actions" *ngIf="this.updateMode">
            <span>
              <button mat-icon-button matTooltip="Rechercher" (click)="searchGroups($event)">
                <mat-icon>search</mat-icon>
              </button>
            </span>
          </div>
        </ng-template>
        <ng-template matTabContent>
          <div>
            <groups-tab
              #groupsTab
              [activatedGroupId]="activatedGroupId"
              [searchKey]="groupsSearchKey"
              (applyGroupPerimeter)="applyPerimeter($event)"
            ></groups-tab>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
  <!--hide the footer for groups tab (index = 2)-->
  <div
    class="geo-menu-footer-actions position-absolute bottom-0 w-100 text-end"
    *ngIf="!inlineMode && selectedTab !== 2"
  >
    <div class="px-4 py-3">
      <button
        mat-flat-button
        color="primary"
        class="btn-md"
        [disabled]="!defaultSelectionExist()"
        (click)="applyPerimeter()"
      >
        Appliquer
      </button>
    </div>
  </div>

  <div
    class="loading-wrapper position-absolute w-100 h-100 d-flex justify-content-center align-items-center top-0"
    id="loading-layer"
    *ngIf="!inlineMode ? loadingPerimeter || applyingPerimeter : loading"
  >
    <div class="loading-message">
      <mat-spinner class="mx-auto" [strokeWidth]="4" [diameter]="50"></mat-spinner>
      <p class="font-14 fw-bold text-center color-primary-700 pt-2">
        {{
          loadingPerimeter
            ? 'Chargement du périmètre..'
            : applyingPerimeter
            ? 'Application du périmètre..'
            : 'Chargement'
        }}
      </p>
    </div>
  </div>
</div>
