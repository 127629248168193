<section class="delete-modal px-2 pb-3">
  <h2 class="delete-modal__title font-18 font-weight-bold mb-2" mat-dialog-title>{{ title }}</h2>

  <mat-dialog-content class="bg-gray-100 px-4 pt-3 pb-2 mb-2">
    <p>{{ introduction }}</p>
    <p>{{ message }}</p>
    <p *ngIf="conclude">{{ conclude }}</p>
    <p *ngIf="sign">{{ sign }}</p>
    <ul *ngIf="!dictionary && labels && labels.length > 0">
      <li *ngFor="let label of labels">
        <a href="#" [routerLink]="[routerPath, label.id]" (click)="close()">{{ label.label }}</a>
      </li>
    </ul>
    <ul *ngIf="!labels && dictionary">
      <li *ngFor="let item of dictionary | keyvalue">
        <strong> {{ dictionaryParentTypeName }}: </strong>{{ item.key }}
        <ul>
          <li *ngFor="let val of item.value">
            <a target="_blank" [routerLink]="[routerPath, val.id]"> {{ val.label }} </a>
          </li>
        </ul>
      </li>
    </ul>
  </mat-dialog-content>

  <mat-dialog-actions class="justify-content-end">
    <button type="button" mat-flat-button color="primary" class="btn-md" (click)="close()">
      Fermer
    </button>
  </mat-dialog-actions>
</section>
