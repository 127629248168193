<ng-container *ngIf="linkedFiles && linkedFiles.length > 0">
  <div class="row">
    <div class="col-5">
      <strong *ngIf="linkedFiles.length < 2" class="d-inline-block w-50">Fichier lié</strong>
      <strong *ngIf="linkedFiles.length > 1" class="d-inline-block w-50">Fichiers liés</strong>
    </div>
    <div class="col-7 ps-0">
      <!-- FILES -->
      <div *ngFor="let file of linkedFiles" (click)="onDownloadFile(file)" class="ps-0">
        <vertuoz-loader [showLoader]="isLoading[file.fileId]" [size]="'small'">
          <div class="col-12 my-2 align-items-center justify-content-center">
            <div class="row">
              <div class="col-1">
                <mat-icon
                  [svgIcon]="file.fileSvgIcon"
                  matTooltip="Télécharger le fichier"
                ></mat-icon>
              </div>
              <div class="col-9">
                <span class="overflow-wrap">
                  {{ file.fileName }}
                </span>
              </div>
              <div class="col-2">{{ file.kiloBytesSize }} Ko</div>
            </div>
          </div>
        </vertuoz-loader>
      </div>
    </div>
  </div>
</ng-container>
