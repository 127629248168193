import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Subject } from 'rxjs';
import { ProgressBarService } from './progressbar.service';

import { takeUntil } from 'rxjs/operators';
@Component({
    selector: 'vertuoz-progressbar',
    templateUrl: './progressbar.component.html',
    styleUrls: ['./progressbar.component.scss']
})
export class ProgressBarComponent implements OnInit, OnDestroy {
    @Input() showProgressBar: boolean;
    @Input() myValue: number;
    @Input() modeProgressBar: string;

    /** enregistrement pour desouscrire */
    private _onDestroy = new Subject<void>();

    constructor(private progressBarService: ProgressBarService) {}

    ngOnInit(): void {
        this.progressBarService.status
            .pipe(takeUntil(this._onDestroy))
            .subscribe((val: boolean) => {
                this.showProgressBar = val;
            });

        this.progressBarService.value
            .pipe(takeUntil(this._onDestroy))
            .subscribe((value: number) => {
                this.myValue = value;
            });

        this.progressBarService.progressMode
            .pipe(takeUntil(this._onDestroy))
            .subscribe((mode: string) => {
                this.modeProgressBar = mode;
            });
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }
}
