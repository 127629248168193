import { Inject, Injectable, LOCALE_ID, OnDestroy } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { CldrIntlService, IntlService } from '@progress/kendo-angular-intl';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class I18nService implements OnDestroy {
    private _onDestroy = new Subject<void>();
    // the locales app supports
    locales = [
        { label: 'English', value: 'en-US' },
        { label: 'Français', value: 'fr-FR' }
    ];

    private cultureNextChange: Subject<string>;
    public cultureChanged: Observable<string>;

    // the user's locale
    public detectedLocale = '';

    // the default locale
    public locale = this.locales[0].value;

    constructor(
        public translate: TranslateService,
        @Inject(LOCALE_ID) public localeId: string,
        public intlService: IntlService
    ) {
        this.cultureNextChange = new Subject<string>();
        this.cultureChanged = this.cultureNextChange.asObservable();
    }

    public init(language: string): void {
        this.translate.addLangs(['fr-FR', 'en-US']);
        this.translate.setDefaultLang(language);
        this.updateLanguage(language);

        this.translate.onLangChange.pipe(takeUntil(this._onDestroy)).subscribe(res => {
            this.onTranslateLanguageUpdated(res.lang);
        });
    }

    public ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    /**
     * Change language at runtime, yes !
     * @param language selectionné
     */
    public updateLanguage(language: string): void {
        // Ngx translate service
        this.translate.use(language);
    }

    private onTranslateLanguageUpdated(language: string): void {
        this.locale = language;

        // Kendo detection, update LocaleId
        this.localeId = this.locale;
        (<CldrIntlService>this.intlService).localeId = this.locale;

        this.setStorageLanguage();
        this.cultureNextChange.next(this.locale);
    }

    // Update locale storage with currentLanguage
    private setStorageLanguage(): void {
        localStorage.setItem('currentLanguage', JSON.stringify(this.locale));
    }
}
