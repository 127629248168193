import { Label } from '@vertuoz/vertuoz-library';
import { ContractModel } from '../contract/contract.model';
import { RoleModel } from '../role/role.model';
import { UserModel } from './user.model';

export interface UserContext {
    user: UserModel;
    role: RoleModel;
    contract: ContractModel;
}

export class UserTracking {
    startDateSession: Date;
    session: string;
    userId: number;
    clientId: number;
    contractId: number;
    organizationId: number;
    roleId: number;
    previousPage: string;
    currentPage: string;
    startDatePage: Date;
    isEstimated: boolean;
    browserName: string;
    browserVersion: string;
    endDateSession: Date;

    constructor(init: Partial<UserTracking>) {
        if (init) {
            Object.assign(this, init);
        }
    }
}
