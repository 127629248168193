import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()

/* Ce service à utiliser dans le cas de validation des formulaires ou autres
   TODO: traiter le cas d'avoir plusieurs loader dans le même composant
*/
export class ProgressBarService {
    public status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public value: BehaviorSubject<number> = new BehaviorSubject<number>(35);
    public progressMode: BehaviorSubject<string> = new BehaviorSubject<string>('indeterminate');

    display(value: boolean) {
        this.status.next(value);
    }

    setValue(value: number) {
        this.value.next(value);
    }

    setProgressMode(value: string) {
        this.progressMode.next(value);
    }
}
