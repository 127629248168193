import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { ToasterService } from '@vertuoz/vertuoz-library';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { EnergySupplierContractsService } from '@app/core/http/energy-supplier-contracts/energy-supplier-contracts.service';
import { MeteringPointsService } from '@app/core/http/meteringplan/meteringpoint/metering-point.service';
import { EnergySupplierContractFilter } from '@app/core/models/energy-supplier-contracts/energy-supplier-contract.filter';
import { EnergySupplierContractModel } from '@app/core/models/energy-supplier-contracts/energy-supplier-contracts.model';
import { MeteringPointsSupplierContractsModel } from '@app/core/models/meteringplan/meteringpoint/metering-points-supplier-contracts.model';
import { PagedContext } from '@app/features/shared/models/paged-context';
import { CustomDialogComponent } from '@app/shared/bulk-action-snackbar/custom-dialog/custom-dialog.component';
import { PerimeterScope } from '@app/shared/constants/filters.enum';
import { Operation } from '@app/shared/constants/operation.enum';

@Component({
    selector: 'associate-to-supplier-contract',
    templateUrl: './associate-to-supplier-contract.component.html',
    styleUrls: ['./associate-to-supplier-contract.component.scss']
})
export class AssociateToSupplierContractComponent implements OnInit, OnDestroy {
    @Input() fluidsAssociatedToContracts: number[];
    @Input() originY: 'center' | 'bottom' | 'top' = 'top';
    @Input() originX: 'center' | 'start' | 'end' = 'center';
    @Input() overlayX: 'center' | 'start' | 'end' = 'center';
    @Input() overlayY: 'center' | 'bottom' | 'top' = 'bottom';

    @ViewChild('customDialogAdd') public customDialogAdd: CustomDialogComponent;
    @ViewChild('customDialogRemove') public customDialoRemove: CustomDialogComponent;

    @Input()
    set meteringPointIds(value: number[]) {
        this._meteringPointIds = value;
        // this.setAddSupplierContracts();
        // this.setRemoveSupplierContracts();
    }

    loadingText = '';
    isSupplierContractsLoading = false;
    isAddPanelShowed = false;
    isRemovePanelShowed = false;

    addToSupplierContractsFormGroup: FormGroup;
    removeFromSupplierContractsFormGroup: FormGroup;

    allSupplierContracts: EnergySupplierContractModel[] = [];
    addSupplierContracts: EnergySupplierContractModel[] = [];
    removeSupplierContracts: EnergySupplierContractModel[] = [];

    private _onDestroy = new Subject<void>();
    private _meteringPointIds: number[] = [];

    @ViewChild('scrollDiv') scrollElement: ElementRef;

    constructor(
        private formBuilder: FormBuilder,
        private energySupplierContractsService: EnergySupplierContractsService,
        private meteringPointsService: MeteringPointsService,
        private toasterService: ToasterService
    ) {}

    ngOnInit(): void {}

    public toggleAddToSupplierContracts(): void {
        this.isAddPanelShowed = true;
        this.isRemovePanelShowed = false;
        this.getSupplierContracts(Operation.ADD);
    }

    public toggleRemoveFromSupplierContracts(): void {
        this.isAddPanelShowed = false;
        this.isRemovePanelShowed = true;
        this.getSupplierContracts(Operation.REMOVE);
    }

    public getSupplierContracts(operation: Operation): void {
        this.loadingText = 'Chargement des contrats fournisseurs...';
        this.isSupplierContractsLoading = true;

        const filter =
            operation === Operation.ADD
                ? <EnergySupplierContractFilter>{
                      fluidsAssociatedToContract: this.fluidsAssociatedToContracts
                  } // To add to the supplier contracts depending on the selected fluids
                : <EnergySupplierContractFilter>{ meteringPointIds: this._meteringPointIds }; // To get the associated supplier contracts for removing it

        this.energySupplierContractsService
            .getEnergySupplierContracts(filter, PerimeterScope.NoScope, new PagedContext())
            .subscribe(
                result => {
                    if (result) {
                        this.allSupplierContracts = [...result.results];
                    } else {
                        this.allSupplierContracts = [];
                    }

                    if (operation === Operation.ADD) {
                        this.setAddSupplierContracts();
                    } else {
                        this.setRemoveSupplierContracts();
                    }

                    this.isSupplierContractsLoading = false;
                },
                () => {
                    this.isSupplierContractsLoading = false;
                }
            );
    }

    private setAddSupplierContracts(): void {
        this.addSupplierContracts = [];
        this.allSupplierContracts.forEach(ctr => {
            this.addSupplierContracts.push(ctr);
        });

        this.initAddToSupplierContractsForm();
    }

    private setRemoveSupplierContracts(): void {
        this.removeSupplierContracts = [...this.allSupplierContracts];
        this.initRemoveFromSupplierContractsForm();
    }

    private initAddToSupplierContractsForm(): void {
        const controls = this.addSupplierContracts.map(c => new FormControl(false));

        this.addToSupplierContractsFormGroup = this.formBuilder.group({
            selectedSupplierContracts: new FormArray(controls, Validators.requiredTrue)
        });
    }

    private initRemoveFromSupplierContractsForm(): void {
        const controls = this.removeSupplierContracts.map(() => new FormControl(false));
        this.removeFromSupplierContractsFormGroup = this.formBuilder.group({
            selectedSupplierContracts: new FormArray(controls)
        });
    }

    public onAddToSupplierContractsToggleClick(): void {
        this.isAddPanelShowed = !this.isAddPanelShowed;
    }

    public onAddToSupplierContracts(event: MouseEvent): void {
        event.stopPropagation();
        this.loadingText = 'Mise à jour des PDC en cours';
        this.updateSelectedSupplierContracts(Operation.ADD);
    }

    public onRemoveFromSupplierContractsToggleClick(): void {
        this.isRemovePanelShowed = !this.isRemovePanelShowed;
    }

    public onRemoveFromSupplierContracts(event: MouseEvent): void {
        event.stopPropagation();
        this.loadingText = 'Mise à jour des PDC en cours';
        this.updateSelectedSupplierContracts(Operation.REMOVE);
    }

    private updateSelectedSupplierContracts(operation: Operation): void {
        let selectedSupplierContractsIds = [];
        if (operation === Operation.ADD) {
            this.isSupplierContractsLoading = true;
            selectedSupplierContractsIds = this.addToSupplierContractsFormGroup.value.selectedSupplierContracts
                .map((v, i) => (v ? this.addSupplierContracts[i].energySupplierContractId : null))
                .filter(v => v !== null);
        } else {
            this.isSupplierContractsLoading = true;
            selectedSupplierContractsIds = this.removeFromSupplierContractsFormGroup.value.selectedSupplierContracts
                .map((v, i) =>
                    v ? this.removeSupplierContracts[i].energySupplierContractId : null
                )
                .filter(v => v !== null);
        }

        this.patchSelectedSupplierContracts(
            this._meteringPointIds,
            selectedSupplierContractsIds,
            operation
        );
    }

    private patchSelectedSupplierContracts(
        meteringPointIds: number[],
        supplierContractIds: number[],
        operation: Operation
    ): void {
        const selectedMeteringPoints = [];

        supplierContractIds.forEach(supplierContractId => {
            selectedMeteringPoints.push(<MeteringPointsSupplierContractsModel>{
                meteringPointIds: meteringPointIds,
                supplierContractId: supplierContractId
            });
        });

        if (operation === Operation.ADD) {
            this.meteringPointsService
                .associateMeteringPointToSupplierContract(selectedMeteringPoints)
                .pipe(takeUntil(this._onDestroy))
                .subscribe(
                    () => {
                        this.toasterService.showSuccess(this.getAddMessage());
                        this.isSupplierContractsLoading = false;
                        this.isAddPanelShowed = false;
                        this.customDialogAdd.closePanel();
                    },
                    () => {
                        this.toasterService.showError(
                            `Erreur lors de la modification du contrat fournisseur.`
                        );
                        this.isSupplierContractsLoading = false;
                    }
                );
        } else {
            this.meteringPointsService
                .dissociateMeteringPointsFromSupplierContract(selectedMeteringPoints)
                .pipe(takeUntil(this._onDestroy))
                .subscribe(
                    () => {
                        this.toasterService.showSuccess(this.getRemoveMessage());
                        this.isSupplierContractsLoading = false;
                        this.isRemovePanelShowed = false;
                        this.customDialoRemove.closePanel();
                    },
                    () => {
                        this.toasterService.showError(
                            `Erreur lors du retrait des points de comptages du/des contrat(s) fournisseur(s) sélectionné(s).`
                        );
                        this.isSupplierContractsLoading = false;
                    }
                );
        }
    }

    private getAddMessage(): string {
        return 'Les points de comptage sélectionnés sont bien ajoutés au(x) contrat(s) fournisseurs sélectionné(s).';
    }

    private getRemoveMessage(): string {
        return 'Les points de comptage sélectionnés sont bien retirés du/des contrat(s) fournisseur(s) sélectionné(s)';
    }

    public ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }
}
