import { Pipe, PipeTransform } from '@angular/core';

/**
 * pipe to filter formArray
 */
@Pipe({
    name: 'formArrayFilterPipe'
})
export class FormArrayFilterPipe implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transform(items: any[], searchText: string): any[] {
        if (!items) {
            return [];
        }
        if (!searchText) {
            return items;
        }

        searchText = searchText.toLowerCase();
        return items.filter(it => {
            // return it.value.device_id.toLowerCase().includes(searchText);
        });
    }
}
