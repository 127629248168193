import { KeyValue } from '@angular/common';
import { Injectable } from '@angular/core';

import * as moment from 'moment';

import { PeriodEnum, TimestepEnum } from '@app/shared/constants/date-time.enum';

@Injectable({ providedIn: 'root' })
export class DateTimeService {
    public format: string;
    public isMeasure: boolean;

    // tableau des liens entre les différentes valeurs des listes.
    private periodDates: Array<KeyValue<PeriodEnum, Array<moment.Moment>>>; // dates (début et fin) des périodes
    private timestepsDatePickerConfig: Array<KeyValue<TimestepEnum, Array<string>>>; // format des dates des périodes
    private periodTimesteps: Array<KeyValue<PeriodEnum, Array<TimestepEnum>>>; // échelles temporelles des périodes

    constructor() {
        this.init();
        this.format = 'L';
    }

    //#region méthodes TIMESTEPS
    /**
     * retourne les échelles temporelles disponibles pour la période passée en paramètre
     * @param period
     */
    public getPeriodTimesteps(period: PeriodEnum): TimestepEnum[] {
        if (this.periodTimesteps != null) {
            const periodTimesteps = this.periodTimesteps.find(pts => pts.key === period);
            return periodTimesteps ? periodTimesteps.value : null;
        } else {
            return;
        }
    }
    //#endregion

    //#region méthodes DATES

    public setFormat(timeStep: TimestepEnum): void {
        this.format = timeStep
            ? this.timestepsDatePickerConfig.find(df => df.key === timeStep).value[0]
            : 'L';
    }

    public getFormat(timeStep: TimestepEnum): string {
        return timeStep
            ? this.timestepsDatePickerConfig.find(df => df.key === timeStep).value[0]
            : 'L';
    }

    public getStartView(timeStep: TimestepEnum): string {
        return timeStep
            ? this.timestepsDatePickerConfig.find(df => df.key === timeStep).value[1]
            : 'month';
    }

    /**
     * retourne la date de début de la période passée en paramètre
     * @param period
     */
    public getPeriodStartDate(
        period: PeriodEnum,
        timeStep: TimestepEnum,
        minDate?: moment.Moment
    ): moment.Moment {
        if (this.periodDates) {
            const pdates = this.periodDates.find(pd => pd.key === period);
            if (pdates && pdates.value[0]) {
                return this.getStartOfDateByTimestep(pdates.value[0], timeStep, minDate);
            }
        }
        return;
    }

    /**
     * retourne la date de fin de la période passée en paramètre
     * @param period
     */
    public getPeriodEndDate(
        period: PeriodEnum,
        timestep: TimestepEnum,
        maxDate?: moment.Moment
    ): moment.Moment {
        if (this.periodDates) {
            const pdates = this.periodDates.find(pd => pd.key === period);
            if (pdates && pdates.value[1]) {
                return this.getEndOfDateByTimestep(pdates.value[1], timestep, maxDate);
            }
        }
        return;
    }

    public getStartOfDateByTimestep(
        startDate: moment.Moment,
        timestep: TimestepEnum,
        minDate?: moment.Moment
    ): moment.Moment {
        if (startDate) {
            let date: moment.Moment;
            switch (timestep) {
                case TimestepEnum.Yearly:
                    date = startDate.clone().startOf('year');
                    break;
                case TimestepEnum.Monthly:
                    date = startDate.clone().startOf('month');
                    break;
                case TimestepEnum.Weekly:
                    date = startDate.clone().startOf('isoWeek');
                    break;
                case TimestepEnum.Daily:
                    date = startDate.clone().startOf('day');
                    break;
                case TimestepEnum.Hourly:
                    date = startDate.clone().startOf('hour');
                    break;
                case TimestepEnum.Minutes10:
                    const minutes = +(startDate.get('minute') / 10).toFixed(0) * 10;
                    date = startDate
                        .clone()
                        .startOf('hour')
                        .set({ minute: minutes });
                    break;
                case TimestepEnum.Minutes:
                    date = startDate.clone().startOf('minute');
                    break;
            }
            if (minDate && minDate < date) {
                date = minDate;
            }

            return date;
        }
    }
    public getEndOfDateByTimestep(
        endDate: moment.Moment,
        timestep: TimestepEnum,
        maxDate?: moment.Moment
    ): moment.Moment {
        if (endDate) {
            let date: moment.Moment;
            switch (timestep) {
                case TimestepEnum.Yearly:
                    date = endDate.clone().endOf('year');
                    break;
                case TimestepEnum.Monthly:
                    date = endDate.clone().endOf('month');
                    break;
                case TimestepEnum.Weekly:
                    date = endDate.clone().endOf('isoWeek');
                    break;
                case TimestepEnum.Daily:
                    date = endDate.clone().endOf('day');
                    break;
                case TimestepEnum.Hourly:
                    date = endDate.clone().endOf('hour');
                    break;
                case TimestepEnum.Minutes10:
                    const minutes = +(endDate.get('minute') / 10).toFixed(0) * 10;
                    date = endDate
                        .clone()
                        .startOf('hour')
                        .set({ minute: minutes });
                    break;
                case TimestepEnum.Minutes:
                    date = endDate.clone().endOf('minute');
                    break;
            }
            if (maxDate && maxDate < date) {
                date = maxDate;
            }
            return date;
        }
    }

    //#endregion

    //#region INITIALISATION DES LISTES DE DEPENDANCES
    /**
     * Exectue la liste des méthodes d'initialisation
     */
    public init(): void {
        this.initPeriodsDate();
        this.initPeriodsTimesteps();
        this.initTimestepsDatePickerConfig();
    }

    /**
     * Initialise les tableaux de dates (date début et date fin) disponibles pour chacune des périodes
     */
    private initPeriodsDate(): void {
        // Initialisation des dates pour chaque périodes
        this.periodDates = new Array<KeyValue<PeriodEnum, Array<moment.Moment>>>();
        this.periodDates.push({
            key: PeriodEnum.Today,
            value: [moment(), moment()]
        });
        this.periodDates.push({
            key: PeriodEnum.Yesterday,
            value: [moment().subtract(1, 'days'), moment().subtract(1, 'days')]
        });
        this.periodDates.push({
            key: PeriodEnum.DayBeforeYesterday,
            value: [moment().subtract(2, 'days'), moment().subtract(2, 'days')]
        });
        this.periodDates.push({
            key: PeriodEnum.Last3Days,
            value: [moment().subtract(3, 'days'), moment()]
        });
        this.periodDates.push({
            key: PeriodEnum.Last7Days,
            value: [moment().subtract(7, 'days'), moment()]
        });
        this.periodDates.push({
            key: PeriodEnum.CurrentWeek,
            value: [
                moment()
                    .startOf('week')
                    .add(1, 'day'),
                moment()
            ]
        });
        this.periodDates.push({
            key: PeriodEnum.PreviousWeek,
            value: [
                moment()
                    .startOf('week')
                    .subtract(6, 'days'),
                moment().startOf('week')
            ]
        });
        this.periodDates.push({
            key: PeriodEnum.Last30Days,
            value: [moment().subtract(30, 'days'), moment()]
        });
        this.periodDates.push({
            key: PeriodEnum.CurrentMonth,
            value: [moment().startOf('month'), moment()]
        });
        this.periodDates.push({
            key: PeriodEnum.PreviousMonth,
            value: [
                moment()
                    .startOf('month')
                    .subtract(1, 'months'),
                moment()
                    .endOf('month')
                    .subtract(1, 'months')
            ]
        });
        this.periodDates.push({
            key: PeriodEnum.Last3Months,
            value: [
                moment()
                    .startOf('month')
                    .subtract(2, 'months'),
                moment()
            ]
        });
        this.periodDates.push({
            key: PeriodEnum.CurrentYear,
            value: [moment().startOf('year'), moment()]
        });
        this.periodDates.push({
            key: PeriodEnum.Last13Months,
            value: [
                moment()
                    .subtract(12, 'months')
                    .startOf('month'),
                moment()
            ]
        });
        this.periodDates.push({
            key: PeriodEnum.Last730Days,
            value: [moment().subtract(2, 'years'), moment()]
        });
        this.periodDates.push({
            key: PeriodEnum.Last1095Days,
            value: [moment().subtract(3, 'years'), moment()]
        });
        this.periodDates.push({
            key: PeriodEnum.PreviousYear,
            value: [
                moment()
                    .subtract(1, 'years')
                    .startOf('year'),
                moment()
                    .subtract(1, 'years')
                    .endOf('year')
            ]
        });
        this.periodDates.push({
            key: PeriodEnum.Last2Years,
            value: [
                moment()
                    .subtract(2, 'years')
                    .startOf('year'),
                moment()
                    .subtract(1, 'years')
                    .endOf('year')
            ]
        });
        this.periodDates.push({
            key: PeriodEnum.Last3Years,
            value: [
                moment()
                    .subtract(3, 'years')
                    .startOf('year'),
                moment()
                    .subtract(1, 'years')
                    .endOf('year')
            ]
        });
    }

    /**
     * Initialise les tableaux d'échelles temporelles disponibles pour chacune des périodes
     */
    private initPeriodsTimesteps(): void {
        this.periodTimesteps = new Array<KeyValue<PeriodEnum, Array<TimestepEnum>>>();
        if (this.isMeasure) {
            this.periodTimesteps.push({
                key: PeriodEnum.Last7Days,
                value: [TimestepEnum.Daily, TimestepEnum.Hourly, TimestepEnum.Minutes]
            });
            this.periodTimesteps.push({
                key: PeriodEnum.CurrentWeek,
                value: [
                    TimestepEnum.Weekly,
                    TimestepEnum.Daily,
                    TimestepEnum.Hourly,
                    TimestepEnum.Minutes
                ]
            });
            this.periodTimesteps.push({
                key: PeriodEnum.PreviousWeek,
                value: [
                    TimestepEnum.Weekly,
                    TimestepEnum.Daily,
                    TimestepEnum.Hourly,
                    TimestepEnum.Minutes
                ]
            });
            this.periodTimesteps.push({
                key: PeriodEnum.Last30Days,
                value: [
                    TimestepEnum.Weekly,
                    TimestepEnum.Daily,
                    TimestepEnum.Hourly,
                    TimestepEnum.Minutes
                ]
            });
            this.periodTimesteps.push({
                key: PeriodEnum.CurrentMonth,
                value: [
                    TimestepEnum.Monthly,
                    TimestepEnum.Weekly,
                    TimestepEnum.Daily,
                    TimestepEnum.Hourly,
                    TimestepEnum.Minutes
                ]
            });
            this.periodTimesteps.push({
                key: PeriodEnum.PreviousMonth,
                value: [
                    TimestepEnum.Monthly,
                    TimestepEnum.Weekly,
                    TimestepEnum.Daily,
                    TimestepEnum.Hourly,
                    TimestepEnum.Minutes
                ]
            });
        } else {
            this.periodTimesteps.push({
                key: PeriodEnum.Last7Days,
                value: [
                    TimestepEnum.Daily,
                    TimestepEnum.Hourly,
                    TimestepEnum.Minutes10,
                    TimestepEnum.Minutes
                ]
            });
            this.periodTimesteps.push({
                key: PeriodEnum.CurrentWeek,
                value: [
                    TimestepEnum.Weekly,
                    TimestepEnum.Daily,
                    TimestepEnum.Hourly,
                    TimestepEnum.Minutes10,
                    TimestepEnum.Minutes
                ]
            });
            this.periodTimesteps.push({
                key: PeriodEnum.PreviousWeek,
                value: [
                    TimestepEnum.Weekly,
                    TimestepEnum.Daily,
                    TimestepEnum.Hourly,
                    TimestepEnum.Minutes10,
                    TimestepEnum.Minutes
                ]
            });
            this.periodTimesteps.push({
                key: PeriodEnum.Last30Days,
                value: [
                    TimestepEnum.Weekly,
                    TimestepEnum.Daily,
                    TimestepEnum.Hourly,
                    TimestepEnum.Minutes10,
                    TimestepEnum.Minutes
                ]
            });
            this.periodTimesteps.push({
                key: PeriodEnum.CurrentMonth,
                value: [
                    TimestepEnum.Monthly,
                    TimestepEnum.Weekly,
                    TimestepEnum.Daily,
                    TimestepEnum.Hourly,
                    TimestepEnum.Minutes10,
                    TimestepEnum.Minutes
                ]
            });
            this.periodTimesteps.push({
                key: PeriodEnum.PreviousMonth,
                value: [
                    TimestepEnum.Monthly,
                    TimestepEnum.Weekly,
                    TimestepEnum.Daily,
                    TimestepEnum.Hourly,
                    TimestepEnum.Minutes10,
                    TimestepEnum.Minutes
                ]
            });
        }
        this.periodTimesteps.push({
            key: PeriodEnum.Today,
            value: [
                TimestepEnum.Daily,
                TimestepEnum.Hourly,
                TimestepEnum.Minutes10,
                TimestepEnum.Minutes
            ]
        });
        this.periodTimesteps.push({
            key: PeriodEnum.Yesterday,
            value: [
                TimestepEnum.Daily,
                TimestepEnum.Hourly,
                TimestepEnum.Minutes10,
                TimestepEnum.Minutes
            ]
        });
        this.periodTimesteps.push({
            key: PeriodEnum.DayBeforeYesterday,
            value: [
                TimestepEnum.Daily,
                TimestepEnum.Hourly,
                TimestepEnum.Minutes10,
                TimestepEnum.Minutes
            ]
        });
        this.periodTimesteps.push({
            key: PeriodEnum.Last3Days,
            value: [
                TimestepEnum.Daily,
                TimestepEnum.Hourly,
                TimestepEnum.Minutes10,
                TimestepEnum.Minutes
            ]
        });

        this.periodTimesteps.push({
            key: PeriodEnum.Last3Months,
            value: [TimestepEnum.Monthly, TimestepEnum.Weekly, TimestepEnum.Daily]
        });
        this.periodTimesteps.push({
            key: PeriodEnum.CurrentYear,
            value: [
                TimestepEnum.Yearly,
                TimestepEnum.Monthly,
                TimestepEnum.Weekly,
                TimestepEnum.Daily
            ]
        });
        this.periodTimesteps.push({
            key: PeriodEnum.Last13Months,
            value: [TimestepEnum.Monthly, TimestepEnum.Weekly, TimestepEnum.Daily]
        });
        this.periodTimesteps.push({
            key: PeriodEnum.Last730Days,
            value: [TimestepEnum.Yearly, TimestepEnum.Monthly, TimestepEnum.Weekly]
        });
        this.periodTimesteps.push({
            key: PeriodEnum.Last1095Days,
            value: [TimestepEnum.Yearly, TimestepEnum.Monthly, TimestepEnum.Weekly]
        });
        this.periodTimesteps.push({
            key: PeriodEnum.PreviousYear,
            value: [
                TimestepEnum.Yearly,
                TimestepEnum.Monthly,
                TimestepEnum.Weekly,
                TimestepEnum.Daily
            ]
        });
        this.periodTimesteps.push({
            key: PeriodEnum.Last2Years,
            value: [TimestepEnum.Yearly, TimestepEnum.Monthly, TimestepEnum.Weekly]
        });
        this.periodTimesteps.push({
            key: PeriodEnum.Last3Years,
            value: [TimestepEnum.Yearly, TimestepEnum.Monthly, TimestepEnum.Weekly]
        });
        this.periodTimesteps.push({
            key: PeriodEnum.Custom,
            value: [
                TimestepEnum.Yearly,
                TimestepEnum.Monthly,
                TimestepEnum.Weekly,
                TimestepEnum.Daily,
                TimestepEnum.Hourly,
                TimestepEnum.Minutes10,
                TimestepEnum.Minutes
            ]
        });
    }

    private initTimestepsDatePickerConfig(): void {
        this.timestepsDatePickerConfig = new Array<KeyValue<TimestepEnum, Array<string>>>();
        this.timestepsDatePickerConfig.push({
            key: TimestepEnum.Yearly,
            value: ['YYYY', 'multi-year']
        });
        this.timestepsDatePickerConfig.push({
            key: TimestepEnum.Monthly,
            value: ['MM/YYYY', 'year']
        });
        this.timestepsDatePickerConfig.push({
            key: TimestepEnum.Weekly,
            value: ['[Sem.] WW - GGGG', 'month']
        });
        this.timestepsDatePickerConfig.push({
            key: TimestepEnum.Daily,
            value: ['L', 'month']
        });
        this.timestepsDatePickerConfig.push({
            key: TimestepEnum.Hourly,
            value: ['L', 'month']
        });
        this.timestepsDatePickerConfig.push({
            key: TimestepEnum.Minutes10,
            value: ['L', 'month']
        });
        this.timestepsDatePickerConfig.push({
            key: TimestepEnum.Minutes,
            value: ['L', 'month']
        });
    }
    //#endregion
}
