import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {
    constructor(private router: Router) {}

    ngOnInit(): void {}

    public onBackPage(): void {
        this.router.navigate(['/']);
    }
}
