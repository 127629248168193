<ng-container *ngIf="_linkedFiles">
  <div *ngIf="_linkedFiles.length > 1">
    <button mat-icon-button [matMenuTriggerFor]="downloadMenu">
      <mat-icon>file_download</mat-icon>
    </button>
    <mat-menu #downloadMenu="matMenu">
      <div *ngFor="let item of _linkedFiles">
        <button
          (click)="downloadFile(item.fileId, item.fileName)"
          mat-flat-button
          matTooltip="Télécharger le fichier"
        >
          <mat-icon [svgIcon]="item.fileSvgIcon"></mat-icon>
          <span>{{ item.fileName }}</span>
        </button>
      </div>
    </mat-menu>
  </div>
  <ng-container *ngIf="_linkedFiles.length === 1">
    <div *ngFor="let item of _linkedFiles">
      <button
        mat-icon-button
        (click)="downloadFile(item.fileId, item.fileName)"
        matTooltip="Télécharger le fichier"
      >
        <mat-icon [svgIcon]="item.fileSvgIcon"></mat-icon>
      </button>
    </div>
  </ng-container>
</ng-container>
