import { DomainEnum } from '@app/shared/constants/domain.enum';
import { Label, LabelColor } from '../../common/label.model';
import { DistributionModel } from './distribution.model';
import { RawDistributionModel } from './raw-distribution.model';

export class MeteringPointBaseModel {
    public id: number;
    public name: string;
    public meteringPointThirdPartyId: string;
    public nameExterne: string;
    public meteringPointIdSiTiers: number;
    public meteringPointInterfaceId: number;
    public fluidId: number;
    public fluidLabel: string;
    public fluid: LabelColor;
    public fluidType: Label;
    public sensId?: number;
    public sensLabel?: string;
    public sens: Label;
    // 3 cas de répartition possible:
    // Domain = Property - réparti à date sur Zones et Usages
    // Domain = Property - non réparti - avec fils obligatoire (typiquement le cas d'un pdc père)
    // Domain = Espace public - réparti sur 1 seule Armoire et Usages
    public domain: DomainEnum;
    public meteringPointChilds: Label[];
    public activeDistributions: RawDistributionModel[];
    /* spécifique front */
    public activeDistribution: DistributionModel;
    public isEquipmentOutput: boolean;

    constructor() {
        this.meteringPointChilds = [];
        this.activeDistribution = <DistributionModel>{
            itemLineDistributions: []
        };
    }
}
