import { Component, Input, OnInit } from '@angular/core';

import { I18nService } from '@app/core/internationalization/i18n.service';
import { OdometerConfig, OdometerModel } from './odometer.model';

@Component({
    selector: 'vertuoz-odometer',
    templateUrl: './odometer.component.html',
    styleUrls: ['./odometer.component.scss']
})
export class OdometerComponent implements OnInit {
    @Input() odometerModel: OdometerModel;

    counterConfig: OdometerConfig;

    constructor(private i18nService: I18nService) {}

    ngOnInit(): void {
        this.counterConfig = {
            theme: 'minima',
            format: this.i18nService.locale === 'fr-FR' ? '( ddd),dd' : '(,ddd).dd'
        };
    }
}
