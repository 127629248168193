import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RGPDService {
    public changeRgpd: BehaviorSubject<boolean>;

    constructor() {
        this.changeRgpd = new BehaviorSubject<boolean>(false);
    }
}
