import { formatDate } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';

import { defaultSources, timeSteps } from '@app/core/constants/constants';
import { NameValuePair } from '@app/core/types/name-value-pair.type';
import { TimeFilterParameters } from '@app/features/consumption-monitoring/pages/fluids-water-view/model/consumption-configuration';
import { TimestepEnum } from '@app/shared/constants/date-time.enum';
import { PerimeterLevelState } from '@core/services/geo-service.service';
import { TranslateService } from '@ngx-translate/core';

import { enumGeographicLevel } from '@vertuoz/vertuoz-library';
import * as moment from 'moment';
import { Colors } from './colors';

@Injectable({
    providedIn: 'root'
})
export class ToolsService {
    private maxValue = Number.MAX_SAFE_INTEGER;

    constructor(
        @Inject(LOCALE_ID) private locale: string,
        private translateService: TranslateService
    ) {}

    /**
     * Pour créer une nouvelle reference de l'objet @object
     */
    clone<T>(object: T): T {
        return <T>JSON.parse(JSON.stringify(object));
    }

    /**
     *
     * @param collection tableau à grouper
     * @param property base de grouper
     */
    groupBy<T>(collection: Array<T>, property: string): Array<Array<T>> {
        let i = 0,
            val,
            index: number;
        const values = [];
        const result = [];
        for (; i < collection.length; i++) {
            val = collection[i][property];
            index = values.indexOf(val);
            if (index > -1) {
                result[index].push(collection[i]);
            } else {
                values.push(val);
                result.push([collection[i]]);
            }
        }
        return result;
    }

    /**
     * @returns crossingValues traité
     * @param chartDataItems données à parcourir
     * @param crossingValues element à incrémenter en fonction de chartDataItems
     * @param field field pour indiquer l'objet à parcourir
     */
    setCrossingValues<T>(
        chartDataItems: Array<T>,
        crossingValues: number[],
        field: string = 'consoPoints'
    ): number[] {
        if (!chartDataItems) {
            return crossingValues;
        }
        for (let i = 0, length = chartDataItems.length; i < length; i++) {
            const item = chartDataItems[i];
            for (let j = 0, lengthConsoPoints = item[field].length; j < lengthConsoPoints; j++) {
                crossingValues = [...crossingValues, this.maxValue];
            }
        }
        return crossingValues;
    }

    /**
     * Permet de transformer un objet en liste de paramètres
     * @param params
     */
    transformParamsToQueryString<T>(params: T, prefix?: string, timeZone: string = null): string {
        let result = '';
        prefix = prefix != null ? prefix : '';
        for (const key in params) {
            if (params.hasOwnProperty(key) && params[key] != null) {
                if (params[key] instanceof Date) {
                    result =
                        result +
                        prefix +
                        key +
                        '=' +
                        formatDate(
                            params[key].toString(),
                            'yyyy MM dd hh:mm:ss a',
                            this.locale,
                            timeZone
                        ) +
                        '&';
                } else if (params[key] instanceof Array) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    if ((<any>params[key]).length > 0) {
                        result =
                            result +
                            prefix +
                            (key + '=' + params[key] + '&').replace(
                                new RegExp(',', 'g'),
                                '&' + key + '='
                            );
                    }
                    // Gestion des classes "simples"
                    // ie: classe contenant que des types primitifs
                } else if (
                    params[key] instanceof Object &&
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    Object.keys(<any>params[key]).length > 0
                ) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const obj = Object.keys(<any>params[key]);
                    obj.forEach(
                        o =>
                            (result =
                                params[key][o] != null
                                    ? result +
                                      prefix +
                                      key +
                                      '.' +
                                      o +
                                      '=' +
                                      this.encodeParameter(params[key][o]) +
                                      '&'
                                    : result)
                    );
                } else if (typeof params[key] === 'string') {
                    result =
                        result +
                        prefix +
                        key +
                        '=' +
                        encodeURIComponent(params[key].toString()) +
                        '&';
                } else {
                    result = result + prefix + key + '=' + params[key] + '&';
                }
            }
        }
        result = result.substring(0, result.length - 1);

        // https://www.w3schools.com/tags/ref_urlencode.asp
        // remplacement des espaces
        result = result.replace(new RegExp(' ', 'g'), '%20');
        // remplacement des deux points
        result = result.replace(new RegExp(':', 'g'), '%3A');

        return result;
    }

    /**
     * Permet de transformer la liste des objets en une liste des paramètres
     * @param params Liste des objets en transformer en liste des paramètres
     * @returns Liste des paramètres à passer en requête
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transformParamsArrayToQueryString(...params: any[]): string {
        if (params == null || params.length === 0) {
            return null;
        }

        // In a paricular case, where the dates should be in the format UTC
        let timeZone = null;
        const withUTCTimeIndex = params.findIndex(p => p != null && p['withUTCTime'] === true);
        if (withUTCTimeIndex > -1) {
            params.splice(withUTCTimeIndex, 1);
            timeZone = 'Z';
        }

        let result = '';
        for (let i = 0; i < params.length; i++) {
            if (params[i]) {
                const qry = this.transformParamsToQueryString(
                    params[i],
                    params[i].prefix,
                    timeZone
                );
                result += (result !== '' && qry != null && qry.trim() !== '' ? '&' : '') + qry;
            }
        }

        return result;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private encodeParameter(parameter: any): string {
        if (parameter instanceof Date) {
            return formatDate(parameter.toString(), 'yyyy MM dd hh:mm:ss a', this.locale);
        } else if (typeof parameter === 'string') {
            return encodeURIComponent(parameter.toString());
        } else {
            return parameter;
        }
    }

    public transformArrayParamsNumberToString(name: string, arrayFilters: number[]): string {
        if (!arrayFilters || arrayFilters.length === 0) {
            return '';
        }
        let concat = '';
        arrayFilters.forEach(element => {
            concat = concat !== undefined ? concat + `&${name}=${element}` : `&${name}=${element}`;
        });
        return concat;
    }

    /**
     * a tool function to update perimeter state on the localStorage
     * @param levelId
     * @param count
     */
    setPerimeterToLocalStorage(levelId: number, count: number): PerimeterLevelState {
        const perimeterState: PerimeterLevelState = { id: levelId, count: count, enum: null };
        perimeterState.enum = this.parseLevelToEnumGeographicLevel(levelId);
        localStorage.setItem('peremiterLevel', JSON.stringify(perimeterState));
        return perimeterState;
    }

    /**
     * a tool function to remove perimeter state from localStorage
     */
    removePerimeterFromLocalStorage(): PerimeterLevelState {
        localStorage.removeItem('peremiterLevel');
        return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    findInArray(array: Array<any>, attribute: string, value: any): number {
        return array.findIndex(item => item[attribute] === value);
    }

    /** parse le nombre level vers l'enum représentant un niveau geographique*/
    public parseLevelToEnumGeographicLevel(level: number): enumGeographicLevel {
        let geographicLevel: enumGeographicLevel;
        switch (level) {
            case -3:
                geographicLevel = enumGeographicLevel.nation;
                break;
            case -2:
                geographicLevel = enumGeographicLevel.region;
                break;
            case -1:
                geographicLevel = enumGeographicLevel.county;
                break;
            case 0:
                geographicLevel = enumGeographicLevel.town;
                break;
            case 1:
                geographicLevel = enumGeographicLevel.establishment;
                break;
            case 2:
                geographicLevel = enumGeographicLevel.building;
                break;
            case 3:
                geographicLevel = enumGeographicLevel.zone;
                break;
            case 11:
                geographicLevel = enumGeographicLevel.district;
                break;
            case 12:
                geographicLevel = enumGeographicLevel.street;
                break;
            case 13:
                geographicLevel = enumGeographicLevel.cabinet;
                break;
        }
        return geographicLevel;
    }

    /**
     * Get time step format
     * @param timeStep
     */
    public getTimeStepFormat(timeStep: TimestepEnum): moment.unitOfTime.Diff {
        switch (timeStep) {
            case TimestepEnum.Yearly:
                return 'y';
            case TimestepEnum.Monthly:
                return 'M';
            case TimestepEnum.Weekly:
                return 'w';
            case TimestepEnum.Daily:
                return 'd';
            case TimestepEnum.Hourly:
                return 'h';
            case TimestepEnum.Minutes:
                return 'm';
            case TimestepEnum.Minutes10:
                return 's';
        }
    }

    /**
     * Get the label of the date to display
     * @param date The date
     * @param timeStep The timestep
     */
    public formatDate(date: Date, timeStep: TimestepEnum): string {
        let time = '';
        const year = date.getFullYear().toString();
        const month =
            date.getMonth() + 1 < 10
                ? '0' + (date.getMonth() + 1).toString()
                : (date.getMonth() + 1).toString();
        const day =
            date.getDate() < 10 ? '0' + date.getDate().toString() : date.getDate().toString();
        switch (+timeStep) {
            case TimestepEnum.Yearly:
                time = date.getFullYear().toString();
                break;
            case TimestepEnum.Monthly:
                time = `${month}/${year}`;
                break;
            case TimestepEnum.Daily:
                time = `${day}/${month}/${year}`;
                break;
            case TimestepEnum.Hourly:
                time = `${date.getHours()}H ${day}/${month}/${year}`;
                break;
            case TimestepEnum.Minutes:
                time = `${date.getHours()}H${date.getMinutes()} ${day}/${month}/${year}`;
                break;
            case TimestepEnum.Weekly:
                time = moment(date).format('[Sem.] WW - GGGG');
                break;
            default:
                time = date.getFullYear.toString();
                break;
        }
        return time;
    }

    public getDate(filter: TimeFilterParameters, isEnd: boolean = false): Date {
        if (!isEnd) {
            const year = filter.startYear;
            const month = filter.startMonth ? filter.startMonth - 1 : 0;
            const day = filter.startDay ? filter.startDay : 1;
            const hour = filter.startHour != null ? filter.startHour : 0;

            return new Date(year, month, day, hour, 0);
        } else {
            const year = filter.endYear;
            const month = filter.endMonth ? filter.endMonth - 1 : 11;
            const day = filter.endDay ? filter.endDay : new Date(year, month + 1, 0).getDay();
            const hour = filter.endHour != null ? filter.endHour : 23;

            return new Date(year, month, day, hour, 59);
        }
    }

    public getDates(filter: TimeFilterParameters): { startDate: Date; endDate: Date } {
        const startYear = filter.startYear;
        const startMonth = filter.startMonth ? filter.startMonth - 1 : 0;
        const startDay = filter.startDay ? filter.startDay : 1;
        const startHour = filter.startHour ? filter.startHour : 0;

        const endYear = filter.endYear;
        const endMonth = filter.endMonth ? filter.endMonth - 1 : 11;
        const endDay = filter.endDay ? filter.endDay : new Date(endYear, endMonth + 1, 0).getDay();
        const endHour = filter.endHour ? filter.endHour : 23;

        return {
            startDate: new Date(startYear, startMonth, startDay, startHour, 0),
            endDate: new Date(endYear, endMonth, endDay, endHour, 59)
        };
    }

    public getTimeStepLabel(timeStep: TimestepEnum): string {
        switch (timeStep) {
            case TimestepEnum.Yearly:
                return this.translateService.instant('common.period.year');
            case TimestepEnum.Monthly:
                return this.translateService.instant('common.period.month');
            case TimestepEnum.Weekly:
                return this.translateService.instant('common.period.week');
            case TimestepEnum.Daily:
                return this.translateService.instant('common.period.day');
            case TimestepEnum.Hourly:
                return this.translateService.instant('common.period.hour');
            default:
                return '';
        }
    }

    public getComparisonDates(
        startDate: Date,
        endDate: Date,
        compare: { year: number; avg3: -3 | 0 }
    ): { previousStartDate: Date; previousEndDate: Date } {
        let previousStartDate: Date = new Date(startDate);
        let previousEndDate: Date = new Date(endDate);

        if (compare.year === -1 || compare.year === -2) {
            previousStartDate = new Date(
                previousStartDate.setFullYear(previousStartDate.getFullYear() + compare.year)
            );
            previousEndDate = new Date(
                previousEndDate.setFullYear(previousEndDate.getFullYear() + compare.year)
            );
        } else if (compare.avg3 === -3) {
            previousStartDate = new Date(
                previousStartDate.setFullYear(previousStartDate.getFullYear() - 3)
            );
            previousEndDate = new Date(
                previousEndDate.setFullYear(previousEndDate.getFullYear() - 1)
            );
        } else {
            previousStartDate = new Date(compare.year, 0, 1);
            previousEndDate = new Date(compare.year, 11, 31);
        }

        return { previousStartDate, previousEndDate };
    }

    getTimeSteps(): Array<NameValuePair> {
        return [...timeSteps];
    }

    getDefaultSources(): Array<NameValuePair> {
        return [...defaultSources];
    }

    /* Méthode utile pour savoir si la valeur d'un input est vide
        car le 0 parfois est considéré comme 'false' */
    stringIsNullEmptyOrUndefined(value: string): boolean {
        return value === '' || value === null || value === undefined;
    }

    public getColor(index: number): string {
        if (index >= Colors.length) {
            return undefined;
        } else {
            return Colors[index];
        }
    }

    public divide(dividend?: number, diviser?: number): number {
        if (diviser && diviser !== 0) {
            return dividend / diviser;
        }

        return undefined;
    }

    public addAlpha(color: string, opacity: number): string {
        // coerce values so ti is between 0 and 1.
        const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
        return color + _opacity.toString(16).toUpperCase();
    }
}
