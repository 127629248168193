import { Injectable } from '@angular/core';
import { GroupsService } from '@core/http/perimeter/groups.service';
import { Label } from '@core/models/common/label.model';
import {EnergySupplierContractModel} from "@core/models/energy-supplier-contracts/energy-supplier-contracts.model";
import { GroupPerimeterModel } from '@core/models/perimeters/group/group-perimeter.model';
import { GroupFilter } from '@core/models/perimeters/group/group.filter';
import { EnumPerimeterLevels } from '@features/perimeter/common/perimeter-levels.enum';
import { PagedContext } from '@features/shared/models/paged-context';
import {enumGeographicLevelId} from "@shared/constants/common.enum";
import { Operation } from '@shared/constants/operation.enum';

@Injectable({
    providedIn: 'root'
})
export class VertuozAssignmentGroupsServiceService {
    allGroups: GroupPerimeterModel[] = [];

    constructor(private groupsService: GroupsService) {}

    public getFormattedAddGroups(
        allGroups: GroupPerimeterModel[] = [],
        selectedIds: number[]
    ): Label[] {
        const addGroups = [];
        const FormattedAddGroups = [];
        allGroups.forEach(grp => {
            if (grp.perimeters && selectedIds) {
                const perimetrIds = grp.perimeters.map(p => p.itemId);
                selectedIds.forEach(selectedId => {
                    if (!perimetrIds.includes(selectedId) && addGroups.indexOf(grp) === -1) {
                        addGroups.push(grp);
                    }
                });
            } else if (addGroups.indexOf(grp) === -1) {
                addGroups.push(grp);
            }
        });
        addGroups.forEach(g => {
            FormattedAddGroups.push(<Label>{ id: g.groupPerimeterId, label: g.name });
        });
        return FormattedAddGroups;
    }

    public getFormattedRemoveGroups(
        allGroups: GroupPerimeterModel[] = [],
        selectedIds: number[]
    ): Label[] {
        const removeGroups = [];
        const FormattedRemoveGroups = [];
        allGroups.forEach(grp => {
            if (grp.perimeters) {
                const perimetrIds = grp.perimeters.map(p => p.itemId);
                perimetrIds.forEach(perimetrId => {
                    if (selectedIds && selectedIds.includes(perimetrId) && removeGroups.indexOf(grp) === -1) {
                        removeGroups.push(grp);
                    }
                });
            }
        });
        removeGroups.forEach(g => {
            FormattedRemoveGroups.push(<Label>{ id: g.groupPerimeterId, label: g.name });
        });
        return FormattedRemoveGroups;
    }

    public getFormattedAddEnergySupplierContract(
        EnergySupplierContracts: EnergySupplierContractModel[] = [],
        selectedPDCIds: number[]
    ): Label[] {
        const addContracts = [];
        const FormattedAddContracts = [];
        EnergySupplierContracts.forEach(c => {
            if (c.meteringPoints) {
                const meteringPointsIds = c.meteringPoints.map(p => p.meteringPointId);
                selectedPDCIds.forEach(selectedId => {
                    if (!meteringPointsIds.includes(selectedId) && addContracts.indexOf(c) === -1) {
                        addContracts.push(c);
                    }
                });
            } else if (addContracts.indexOf(c) === -1) {
                addContracts.push(c);
            }
        });
        addContracts.forEach(pdc => {
            FormattedAddContracts.push(<Label>{ id: pdc.energySupplierContractId, label: pdc.label });
        });
        return FormattedAddContracts;
    }

    public getFormattedRemoveEnergySupplierContract(
        EnergySupplierContract: EnergySupplierContractModel[] = [],
        selectedPDCIds: number[]
    ): Label[] {
        const removeContracts = [];
        const FormattedRemoveContracts = [];
        EnergySupplierContract.forEach(c => {
            if (c.meteringPoints) {
                const meteringPointsIds = c.meteringPoints.map(p => p.meteringPointId);
                meteringPointsIds.forEach(pdc => {
                    if (selectedPDCIds.includes(pdc) && removeContracts.indexOf(c) === -1) {
                        removeContracts.push(c);
                    }
                });
            }
        });
        removeContracts.forEach(pdc => {
            FormattedRemoveContracts.push(<Label>{ id: pdc.energySupplierContractId, label: pdc.label });
        });
        return FormattedRemoveContracts;
    }

    public getToasterMessage(isAddMode: boolean, levelId: enumGeographicLevelId): string {
        switch (levelId) {
            case enumGeographicLevelId.establishment:
                return isAddMode
                    ? 'Les établissements sélectionnés sont bien ajoutés au(x) groupe(s) sélectionné(s).'
                    : 'Les établissements sont retirés de(s) groupe(s) sélectionné(s).';
            case enumGeographicLevelId.building:
                return isAddMode
                    ? 'Les bâtiments sélectionnés sont bien ajoutés au(x) groupe(s) sélectionné(s).'
                    : 'Les bâtiments sont retirés de(s) groupe(s) sélectionné(s).';
            case enumGeographicLevelId.zone:
                return isAddMode
                    ? 'Les zones sélectionnées sont bien ajoutées au(x) groupe(s) sélectionné(s).'
                    : 'Les zones sont retirées de(s) groupe(s) sélectionné(s).';
            case enumGeographicLevelId.district:
                return isAddMode
                    ? 'Les quartiers sélectionnés sont bien ajoutées au(x) groupe(s) sélectionné(s)'
                    : 'Les quartiers sont retirés de(s) groupe(s) sélectionné(s).';
            case enumGeographicLevelId.street:
                return isAddMode
                    ? 'Les rues sélectionnées sont bien ajoutées au(x) groupe(s) sélectionné(s)'
                    : 'Les rues sont retirées de(s) groupe(s) sélectionné(s).';
            case enumGeographicLevelId.cabinet:
                return isAddMode
                    ? 'Les armoires sélectionnées sont bien ajoutées au(x) groupe(s) sélectionné(s).'
                    : 'Les armoires sont retirées de(s) groupe(s) sélectionné(s).';
            default:
                return isAddMode
                    ? 'Les éléments sélectionnés sont bien ajoutés au(x) groupe(s) sélectionné(s).'
                    : 'Les éléments sont retirés de(s) groupe(s) sélectionné(s).';
        }
    }
}
