import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    Router
} from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { EnumController } from '@app/core/services/end-point-extend';
import { LoadingIndicatorService } from '@app/core/services/loading-indicator.service';
import { WidgetContainerService } from '@core/services/widget-container.service';
import { environment } from '@environments/environment';
import { ModalHandlerService } from '@app/shared/components/modal/modal-dialog/modal-handler.service';
import { ModalMessage } from '@app/shared/components/modal/modal-dialog/modal-dialog.component';

import * as moment from 'moment';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy, AfterViewChecked {
    public showSidebar = true;
    height = 'auto';
    public version = environment.version;

    public loading = false;
    loadingPerimeter = false;

    public value = 0;
    public mode = 'determinate';
    public func;

    /** enregistrement pour desouscrire */
    private _onDestroy = new Subject<void>();

    constructor(
        private widgetContainerService: WidgetContainerService,
        private router: Router,
        private modalHandlerService: ModalHandlerService,
        private cdRef: ChangeDetectorRef,
        private loadingService: LoadingIndicatorService,
        private http: HttpClient
    ) {}

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getJSON(): Observable<any> {
        const _jsonURL = '../../assets/const/constJson.json';
        return this.http.get(_jsonURL);
    }

    ngOnInit(): void {
        this.getJSON().subscribe(data => {
            const currentDate = moment(moment().format('MM/DD/YYYY'));
            const minDate = moment(data.popupMaintenanceDateMin);
            const maxDate = moment(data.popupMaintenanceDateMax);
            const intro = null;
            // minDate.format('MM/DD/YYYY') === maxDate.format('MM/DD/YYYY')
            //     ? `Le ${minDate.format('DD/MM/YYYY')}`
            //     : `Du ${minDate.format('DD/MM/YYYY')} au ${maxDate.format('DD/MM/YYYY')}`;
            if (minDate <= currentDate && currentDate <= maxDate) {
                this.modalHandlerService.openModal(
                    new ModalMessage({
                        title: 'Maintenance',
                        introduction: intro,
                        message: `Nous vous informons qu'une opération de maintenance est programmée vendredi 15 décembre 2023, de 13h à 20h. L'accès à VERTUOZ Tableau de bord sera coupé durant cette opération.`,
                        conclude: `Merci de votre compréhension.`,
                        sign: `Vertuoz Tableau de bord`
                    })
                );
            }
        });

        this.loadingService.isLoading.pipe(takeUntil(this._onDestroy)).subscribe(state => {
            this.loadingPerimeter = this.loadingService.checkStateByControllers(
                state,
                [EnumController.ActivePerimeters],
                null,
                null,
                ['POST']
            );
        });
        this.widgetContainerService.height.pipe(takeUntil(this._onDestroy)).subscribe(height => {
            // DashboardData.fixedRowHeight la taille d'une case + DashboardData.marginBetweenWidget espace entre les cases
            if (height && this.router.url.indexOf('/dashboard') > -1) {
                this.height = height.toString() + 'px';
            } else {
                this.height = null;
            }
        });

        this.router.events.pipe(takeUntil(this._onDestroy)).subscribe(event => {
            switch (true) {
                case event instanceof NavigationStart: {
                    this.loading = true;
                    this.func = setInterval(() => {
                        if (this.value < 100) {
                            this.value = this.value + 5;
                        } else {
                            this.mode = 'indeterminate';
                        }
                    }, 5);

                    break;
                }

                case event instanceof NavigationEnd:
                case event instanceof NavigationCancel:
                case event instanceof NavigationError: {
                    this.loading = false;
                    clearInterval(this.func);
                    this.value = 0;
                    this.mode = 'determinate';
                    break;
                }
                default: {
                    break;
                }
            }
        });
    }

    ngAfterViewChecked(): void {
        this.cdRef.detectChanges();
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    ifRouterUrlApplyHeight = (url): string => (url === this.router.url ? this.height : '100%');
}
