import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
    selector: 'delayed-button',
    templateUrl: './delayed-button.component.html',
    styleUrls: ['./delayed-button.component.scss']
})
export class DelayedButtonComponent implements OnInit, OnDestroy {
    @Input() delay: number;
    @Output() heldUpToDelay = new EventEmitter<boolean>();
    private readonly PROGRESS_END = 0;
    private readonly PROGRESS_START = 100;
    private readonly INTERVAL = 200;
    private intervalId: number;
    progress = this.PROGRESS_START;

    ngOnInit() {}

    startCounter() {
        /* We want progress to reach PROGRESS_END when delay is elapsed. */
        const step = (this.INTERVAL / this.delay) * 100;
        this.intervalId = window.setInterval(() => {
            this.progress -= step;
            if (this.progress < this.PROGRESS_END) {
                window.clearInterval(this.intervalId);
                this.heldUpToDelay.emit(true);
            }
        }, this.INTERVAL);
    }

    stopCounter() {
        if (this.progress > this.PROGRESS_END) {
            this.progress = this.PROGRESS_START;
            window.clearInterval(this.intervalId);
        }
    }

    ngOnDestroy() {
        window.clearInterval(this.intervalId);
    }
}
