import { Component } from '@angular/core';

@Component({
    selector: 'site-disabled',
    templateUrl: './site-disabled.component.html',
    styleUrls: ['./site-disabled.component.css']
})
export class SiteDisabledComponent {
    constructor() {}
}
