import { HttpClient } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BrowserModule, Title, ɵDomSharedStylesHost } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ActivatedRouteSnapshot, RouterModule } from '@angular/router';

// Import locale
import { registerLocaleData, CommonModule } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';

registerLocaleData(localeFr, 'fr-FR');
registerLocaleData(localeEn, 'en-US');

/* @progress */
import { FilterService } from '@progress/kendo-angular-grid';
import { IntlModule } from '@progress/kendo-angular-intl';
import '@progress/kendo-angular-intl/locales/en/all';
import '@progress/kendo-angular-intl/locales/fr/all';

/* autres*/
import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateFormatJsCompiler } from 'ngx-translate-formatjs-compiler';
import 'hammerjs';
import { ToastrModule } from 'ngx-toastr';

// import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { TreeviewModule } from 'ngx-treeview';

/* local */
import { AgmCoreModule } from '@agm/core';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { CoreModule } from '@core/core.module';
import { environment } from '@environments/environment';
import { PadNumberPipe } from '@shared/pipes/pad-number-with-leading-zeros.pipe';
import { externalUrlProvider } from './app-root/app-routes';
import { AppRoutingModule } from './app-root/app-routing.module';
import { AppComponent } from './app.component';
import { VertuozLayoutModule } from './layout/vertuoz-layout.module';
import { PagesModule } from './pages/pages.modules';
import { CustomDomSharedStylesHost } from './csp/shared-styles-host';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        AgmCoreModule.forRoot({
            apiKey: environment.GOOGLEMAP_KEY
        }),
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        VertuozLayoutModule,
        PagesModule,
        IntlModule,
        RouterModule,
        CoreModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        TreeviewModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            compiler: {
                provide: TranslateCompiler,
                useClass: TranslateFormatJsCompiler
            }
        }),
        ToastrModule.forRoot({
            // déclaration du style par défaut
            positionClass: 'toast-bottom-right',
            progressBar: true,
            preventDuplicates: true,
            resetTimeoutOnDuplicate: true
        })
    ],
    exports: [PagesModule, CoreModule],
    providers: [
        { provide: 'cspMetaSelector', useValue: 'meta[name="CSP-NONCE"]' },
        { provide: ɵDomSharedStylesHost, useClass: CustomDomSharedStylesHost },
        FilterService,
        {
            provide: LOCALE_ID,
            useValue: 'fr-FR'
        },
        {
            provide: externalUrlProvider,

            useValue: (route: ActivatedRouteSnapshot) => {
                const externalUrl = route.paramMap.get('externalUrl');
                if (externalUrl.includes(environment.extLinks.portal)) {
                    window.location.replace(externalUrl);
                } else {
                    window.open(externalUrl, '_blank');
                }
            }
        },
        PadNumberPipe,
        Title
    ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {}
