import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BuildingDataFilter } from '@app/core/models/building/building-data-filter.model';
import { Label } from '@app/core/models/common/label.model';
import { PageResult } from '@app/core/models/common/page-result.model';
import { EstablishmentDataFilter } from '@app/core/models/establishment/establishment-data-filter.model';
import { MeteringPointOnSupplierContractsOverlapped } from '@app/core/models/meteringplan/meteringpoint/metering-point-on-supplier-contracts-overlapped.model';
import {
    DialFilter,
    MeteringPointFilter
} from '@app/core/models/meteringplan/meteringpoint/metering-point.filter';
import { ZoneDataFilter } from '@app/core/models/zone/zone-data-filter.model';
import { EnumController } from '@app/core/services/end-point-extend';
import { PagedContext } from '@app/features/shared/models/paged-context';
import { ToolsService } from '@app/features/shared/services/tools.service';
import { FileExportType } from '@app/shared/constants/export.enum';
import { PerimeterScope } from '@app/shared/constants/filters.enum';
import { environment } from '@environments/environment';
import { MeteringPointModel } from '../../../models/meteringplan/meteringpoint/metering-point.model';
import { MeteringPointsSupplierContractsModel } from '../../../models/meteringplan/meteringpoint/metering-points-supplier-contracts.model';
import { AppService } from '../../app.service';
import { BaseService } from '../../base.service';

@Injectable({
    providedIn: 'root'
})
export class MeteringPointsService extends BaseService {
    private API_ENDPOINT: string = environment.API_ENDPOINT;
    private METERINGPOINT_API_URL_VERSION = `${this.API_ENDPOINT}${this.version}/${EnumController.meteringPoints}`;
    private METERING_POINT_SUPPLIER_CONTRACTS_API = `${this.API_ENDPOINT}${EnumController.meteringPointsOnSupplierContractsOverlapped}`;

    private METERINGPOINT_API_URL = `${this.API_ENDPOINT}${EnumController.meteringPoints}`;

    // old url
    protected url_MeteringPoint_EndPoint_old = `${this.API_ENDPOINT}${EnumController.meteringPoint}`;

    constructor(
        private http: HttpClient,
        private appService: AppService,
        private toolsService: ToolsService
    ) {
        super();
    }

    public getMeteringPoint(meteringPointId: number): Observable<MeteringPointModel> {
        const contractId = this.appService.getUserContext().contract.id.toString();

        return this.http
            .get<MeteringPointModel>(
                `${this.METERINGPOINT_API_URL_VERSION}/${meteringPointId}?contractId=${contractId}`
            )
            .pipe(
                map(r => {
                    MeteringPointModel.fillDistribution(r);
                    return r;
                })
            );
    }

    public getMeteringPointsCarto(
        meteringPointFilter: MeteringPointFilter,
        perimeterScope: PerimeterScope,
        pagedContext: PagedContext
    ): Observable<PageResult<MeteringPointModel>> {
        let finalQry = '';
        const qryMeteringPointFilter = meteringPointFilter
            ? this.toolsService.transformParamsToQueryString(meteringPointFilter)
            : '';
        if (qryMeteringPointFilter.length > 0) {
            finalQry = finalQry + '&' + qryMeteringPointFilter;
        }

        const qryPagedContext = pagedContext
            ? this.toolsService.transformParamsToQueryString(pagedContext)
            : '';
        const contractId = this.appService.getUserContext().contract.id.toString();

        return this.http.get<PageResult<MeteringPointModel>>(
            `${this.METERINGPOINT_API_URL_VERSION}/cartography?perimeterScope=${perimeterScope}&contractId=${contractId}&${qryPagedContext}${finalQry}`
        );
    }

    public getMeteringPoints(
        meteringPointFilter: MeteringPointFilter,
        dialFilter: DialFilter,
        establishmentFilter: EstablishmentDataFilter,
        buildingFilter: BuildingDataFilter,
        zoneFilter: ZoneDataFilter,
        perimeterScope: PerimeterScope,
        pagedContext: PagedContext
    ): Observable<PageResult<MeteringPointModel>> {
        let finalQry = '';
        const qryMeteringPointFilter = meteringPointFilter
            ? this.toolsService.transformParamsToQueryString(meteringPointFilter)
            : '';
        if (qryMeteringPointFilter.length > 0) {
            finalQry = finalQry + '&' + qryMeteringPointFilter;
        }
        const qryDialFilter = dialFilter
            ? this.toolsService.transformParamsToQueryString(dialFilter)
            : '';
        if (qryDialFilter.length > 0) {
            finalQry = finalQry + '&' + qryDialFilter;
        }
        const qryEstablishmentFilter = establishmentFilter
            ? this.toolsService.transformParamsToQueryString(establishmentFilter)
            : '';
        if (qryEstablishmentFilter.length > 0) {
            finalQry = finalQry + '&' + qryEstablishmentFilter;
        }
        const qryBuildingFilter = buildingFilter
            ? this.toolsService.transformParamsToQueryString(buildingFilter)
            : '';
        if (qryBuildingFilter.length > 0) {
            finalQry = finalQry + '&' + qryBuildingFilter;
        }
        const qryZoneFilter = zoneFilter
            ? this.toolsService.transformParamsToQueryString(zoneFilter)
            : '';
        if (qryZoneFilter.length > 0) {
            finalQry = finalQry + '&' + qryZoneFilter;
        }
        const qryPagedContext = pagedContext
            ? this.toolsService.transformParamsToQueryString(pagedContext)
            : '';

        const contractId = this.appService.getUserContext().contract.id.toString();

        return this.http.get<PageResult<MeteringPointModel>>(
            `${this.METERINGPOINT_API_URL_VERSION}?perimeterScope=${perimeterScope}&contractId=${contractId}&${qryPagedContext}${finalQry}`
        );
    }

    public createMeteringPoint(meteringPoint: MeteringPointModel): Observable<number> {
        const contractId = this.appService.getUserContext().contract.id.toString();
        MeteringPointModel.fillRawDistribution(meteringPoint);
        return this.http.post<number>(
            `${this.METERINGPOINT_API_URL_VERSION}?contractId=${contractId}`,
            meteringPoint
        );
    }

    public updateMeteringPoint(meteringPoint: MeteringPointModel): Observable<void> {
        const contractId = this.appService.getUserContext().contract.id.toString();
        MeteringPointModel.fillRawDistribution(meteringPoint);
        return this.http.put<void>(
            `${this.METERINGPOINT_API_URL_VERSION}/${meteringPoint.id}?contractId=${contractId}`,
            meteringPoint,
            {
                headers: { contractId }
            }
        );
    }

    public patchMeteringPoint(updateDate: Date, activ?: boolean, id?: number): Observable<void> {
        return this.http.patch<void>(
            this.METERINGPOINT_API_URL_VERSION + '?active=' + activ + '?id=' + id,
            updateDate
        );
    }

    downloadMeteringPointsFile(
        fileType: FileExportType,
        perimeterScope: PerimeterScope,
        paramsMeteringPoint: MeteringPointFilter,
        pagedContext: PagedContext
    ): Observable<HttpResponse<Object>> {
        let queryStr: string;
        if (
            paramsMeteringPoint.meteringPointIds &&
            paramsMeteringPoint.meteringPointIds.length > 0
        ) {
            queryStr = this.toolsService.transformParamsToQueryString(paramsMeteringPoint);
        } else {
            queryStr = this.toolsService.transformParamsToQueryString({
                meteringPointLabel: paramsMeteringPoint.meteringPointLabel,
                meteringPointThirdPartyId: paramsMeteringPoint.meteringPointThirdPartyId,
                fluidId: paramsMeteringPoint.fluidId,
                distributedParentItemLabel: paramsMeteringPoint.distributedParentItemLabel,
                hasGps: paramsMeteringPoint.hasGps,
                meteringPointHasConsumption: paramsMeteringPoint.meteringPointHasConsumption,
                meteringPointConsumptionStartDate:
                    paramsMeteringPoint.meteringPointConsumptionStartDate,
                meteringPointConsumptionEndDate:
                    paramsMeteringPoint.meteringPointConsumptionEndDate,
                sensId: paramsMeteringPoint.sensId
            });
        }

        return this.http.get(
            `${
                this.url_MeteringPoint_EndPoint_old
            }s/Download?${queryStr}&perimeterScope=${perimeterScope.toString()}&fileType=${fileType.toString()}&sortDesc=${
                pagedContext.sortDesc
            }&sortField=${
                pagedContext.sortField === 'pdcLabel'
                    ? 'name'
                    : pagedContext.sortField === 'pdcIdSITiers'
                    ? 'MeteringPointThirdPartyId'
                    : pagedContext.sortField
            }`,
            {
                responseType: 'blob' as 'json',
                observe: 'response'
            }
        );
    }

    /**
     * End point permettant de supprimer un point de comptage
     */
    deleteMeteringPoint(meteringPointId: number): Observable<void> {
        const contractId = this.appService.getUserContext().contract.id.toString();
        return this.http.delete<void>(
            `${this.METERINGPOINT_API_URL_VERSION}/${meteringPointId}?contractId=${contractId}`
        );
    }

    deleteMeteringPoints(meteringPointsIds: number[]): Observable<void> {
        const contractId = this.appService.getUserContext().contract.id.toString();

        const params = this.createHttpParams({ ids: meteringPointsIds, contractId: contractId });
        return this.http.delete<void>(`${this.METERINGPOINT_API_URL_VERSION}`, { params });
    }

    public getDirections(): Observable<Label[]> {
        const contractId = this.appService.getUserContext().contract.id.toString();

        return this.http.get<Label[]>(`${this.METERINGPOINT_API_URL}/Directions`, {
            headers: { contractId }
        });
    }

    public getUseTypes(): Observable<Label[]> {
        const contractId = this.appService.getUserContext().contract.id.toString();

        return this.http.get<Label[]>(`${this.METERINGPOINT_API_URL}/UseTypes`, {
            headers: { contractId }
        });
    }

    public associateMeteringPointToSupplierContract(
        meteringPointsToAssociateToSupplierContractsModel: MeteringPointsSupplierContractsModel[]
    ): Observable<void> {
        let queryStr = '';

        meteringPointsToAssociateToSupplierContractsModel.forEach(x => {
            if (queryStr.length > 0) {
                queryStr += '?';
            }
            queryStr += this.toolsService.transformParamsToQueryString(x);
        });

        return this.http.patch<void>(
            `${this.METERINGPOINT_API_URL_VERSION}?${queryStr}`,
            meteringPointsToAssociateToSupplierContractsModel
        );
    }

    public dissociateMeteringPointsFromSupplierContract(
        meteringPointsToDissociateFromSupplierContracts: MeteringPointsSupplierContractsModel[]
    ): Observable<void> {
        return this.http.patch<void>(
            `${this.METERINGPOINT_API_URL_VERSION}/dissociate`,
            meteringPointsToDissociateFromSupplierContracts
        );
    }

    public enableMeteringPointsIntgPDF(ids: number[], enable: boolean): Observable<void> {
        return this.http.patch<void>(
            `${this.METERINGPOINT_API_URL_VERSION}/enablePDFIntg?enable=${enable}`,
            ids
        );
    }

    public GetMeteringPointsOnSupplierContractsOverlapped(
        meneringPointIds: number[]
    ): Observable<MeteringPointOnSupplierContractsOverlapped[]> {
        const queryStr = this.toolsService.transformParamsToQueryString(meneringPointIds);
        return this.http.get<MeteringPointOnSupplierContractsOverlapped[]>(
            `${this.METERING_POINT_SUPPLIER_CONTRACTS_API}?${queryStr}`
        );
    }
}
