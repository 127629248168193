import { KeyValue } from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';

import * as moment from 'moment';

import { DateTimeService } from '@app/core/services/date-time/date-time.service';
import { I18nDateAdapter, MOMENT_FORMATS } from '@app/core/services/date-time/i18n-date-adapter';
import { DomainService } from '@app/core/services/domain.service';
import { GeoSelectionTimeStampService } from '@app/core/services/geo-service.service';
import { enumGeographicLevelId, enumGeographicLevelRSX } from '@app/shared/constants/common.enum';
import {
    PeriodEnum,
    PeriodEnumRSX,
    TimestepEnum,
    TimestepEnumRSX
} from '@app/shared/constants/date-time.enum';
import { DomainEnum, DomainEnumRSX } from '@app/shared/constants/domain.enum';
import { SourceEnum, SourceEnumRSX } from '@app/shared/constants/source.enum';
import { Subject } from 'rxjs';
import { takeUntil, timestamp } from 'rxjs/operators';

export class VertuozChartSettingsModel {
    public domainsSelected: Array<DomainEnum>;
    public analysisLevelSelected: enumGeographicLevelId;
    public sourceSelected: SourceEnum;
    public periodSelected: PeriodEnum;
    public startDateSelected: Date;
    public endDateSelected: Date;
    public timestepSelected: TimestepEnum;
    public isPerimeterChanged: boolean;
}
@Component({
    selector: 'vertuoz-chart-settings',
    templateUrl: './vertuoz-chart-settings.component.html',
    styleUrls: ['./vertuoz-chart-settings.component.scss'],
    providers: [
        { provide: DateAdapter, useClass: I18nDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: MOMENT_FORMATS }
    ]
})
export class VertuozChartSettingsComponent implements OnChanges, OnDestroy, OnInit {
    public chartSettingsForm: FormGroup;
    public TimestepEnum = TimestepEnum;

    //#region inputs & outputs

    //#region Champs à masquer

    // à vrai, masque la liste déroulante des domaines
    @Input()
    public hideDomains = false;
    // à vrai, masque la liste déroulante des niveaux d'analyse
    @Input()
    public hideAnalysisLevels = false;
    // à vrai, masque la liste déroulante des sources
    @Input()
    public hideSources = false;
    // à vrai, masque la liste déroulante des périodes
    @Input()
    public hidePeriods = false;
    // à vrai, masque les listes déroulantes des heures de début et de fin
    @Input()
    public hideHours = false;
    @Input()
    public hideMinutes = true;

    // à vrai, masque la liste déroulante des échelles temporelles
    @Input()
    public hideTimesteps = false;

    @Input() public isMeasure = false;
    //#endregion

    //#region Champs à désactiver

    // Active ou désactive les dates pickers
    @Input()
    public enableDatePickers = true;
    // Active ou désactive les listes déroulantes des heures de début et de fin
    @Input()
    public enableTimePickers = true;
    // Active ou désactive la sélection multiple pour les domaines
    @Input()
    public enableMultiDomainSelection = true;
    //#endregion

    //#region Valeurs des champs à masquer
    // Liste des valeurs à masquer de la liste déroulante des domaines
    @Input()
    public hideDomainsValues: Array<DomainEnum> = [];
    // Liste des valeurs à masquer de la liste déroulante des niveaux d'analyse
    @Input()
    public hideAnalysisLevelsValues: Array<enumGeographicLevelId> = [];
    // Liste des valeurs à masquer de la liste déroulante des sources
    @Input()
    public hideSourcesValues: Array<SourceEnum> = [];
    // Liste des valeurs à masquer de la liste déroulante des périodes
    @Input()
    public hidePeriodsValues: Array<PeriodEnum> = [];
    // Liste des valeurs à masquer de la liste déroulante des échelles temporelles
    @Input()
    public hideTimestepsValues: Array<TimestepEnum> = [];
    //#endregion

    //#region Sélection par défaut
    // Domaine par défaut sélectionnée à l'initialisation du composant
    @Input()
    public defaultSelectedDomains: Array<DomainEnum>;
    // Niveau d'analyse sélectionné à l'initialisation du composant
    @Input()
    public defaultSelectedAnalysisLevel: enumGeographicLevelId;
    // Source par défaut sélectionnée à l'initialisation du composant
    @Input()
    public defaultSelectedSource: SourceEnum;
    // Période par défaut sélectionnée à l'initialisation du composant
    @Input()
    public defaultSelectedPeriod: PeriodEnum;
    // Echelle temporelle par défaut sélectionnée à l'initialisation du composant
    @Input()
    public defaultSelectedTimestep: TimestepEnum;
    // Date de début par défaut sélectionnée à l'initialisation du composant
    @Input()
    public defaultSelectedStartDate: Date;
    // Date de fin par défaut sélectionnée à l'initialisation du composant
    @Input()
    public defaultSelectedEndDate: Date;

    @Input()
    public maxDatesBySources: Array<KeyValue<SourceEnum, Date>>;
    public selectedMaxDate: Date;

    //#endregion

    // Evenement déclenché lors de la validation du formulaire
    @Output()
    public settingsChangedEvent: EventEmitter<VertuozChartSettingsModel> = new EventEmitter<
        VertuozChartSettingsModel
    >();
    public settingsIsChanging = false;
    //#endregion

    //#region membres
    private _onDestroy = new Subject<void>();
    private minutesStep: number;

    private allDomains: DomainEnum[];
    private allPeriods: PeriodEnum[];
    private allSources: SourceEnum[];
    public isDomainHidden = true;

    public readonly DAYS_GAP_FOR_10_MIN: number = 90;

    // listes des valeurs disponibles dans les listes déroulantes
    public displayedDomains: Array<DomainEnum>; // Domaines affichés dans le formulaire
    public displayedAnalysisLevel: Array<enumGeographicLevelId>; // Niveau d'analyse affichés dans le formulaire
    public displayedSources: Array<SourceEnum>; // Sources affichées dans le formulaire
    public displayedPeriods: Array<PeriodEnum>; // Périodes affichées dans le formulaire
    public displayedMinutesInDay: Array<number>; // Heures affichées dans le formulaire
    public displayedTimesteps: Array<TimestepEnum>; // Echelles temporelles dans le formulaire

    // Sélection courante
    public selectedDomains: Array<DomainEnum>; // Domaine sélectionné
    private selectedAnalysisLevel: enumGeographicLevelId; // Niveau d'analyse selectionné
    private selectedSource: SourceEnum; // Source sélectionnée
    private selectedPeriod: PeriodEnum; // Période sélectionnée
    private selectedStartDate: moment.Moment; // Date de début sélectionnée
    private selectedEndDate: moment.Moment; // Date de fin sélectionnée
    public selectedTimestep: TimestepEnum; // Echelle temporelle sélectionnée

    public dateTimeService: DateTimeService;
    private sourcesPeriods: Array<KeyValue<SourceEnum, Array<PeriodEnum>>>; // tableau contenant pour chaque source, les périodes disponibles
    private sourcesTimesteps: Array<KeyValue<SourceEnum, Array<TimestepEnum>>>; // tableau contenant pour chaque source, les échelles temporelles disponibles
    //#endregion

    //#region constructeur et initialisation
    constructor(
        private fb: FormBuilder,
        private _dateTimeService: DateTimeService,
        private _domainService: DomainService,
        private _geoService: GeoSelectionTimeStampService
    ) {
        this.dateTimeService = this._dateTimeService;
    }

    ngOnInit(): void {
        this.dateTimeService.isMeasure = this.isMeasure;
        this.dateTimeService.init();

        this.minutesStep = this.hideMinutes ? 60 : 10;

        this.chartSettingsForm = this.fb.group({
            domainsField: '',
            analysisLevelField: '',
            sourceField: '',
            periodField: '',
            startDateField: '',
            startTimeField: '',
            endDateField: '',
            endTimeField: '',
            timestepField: ''
        });
        this.initForm();

        this._geoService.data.pipe(takeUntil(this._onDestroy)).subscribe(res => {
            if (!(this.hideDomainsValues.length > 0)) {
                this.autoSelectDomains();
                this.autoHideDomains();

                this.changeSettings(true);
            }
            this.changeDisplayedDomains();
            this.patchForm();
        });
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes) {
            /* Changement de la date de début par le composant parent */
            if (changes.defaultSelectedStartDate) {
                if (changes.defaultSelectedStartDate.firstChange) {
                    this.selectedStartDate = moment(changes.defaultSelectedStartDate.currentValue);
                } else if (
                    !moment(changes.defaultSelectedStartDate.currentValue).isSame(
                        this.selectedStartDate
                    )
                ) {
                    this.selectStartDateAndTime(this.defaultSelectedStartDate, false);
                }
            }
            /* Changement de la date de fin par le composant parent */
            if (changes.defaultSelectedEndDate) {
                if (changes.defaultSelectedEndDate.firstChange) {
                    this.selectedEndDate = moment(changes.defaultSelectedEndDate.currentValue);
                } else if (
                    !moment(changes.defaultSelectedEndDate.currentValue).isSame(
                        this.selectedEndDate,
                        'hour'
                    )
                ) {
                    this.selectEndDateAndTime(this.defaultSelectedEndDate, false);
                }
            }
            /* Changement de la source par le composant parent */
            if (changes.defaultSelectedSource) {
                if (changes.defaultSelectedSource.firstChange) {
                    // Si le changement est l'initial, on affecte directement la valeur
                    this.selectedSource = changes.defaultSelectedSource.currentValue;
                } else if (changes.defaultSelectedSource.currentValue !== this.selectedSource) {
                    // Sinon, si la valeur est différente de la précédente on sélectionne la source
                    this.selectSource(
                        this.defaultSelectedSource != null ? this.defaultSelectedSource : null,
                        true
                    );
                }
            }

            /* Changement de l'affichage ou non des sources par le composant parent */
            if (changes.hideSourcesValues) {
                this.changeDisplayedSources();

                if (
                    this.displayedSources &&
                    this.displayedSources.findIndex(s => s === this.selectedSource) === -1
                ) {
                    this.defaultSelectedSource = this.displayedSources
                        ? this.displayedSources[0]
                        : null;
                    this.selectSource(this.defaultSelectedSource, false);
                }
            }
        }
    }

    /**
     * Initialisation du formulaire
     */
    initForm(): void {
        this.initListsValues();
        this.initListsDependencies();

        this.initCustomPeriod();

        this.selectDefaultValues();
    }

    /**
     * Initialisation des listes à affichées
     */
    private initListsValues(): void {
        this.allDomains = [DomainEnum.Property, DomainEnum.PublicSpace];
        this.allSources = [SourceEnum.Invoice, SourceEnum.Reading, SourceEnum.RemoteReading];
        this.allPeriods = [
            PeriodEnum.Today,
            PeriodEnum.Yesterday,
            PeriodEnum.DayBeforeYesterday,
            PeriodEnum.Last3Days,
            PeriodEnum.Last7Days,
            PeriodEnum.CurrentWeek,
            PeriodEnum.PreviousWeek,
            PeriodEnum.Last30Days,
            PeriodEnum.CurrentMonth,
            PeriodEnum.PreviousMonth,
            PeriodEnum.Last3Months,
            PeriodEnum.CurrentYear,
            PeriodEnum.Last13Months,
            PeriodEnum.Last730Days,
            PeriodEnum.Last1095Days,
            PeriodEnum.PreviousYear,
            PeriodEnum.Last2Years,
            PeriodEnum.Last3Years,
            PeriodEnum.Custom
        ];
    }

    /**
     * Initialisation des dépendances entre les listes déroulantes
     * > Sources et périodes
     * > Sources et échelles temporelles
     */
    private initListsDependencies(): void {
        this.sourcesPeriods = new Array<KeyValue<SourceEnum, Array<PeriodEnum>>>();
        this.sourcesPeriods.push({
            key: SourceEnum.Invoice,
            value: [
                PeriodEnum.CurrentMonth,
                PeriodEnum.PreviousMonth,
                PeriodEnum.Last3Months,
                PeriodEnum.CurrentYear,
                PeriodEnum.Last13Months,
                PeriodEnum.Last730Days,
                PeriodEnum.Last1095Days,
                PeriodEnum.PreviousYear,
                PeriodEnum.Last2Years,
                PeriodEnum.Last3Years,
                PeriodEnum.Custom
            ]
        });
        this.sourcesPeriods.push({
            key: SourceEnum.Reading,
            value: [
                PeriodEnum.Yesterday,
                PeriodEnum.DayBeforeYesterday,
                PeriodEnum.Last3Days,
                PeriodEnum.Last7Days,
                PeriodEnum.CurrentWeek,
                PeriodEnum.PreviousWeek,
                PeriodEnum.Last30Days,
                PeriodEnum.CurrentMonth,
                PeriodEnum.PreviousMonth,
                PeriodEnum.Last3Months,
                PeriodEnum.CurrentYear,
                PeriodEnum.Last13Months,
                PeriodEnum.Last730Days,
                PeriodEnum.Last1095Days,
                PeriodEnum.PreviousYear,
                PeriodEnum.Last2Years,
                PeriodEnum.Last3Years,
                PeriodEnum.Custom
            ]
        });
        this.sourcesPeriods.push({
            key: SourceEnum.RemoteReading,
            value: [
                PeriodEnum.Today,
                PeriodEnum.Yesterday,
                PeriodEnum.DayBeforeYesterday,
                PeriodEnum.Last3Days,
                PeriodEnum.Last7Days,
                PeriodEnum.CurrentWeek,
                PeriodEnum.PreviousWeek,
                PeriodEnum.Last30Days,
                PeriodEnum.CurrentMonth,
                PeriodEnum.PreviousMonth,
                PeriodEnum.Last3Months,
                PeriodEnum.CurrentYear,
                PeriodEnum.Last13Months,
                PeriodEnum.Last730Days,
                PeriodEnum.Last1095Days,
                PeriodEnum.PreviousYear,
                PeriodEnum.Last2Years,
                PeriodEnum.Last3Years,
                PeriodEnum.Custom
            ]
        });

        this.sourcesTimesteps = new Array<KeyValue<SourceEnum, Array<TimestepEnum>>>();
        this.sourcesTimesteps.push({
            key: SourceEnum.Invoice,
            value: [TimestepEnum.Yearly, TimestepEnum.Monthly]
        });
        this.sourcesTimesteps.push({
            key: SourceEnum.Reading,
            value: [
                TimestepEnum.Yearly,
                TimestepEnum.Monthly,
                TimestepEnum.Weekly,
                TimestepEnum.Daily
            ]
        });
        this.sourcesTimesteps.push({
            key: SourceEnum.RemoteReading,
            value: [
                TimestepEnum.Yearly,
                TimestepEnum.Monthly,
                TimestepEnum.Weekly,
                TimestepEnum.Daily,
                TimestepEnum.Hourly,
                TimestepEnum.Minutes,
                TimestepEnum.Minutes10
            ]
        });
    }

    /**
     * Sélectionne les valeurs par défaut
     */
    private selectDefaultValues(): void {
        // Valeur par défaut du Domaine
        if (!(this.hideDomainsValues.length > 0)) {
            this.autoSelectDomains();
            this.autoHideDomains();
            this.changeDisplayedDomains();
        } else {
            this.changeDisplayedDomains();
            this.selectDomains(
                this.defaultSelectedDomains != null && this.defaultSelectedDomains.length > 0
                    ? this.defaultSelectedDomains
                    : null,
                true
            );
        }

        // Valeur par défaut du niveau d'analyse
        this.changeDisplayedAnalysisLevel();
        this.selectAnalysisLevel(
            this.defaultSelectedAnalysisLevel != null
                ? this.defaultSelectedAnalysisLevel
                : this.displayedAnalysisLevel[0],
            true
        );

        // Valeur par défaut de la source
        this.changeDisplayedSources();
        this.selectSource(
            this.defaultSelectedSource != null ? this.defaultSelectedSource : null,
            true
        );

        // Valeur par défaut de l'échelle temporelle
        this.changeDisplayedTimesteps();
        this.selectTimestep(
            this.defaultSelectedTimestep != null ? this.defaultSelectedTimestep : null,
            true
        );

        // Valeur par défaut de la période
        this.changeDisplayedPeriods();
        if (this.defaultSelectedPeriod != null) {
            const timeSteps = [TimestepEnum.Minutes, TimestepEnum.Minutes10, TimestepEnum.Hourly];
            let times: { startTime: Date; endTime: Date };
            if (timeSteps.find(t => t === this.selectedTimestep) && !this.hideTimesteps) {
                times = {
                    startTime: this.defaultSelectedStartDate,
                    endTime: this.defaultSelectedEndDate
                };
            }

            // si une période par défaut est définie
            // c'est elle qui doit être prises en compte en priorité
            this.selectPeriod(this.defaultSelectedPeriod, true, times);
        } else if (this.defaultSelectedStartDate || this.defaultSelectedEndDate) {
            // sinon, si des dates par défaut sont définies
            // la période est personnalisée
            this.selectPeriod(PeriodEnum.Custom, true);
        } else {
            // si aucune date, ni aucune période n'est définie, on sélectionne les 7 derniers jours
            this.selectPeriod(PeriodEnum.Last7Days, true);
        }

        // Valeur par défaut des dates
        if (this.defaultSelectedPeriod == null) {
            // si aucune période n'est définie, on sélectionne les dates par défaut s'il y'en a
            if (this.defaultSelectedStartDate) {
                this.selectStartDateAndTime(this.defaultSelectedStartDate, false);
            }
            if (this.defaultSelectedEndDate) {
                this.selectEndDateAndTime(this.defaultSelectedEndDate, false);
            }
        }
        this.autoSelectMaxDate();
        this.changeDisplayedHours();

        this.patchForm();
    }

    /**
     * Permet de gérer si la période Custom doit être affichée ou non
     * Elle n'est pas affichée si les champs dates ne sont pas activés
     */
    private initCustomPeriod(): void {
        let customPeriodDisabled = false;
        if (this.hidePeriodsValues) {
            customPeriodDisabled =
                this.hidePeriodsValues.findIndex(p => p === PeriodEnum.Custom) >= 0;
        }

        // si la période personnalisée est désactivée, on désactive les dates pickers et times pickers
        if (customPeriodDisabled) {
            if (this.chartSettingsForm) {
                this.chartSettingsForm.get('startDateField').disable();
                this.chartSettingsForm.get('endDateField').disable();
            }
        }

        // si les pickers sont désactivés et que la Période personnalisée est toujours disponible, on la masque
        if (!this.enableDatePickers && !customPeriodDisabled) {
            this.hidePeriodsValues.push(PeriodEnum.Custom);
        }
    }

    private autoHideDomains(): void {
        if (!this.hideDomains) {
            this.isDomainHidden = this.allDomains.length <= 1;
        }
    }

    //#endregion

    /**
     * Sélectionne le domaine
     * Mets à jours le formulaire
     * @param domains
     * @param autoSelect sert à bloquer le rafraichissement automatique des composants qui dépendent de la liste des domaines
     */
    public selectDomains(domains: Array<DomainEnum> | DomainEnum, autoSelect?: boolean): void {
        this.selectedDomains =
            typeof domains === 'number' ? [<DomainEnum>domains] : <Array<DomainEnum>>domains;

        this.changeDisplayedAnalysisLevel();
        this.autoSelectAnalysisLevel();

        if (!autoSelect) {
            this.settingsIsChanging = true;
            this.patchForm();
        }
    }

    /**
     * Sélectionne le niveau d'analyse
     * Mets à jours le formulaire
     * @param analysisLevel
     * @param autoSelect sert à bloquer le rafraichissement automatique des composants qui dépendent de la liste des niveaux d'analyse
     */
    public selectAnalysisLevel(analysisLevel: enumGeographicLevelId, autoSelect?: boolean): void {
        this.selectedAnalysisLevel = analysisLevel;

        if (!autoSelect) {
            this.settingsIsChanging = true;
            this.patchForm();
        }
    }

    /**
     * Sélectionne la source
     * Change les périodes disponibles et sélectionne la période automatiquement
     * Change les échelles temporelles et sélectionne l'échelle temporelle automatiquement
     * @param source
     * @param autoSelect sert à bloquer le rafraichissement automatique des composants qui dépendent de la liste des sources
     */
    public selectSource(source: SourceEnum, autoSelect?: boolean): void {
        this.selectedSource = source;
        this.autoSelectMaxDate();

        if (!autoSelect) {
            this.changeDisplayedPeriods();
            this.autoSelectPeriod();

            this.changeDisplayedTimesteps();
            this.autoSelectTimestep();

            this.settingsIsChanging = true;
            this.patchForm();
        }
    }

    /**
     * Sélectionne la période
     * Change les dates sélectionnées automatiquement
     * Change les échelles temporelles disponibles
     * @param period
     * @param autoSelect sert à bloquer le rafraichissement automatique des composants qui dépendent de la liste des périodes
     */
    public selectPeriod(
        period: PeriodEnum,
        autoSelect?: boolean,
        times: { startTime: Date; endTime: Date } = null
    ): void {
        this.selectedPeriod = period;

        this.changeDisplayedTimesteps();
        this.autoSelectTimestep();
        this.autoSelectDates(times);

        if (!autoSelect) {
            this.settingsIsChanging = true;
            this.patchForm({ emitEvent: false, onlySelf: true });
        }
    }

    /**
     * Sélectionne la date de début et l'heure de début
     * @param date
     * @param autoSelect indique si la sélection est manuelle ou non
     */
    public selectStartDateAndTime(date: Date, autoSelect: boolean): void {
        this.selectStartDate(moment(date), autoSelect);
        // gestion heures
        const m = moment(date);
        this.selectStartTime(m.hour() * 60 + m.minute());
    }

    /**
     * Sélectionne la date de début
     * Change la date de fin automatiquement si elle est antérieur
     * Définit automatiquement la période personnalisée et change les échelles temporelles disponible
     * @param date
     */
    public selectStartDate(date: moment.Moment, autoSelect?: boolean): void {
        this.selectedStartDate = this.dateTimeService.getStartOfDateByTimestep(
            date,
            this.selectedTimestep,
            null
        );
        const prevDate: moment.Moment = this.selectedStartDate.clone();
        // si les heures ne sont pas masquées, on ne change pas l'heure sélectionné automatiquement.
        // on remet l'heure précédente
        if (!this.hideHours) {
            this.selectedStartDate.set({ hours: prevDate.hour(), minutes: prevDate.minute() });
        }

        if (this.selectedEndDate < this.selectedStartDate) {
            this.selectedEndDate = this.dateTimeService.getEndOfDateByTimestep(
                this.selectedStartDate,
                this.selectedTimestep,
                this.selectedMaxDate ? moment(this.selectedMaxDate) : null
            );
        } else {
            // Limit the gap if it is too big
            const timeSteps = [TimestepEnum.Minutes10, TimestepEnum.Minutes, TimestepEnum.Hourly];
            if (
                timeSteps.find(t => t === this.selectedTimestep) &&
                prevDate.add(this.DAYS_GAP_FOR_10_MIN, 'days').isBefore(this.selectedEndDate)
            ) {
                this.selectedEndDate = this.dateTimeService.getEndOfDateByTimestep(
                    prevDate,
                    this.selectedTimestep,
                    this.selectedMaxDate ? moment(this.selectedMaxDate) : null
                );
            }
        }

        if (!autoSelect) {
            this.selectPeriod(PeriodEnum.Custom, autoSelect);
            this.settingsIsChanging = true;
            this.patchForm({ emitEvent: false, onlySelf: true });
        }
    }

    /**
     * Sélectionne l'heure de début
     * Modifie l'heure de la date de début sélectionnée
     * @param value
     */
    public selectStartTime(value: number, autoSelect?: boolean): void {
        this.selectedStartDate.set({ hour: 0, minute: value, second: 0, millisecond: 0 });

        if (this.selectedEndDate.isBefore(this.selectedStartDate)) {
            this.selectedEndDate = this.dateTimeService.getEndOfDateByTimestep(
                this.selectedStartDate,
                this.selectedTimestep,
                this.selectedMaxDate ? moment(this.selectedMaxDate) : null
            );
        }

        if (!autoSelect) {
            this.settingsIsChanging = true;
            this.patchForm({ emitEvent: false, onlySelf: true });
        }
    }

    /**
     * Sélectionne la date de fin et l'heure de fin
     * @param date
     * @param autoSelect indique si la sélection est manuelle ou non
     */
    public selectEndDateAndTime(date: Date, autoSelect: boolean): void {
        this.selectEndDate(moment(date), autoSelect);
        // gestion heures
        const m = moment(date);
        this.selectEndTime(m.hour() * 60 + m.minute());
    }

    /**
     * Sélectionne la date de fin
     * Change la date de début automatiquement si elle est postérieure
     * Définit automatiquement la période personnalisée et change les échelles temporelles disponible
     * @param date
     */
    public selectEndDate(date: moment.Moment, autoSelect?: boolean): void {
        this.selectedEndDate = this.dateTimeService.getEndOfDateByTimestep(
            date,
            this.selectedTimestep,
            this.selectedMaxDate ? moment(this.selectedMaxDate) : null
        );
        const prevDate: moment.Moment = this.selectedEndDate.clone();
        // si les heures ne sont pas masquées, on ne change pas l'heure sélectionné automatiquement.
        // on remet l'heure précédente
        if (!this.hideHours) {
            this.selectedEndDate.set({ hours: prevDate.hour(), minutes: prevDate.minute() });
        }

        if (this.selectedStartDate.isAfter(this.selectedEndDate)) {
            this.selectedStartDate = this.dateTimeService.getStartOfDateByTimestep(
                this.selectedEndDate,
                this.selectedTimestep,
                null
            );
        } else {
            // Limit the gap if it is too big
            const timeSteps = [TimestepEnum.Minutes10, TimestepEnum.Minutes, TimestepEnum.Hourly];
            if (
                timeSteps.find(t => t === this.selectedTimestep) &&
                this.selectedStartDate.isBefore(prevDate.add(-this.DAYS_GAP_FOR_10_MIN, 'days'))
            ) {
                this.selectedStartDate = this.dateTimeService.getStartOfDateByTimestep(
                    prevDate,
                    this.selectedTimestep,
                    null
                );
            }
        }

        if (!autoSelect) {
            this.selectPeriod(PeriodEnum.Custom, autoSelect);
            this.settingsIsChanging = true;
            this.patchForm({ emitEvent: false, onlySelf: true });
        }
    }

    /**
     * Sélectionne l'heure de fin
     * Modifie l'heure de la date de fin sélectionnée
     * @param value
     */
    public selectEndTime(value: number, autoSelect?: boolean): void {
        this.selectedEndDate.set({ hour: 0, minute: value, second: 0, millisecond: 0 });

        if (this.selectedStartDate.isAfter(this.selectedEndDate)) {
            this.selectedStartDate = this.dateTimeService.getStartOfDateByTimestep(
                this.selectedEndDate,
                this.selectedTimestep,
                null
            );
        }

        if (!autoSelect) {
            this.settingsIsChanging = true;
            this.patchForm({ emitEvent: false, onlySelf: true });
        }
    }

    /**
     * Sélectionne l'échelle temporelle
     * définit le format de date du dateTimeService pour l'affichage des pickers
     * @param timestep
     */
    public selectTimestep(timestep: TimestepEnum, autoSelect?: boolean): void {
        this.selectedTimestep = timestep;
        this.dateTimeService.setFormat(timestep);
        this.autoSelectMaxDate();

        this.changeDisplayedHours();

        this.hideHours = this.selectedTimestep <= TimestepEnum.Daily ? true : false;
        if (!autoSelect) {
            if (this.selectedPeriod !== PeriodEnum.Custom) {
                this.selectedStartDate = this.dateTimeService.getPeriodStartDate(
                    this.selectedPeriod,
                    this.selectedTimestep,
                    null
                );
                this.selectedEndDate = this.dateTimeService.getPeriodEndDate(
                    this.selectedPeriod,
                    this.selectedTimestep,
                    this.selectedMaxDate ? moment(this.selectedMaxDate) : null
                );
            } else {
                this.selectedStartDate = this.dateTimeService.getStartOfDateByTimestep(
                    this.selectedStartDate,
                    this.selectedTimestep,
                    null
                );
                this.selectedEndDate = this.dateTimeService.getEndOfDateByTimestep(
                    this.selectedEndDate,
                    this.selectedTimestep,
                    this.selectedMaxDate ? moment(this.selectedMaxDate) : null
                );
            }
            this.settingsIsChanging = true;
            this.patchForm();
        }
    }

    /**
     * Reset the default values
     */
    public reset(): void {
        this.selectDefaultValues();
        this.changeSettings();
    }

    /**
     * Change la liste des domaines affichés
     * > Retire les domaines à masquer
     */
    private changeDisplayedDomains(): void {
        this.displayedDomains = this.allDomains.filter(
            d => this.hideDomainsValues.findIndex(hd => hd === d) === -1
        );
    }

    /**
     * Change la liste des niveaux d'analyse affichés
     * > Retire les niveaux à masquer
     */
    private changeDisplayedAnalysisLevel(): void {
        this.displayedAnalysisLevel = [
            enumGeographicLevelId.town,
            ...this._domainService.getDomainsPerimeters(this.selectedDomains)
        ].filter(a => this.hideAnalysisLevelsValues.findIndex(al => al === a) === -1);

        this.displayedAnalysisLevel = this.displayedAnalysisLevel.filter(
            dal => dal >= this._geoService.perimeterLevelState.id
        );
    }

    /**
     * Change la liste des sources affichées
     * > Retire les sources à masquer
     */
    private changeDisplayedSources(): void {
        if (this.allSources) {
            const sources = this.allSources.filter(
                s => this.hideSourcesValues.findIndex(hs => hs === s) === -1
            );

            this.displayedSources = [];
            const orderedSources = [
                SourceEnum.Invoice,
                SourceEnum.RemoteReading,
                SourceEnum.Reading
            ];
            orderedSources.forEach(os => {
                if (sources.findIndex(s => s === os) !== -1) {
                    this.displayedSources.push(os);
                }
            });
        }
    }

    /**
     * Change la liste des périodes affichées
     * > En fonction de la source sélectionnée
     * > Retire les périodes à masquer
     */
    private changeDisplayedPeriods(): void {
        this.displayedPeriods =
            this.selectedSource != null
                ? this.getSourcePeriods(this.selectedSource).filter(
                      p => this.hidePeriodsValues.findIndex(hp => hp === p) === -1
                  )
                : this.allPeriods.filter(
                      p => this.hidePeriodsValues.findIndex(hp => hp === p) === -1
                  );
    }

    /**
     * Change la liste des échelles temporelles affichées
     * > En fonction de la période sélectionnée
     * > En fonction des sources sélectionnée
     * > Retire les échelles temporelles à masquer
     */
    private changeDisplayedTimesteps(): void {
        let periodTimesteps: Array<TimestepEnum>;
        if (this.selectedPeriod != null) {
            periodTimesteps = this.dateTimeService.getPeriodTimesteps(this.selectedPeriod);
        } else {
            periodTimesteps = this.dateTimeService.getPeriodTimesteps(PeriodEnum.Custom);
        }

        if (this.selectedSource != null) {
            const selectedSourcePeriods = this.sourcesTimesteps.find(
                x => x.key === this.selectedSource
            ).value;
            this.displayedTimesteps = periodTimesteps
                .filter(tsAllowed => selectedSourcePeriods.findIndex(ts => tsAllowed === ts) >= 0)
                .filter(ts => this.hideTimestepsValues.findIndex(hts => hts === ts) === -1);
        } else {
            this.displayedTimesteps = periodTimesteps.filter(
                ts => this.hideTimestepsValues.findIndex(hts => hts === ts) === -1
            );
        }
    }

    private changeDisplayedHours(): void {
        if (this.selectedTimestep === TimestepEnum.Minutes) {
            this.minutesStep = 1;
        } else if (this.selectedTimestep === TimestepEnum.Minutes10) {
            this.minutesStep = 10;
        } else {
            this.minutesStep = 60;
        }
        // calcul des tranches horaires disponible dans les listes déroulantes des heures de début et de fin

        this.displayedMinutesInDay = [];
        for (let i = 0; i < 1440; i = i + this.minutesStep) {
            this.displayedMinutesInDay.push(i);
        }
    }

    private autoSelectAnalysisLevel(): void {
        if (!this.hideAnalysisLevels) {
            if (
                this.selectedAnalysisLevel == null ||
                !(
                    this.displayedAnalysisLevel &&
                    this.displayedAnalysisLevel.findIndex(p => p === this.selectedAnalysisLevel) >=
                        0
                )
            ) {
                if (this.displayedAnalysisLevel) {
                    this.selectAnalysisLevel(this.displayedAnalysisLevel[0], true);
                }
            }
            if (this.chartSettingsForm) {
                this.chartSettingsForm.markAllAsTouched();
            }
        }
    }

    /**
     * Sélectionne automatiquement la période lors du rafraichissement de la liste
     * Si la période précédemment sélectionnée est toujours disponible, on la resélectionne
     * Sinon on prend la 1ère de la liste
     */
    private autoSelectPeriod(): void {
        if (!this.hidePeriods) {
            if (
                this.selectedPeriod == null ||
                !(
                    this.displayedPeriods &&
                    this.displayedPeriods.findIndex(p => p === this.selectedPeriod) >= 0
                )
            ) {
                if (this.displayedPeriods) {
                    this.selectedPeriod = this.displayedPeriods[0];
                    this.autoSelectDates();
                }
            }
            if (this.chartSettingsForm) {
                this.chartSettingsForm.markAllAsTouched();
            }
        }
    }

    /**
     * Sélectionne automatiquement les dates débuts et fin en fonction de la période sélectionnée
     */
    private autoSelectDates(times: { startTime: Date; endTime: Date } = null): void {
        // pour la période Custom, aucun date n'est préselectionnée
        if (this.selectedPeriod !== PeriodEnum.Custom) {
            const startDate = this.dateTimeService.getPeriodStartDate(
                this.selectedPeriod,
                this.selectedTimestep,
                null
            );

            // Si il y a une heure définie, cette heure est choisie au lieu de l'heure par défaut
            if (times && times.startTime) {
                startDate.set({
                    hours: times.startTime.getHours(),
                    minute: times.startTime.getMinutes()
                });
            }

            this.selectStartDate(startDate, true);

            const endDate = this.dateTimeService.getPeriodEndDate(
                this.selectedPeriod,
                this.selectedTimestep,
                null
            );
            if (times && times.endTime) {
                endDate.set({
                    hours: times.endTime.getHours(),
                    minute: times.endTime.getMinutes()
                });
            }

            this.selectEndDate(endDate, true);
            if (this.chartSettingsForm) {
                this.chartSettingsForm.markAllAsTouched();
            }
        }
    }

    /**
     * Sélectionne automatiquement l'échelle temporelle.
     * Si l'échelle précédemment sélectionnée est disponible, on la resélectionne
     * Sinon on sélectionne automatiquement la 1ère de la liste
     */
    private autoSelectTimestep(): void {
        if (!this.hideTimesteps) {
            if (
                this.selectedTimestep == null ||
                !(
                    this.displayedTimesteps &&
                    this.displayedTimesteps.findIndex(ts => ts === this.selectedTimestep) >= 0
                )
            ) {
                if (this.displayedTimesteps) {
                    this.selectTimestep(this.displayedTimesteps[0], true);
                }
            }
            if (this.chartSettingsForm) {
                this.chartSettingsForm.markAllAsTouched();
            }
        }
    }

    private autoSelectDomains(): void {
        const defaultDomains =
            this.defaultSelectedDomains != null && this.defaultSelectedDomains.length > 0
                ? this.defaultSelectedDomains
                : null;
        const userDomains: DomainEnum[] = this._domainService
            .getUserDomains()
            .filter(
                ud =>
                    this._domainService.getUsersUnavailableDomains().findIndex(und => und === ud) <
                    0
            );

        if (userDomains.length <= 1) {
            // si un seul domaine est dans la liste de l'utilisateur, on sélectionne ce domaine par défaut
            this.allDomains = userDomains;
        } else {
            const perimeterSelectedDomain = this._domainService.getActivePerimeterDomain();
            // sinon, si le périmètre géo est au niveau du domaine Géographie (au dessus de ville)
            if (perimeterSelectedDomain === DomainEnum.Geography) {
                this.allDomains = userDomains;
            } else {
                this.allDomains = [perimeterSelectedDomain];
            }
        }

        const existingDomains = [];
        if (defaultDomains) {
            defaultDomains.forEach(d => {
                if (this.allDomains.indexOf(d) > -1) {
                    existingDomains.push(d);
                }
            });
        }

        existingDomains.length > 0
            ? this.selectDomains(existingDomains, true)
            : this.selectDomains(this.allDomains, true);

        if (this.chartSettingsForm) {
            this.chartSettingsForm.markAllAsTouched();
        }
    }

    private autoSelectMaxDate(): void {
        if (
            this.maxDatesBySources != null &&
            this.maxDatesBySources.findIndex(md => md.key === this.selectedSource) >= 0
        ) {
            const selectedSourceDate = this.maxDatesBySources.find(
                md => md.key === this.selectedSource
            ).value;

            const maxMoment = this.dateTimeService.getEndOfDateByTimestep(
                moment(selectedSourceDate),
                this.selectedTimestep
            );
            if (maxMoment != null) {
                this.selectedMaxDate = maxMoment.toDate();
            }
            if (this.chartSettingsForm) {
                this.chartSettingsForm.markAllAsTouched();
            }
        }
    }

    //#region gestion des affichages, traduction des Enums
    /**
     * retourne le libelle du niveau d'analyse passé en paramètre
     * @param analysisLevel
     */
    public getAnalysisLevelLabel(analysisLevel: enumGeographicLevelId): string {
        const analysisLevelRSX = enumGeographicLevelRSX.find(al => al.key === analysisLevel);
        return analysisLevelRSX ? analysisLevelRSX.value : null;
    }

    /**
     * retourne le libelle du domaine passée en paramètre
     * @param timestep
     */
    public getDomainLabel(domain: DomainEnum): string {
        const DomainRSX = DomainEnumRSX.find(ts => ts.key === domain);
        return DomainRSX ? DomainRSX.value : null;
    }
    /**
     * retourne le libelle de la source passée en paramètre
     * @param timestep
     */
    public getSourceLabel(source: SourceEnum): string {
        const SourceRSX = SourceEnumRSX.find(ts => ts.key === source);
        return SourceRSX ? SourceRSX.value : null;
    }
    /**
     * retourne le libelle de la période passée en paramètre
     * @param period
     */
    public getPeriodLabel(period: PeriodEnum): string {
        const periodRSX = PeriodEnumRSX.find(p => p.key === period);
        return periodRSX ? periodRSX.value : null;
    }

    /**
     * retourne le libelle de l'échelle temporelle passée en paramètre
     * @param timestep
     */
    public getTimestepLabel(timestep: TimestepEnum): string {
        const TimestepsRSX = TimestepEnumRSX.find(ts => ts.key === timestep);
        return TimestepsRSX ? TimestepsRSX.value : null;
    }
    //#endregion

    //#region évenements
    /**
     * Evénement déclenché lorsque le formulaire est validé
     */
    public onSubmitForm(): void {
        this.changeSettings();
    }

    public onPickerYearSelected(
        $event: moment.Moment,
        datepicker: MatDatepicker<Date>,
        pickername: string
    ): void {
        if (
            this.selectedTimestep &&
            this._dateTimeService.getStartView(this.selectedTimestep) === 'multi-year'
        ) {
            pickername === 'start' ? this.selectStartDate($event) : this.selectEndDate($event);
            datepicker.close();
        }
    }
    public onPickerMonthSelected(
        $event: moment.Moment,
        datepicker: MatDatepicker<Date>,
        pickername: string
    ): void {
        if (
            this.selectedTimestep &&
            this._dateTimeService.getStartView(this.selectedTimestep) === 'year'
        ) {
            pickername === 'start' ? this.selectStartDate($event) : this.selectEndDate($event);
            datepicker.close();
        }
    }
    //#endregion

    /**
     * Update the form with the selected values
     */
    private patchForm(options?: { emitEvent?: boolean; onlySelf?: boolean }): void {
        if (this.chartSettingsForm) {
            if (this.selectedDomains != null) {
                this.chartSettingsForm
                    .get('domainsField')
                    .patchValue(
                        this.enableMultiDomainSelection
                            ? this.selectedDomains
                            : this.selectedDomains[0]
                    );
            }
            if (this.selectedAnalysisLevel != null) {
                this.chartSettingsForm
                    .get('analysisLevelField')
                    .patchValue(this.selectedAnalysisLevel);
            }
            if (this.selectedSource != null) {
                this.chartSettingsForm.get('sourceField').patchValue(this.selectedSource);
            }
            if (this.selectedPeriod != null) {
                this.chartSettingsForm.get('periodField').patchValue(this.selectedPeriod);
            }
            if (this.selectedStartDate) {
                this.chartSettingsForm
                    .get('startDateField')
                    .patchValue(this.selectedStartDate, options);

                const startTime =
                    this.selectedStartDate.get('hours') * 60 +
                    this.selectedStartDate.get('minutes');
                this.chartSettingsForm
                    .get('startTimeField')
                    .patchValue(
                        Math.floor(startTime / this.minutesStep) * this.minutesStep,
                        options
                    );
            }

            if (this.selectedEndDate) {
                this.chartSettingsForm
                    .get('endDateField')
                    .patchValue(this.selectedEndDate, options);

                const endTime =
                    this.selectedEndDate.get('hours') * 60 + this.selectedEndDate.get('minutes');
                this.chartSettingsForm
                    .get('endTimeField')
                    .patchValue(Math.floor(endTime / this.minutesStep) * this.minutesStep, options);
            }

            if (this.selectedTimestep != null) {
                this.chartSettingsForm.get('timestepField').patchValue(this.selectedTimestep);
            }

            if (this.displayedDomains && this.displayedDomains.length > 1) {
                this.chartSettingsForm.get('domainsField').enable();
            }

            if (!this.enableDatePickers) {
                this.chartSettingsForm.get('startDateField').disable();
                this.chartSettingsForm.get('endDateField').disable();
            } else {
                this.chartSettingsForm.get('startDateField').enable();
                this.chartSettingsForm.get('endDateField').enable();
            }

            if (!this.enableTimePickers) {
                this.chartSettingsForm.get('startTimeField').disable();
                this.chartSettingsForm.get('endTimeField').disable();
            } else {
                this.chartSettingsForm.get('startTimeField').enable();
                this.chartSettingsForm.get('endTimeField').enable();
            }
        }
    }

    /**
     * Renvoie la liste des périodes pour une source donnée
     * @param source
     */
    private getSourcePeriods(source: SourceEnum): Array<PeriodEnum> {
        if (this.sourcesPeriods != null) {
            const sourcePeriods = this.sourcesPeriods.find(pd => pd.key === source);
            return sourcePeriods ? sourcePeriods.value : null;
        }
        return;
    }

    /**
     * déclenche un évenement pour indiquer que les données du formulaire ont changées.
     */
    public changeSettings(isPerimeterChanged: boolean = false): void {
        this.settingsIsChanging = false;
        const params: VertuozChartSettingsModel = {
            domainsSelected: this.selectedDomains,
            analysisLevelSelected: this.selectedAnalysisLevel,
            sourceSelected: this.selectedSource,
            periodSelected: this.selectedPeriod,
            startDateSelected: this.selectedStartDate ? this.selectedStartDate.toDate() : null,
            endDateSelected: this.selectedEndDate ? this.selectedEndDate.toDate() : null,
            timestepSelected: this.selectedTimestep,
            isPerimeterChanged: isPerimeterChanged
        };
        this.settingsChangedEvent.emit(params);
    }
}
