import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { PermissionService } from '@app/core/services/permission.service';
import { AppService } from '../http/app.service';

/**
 * Intercepte les requêtes HTTP pour mettre à jour le header
 *
 *
 * @export
 * @class HeaderInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
    constructor(private permissionService: PermissionService, private appService: AppService) {}

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const userContext = this.appService.getUserContext();
        if (userContext != null && userContext.user) {
            const headers = request.url.endsWith('UploadFirstInitFile')
                ? this.permissionService.getHttpHeaderForUpload()
                : request.url.startsWith('https://api-adresse.data.gouv.fr')
                ? {}
                : this.permissionService.getHttpHeader();
            request = request.clone({
                setHeaders: headers
            });
        }
        return next.handle(request);
    }
}
