import { LayoutModuleState } from '@app/core/store/state/layout/layout-module-state.interface';
import { setPerimeterScope, togglePerimeter } from '@app/core/store/state/layout/layout.action';
import { PerimeterScope } from '@app/shared/constants/filters.enum';
import { createReducer, on } from '@ngrx/store';

export const initialState = new LayoutModuleState({
    perimeterScopeState: PerimeterScope.activeSelectedPerimeter
}); // le State est READONLY

export const layoutModuleReducer = createReducer(
    initialState,
    on(
        togglePerimeter,
        state =>
            <LayoutModuleState>{
                perimeterScopeState:
                    state.perimeterScopeState === PerimeterScope.activeGeographicRight
                        ? PerimeterScope.activeSelectedPerimeter
                        : PerimeterScope.activeGeographicRight
            }
    ),
    on(
        setPerimeterScope,
        (state, { newPerimeterScope }) =>
            <LayoutModuleState>{
                perimeterScopeState: newPerimeterScope
            }
    )
);
