//#region Imports
import { InjectionToken } from '@angular/core';

import { MsalGuard } from '@azure/msal-angular';

import { AccessGuard } from '@app/core/guards/access.guard';
import { IdentificationGuard } from '@app/core/guards/identification.guard';
import { EnumPermissions } from '@app/core/models/permissions/permissions.enum';
import { LayoutComponent } from '@app/layout/layout.component';
import { LoginComponent } from '@app/pages/login/login.component';
import { NotFoundComponent } from '@app/pages/not-found/not-found.component';
import { UnauthorizedComponent } from '@app/pages/unauthorized/unauthorized.component';
import { appRoutes } from './app-routes.enum';
import { SiteDisabledComponent } from '@app/pages/site-disabled/site-disabled.component';
//#endregion

//#region Types
export const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');
//#endregion

//#region Routes
export const AppRoutes = [
    {
        path: appRoutes.login,
        component: LoginComponent,
        canActivate: [MsalGuard]
    },
    {
        path: '',
        component: LayoutComponent,
        canActivate: [IdentificationGuard],
        canActivateChild: [IdentificationGuard, AccessGuard],
        children: [
            {
                path: '',
                redirectTo: appRoutes.dashboard,
                pathMatch: 'full',
                data: {
                    title: 'Vertuoz - Tableau de bord - Accueil'
                }
            },
            {
                path: appRoutes.dashboard,
                loadChildren: () =>
                    import('../features/dashboard/dashboard.module').then(m => m.DashboardModule),
                data: {
                    title: 'Vertuoz - Tableau de bord - Accueil'
                }
            },
            {
                path: appRoutes.fluids,
                loadChildren: () =>
                    import('../features/consumption-monitoring/consumption-monitoring.module').then(
                        m => m.ConsumptionMonitoringModule
                    ),
                data: {
                    permissions: [EnumPermissions.CONSUMPTION_MONITORING_ACCESS],
                    title: 'Vertuoz - Tableau de bord - Consommations agrégées'
                }
            },
            {
                path: appRoutes.propertyData,
                loadChildren: () =>
                    import('../features/property-data/property-data.module').then(
                        m => m.PropertyDataModule
                    ),
                data: {
                    permissions: [EnumPermissions.SITES_COMPARISON_ACCESS],
                    title: 'Vertuoz - Tableau de bord - Consommations par site'
                }
            },
            {
                path: appRoutes.measure,
                loadChildren: () =>
                    import('../features/measure/measure.module').then(m => m.MeasureModule),
                data: {
                    permissions: [EnumPermissions.DEVICE_ACCESS],
                    title: 'Vertuoz - Tableau de bord - Suivi des mesures'
                }
            },
            {
                path: appRoutes.loadCurve,
                loadChildren: () =>
                    import('../features/load-curve/load-curve.module').then(m => m.LoadCurveModule),
                data: {
                    permissions: [EnumPermissions.LOAD_CURVE_ACCESS],
                    title: 'Vertuoz - Tableau de bord - Courbe de charge'
                }
            },
            {
                path: appRoutes.geography,
                loadChildren: () =>
                    import('../features/perimeter/geography/geography.module').then(
                        m => m.GeographyModule
                    ),
                data: {
                    title: 'Vertuoz - Tableau de bord - Arboresence géographique'
                }
            },
            {
                path: appRoutes.property,
                loadChildren: () =>
                    import('../features/perimeter/property/property.module').then(
                        m => m.PropertyModule
                    ),
                data: {
                    permissions: [EnumPermissions.PROPERTY_ACCESS]
                }
            },
            {
                path: appRoutes.publicSpace,
                loadChildren: () =>
                    import('../features/perimeter/public-space/public-space.module').then(
                        m => m.PublicSpaceModule
                    ),
                data: { permissions: [EnumPermissions.PUBLICSPACE_ACCESS] }
            },
            {
                path: appRoutes.device,
                loadChildren: () =>
                    import('../features/device/device.module').then(m => m.DeviceModule),
                data: {
                    permissions: [EnumPermissions.DEVICE_ACCESS],
                    title: 'Vertuoz - Tableau de bord - Mesures et capteurs'
                }
            },
            {
                path: appRoutes.map,
                loadChildren: () => import('../features/map/map.module').then(m => m.MapModule),
                data: {
                    permissions: [EnumPermissions.MAP_ACCESS],
                    title: 'Vertuoz - Tableau de bord - Carte'
                }
            },
            {
                path: appRoutes.invoices,
                loadChildren: () =>
                    import('../features/invoice-reading/invoices/invoices.module').then(
                        m => m.InvoicesModule
                    ),
                data: {
                    permissions: [EnumPermissions.INVOICES_ACCESS],
                    title: 'Vertuoz - Tableau de bord - Factures'
                }
            },
            {
                path: appRoutes.readings,
                loadChildren: () =>
                    import('../features/invoice-reading/readings/readings.module').then(
                        m => m.ReadingsModule
                    ),
                data: {
                    permissions: [EnumPermissions.READINGS_ACCESS],
                    title: 'Vertuoz - Tableau de bord - Relèves'
                }
            },
            {
                path: appRoutes.automaticInvoice,
                loadChildren: () =>
                    import(
                        '../features/invoice-reading/invoices/automatic-invoice/automatic-invoice.module'
                    ).then(m => m.AutomaticInvoiceModule),
                data: {
                    permissions: [EnumPermissions.INVOICES_IMPORT_AUTO],
                    title: 'Vertuoz - Tableau de bord - Factures'
                }
            },
            // {
            //     path: appRoutes.addAutomaticInvoiceConfig,
            //     loadChildren: () =>
            //         import('../features/invoice-reading/invoices/automatic-invoice/AddAutomaticInvoiceConfiguration/add-automatic-invoice-config.module').then(
            //             m => m.AddAutomaticInvoiceModule
            //         ),
            //     data: { permissions: [EnumPermissions.INVOICES_IMPORT_AUTO] }
            // },
            {
                path: appRoutes.fileImport,
                loadChildren: () =>
                    import('../features/file-import/file-import.module').then(
                        m => m.FileImportModule
                    ),
                data: {
                    title: 'Vertuoz - Tableau de bord'
                }
            },
            {
                path: appRoutes.fileImportInvoices,
                loadChildren: () =>
                    import('../features/file-import/file-import.module').then(
                        m => m.FileImportModule
                    ),
                data: {
                    title: 'Vertuoz - Tableau de bord'
                }
            },
            {
                path: appRoutes.fileImportReadings,
                loadChildren: () =>
                    import('../features/file-import/file-import.module').then(
                        m => m.FileImportModule
                    ),
                data: {
                    title: 'Vertuoz - Tableau de bord'
                }
            },
            {
                path: appRoutes.scenarios,
                loadChildren: () =>
                    import('../features/scenarios/scenarios.module').then(m => m.ScenariosModule),
                data: {
                    permissions: [EnumPermissions.IMPORT_SCENARIO_ACCESS],
                    title: 'Vertuoz - Tableau de bord - Import et Export des données'
                }
            },
            {
                path: appRoutes.depotFile,
                loadChildren: () =>
                    import(
                        '../features/invoice-reading/invoices/add-pdf-files/add-pdf-file.module'
                    ).then(m => m.AddPdfFileModule),
                data: {
                    permissions: [EnumPermissions.INVOICES_IMPORT_PDF],
                    title: 'Vertuoz - Tableau de bord - Factures'
                }
            },
            {
                path: appRoutes.administration,
                loadChildren: () =>
                    import('../features/administration/administration.module').then(
                        m => m.AdministrationModule
                    ),
                data: {
                    title: 'Vertuoz - Tableau de bord - Administration'
                }
            },
            {
                path: appRoutes.objectives,
                loadChildren: () =>
                    import('../features/objectives/objectives.module').then(
                        m => m.ObjectivesModule
                    ),
                data: {
                    permissions: [EnumPermissions.OBJECTIVES_ACCESS],
                    title: 'Vertuoz - Tableau de bord Objectives'
                }
            },
            {
                path: appRoutes.equipments,
                loadChildren: () =>
                    import('../features/equipments/equipments.module').then(
                        m => m.EquipmentsModule
                    ),
                data: {
                    permissions: [EnumPermissions.EQUIPMENTS_ACCESS],
                    title: 'Vertuoz - Tableau de bord - Equipements '
                }
            },
            {
                path: appRoutes.restitution,
                loadChildren: () =>
                    import('../features/restitution-objectives/restitution-objectives.module').then(
                        m => m.RestitutionObjectivesModule
                    ),
                data: {
                    permissions: [EnumPermissions.OBJECTIVES_ACCESS],
                    title: 'Vertuoz - Tableau de bord - Suivi des Objectifs'
                }
            },
            {
                path: appRoutes.aggregateProduction,
                loadChildren: () =>
                    import('../features/aggregate-production/aggregate-production.module').then(
                        m => m.AggregateProductionModule
                    ),
                data: {
                    permissions: [EnumPermissions.CONSUMPTION_MONITORING_ACCESS],
                    title: 'Vertuoz - Tableau de bord - Production agrégée'
                }
            },
            {
                path: appRoutes.groups,
                loadChildren: () =>
                    import('../features/groups-section/groups-section.module').then(
                        m => m.GroupsSectionModule
                    ),
                data: {
                    permissions: [EnumPermissions.GROUP_ACCESS],
                    title: 'Vertuoz - Tableau de bord - Groupes'
                }
            },
            {
                path: appRoutes.search,
                loadChildren: () =>
                    import('../features/search/search.module').then(m => m.SearchModule)
            },
            {
                path: appRoutes.meteringPoint,
                loadChildren: () =>
                    import('../features/metering-point/metering-point.module').then(
                        m => m.MeteringPointModule
                    ),
                data: {
                    permissions: [EnumPermissions.METERINGPLAN_ACCESS],
                    title: 'Vertuoz - Tableau de bord - Plan de comptage'
                }
            },
            {
                path: appRoutes.dataControl,
                loadChildren: () =>
                    import('../features/data-control/data-control.module').then(
                        m => m.DataControlModule
                    ),
                data: {
                    permissions: [EnumPermissions.CONTROL_RULES_ACCESS],
                    title: 'Vertuoz - Tableau de bord - Règles de contrôle'
                }
            },
            {
                path: appRoutes.vehicle,
                loadChildren: () =>
                    import('../features/vehicle/vehicle.module').then(m => m.VehicleModule),
                data: {
                    permissions: [EnumPermissions.VEHICLE_ACCESS],
                    title: 'Vertuoz - Tableau de bord - Véhicule'
                }
            },
            {
                path: appRoutes.init,
                loadChildren: () => import('../features/init/init.module').then(m => m.InitModule)
            },
            {
                path: appRoutes.initImport,
                loadChildren: () =>
                    import('../features/init-import/init-import.module').then(
                        m => m.InitImportModule
                    ),
                data: {
                    permissions: [EnumPermissions.METERINGPLAN_ACCESS],
                    title: 'Vertuoz - Tableau de bord - Initialisation'
                }
            },
            {
                path: appRoutes.energySupplierContracts,
                loadChildren: () =>
                    import(
                        '../features/energy-supplier-contracts/energy-supplier-contracts.module'
                    ).then(m => m.EnergySupplierContractsModule),
                data: {
                    permissions: [EnumPermissions.ENERGY_SUPPLIER_CONTRACTS_ACCESS],
                    title: 'Vertuoz - tableau de bord - Contrats fournisseurs'
                }
            }
        ]
    },
    {
        path: appRoutes.externalRedirect,
        canActivate: [externalUrlProvider],
        component: NotFoundComponent
    },
    {
        path: appRoutes.notFound,
        component: NotFoundComponent,
        canActivate: [IdentificationGuard]
    },
    {
        path: appRoutes.unauthorized,
        component: UnauthorizedComponent,
        canActivate: [IdentificationGuard]
    },
    {
        path: appRoutes.siteDisabled,
        component: SiteDisabledComponent
    },
    { path: '**', redirectTo: appRoutes.default }
];
//#endregion
