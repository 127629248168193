import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';

import { PerimeterItemBaseModel } from '@app/core/models/perimeters/perimeter-item-base.model';
import { PerimeterItemModel } from '@app/core/models/perimeters/perimeter-item.model';
import { PerimeterLevelModel } from '@app/core/models/perimeters/perimeter-level.model';
import { EnumPerimeterLevels } from '@app/features/perimeter/common/perimeter-levels.enum';
import { SelectionItem } from './model/selection-item';
import { TertiaryDecreeFilter } from './model/tertiary-decree-filter.model';
import { GeoMenuService } from '../geographic-menu/geo-menu.service';

export enum DispatchHierachyEnum {
    all = 'all',
    onlyDisplayLevel = 'only-display-level',
    no = 'no'
}

@Component({
    selector: 'vertuoz-perimeter-selector',
    templateUrl: './perimeter-selector.component.html',
    styleUrls: ['./perimeter-selector.component.scss']
})
export class PerimeterSelectorComponent implements OnInit, OnChanges {
    //#region fields

    @ViewChild(MatAccordion) propertiesAccordion: MatAccordion;
    @Input() updateMode: boolean;
    // highlight only the current actif level (default: true)
    @Input() highlightMode = true;
    // levels ids where we should display parents items
    @Input() displayParentsItemsFor: Array<number> = null;
    @Input() selectedLabel: string;
    @Input() lowestLevelMode = true;
    @Input() selectedInfoLevel: string;
    @Input() partialSelectedLabel: string;
    @Input() displayUntilLevel: number;
    @Input() displayBeginLevel: number;
    @Input() loading = false;
    @Input() dispatchHierachy: DispatchHierachyEnum = DispatchHierachyEnum.all;
    @Input() filterSelection: Array<PerimeterItemModel> = [];
    @Input() selection: PerimeterItemModel[];
    @Input() tertiaryDecreeFilter?: TertiaryDecreeFilter;

    @Input() key: string;

    @Output() closedPanel = new EventEmitter<number>();
    @Output() selectionChange = new EventEmitter<void>();

    searchMode = false;
    diplayLevel: Array<number> = [];
    searchItems: Array<PerimeterLevelModel>;

    // l'état par defaut de la selection apres un cancel (retour a l'état initial du chargement de la page)
    defaultSelection: Array<PerimeterItemModel>;
    defaultFilterSelection: Array<PerimeterItemModel>;

    modePublicSpace: boolean;

    //#endregion

    //#region construtor / lifecycle hook

    constructor(private geoMenuService: GeoMenuService) {}

    ngOnInit(): void {
        this.initDisplayLevel();
        this.modePublicSpace = this.displayUntilLevel > 10;

        this.geoMenuService.getSearchParameters(this.key).subscribe(() => {
            this.closeAllPanel();
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selection) {
            this.setUserSelectionHistory(this.selection, this.filterSelection);

            if (this.selection) {
                for (const selection of this.selection) {
                    this.dispatchHierarchies(selection, true);
                }
            }
        }
        if (changes.displayBeginLevel || changes.displayUntilLevel) {
            this.initDisplayLevel();
        }
    }

    //#endregion

    public initDisplayLevel(): void {
        this.searchItems = [];
        this.diplayLevel.length = 0;
        for (let i = this.displayBeginLevel; i <= this.displayUntilLevel; i++) {
            this.diplayLevel.push(i);
            this.searchItems.push(<PerimeterLevelModel>{ levelId: i, propertiesCount: 0 });
        }
    }

    // sauvegarde la selection précédente en sauvegarde par defaut apres un changement
    // TODO a reprendre avec une liste pour gérer les undo et do (aujourd'hui le undo fais un cancel et revient usr l'état initial)
    private setUserSelectionHistory(
        userSelection: Array<PerimeterItemModel>,
        userFilterSelection: Array<PerimeterItemModel>
    ): void {
        if (!this.defaultSelection && userSelection && userSelection.length > 0) {
            this.defaultSelection = [];
            this.defaultSelection.push(...userSelection);
        }
        if (!this.defaultFilterSelection && userSelection && userSelection.length > 0) {
            this.defaultFilterSelection = [];
            this.defaultFilterSelection.push(...userFilterSelection);
        }
    }
    /**
     * permet dans une liste de périmètre de trouver un élément parent et de supprimer tous ses enfants de la selection est des filtres
     * @param propertiesModel
     * @param itemParent
     */
    private findAnddeleteChilds(
        propertiesModel: Array<PerimeterItemModel>,
        itemParent: PerimeterItemBaseModel
    ): void {
        const childLevelId =
            this.modePublicSpace && itemParent.perimeterLevel === 0
                ? itemParent.perimeterLevel + 11
                : itemParent.perimeterLevel + 1;
        if (childLevelId > this.lowestLevel) {
            return;
        }

        // on cherche a supprimer tous les enfants du parent
        let indexSelection = propertiesModel.findIndex(
            it => it.parentItemId === itemParent.itemId && it.perimeterLevel === childLevelId
        );
        while (indexSelection > -1) {
            const childItem = propertiesModel.splice(indexSelection, 1);
            // on supprime également les enfants de l'élément qui vient d'être supprimé
            this.removeAllChildSelecionAndFilter(childItem[0]);
            indexSelection = propertiesModel.findIndex(
                it => it.parentItemId === itemParent.itemId && it.perimeterLevel === childLevelId
            );
        }
    }

    /** Permet de supprimer les enfants de l'élement renseigné de manière récursive */
    private removeAllChildSelecionAndFilter(item: PerimeterItemBaseModel): void {
        // si l'élément renseigné est une nation on cherche a supprimer tous ses enfants région
        // si l'élément renseigné est une région on cherche a supprimer tous ses enfants départements
        // ...
        // si l'élement renseigné est une zone il n'y a pas d'enfant a supprimer
        this.findAnddeleteChilds(this.selection, item);
        this.findAnddeleteChilds(this.filterSelection, item);
    }

    public selectionUpdate($event: SelectionItem): void {
        // this.setUserSelectionHistory(this.userSelection, this.userFilterSelection);
        // la séléction change : dans le cas ou l'on a ajouté un élément sélectionné. on supprime les sélectionés et partiellement sélectionné ayant ce parent
        if ($event.checked && !this.lowestLevelMode) {
            this.removeAllChildSelecionAndFilter($event.item);
        }
        // ça prsk on doit traiter les level de bas vers le haut pour vérifier
        // qu'un niveau n'a plus de fils apres le deselection
        this.dispatchHierarchies($event.item, $event.checked);

        if (this.lowestLevelMode) {
            // selection change emit inside
            this.defineLowestLevel();
        } else {
            // here selection change sometime
            this.selectionChange.emit();
        }
    }

    private dispatchHierarchies(property: PerimeterItemModel, isSelected: boolean): void {
        if (property.parentItems && property.parentItems.length > 0) {
            property.parentItems.sort((a, b) => (a.perimeterLevel < b.perimeterLevel ? 1 : -1));
            property.parentItems.map(itemBaseHierarchy =>
                this.dispatchSelection(property, itemBaseHierarchy, isSelected)
            );
        }
    }

    private defineLowestLevel(): void {
        // toutes les selection superieur au niveau le plus bas deviennet des filtres
        this.filterSelection.push(
            ...this.selection.filter(f => f.perimeterLevel < this.lowestLevel)
        );
        // Seul les sélection du niveau le plus bas sont gardé
        const keepLowestLevelSelection = this.selection.filter(
            f => f.perimeterLevel === this.lowestLevel
        );
        this.selection.length = 0;
        this.selection.push(...keepLowestLevelSelection);
        // les filtres du niveau le plus bas devienne dess selection
        this.selection.push(
            ...this.filterSelection.filter(f => f.perimeterLevel === this.lowestLevel)
        );
        // les filtres sont gardé sauf ceux du niveau le plus bas
        const keepFilterSelectionWithoutLoswestLevel = this.filterSelection.filter(
            f => f.perimeterLevel !== this.lowestLevel
        );
        this.filterSelection.length = 0;

        this.filterSelection.push(...keepFilterSelectionWithoutLoswestLevel);

        // here selection change sometime
        this.selectionChange.emit();
    }

    displayParents(levelId: number): boolean {
        return this.displayParentsItemsFor.includes(levelId);
    }

    public levelReset($event: number): void {
        const keepUpperSelection = this.selection.filter(u => u.perimeterLevel !== $event);
        this.selection.length = 0;
        this.selection.push(...keepUpperSelection);

        // Si le level id est district ou district
        if (
            (this.modePublicSpace && $event === EnumPerimeterLevels.DISTRICT) ||
            $event === EnumPerimeterLevels.ESTABLISHMENT
        ) {
            // On check les lists filter et selected du niveau establishment, si il y a une valeur on ne selectionne pas le parent directement
            this.levelResetLogicSelectionParent($event);
        }
        // Si la pastille est ni establishment ni district pas  besoin de check
        else {
            // le niveau juste supérieur partiellement selectionné  passe à sélectionné s'il n'as pas d'enfant sélectionné
            this.selection.push(
                ...this.filterSelection
                    .filter(
                        f =>
                            f.perimeterLevel ===
                            (this.modePublicSpace && $event === EnumPerimeterLevels.DISTRICT
                                ? $event - 11
                                : $event - 1)
                    )
                    .filter(res => {
                        return !this.filterSelection
                            .filter(f => f.perimeterLevel === $event)
                            .some(x => x.parentItemId === res.itemId);
                    })
            );
        }

        // les niveau supérieur partiellement selectionné passe à déselectionné s'il n'existe pas dans la sélection
        const temp = this.filterSelection.filter(x => {
            return !this.selection.some(
                t => t.itemId === x.itemId && t.perimeterLevel === x.perimeterLevel
            );
        });
        this.filterSelection.length = 0;

        this.filterSelection.push(...temp);

        if (this.lowestLevelMode) {
            // selection change emit inside
            this.defineLowestLevel();
        } else {
            // here selection change
            this.selectionChange.emit();
        }
    }

    private levelResetLogicSelectionParent(levelId: number): void {
        if (
            this.filterSelection
                .filter(f => f.perimeterLevel === (levelId === 11 ? levelId - 10 : levelId + 10))
                .filter(res => {
                    return !this.filterSelection
                        .filter(f => f.perimeterLevel === levelId)
                        .some(x => x.parentItemId === res.parentItemId);
                }).length !== 0 ||
            this.selection
                .filter(f => f.perimeterLevel === (levelId === 11 ? levelId - 10 : levelId + 10))
                .filter(res => {
                    return !this.selection
                        .filter(f => f.perimeterLevel === levelId)
                        .some(x => x.parentItemId === res.parentItemId);
                }).length !== 0
        ) {
            // On stock les elements qui ont le même parent entre selection establishment et selection EP s'il y en a
            const sameImmoEpIdsSelection = this.selection
                .filter(f => f.perimeterLevel === (levelId === 11 ? levelId - 10 : levelId + 10))
                .filter(res => {
                    return !this.selection
                        .filter(f => f.perimeterLevel === levelId)
                        .some(x => x.parentItemId === res.parentItemId);
                })
                .map(res => res.parentItemId);
            // On stock les elements qui ont le même parent entre filter establishment et selection EP s'il y en a
            const sameImmoEpIdsFilter = this.filterSelection
                .filter(f => f.perimeterLevel === (levelId === 11 ? levelId - 10 : levelId + 10))
                .filter(res => {
                    return !this.selection
                        .filter(f => f.perimeterLevel === levelId)
                        .some(x => x.parentItemId === res.parentItemId);
                })
                .map(res => res.parentItemId);

            // On passe en selectionner les villes qui n'ont plus d'enfant direct
            this.selection.push(
                ...this.filterSelection
                    .filter(f => f.perimeterLevel === (levelId === 11 ? levelId - 11 : levelId - 1))
                    .filter(res => {
                        return (
                            !sameImmoEpIdsSelection.some(parentId => parentId === res.itemId) &&
                            !sameImmoEpIdsFilter.some(parentId => parentId === res.itemId)
                        );
                    })
            );
        } else {
            this.selection.push(
                ...this.filterSelection
                    .filter(f => f.perimeterLevel === (levelId === 11 ? levelId - 11 : levelId - 1))
                    .filter(res => {
                        return !this.filterSelection
                            .filter(f => f.perimeterLevel === levelId)
                            .some(x => x.parentItemId === res.itemId);
                    })
            );
        }

        // here selection change sometime
        this.selectionChange.emit();
    }

    private pushHierarchyFilterItem(
        item: PerimeterItemModel,
        hierarchyBaseItem: PerimeterItemBaseModel,
        userFilterSelection: Array<PerimeterItemModel>,
        userSelection: Array<PerimeterItemModel>
    ): void {
        const hierarchyItem = <PerimeterItemModel>{
            itemId: hierarchyBaseItem.itemId,
            itemLabel: hierarchyBaseItem.itemLabel,
            perimeterLevel: hierarchyBaseItem.perimeterLevel,
            parentItemId: hierarchyBaseItem.parentItemId,
            parentItems: item.parentItems.filter(
                ph => ph.perimeterLevel < hierarchyBaseItem.perimeterLevel
            )
        };
        // l'élément à ajouter dans la liste des filtres est trouvé dans les listes des éléments seléctionées
        // alors on l'ajoute au filtre et on supprime de la liste des séléctions
        if (
            userSelection.some(
                el =>
                    el.itemId === hierarchyItem.itemId &&
                    el.perimeterLevel === hierarchyItem.perimeterLevel
            )
        ) {
            userFilterSelection.push(hierarchyItem);

            const index = userSelection.findIndex(
                el =>
                    el.itemId === hierarchyItem.itemId &&
                    el.perimeterLevel === hierarchyItem.perimeterLevel
            );
            if (index > -1) {
                userSelection.splice(index, 1);
            }
        }
        // l'élément a ajouter dans la liste des filtres n'existe pas déjà
        else if (
            !userFilterSelection.some(
                el =>
                    el.itemId === hierarchyItem.itemId &&
                    el.perimeterLevel === hierarchyItem.perimeterLevel
            )
        ) {
            userFilterSelection.push(hierarchyItem);
        }
        // sinon on fait rien
    }

    // todo adapter pour EP
    get lowestLevel(): number {
        if (this.selection && this.selection.length > 0) {
            const lowestLevel = Math.max(
                ...this.selection.map(u =>
                    this.modePublicSpace ? u.perimeterLevel : u.perimeterLevel
                )
            );
            return lowestLevel;
        } else if (this.filterSelection && this.filterSelection.length > 0) {
            const lowestLevel = Math.max(...this.filterSelection.map(u => u.perimeterLevel));
            return lowestLevel;
        } else {
            return this.displayBeginLevel;
        }
    }

    private removeFilterItem(
        item: PerimeterItemBaseModel,
        userFilterSelection: Array<PerimeterItemModel>,
        userSelectionAndFilterSelectionLevelDown: Array<PerimeterItemModel>
    ): void {
        if (!this.lowestLevelMode) {
            // cherche si l'item existe déjà dans la liste des filtres (et vérifie que la liste donnée est bien du même niveau)
            const index = userFilterSelection.findIndex(
                el => el.itemId === item.itemId && el.perimeterLevel === item.perimeterLevel
            );
            if (index > -1) {
                // l'item existe déjà. On cherche mainteant si l'item a d'autre enfant sélectioné ou partiellement selectionné si non on suprime l'élément des filtres
                if (
                    !userSelectionAndFilterSelectionLevelDown.some(
                        downLevel =>
                            downLevel.parentItemId === item.itemId &&
                            downLevel.perimeterLevel ===
                                (this.modePublicSpace && item.perimeterLevel === 0
                                    ? item.perimeterLevel + 11
                                    : item.perimeterLevel + 1)
                    )
                ) {
                    userFilterSelection.splice(index, 1);
                }
            }
        }
    }

    private dispatchSelection(
        item: PerimeterItemModel,
        hierarchyBaseItem: PerimeterItemBaseModel,
        isAdded: boolean
    ): void {
        if (this.dispatchHierachy !== DispatchHierachyEnum.no) {
            if (
                this.dispatchHierachy === DispatchHierachyEnum.all ||
                (this.dispatchHierachy === DispatchHierachyEnum.onlyDisplayLevel &&
                    hierarchyBaseItem.perimeterLevel >= this.displayBeginLevel &&
                    hierarchyBaseItem.perimeterLevel <= this.displayUntilLevel)
            ) {
                if (isAdded) {
                    this.pushHierarchyFilterItem(
                        item,
                        hierarchyBaseItem,
                        this.filterSelection,
                        this.selection
                    );
                } else {
                    this.removeFilterItem(
                        hierarchyBaseItem,
                        this.filterSelection,
                        this.selection.concat(this.filterSelection)
                    );
                }
            }
        }
    }

    public closeAllPanel(): void {
        if (!this.propertiesAccordion) {
            return;
        }

        this.propertiesAccordion.multi = true;
        this.propertiesAccordion.closeAll();
        this.propertiesAccordion.multi = false;
    }

    //#region search mode
    public searchPerimeter(): void {
        this.searchMode = true;
        this.closeAllPanel();
    }

    public stopSearchMode(): void {
        this.closeAllPanel();
        this.searchMode = false;
    }
    public resetDefaultSelection(): void {
        this.selection.length = 0;
        this.filterSelection.length = 0;

        if (
            this.defaultSelection &&
            this.defaultSelection.length > 0 &&
            this.defaultFilterSelection
        ) {
            this.selection.push(...this.defaultSelection);
            this.filterSelection.push(...this.defaultFilterSelection);

            // here selection change
            this.selectionChange.emit();
        }
    }

    public removeAllSelection(): void {
        this.selection.length = 0;
        this.filterSelection.length = 0;

        // here selection change
        this.selectionChange.emit();
    }

    closedOtherPanel($event: number): void {
        this.closedPanel.emit($event);
    }

    public _selectionChange(): void {
        this.selectionChange.emit();
    }
    //#endregion
}
