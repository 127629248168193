import {
    AfterViewInit,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges
} from '@angular/core';
import { Operators } from '@app/core/models/data-control/enums/data-control.enum';
import { DataControlConditionPeriodicityModel } from '@app/core/models/data-control/models/data-control-rule.model';
import * as Highcharts from 'highcharts';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ThresholdGraphService } from './service/threshold-graph.service';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const require: (arg0: string) => any;
const More = require('highcharts/highcharts-more');

More(Highcharts);

@Component({
    selector: 'threshold-graph',
    templateUrl: './threshold-graph.component.html',
    styleUrls: ['./threshold-graph.component.scss']
})
export class ThresholdGraphComponent implements AfterViewInit, OnChanges, OnDestroy {
    private _onDestroy = new Subject<void>();
    public chart: Highcharts.Chart;
    public options: Object = {};
    @Input() startDate: Date;
    @Input() endDate: Date;
    @Input() dataControlPeriodicities: DataControlConditionPeriodicityModel[];
    @Input() thresholdMin: number;
    @Input() thresholdMax: number;
    @Input() unitLabel: string;
    @Input() eventGenerate: Observable<void>;

    copyDataControlPeriodicities: DataControlConditionPeriodicityModel[];
    copyThresholdMin: number;
    copyThresholdMax: number;
    public ranges: Array<Array<Array<number>>>;

    constructor(public tservice: ThresholdGraphService) {}

    ngAfterViewInit(): void {
        this.eventGenerate.pipe(takeUntil(this._onDestroy)).subscribe(e => {
            this.ranges = this.tservice.generateRangesThresholdGraph(
                this.startDate,
                this.endDate,
                this.dataControlPeriodicities,
                this.thresholdMax,
                this.thresholdMin
            );
            this.copyDataControlPeriodicities = [...this.dataControlPeriodicities];
            this.copyThresholdMax = this.thresholdMax;
            this.copyThresholdMin = this.thresholdMin;
            setTimeout(() => this.updateChart(this.thresholdMax, this.thresholdMin), 50);
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.firstChange) {
            if (
                (changes.startDate && changes.startDate.currentValue) ||
                (changes.endDate && changes.endDate.currentValue)
            ) {
                if (this.copyDataControlPeriodicities) {
                    this.ranges = this.tservice.generateRangesThresholdGraph(
                        this.startDate,
                        this.endDate,
                        this.copyDataControlPeriodicities,
                        this.copyThresholdMax,
                        this.copyThresholdMin
                    );
                    setTimeout(
                        () => this.updateChart(this.copyThresholdMax, this.copyThresholdMin),
                        50
                    );
                }
            }
        }
    }

    ngOnDestroy(): void {
        if (this.chart) {
            this.chart.destroy();
        }
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    public updateChart(threshMax: number, threshMin: number): void {
        this.options = {
            title: {
                text: 'Planning'
            },
            xAxis: {
                labels: {
                    format: '{value:%d/%m/%Y %H:%M}',
                    rotation: -45
                },
                crosshair: true,
                type: 'datetime'
            },

            yAxis: {
                title: {
                    text: this.unitLabel
                },
                max: threshMax,
                min: threshMin,
                startOnTick: false,
                endOnTick: false
            },
            tooltip: {
                // tslint:disable-next-line:typedef
                formatter: function() {
                    const date = new Date(this.x);
                    const operator = this.points[0].point.custom.operator;
                    const operatorIs = this.points[0].point.custom.operatorIs;

                    const operatorName =
                        operator === 1
                            ? 'supérieure à'
                            : operator === 2
                            ? 'inférieure à'
                            : operator === 3
                            ? 'égale à'
                            : 'comprise entre';
                    const operatorIsName = operatorIs ? 'est' : "n'est pas";

                    let sentence = '';

                    if (
                        (operator === Operators.Between || operator === Operators.Equal) &&
                        !operatorIs
                    ) {
                        if (operator === Operators.Equal) {
                            sentence = `La valeur ${operatorIsName} ${operatorName} ${this.points[0].point.options.low} ${this.points[0].series.options.unit}`;
                        } else {
                            sentence = `La valeur ${operatorIsName} ${operatorName} ${this.points[0].point.options.low} ${this.points[0].series.options.unit} et ${this.points[1].point.options.low} ${this.points[0].series.options.unit}`;
                        }
                    } else {
                        if (operator === Operators.Between) {
                            sentence = `La valeur ${operatorIsName} ${operatorName} ${this.points[0].point.options.low} ${this.points[0].series.options.unit} et ${this.points[0].point.options.high} ${this.points[0].series.options.unit}`;
                        } else {
                            sentence = `La valeur ${operatorIsName} ${operatorName} ${this.points[0].point.options.low} ${this.points[0].series.options.unit}`;
                        }
                    }

                    return `${date.toLocaleString('fr-FR')}
                <br>
                ${sentence}`;
                },
                shared: true
            },
            chart: {
                panning: true,
                panKey: 'shift',
                zoomType: 'x',
                style: {
                    fontFamily: 'Roboto'
                }
            },
            plotOptions: {
                series: {
                    pointStart: this.startDate,
                    pointIntervalUnit: 'day',
                    keys: ['x', 'low', 'high', 'custom']
                }
            },
            legend: {
                enabled: false
            },
            exporting: {
                enabled: false,
                buttons: {
                    contextButton: {}
                }
            },
            credits: { enabled: false },
            series: [
                {
                    type: 'arearange',
                    boostThreshold: 0,
                    unit: this.unitLabel,
                    threshMax: threshMax,
                    threshMin: threshMin,
                    data: this.ranges[0],
                    color: '#7cb5ec'
                },
                {
                    type: 'arearange',
                    boostThreshold: 0,
                    unit: this.unitLabel,
                    threshMax: threshMax,
                    threshMin: threshMin,
                    data: this.ranges[1],
                    color: '#7cb5ec'
                }
            ]
        };

        this.chart = Highcharts.chart('container', this.options);
        Highcharts.setOptions({
            lang: {
                resetZoom: 'Réinitialiser le zoom'
            },
            time: {
                useUTC: false
            }
        });
    }
}
