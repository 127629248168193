export class EnergySupplierContractFilter {
    public label: string;
    public exactLabel: string;
    public number: string;
    public exactNumber: string;
    public isActivated?: boolean;
    public fluidId?: number;
    public supplierContractHolder: string;
    public exactContractHolder: string;
    public supplierName: string;
    public exactSupplierName: string;
    public subscriptionDateMin?: Date;
    public startDateMin?: Date;
    public endDateMax?: Date;
    public energySupplierContractsIds: Array<number>;
    public fluidsAssociatedToContract: Array<number>;
    public meteringPointIds: Array<number>;
    public hasMeteringPoints: boolean;
    public hasLinkedFiles?: boolean;
    constructor(init: Partial<EnergySupplierContractFilter>) {
        Object.assign(this, init);
    }
}
