import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiVersion } from '@app/shared/constants/version.enum';
import { environment } from '@environments/environment';
import { PermissionService } from './permission.service';
import { AppService } from '../http/app.service';

import { AzureStorageVTZFolder } from '@features/shared/routing/init-import-route.enums';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FileService {
    private API_ENDPOINT = environment.API_ENDPOINT;
    constructor(
        private httpClient: HttpClient,
        private permissionService: PermissionService,
        private appService: AppService
    ) {}

    // Download file
    public downloadFile(blob: Blob, fileName: string): void {
        const objectUrl: string = URL.createObjectURL(blob);
        const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
        a.href = objectUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(objectUrl);
    }

    /**
     * return file by storageFolderName & fileName from Azure FileStorage
     * @param fileName
     * @param storageFolderName
     */

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    getFile(storageFolderName: string, fileName: string, sharedReference: string) {
        const headers = this.permissionService.getHeader();
        const queryParams = `?storageFolderName=${storageFolderName}&fileName=${fileName}&sharedReference=${sharedReference}`;
        const url = `${this.API_ENDPOINT}${ApiVersion.version2_3}/files` + queryParams;
        return this.httpClient.get(url, {
            headers,
            responseType: 'blob' as 'json',
            observe: 'response'
        });
    }

    getLogs(initImportEnum: AzureStorageVTZFolder): Observable<HttpResponse<Blob>> {
        const headers = this.permissionService.getHeader();
        const contractId = this.appService.getUserContext().contract.id;
        const url = `${this.API_ENDPOINT}${ApiVersion.version2_3}/files/logs/${initImportEnum}?contractId=${contractId}`;

        const result = this.httpClient.get(url, {
            headers,
            responseType: 'blob',
            observe: 'response'
        });

        return result;
    }
}
