import { Component, Input, OnInit } from '@angular/core';
import { RenewableEnergyEnum } from '@app/shared/constants/renewable-energy.enum';

@Component({
    selector: 'renewable-energy-icon',
    templateUrl: './renewable-energy-icon.component.html',
    styleUrls: ['./renewable-energy-icon.component.scss']
})
export class RenewableEnergyIconComponent implements OnInit {
    @Input() renewableEnergyId: number;
    @Input() classStyle: string;

    RenewableEnergyEnum = RenewableEnergyEnum;

    constructor() {}

    ngOnInit(): void {}
}
