import { createAction, props } from '@ngrx/store';
import { GeographicMenuFilterState } from './geographic-menu-module-state.interface';

export enum GeographicMenuActionTypes {
    RESET_STATE = '[GEO MENU Module State] Reset',
    SET_FILTER_STATE = '[GEO MENU State] Set'
}

export const resetGeoMenuModuleState = createAction(GeographicMenuActionTypes.RESET_STATE);

export const setGeoMenuFilterState = createAction(
    GeographicMenuActionTypes.SET_FILTER_STATE,
    props<{ newGeographicMenuFilterState: GeographicMenuFilterState }>()
);
