<div class="loader-wrapper position-relative w-100">
  <div class="overlay w-100 h-100 position-absolute" *ngIf="showLoader">
    <div class="spinner-wrapper d-flex justify-content-center align-items-center" *ngIf="!noLoader">
      <mat-spinner
        [diameter]="diameter"
        [ngClass]="{ 'margin-giant': size === 'giant' }"
      ></mat-spinner>
    </div>
  </div>
  <ng-content></ng-content>
</div>
