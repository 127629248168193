import { KeyValue } from '@angular/common';
import { enumGeographicLevelId } from './common.enum';

export enum DomainEnum {
    Undefined = 0,
    Geography = 1,
    Property = 2,
    PublicSpace = 3
}

export const DomainEnumRSX: Array<KeyValue<DomainEnum, string>> = [
    { key: DomainEnum.Property, value: 'common.domain.real-estate' },
    { key: DomainEnum.PublicSpace, value: 'common.domain.public' }
];

export const PerimeterDomains: Array<KeyValue<DomainEnum, enumGeographicLevelId[]>> = [
    {
        key: DomainEnum.Geography,
        value: [
            enumGeographicLevelId.nation,
            enumGeographicLevelId.region,
            enumGeographicLevelId.county,
            enumGeographicLevelId.town
        ]
    },
    {
        key: DomainEnum.Property,
        value: [
            enumGeographicLevelId.establishment,
            enumGeographicLevelId.building,
            enumGeographicLevelId.zone
        ]
    },
    {
        key: DomainEnum.PublicSpace,
        value: [
            enumGeographicLevelId.district,
            enumGeographicLevelId.street,
            enumGeographicLevelId.cabinet
        ]
    }
];
