import { Injectable } from '@angular/core';
import { IApp, ILayout } from '@core/models/settings/ISettings';

@Injectable()
export class SettingsService {
    public app: IApp;
    public layout: ILayout;

    constructor() {
        // App Settings
        // -----------------------------------
        this.app = {
            name: 'Tableau de bord',
            version: '',
            description: 'Vertuoz Tableau de bord',
            year: new Date().getFullYear()
        };

        this.layout = {
            /* ******************************* Main **************************** */
            brand: 'vertuoz', // is Brand 'ds', 'vertuoz' or 'blue'
            defaultTheme: 'primaryBlue', // graphic theme [blueAlt, whiteSnow, primaryBlue, bigStone, bigStoneLight, yellowSpark, gradient]
            viewAnimation: 'ng-fadeInUp',

            isLayoutFixed: true, // is Header / Footer / Sidebar with position fixed
            isLayoutBoxed: false, // Full Layout centered and width 65%
            isContentFull: false, // is Layout content width 100%

            /* ******************************* Header **************************** */

            // Btns
            isHeaderSearch: true, // is Header Button Search active
            isHeaderDataControl: false, // is header button notification active
            numberDataControl: 0, // is number of notification active
            isHeaderHub: true, // is Header button Platform active
            isSidebarBtn: true, // is header perimeter active
            isHeaderMailLink: true,
            // Component
            isLangSelector: false, // is language selector active
            isProfileMenu: true,
            isHeaderPerimeter: false,
            isSiteSelection: false, // is Site Selection active
            isHeaderNavAdd: true, // is header additional navigation
            isHeaderFreeZone: true, // is header free zone random content

            /* ******************************* Sidebar *************************** */

            isSidebarHidden: false, // is Sidebar hidden
            isSidebarCollapsed: false, // is Sidebar folded when App is loaded
            isSidebarCollapsedText: false, // is Sidebar folded (Icon with small text) and locked (No Toggle button in header)
            isSidebarToggled: true, // toggle sidebar from the header
            isSidebarFloat: false, // Sidebar Flottante
            isSidebarHover: false, // Forced Panel on hover links even if isSidebarCollapsed: true
            isSidebarScrollbar: false,
            isSidebarFreeZone: false, // is header free zone random content

            /* ******************************* Footer **************************** */

            isFooterHidden: false, // is Footer hidden
            isSiteVersion: false, // is Footer site version hidden
            isMentionsLink: false // is Footer legal mentions hidden
        };
    }

    getAppSetting(name: string): string | number {
        return name ? this.app[name] : this.app;
    }
    setAppSetting(name: string, value: string | number): void {
        if (typeof this.app[name] !== 'undefined') {
            this.app[name] = value;
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getLayoutSetting(name: string): any {
        return name ? this.layout[name] : this.layout;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setLayoutSetting(name: string, value: any): any {
        if (typeof this.layout[name] !== 'undefined') {
            return (this.layout[name] = value);
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toggleLayoutSetting(name: any): any {
        return this.setLayoutSetting(name, !this.getLayoutSetting(name));
    }
}
