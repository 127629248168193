<span
  class="nav-link selection bg-gray-50 font-14 rounded-pill d-block p-1 position-relative overflow-hidden mx-3"
>
  <span class="selection-inner bg-white rounded-pill d-block px-0">
    <mat-form-field class="position-absolute">
      <mat-select aria-placeholder="Périmètre" placeholder="FR">
        <mat-option [value]="'en'">EN</mat-option>
        <mat-option [value]="'fr'">FR</mat-option>
      </mat-select>
    </mat-form-field>
  </span>
</span>
