import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root'
})
export class IconService {
    icons = [
        'adduction',
        'boiler',
        'boolean',
        'chart_accrued',
        'chart_area',
        'chart_bar',
        'chart_bar_h',
        'chart_label',
        'chart_stacked',
        'co2',
        'compare',
        'consumption',
        'cov',
        'database',
        'data_exclude',
        'data_include',
        'dju',
        'double_arrow_left',
        'double_arrow_right',
        'edit_calendar',
        'electricity',
        'engie',
        'file_tree',
        'file_pdf',
        'file_spreadsheet',
        'csv_file',
        'doc_file',
        'gif_file',
        'jpg_file',
        'ods_file',
        'odt_file',
        'png_file',
        'ppt_file',
        'svg_file',
        'txt_file',
        'pdf_file',
        'xls_file',
        'fire',
        'folder_special',
        'folder_special_off',
        'fuel',
        'gas',
        'gas_2',
        'geothermal',
        'heat',
        'heater2',
        'help_buoy',
        'hydraulic',
        'info',
        'leaf',
        'light_cfl',
        'lightbulb',
        'lightbulb_incandescent',
        'metering_point',
        'metering_point2',
        'move_to_folder',
        'no2',
        'no_file_pdf',
        'outlet',
        'place_remote',
        'plug',
        'production',
        'radon',
        'ratio',
        'sms_text',
        'snowflake',
        'solar_elec',
        'solar_therm',
        'sort_ascending',
        'sort_descending',
        'speed',
        'square_ruler_x',
        'square_ruler_y',
        'tap',
        'thermometer',
        'thermostat',
        'tools',
        'water',
        'water_recycle',
        'wind_turbine',
        'wind_turbine2',
        'invoice',
        'nature',
        'swap_calls',
        'building',
        'building_disabled',
        'metering_point',
        'metering_point_disabled',
        'equipment',
        'equipment_disabled',
        'process_equipment',
        'process_equipment_disabled',
        'coming_soon'
    ];

    constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {}

    registerIcons(): void {
        this.icons.forEach(icon => {
            this.matIconRegistry.addSvgIcon(
                icon,
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    `../../assets/svg/icons/${icon}.svg`
                )
            );
        });
    }

    contains(alias: string): boolean {
        return this.icons.includes(alias);
    }
}
