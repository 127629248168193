import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Label } from '@app/core/models/common/label.model';
import { LabelModel } from '@app/features/property-data/models/comparison.model';

export class ModalMessage {
    public title: string;
    public introduction: string;
    public message: string;
    public routerPath: string;
    public labels: Label[];
    public dictionary?: { [label: string]: Array<LabelModel> };
    public dictionaryParentTypeName?: string;
    public dictionaryChildTypeName?: string;
    public conclude?: string;
    public sign?: string;
    constructor(init: Partial<ModalMessage>) {
        Object.assign(this, init);
    }
}

@Component({
    selector: 'modal-dialog',
    templateUrl: './modal-dialog.component.html',
    styleUrls: ['./modal-dialog.component.scss']
})
export class ModalDialogComponent {
    title: string;
    introduction: string;
    message: string;
    labels: Label[];
    routerPath: string;
    dictionary?: { [label: string]: Array<LabelModel> };
    dictionaryParentTypeName?: string;
    dictionaryChildTypeName?: string;
    conclude?: string;
    sign?: string;

    constructor(
        private dialogRef: MatDialogRef<ModalDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: ModalMessage
    ) {
        this.title = data.title;
        this.introduction = data.introduction;
        this.message = data.message;
        this.labels = data.labels;
        this.routerPath = data.routerPath;
        this.dictionary = data.dictionary;
        this.dictionaryParentTypeName = data.dictionaryParentTypeName;
        this.dictionaryChildTypeName = data.dictionaryChildTypeName;
        this.conclude = data.conclude;
        this.sign = data.sign;
    }

    close(): void {
        this.dialogRef.close();
    }
}
