export enum HttpEnum {
    // 2xx : Success
    OK = 200,
    Created = 201,
    NoContent = 204,

    // 4xx : Client HTTP Error
    BadRequest = 400,
    Unauthorized = 401,
    Forbidden = 403,
    NotFound = 404,
    Conflict = 409,
    TooManyRequest = 429,

    // 5xx : Server Error
    InternalServerError = 500
}

export enum ExceptionsCode {
    UserCreate_MailNotSend = 20601,

    // BadRequest = 400, Les inputs ne permettent d'effectuer l'action sur la ressource
    UserIdRequired = 40001,
    AzureUserIdRequired = 40002,
    BadFormatAzureUserId = 40003,
    ContractIdRequired = 40004,
    ClientIdRequired = 40005,
    ClientModelRequired = 40006,
    OrganisationModelRequired = 40007,
    RoleIdRequired = 40008,
    UserNotAllowedOperation = 40009,
    ParentIdRequired = 40010,
    BadPropertyLevel = 40011,
    BadPerimeterLevel = 40012,
    PerimeterLevelRequired = 40013,
    PerimeterItemIdRequired = 40014,
    ObjectiveCategoryRequired = 40015,
    ObjectiveFluidUseRequired = 40016,
    BadFileType = 40017,
    FileNameRequired = 40018,
    MappingColumnBusinessFieldRequired = 40019,
    BadElementType = 40020,
    NotNullObjectRequired = 40021,
    UserLoginRequired = 40022,

    // Forbidden = 403, Une regle métier interdit l'accès à la ressource
    Forbbiden = 40300,
    UserForbbidenAccessRole = 40301,
    ClientForbiddenAccessRole = 40301,
    UserForbbidenAccessPermission = 40302,
    ContractForbbidenAccessRole = 40303,
    OrganisationForbiddenDeleteContractEnable = 40304,
    UserForbbidenAccesGeographicalRight = 40305,
    ScenarioForbiddenAccess = 40306,
    ClientDetailLevelForbiddenRole = 40307,
    MeterViewIndexForbiddenAccess = 40310,
    UserForbbidenAccessDomain = 40311,
    ConsumptionByPeriodForbiddenAccess = 40312,
    ConsumptionByPropertyForbiddenAccess = 40313,
    MeterConsumptionForbiddenAccess = 40314,
    ObjectivesValuesForbiddenAccess = 40315,
    UserForbbidenAccessPerimeter = 40316,
    UserForbiddenAccessGroupPermission = 40317,
    UserForbiddenCreateGroupPermission = 40318,
    UserForbiddenDeleteGroupPermission = 40319,

    // NotFound = 404, La ressource n'a pas été trouvée
    NotFound = 40400,
    UserNotFound = 40401,
    ContractNotFound = 40402,
    ClientNotFound = 40404,
    ModuleNotFound = 40405,
    OrganisationNotFound = 40406,
    RoleNotFound = 40407,
    PermissionNotFound = 40408,
    UserRoleNotFound = 40409,
    OfferNotfound = 40410,
    BuildingNotFound = 40411,
    MeteringPointNotFound = 40412,
    CountyNotFound = 40413,
    ScenarioNotFound = 40414,
    AlertNotFound = 40414,
    EstablishmentNotFound = 40415,
    NationNotFound = 40416,
    RegionNotFound = 40417,
    TownNotFound = 40418,
    groupNotFound = 40419,
    FilterNotFound = 40420,
    SortFieldNotFound = 40421,
    FluidNotFound = 40422,
    CustomSectorNotFound = 40423,
    ActivePerimeterNotFound = 40424,
    MeterNotFound = 40425,
    InvoiceLogNotFound = 40426,
    FileNotFound = 40427,
    InvoiceNotFound = 40428,
    ReportItemsNotFound = 40429,
    FileToImportNotFound = 40430,
    EquipmentNotFound = 40431,
    EnergySupplierContractNotFound = 40432,

    // Conflict = 409, une contrainte métier (ou intégration) qui empêche l'action sur la ressource
    UserConflict = 40901,
    ClientConflictNameOrSiren = 40902,
    GroupWithLowerLevelConflict = 40903,
    UserPerimeterConflict = 40904,
    RoleCreationConfilct = 40906,
    ItemAlreadyExistsConflict = 40908,
    ActiveMeterExistsConflict = 40909,
    SupplierIdExists = 40910,
    // PowerRestrictedToElec = 40911, not use
    MeteringPlanConflict = 40912,
    RoleUsersDependencies = 40913,
    MeteringPointHasFatherConflict = 40914,
    ObjectiveConflict = 40915,
    EquipmentConflict = 40916,
    MeteringPointHasEquipmentConflict = 40917,
    TownHasEquipmentConflict = 40918,
    EstablishmentHasEquipmentConflict = 40919,
    BuildingHasEquipmentConflict = 40920,
    ZoneHasEquipmentConflict = 40921,
    DialConflict = 40922,
    UnitConflict = 40923,
    ItemHasDuplicates = 40924,
    TechnicalIdIsDefinedConflict = 40930,
    TechnicalIdIsNotDefinedConflict = 40931,
    DataControlRuleConflict = 40940,
    FileTooLargeConflict = 40950,

    // 413 La taille de l'entité fournie par la requête est supérieure aux limites définies.
    MessageBatchToLarge = 41301
}
