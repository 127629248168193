/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import {
    DataControlPeriodicity,
    Operators
} from '@app/core/models/data-control/enums/data-control.enum';
import { DataControlConditionPeriodicityModel } from '@app/core/models/data-control/models/data-control-rule.model';
@Injectable({
    providedIn: 'root'
})
export class ThresholdGraphService {
    public generateRangesThresholdGraph(
        startDate: Date,
        endDate: Date,
        conditions: DataControlConditionPeriodicityModel[],
        thresholdMax?: number,
        thresholedMin?: number,
        isMinutes: boolean = false
    ): Array<Array<Array<number>>> {
        let allRange: number[][] = [];
        if (isMinutes) {
            allRange = this.getAllMinutesWithStartAndEndDate(startDate, endDate);
        } else {
            allRange = this.getAllDaysWithStartAndEndDate(startDate, endDate);
        }

        let secondeAllRange: number[][] = [];
        const haveSecondRange = conditions.some(
            e =>
                !e.operatorIs &&
                (e.operator === Operators.Between || e.operator === Operators.Equal)
        );
        if (haveSecondRange) {
            if (isMinutes) {
                secondeAllRange = this.getAllMinutesWithStartAndEndDate(startDate, endDate);
            } else {
                secondeAllRange = this.getAllDaysWithStartAndEndDate(startDate, endDate);
            }
        }

        conditions = conditions.sort((a, b) => -(a.priority - b.priority));

        conditions.forEach(condition => {
            if (
                !condition.operatorIs &&
                (condition.operator === Operators.Between || condition.operator === Operators.Equal)
            ) {
                this.buildRange(
                    condition.isDisabled,
                    allRange,
                    condition,
                    thresholdMax,
                    thresholedMin,
                    true
                );
                this.buildRange(
                    condition.isDisabled,
                    secondeAllRange,
                    condition,
                    thresholdMax,
                    thresholedMin,
                    false
                );
            } else {
                this.buildRange(
                    condition.isDisabled,
                    allRange,
                    condition,
                    thresholdMax,
                    thresholedMin,
                    false
                );
                if (haveSecondRange) {
                    this.buildRange(
                        condition.isDisabled,
                        secondeAllRange,
                        condition,
                        thresholdMax,
                        thresholedMin,
                        false
                    );
                }
            }
        });

        allRange.forEach(item => {
            if (item[1] === undefined || item[2] === undefined) {
                item[1] = null;
                item[2] = null;
            }
        });

        secondeAllRange.forEach(item => {
            if (item[1] === undefined || item[2] === undefined) {
                item[1] = null;
                item[2] = null;
            }
        });
        return [allRange, secondeAllRange];
    }

    public getAllMinutesWithStartAndEndDate(startDate: Date, endDate: Date): Array<Array<number>> {
        const dates = [];
        let currentDate = startDate;

        const addDays = function(days: any): Date {
            const date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
        };

        while (currentDate <= endDate) {
            for (let i = 0; i < 24; i++) {
                for (let e = 0; e < 60; e++) {
                    const newDate = new Date(
                        currentDate.getFullYear(),
                        currentDate.getMonth(),
                        currentDate.getDate(),
                        i,
                        e
                    );
                    dates.push([newDate.getTime(), undefined, undefined]);
                }
            }
            currentDate = addDays.call(currentDate, 1);
        }

        return dates;
    }

    public getAllDaysWithStartAndEndDate(startDate: Date, endDate: Date): Array<Array<number>> {
        const dates = [];
        let currentDate = startDate;

        const addDays = function(days: any): Date {
            const date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
        };

        while (currentDate <= endDate) {
            for (let i = 0; i < 24; i++) {
                const newDate = new Date(
                    currentDate.getFullYear(),
                    currentDate.getMonth(),
                    currentDate.getDate(),
                    i
                );
                dates.push([newDate.getTime(), undefined, undefined]);
            }
            currentDate = addDays.call(currentDate, 1);
        }

        return dates;
    }

    private buildRange(
        isDisabled: boolean,
        allRange: number[][],
        condition: DataControlConditionPeriodicityModel,
        thresholdMax: number,
        thresholedMin: number,
        isFirstArray: boolean
    ): number[][] {
        switch (condition.periodicity) {
            case DataControlPeriodicity.Weekly:
                allRange.forEach(item => {
                    const dateItemHour = new Date(item[0]).getHours();

                    let isValid;
                    if (condition.timeSlotStart < condition.timeSlotEnd) {
                        isValid =
                            condition.timeSlotStart <= dateItemHour &&
                            dateItemHour < condition.timeSlotEnd;
                    } else {
                        isValid =
                            condition.timeSlotStart <= dateItemHour ||
                            dateItemHour < condition.timeSlotEnd;
                    }

                    if (condition.monday) {
                        if (this.isMonday(item[0]) && isValid) {
                            this.bindThresholdValues(
                                item,
                                condition,
                                isDisabled,
                                thresholdMax,
                                thresholedMin,
                                isFirstArray
                            );
                        }
                    }

                    if (condition.tuesday) {
                        if (this.isTuesday(item[0]) && isValid) {
                            this.bindThresholdValues(
                                item,
                                condition,
                                isDisabled,
                                thresholdMax,
                                thresholedMin,
                                isFirstArray
                            );
                        }
                    }
                    if (condition.wednesday) {
                        if (this.isWednesday(item[0]) && isValid) {
                            this.bindThresholdValues(
                                item,
                                condition,
                                isDisabled,
                                thresholdMax,
                                thresholedMin,
                                isFirstArray
                            );
                        }
                    }
                    if (condition.thursday) {
                        if (this.isThursday(item[0]) && isValid) {
                            this.bindThresholdValues(
                                item,
                                condition,
                                isDisabled,
                                thresholdMax,
                                thresholedMin,
                                isFirstArray
                            );
                        }
                    }
                    if (condition.friday) {
                        if (this.isFriday(item[0]) && isValid) {
                            this.bindThresholdValues(
                                item,
                                condition,
                                isDisabled,
                                thresholdMax,
                                thresholedMin,
                                isFirstArray
                            );
                        }
                    }
                    if (condition.saturday) {
                        if (this.isSaturday(item[0]) && isValid) {
                            this.bindThresholdValues(
                                item,
                                condition,
                                isDisabled,
                                thresholdMax,
                                thresholedMin,
                                isFirstArray
                            );
                        }
                    }
                    if (condition.sunday) {
                        if (this.isSunday(item[0]) && isValid) {
                            this.bindThresholdValues(
                                item,
                                condition,
                                isDisabled,
                                thresholdMax,
                                thresholedMin,
                                isFirstArray
                            );
                        }
                    }
                });

                break;
            case DataControlPeriodicity.Monthly:
            case DataControlPeriodicity.HeatingSeason:
                allRange.forEach(item => {
                    if (condition.january) {
                        if (this.isJanuary(item[0])) {
                            this.bindThresholdValues(
                                item,
                                condition,
                                isDisabled,
                                thresholdMax,
                                thresholedMin,
                                isFirstArray
                            );
                        }
                    }
                    if (condition.february) {
                        if (this.isFebruary(item[0])) {
                            this.bindThresholdValues(
                                item,
                                condition,
                                isDisabled,
                                thresholdMax,
                                thresholedMin,
                                isFirstArray
                            );
                        }
                    }
                    if (condition.march) {
                        if (this.isMarch(item[0])) {
                            this.bindThresholdValues(
                                item,
                                condition,
                                isDisabled,
                                thresholdMax,
                                thresholedMin,
                                isFirstArray
                            );
                        }
                    }
                    if (condition.april) {
                        if (this.isApril(item[0])) {
                            this.bindThresholdValues(
                                item,
                                condition,
                                isDisabled,
                                thresholdMax,
                                thresholedMin,
                                isFirstArray
                            );
                        }
                    }
                    if (condition.may) {
                        if (this.isMay(item[0])) {
                            this.bindThresholdValues(
                                item,
                                condition,
                                isDisabled,
                                thresholdMax,
                                thresholedMin,
                                isFirstArray
                            );
                        }
                    }
                    if (condition.june) {
                        if (this.isJune(item[0])) {
                            this.bindThresholdValues(
                                item,
                                condition,
                                isDisabled,
                                thresholdMax,
                                thresholedMin,
                                isFirstArray
                            );
                        }
                    }
                    if (condition.july) {
                        if (this.isJuly(item[0])) {
                            this.bindThresholdValues(
                                item,
                                condition,
                                isDisabled,
                                thresholdMax,
                                thresholedMin,
                                isFirstArray
                            );
                        }
                    }
                    if (condition.august) {
                        if (this.isAugust(item[0])) {
                            this.bindThresholdValues(
                                item,
                                condition,
                                isDisabled,
                                thresholdMax,
                                thresholedMin,
                                isFirstArray
                            );
                        }
                    }
                    if (condition.september) {
                        if (this.isSeptember(item[0])) {
                            this.bindThresholdValues(
                                item,
                                condition,
                                isDisabled,
                                thresholdMax,
                                thresholedMin,
                                isFirstArray
                            );
                        }
                    }
                    if (condition.october) {
                        if (this.isOctober(item[0])) {
                            this.bindThresholdValues(
                                item,
                                condition,
                                isDisabled,
                                thresholdMax,
                                thresholedMin,
                                isFirstArray
                            );
                        }
                    }
                    if (condition.november) {
                        if (this.isNovember(item[0])) {
                            this.bindThresholdValues(
                                item,
                                condition,
                                isDisabled,
                                thresholdMax,
                                thresholedMin,
                                isFirstArray
                            );
                        }
                    }
                    if (condition.december) {
                        if (this.isDecember(item[0])) {
                            this.bindThresholdValues(
                                item,
                                condition,
                                isDisabled,
                                thresholdMax,
                                thresholedMin,
                                isFirstArray
                            );
                        }
                    }
                });
                break;
            case DataControlPeriodicity.Calendar:
                allRange.forEach(item => {
                    const dateItem = new Date(item[0]);
                    if (condition.startDate <= dateItem && condition.endDate >= dateItem) {
                        this.bindThresholdValues(
                            item,
                            condition,
                            isDisabled,
                            thresholdMax,
                            thresholedMin,
                            isFirstArray
                        );
                    }
                });

                break;
            case DataControlPeriodicity.Permanent:
                allRange.forEach(item => {
                    this.bindThresholdValues(
                        item,
                        condition,
                        isDisabled,
                        thresholdMax,
                        thresholedMin,
                        isFirstArray
                    );
                });
                break;
            default:
                break;
        }

        return allRange;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private bindThresholdValues(
        item: any[],
        condition: DataControlConditionPeriodicityModel,
        isDisabled: boolean,
        thresholdMax: number,
        thresholedMin: number,
        isFirstArray: boolean
    ): void {
        if (isDisabled) {
            item[1] = null;
            item[2] = null;
            item[3] = null;
        } else {
            switch (condition.operator) {
                case Operators.Superior:
                    if (condition.operatorIs) {
                        item[1] = condition.thresholdValue;
                        item[2] = thresholdMax;
                        item[3] = {
                            operator: condition.operator,
                            operatorIs: condition.operatorIs
                        };
                    } else {
                        item[1] = condition.thresholdValue;
                        item[2] = thresholedMin;
                        item[3] = {
                            operator: condition.operator,
                            operatorIs: condition.operatorIs
                        };
                    }

                    break;
                case Operators.Lower:
                    if (condition.operatorIs) {
                        item[1] = condition.thresholdValue;
                        item[2] = thresholedMin;
                        item[3] = {
                            operator: condition.operator,
                            operatorIs: condition.operatorIs
                        };
                    } else {
                        item[1] = condition.thresholdValue;
                        item[2] = thresholdMax;
                        item[3] = {
                            operator: condition.operator,
                            operatorIs: condition.operatorIs
                        };
                    }
                    break;
                case Operators.Between:
                    if (condition.operatorIs) {
                        item[1] = condition.thresholdValue;
                        item[2] = condition.secondThresholdValue;
                        item[3] = {
                            operator: condition.operator,
                            operatorIs: condition.operatorIs
                        };
                    } else if (!condition.operatorIs && isFirstArray) {
                        item[1] =
                            condition.thresholdValue < condition.secondThresholdValue
                                ? condition.thresholdValue
                                : condition.secondThresholdValue;
                        item[2] = thresholedMin;
                        item[3] = {
                            operator: condition.operator,
                            operatorIs: condition.operatorIs
                        };
                    } else {
                        item[1] =
                            condition.thresholdValue > condition.secondThresholdValue
                                ? condition.thresholdValue
                                : condition.secondThresholdValue;
                        item[2] = thresholdMax;
                        item[3] = {
                            operator: condition.operator,
                            operatorIs: condition.operatorIs
                        };
                    }
                    break;
                case Operators.Equal:
                    if (condition.operatorIs) {
                        item[1] = condition.thresholdValue;
                        item[2] = condition.thresholdValue;
                        item[3] = {
                            operator: condition.operator,
                            operatorIs: condition.operatorIs
                        };
                    } else if (!condition.operatorIs && isFirstArray) {
                        item[1] = condition.thresholdValue;
                        item[2] = thresholdMax;
                        item[3] = {
                            operator: condition.operator,
                            operatorIs: condition.operatorIs
                        };
                    } else {
                        item[1] = condition.thresholdValue;
                        item[2] = thresholedMin;
                        item[3] = {
                            operator: condition.operator,
                            operatorIs: condition.operatorIs
                        };
                    }
                    break;
                default:
                    break;
            }
        }
    }

    //#region test day
    private isMonday(date: number): boolean {
        return new Date(date).getDay() === 1;
    }
    private isTuesday(date: number): boolean {
        return new Date(date).getDay() === 2;
    }
    private isWednesday(date: number): boolean {
        return new Date(date).getDay() === 3;
    }
    private isThursday(date: number): boolean {
        return new Date(date).getDay() === 4;
    }
    private isFriday(date: number): boolean {
        return new Date(date).getDay() === 5;
    }
    private isSaturday(date: number): boolean {
        return new Date(date).getDay() === 6;
    }
    private isSunday(date: number): boolean {
        return new Date(date).getDay() === 0;
    }
    //#endregion

    //#region test month
    private isJanuary(date: number): boolean {
        return new Date(date).getMonth() === 0;
    }
    private isFebruary(date: number): boolean {
        return new Date(date).getMonth() === 1;
    }
    private isMarch(date: number): boolean {
        return new Date(date).getMonth() === 2;
    }
    private isApril(date: number): boolean {
        return new Date(date).getMonth() === 3;
    }
    private isMay(date: number): boolean {
        return new Date(date).getMonth() === 4;
    }
    private isJune(date: number): boolean {
        return new Date(date).getMonth() === 5;
    }
    private isJuly(date: number): boolean {
        return new Date(date).getMonth() === 6;
    }
    private isAugust(date: number): boolean {
        return new Date(date).getMonth() === 7;
    }
    private isSeptember(date: number): boolean {
        return new Date(date).getMonth() === 8;
    }
    private isOctober(date: number): boolean {
        return new Date(date).getMonth() === 9;
    }
    private isNovember(date: number): boolean {
        return new Date(date).getMonth() === 10;
    }
    private isDecember(date: number): boolean {
        return new Date(date).getMonth() === 11;
    }
    //#endregion
}
