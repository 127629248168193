export interface IDictionary<TValue> {
    Keys: number[];
    Values: TValue[];
    Items: { [index: number]: TValue };
    Add(key: number, value: TValue): void;
    ContainsKey(key: number): boolean;
    Count(): number;
    Value(key: number): TValue;
    Remove(key: number): TValue;
}

export class Dictionary<TValue> implements IDictionary<TValue> {
    private items: { [index: number]: TValue } = {};

    private count = 0;

    public constructor(dictionary: { [index: number]: TValue } = null) {
        if (dictionary != null) {
            this.items = dictionary;
            this.count = this.Keys.length;
        }
    }
    public ContainsKey(key: number): boolean {
        return this.items.hasOwnProperty(key);
    }

    public Count(): number {
        return this.count;
    }

    public Add(key: number, value: TValue): void {
        if (!this.items.hasOwnProperty(key)) {
            this.count++;
        }

        this.items[key] = value;
    }

    public Remove(key: number): TValue {
        const val = this.items[key];
        delete this.items[key];
        this.count--;
        return val;
    }

    public Value(key: number): TValue {
        return this.items[key];
    }

    public get Keys(): number[] {
        const keySet: number[] = [];

        for (const prop in this.items) {
            if (this.items.hasOwnProperty(prop)) {
                keySet.push(<number>(<unknown>prop));
            }
        }

        return keySet;
    }

    public get Values(): TValue[] {
        const values: TValue[] = [];

        for (const prop in this.items) {
            if (this.items.hasOwnProperty(prop)) {
                values.push(this.items[prop]);
            }
        }

        return values;
    }

    public get Items(): { [index: number]: TValue } {
        return this.items;
    }
}
