import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoadingIndicatorService } from '../services/loading-indicator.service';

@Injectable()
export class LoadingIndicatorInterceptor implements HttpInterceptor {
    constructor(private loadingIndicatorService: LoadingIndicatorService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.onBegin(req);
        return next.handle(req).pipe(
            finalize(() => {
                this.onEnd(req);
            })
        );
    }

    private onEnd(req: HttpRequest<any>): void {
        this.loadingIndicatorService.end(req);
    }
    private onBegin(req: HttpRequest<any>): void {
        this.loadingIndicatorService.begin(req);
    }
}
