// angular
import { Overlay, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';

@Component({
    selector: 'anchor-dialog',
    templateUrl: './anchor-dialog.component.html',
    styleUrls: ['./anchor-dialog.component.scss']
})
export class AnchorDialogComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() anchorElement: ElementRef | HTMLElement;
    @Input() width: number | string;
    @Input() height: number | string;
    // a padding input value for the dialog
    // default 0 / no padding
    @Input() padding = 0;
    @Input() overlayX: 'center' | 'start' | 'end' = 'center';
    @Input() overlayY: 'center' | 'bottom' | 'top' = 'bottom';
    @Input() originX: 'center' | 'start' | 'end' = 'center';
    @Input() originY: 'center' | 'bottom' | 'top' = 'top';

    @Input() customClass = '';

    @Input() closeOnScroll = true;
    @Input() useOverlay = false;

    @ViewChild('dialogPortal') dialogComponent;

    private overlayRef: OverlayRef;

    constructor(private overlay: Overlay) {}

    ngOnInit(): void {
        if (this.overlayRef) {
            this.ngAfterViewInit();
        }
    }

    ngOnDestroy(): void {
        this.overlayRef.detach();
        this.overlayRef.dispose();
    }

    ngAfterViewInit(): void {
        this.overlayRef = this.overlay.create({
            panelClass: this.customClass,
            positionStrategy: this.getPositionStrategy(),
            width: this.width,
            height: this.height,
            scrollStrategy:
                this.closeOnScroll === true
                    ? this.overlay.scrollStrategies.close()
                    : this.overlay.scrollStrategies.noop()
        });

        this.overlayRef.attach(this.dialogComponent);
    }

    /**
     * return position strategy to create the overlay
     */
    private getPositionStrategy(): PositionStrategy {
        let element = (<ElementRef>this.anchorElement).nativeElement;
        if (!element) {
            element = this.anchorElement;
        }
        if (this.useOverlay) {
            return this.overlay
                .position()
                .flexibleConnectedTo(element)
                .withPositions([
                    {
                        overlayX: this.overlayX,
                        overlayY: this.overlayY,
                        originX: this.originX,
                        originY: this.originY
                    }
                ]);
        } else {
            return this.overlay
                .position()
                .global()
                .top(element.getBoundingClientRect().top + 'px')
                .left(element.getBoundingClientRect().left - 120 + 'px');
        }
    }
}
