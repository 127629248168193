import { state, style, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';

import { SettingsService } from '../../core/services/settings.service';

@Component({
    selector: 'app-sitemap',
    templateUrl: './sitemap.component.html',
    styleUrls: ['./sitemap.component.scss'],
    animations: [
        trigger('subMap', [
            state(
                'opened',
                style({
                    height: 'calc(100vh - 74px)'
                })
            )
        ]),
        trigger('subArrow', [
            state(
                'opened',
                style({
                    transform: 'rotate(180deg)'
                })
            )
        ])
    ]
})
export class SiteMapComponent implements OnInit {
    stateSubMap = 'closed';

    constructor(public settings: SettingsService) {}

    ngOnInit() {}

    toggleCollapsedSubMap() {
        this.stateSubMap = this.stateSubMap === 'opened' ? 'closed' : 'opened';
    }
    toggleCollapsedSideabar() {
        this.settings.layout.isCollapsed = !this.settings.layout.isCollapsed;
    }
}
