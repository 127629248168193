import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { LoadingIndicatorRequest } from '../models/loadingIndicator/loading-indicator-request.model';
import { LoadingIndicatorState } from '../models/loadingIndicator/loading-indicator-state.model';
import { EnumController } from './end-point-extend';

@Injectable({
    providedIn: 'root'
})
export class LoadingIndicatorService {
    /** indique le nombre de requete en cours de chargement*/
    private bs_loading = new BehaviorSubject<LoadingIndicatorState>(<LoadingIndicatorState>{
        loading: false,
        loadingApi: false,
        loadingIndicatorRequests: []
    });

    /** indique si une ou plusieurs requetes sont en cours de chargement */
    public isLoading = this.bs_loading.asObservable().pipe(debounceTime(300));

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private requests: Array<HttpRequest<any>> = [];
    private loadingIndicatorRequests: Array<LoadingIndicatorRequest> = [];

    constructor() {}

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private remove(req: HttpRequest<any>): void {
        const i = this.requests.indexOf(req);
        if (i >= 0) {
            this.requests.splice(i, 1);
            this.loadingIndicatorRequests.splice(i, 1);
        }
    }

    /**
     * Vérifie que l'état contient une url contenant le/les 'controllers' ET ne contenant pas une url commençant par le/les 'excludeUrlsStart'
     * @param state Etat
     * @param controllers Liste des controllers à vérifier
     * @param excludeUrlsStart Liste des urls à exclure de la vérification
     */
    public checkStateByControllers(
        state: LoadingIndicatorState,
        controllers: Array<EnumController>,
        excludeUrlsStart: Array<string> = null,
        excludeUrlContainParams: Array<string> = null,
        includeMethod: Array<string> = null
    ): boolean {
        let t = state.loadingIndicatorRequests.filter(r =>
            controllers.find(c => r.controllerApi === c)
        );

        if (includeMethod) {
            t = t.filter(r => includeMethod.find(m => r.method === m));
        }

        if (excludeUrlsStart) {
            const t2 = t;
            // tslint:disable-next-line:typedef
            t2.forEach(function(urlGeree) {
                let exclude = false;
                // tslint:disable-next-line:typedef
                excludeUrlsStart.forEach(function(excludeURL) {
                    if (urlGeree.url.indexOf(excludeURL) >= 0) {
                        exclude = true;
                    }
                });
                if (exclude) {
                    t = t.filter(i => i.url !== urlGeree.url);
                }
            });
        }

        if (excludeUrlContainParams) {
            t = t.filter(r => {
                const paramIndex = r.url.indexOf('?');
                const paramsString = r.url.substr(paramIndex);
                return !excludeUrlContainParams.find(u => paramsString.includes(u));
            });
        }

        return t.length > 0;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private add(req: HttpRequest<any>): void {
        this.requests.push(req);
        this.loadingIndicatorRequests.push(this.createLoadingIndicatorRequest(req));
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public begin(req: HttpRequest<any>): void {
        this.add(req);
        this.bs_loading.next(<LoadingIndicatorState>{
            loading: true,
            loadingApi:
                this.loadingIndicatorRequests.filter(r => {
                    return r.isApi;
                }).length > 0,
            loadingIndicatorRequests: this.loadingIndicatorRequests
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public end(req: HttpRequest<any>): void {
        this.remove(req);
        this.bs_loading.next(<LoadingIndicatorState>{
            loading: this.loadingIndicatorRequests.length > 0,
            loadingApi:
                this.loadingIndicatorRequests.filter(r => {
                    return r.isApi;
                }).length > 0,
            loadingIndicatorRequests: this.loadingIndicatorRequests
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private createLoadingIndicatorRequest(req: HttpRequest<any>): LoadingIndicatorRequest {
        const _isApi: boolean = req.url.startsWith(environment.API_ENDPOINT);
        let _controller: EnumController;

        if (_isApi) {
            let endOfUrl: string = req.url.substring(environment.API_ENDPOINT.length);
            // Todo gérer les versions autrement plus tard
            if (endOfUrl.includes('v2.3/')) {
                endOfUrl = endOfUrl.substring('v2.3/'.length);
            }
            const indexOfParams: number = endOfUrl.indexOf('?');
            if (indexOfParams && indexOfParams !== -1) {
                endOfUrl = endOfUrl.substring(0, indexOfParams);
            }
            const tab: Array<string> = endOfUrl.split('/');
            for (const strController of Object.keys(EnumController)) {
                if (strController === tab[0]) {
                    _controller = <EnumController>tab[0];
                }
            }
        }
        const loadingIndicatorRequest = <LoadingIndicatorRequest>{
            method: req.method,
            url: req.urlWithParams,
            isApi: _isApi,
            controllerApi: _controller
        };

        return loadingIndicatorRequest;
    }
}
