import { PerimeterScope } from '@app/shared/constants/filters.enum';

export class LayoutModuleState {
    perimeterScopeState: PerimeterScope;
    constructor(init?: Partial<LayoutModuleState>) {
        if (init) {
            Object.assign(this, init);
        }
    }
}
