import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Label } from '@app/core/models/common/label.model';
import { Subject } from 'rxjs';
import { pairwise, startWith, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'select-text-field',
    templateUrl: './select-text-field.component.html',
    styleUrls: ['./select-text-field.component.scss']
})
export class SelectTextFieldComponent implements OnInit, OnChanges {
    @Input() placeHolder: string;
    @Input() items: Label[];
    @Input() selectedItem: { itemId: number; label: string };
    @Output() valueChanged: EventEmitter<{ itemId: number; label: string }> = new EventEmitter<{
        itemId: number;
        label: string;
    }>();

    selectTextFieldForm: FormGroup;

    private _onDestroy = new Subject<void>();

    constructor(private formBuilder: FormBuilder) {}

    ngOnInit(): void {
        this.initForm();

        this.subscribeValueChanges();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes) {
            // When the list is updated and the current selected item is not in the list, clear the selection
            // When the list is empty or null, clear the selection
            if (changes.items && !changes.items.firstChange) {
                const selectionControlForm = this.selectTextFieldForm.get('selectionControl');
                if (changes.items.currentValue) {
                    const currentSelectedId = selectionControlForm.value;

                    if (
                        currentSelectedId &&
                        !changes.items.currentValue.find(v => v.id === currentSelectedId)
                    ) {
                        selectionControlForm.patchValue(null);
                    }
                } else {
                    selectionControlForm.patchValue(null);
                }
            }

            if (changes.selectedItem && changes.selectedItem.currentValue) {
                const selectionControlForm = this.selectTextFieldForm.get('selectionControl');
                selectionControlForm.patchValue(changes.selectedItem.currentValue.itemId);

                const textControlForm = this.selectTextFieldForm.get('textControl');
                textControlForm.patchValue(changes.selectedItem.currentValue.label);
            }
        }
    }

    public emitNewValue(itemId: number, label: string): void {
        this.valueChanged.emit({ itemId: itemId, label: label });
    }

    private initForm(): void {
        this.selectTextFieldForm = this.formBuilder.group({
            selectionControl: [null],
            textControl: [{ value: null, disabled: true }]
        });
    }

    private subscribeValueChanges(): void {
        const selectionControlForm = this.selectTextFieldForm.get('selectionControl');
        const textControlForm = this.selectTextFieldForm.get('textControl');

        // When the Null item is selected, clear the value of the text
        selectionControlForm.valueChanges
            .pipe(startWith(selectionControlForm.value), pairwise(), takeUntil(this._onDestroy))
            .subscribe(([oldValue, newValue]) => {
                if (newValue && oldValue == null) {
                    textControlForm.enable();
                    textControlForm.setValidators([Validators.required]);
                } else if (newValue == null) {
                    textControlForm.disable();
                    textControlForm.patchValue(null);
                    textControlForm.clearValidators();
                }
            });

        // When the text is modified, the value is emitted
        textControlForm.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(res => {
            this.emitNewValue(selectionControlForm.value, res);
        });
    }
}
