//#region Imports
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
//#endregion

@Injectable({
    providedIn: 'root'
})
export class WidgetContainerService {
    //#region Fields
    height: Observable<number>;
    private _height: BehaviorSubject<number>;

    private headerHeight: number;
    private fixedRowheight: number;
    private marginHeight: number;
    private offsetHeight: number;
    //#endregion

    //#region Constructor
    constructor() {
        this._height = new BehaviorSubject(0);
        this.height = this._height.asObservable();
    }
    //#endregion

    initGridConfig(
        headerHeight: number,
        rowHeight: number,
        marginHeight: number,
        offset: number = 0
    ): void {
        this.headerHeight = headerHeight;
        this.fixedRowheight = rowHeight;
        this.marginHeight = marginHeight;
        this.offsetHeight = offset;
    }

    //#region Methods
    setHeightNbRows(nbRows: number): void {
        if (nbRows > 0) {
            const height =
                this.fixedRowheight * nbRows +
                nbRows * this.marginHeight +
                this.headerHeight +
                this.offsetHeight;
            this._height.next(height);
        } else {
            this._height.next(null);
        }
    }
    //#endregion
}
