import { DOCUMENT } from '@angular/common';
import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Output,
    Renderer2,
    ViewChild
} from '@angular/core';
import { CustomDialogComponent } from '@app/shared/bulk-action-snackbar/custom-dialog/custom-dialog.component';
import { FileExportType } from '@app/shared/constants/export.enum';

@Component({
    selector: 'export-data',
    templateUrl: './export-data.component.html',
    styleUrls: ['./export-data.component.scss']
})
export class ExportDataComponent implements OnInit {
    @Input() fileExportList: Array<FileExportType> = [];

    @Input() tooltipMessageExport: string;
    @Input() tooltipMessageFullscreen = 'Afficher en plein écran';
    @Input() tooltipMessagePrint = 'common.graph-capture';
    @Input() tooltipDownload: string;

    @Input() showZoom = false;
    @Input() showPrint = false;
    @Input() showExport = false;
    @Input() disableExport = false;
    @Input() showDownload = false;
    @Input() showFilter = false;

    @Input() activeRequestHttp = false;
    @Input() noLoader = false;

    @Input() closeOnScroll = true;
    @Input() useOverlay = false;

    @Input() overlayX: 'center' | 'start' | 'end' = 'center';
    @Input() overlayY: 'center' | 'bottom' | 'top' = 'bottom';
    @Input() originX: 'center' | 'start' | 'end' = 'center';
    @Input() originY: 'center' | 'bottom' | 'top' = 'top';

    @Input() customClass;
    @Input() isBulkActionSnackbarContent;

    @Output() selectedType = new EventEmitter<FileExportType>();
    @Output() fullscreenEnabled = new EventEmitter<boolean>();
    @Output() printGraph = new EventEmitter<boolean>();
    @Output() downloadReport = new EventEmitter();
    @Output() openFilter = new EventEmitter<boolean>();

    @ViewChild(CustomDialogComponent) public customDialog: CustomDialogComponent;

    constructor(@Inject(DOCUMENT) private document: Document, private renderer: Renderer2) {}

    ngOnInit(): void {}

    selectedTypeEmission(exportType: FileExportType): void {
        this.selectedType.emit(exportType);
        if (this.customDialog) {
            this.customDialog.closePanel();
        }
    }

    capturGraph(): void {
        this.printGraph.emit(true);
    }

    enableFullscreen(): void {
        this.fullscreenEnabled.emit(true);
        this.renderer.addClass(this.document.body, 'full-screen');
    }

    disableFullscreen(): void {
        this.fullscreenEnabled.emit(false);
        this.renderer.removeClass(this.document.body, 'full-screen');
    }

    download(): void {
        this.downloadReport.emit();
    }

    onOpenFilter(): void {
        this.openFilter.emit(true);
    }
}
