<form [formGroup]="selectTextFieldForm">
  <div class="row row-cols-2">
    <div class="col pe-1">
      <mat-form-field class="w-100">
        <mat-select placeholder="{{ placeHolder }}" formControlName="selectionControl">
          <mat-option>Aucun</mat-option>
          <mat-option *ngFor="let item of items" [value]="item.id"> {{ item.label }} </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col ps-1">
      <mat-form-field class="w-100">
        <input matInput formControlName="textControl" />
      </mat-form-field>
    </div>
  </div>
</form>
